import React, { type ComponentType, type FC, useState } from 'react';
import DropdownMenu from '@atlaskit/dropdown-menu';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { ErrorState } from '@atlassian/jira-issue-navigator-actions-common/src/ui/error/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import { PluggableItemsFilterButton as FilterButton } from './filter-button/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/deprecations/no-rll-client-async-experiences
const PluggableItems: ComponentType<Record<any, any>> = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-pluggable-items" */ './main'),
	{
		ssr: false,
	},
);

const Fallback = ({ onRetry }: { onRetry: () => void }) => (
	<DropdownMenu
		trigger={({ triggerRef, ...props }) => <FilterButton {...props} ref={triggerRef} />}
		shouldFlip
		placement="bottom-end"
		spacing="compact"
	>
		<ErrorState onRetry={onRetry} pageAction="apps" />
	</DropdownMenu>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AsyncPluggableItems: FC<Record<any, any>> = () => {
	const [resetCaughtError, setResetCaughtError] = useState<Error>();
	return (
		<JSErrorBoundary
			packageName="jiraIssueNavigatorActionPluggableItems"
			id="async-pluggable-items"
			sendToPrivacyUnsafeSplunk
			// Attempt to re-render children when retry is pressed in the fallback error state
			fallback={({ error }) => <Fallback onRetry={() => setResetCaughtError(error)} />}
			onError={() => setResetCaughtError(undefined)}
			resetCaughtError={resetCaughtError}
		>
			<Placeholder name="async-pluggable-items" fallback={<FilterButton isDisabled />}>
				<PluggableItems />
			</Placeholder>
		</JSErrorBoundary>
	);
};

export default AsyncPluggableItems;
