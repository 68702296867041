import { useCallback, useEffect } from 'react';
import { toFlagId, useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useQueryParam } from '@atlassian/react-resource-router';
import { useBooleanPreferenceSet } from '../../services/use-boolean-preference-set/index.tsx';
import { WILDCARD_ADDED_FLAG_ID } from './constants.tsx';
import messages from './messages.tsx';

const flagId = toFlagId(WILDCARD_ADDED_FLAG_ID);

const useWildcardAddedFlag = () => {
	const { showFlag, hasFlag } = useFlagsService();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const displayFlag = useCallback(() => {
		if (!hasFlag?.(flagId)) {
			showFlag({
				id: flagId,
				key: flagId,
				type: 'info',
				title: formatMessage(messages.wildcardAddedFlagTitle),
				description: formatMessage(messages.wildcardAddedFlagDescription),
				actions: [
					{
						content: messages.wildcardAddedFlagLearnMoreAction,
						target: '_blank',
						href: 'https://support.atlassian.com/jira-software-cloud/docs/search-for-issues-using-the-text-field/#Term-searches',
					},
				],
				testId: 'issue-navigator-flags.ui.wildcard-added-flag.smart-query-detected-flag',
			});
			fireUIAnalytics(createAnalyticsEvent({}), 'wildcardAddedFlag shown');
		}
	}, [hasFlag, showFlag, createAnalyticsEvent, formatMessage]);
	return { displayFlag };
};

export const WildcardAddedFlag = () => {
	const { displayFlag } = useWildcardAddedFlag();
	const { update: updateWildcardShown } = useBooleanPreferenceSet(
		'user.query.wildcard.flag.dont.show',
	);
	const [wildcardFlag, setWildcardFlag] = useQueryParam('wildcardFlag');
	const shouldShowFlag = wildcardFlag === 'true';
	useEffect(() => {
		if (shouldShowFlag) {
			displayFlag();
			updateWildcardShown();
			setWildcardFlag(undefined, 'replace');
		}
	}, [shouldShowFlag, displayFlag, setWildcardFlag, updateWildcardShown]);
	return null;
};

export default WildcardAddedFlag;
