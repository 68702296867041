import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	wildcardAddedFlagLearnMoreAction: {
		defaultMessage: 'Learn more about searching for issues',
		id: 'issue-navigator-flags.wildcard-added-flag.wildcard-added-flag-learn-more-action',
		description: 'Link text to read a help article on text searches',
	},
	wildcardAddedFlagDescription: {
		defaultMessage: 'Use a wildcard to return more relevant results and find the work you need.',
		id: 'issue-navigator-flags.wildcard-added-flag.wildcard-added-flag-description',
		description: 'explanation of that we have added a wildcard (*) to the search query',
	},
	wildcardAddedFlagTitle: {
		defaultMessage: "We've added a wildcard (*) to your query",
		id: 'issue-navigator-flags.wildcard-added-flag.wildcard-added-flag-title',
		description:
			'Title of the flag that tells the user we added a wildcard (*) to the search query',
	},
});
