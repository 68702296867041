import React, { type FC } from 'react';
import IssueNavigator, {
	type Props as IssueNavigatorProps,
} from '@atlassian/jira-issue-navigator/src/main.tsx';
import { GlobalIssueNavigatorHeaderSkeleton } from '@atlassian/jira-skeletons/src/ui/issue-navigator/components/global-header.tsx';
import Header from './ui/index.tsx';

type InnerProps = {
	CustomHeader?: IssueNavigatorProps['CustomHeader'];
	HeaderSkeletonImage: IssueNavigatorProps['HeaderSkeletonImage'];
};

export type Props = Flow.Diff<IssueNavigatorProps, InnerProps>;

const GlobalIssueNavigator: FC<Props> = (props: Props) => (
	<IssueNavigator
		CustomHeader={Header}
		HeaderSkeletonImage={GlobalIssueNavigatorHeaderSkeleton}
		isFeedbackButtonDisabled
		{...props}
	/>
);

export default GlobalIssueNavigator;
