import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	flagTitle: {
		defaultMessage: 'Trying to search using a smart query?',
		id: 'issue-navigator-flags.smart-query-detected-flag.flag-title',
		description:
			'Flag header if a smart query is detected but not applied in the global issue navigator',
	},
	flagDescription: {
		defaultMessage:
			'Smart queries are turned off by default. You can enable them in your <strong>personal settings</strong>.',
		id: 'issue-navigator-flags.smart-query-detected-flag.flag-description',
		description:
			'Flag body if a smart query is detected but not applied in the global issue navigator.',
	},
	flagDontShowAgainAction: {
		defaultMessage: 'Don’t show me this again',
		id: 'issue-navigator-flags.smart-query-detected-flag.flag-dont-show-again-action',
		description: 'Button text to dismiss this flag and never see it again',
	},
});
