function $parcel$interopDefault(a) {
    return a && a.__esModule ? a.default : a;
}
function $parcel$defineInteropFlag(a) {
    Object.defineProperty(a, '__esModule', {
        value: true,
        configurable: true
    });
}
function $parcel$export(e, n, v, s) {
    Object.defineProperty(e, n, {
        get: v,
        set: s,
        enumerable: true,
        configurable: true
    });
}
var $parcel$global = globalThis;
var parcelRequire = $parcel$global["parcelRequire94c2"];
var parcelRegister = parcelRequire.register;
parcelRegister("iUdxh", function(module, exports) {
    $parcel$defineInteropFlag(module.exports);
    $parcel$export(module.exports, "default", ()=>$5fa05719bb5ae605$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    parcelRequire("2Geeh");
    var $ajpes = parcelRequire("ajpes");
    var $4f8Oj = parcelRequire("4f8Oj");
    var $ebyGx = parcelRequire("ebyGx");
    var $dGrdR;
    var $39xOx;
    var $9Nvh7;
    var $fasDq;
    var $h18VN;
    var $6UAdF;
    var $eJ0Zs;
    var $g5X3G;
    var $l8ruj;
    var $5y0F0;
    var $jDDFr;
    var $hayxm;
    const $5fa05719bb5ae605$var$BROWSER_METRICS_INTERACTION_APP_KEY = 'jira.global-issue-navigator';
    const $5fa05719bb5ae605$var$initInteractions = ()=>({
            pagination: {
                metrics: (0, (0, parcelRequire("dGrdR")).metrics).interaction({
                    key: `${$5fa05719bb5ae605$var$BROWSER_METRICS_INTERACTION_APP_KEY}.pagination.start-end.diff`
                }),
                properties: {}
            },
            refinement: {
                metrics: (0, (0, parcelRequire("dGrdR")).metrics).interaction({
                    key: `${$5fa05719bb5ae605$var$BROWSER_METRICS_INTERACTION_APP_KEY}.refinement.start-end.diff`
                }),
                properties: {}
            },
            filter: {
                metrics: (0, (0, parcelRequire("dGrdR")).metrics).interaction({
                    key: `${$5fa05719bb5ae605$var$BROWSER_METRICS_INTERACTION_APP_KEY}.filter.start-end.diff`
                }),
                properties: {}
            },
            'list-view': {
                metrics: (0, (0, parcelRequire("dGrdR")).metrics).interaction({
                    key: `${$5fa05719bb5ae605$var$BROWSER_METRICS_INTERACTION_APP_KEY}.list-view.start-end.diff`
                }),
                properties: {}
            },
            'split-view': {
                metrics: (0, (0, parcelRequire("dGrdR")).metrics).interaction({
                    key: `${$5fa05719bb5ae605$var$BROWSER_METRICS_INTERACTION_APP_KEY}.split-view.start-end.diff`
                }),
                properties: {}
            },
            'column-configuration': {
                metrics: (0, (0, parcelRequire("dGrdR")).metrics).interaction({
                    key: `${$5fa05719bb5ae605$var$BROWSER_METRICS_INTERACTION_APP_KEY}.column-configuration.start-end.diff`
                }),
                properties: {}
            }
        });
    class $5fa05719bb5ae605$var$GlobalIssueNavigatorSPAWrapper extends (0, $5uXOq.Component) {
        constructor(props){
            super(props);
            this.state = {
                initialised: false,
                issueKey: this.props.match?.params.issueKey ?? ''
            };
            this.onChangeIssue = (issueKey, isSelectedByUserInteraction)=>{
                this.push({
                    issueKey: issueKey
                }, !isSelectedByUserInteraction);
            };
            this.onChangePage = ()=>{
                this.startInteraction('pagination', {
                    view: this.view
                });
            };
            this.onSetView = (newView, issueKey)=>{
                this.startInteraction((0, (0, parcelRequire("eJ0Zs")).convertToView)(newView));
                this.view = newView;
                if (issueKey !== undefined) this.push({
                    issueKey: issueKey
                }, true);
            };
            this.onRefinement = ()=>{
                this.startInteraction('refinement');
            };
            this.onChangeJql = (jql, clearFilter = false)=>{
                this.push({
                    ...clearFilter ? {
                        filter: undefined
                    } : {},
                    jql: jql,
                    issueKey: (0, (0, parcelRequire("g5X3G")).toIssueKey)('')
                });
            };
            this.onPageDataLoad = (selectedView)=>{
                if (selectedView !== undefined) this.view = selectedView;
                if (!this.state.initialised) this.setState({
                    initialised: true
                });
                this.endInteractions();
            };
            this.onStartFilterChangeInteractionIfAny = (filter)=>{
                if (this.lastFilter !== filter) {
                    this.lastFilter = filter;
                    if (filter != null && (0, (0, parcelRequire("9Nvh7")).isFilterId)(filter)) this.startInteraction('filter', {
                        filterId: filter
                    });
                }
            };
            this.onChangeColumnConfiguration = ()=>{
                this.startInteraction('column-configuration');
            };
            this.onChangeFilter = (filter)=>{
                this.push({
                    ...filter ? {
                        filter: filter,
                        jql: undefined
                    } : {
                        jql: undefined
                    }
                }, true);
            };
            this.startInteraction = (key, properties)=>{
                if (!this.activeInteractions.includes(key)) this.activeInteractions.push(key);
                const interaction = this.interactions[key];
                if (interaction) {
                    interaction.properties = properties || {};
                    interaction.metrics.start();
                }
            };
            this.endInteractions = ()=>{
                this.activeInteractions.forEach((key)=>{
                    this.interactions[key]?.metrics.stop({
                        customData: this.interactions[key]?.properties
                    });
                });
                this.activeInteractions = [];
            };
            this.getQueryStringParams = ()=>{
                const { location: location } = this.props;
                const { filter: filter, jql: jql } = (0, $ebyGx.parse)(location.search, {
                    arrayFormat: 'comma'
                });
                return {
                    filter: filter,
                    jql: jql
                };
            };
            const { location: location } = props;
            this.locationSearchStr = location.search;
            this.activeInteractions = [];
            this.view = (0, (0, parcelRequire("6UAdF")).DEFAULT_VIEW_ID);
            this.interactions = $5fa05719bb5ae605$var$initInteractions();
        }
        componentDidUpdate(prevProps) {
            const prevIssueKey = prevProps.match?.params.issueKey ?? '';
            const issueKey = this.props.match?.params.issueKey ?? '';
            if (issueKey !== prevIssueKey && issueKey !== this.state.issueKey) this.setState({
                issueKey: issueKey
            });
        }
        push(newParams, shouldReplace = false) {
            const { push: push, location: location, replace: replace } = this.props;
            const historyAction = shouldReplace ? replace : push;
            const oldIssueKey = this.state.issueKey;
            const { issueKey: issueKey = oldIssueKey ?? '', ...params } = newParams;
            const issueKeyChanged = newParams.issueKey !== oldIssueKey;
            const query = (0, $ebyGx.parse)(location.search, {
                arrayFormat: 'comma'
            });
            const queryParamsChanged = Object.keys(params).length !== 0 && !(0, ($parcel$interopDefault($ajpes)))((0, ($parcel$interopDefault($4f8Oj)))(params, (param)=>param), query);
            if (issueKeyChanged || queryParamsChanged) historyAction({
                pathname: `/issues/${issueKey ?? ''}`,
                search: (0, $ebyGx.stringify)({
                    ...query,
                    ...params
                }, {
                    arrayFormat: 'comma'
                })
            });
            if (issueKeyChanged) this.setState({
                issueKey: issueKey ?? ''
            });
            return true;
        }
        render() {
            const { filter: filter, jql: jql } = this.getQueryStringParams();
            this.onStartFilterChangeInteractionIfAny(filter);
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hayxm")).default), {
                issueKey: (0, (0, parcelRequire("g5X3G")).toIssueKey)(this.state.issueKey),
                jql: jql,
                onChangeIssue: this.onChangeIssue,
                onChangePage: this.onChangePage,
                onPageDataLoad: this.onPageDataLoad,
                onChangeColumnConfiguration: this.onChangeColumnConfiguration,
                onChangeJql: this.onChangeJql,
                onChangeFilter: this.onChangeFilter,
                initialised: this.state.initialised,
                onRefinement: this.onRefinement,
                onSetView: this.onSetView,
                view: (0, (0, parcelRequire("eJ0Zs")).convertToView)(this.view),
                filter: filter
            }), this.state.initialised && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
                id: "smartQueryDetectedFlag",
                packageName: "jiraSpaAppsGlobalIssueNavigator",
                teamName: "empanada",
                fallback: "unmount"
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fasDq")).SmartQueryDetectedFlag), {
                jql: jql
            })), this.state.initialised && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
                id: "wildcardAddedFlag",
                packageName: "jiraSpaAppsGlobalIssueNavigator",
                teamName: "empanada",
                fallback: "unmount"
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("h18VN")).WildcardAddedFlag), null)));
        }
    }
    const $5fa05719bb5ae605$var$GlobalIssueNavigatorSPAWrapperRouterConsumer = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l8ruj")).default), {
            name: "global-issue-navigator"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5y0F0")).RouterActions), null, ({ push: push, replace: replace })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jDDFr")).RouterSubscriber), null, ({ location: location, match: match })=>(0, ($parcel$interopDefault($5uXOq))).createElement($5fa05719bb5ae605$var$GlobalIssueNavigatorSPAWrapper, {
                    push: push,
                    location: location,
                    match: match,
                    replace: replace
                }))));
    var $5fa05719bb5ae605$export$2e2bcd8739ae039 = (0, $5uXOq.memo)($5fa05719bb5ae605$var$GlobalIssueNavigatorSPAWrapperRouterConsumer);
});
parcelRegister("fasDq", function(module, exports) {
    $parcel$export(module.exports, "SmartQueryDetectedFlag", ()=>$adf6e9334467a02d$export$8b388d8aa2182f84);
    var $5uXOq = parcelRequire("5uXOq");
    var $ebyGx = parcelRequire("ebyGx");
    var $cgL5K;
    var $7HGHK;
    var $pa9q9;
    var $g9eN3;
    var $koVbs;
    var $8zOmE;
    var $2NMNM;
    var $doZTA;
    var $4n4Cr;
    var $k9xB3;
    var $84gH2;
    const $adf6e9334467a02d$var$flagId = (0, (0, parcelRequire("7HGHK")).toFlagId)((0, $e5db7e3c291ae89c$export$aead6163e23d2a49));
    const $adf6e9334467a02d$export$48ea269755562084 = (props)=>{
        const { dismissFlag: dismissFlag } = (0, (0, parcelRequire("pa9q9")).useFlagsService)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const onDismissed = ()=>{
            dismissFlag($adf6e9334467a02d$var$flagId);
            (0, (0, parcelRequire("8zOmE")).fireTrackAnalytics)(createAnalyticsEvent({}), 'smartQueryDetectedFlag dismissed');
        };
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("g9eN3")).default), {
            ...props,
            onDismissed: onDismissed
        });
    };
    function $adf6e9334467a02d$var$useDontShowAgainAction() {
        const { update: updateSmartQueryDontShowPreference } = (0, (0, parcelRequire("4n4Cr")).useBooleanPreferenceSet)('user.smart.query.flag.dont.show');
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const { dismissFlag: dismissFlag } = (0, (0, parcelRequire("pa9q9")).useFlagsService)();
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return {
            dontShowAgainAction: (0, $5uXOq.useMemo)(()=>({
                    content: formatMessage((0, (0, parcelRequire("84gH2")).default).flagDontShowAgainAction),
                    onClick: ()=>{
                        updateSmartQueryDontShowPreference();
                        (0, (0, parcelRequire("8zOmE")).fireTrackAnalytics)(createAnalyticsEvent({}), 'smartQueryDetectedFlagDontShowAgain clicked');
                        dismissFlag($adf6e9334467a02d$var$flagId);
                    }
                }), [
                formatMessage,
                updateSmartQueryDontShowPreference,
                createAnalyticsEvent,
                dismissFlag
            ])
        };
    }
    const $adf6e9334467a02d$export$a9400f40005bf958 = ()=>{
        const { showFlag: showFlag, hasFlag: hasFlag } = (0, (0, parcelRequire("pa9q9")).useFlagsService)();
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { dontShowAgainAction: dontShowAgainAction } = $adf6e9334467a02d$var$useDontShowAgainAction();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const flagActions = (0, $5uXOq.useMemo)(()=>[
                dontShowAgainAction
            ], [
            dontShowAgainAction
        ]);
        const displayFlag = (0, $5uXOq.useCallback)(()=>{
            if (!hasFlag?.($adf6e9334467a02d$var$flagId)) {
                showFlag({
                    id: $adf6e9334467a02d$var$flagId,
                    render: (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement($adf6e9334467a02d$export$48ea269755562084, {
                            ...props,
                            id: $adf6e9334467a02d$var$flagId,
                            key: $adf6e9334467a02d$var$flagId,
                            title: formatMessage((0, (0, parcelRequire("84gH2")).default).flagTitle),
                            description: formatMessage((0, (0, parcelRequire("84gH2")).default).flagDescription, {
                                strong: (chunks)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
                                        as: "strong"
                                    }, chunks)
                            }),
                            actions: flagActions,
                            testId: "issue-navigator-flags.ui.smart-query-detected-flag.smart-query-detected-flag"
                        })
                });
                (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({}), 'smartQueryDetectedFlag shown');
            }
        }, [
            hasFlag,
            showFlag,
            formatMessage,
            flagActions,
            createAnalyticsEvent
        ]);
        return {
            displayFlag: displayFlag
        };
    };
    const $adf6e9334467a02d$export$8b388d8aa2182f84 = ({ jql: jql })=>{
        const { displayFlag: displayFlag } = $adf6e9334467a02d$export$a9400f40005bf958();
        const [route, { replace: replace }] = (0, (0, parcelRequire("doZTA")).useRouter)();
        const params = (0, $5uXOq.useMemo)(()=>(0, $ebyGx.parse)(route.location.search), [
            route.location.search
        ]);
        const wildcardAddedBool = params.wildcardAdded === 'true';
        const smartQueryDisabledBool = params.smartQueryDisabled === 'true';
        const { isSmartQuery: isSmartQuery, data: data } = (0, (0, parcelRequire("k9xB3")).useSmartQueryCheck)({
            jql: smartQueryDisabledBool ? jql : undefined,
            wildcardAdded: wildcardAddedBool
        });
        (0, $5uXOq.useEffect)(()=>{
            if (isSmartQuery) displayFlag();
        }, [
            isSmartQuery,
            displayFlag
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (data || !smartQueryDisabledBool && wildcardAddedBool) {
                const newSearch = {
                    ...params
                };
                if (newSearch.smartQueryDisabled) delete newSearch.smartQueryDisabled;
                if (newSearch.wildcardAdded) delete newSearch.wildcardAdded;
                const newSearchString = `?${(0, $ebyGx.stringify)(newSearch, {
                    arrayFormat: 'comma'
                })}`;
                const oldSearchString = `?${(0, $ebyGx.stringify)(params, {
                    arrayFormat: 'comma'
                })}`;
                if (newSearchString !== oldSearchString) replace({
                    ...route.location,
                    search: newSearchString
                });
            }
        }, [
            data,
            route,
            replace,
            smartQueryDisabledBool,
            wildcardAddedBool,
            params
        ]);
        return null;
    };
    var $adf6e9334467a02d$export$2e2bcd8739ae039 = $adf6e9334467a02d$export$8b388d8aa2182f84;
});
parcelRegister("g9eN3", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$100aabc6f0d895d9$export$2e2bcd8739ae039);
    var $3KRpp;
    var $100aabc6f0d895d9$export$2e2bcd8739ae039 = (0, (0, parcelRequire("3KRpp")).InfoFlag);
});
parcelRegister("3KRpp", function(module, exports) {
    $parcel$export(module.exports, "CommonFlag", ()=>$ef767762d5402ccf$export$13d73238a031436b);
    $parcel$export(module.exports, "ErrorFlag", ()=>$ef767762d5402ccf$export$518ba8e0e60f145f);
    $parcel$export(module.exports, "ErrorAutoDismissFlag", ()=>$ef767762d5402ccf$export$3f75672bb90a4431);
    $parcel$export(module.exports, "InfoFlag", ()=>$ef767762d5402ccf$export$6a55b52e74c1e48e);
    $parcel$export(module.exports, "SuccessAutoDismissFlag", ()=>$ef767762d5402ccf$export$74a3c0748aa31355);
    $parcel$export(module.exports, "SuccessFlag", ()=>$ef767762d5402ccf$export$93bd4963a1d00fd5);
    $parcel$export(module.exports, "WarningFlag", ()=>$ef767762d5402ccf$export$23695d8281998f27);
    $parcel$export(module.exports, "FlagGroup", ()=>$ef767762d5402ccf$export$a2c3479950310d7d);
    var $5uXOq = parcelRequire("5uXOq");
    var $1eg3p;
    var $kxZPA;
    const $ef767762d5402ccf$var$ForPaintJiraCommonFlag = (0, (0, parcelRequire("kxZPA")).lazyForPaint)(()=>(parcelRequire("lKKug")).then(({ JiraCommonFlag: JiraCommonFlag })=>JiraCommonFlag), {
        ssr: false,
        moduleId: "./src/packages/platform/ui/flags/src/common/ui/components/common-flag-v2/index.tsx"
    });
    const $ef767762d5402ccf$var$AfterPaintJiraCommonFlag = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("lKKug")).then(({ JiraCommonFlag: JiraCommonFlag })=>JiraCommonFlag), {
        ssr: false,
        moduleId: "./src/packages/platform/ui/flags/src/common/ui/components/common-flag-v2/index.tsx"
    });
    const $ef767762d5402ccf$var$CommonFlagImpl = (props)=>__SPA__ ? (0, ($parcel$interopDefault($5uXOq))).createElement($ef767762d5402ccf$var$AfterPaintJiraCommonFlag, props) : (0, ($parcel$interopDefault($5uXOq))).createElement($ef767762d5402ccf$var$ForPaintJiraCommonFlag, props);
    const $ef767762d5402ccf$var$ForPaintFlagGroup = (0, (0, parcelRequire("kxZPA")).lazyForPaint)(()=>(parcelRequire("fhw5b")), {
        ssr: false,
        moduleId: "./src/packages/platform/ui/flags/src/common/ui/components/group-flag-v2/index.tsx"
    });
    const $ef767762d5402ccf$var$AfterPaintFlagGroup = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("fhw5b")), {
        ssr: false,
        moduleId: "./src/packages/platform/ui/flags/src/common/ui/components/group-flag-v2/index.tsx"
    });
    const $ef767762d5402ccf$var$FlagGroupImpl = (props)=>__SPA__ ? (0, ($parcel$interopDefault($5uXOq))).createElement($ef767762d5402ccf$var$AfterPaintFlagGroup, props) : (0, ($parcel$interopDefault($5uXOq))).createElement($ef767762d5402ccf$var$ForPaintFlagGroup, props);
    const $ef767762d5402ccf$export$13d73238a031436b = ({ packageName: packageName = 'flags', ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1eg3p")).FlagsBoundary), {
            packageName: packageName
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($ef767762d5402ccf$var$CommonFlagImpl, props));
    const $ef767762d5402ccf$export$518ba8e0e60f145f = ({ packageName: packageName = 'flags', ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1eg3p")).FlagsBoundary), {
            packageName: packageName
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($ef767762d5402ccf$var$CommonFlagImpl, {
            flagType: "error",
            ...props
        }));
    const $ef767762d5402ccf$export$3f75672bb90a4431 = ({ packageName: packageName = 'flags', ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1eg3p")).FlagsBoundary), {
            packageName: packageName
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($ef767762d5402ccf$var$CommonFlagImpl, {
            flagType: "error",
            isAutoDismiss: true,
            ...props
        }));
    const $ef767762d5402ccf$export$6a55b52e74c1e48e = ({ packageName: packageName = 'flags', ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1eg3p")).FlagsBoundary), {
            packageName: packageName
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($ef767762d5402ccf$var$CommonFlagImpl, {
            flagType: "info",
            ...props
        }));
    const $ef767762d5402ccf$export$74a3c0748aa31355 = ({ packageName: packageName = 'flags', ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1eg3p")).FlagsBoundary), {
            packageName: packageName
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($ef767762d5402ccf$var$CommonFlagImpl, {
            flagType: "success",
            isAutoDismiss: true,
            ...props
        }));
    const $ef767762d5402ccf$export$93bd4963a1d00fd5 = ({ packageName: packageName = 'flags', ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1eg3p")).FlagsBoundary), {
            packageName: packageName
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($ef767762d5402ccf$var$CommonFlagImpl, {
            flagType: "success",
            ...props
        }));
    const $ef767762d5402ccf$export$23695d8281998f27 = ({ packageName: packageName = 'flags', ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1eg3p")).FlagsBoundary), {
            packageName: packageName
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($ef767762d5402ccf$var$CommonFlagImpl, {
            flagType: "warning",
            ...props
        }));
    const $ef767762d5402ccf$export$a2c3479950310d7d = ({ packageName: packageName = 'flags', ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1eg3p")).FlagsBoundary), {
            packageName: packageName
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($ef767762d5402ccf$var$FlagGroupImpl, props));
});
parcelRegister("lKKug", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("hPzOn"),
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("4yB9l")
    ]).then(()=>parcelRequire('8ZHDV'));
});
parcelRegister("fhw5b", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("Fkef4").then(()=>parcelRequire('5pz6a'));
});
parcelRegister("4n4Cr", function(module, exports) {
    $parcel$export(module.exports, "useBooleanPreferenceSet", ()=>$aca83aa66389e1ff$export$2e9a7ecf87efb1e6);
    var $5uXOq = parcelRequire("5uXOq");
    var $ld2hZ;
    var $jcw0u;
    var $eusud;
    const $aca83aa66389e1ff$export$2e9a7ecf87efb1e6 = (key)=>{
        const setPreferenceToTrue = (0, $5uXOq.useCallback)(()=>(0, (0, parcelRequire("eusud")).performPutRequest)(`/rest/api/3/mypreferences?key=${key}`, {
                body: 'true'
            }), [
            key
        ]);
        const { loading: loading, error: error, fetch: update } = (0, (0, parcelRequire("ld2hZ")).useService)(setPreferenceToTrue);
        (0, $5uXOq.useEffect)(()=>{
            if (error) (0, (0, parcelRequire("jcw0u")).default)({
                meta: {
                    id: 'useBooleanPreferenceSet',
                    packageName: 'jiraIssueNavigatorFlags',
                    teamName: 'empanada'
                },
                error: error,
                attributes: {
                    key: key
                }
            });
        }, [
            error,
            key
        ]);
        return {
            loading: loading,
            error: error,
            update: update
        };
    };
    var $aca83aa66389e1ff$export$2e2bcd8739ae039 = $aca83aa66389e1ff$export$2e9a7ecf87efb1e6;
});
parcelRegister("ld2hZ", function(module, exports) {
    $parcel$export(module.exports, "useService", ()=>$b3ad55f76ff82cb6$export$6de4685a6c88b4e9);
    $parcel$export(module.exports, "useLoadingStatusService", ()=>$b3ad55f76ff82cb6$export$c963713a27525aaf);
    var $5uXOq = parcelRequire("5uXOq");
    const $b3ad55f76ff82cb6$export$6de4685a6c88b4e9 = (request, initialState = {})=>{
        const [state, setState] = (0, $5uXOq.useState)({
            loading: false,
            data: undefined,
            error: undefined,
            ...initialState
        });
        const fetch = (0, $5uXOq.useCallback)(async (requestData)=>{
            setState({
                loading: true,
                error: undefined,
                data: undefined
            });
            try {
                const data = await request(requestData);
                setState({
                    loading: false,
                    error: undefined,
                    data: data
                });
                return data;
            } catch (error) {
                setState({
                    loading: false,
                    error: error,
                    data: undefined
                });
                return undefined;
            }
        }, [
            request
        ]);
        return {
            ...state,
            fetch: fetch
        };
    };
    const $b3ad55f76ff82cb6$export$c963713a27525aaf = (request, initialState = {})=>{
        const [loadingStatus, setLoadingStatus] = (0, $5uXOq.useState)('idle');
        const { data: data, loading: loading, error: error, fetch: fetch } = $b3ad55f76ff82cb6$export$6de4685a6c88b4e9(request, initialState);
        (0, $5uXOq.useEffect)(()=>{
            if (loading) setLoadingStatus('started');
            else if (error) setLoadingStatus('error');
            else if (data) setLoadingStatus('success');
        }, [
            data,
            loading,
            error
        ]);
        return {
            data: data,
            loadingStatus: loadingStatus,
            error: error,
            fetch: fetch
        };
    };
});
parcelRegister("k9xB3", function(module, exports) {
    $parcel$export(module.exports, "useSmartQueryCheck", ()=>$1db75f3e8ec45b3c$export$26a66faebe7f7b65);
    var $5uXOq = parcelRequire("5uXOq");
    var $ebyGx = parcelRequire("ebyGx");
    var $ld2hZ;
    var $jcw0u;
    var $co1wz;
    var $4R6GH;
    var $7f4tk;
    var $9OXo1;
    const $1db75f3e8ec45b3c$var$JQL_REGEX = /(?:textfields ~ )(.*?)(?= AND .*| OR .*| ORDER BY .*|$)/i;
    const $1db75f3e8ec45b3c$var$JQL_REGEX_OLD = /(?:text ~ )(.*?)(?= AND .*| OR .*| ORDER BY .*|$)/;
    const $1db75f3e8ec45b3c$var$JQL_REGEX_SUMMARY_AND_DESCRIPTION = /(?:summary ~ )(.*?)( OR description ~ )(.*?)(?= AND .*| OR .*| ORDER BY .*|$)/;
    const $1db75f3e8ec45b3c$export$26a66faebe7f7b65 = ({ jql: jql, wildcardAdded: wildcardAdded })=>{
        const { atlassianAccountId: atlassianAccountId } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const request = (0, $5uXOq.useCallback)(async ()=>{
            if (!atlassianAccountId || !jql) return undefined;
            let searchString;
            if ((0, (0, parcelRequire("4R6GH")).fg)('nin-text-fields-new-text-search-field')) searchString = ($1db75f3e8ec45b3c$var$JQL_REGEX.exec(jql)?.[1] || '').replaceAll('"', '');
            else if ((0, (0, parcelRequire("co1wz")).ff)('nin_expose_text_option_jql_builder_4kwzo')) searchString = ($1db75f3e8ec45b3c$var$JQL_REGEX_SUMMARY_AND_DESCRIPTION.exec(jql)?.[1] || '').replaceAll('"', '');
            else searchString = ($1db75f3e8ec45b3c$var$JQL_REGEX_OLD.exec(jql)?.[1] || '').replaceAll('"', '');
            if (wildcardAdded) searchString = searchString.slice(0, -1);
            const queryStr = (0, $ebyGx.stringify)({
                searchString: searchString,
                ignorePreference: true
            });
            return (0, (0, parcelRequire("7f4tk")).default)(`/rest/navigation/4/quicksearch?${queryStr}`);
        }, [
            atlassianAccountId,
            jql,
            wildcardAdded
        ]);
        const { loading: loading, error: error, data: data, fetch: fetch } = (0, (0, parcelRequire("ld2hZ")).useService)(request);
        (0, $5uXOq.useEffect)(()=>{
            fetch();
        }, [
            fetch
        ]);
        const isSmartQuery = (0, $5uXOq.useMemo)(()=>{
            const quicksearchUrl = data?.url;
            if (quicksearchUrl) {
                const query = (0, $ebyGx.parse)(quicksearchUrl, {
                    arrayFormat: 'comma'
                });
                if (query.quickSearchQuery !== undefined) return true;
            }
            return false;
        }, [
            data
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (error) (0, (0, parcelRequire("jcw0u")).default)({
                meta: {
                    id: 'quicksearchSmartQueryDisabledFetch',
                    packageName: 'jiraIssueNavigatorFlags',
                    teamName: 'empanada'
                },
                error: error
            });
        }, [
            error
        ]);
        return {
            loading: loading,
            error: error,
            data: data,
            fetch: fetch,
            isSmartQuery: isSmartQuery
        };
    };
    var $1db75f3e8ec45b3c$export$2e2bcd8739ae039 = $1db75f3e8ec45b3c$export$26a66faebe7f7b65;
});
const $e5db7e3c291ae89c$export$aead6163e23d2a49 = 'SMART_QUERY_DETECTED_FLAG_ID';
parcelRegister("84gH2", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$e8833b1899639508$export$2e2bcd8739ae039);
    var $7VHMR;
    var $e8833b1899639508$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        flagTitle: {
            "id": "issue-navigator-flags.smart-query-detected-flag.flag-title",
            "defaultMessage": "Trying to search using a smart query?"
        },
        flagDescription: {
            "id": "issue-navigator-flags.smart-query-detected-flag.flag-description",
            "defaultMessage": "Smart queries are turned off by default. You can enable them in your <strong>personal settings</strong>."
        },
        flagDontShowAgainAction: {
            "id": "issue-navigator-flags.smart-query-detected-flag.flag-dont-show-again-action",
            "defaultMessage": "Don\u2019t show me this again"
        }
    });
});
parcelRegister("h18VN", function(module, exports) {
    $parcel$export(module.exports, "WildcardAddedFlag", ()=>$06b901faebbad881$export$cec045e2d66506ad);
    var $5uXOq = parcelRequire("5uXOq");
    var $7HGHK;
    var $pa9q9;
    var $koVbs;
    var $8zOmE;
    var $2NMNM;
    var $ieDtd;
    var $4n4Cr;
    var $kFhBX;
    const $06b901faebbad881$var$flagId = (0, (0, parcelRequire("7HGHK")).toFlagId)((0, $e7e714842c3694bd$export$8ba446e2fadf2828));
    const $06b901faebbad881$var$useWildcardAddedFlag = ()=>{
        const { showFlag: showFlag, hasFlag: hasFlag } = (0, (0, parcelRequire("pa9q9")).useFlagsService)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const displayFlag = (0, $5uXOq.useCallback)(()=>{
            if (!hasFlag?.($06b901faebbad881$var$flagId)) {
                showFlag({
                    id: $06b901faebbad881$var$flagId,
                    key: $06b901faebbad881$var$flagId,
                    type: 'info',
                    title: formatMessage((0, (0, parcelRequire("kFhBX")).default).wildcardAddedFlagTitle),
                    description: formatMessage((0, (0, parcelRequire("kFhBX")).default).wildcardAddedFlagDescription),
                    actions: [
                        {
                            content: (0, (0, parcelRequire("kFhBX")).default).wildcardAddedFlagLearnMoreAction,
                            target: '_blank',
                            href: 'https://support.atlassian.com/jira-software-cloud/docs/search-for-issues-using-the-text-field/#Term-searches'
                        }
                    ],
                    testId: 'issue-navigator-flags.ui.wildcard-added-flag.smart-query-detected-flag'
                });
                (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({}), 'wildcardAddedFlag shown');
            }
        }, [
            hasFlag,
            showFlag,
            createAnalyticsEvent,
            formatMessage
        ]);
        return {
            displayFlag: displayFlag
        };
    };
    const $06b901faebbad881$export$cec045e2d66506ad = ()=>{
        const { displayFlag: displayFlag } = $06b901faebbad881$var$useWildcardAddedFlag();
        const { update: updateWildcardShown } = (0, (0, parcelRequire("4n4Cr")).useBooleanPreferenceSet)('user.query.wildcard.flag.dont.show');
        const [wildcardFlag, setWildcardFlag] = (0, (0, parcelRequire("ieDtd")).useQueryParam)('wildcardFlag');
        const shouldShowFlag = wildcardFlag === 'true';
        (0, $5uXOq.useEffect)(()=>{
            if (shouldShowFlag) {
                displayFlag();
                updateWildcardShown();
                setWildcardFlag(undefined, 'replace');
            }
        }, [
            shouldShowFlag,
            displayFlag,
            setWildcardFlag,
            updateWildcardShown
        ]);
        return null;
    };
    var $06b901faebbad881$export$2e2bcd8739ae039 = $06b901faebbad881$export$cec045e2d66506ad;
});
const $e7e714842c3694bd$export$8ba446e2fadf2828 = 'WILDCARD_ADDED_FLAG_ID';
parcelRegister("kFhBX", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$c4b2d8af920c3274$export$2e2bcd8739ae039);
    var $7VHMR;
    var $c4b2d8af920c3274$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        wildcardAddedFlagLearnMoreAction: {
            "id": "issue-navigator-flags.wildcard-added-flag.wildcard-added-flag-learn-more-action",
            "defaultMessage": "Learn more about searching for issues"
        },
        wildcardAddedFlagDescription: {
            "id": "issue-navigator-flags.wildcard-added-flag.wildcard-added-flag-description",
            "defaultMessage": "Use a wildcard to return more relevant results and find the work you need."
        },
        wildcardAddedFlagTitle: {
            "id": "issue-navigator-flags.wildcard-added-flag.wildcard-added-flag-title",
            "defaultMessage": "We've added a wildcard (*) to your query"
        }
    });
});
parcelRegister("eJ0Zs", function(module, exports) {
    $parcel$export(module.exports, "createRememoizeSelector", ()=>$945e9d040796364e$export$982d75deeca693ef);
    $parcel$export(module.exports, "isNormalClick", ()=>$945e9d040796364e$export$729f3684d5c2b9b9);
    $parcel$export(module.exports, "isNonNullish", ()=>$945e9d040796364e$export$70573498ee17fe1d);
    $parcel$export(module.exports, "parseIssueNavigatorViewIdOrDefault", ()=>$945e9d040796364e$export$ad0c80ba9128e83a);
    $parcel$export(module.exports, "isFilterViewId", ()=>$945e9d040796364e$export$194f1a1e890054c9);
    $parcel$export(module.exports, "convertToIssueNavigatorId", ()=>$945e9d040796364e$export$6ce707b3eccb341d);
    $parcel$export(module.exports, "convertToView", ()=>$945e9d040796364e$export$95ef66082092aa68);
    $parcel$export(module.exports, "convertFilterIdToIssueNavigatorId", ()=>$945e9d040796364e$export$ec4f6e9ea23bb55d);
    var $dGvGJ = parcelRequire("dGvGJ");
    var $6UAdF;
    const $945e9d040796364e$export$982d75deeca693ef = (0, $dGvGJ.createSelectorCreator)((0, $dGvGJ.defaultMemoize), (a, b)=>a._rememoize === b._rememoize);
    const $945e9d040796364e$export$729f3684d5c2b9b9 = (e)=>!(e.button || e.metaKey || e.altKey || e.ctrlKey || e.shiftKey);
    function $945e9d040796364e$var$isPossibleValueEntry(entry) {
        return typeof entry[1] === 'string' || typeof entry[1] === 'number' || Array.isArray(entry[1]) && entry[1].length > 0;
    }
    const $945e9d040796364e$export$20fba0bc14771b28 = (state)=>{
        const definedEntries = Object.entries(state).filter($945e9d040796364e$var$isPossibleValueEntry);
        return definedEntries.map((entry)=>({
                key: entry[0],
                value: Array.isArray(entry[1]) ? entry[1].map((values)=>values.value) : entry[1]
            }));
    };
    const $945e9d040796364e$export$6256478a5735e738 = (state)=>Object.keys(state).reduce((acc, key)=>{
            const value = state[key];
            if (key === 'search') {
                if (typeof value !== 'string') throw new Error('Unexpected non-string literal for search.');
                acc[key] = value;
            } else if (Array.isArray(value)) acc[key] = value.map((values)=>values.value);
            else if (typeof value === 'string' || typeof value === 'undefined') acc[key] = value || [];
            else acc[key] = value;
            return acc;
        }, {});
    const $945e9d040796364e$export$28dade55d1b3964b = (viewId)=>viewId === 'detail' ? (0, (0, parcelRequire("6UAdF")).views).detail : (0, (0, parcelRequire("6UAdF")).views).list;
    const $945e9d040796364e$export$587f73d78d2a7579 = (viewMode)=>viewMode === (0, (0, parcelRequire("6UAdF")).views).detail ? 'detail' : 'list_default';
    const $945e9d040796364e$export$5786a571e562003d = (viewMode)=>viewMode === (0, (0, parcelRequire("6UAdF")).views).detail ? 'DETAIL' : 'LIST';
    function $945e9d040796364e$export$70573498ee17fe1d(value) {
        return value != null;
    }
    const $945e9d040796364e$export$ad0c80ba9128e83a = (viewId, defaultValue = 'list_default')=>{
        if (!viewId) return defaultValue;
        switch(viewId){
            case 'list_default':
                return 'list_default';
            case 'list_system':
                return 'list_system';
            case 'detail':
                return 'detail';
            default:
                if ($945e9d040796364e$export$194f1a1e890054c9(viewId)) return viewId;
                return defaultValue;
        }
    };
    const $945e9d040796364e$export$194f1a1e890054c9 = (viewId)=>/^list_filter_\d+$/.test(viewId);
    const $945e9d040796364e$export$6ce707b3eccb341d = (view)=>view === (0, (0, parcelRequire("6UAdF")).views).detail ? 'detail' : 'list_default';
    const $945e9d040796364e$export$95ef66082092aa68 = (issueNavigatorViewId)=>issueNavigatorViewId === 'detail' ? (0, (0, parcelRequire("6UAdF")).views).detail : (0, (0, parcelRequire("6UAdF")).views).list;
    const $945e9d040796364e$export$ec4f6e9ea23bb55d = (filterId)=>{
        if (typeof filterId === 'number') return `list_filter_${filterId}`;
        const filterIdInt = Number(filterId);
        if (Number.isNaN(filterIdInt)) throw new Error('filterId is invalid');
        return `list_filter_${filterIdInt}`;
    };
});
parcelRegister("hayxm", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$fd5a1b49151ba2ac$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $gcl1l;
    var $7iJwP;
    var $co1wz;
    var $cxmtN;
    var $9Nvh7;
    var $5Czte;
    var $km2nW;
    var $a8lIB;
    var $ay8sY;
    var $lfTZh;
    var $iXVV6;
    var $8PRuf;
    var $5rqex;
    var $ao2h1;
    var $aBKbA;
    var $89kiv;
    var $1gXMV;
    var $9dbF6;
    var $dZw8H;
    const $fd5a1b49151ba2ac$var$AsyncGlobalIssueNavigatorWrapper = ({ filter: filter, jql: jqlParam, initialised: initialised, view: view, ...props })=>{
        const onMutateJqlParam = (0, (0, parcelRequire("ay8sY")).useUpdateUrlQueryParamCallback)('jql');
        const defaultJql = (0, (0, parcelRequire("aBKbA")).getDefaultJqlGin)();
        const jql = (0, (0, parcelRequire("ay8sY")).getJqlFromParamValues)(defaultJql, jqlParam, filter, undefined, onMutateJqlParam);
        const searchInput = (0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("ay8sY")).getSearchInput)(jql, filter), [
            jql,
            filter
        ]);
        const filterForAnalytics = filter && (0, (0, parcelRequire("9Nvh7")).isFilterId)(filter) ? filter : 'default';
        const attributes = {
            navigatorScope: 'global',
            viewLayout: view || '',
            jql: jqlParam !== undefined,
            filter: filterForAnalytics,
            ...(0, (0, parcelRequire("km2nW")).getIssueNavigatorFFAndFG)()
        };
        const extraInfo = {
            scope: 'global',
            filterId: filterForAnalytics,
            isJqlParamPresent: !!jqlParam,
            ...attributes
        };
        const response = (0, (0, parcelRequire("ao2h1")).useRelayResource)((0, (0, parcelRequire("89kiv")).issueNavigatorRelayResource));
        const { queryReference: queryReference } = response;
        const filterResponse = (0, (0, parcelRequire("ao2h1")).useRelayResource)((0, (0, parcelRequire("89kiv")).issueNavigatorFilterRelayResource));
        const filterQueryReference = filterResponse.queryReference;
        const hydrationResponse = (0, (0, parcelRequire("ao2h1")).useRelayResource)((0, (0, parcelRequire("89kiv")).issueNavigatorHydrationRelayResource));
        const hydrationQueryReference = hydrationResponse.queryReference;
        if (queryReference == null) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1gXMV")).GlobalIssueNavigatorSkeleton), null);
        if (filterQueryReference == null) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1gXMV")).GlobalIssueNavigatorSkeleton), null);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            attributes: attributes,
            ...filter !== null && filter !== undefined && (0, (0, parcelRequire("9Nvh7")).isFilterId)(filter) && {
                containers: {
                    issueFilter: {
                        id: filter
                    }
                }
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7iJwP")).default), {
            id: "unhandled",
            packageName: "jiraSpaAppsGlobalIssueNavigator",
            teamName: "empanada",
            sendToPrivacyUnsafeSplunk: true,
            attributes: (0, (0, parcelRequire("gcl1l")).isLoaderErrorAttributes)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "global-issue-navigator-app",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1gXMV")).GlobalIssueNavigatorSkeleton), null)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5Czte")).EnableNinChangeboardingProvider), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cxmtN")).default), {
            ...props,
            searchInput: searchInput,
            defaultJql: defaultJql,
            queryReference: queryReference || undefined,
            filterQueryReference: filterQueryReference || undefined,
            jqlBuilderProps: {
                maybeHydrationQueryReference: hydrationQueryReference || undefined
            }
        })))), initialised && (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5rqex")).default), {
            appName: "global-issue-navigator",
            isExpectedToHaveSsr: true,
            withMarks: [
                (0, (0, parcelRequire("8PRuf")).appBundleReadyMark),
                (0, (0, parcelRequire("8PRuf")).prefetchApiReadyMark)
            ],
            ttrSlo: 3000,
            ttiSlo: 4000,
            extra: extraInfo,
            metric: (0, (0, parcelRequire("a8lIB")).NINPageLoad),
            emitOnAnimationFrame: (0, (0, parcelRequire("co1wz")).ff)('bm3.emit-on-raf.top-experiences')
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dZw8H")).SpaStatePageReady), null)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9dbF6")).SubProductUpdater), {
            subProduct: "platform"
        }));
    };
    var $fd5a1b49151ba2ac$export$2e2bcd8739ae039 = (0, $5uXOq.memo)($fd5a1b49151ba2ac$var$AsyncGlobalIssueNavigatorWrapper);
});
parcelRegister("gcl1l", function(module, exports) {
    $parcel$export(module.exports, "isLoaderErrorAttributes", ()=>$ecfe970d67067a10$export$c7209ce2e0d060ea);
    var $bCf8q;
    const $ecfe970d67067a10$export$c7209ce2e0d060ea = (error)=>({
            isLoaderError: (0, (0, parcelRequire("bCf8q")).isLoaderError)(error)
        });
});
parcelRegister("cxmtN", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$4d906565302a52a0$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $4m2yJ;
    var $lNtuJ;
    var $kfUal;
    const $4d906565302a52a0$var$GlobalIssueNavigator = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4m2yJ")).default), {
            CustomHeader: (0, (0, parcelRequire("kfUal")).default),
            HeaderSkeletonImage: (0, (0, parcelRequire("lNtuJ")).GlobalIssueNavigatorHeaderSkeleton),
            isFeedbackButtonDisabled: true,
            ...props
        });
    var $4d906565302a52a0$export$2e2bcd8739ae039 = $4d906565302a52a0$var$GlobalIssueNavigator;
});
parcelRegister("4m2yJ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$36b09ac9b73a27e7$export$2e2bcd8739ae039);
    var $7u5Tm;
    var $1nibn;
    var $7uwEc;
    var $ai5n6;
    var $4LFGS;
    var $ln32M;
    var $l5FbD;
    var $hTvlq;
    var $kvekU;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $6s1PB;
    var $7XYc4;
    var $4R6GH;
    var $2CtcG;
    var $6UAdF;
    var $a8lIB;
    var $1z7Yr;
    var $gtUFk;
    var $gwtSG;
    var $cKK2E;
    var $isIOR;
    var $a5eZM;
    var $en3WN;
    var $9BgIv;
    var $k4pU6;
    const $36b09ac9b73a27e7$export$e111e6466962b82b = (0, (0, parcelRequire("kvekU")).default);
    const $36b09ac9b73a27e7$var$FilterAndJqlProvider = (0, $5uXOq.memo)(({ children: children, jql: jql, refetch: refetch, filterId: filterId, isFilterEditable: isFilterEditable, filterJql: filterJql })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cKK2E")).FilterQueryProvider), {
            query: $36b09ac9b73a27e7$export$e111e6466962b82b,
            refetch: refetch,
            filterId: filterId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gwtSG")).ActiveJqlProvider), {
            filterId: filterId,
            filterJql: filterJql,
            jql: jql,
            isFilterEditable: isFilterEditable
        }, children)));
    const $36b09ac9b73a27e7$var$getFilter = (searchParam)=>{
        if (searchParam?.type === (0, (0, parcelRequire("6UAdF")).SearchInputTypes).JQL) return undefined;
        return searchParam.type === (0, (0, parcelRequire("6UAdF")).SearchInputTypes).FILTER_AND_JQL ? searchParam.filter : searchParam;
    };
    const $36b09ac9b73a27e7$var$MaybeIssueSearch = (0, (0, parcelRequire("7XYc4")).componentWithCondition)(()=>(0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false), (0, (0, parcelRequire("a5eZM")).IssueSearch), ({ children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children));
    const $36b09ac9b73a27e7$var$MaybeIssueSearchQueryProvider = (0, (0, parcelRequire("7XYc4")).componentWithCondition)(()=>(0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false), ({ children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children), (0, (0, parcelRequire("isIOR")).IssueSearchQueryProvider));
    const $36b09ac9b73a27e7$var$SelectedIssue = (0, (0, parcelRequire("7XYc4")).componentWithCondition)(()=>(0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false), ({ children: children, issues: issues, onChange: onChange, selectedIssueKey: selectedIssueKey })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9BgIv")).SelectedIssue), {
            issues: issues,
            onChange: onChange,
            selectedIssueKey: selectedIssueKey
        }, children), ({ children: children, issuesOld: issuesOld, onChange: onChange, selectedIssueKey: selectedIssueKey })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gtUFk")).SelectedIssueContainer), {
            issueResults: issuesOld,
            onChange: onChange,
            selectedIssueKey: selectedIssueKey
        }, children));
    const $36b09ac9b73a27e7$var$IssueNavigator = ({ issueKey: issueKey, queryReference: queryReference, filterQueryReference: filterQueryReference, CustomHeader: CustomHeader, ActionMenu: ActionMenu, HeaderSkeletonImage: HeaderSkeletonImage, onChangeIssue: onChangeIssue, onChangeFilter: onChangeFilter, onRefinement: onRefinement, onChangePage: onChangePage, onPageDataLoad: onPageDataLoad, onChangeColumnConfiguration: onChangeColumnConfiguration, onChangeJql: onChangeJql, onSetView: onSetView, defaultJql: defaultJql, searchInput: searchInput, jqlBuilderProps: jqlBuilderProps, isFeedbackButtonDisabled: isFeedbackButtonDisabled, issueTableProps: issueTableProps, isIssueHierarchySupportEnabled: isIssueHierarchySupportEnabled })=>{
        (0, (0, parcelRequire("a8lIB")).markOnce)((0, (0, parcelRequire("a8lIB")).marks).ISSUE_NAVIGATOR_START);
        (0, $5uXOq.useLayoutEffect)(()=>{
            (0, (0, parcelRequire("a8lIB")).markOnce)((0, (0, parcelRequire("a8lIB")).marks).ISSUE_NAVIGATOR_END);
        }, []);
        const filterInput = searchInput ? $36b09ac9b73a27e7$var$getFilter(searchInput) : undefined;
        const issueQuery = (0, $8Uumt.usePreloadedQuery)((0, (0, parcelRequire("hTvlq")).default), queryReference);
        const issueDataCondition = (0, $8Uumt.useFragment)((0, (0, parcelRequire("l5FbD")).default), issueQuery);
        const refetchQueryOld = (0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false) ? null : issueDataCondition;
        const refetchQueryNew = (0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false) ? issueDataCondition : null;
        const [issueNavigatorRefetchDataOld, issueNavigatorRefetchOld] = (0, $8Uumt.useRefetchableFragment)((0, (0, parcelRequire("ln32M")).default), refetchQueryOld);
        const [issueNavigatorRefetchDataNew, issueNavigatorRefetchNew] = (0, $8Uumt.useRefetchableFragment)((0, (0, parcelRequire("4LFGS")).default), refetchQueryNew);
        const { data: issueConnectionData, loadNext: loadNext, loadPrevious: loadPrevious, hasNext: hasNext, hasPrevious: hasPrevious, isLoadingNext: isLoadingNext, isLoadingPrevious: isLoadingPrevious } = (0, $8Uumt.usePaginationFragment)((0, (0, parcelRequire("ai5n6")).default), issueNavigatorRefetchDataNew ?? null);
        const userPreferencesData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("7uwEc")).default), issueQuery.jira?.userPreferences ?? null);
        const jqlBuilderWithAiData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("1nibn")).default), issueQuery.jira);
        const issueResultsDataOld = issueNavigatorRefetchDataOld?.jira?.issueSearchStable ?? null;
        const viewResultDataOld = issueNavigatorRefetchDataOld?.jira?.issueSearchViewResult ?? null;
        const issueSearchViewDataOld = viewResultDataOld?.__typename === 'JiraIssueSearchView' ? viewResultDataOld : null;
        const issueResultsDataNew = issueConnectionData?.jira?.issueSearch ?? null;
        const viewResultDataNew = issueNavigatorRefetchDataNew?.jira?.issueSearchViewResult ?? null;
        const issueSearchViewDataNew = viewResultDataNew?.__typename === 'JiraIssueSearchView' ? viewResultDataNew : null;
        let issueResultsData;
        let viewResultData;
        let issueSearchViewData;
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
            issueResultsData = issueResultsDataNew;
            viewResultData = viewResultDataNew;
            issueSearchViewData = issueSearchViewDataNew;
        } else {
            issueResultsData = issueResultsDataOld;
            viewResultData = viewResultDataOld;
            issueSearchViewData = issueSearchViewDataOld;
        }
        let groupResultsData = null;
        if ((0, (0, parcelRequire("4R6GH")).fg)('refactor_nin_to_jira_view_schema')) {
            const jiraViewResult = issueNavigatorRefetchDataNew?.jira?.jiraIssueSearchView;
            groupResultsData = jiraViewResult?.__typename === 'JiraGroupedListView' && jiraViewResult.groups ? jiraViewResult.groups : null;
        }
        const filterQuery = (0, $8Uumt.usePreloadedQuery)($36b09ac9b73a27e7$export$e111e6466962b82b, filterQueryReference);
        const [filterData, refetchFilter] = (0, $8Uumt.useRefetchableFragment)((0, (0, parcelRequire("7u5Tm")).default), filterQuery);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1z7Yr")).AppContextualAnalytics), null, (0, ($parcel$interopDefault($5uXOq))).createElement($36b09ac9b73a27e7$var$FilterAndJqlProvider, {
            refetch: refetchFilter,
            filterId: filterInput?.type === (0, (0, parcelRequire("6UAdF")).FilterTypes).ID ? filterInput?.value : undefined,
            filterJql: filterInput?.type === (0, (0, parcelRequire("6UAdF")).FilterTypes).JQL ? filterInput?.value : filterData?.jira?.filter?.jql,
            isFilterEditable: filterData.jira?.filter?.isEditable !== null ? filterData.jira?.filter?.isEditable : undefined,
            jql: searchInput?.type === (0, (0, parcelRequire("6UAdF")).SearchInputTypes).FILTER_AND_JQL || searchInput?.type === (0, (0, parcelRequire("6UAdF")).SearchInputTypes).JQL ? searchInput?.jql : undefined
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($36b09ac9b73a27e7$var$MaybeIssueSearchQueryProvider, {
            refetch: issueNavigatorRefetchOld,
            issueResults: issueResultsDataOld,
            view: issueSearchViewDataOld,
            query: (0, (0, parcelRequire("2CtcG")).default),
            onPageDataLoad: onPageDataLoad,
            onChangePage: onChangePage
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($36b09ac9b73a27e7$var$MaybeIssueSearch, {
            onPageDataLoad: onPageDataLoad,
            view: issueSearchViewDataNew,
            refetch: issueNavigatorRefetchNew,
            onLoadNext: loadNext,
            onLoadPrevious: loadPrevious,
            isLoadingNext: isLoadingNext,
            isLoadingPrevious: isLoadingPrevious,
            hasNext: hasNext,
            hasPrevious: hasPrevious,
            isIssueHierarchySupportEnabled: isIssueHierarchySupportEnabled
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("k4pU6")).SelectedViewContainer), {
            onSetView: onSetView,
            fragment: issueSearchViewData
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($36b09ac9b73a27e7$var$SelectedIssue, {
            selectedIssueKey: issueKey,
            onChange: onChangeIssue,
            issuesOld: issueResultsDataOld,
            issues: issueResultsDataNew
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("en3WN")).default), {
            CustomHeader: CustomHeader,
            ActionMenu: ActionMenu,
            HeaderSkeletonImage: HeaderSkeletonImage,
            filter: filterData?.jira?.filter ?? null,
            onChangeIssue: onChangeIssue,
            onChangeFilter: onChangeFilter,
            onPageDataLoad: onPageDataLoad,
            onChangeColumnConfiguration: onChangeColumnConfiguration,
            onChangeJql: onChangeJql,
            onRefinement: onRefinement,
            defaultJql: defaultJql,
            issueResults: issueResultsData,
            groupResults: groupResultsData,
            viewResult: viewResultData,
            project: issueQuery.jira?.jiraProjectByKey ?? null,
            userPreferences: userPreferencesData,
            jqlBuilderProps: jqlBuilderProps,
            jqlBuilderWithAiKey: jqlBuilderWithAiData,
            isFeedbackButtonDisabled: isFeedbackButtonDisabled,
            issueTableProps: issueTableProps,
            isIssueHierarchySupportEnabled: isIssueHierarchySupportEnabled
        })))))));
    };
    var $36b09ac9b73a27e7$export$2e2bcd8739ae039 = (0, $5uXOq.memo)($36b09ac9b73a27e7$var$IssueNavigator);
});
parcelRegister("7u5Tm", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$f9610eaebf63e0bf$export$2e2bcd8739ae039);
    var $2JKPT;
    const $f9610eaebf63e0bf$var$node = {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "filterAri"
            },
            {
                "kind": "RootArgument",
                "name": "includeFilter"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "refetch": {
                "fragmentPathInResult": [],
                "operation": (0, (0, parcelRequire("2JKPT")).default)
            }
        },
        "name": "main_issueNavigator_IssueNavigator_filterQuery",
        "selections": [
            {
                "concreteType": "JiraQuery",
                "kind": "LinkedField",
                "name": "jira",
                "plural": false,
                "selections": [
                    {
                        "condition": "includeFilter",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                            {
                                "args": [
                                    {
                                        "kind": "Variable",
                                        "name": "id",
                                        "variableName": "filterAri"
                                    }
                                ],
                                "kind": "LinkedField",
                                "name": "filter",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "ScalarField",
                                        "name": "jql"
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            {
                                                "kind": "ScalarField",
                                                "name": "isEditable"
                                            }
                                        ],
                                        "type": "JiraCustomFilter"
                                    },
                                    {
                                        "kind": "FragmentSpread",
                                        "name": "main_issueNavigator_Header_filter"
                                    },
                                    {
                                        "kind": "FragmentSpread",
                                        "name": "topBar_issueNavigator_filter"
                                    },
                                    {
                                        "kind": "FragmentSpread",
                                        "name": "jqlBuilder_issueNavigator_JQLBuilderWrapper_filter"
                                    },
                                    {
                                        "kind": "FragmentSpread",
                                        "name": "main_issueNavigator_ListView_filter"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ],
        "type": "Query"
    };
    $f9610eaebf63e0bf$var$node.hash = "26da718314f181d4824f62928229af2b";
    var $f9610eaebf63e0bf$export$2e2bcd8739ae039 = $f9610eaebf63e0bf$var$node;
});
parcelRegister("2JKPT", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$a4685b2a0e7385ab$export$2e2bcd8739ae039);
    const $a4685b2a0e7385ab$var$node = function() {
        var v0 = [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "filterAri"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "includeFilter"
            }
        ];
        return {
            "fragment": {
                "argumentDefinitions": v0,
                "kind": "Fragment",
                "name": "FilterRefetchQuery",
                "selections": [
                    {
                        "kind": "FragmentSpread",
                        "name": "main_issueNavigator_IssueNavigator_filterQuery"
                    }
                ],
                "type": "Query"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": v0,
                "kind": "Operation",
                "name": "FilterRefetchQuery",
                "selections": [
                    {
                        "concreteType": "JiraQuery",
                        "kind": "LinkedField",
                        "name": "jira",
                        "plural": false,
                        "selections": [
                            {
                                "condition": "includeFilter",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                    {
                                        "args": [
                                            {
                                                "kind": "Variable",
                                                "name": "id",
                                                "variableName": "filterAri"
                                            }
                                        ],
                                        "kind": "LinkedField",
                                        "name": "filter",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "ScalarField",
                                                "name": "__typename"
                                            },
                                            {
                                                "kind": "TypeDiscriminator",
                                                "abstractKey": "__isJiraFilter"
                                            },
                                            {
                                                "kind": "ScalarField",
                                                "name": "jql"
                                            },
                                            {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "isEditable"
                                                    }
                                                ],
                                                "type": "JiraCustomFilter"
                                            },
                                            {
                                                "kind": "ScalarField",
                                                "name": "filterId"
                                            },
                                            {
                                                "kind": "ScalarField",
                                                "name": "name"
                                            },
                                            {
                                                "kind": "ScalarField",
                                                "name": "isFavourite"
                                            },
                                            {
                                                "kind": "ScalarField",
                                                "name": "id"
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "params": {
                "id": "fa55db43feb181a2ea2d08440aa1fca705fd8ddbbea5388f6c28457a3933a61e",
                "metadata": {},
                "name": "FilterRefetchQuery",
                "operationKind": "query",
                "text": null
            }
        };
    }();
    $a4685b2a0e7385ab$var$node.hash = "26da718314f181d4824f62928229af2b";
    var $a4685b2a0e7385ab$export$2e2bcd8739ae039 = $a4685b2a0e7385ab$var$node;
});
parcelRegister("1nibn", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$81d0842de2b1cb5f$export$2e2bcd8739ae039);
    const $81d0842de2b1cb5f$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_IssueNavigator_jira",
        "selections": [
            {
                "kind": "FragmentSpread",
                "name": "main_issueNavigator_IssueNavigatorUI_jqlBuilderWithAiKey"
            }
        ],
        "type": "JiraQuery"
    };
    $81d0842de2b1cb5f$var$node.hash = "e871bd0eb341a6573bd1df76ea8e5df1";
    var $81d0842de2b1cb5f$export$2e2bcd8739ae039 = $81d0842de2b1cb5f$var$node;
});
parcelRegister("7uwEc", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$4652f62216686f4e$export$2e2bcd8739ae039);
    const $4652f62216686f4e$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_IssueNavigator_userPreferences",
        "selections": [
            {
                "kind": "FragmentSpread",
                "name": "main_issueNavigator_IssueNavigatorUI_userPreferences"
            }
        ],
        "type": "JiraUserPreferences"
    };
    $4652f62216686f4e$var$node.hash = "1143e00ad7112d3bc319370b0e0a4b27";
    var $4652f62216686f4e$export$2e2bcd8739ae039 = $4652f62216686f4e$var$node;
});
parcelRegister("ai5n6", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$8d5526f1028c0833$export$2e2bcd8739ae039);
    var $5iW7l;
    const $8d5526f1028c0833$var$node = function() {
        var v0 = [
            "jira",
            "issueSearch"
        ];
        return {
            "argumentDefinitions": [
                {
                    "kind": "RootArgument",
                    "name": "after"
                },
                {
                    "kind": "RootArgument",
                    "name": "amountOfColumns"
                },
                {
                    "kind": "RootArgument",
                    "name": "before"
                },
                {
                    "kind": "RootArgument",
                    "name": "cloudId"
                },
                {
                    "kind": "RootArgument",
                    "name": "fieldSetIds"
                },
                {
                    "kind": "RootArgument",
                    "name": "filterId"
                },
                {
                    "kind": "RootArgument",
                    "name": "first"
                },
                {
                    "kind": "RootArgument",
                    "name": "issueSearchInput"
                },
                {
                    "kind": "RootArgument",
                    "name": "issueTypeId"
                },
                {
                    "kind": "RootArgument",
                    "name": "last"
                },
                {
                    "kind": "RootArgument",
                    "name": "namespace"
                },
                {
                    "kind": "RootArgument",
                    "name": "options"
                },
                {
                    "kind": "RootArgument",
                    "name": "projectId"
                },
                {
                    "kind": "RootArgument",
                    "name": "shouldQueryFieldSetsById"
                },
                {
                    "kind": "RootArgument",
                    "name": "viewConfigInput"
                },
                {
                    "kind": "RootArgument",
                    "name": "viewId"
                }
            ],
            "kind": "Fragment",
            "metadata": {
                "connection": [
                    {
                        "count": null,
                        "cursor": null,
                        "direction": "bidirectional",
                        "path": v0
                    }
                ],
                "refetch": {
                    "connection": {
                        "forward": {
                            "count": "first",
                            "cursor": "after"
                        },
                        "backward": {
                            "count": "last",
                            "cursor": "before"
                        },
                        "path": v0
                    },
                    "fragmentPathInResult": [],
                    "operation": (0, (0, parcelRequire("5iW7l")).default)
                }
            },
            "name": "main_issueNavigator_IssueNavigator_issueSearchData",
            "selections": [
                {
                    "concreteType": "JiraQuery",
                    "kind": "LinkedField",
                    "name": "jira",
                    "plural": false,
                    "selections": [
                        {
                            "alias": "issueSearch",
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "cloudId",
                                    "variableName": "cloudId"
                                },
                                {
                                    "kind": "Variable",
                                    "name": "issueSearchInput",
                                    "variableName": "issueSearchInput"
                                },
                                {
                                    "kind": "Variable",
                                    "name": "options",
                                    "variableName": "options"
                                },
                                {
                                    "kind": "Variable",
                                    "name": "viewConfigInput",
                                    "variableName": "viewConfigInput"
                                }
                            ],
                            "concreteType": "JiraIssueConnection",
                            "kind": "LinkedField",
                            "name": "__IssueNavigatorIssueSearchPagination__issueSearch_connection",
                            "plural": false,
                            "selections": [
                                {
                                    "concreteType": "JiraIssueEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "name": "cursor"
                                        },
                                        {
                                            "concreteType": "JiraIssue",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "ScalarField",
                                                    "name": "__typename"
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "kind": "FragmentSpread",
                                    "name": "selectedIssue_issueNavigator"
                                },
                                {
                                    "kind": "FragmentSpread",
                                    "name": "main_issueNavigator_IssueNavigatorUI_issueResults"
                                },
                                {
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "name": "endCursor"
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "name": "hasNextPage"
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "name": "hasPreviousPage"
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "name": "startCursor"
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            "type": "Query"
        };
    }();
    $8d5526f1028c0833$var$node.hash = "bdcb9231bcd4f7f0baea2d7ced5b03de";
    var $8d5526f1028c0833$export$2e2bcd8739ae039 = $8d5526f1028c0833$var$node;
});
parcelRegister("5iW7l", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9c3d59a7ab62dfe5$export$2e2bcd8739ae039);
    var $hLeia;
    var $bqIzm;
    var $5oQtH;
    var $1ZvTA;
    var $G39h7;
    var $6vKPN;
    var $5y5Fx;
    var $1kHcz;
    const $9c3d59a7ab62dfe5$var$node = function() {
        var v0 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "after"
        }, v1 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "amountOfColumns"
        }, v2 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "before"
        }, v3 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cloudId"
        }, v4 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "fieldSetIds"
        }, v5 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "filterId"
        }, v6 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "first"
        }, v7 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "issueSearchInput"
        }, v8 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "issueTypeId"
        }, v9 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "last"
        }, v10 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "namespace"
        }, v11 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "options"
        }, v12 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "projectId"
        }, v13 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "shouldQueryFieldSetsById"
        }, v14 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "viewConfigInput"
        }, v15 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "viewId"
        }, v16 = [
            {
                "kind": "Variable",
                "name": "after",
                "variableName": "after"
            },
            {
                "kind": "Variable",
                "name": "before",
                "variableName": "before"
            },
            {
                "kind": "Variable",
                "name": "cloudId",
                "variableName": "cloudId"
            },
            {
                "kind": "Variable",
                "name": "first",
                "variableName": "first"
            },
            {
                "kind": "Variable",
                "name": "issueSearchInput",
                "variableName": "issueSearchInput"
            },
            {
                "kind": "Variable",
                "name": "last",
                "variableName": "last"
            },
            {
                "kind": "Variable",
                "name": "options",
                "variableName": "options"
            },
            {
                "kind": "Variable",
                "name": "viewConfigInput",
                "variableName": "viewConfigInput"
            }
        ], v17 = {
            "kind": "ScalarField",
            "name": "cursor"
        }, v18 = {
            "kind": "ScalarField",
            "name": "__typename"
        }, v19 = {
            "kind": "ScalarField",
            "name": "id"
        }, v20 = {
            "kind": "ScalarField",
            "name": "key"
        }, v21 = {
            "kind": "ScalarField",
            "name": "fieldId"
        }, v22 = {
            "kind": "ScalarField",
            "name": "statusCategoryId"
        }, v23 = {
            "concreteType": "JiraStatusCategory",
            "kind": "LinkedField",
            "name": "statusCategory",
            "plural": false,
            "selections": [
                v22,
                v19
            ]
        }, v24 = {
            "kind": "Variable",
            "name": "first",
            "variableName": "amountOfColumns"
        }, v25 = [
            {
                "fields": [
                    {
                        "fields": [
                            {
                                "kind": "Variable",
                                "name": "issueType",
                                "variableName": "issueTypeId"
                            },
                            {
                                "kind": "Variable",
                                "name": "project",
                                "variableName": "projectId"
                            }
                        ],
                        "kind": "ObjectValue",
                        "name": "projectContext"
                    }
                ],
                "kind": "ObjectValue",
                "name": "context"
            },
            {
                "kind": "Variable",
                "name": "filterId",
                "variableName": "filterId"
            },
            v24,
            {
                "kind": "Variable",
                "name": "namespace",
                "variableName": "namespace"
            },
            {
                "kind": "Variable",
                "name": "viewId",
                "variableName": "viewId"
            }
        ], v26 = {
            "kind": "ScalarField",
            "name": "name"
        }, v27 = {
            "kind": "ScalarField",
            "name": "text"
        }, v28 = {
            "kind": "ScalarField",
            "name": "picture"
        }, v29 = {
            "concreteType": "JiraAvatar",
            "kind": "LinkedField",
            "name": "avatar",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "small"
                }
            ]
        }, v30 = {
            "kind": "ScalarField",
            "name": "type"
        }, v31 = {
            "kind": "ScalarField",
            "name": "fieldSetId"
        }, v32 = {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isJiraIssueField"
        }, v33 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraAffectedServiceConnection",
                    "kind": "LinkedField",
                    "name": "selectedAffectedServicesConnection",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraAffectedServiceEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "concreteType": "JiraAffectedService",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "name": "serviceId"
                                        },
                                        v26,
                                        v19
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            "type": "JiraAffectedServicesField"
        }, v34 = {
            "kind": "ScalarField",
            "name": "message"
        }, v35 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraCmdbObjectConnection",
                    "kind": "LinkedField",
                    "name": "selectedCmdbObjectsConnection",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraCmdbObjectEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "concreteType": "JiraCmdbObject",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "name": "objectId"
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "name": "label"
                                        },
                                        {
                                            "concreteType": "JiraCmdbAvatar",
                                            "kind": "LinkedField",
                                            "name": "avatar",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider",
                                                    "kind": "Condition",
                                                    "passingValue": true,
                                                    "selections": [
                                                        {
                                                            "concreteType": "JiraCmdbMediaClientConfig",
                                                            "kind": "LinkedField",
                                                            "name": "mediaClientConfig",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "kind": "ScalarField",
                                                                    "name": "clientId"
                                                                },
                                                                {
                                                                    "kind": "ScalarField",
                                                                    "name": "fileId"
                                                                },
                                                                {
                                                                    "kind": "ScalarField",
                                                                    "name": "mediaBaseUrl"
                                                                },
                                                                {
                                                                    "kind": "ScalarField",
                                                                    "name": "mediaJwtToken"
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "name": "url48"
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "concreteType": "QueryError",
                            "kind": "LinkedField",
                            "name": "errors",
                            "plural": true,
                            "selections": [
                                v34
                            ]
                        }
                    ]
                }
            ],
            "type": "JiraCMDBField"
        }, v36 = [
            v26,
            v19
        ], v37 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraComponentConnection",
                    "kind": "LinkedField",
                    "name": "selectedComponentsConnection",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraComponentEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "concreteType": "JiraComponent",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": v36
                                }
                            ]
                        }
                    ]
                }
            ],
            "type": "JiraComponentsField"
        }, v38 = {
            "kind": "ScalarField",
            "name": "date"
        }, v39 = {
            "concreteType": "JiraFieldConfig",
            "kind": "LinkedField",
            "name": "fieldConfig",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "isRequired"
                }
            ]
        }, v40 = {
            "kind": "ScalarField",
            "name": "lazyIsEditableInIssueView"
        }, v41 = {
            "kind": "ClientExtension",
            "selections": [
                v40
            ]
        }, v42 = {
            "kind": "InlineFragment",
            "selections": [
                v38,
                v26,
                v39,
                v41
            ],
            "type": "JiraDatePickerField"
        }, v43 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "dateTime"
                }
            ],
            "type": "JiraDateTimePickerField"
        }, v44 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraEpic",
                    "kind": "LinkedField",
                    "name": "epic",
                    "plural": false,
                    "selections": [
                        v20,
                        {
                            "kind": "ScalarField",
                            "name": "summary"
                        },
                        {
                            "kind": "ScalarField",
                            "name": "color"
                        },
                        v19
                    ]
                }
            ],
            "type": "JiraEpicLinkField"
        }, v45 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "renderedFieldHtml"
                }
            ],
            "type": "JiraFallbackField"
        }, v46 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraStatusCategory",
                    "kind": "LinkedField",
                    "name": "statusCategory",
                    "plural": false,
                    "selections": [
                        v26,
                        v22,
                        v19
                    ]
                }
            ],
            "type": "JiraStatusCategoryField"
        }, v47 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraStatus",
                    "kind": "LinkedField",
                    "name": "status",
                    "plural": false,
                    "selections": [
                        v26,
                        {
                            "kind": "ScalarField",
                            "name": "statusId"
                        },
                        v23,
                        v19
                    ]
                }
            ],
            "type": "JiraStatusField"
        }, v48 = [
            v26
        ], v49 = {
            "kind": "ScalarField",
            "name": "totalCount"
        }, v50 = {
            "args": [
                {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1000
                }
            ],
            "concreteType": "JiraLabelConnection",
            "kind": "LinkedField",
            "name": "selectedLabelsConnection",
            "plural": false,
            "selections": [
                {
                    "concreteType": "JiraLabelEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "concreteType": "JiraLabel",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": v48
                        }
                    ]
                },
                v49
            ],
            "storageKey": "selectedLabelsConnection(first:1000)"
        }, v51 = {
            "kind": "InlineFragment",
            "selections": [
                v50,
                v26,
                v41
            ],
            "type": "JiraLabelsField"
        }, v52 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraIssueType",
                    "kind": "LinkedField",
                    "name": "issueType",
                    "plural": false,
                    "selections": [
                        v29,
                        v26,
                        v19
                    ]
                }
            ],
            "type": "JiraIssueTypeField"
        }, v53 = {
            "concreteType": "JiraAvatar",
            "kind": "LinkedField",
            "name": "avatar",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "xsmall"
                }
            ]
        }, v54 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraIssue",
                    "kind": "LinkedField",
                    "name": "parentIssue",
                    "plural": false,
                    "selections": [
                        v20,
                        {
                            "concreteType": "JiraSingleLineTextField",
                            "kind": "LinkedField",
                            "name": "summaryField",
                            "plural": false,
                            "selections": [
                                v27,
                                v19
                            ]
                        },
                        v19,
                        {
                            "concreteType": "JiraColorField",
                            "kind": "LinkedField",
                            "name": "issueColorField",
                            "plural": false,
                            "selections": [
                                {
                                    "concreteType": "JiraColor",
                                    "kind": "LinkedField",
                                    "name": "color",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "name": "colorKey"
                                        },
                                        v19
                                    ]
                                },
                                v19
                            ]
                        },
                        {
                            "concreteType": "JiraIssueTypeField",
                            "kind": "LinkedField",
                            "name": "issueTypeField",
                            "plural": false,
                            "selections": [
                                {
                                    "concreteType": "JiraIssueType",
                                    "kind": "LinkedField",
                                    "name": "issueType",
                                    "plural": false,
                                    "selections": [
                                        v53,
                                        v26,
                                        v19
                                    ]
                                },
                                v19
                            ]
                        }
                    ]
                }
            ],
            "type": "JiraParentIssueField"
        }, v55 = {
            "concreteType": "JiraPriority",
            "kind": "LinkedField",
            "name": "priority",
            "plural": false,
            "selections": [
                v26,
                {
                    "kind": "ScalarField",
                    "name": "iconUrl"
                },
                v19
            ]
        }, v56 = {
            "kind": "InlineFragment",
            "selections": [
                v55,
                v26,
                v41
            ],
            "type": "JiraPriorityField"
        }, v57 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                        v26,
                        v20,
                        {
                            "concreteType": "JiraAvatar",
                            "kind": "LinkedField",
                            "name": "avatar",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "name": "large"
                                }
                            ]
                        },
                        v19
                    ]
                }
            ],
            "type": "JiraProjectField"
        }, v58 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraResolution",
                    "kind": "LinkedField",
                    "name": "resolution",
                    "plural": false,
                    "selections": v36
                }
            ],
            "type": "JiraResolutionField"
        }, v59 = {
            "concreteType": "JiraSprintConnection",
            "kind": "LinkedField",
            "name": "selectedSprintsConnection",
            "plural": false,
            "selections": [
                {
                    "concreteType": "JiraSprintEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "concreteType": "JiraSprint",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                v19,
                                {
                                    "kind": "ScalarField",
                                    "name": "sprintId"
                                },
                                v26,
                                {
                                    "kind": "ScalarField",
                                    "name": "state"
                                },
                                {
                                    "kind": "ScalarField",
                                    "name": "endDate"
                                }
                            ]
                        }
                    ]
                }
            ]
        }, v60 = {
            "kind": "InlineFragment",
            "selections": [
                v59,
                v26,
                v41
            ],
            "type": "JiraSprintField"
        }, v61 = {
            "kind": "ScalarField",
            "name": "accountId"
        }, v62 = {
            "kind": "ScalarField",
            "name": "accountStatus"
        }, v63 = {
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
                v18,
                v61,
                v28,
                v26,
                v19,
                v62
            ]
        }, v64 = {
            "kind": "InlineFragment",
            "selections": [
                v26,
                {
                    "concreteType": "JiraVersionConnection",
                    "kind": "LinkedField",
                    "name": "selectedVersionsConnection",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraVersionEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "concreteType": "JiraVersion",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": v36
                                }
                            ]
                        }
                    ]
                }
            ],
            "type": "JiraMultipleVersionPickerField"
        }, v65 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraRichText",
                    "kind": "LinkedField",
                    "name": "richText",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraADF",
                            "kind": "LinkedField",
                            "name": "adfValue",
                            "plural": false,
                            "selections": [
                                {
                                    "concreteType": "JiraAdfToConvertedPlainText",
                                    "kind": "LinkedField",
                                    "name": "convertedPlainText",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "name": "plainText"
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            "type": "JiraRichTextField"
        }, v66 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraServiceManagementRequestType",
                    "kind": "LinkedField",
                    "name": "requestType",
                    "plural": false,
                    "selections": [
                        v26,
                        v53,
                        v19
                    ]
                }
            ],
            "type": "JiraServiceManagementRequestTypeField"
        }, v67 = {
            "kind": "ScalarField",
            "name": "hasNextPage"
        }, v68 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "first",
                            "variableName": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
                        }
                    ],
                    "concreteType": "JiraIssueLinkConnection",
                    "kind": "LinkedField",
                    "name": "issueLinkConnection",
                    "plural": false,
                    "selections": [
                        {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
                            "kind": "Condition",
                            "passingValue": false,
                            "selections": [
                                {
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                        v67
                                    ]
                                }
                            ]
                        },
                        {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                                v49
                            ]
                        },
                        {
                            "concreteType": "JiraIssueLinkEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "concreteType": "JiraIssueLink",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "concreteType": "JiraIssue",
                                            "kind": "LinkedField",
                                            "name": "issue",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "ScalarField",
                                                    "name": "webUrl"
                                                },
                                                v20,
                                                {
                                                    "args": [
                                                        {
                                                            "kind": "Literal",
                                                            "name": "ids",
                                                            "value": [
                                                                "statusCategory"
                                                            ]
                                                        }
                                                    ],
                                                    "concreteType": "JiraIssueFieldConnection",
                                                    "kind": "LinkedField",
                                                    "name": "fieldsById",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "concreteType": "JiraIssueFieldEdge",
                                                            "kind": "LinkedField",
                                                            "name": "edges",
                                                            "plural": true,
                                                            "selections": [
                                                                {
                                                                    "kind": "LinkedField",
                                                                    "name": "node",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        v18,
                                                                        {
                                                                            "kind": "InlineFragment",
                                                                            "selections": [
                                                                                v23
                                                                            ],
                                                                            "type": "JiraStatusCategoryField"
                                                                        },
                                                                        v19
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ],
                                                    "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                                                },
                                                v19
                                            ]
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "name": "relationName"
                                        },
                                        v19
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            "type": "JiraIssueLinkField"
        }, v69 = {
            "kind": "ScalarField",
            "name": "isStoryPointField"
        }, v70 = {
            "kind": "ScalarField",
            "name": "number"
        }, v71 = {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
                {
                    "kind": "InlineFragment",
                    "selections": [
                        v69,
                        v70,
                        v26,
                        v41
                    ],
                    "type": "JiraNumberField"
                }
            ]
        }, v72 = {
            "condition": "shouldQueryFieldSetsById",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "fieldSetIds",
                            "variableName": "fieldSetIds"
                        },
                        v24
                    ],
                    "concreteType": "JiraIssueFieldSetConnection",
                    "kind": "LinkedField",
                    "name": "fieldSetsById",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraIssueFieldSetEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "concreteType": "JiraIssueFieldSet",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        v30,
                                        v31,
                                        {
                                            "concreteType": "JiraIssueFieldConnection",
                                            "kind": "LinkedField",
                                            "name": "fields",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "concreteType": "JiraIssueFieldEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                v18,
                                                                v21,
                                                                v30,
                                                                v32,
                                                                v19,
                                                                v33,
                                                                v35,
                                                                v37,
                                                                v42,
                                                                v43,
                                                                v44,
                                                                v45,
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        v27,
                                                                        v26,
                                                                        v41
                                                                    ],
                                                                    "type": "JiraSingleLineTextField"
                                                                },
                                                                v46,
                                                                v47,
                                                                v51,
                                                                v52,
                                                                v54,
                                                                v56,
                                                                v57,
                                                                v58,
                                                                v60,
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        v63,
                                                                        v26,
                                                                        v39,
                                                                        v41
                                                                    ],
                                                                    "type": "JiraSingleSelectUserPickerField"
                                                                },
                                                                v64,
                                                                v65,
                                                                v66,
                                                                v68,
                                                                v71
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }, v73 = {
            "condition": "shouldQueryFieldSetsById",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
                {
                    "args": v25,
                    "concreteType": "JiraIssueFieldSetConnection",
                    "kind": "LinkedField",
                    "name": "fieldSetsForIssueSearchView",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraIssueFieldSetEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "concreteType": "JiraIssueFieldSet",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        v30,
                                        v31,
                                        {
                                            "concreteType": "JiraIssueFieldConnection",
                                            "kind": "LinkedField",
                                            "name": "fields",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "concreteType": "JiraIssueFieldEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                v21,
                                                                v30,
                                                                v32,
                                                                v33,
                                                                v35,
                                                                v37,
                                                                v42,
                                                                v43,
                                                                v44,
                                                                v45,
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        v30,
                                                                        v41
                                                                    ],
                                                                    "type": "JiraSingleLineTextField"
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        {
                                                                            "concreteType": "JiraStatusCategory",
                                                                            "kind": "LinkedField",
                                                                            "name": "statusCategory",
                                                                            "plural": false,
                                                                            "selections": v48
                                                                        }
                                                                    ],
                                                                    "type": "JiraStatusCategoryField"
                                                                },
                                                                v47,
                                                                v51,
                                                                v54,
                                                                v56,
                                                                v57,
                                                                v58,
                                                                v60,
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        v30,
                                                                        {
                                                                            "kind": "LinkedField",
                                                                            "name": "user",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                v61,
                                                                                v62
                                                                            ]
                                                                        },
                                                                        v39,
                                                                        v41
                                                                    ],
                                                                    "type": "JiraSingleSelectUserPickerField"
                                                                },
                                                                v64,
                                                                v65,
                                                                v66,
                                                                v68,
                                                                v71
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }, v74 = {
            "kind": "ScalarField",
            "name": "pageNumber"
        }, v75 = {
            "kind": "ScalarField",
            "name": "isCurrent"
        }, v76 = [
            v74,
            v17,
            v75
        ];
        return {
            "fragment": {
                "argumentDefinitions": [
                    v0,
                    v1,
                    v2,
                    v3,
                    v4,
                    v5,
                    v6,
                    v7,
                    v8,
                    v9,
                    v10,
                    v11,
                    v12,
                    v13,
                    v14,
                    v15
                ],
                "kind": "Fragment",
                "name": "IssueNavigatorIssueSearchPaginationQuery",
                "selections": [
                    {
                        "kind": "FragmentSpread",
                        "name": "main_issueNavigator_IssueNavigator_issueSearchData"
                    }
                ],
                "type": "Query"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": [
                    v0,
                    v1,
                    v2,
                    v3,
                    v4,
                    v5,
                    v6,
                    v7,
                    v8,
                    v9,
                    v10,
                    v11,
                    v12,
                    v13,
                    v14,
                    v15,
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
                    }
                ],
                "kind": "Operation",
                "name": "IssueNavigatorIssueSearchPaginationQuery",
                "selections": [
                    {
                        "concreteType": "JiraQuery",
                        "kind": "LinkedField",
                        "name": "jira",
                        "plural": false,
                        "selections": [
                            {
                                "args": v16,
                                "concreteType": "JiraIssueConnection",
                                "kind": "LinkedField",
                                "name": "issueSearch",
                                "plural": false,
                                "selections": [
                                    {
                                        "concreteType": "JiraIssueEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                            v17,
                                            {
                                                "concreteType": "JiraIssue",
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                    v18,
                                                    v19,
                                                    v20,
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "issueId"
                                                    },
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "isResolved"
                                                    },
                                                    {
                                                        "args": [
                                                            {
                                                                "kind": "Literal",
                                                                "name": "ids",
                                                                "value": [
                                                                    "status"
                                                                ]
                                                            }
                                                        ],
                                                        "concreteType": "JiraIssueFieldConnection",
                                                        "kind": "LinkedField",
                                                        "name": "fieldsById",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "concreteType": "JiraIssueFieldEdge",
                                                                "kind": "LinkedField",
                                                                "name": "edges",
                                                                "plural": true,
                                                                "selections": [
                                                                    {
                                                                        "kind": "LinkedField",
                                                                        "name": "node",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            v18,
                                                                            {
                                                                                "kind": "InlineFragment",
                                                                                "selections": [
                                                                                    v21,
                                                                                    {
                                                                                        "concreteType": "JiraStatus",
                                                                                        "kind": "LinkedField",
                                                                                        "name": "status",
                                                                                        "plural": false,
                                                                                        "selections": [
                                                                                            v23,
                                                                                            v19
                                                                                        ]
                                                                                    }
                                                                                ],
                                                                                "type": "JiraStatusField"
                                                                            },
                                                                            v19
                                                                        ]
                                                                    }
                                                                ]
                                                            }
                                                        ],
                                                        "storageKey": "fieldsById(ids:[\"status\"])"
                                                    },
                                                    {
                                                        "args": v25,
                                                        "concreteType": "JiraIssueFieldSetConnection",
                                                        "kind": "LinkedField",
                                                        "name": "fieldSetsForIssueSearchView",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "concreteType": "JiraIssueFieldSetEdge",
                                                                "kind": "LinkedField",
                                                                "name": "edges",
                                                                "plural": true,
                                                                "selections": [
                                                                    {
                                                                        "concreteType": "JiraIssueFieldSet",
                                                                        "kind": "LinkedField",
                                                                        "name": "node",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            {
                                                                                "concreteType": "JiraIssueFieldConnection",
                                                                                "kind": "LinkedField",
                                                                                "name": "fields",
                                                                                "plural": false,
                                                                                "selections": [
                                                                                    {
                                                                                        "concreteType": "JiraIssueFieldEdge",
                                                                                        "kind": "LinkedField",
                                                                                        "name": "edges",
                                                                                        "plural": true,
                                                                                        "selections": [
                                                                                            {
                                                                                                "kind": "LinkedField",
                                                                                                "name": "node",
                                                                                                "plural": false,
                                                                                                "selections": [
                                                                                                    v18,
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            v21,
                                                                                                            v26,
                                                                                                            v27
                                                                                                        ],
                                                                                                        "type": "JiraSingleLineTextField"
                                                                                                    },
                                                                                                    v19,
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            v21,
                                                                                                            v26,
                                                                                                            {
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "user",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    v18,
                                                                                                                    v26,
                                                                                                                    v28,
                                                                                                                    v19
                                                                                                                ]
                                                                                                            }
                                                                                                        ],
                                                                                                        "type": "JiraSingleSelectUserPickerField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            v21,
                                                                                                            {
                                                                                                                "concreteType": "JiraIssueType",
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "issueType",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    v26,
                                                                                                                    v29,
                                                                                                                    v19
                                                                                                                ]
                                                                                                            }
                                                                                                        ],
                                                                                                        "type": "JiraIssueTypeField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            v21,
                                                                                                            v23
                                                                                                        ],
                                                                                                        "type": "JiraStatusCategoryField"
                                                                                                    }
                                                                                                ]
                                                                                            }
                                                                                        ]
                                                                                    }
                                                                                ]
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider",
                                                        "kind": "Condition",
                                                        "passingValue": true,
                                                        "selections": [
                                                            {
                                                                "kind": "ScalarField",
                                                                "name": "hasChildren"
                                                            },
                                                            {
                                                                "concreteType": "JiraIssueTypeField",
                                                                "kind": "LinkedField",
                                                                "name": "issueTypeField",
                                                                "plural": false,
                                                                "selections": [
                                                                    {
                                                                        "concreteType": "JiraIssueType",
                                                                        "kind": "LinkedField",
                                                                        "name": "issueType",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            {
                                                                                "concreteType": "JiraIssueTypeHierarchyLevel",
                                                                                "kind": "LinkedField",
                                                                                "name": "hierarchy",
                                                                                "plural": false,
                                                                                "selections": [
                                                                                    {
                                                                                        "kind": "ScalarField",
                                                                                        "name": "level"
                                                                                    }
                                                                                ]
                                                                            },
                                                                            v19
                                                                        ]
                                                                    },
                                                                    v19
                                                                ]
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider",
                                                        "kind": "Condition",
                                                        "passingValue": false,
                                                        "selections": [
                                                            v72,
                                                            v73
                                                        ]
                                                    },
                                                    {
                                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider",
                                                        "kind": "Condition",
                                                        "passingValue": true,
                                                        "selections": [
                                                            v72,
                                                            v73,
                                                            {
                                                                "kind": "ClientExtension",
                                                                "selections": [
                                                                    {
                                                                        "concreteType": "JiraIssueFieldSetConnection",
                                                                        "kind": "LinkedField",
                                                                        "name": "issueRowFieldSets",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            {
                                                                                "concreteType": "JiraIssueFieldSetEdge",
                                                                                "kind": "LinkedField",
                                                                                "name": "edges",
                                                                                "plural": true,
                                                                                "selections": [
                                                                                    {
                                                                                        "concreteType": "JiraIssueFieldSet",
                                                                                        "kind": "LinkedField",
                                                                                        "name": "node",
                                                                                        "plural": false,
                                                                                        "selections": [
                                                                                            v30,
                                                                                            v31,
                                                                                            {
                                                                                                "concreteType": "JiraIssueFieldConnection",
                                                                                                "kind": "LinkedField",
                                                                                                "name": "fields",
                                                                                                "plural": false,
                                                                                                "selections": [
                                                                                                    {
                                                                                                        "concreteType": "JiraIssueFieldEdge",
                                                                                                        "kind": "LinkedField",
                                                                                                        "name": "edges",
                                                                                                        "plural": true,
                                                                                                        "selections": [
                                                                                                            {
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "node",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    v18,
                                                                                                                    v21,
                                                                                                                    v30,
                                                                                                                    v32,
                                                                                                                    v19,
                                                                                                                    v33,
                                                                                                                    v35,
                                                                                                                    v37,
                                                                                                                    {
                                                                                                                        "kind": "InlineFragment",
                                                                                                                        "selections": [
                                                                                                                            v38,
                                                                                                                            v40,
                                                                                                                            v26,
                                                                                                                            v39
                                                                                                                        ],
                                                                                                                        "type": "JiraDatePickerField"
                                                                                                                    },
                                                                                                                    v43,
                                                                                                                    v44,
                                                                                                                    v45,
                                                                                                                    {
                                                                                                                        "kind": "InlineFragment",
                                                                                                                        "selections": [
                                                                                                                            v27,
                                                                                                                            v40,
                                                                                                                            v26
                                                                                                                        ],
                                                                                                                        "type": "JiraSingleLineTextField"
                                                                                                                    },
                                                                                                                    v46,
                                                                                                                    v47,
                                                                                                                    {
                                                                                                                        "kind": "InlineFragment",
                                                                                                                        "selections": [
                                                                                                                            v50,
                                                                                                                            v40,
                                                                                                                            v26
                                                                                                                        ],
                                                                                                                        "type": "JiraLabelsField"
                                                                                                                    },
                                                                                                                    v52,
                                                                                                                    v54,
                                                                                                                    {
                                                                                                                        "kind": "InlineFragment",
                                                                                                                        "selections": [
                                                                                                                            v40,
                                                                                                                            v55,
                                                                                                                            v26
                                                                                                                        ],
                                                                                                                        "type": "JiraPriorityField"
                                                                                                                    },
                                                                                                                    v57,
                                                                                                                    v58,
                                                                                                                    {
                                                                                                                        "kind": "InlineFragment",
                                                                                                                        "selections": [
                                                                                                                            v40,
                                                                                                                            v59,
                                                                                                                            v26
                                                                                                                        ],
                                                                                                                        "type": "JiraSprintField"
                                                                                                                    },
                                                                                                                    {
                                                                                                                        "kind": "InlineFragment",
                                                                                                                        "selections": [
                                                                                                                            v63,
                                                                                                                            v40,
                                                                                                                            v26,
                                                                                                                            v39
                                                                                                                        ],
                                                                                                                        "type": "JiraSingleSelectUserPickerField"
                                                                                                                    },
                                                                                                                    v64,
                                                                                                                    v65,
                                                                                                                    v66,
                                                                                                                    v68,
                                                                                                                    {
                                                                                                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
                                                                                                                        "kind": "Condition",
                                                                                                                        "passingValue": true,
                                                                                                                        "selections": [
                                                                                                                            {
                                                                                                                                "kind": "InlineFragment",
                                                                                                                                "selections": [
                                                                                                                                    v69,
                                                                                                                                    v70,
                                                                                                                                    v40,
                                                                                                                                    v26
                                                                                                                                ],
                                                                                                                                "type": "JiraNumberField"
                                                                                                                            }
                                                                                                                        ]
                                                                                                                    }
                                                                                                                ]
                                                                                                            }
                                                                                                        ]
                                                                                                    }
                                                                                                ]
                                                                                            }
                                                                                        ]
                                                                                    }
                                                                                ]
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        "kind": "ClientExtension",
                                                        "selections": [
                                                            {
                                                                "kind": "ScalarField",
                                                                "name": "isHighlightedIssueRow"
                                                            }
                                                        ]
                                                    }
                                                ]
                                            },
                                            v18
                                        ]
                                    },
                                    {
                                        "concreteType": "JiraIssueNavigatorPageInfo",
                                        "kind": "LinkedField",
                                        "name": "issueNavigatorPageInfo",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "ScalarField",
                                                "name": "firstIssuePosition"
                                            },
                                            {
                                                "kind": "ScalarField",
                                                "name": "lastIssuePosition"
                                            }
                                        ]
                                    },
                                    {
                                        "args": [
                                            {
                                                "kind": "Literal",
                                                "name": "maxCursors",
                                                "value": 7
                                            }
                                        ],
                                        "concreteType": "JiraPageCursors",
                                        "kind": "LinkedField",
                                        "name": "pageCursors",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "concreteType": "JiraPageCursor",
                                                "kind": "LinkedField",
                                                "name": "around",
                                                "plural": true,
                                                "selections": v76
                                            },
                                            {
                                                "concreteType": "JiraPageCursor",
                                                "kind": "LinkedField",
                                                "name": "last",
                                                "plural": false,
                                                "selections": v76
                                            },
                                            {
                                                "concreteType": "JiraPageCursor",
                                                "kind": "LinkedField",
                                                "name": "first",
                                                "plural": false,
                                                "selections": [
                                                    v17,
                                                    v74,
                                                    v75
                                                ]
                                            }
                                        ],
                                        "storageKey": "pageCursors(maxCursors:7)"
                                    },
                                    {
                                        "kind": "ScalarField",
                                        "name": "totalIssueSearchResultCount"
                                    },
                                    {
                                        "kind": "LinkedField",
                                        "name": "issueSearchError",
                                        "plural": false,
                                        "selections": [
                                            v18,
                                            {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "messages"
                                                    }
                                                ],
                                                "type": "JiraInvalidJqlError"
                                            },
                                            {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                    v34,
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "errorType"
                                                    }
                                                ],
                                                "type": "JiraInvalidSyntaxError"
                                            }
                                        ]
                                    },
                                    {
                                        "kind": "ScalarField",
                                        "name": "isCappingIssueSearchResult"
                                    },
                                    {
                                        "concreteType": "PageInfo",
                                        "kind": "LinkedField",
                                        "name": "pageInfo",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "ScalarField",
                                                "name": "endCursor"
                                            },
                                            v67,
                                            {
                                                "kind": "ScalarField",
                                                "name": "hasPreviousPage"
                                            },
                                            {
                                                "kind": "ScalarField",
                                                "name": "startCursor"
                                            }
                                        ]
                                    },
                                    {
                                        "kind": "ClientExtension",
                                        "selections": [
                                            {
                                                "kind": "ScalarField",
                                                "name": "__id"
                                            }
                                        ]
                                    },
                                    {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": [
                                            {
                                                "concreteType": "JiraIssueEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                    {
                                                        "concreteType": "JiraIssue",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "args": v25,
                                                                "concreteType": "JiraIssueFieldSetConnection",
                                                                "kind": "LinkedField",
                                                                "name": "fieldSetsForIssueSearchView",
                                                                "plural": false,
                                                                "selections": [
                                                                    {
                                                                        "concreteType": "JiraIssueFieldSetEdge",
                                                                        "kind": "LinkedField",
                                                                        "name": "edges",
                                                                        "plural": true,
                                                                        "selections": [
                                                                            {
                                                                                "concreteType": "JiraIssueFieldSet",
                                                                                "kind": "LinkedField",
                                                                                "name": "node",
                                                                                "plural": false,
                                                                                "selections": [
                                                                                    {
                                                                                        "concreteType": "JiraIssueFieldConnection",
                                                                                        "kind": "LinkedField",
                                                                                        "name": "fields",
                                                                                        "plural": false,
                                                                                        "selections": [
                                                                                            {
                                                                                                "concreteType": "JiraIssueFieldEdge",
                                                                                                "kind": "LinkedField",
                                                                                                "name": "edges",
                                                                                                "plural": true,
                                                                                                "selections": [
                                                                                                    {
                                                                                                        "kind": "LinkedField",
                                                                                                        "name": "node",
                                                                                                        "plural": false,
                                                                                                        "selections": [
                                                                                                            v32,
                                                                                                            v21
                                                                                                        ]
                                                                                                    }
                                                                                                ]
                                                                                            }
                                                                                        ]
                                                                                    }
                                                                                ]
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "args": v16,
                                "filters": [
                                    "cloudId",
                                    "issueSearchInput",
                                    "options",
                                    "viewConfigInput"
                                ],
                                "handle": "connection",
                                "key": "IssueNavigatorIssueSearchPagination__issueSearch",
                                "kind": "LinkedHandle",
                                "name": "issueSearch"
                            }
                        ]
                    }
                ]
            },
            "params": {
                "id": "d9d8ac3393a5f801f0230f5c8f4cbe2ea9c173fe53d6563473bfd82a5c4a5532",
                "metadata": {},
                "name": "IssueNavigatorIssueSearchPaginationQuery",
                "operationKind": "query",
                "text": null,
                "providedVariables": {
                    "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider": (0, (0, parcelRequire("bqIzm")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider": (0, (0, parcelRequire("5y5Fx")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider": (0, (0, parcelRequire("6vKPN")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": (0, (0, parcelRequire("1kHcz")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider": (0, (0, parcelRequire("hLeia")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider": (0, (0, parcelRequire("G39h7")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider": (0, (0, parcelRequire("1ZvTA")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": (0, (0, parcelRequire("5oQtH")).default)
                }
            }
        };
    }();
    $9c3d59a7ab62dfe5$var$node.hash = "bdcb9231bcd4f7f0baea2d7ced5b03de";
    var $9c3d59a7ab62dfe5$export$2e2bcd8739ae039 = $9c3d59a7ab62dfe5$var$node;
});
parcelRegister("4LFGS", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$7e58808b90d1423b$export$2e2bcd8739ae039);
    var $kF1V3;
    const $7e58808b90d1423b$var$node = function() {
        var v0 = {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
        }, v1 = {
            "kind": "Variable",
            "name": "issueSearchInput",
            "variableName": "issueSearchInput"
        }, v2 = {
            "kind": "Variable",
            "name": "namespace",
            "variableName": "namespace"
        }, v3 = {
            "kind": "Variable",
            "name": "viewId",
            "variableName": "viewId"
        }, v4 = {
            "kind": "ScalarField",
            "name": "__typename"
        };
        return {
            "argumentDefinitions": [
                {
                    "kind": "RootArgument",
                    "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider"
                },
                {
                    "kind": "RootArgument",
                    "name": "after"
                },
                {
                    "kind": "RootArgument",
                    "name": "amountOfColumns"
                },
                {
                    "kind": "RootArgument",
                    "name": "before"
                },
                {
                    "kind": "RootArgument",
                    "name": "cloudId"
                },
                {
                    "kind": "RootArgument",
                    "name": "fieldSetIds"
                },
                {
                    "kind": "RootArgument",
                    "name": "filterId"
                },
                {
                    "kind": "RootArgument",
                    "name": "first"
                },
                {
                    "kind": "RootArgument",
                    "name": "issueSearchInput"
                },
                {
                    "kind": "RootArgument",
                    "name": "issueTypeId"
                },
                {
                    "kind": "RootArgument",
                    "name": "last"
                },
                {
                    "kind": "RootArgument",
                    "name": "namespace"
                },
                {
                    "kind": "RootArgument",
                    "name": "options"
                },
                {
                    "kind": "RootArgument",
                    "name": "projectId"
                },
                {
                    "kind": "RootArgument",
                    "name": "shouldQueryFieldSetsById"
                },
                {
                    "kind": "RootArgument",
                    "name": "staticViewInput"
                },
                {
                    "kind": "RootArgument",
                    "name": "viewConfigInput"
                },
                {
                    "kind": "RootArgument",
                    "name": "viewId"
                }
            ],
            "kind": "Fragment",
            "metadata": {
                "refetch": {
                    "fragmentPathInResult": [],
                    "operation": (0, (0, parcelRequire("kF1V3")).default)
                }
            },
            "name": "main_issueNavigator_IssueNavigator_refetchQueryNew",
            "selections": [
                {
                    "kind": "FragmentSpread",
                    "name": "main_issueNavigator_IssueNavigator_issueSearchData"
                },
                {
                    "concreteType": "JiraQuery",
                    "kind": "LinkedField",
                    "name": "jira",
                    "plural": false,
                    "selections": [
                        {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                                {
                                    "args": [
                                        v0,
                                        v1,
                                        v2,
                                        v3
                                    ],
                                    "kind": "LinkedField",
                                    "name": "jiraIssueSearchView",
                                    "plural": false,
                                    "selections": [
                                        v4,
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                {
                                                    "args": [
                                                        {
                                                            "kind": "Variable",
                                                            "name": "after",
                                                            "variableName": "after"
                                                        },
                                                        {
                                                            "kind": "Variable",
                                                            "name": "before",
                                                            "variableName": "before"
                                                        },
                                                        {
                                                            "kind": "Variable",
                                                            "name": "first",
                                                            "variableName": "first"
                                                        },
                                                        v1,
                                                        {
                                                            "kind": "Variable",
                                                            "name": "last",
                                                            "variableName": "last"
                                                        }
                                                    ],
                                                    "concreteType": "JiraSpreadsheetGroupConnection",
                                                    "kind": "LinkedField",
                                                    "name": "groups",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "kind": "FragmentSpread",
                                                            "name": "main_issueNavigator_IssueNavigatorUI_groupResults"
                                                        }
                                                    ]
                                                }
                                            ],
                                            "type": "JiraGroupedListView"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "args": [
                                v0,
                                {
                                    "kind": "Variable",
                                    "name": "filterId",
                                    "variableName": "filterId"
                                },
                                v1,
                                v2,
                                v3
                            ],
                            "kind": "LinkedField",
                            "name": "issueSearchViewResult",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        v4,
                                        {
                                            "kind": "FragmentSpread",
                                            "name": "issueSearch_issueNavigator"
                                        },
                                        {
                                            "kind": "FragmentSpread",
                                            "name": "selectedView_issueNavigator_SelectedViewContainer"
                                        }
                                    ],
                                    "type": "JiraIssueSearchView"
                                },
                                {
                                    "kind": "FragmentSpread",
                                    "name": "main_issueNavigator_IssueNavigatorUI_viewResult"
                                }
                            ]
                        }
                    ]
                }
            ],
            "type": "Query"
        };
    }();
    $7e58808b90d1423b$var$node.hash = "8e56b4674f49c8d00c94c738c6b308b9";
    var $7e58808b90d1423b$export$2e2bcd8739ae039 = $7e58808b90d1423b$var$node;
});
parcelRegister("kF1V3", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$a2b7fdc33d59789a$export$2e2bcd8739ae039);
    var $hLeia;
    var $bqIzm;
    var $5oQtH;
    var $1q6lK;
    var $1ZvTA;
    var $G39h7;
    var $6vKPN;
    var $5y5Fx;
    var $1kHcz;
    const $a2b7fdc33d59789a$var$node = function() {
        var v0 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "after"
        }, v1 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "amountOfColumns"
        }, v2 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "before"
        }, v3 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cloudId"
        }, v4 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "fieldSetIds"
        }, v5 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "filterId"
        }, v6 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "first"
        }, v7 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "isRefactorNinToViewSchemaEnabled"
        }, v8 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "issueSearchInput"
        }, v9 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "issueTypeId"
        }, v10 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "last"
        }, v11 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "namespace"
        }, v12 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "options"
        }, v13 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "projectId"
        }, v14 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "shouldQueryFieldSetsById"
        }, v15 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "staticViewInput"
        }, v16 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "viewConfigInput"
        }, v17 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "viewId"
        }, v18 = {
            "kind": "Variable",
            "name": "after",
            "variableName": "after"
        }, v19 = {
            "kind": "Variable",
            "name": "before",
            "variableName": "before"
        }, v20 = {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
        }, v21 = {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
        }, v22 = {
            "kind": "Variable",
            "name": "issueSearchInput",
            "variableName": "issueSearchInput"
        }, v23 = {
            "kind": "Variable",
            "name": "last",
            "variableName": "last"
        }, v24 = [
            v18,
            v19,
            v20,
            v21,
            v22,
            v23,
            {
                "kind": "Variable",
                "name": "options",
                "variableName": "options"
            },
            {
                "kind": "Variable",
                "name": "viewConfigInput",
                "variableName": "viewConfigInput"
            }
        ], v25 = {
            "kind": "ScalarField",
            "name": "cursor"
        }, v26 = {
            "kind": "ScalarField",
            "name": "__typename"
        }, v27 = {
            "kind": "ScalarField",
            "name": "id"
        }, v28 = {
            "kind": "ScalarField",
            "name": "key"
        }, v29 = {
            "kind": "ScalarField",
            "name": "issueId"
        }, v30 = {
            "kind": "ScalarField",
            "name": "isResolved"
        }, v31 = {
            "kind": "ScalarField",
            "name": "fieldId"
        }, v32 = {
            "kind": "ScalarField",
            "name": "statusCategoryId"
        }, v33 = {
            "concreteType": "JiraStatusCategory",
            "kind": "LinkedField",
            "name": "statusCategory",
            "plural": false,
            "selections": [
                v32,
                v27
            ]
        }, v34 = {
            "args": [
                {
                    "kind": "Literal",
                    "name": "ids",
                    "value": [
                        "status"
                    ]
                }
            ],
            "concreteType": "JiraIssueFieldConnection",
            "kind": "LinkedField",
            "name": "fieldsById",
            "plural": false,
            "selections": [
                {
                    "concreteType": "JiraIssueFieldEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                v26,
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        v31,
                                        {
                                            "concreteType": "JiraStatus",
                                            "kind": "LinkedField",
                                            "name": "status",
                                            "plural": false,
                                            "selections": [
                                                v33,
                                                v27
                                            ]
                                        }
                                    ],
                                    "type": "JiraStatusField"
                                },
                                v27
                            ]
                        }
                    ]
                }
            ],
            "storageKey": "fieldsById(ids:[\"status\"])"
        }, v35 = {
            "kind": "Variable",
            "name": "filterId",
            "variableName": "filterId"
        }, v36 = {
            "kind": "Variable",
            "name": "first",
            "variableName": "amountOfColumns"
        }, v37 = {
            "kind": "Variable",
            "name": "namespace",
            "variableName": "namespace"
        }, v38 = {
            "kind": "Variable",
            "name": "viewId",
            "variableName": "viewId"
        }, v39 = [
            {
                "fields": [
                    {
                        "fields": [
                            {
                                "kind": "Variable",
                                "name": "issueType",
                                "variableName": "issueTypeId"
                            },
                            {
                                "kind": "Variable",
                                "name": "project",
                                "variableName": "projectId"
                            }
                        ],
                        "kind": "ObjectValue",
                        "name": "projectContext"
                    }
                ],
                "kind": "ObjectValue",
                "name": "context"
            },
            v35,
            v36,
            v37,
            v38
        ], v40 = {
            "kind": "ScalarField",
            "name": "name"
        }, v41 = {
            "kind": "ScalarField",
            "name": "text"
        }, v42 = {
            "kind": "ScalarField",
            "name": "picture"
        }, v43 = {
            "concreteType": "JiraAvatar",
            "kind": "LinkedField",
            "name": "avatar",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "small"
                }
            ]
        }, v44 = {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "hasChildren"
                },
                {
                    "concreteType": "JiraIssueTypeField",
                    "kind": "LinkedField",
                    "name": "issueTypeField",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraIssueType",
                            "kind": "LinkedField",
                            "name": "issueType",
                            "plural": false,
                            "selections": [
                                {
                                    "concreteType": "JiraIssueTypeHierarchyLevel",
                                    "kind": "LinkedField",
                                    "name": "hierarchy",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "name": "level"
                                        }
                                    ]
                                },
                                v27
                            ]
                        },
                        v27
                    ]
                }
            ]
        }, v45 = {
            "kind": "ScalarField",
            "name": "type"
        }, v46 = {
            "kind": "ScalarField",
            "name": "fieldSetId"
        }, v47 = {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isJiraIssueField"
        }, v48 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraAffectedServiceConnection",
                    "kind": "LinkedField",
                    "name": "selectedAffectedServicesConnection",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraAffectedServiceEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "concreteType": "JiraAffectedService",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "name": "serviceId"
                                        },
                                        v40,
                                        v27
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            "type": "JiraAffectedServicesField"
        }, v49 = {
            "kind": "ScalarField",
            "name": "message"
        }, v50 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraCmdbObjectConnection",
                    "kind": "LinkedField",
                    "name": "selectedCmdbObjectsConnection",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraCmdbObjectEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "concreteType": "JiraCmdbObject",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "name": "objectId"
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "name": "label"
                                        },
                                        {
                                            "concreteType": "JiraCmdbAvatar",
                                            "kind": "LinkedField",
                                            "name": "avatar",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider",
                                                    "kind": "Condition",
                                                    "passingValue": true,
                                                    "selections": [
                                                        {
                                                            "concreteType": "JiraCmdbMediaClientConfig",
                                                            "kind": "LinkedField",
                                                            "name": "mediaClientConfig",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "kind": "ScalarField",
                                                                    "name": "clientId"
                                                                },
                                                                {
                                                                    "kind": "ScalarField",
                                                                    "name": "fileId"
                                                                },
                                                                {
                                                                    "kind": "ScalarField",
                                                                    "name": "mediaBaseUrl"
                                                                },
                                                                {
                                                                    "kind": "ScalarField",
                                                                    "name": "mediaJwtToken"
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "name": "url48"
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "concreteType": "QueryError",
                            "kind": "LinkedField",
                            "name": "errors",
                            "plural": true,
                            "selections": [
                                v49
                            ]
                        }
                    ]
                }
            ],
            "type": "JiraCMDBField"
        }, v51 = [
            v40,
            v27
        ], v52 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraComponentConnection",
                    "kind": "LinkedField",
                    "name": "selectedComponentsConnection",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraComponentEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "concreteType": "JiraComponent",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": v51
                                }
                            ]
                        }
                    ]
                }
            ],
            "type": "JiraComponentsField"
        }, v53 = {
            "kind": "ScalarField",
            "name": "date"
        }, v54 = {
            "concreteType": "JiraFieldConfig",
            "kind": "LinkedField",
            "name": "fieldConfig",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "isRequired"
                }
            ]
        }, v55 = {
            "kind": "ScalarField",
            "name": "lazyIsEditableInIssueView"
        }, v56 = {
            "kind": "ClientExtension",
            "selections": [
                v55
            ]
        }, v57 = {
            "kind": "InlineFragment",
            "selections": [
                v53,
                v40,
                v54,
                v56
            ],
            "type": "JiraDatePickerField"
        }, v58 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "dateTime"
                }
            ],
            "type": "JiraDateTimePickerField"
        }, v59 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraEpic",
                    "kind": "LinkedField",
                    "name": "epic",
                    "plural": false,
                    "selections": [
                        v28,
                        {
                            "kind": "ScalarField",
                            "name": "summary"
                        },
                        {
                            "kind": "ScalarField",
                            "name": "color"
                        },
                        v27
                    ]
                }
            ],
            "type": "JiraEpicLinkField"
        }, v60 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "renderedFieldHtml"
                }
            ],
            "type": "JiraFallbackField"
        }, v61 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraStatusCategory",
                    "kind": "LinkedField",
                    "name": "statusCategory",
                    "plural": false,
                    "selections": [
                        v40,
                        v32,
                        v27
                    ]
                }
            ],
            "type": "JiraStatusCategoryField"
        }, v62 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraStatus",
                    "kind": "LinkedField",
                    "name": "status",
                    "plural": false,
                    "selections": [
                        v40,
                        {
                            "kind": "ScalarField",
                            "name": "statusId"
                        },
                        v33,
                        v27
                    ]
                }
            ],
            "type": "JiraStatusField"
        }, v63 = [
            v40
        ], v64 = {
            "kind": "ScalarField",
            "name": "totalCount"
        }, v65 = {
            "args": [
                {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1000
                }
            ],
            "concreteType": "JiraLabelConnection",
            "kind": "LinkedField",
            "name": "selectedLabelsConnection",
            "plural": false,
            "selections": [
                {
                    "concreteType": "JiraLabelEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "concreteType": "JiraLabel",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": v63
                        }
                    ]
                },
                v64
            ],
            "storageKey": "selectedLabelsConnection(first:1000)"
        }, v66 = {
            "kind": "InlineFragment",
            "selections": [
                v65,
                v40,
                v56
            ],
            "type": "JiraLabelsField"
        }, v67 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraIssueType",
                    "kind": "LinkedField",
                    "name": "issueType",
                    "plural": false,
                    "selections": [
                        v43,
                        v40,
                        v27
                    ]
                }
            ],
            "type": "JiraIssueTypeField"
        }, v68 = [
            {
                "concreteType": "JiraColor",
                "kind": "LinkedField",
                "name": "color",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "name": "colorKey"
                    },
                    v27
                ]
            },
            v27
        ], v69 = {
            "concreteType": "JiraAvatar",
            "kind": "LinkedField",
            "name": "avatar",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "xsmall"
                }
            ]
        }, v70 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraIssue",
                    "kind": "LinkedField",
                    "name": "parentIssue",
                    "plural": false,
                    "selections": [
                        v28,
                        {
                            "concreteType": "JiraSingleLineTextField",
                            "kind": "LinkedField",
                            "name": "summaryField",
                            "plural": false,
                            "selections": [
                                v41,
                                v27
                            ]
                        },
                        v27,
                        {
                            "concreteType": "JiraColorField",
                            "kind": "LinkedField",
                            "name": "issueColorField",
                            "plural": false,
                            "selections": v68
                        },
                        {
                            "concreteType": "JiraIssueTypeField",
                            "kind": "LinkedField",
                            "name": "issueTypeField",
                            "plural": false,
                            "selections": [
                                {
                                    "concreteType": "JiraIssueType",
                                    "kind": "LinkedField",
                                    "name": "issueType",
                                    "plural": false,
                                    "selections": [
                                        v69,
                                        v40,
                                        v27
                                    ]
                                },
                                v27
                            ]
                        }
                    ]
                }
            ],
            "type": "JiraParentIssueField"
        }, v71 = {
            "kind": "ScalarField",
            "name": "iconUrl"
        }, v72 = {
            "concreteType": "JiraPriority",
            "kind": "LinkedField",
            "name": "priority",
            "plural": false,
            "selections": [
                v40,
                v71,
                v27
            ]
        }, v73 = {
            "kind": "InlineFragment",
            "selections": [
                v72,
                v40,
                v56
            ],
            "type": "JiraPriorityField"
        }, v74 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                        v40,
                        v28,
                        {
                            "concreteType": "JiraAvatar",
                            "kind": "LinkedField",
                            "name": "avatar",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "name": "large"
                                }
                            ]
                        },
                        v27
                    ]
                }
            ],
            "type": "JiraProjectField"
        }, v75 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraResolution",
                    "kind": "LinkedField",
                    "name": "resolution",
                    "plural": false,
                    "selections": v51
                }
            ],
            "type": "JiraResolutionField"
        }, v76 = {
            "kind": "ScalarField",
            "name": "state"
        }, v77 = {
            "concreteType": "JiraSprintConnection",
            "kind": "LinkedField",
            "name": "selectedSprintsConnection",
            "plural": false,
            "selections": [
                {
                    "concreteType": "JiraSprintEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "concreteType": "JiraSprint",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                v27,
                                {
                                    "kind": "ScalarField",
                                    "name": "sprintId"
                                },
                                v40,
                                v76,
                                {
                                    "kind": "ScalarField",
                                    "name": "endDate"
                                }
                            ]
                        }
                    ]
                }
            ]
        }, v78 = {
            "kind": "InlineFragment",
            "selections": [
                v77,
                v40,
                v56
            ],
            "type": "JiraSprintField"
        }, v79 = {
            "kind": "ScalarField",
            "name": "accountId"
        }, v80 = {
            "kind": "ScalarField",
            "name": "accountStatus"
        }, v81 = {
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
                v26,
                v79,
                v42,
                v40,
                v27,
                v80
            ]
        }, v82 = {
            "kind": "InlineFragment",
            "selections": [
                v40,
                {
                    "concreteType": "JiraVersionConnection",
                    "kind": "LinkedField",
                    "name": "selectedVersionsConnection",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraVersionEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "concreteType": "JiraVersion",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": v51
                                }
                            ]
                        }
                    ]
                }
            ],
            "type": "JiraMultipleVersionPickerField"
        }, v83 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraRichText",
                    "kind": "LinkedField",
                    "name": "richText",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraADF",
                            "kind": "LinkedField",
                            "name": "adfValue",
                            "plural": false,
                            "selections": [
                                {
                                    "concreteType": "JiraAdfToConvertedPlainText",
                                    "kind": "LinkedField",
                                    "name": "convertedPlainText",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "name": "plainText"
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            "type": "JiraRichTextField"
        }, v84 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraServiceManagementRequestType",
                    "kind": "LinkedField",
                    "name": "requestType",
                    "plural": false,
                    "selections": [
                        v40,
                        v69,
                        v27
                    ]
                }
            ],
            "type": "JiraServiceManagementRequestTypeField"
        }, v85 = {
            "kind": "ScalarField",
            "name": "hasNextPage"
        }, v86 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "first",
                            "variableName": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
                        }
                    ],
                    "concreteType": "JiraIssueLinkConnection",
                    "kind": "LinkedField",
                    "name": "issueLinkConnection",
                    "plural": false,
                    "selections": [
                        {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
                            "kind": "Condition",
                            "passingValue": false,
                            "selections": [
                                {
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                        v85
                                    ]
                                }
                            ]
                        },
                        {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                                v64
                            ]
                        },
                        {
                            "concreteType": "JiraIssueLinkEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "concreteType": "JiraIssueLink",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "concreteType": "JiraIssue",
                                            "kind": "LinkedField",
                                            "name": "issue",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "ScalarField",
                                                    "name": "webUrl"
                                                },
                                                v28,
                                                {
                                                    "args": [
                                                        {
                                                            "kind": "Literal",
                                                            "name": "ids",
                                                            "value": [
                                                                "statusCategory"
                                                            ]
                                                        }
                                                    ],
                                                    "concreteType": "JiraIssueFieldConnection",
                                                    "kind": "LinkedField",
                                                    "name": "fieldsById",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "concreteType": "JiraIssueFieldEdge",
                                                            "kind": "LinkedField",
                                                            "name": "edges",
                                                            "plural": true,
                                                            "selections": [
                                                                {
                                                                    "kind": "LinkedField",
                                                                    "name": "node",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        v26,
                                                                        {
                                                                            "kind": "InlineFragment",
                                                                            "selections": [
                                                                                v33
                                                                            ],
                                                                            "type": "JiraStatusCategoryField"
                                                                        },
                                                                        v27
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ],
                                                    "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                                                },
                                                v27
                                            ]
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "name": "relationName"
                                        },
                                        v27
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            "type": "JiraIssueLinkField"
        }, v87 = {
            "kind": "ScalarField",
            "name": "isStoryPointField"
        }, v88 = {
            "kind": "ScalarField",
            "name": "number"
        }, v89 = {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
                {
                    "kind": "InlineFragment",
                    "selections": [
                        v87,
                        v88,
                        v40,
                        v56
                    ],
                    "type": "JiraNumberField"
                }
            ]
        }, v90 = [
            {
                "concreteType": "JiraIssueFieldSetEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                    {
                        "concreteType": "JiraIssueFieldSet",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                            v45,
                            v46,
                            {
                                "concreteType": "JiraIssueFieldConnection",
                                "kind": "LinkedField",
                                "name": "fields",
                                "plural": false,
                                "selections": [
                                    {
                                        "concreteType": "JiraIssueFieldEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                            {
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                    v26,
                                                    v31,
                                                    v45,
                                                    v47,
                                                    v27,
                                                    v48,
                                                    v50,
                                                    v52,
                                                    v57,
                                                    v58,
                                                    v59,
                                                    v60,
                                                    {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                            v41,
                                                            v40,
                                                            v56
                                                        ],
                                                        "type": "JiraSingleLineTextField"
                                                    },
                                                    v61,
                                                    v62,
                                                    v66,
                                                    v67,
                                                    v70,
                                                    v73,
                                                    v74,
                                                    v75,
                                                    v78,
                                                    {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                            v81,
                                                            v40,
                                                            v54,
                                                            v56
                                                        ],
                                                        "type": "JiraSingleSelectUserPickerField"
                                                    },
                                                    v82,
                                                    v83,
                                                    v84,
                                                    v86,
                                                    v89
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ], v91 = {
            "condition": "shouldQueryFieldSetsById",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "fieldSetIds",
                            "variableName": "fieldSetIds"
                        },
                        v36
                    ],
                    "concreteType": "JiraIssueFieldSetConnection",
                    "kind": "LinkedField",
                    "name": "fieldSetsById",
                    "plural": false,
                    "selections": v90
                }
            ]
        }, v92 = {
            "condition": "shouldQueryFieldSetsById",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
                {
                    "args": v39,
                    "concreteType": "JiraIssueFieldSetConnection",
                    "kind": "LinkedField",
                    "name": "fieldSetsForIssueSearchView",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraIssueFieldSetEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "concreteType": "JiraIssueFieldSet",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        v45,
                                        v46,
                                        {
                                            "concreteType": "JiraIssueFieldConnection",
                                            "kind": "LinkedField",
                                            "name": "fields",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "concreteType": "JiraIssueFieldEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                v31,
                                                                v45,
                                                                v47,
                                                                v48,
                                                                v50,
                                                                v52,
                                                                v57,
                                                                v58,
                                                                v59,
                                                                v60,
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        v45,
                                                                        v56
                                                                    ],
                                                                    "type": "JiraSingleLineTextField"
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        {
                                                                            "concreteType": "JiraStatusCategory",
                                                                            "kind": "LinkedField",
                                                                            "name": "statusCategory",
                                                                            "plural": false,
                                                                            "selections": v63
                                                                        }
                                                                    ],
                                                                    "type": "JiraStatusCategoryField"
                                                                },
                                                                v62,
                                                                v66,
                                                                v70,
                                                                v73,
                                                                v74,
                                                                v75,
                                                                v78,
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        v45,
                                                                        {
                                                                            "kind": "LinkedField",
                                                                            "name": "user",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                v79,
                                                                                v80
                                                                            ]
                                                                        },
                                                                        v54,
                                                                        v56
                                                                    ],
                                                                    "type": "JiraSingleSelectUserPickerField"
                                                                },
                                                                v82,
                                                                v83,
                                                                v84,
                                                                v86,
                                                                v89
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }, v93 = {
            "kind": "ClientExtension",
            "selections": [
                {
                    "concreteType": "JiraIssueFieldSetConnection",
                    "kind": "LinkedField",
                    "name": "issueRowFieldSets",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraIssueFieldSetEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "concreteType": "JiraIssueFieldSet",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        v45,
                                        v46,
                                        {
                                            "concreteType": "JiraIssueFieldConnection",
                                            "kind": "LinkedField",
                                            "name": "fields",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "concreteType": "JiraIssueFieldEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                v26,
                                                                v31,
                                                                v45,
                                                                v47,
                                                                v27,
                                                                v48,
                                                                v50,
                                                                v52,
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        v53,
                                                                        v55,
                                                                        v40,
                                                                        v54
                                                                    ],
                                                                    "type": "JiraDatePickerField"
                                                                },
                                                                v58,
                                                                v59,
                                                                v60,
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        v41,
                                                                        v55,
                                                                        v40
                                                                    ],
                                                                    "type": "JiraSingleLineTextField"
                                                                },
                                                                v61,
                                                                v62,
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        v65,
                                                                        v55,
                                                                        v40
                                                                    ],
                                                                    "type": "JiraLabelsField"
                                                                },
                                                                v67,
                                                                v70,
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        v55,
                                                                        v72,
                                                                        v40
                                                                    ],
                                                                    "type": "JiraPriorityField"
                                                                },
                                                                v74,
                                                                v75,
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        v55,
                                                                        v77,
                                                                        v40
                                                                    ],
                                                                    "type": "JiraSprintField"
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        v81,
                                                                        v55,
                                                                        v40,
                                                                        v54
                                                                    ],
                                                                    "type": "JiraSingleSelectUserPickerField"
                                                                },
                                                                v82,
                                                                v83,
                                                                v84,
                                                                v86,
                                                                {
                                                                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
                                                                    "kind": "Condition",
                                                                    "passingValue": true,
                                                                    "selections": [
                                                                        {
                                                                            "kind": "InlineFragment",
                                                                            "selections": [
                                                                                v87,
                                                                                v88,
                                                                                v55,
                                                                                v40
                                                                            ],
                                                                            "type": "JiraNumberField"
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }, v94 = {
            "kind": "ClientExtension",
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "isHighlightedIssueRow"
                }
            ]
        }, v95 = {
            "kind": "ScalarField",
            "name": "pageNumber"
        }, v96 = {
            "kind": "ScalarField",
            "name": "isCurrent"
        }, v97 = [
            v95,
            v25,
            v96
        ], v98 = {
            "kind": "ScalarField",
            "name": "endCursor"
        }, v99 = {
            "kind": "ClientExtension",
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "__id"
                }
            ]
        }, v100 = {
            "kind": "ScalarField",
            "name": "displayName"
        }, v101 = [
            {
                "kind": "Literal",
                "name": "first",
                "value": 50
            }
        ], v102 = {
            "condition": "shouldQueryFieldSetsById",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
                {
                    "args": v39,
                    "concreteType": "JiraIssueFieldSetConnection",
                    "kind": "LinkedField",
                    "name": "fieldSetsForIssueSearchView",
                    "plural": false,
                    "selections": v90
                }
            ]
        }, v103 = {
            "kind": "ScalarField",
            "name": "jql"
        }, v104 = {
            "kind": "ScalarField",
            "name": "fieldType"
        }, v105 = {
            "kind": "ScalarField",
            "name": "issueCount"
        }, v106 = {
            "kind": "LinkedField",
            "name": "fieldValue",
            "plural": false,
            "selections": [
                v26,
                {
                    "kind": "InlineFragment",
                    "selections": [
                        v100,
                        {
                            "concreteType": "JiraPriority",
                            "kind": "LinkedField",
                            "name": "priority",
                            "plural": false,
                            "selections": [
                                v71,
                                v27
                            ]
                        }
                    ],
                    "type": "JiraJqlPriorityFieldValue"
                },
                {
                    "kind": "InlineFragment",
                    "selections": [
                        v100,
                        {
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                v26,
                                v42,
                                v27
                            ]
                        }
                    ],
                    "type": "JiraJqlUserFieldValue"
                },
                {
                    "kind": "InlineFragment",
                    "selections": [
                        v100,
                        v33
                    ],
                    "type": "JiraJqlStatusFieldValue"
                },
                {
                    "kind": "InlineFragment",
                    "selections": [
                        v100,
                        {
                            "concreteType": "JiraOption",
                            "kind": "LinkedField",
                            "name": "option",
                            "plural": false,
                            "selections": v68
                        }
                    ],
                    "type": "JiraJqlOptionFieldValue"
                },
                {
                    "kind": "InlineFragment",
                    "selections": [
                        v100,
                        {
                            "concreteType": "JiraSprint",
                            "kind": "LinkedField",
                            "name": "sprint",
                            "plural": false,
                            "selections": [
                                v76,
                                v27
                            ]
                        }
                    ],
                    "type": "JiraJqlSprintFieldValue"
                }
            ]
        };
        return {
            "fragment": {
                "argumentDefinitions": [
                    v0,
                    v1,
                    v2,
                    v3,
                    v4,
                    v5,
                    v6,
                    v7,
                    v8,
                    v9,
                    v10,
                    v11,
                    v12,
                    v13,
                    v14,
                    v15,
                    v16,
                    v17
                ],
                "kind": "Fragment",
                "name": "IssueNavigatorIssueSearchRefetchQuery",
                "selections": [
                    {
                        "kind": "FragmentSpread",
                        "name": "main_issueNavigator_IssueNavigator_refetchQueryNew"
                    }
                ],
                "type": "Query"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": [
                    v0,
                    v1,
                    v2,
                    v3,
                    v4,
                    v5,
                    v6,
                    v7,
                    v8,
                    v9,
                    v10,
                    v11,
                    v12,
                    v13,
                    v14,
                    v15,
                    v16,
                    v17,
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
                    }
                ],
                "kind": "Operation",
                "name": "IssueNavigatorIssueSearchRefetchQuery",
                "selections": [
                    {
                        "concreteType": "JiraQuery",
                        "kind": "LinkedField",
                        "name": "jira",
                        "plural": false,
                        "selections": [
                            {
                                "args": v24,
                                "concreteType": "JiraIssueConnection",
                                "kind": "LinkedField",
                                "name": "issueSearch",
                                "plural": false,
                                "selections": [
                                    {
                                        "concreteType": "JiraIssueEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                            v25,
                                            {
                                                "concreteType": "JiraIssue",
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                    v26,
                                                    v27,
                                                    v28,
                                                    v29,
                                                    v30,
                                                    v34,
                                                    {
                                                        "args": v39,
                                                        "concreteType": "JiraIssueFieldSetConnection",
                                                        "kind": "LinkedField",
                                                        "name": "fieldSetsForIssueSearchView",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "concreteType": "JiraIssueFieldSetEdge",
                                                                "kind": "LinkedField",
                                                                "name": "edges",
                                                                "plural": true,
                                                                "selections": [
                                                                    {
                                                                        "concreteType": "JiraIssueFieldSet",
                                                                        "kind": "LinkedField",
                                                                        "name": "node",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            {
                                                                                "concreteType": "JiraIssueFieldConnection",
                                                                                "kind": "LinkedField",
                                                                                "name": "fields",
                                                                                "plural": false,
                                                                                "selections": [
                                                                                    {
                                                                                        "concreteType": "JiraIssueFieldEdge",
                                                                                        "kind": "LinkedField",
                                                                                        "name": "edges",
                                                                                        "plural": true,
                                                                                        "selections": [
                                                                                            {
                                                                                                "kind": "LinkedField",
                                                                                                "name": "node",
                                                                                                "plural": false,
                                                                                                "selections": [
                                                                                                    v26,
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            v31,
                                                                                                            v40,
                                                                                                            v41
                                                                                                        ],
                                                                                                        "type": "JiraSingleLineTextField"
                                                                                                    },
                                                                                                    v27,
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            v31,
                                                                                                            v40,
                                                                                                            {
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "user",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    v26,
                                                                                                                    v40,
                                                                                                                    v42,
                                                                                                                    v27
                                                                                                                ]
                                                                                                            }
                                                                                                        ],
                                                                                                        "type": "JiraSingleSelectUserPickerField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            v31,
                                                                                                            {
                                                                                                                "concreteType": "JiraIssueType",
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "issueType",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    v40,
                                                                                                                    v43,
                                                                                                                    v27
                                                                                                                ]
                                                                                                            }
                                                                                                        ],
                                                                                                        "type": "JiraIssueTypeField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            v31,
                                                                                                            v33
                                                                                                        ],
                                                                                                        "type": "JiraStatusCategoryField"
                                                                                                    }
                                                                                                ]
                                                                                            }
                                                                                        ]
                                                                                    }
                                                                                ]
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    },
                                                    v44,
                                                    {
                                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider",
                                                        "kind": "Condition",
                                                        "passingValue": false,
                                                        "selections": [
                                                            v91,
                                                            v92
                                                        ]
                                                    },
                                                    {
                                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider",
                                                        "kind": "Condition",
                                                        "passingValue": true,
                                                        "selections": [
                                                            v91,
                                                            v92,
                                                            v93
                                                        ]
                                                    },
                                                    v94
                                                ]
                                            },
                                            v26
                                        ]
                                    },
                                    {
                                        "concreteType": "JiraIssueNavigatorPageInfo",
                                        "kind": "LinkedField",
                                        "name": "issueNavigatorPageInfo",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "ScalarField",
                                                "name": "firstIssuePosition"
                                            },
                                            {
                                                "kind": "ScalarField",
                                                "name": "lastIssuePosition"
                                            }
                                        ]
                                    },
                                    {
                                        "args": [
                                            {
                                                "kind": "Literal",
                                                "name": "maxCursors",
                                                "value": 7
                                            }
                                        ],
                                        "concreteType": "JiraPageCursors",
                                        "kind": "LinkedField",
                                        "name": "pageCursors",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "concreteType": "JiraPageCursor",
                                                "kind": "LinkedField",
                                                "name": "around",
                                                "plural": true,
                                                "selections": v97
                                            },
                                            {
                                                "concreteType": "JiraPageCursor",
                                                "kind": "LinkedField",
                                                "name": "last",
                                                "plural": false,
                                                "selections": v97
                                            },
                                            {
                                                "concreteType": "JiraPageCursor",
                                                "kind": "LinkedField",
                                                "name": "first",
                                                "plural": false,
                                                "selections": [
                                                    v25,
                                                    v95,
                                                    v96
                                                ]
                                            }
                                        ],
                                        "storageKey": "pageCursors(maxCursors:7)"
                                    },
                                    {
                                        "kind": "ScalarField",
                                        "name": "totalIssueSearchResultCount"
                                    },
                                    {
                                        "kind": "LinkedField",
                                        "name": "issueSearchError",
                                        "plural": false,
                                        "selections": [
                                            v26,
                                            {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "messages"
                                                    }
                                                ],
                                                "type": "JiraInvalidJqlError"
                                            },
                                            {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                    v49,
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "errorType"
                                                    }
                                                ],
                                                "type": "JiraInvalidSyntaxError"
                                            }
                                        ]
                                    },
                                    {
                                        "kind": "ScalarField",
                                        "name": "isCappingIssueSearchResult"
                                    },
                                    {
                                        "concreteType": "PageInfo",
                                        "kind": "LinkedField",
                                        "name": "pageInfo",
                                        "plural": false,
                                        "selections": [
                                            v98,
                                            v85,
                                            {
                                                "kind": "ScalarField",
                                                "name": "hasPreviousPage"
                                            },
                                            {
                                                "kind": "ScalarField",
                                                "name": "startCursor"
                                            }
                                        ]
                                    },
                                    v99,
                                    {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": [
                                            {
                                                "concreteType": "JiraIssueEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                    {
                                                        "concreteType": "JiraIssue",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "args": v39,
                                                                "concreteType": "JiraIssueFieldSetConnection",
                                                                "kind": "LinkedField",
                                                                "name": "fieldSetsForIssueSearchView",
                                                                "plural": false,
                                                                "selections": [
                                                                    {
                                                                        "concreteType": "JiraIssueFieldSetEdge",
                                                                        "kind": "LinkedField",
                                                                        "name": "edges",
                                                                        "plural": true,
                                                                        "selections": [
                                                                            {
                                                                                "concreteType": "JiraIssueFieldSet",
                                                                                "kind": "LinkedField",
                                                                                "name": "node",
                                                                                "plural": false,
                                                                                "selections": [
                                                                                    {
                                                                                        "concreteType": "JiraIssueFieldConnection",
                                                                                        "kind": "LinkedField",
                                                                                        "name": "fields",
                                                                                        "plural": false,
                                                                                        "selections": [
                                                                                            {
                                                                                                "concreteType": "JiraIssueFieldEdge",
                                                                                                "kind": "LinkedField",
                                                                                                "name": "edges",
                                                                                                "plural": true,
                                                                                                "selections": [
                                                                                                    {
                                                                                                        "kind": "LinkedField",
                                                                                                        "name": "node",
                                                                                                        "plural": false,
                                                                                                        "selections": [
                                                                                                            v47,
                                                                                                            v31
                                                                                                        ]
                                                                                                    }
                                                                                                ]
                                                                                            }
                                                                                        ]
                                                                                    }
                                                                                ]
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "args": v24,
                                "filters": [
                                    "cloudId",
                                    "issueSearchInput",
                                    "options",
                                    "viewConfigInput"
                                ],
                                "handle": "connection",
                                "key": "IssueNavigatorIssueSearchPagination__issueSearch",
                                "kind": "LinkedHandle",
                                "name": "issueSearch"
                            },
                            {
                                "args": [
                                    v20,
                                    v35,
                                    v22,
                                    v37,
                                    v38
                                ],
                                "kind": "LinkedField",
                                "name": "issueSearchViewResult",
                                "plural": false,
                                "selections": [
                                    v26,
                                    {
                                        "kind": "TypeDiscriminator",
                                        "abstractKey": "__isJiraIssueSearchViewResult"
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            {
                                                "kind": "ScalarField",
                                                "name": "viewId"
                                            },
                                            v27,
                                            {
                                                "kind": "ScalarField",
                                                "name": "hasDefaultFieldSets"
                                            },
                                            {
                                                "args": [
                                                    {
                                                        "kind": "Literal",
                                                        "name": "filter",
                                                        "value": {
                                                            "fieldSetSelectedState": "SELECTED"
                                                        }
                                                    },
                                                    v36
                                                ],
                                                "concreteType": "JiraIssueSearchFieldSetConnection",
                                                "kind": "LinkedField",
                                                "name": "fieldSets",
                                                "plural": false,
                                                "selections": [
                                                    v64,
                                                    {
                                                        "concreteType": "JiraIssueSearchFieldSetEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                            {
                                                                "concreteType": "JiraIssueSearchFieldSet",
                                                                "kind": "LinkedField",
                                                                "name": "node",
                                                                "plural": false,
                                                                "selections": [
                                                                    v46,
                                                                    v45,
                                                                    v100,
                                                                    {
                                                                        "kind": "ScalarField",
                                                                        "name": "jqlTerm"
                                                                    },
                                                                    {
                                                                        "kind": "ScalarField",
                                                                        "name": "isSortable"
                                                                    },
                                                                    {
                                                                        "concreteType": "JiraFieldSetPreferences",
                                                                        "kind": "LinkedField",
                                                                        "name": "fieldSetPreferences",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            {
                                                                                "kind": "ScalarField",
                                                                                "name": "width"
                                                                            }
                                                                        ]
                                                                    },
                                                                    {
                                                                        "concreteType": "JiraFieldType",
                                                                        "kind": "LinkedField",
                                                                        "name": "fieldType",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            v100
                                                                        ]
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    },
                                                    v99
                                                ]
                                            },
                                            {
                                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider",
                                                "kind": "Condition",
                                                "passingValue": true,
                                                "selections": [
                                                    {
                                                        "args": [
                                                            {
                                                                "kind": "Variable",
                                                                "name": "staticViewInput",
                                                                "variableName": "staticViewInput"
                                                            }
                                                        ],
                                                        "concreteType": "JiraIssueSearchViewConfigSettings",
                                                        "kind": "LinkedField",
                                                        "name": "viewConfigSettings",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "kind": "ScalarField",
                                                                "name": "isHierarchyEnabled"
                                                            },
                                                            {
                                                                "kind": "ScalarField",
                                                                "name": "canEnableHierarchy"
                                                            },
                                                            v99
                                                        ]
                                                    }
                                                ]
                                            }
                                        ],
                                        "type": "JiraIssueSearchView"
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            v49,
                                            {
                                                "kind": "LinkedField",
                                                "name": "extensions",
                                                "plural": true,
                                                "selections": [
                                                    v26,
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "statusCode"
                                                    }
                                                ]
                                            }
                                        ],
                                        "type": "QueryError"
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            v27
                                        ],
                                        "type": "Node",
                                        "abstractKey": "__isNode"
                                    }
                                ]
                            },
                            {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                    {
                                        "args": [
                                            v20,
                                            v22,
                                            v37,
                                            v38
                                        ],
                                        "kind": "LinkedField",
                                        "name": "jiraIssueSearchView",
                                        "plural": false,
                                        "selections": [
                                            v26,
                                            v27,
                                            {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                    {
                                                        "args": [
                                                            v18,
                                                            v19,
                                                            v21,
                                                            v22,
                                                            v23
                                                        ],
                                                        "concreteType": "JiraSpreadsheetGroupConnection",
                                                        "kind": "LinkedField",
                                                        "name": "groups",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "concreteType": "JiraSpreadsheetGroup",
                                                                "kind": "LinkedField",
                                                                "name": "firstGroup",
                                                                "plural": false,
                                                                "selections": [
                                                                    v27,
                                                                    {
                                                                        "args": v101,
                                                                        "concreteType": "JiraIssueConnection",
                                                                        "kind": "LinkedField",
                                                                        "name": "issues",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            {
                                                                                "concreteType": "JiraIssueEdge",
                                                                                "kind": "LinkedField",
                                                                                "name": "edges",
                                                                                "plural": true,
                                                                                "selections": [
                                                                                    {
                                                                                        "concreteType": "JiraIssue",
                                                                                        "kind": "LinkedField",
                                                                                        "name": "node",
                                                                                        "plural": false,
                                                                                        "selections": [
                                                                                            v29,
                                                                                            v27,
                                                                                            v26,
                                                                                            v28,
                                                                                            v30,
                                                                                            v34,
                                                                                            v44,
                                                                                            {
                                                                                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider",
                                                                                                "kind": "Condition",
                                                                                                "passingValue": false,
                                                                                                "selections": [
                                                                                                    v91,
                                                                                                    v102
                                                                                                ]
                                                                                            },
                                                                                            {
                                                                                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider",
                                                                                                "kind": "Condition",
                                                                                                "passingValue": true,
                                                                                                "selections": [
                                                                                                    v91,
                                                                                                    v102,
                                                                                                    v93
                                                                                                ]
                                                                                            },
                                                                                            v94
                                                                                        ]
                                                                                    },
                                                                                    v26,
                                                                                    v25
                                                                                ]
                                                                            },
                                                                            {
                                                                                "concreteType": "PageInfo",
                                                                                "kind": "LinkedField",
                                                                                "name": "pageInfo",
                                                                                "plural": false,
                                                                                "selections": [
                                                                                    v98,
                                                                                    v85
                                                                                ]
                                                                            },
                                                                            v99
                                                                        ],
                                                                        "storageKey": "issues(first:50)"
                                                                    },
                                                                    {
                                                                        "args": v101,
                                                                        "handle": "connection",
                                                                        "key": "firstGroupSection_nativeIssueTable__issues",
                                                                        "kind": "LinkedHandle",
                                                                        "name": "issues"
                                                                    },
                                                                    v103,
                                                                    v104,
                                                                    v105,
                                                                    v106
                                                                ]
                                                            },
                                                            {
                                                                "concreteType": "JiraSpreadsheetGroupEdge",
                                                                "kind": "LinkedField",
                                                                "name": "edges",
                                                                "plural": true,
                                                                "selections": [
                                                                    {
                                                                        "concreteType": "JiraSpreadsheetGroup",
                                                                        "kind": "LinkedField",
                                                                        "name": "node",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            v27,
                                                                            v104,
                                                                            v105,
                                                                            v103,
                                                                            v106
                                                                        ]
                                                                    }
                                                                ]
                                                            },
                                                            v99
                                                        ]
                                                    }
                                                ],
                                                "type": "JiraGroupedListView"
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "params": {
                "id": "ee527aef2a2a40d44b27f2fc86c42817d720c54bf08f9370ab8b00596206eb28",
                "metadata": {},
                "name": "IssueNavigatorIssueSearchRefetchQuery",
                "operationKind": "query",
                "text": null,
                "providedVariables": {
                    "isRefactorNinToViewSchemaEnabled": (0, (0, parcelRequire("1q6lK")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider": (0, (0, parcelRequire("1q6lK")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider": (0, (0, parcelRequire("bqIzm")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider": (0, (0, parcelRequire("5y5Fx")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider": (0, (0, parcelRequire("6vKPN")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": (0, (0, parcelRequire("1kHcz")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider": (0, (0, parcelRequire("hLeia")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider": (0, (0, parcelRequire("G39h7")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider": (0, (0, parcelRequire("1ZvTA")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": (0, (0, parcelRequire("5oQtH")).default)
                }
            }
        };
    }();
    $a2b7fdc33d59789a$var$node.hash = "8e56b4674f49c8d00c94c738c6b308b9";
    var $a2b7fdc33d59789a$export$2e2bcd8739ae039 = $a2b7fdc33d59789a$var$node;
});
parcelRegister("ln32M", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$361a37bcf594286c$export$2e2bcd8739ae039);
    var $2CtcG;
    const $361a37bcf594286c$var$node = function() {
        var v0 = {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
        }, v1 = {
            "kind": "Variable",
            "name": "issueSearchInput",
            "variableName": "issueSearchInput"
        };
        return {
            "argumentDefinitions": [
                {
                    "kind": "RootArgument",
                    "name": "after"
                },
                {
                    "kind": "RootArgument",
                    "name": "amountOfColumns"
                },
                {
                    "kind": "RootArgument",
                    "name": "before"
                },
                {
                    "kind": "RootArgument",
                    "name": "cloudId"
                },
                {
                    "kind": "RootArgument",
                    "name": "fieldSetIds"
                },
                {
                    "kind": "RootArgument",
                    "name": "filterId"
                },
                {
                    "kind": "RootArgument",
                    "name": "first"
                },
                {
                    "kind": "RootArgument",
                    "name": "issueSearchInput"
                },
                {
                    "kind": "RootArgument",
                    "name": "issueTypeId"
                },
                {
                    "kind": "RootArgument",
                    "name": "last"
                },
                {
                    "kind": "RootArgument",
                    "name": "namespace"
                },
                {
                    "kind": "RootArgument",
                    "name": "options"
                },
                {
                    "kind": "RootArgument",
                    "name": "projectId"
                },
                {
                    "kind": "RootArgument",
                    "name": "shouldQueryFieldSetsById"
                },
                {
                    "kind": "RootArgument",
                    "name": "staticViewInput"
                },
                {
                    "kind": "RootArgument",
                    "name": "viewId"
                }
            ],
            "kind": "Fragment",
            "metadata": {
                "refetch": {
                    "fragmentPathInResult": [],
                    "operation": (0, (0, parcelRequire("2CtcG")).default)
                }
            },
            "name": "main_issueNavigator_IssueNavigator_refetchQueryOld",
            "selections": [
                {
                    "concreteType": "JiraQuery",
                    "kind": "LinkedField",
                    "name": "jira",
                    "plural": false,
                    "selections": [
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "after",
                                    "variableName": "after"
                                },
                                {
                                    "kind": "Variable",
                                    "name": "before",
                                    "variableName": "before"
                                },
                                v0,
                                {
                                    "kind": "Variable",
                                    "name": "first",
                                    "variableName": "first"
                                },
                                v1,
                                {
                                    "kind": "Variable",
                                    "name": "last",
                                    "variableName": "last"
                                },
                                {
                                    "kind": "Variable",
                                    "name": "options",
                                    "variableName": "options"
                                }
                            ],
                            "concreteType": "JiraIssueConnection",
                            "kind": "LinkedField",
                            "name": "issueSearchStable",
                            "plural": false,
                            "selections": [
                                {
                                    "concreteType": "JiraIssueEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "concreteType": "JiraIssue",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "ScalarField",
                                                    "name": "key"
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "kind": "FragmentSpread",
                                    "name": "issueSearchQuery_issueNavigator_IssueSearchQueryProvider_issueResults"
                                },
                                {
                                    "kind": "FragmentSpread",
                                    "name": "selectedIssueStateOld_issueNavigator_SelectedIssueContainer"
                                },
                                {
                                    "kind": "FragmentSpread",
                                    "name": "main_issueNavigator_IssueNavigatorUI_issueResults"
                                }
                            ]
                        },
                        {
                            "args": [
                                v0,
                                {
                                    "kind": "Variable",
                                    "name": "filterId",
                                    "variableName": "filterId"
                                },
                                v1,
                                {
                                    "kind": "Variable",
                                    "name": "namespace",
                                    "variableName": "namespace"
                                },
                                {
                                    "kind": "Variable",
                                    "name": "viewId",
                                    "variableName": "viewId"
                                }
                            ],
                            "kind": "LinkedField",
                            "name": "issueSearchViewResult",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "name": "__typename"
                                        },
                                        {
                                            "kind": "FragmentSpread",
                                            "name": "issueSearchQuery_issueNavigator_IssueSearchQueryProvider_view"
                                        },
                                        {
                                            "kind": "FragmentSpread",
                                            "name": "selectedView_issueNavigator_SelectedViewContainer"
                                        }
                                    ],
                                    "type": "JiraIssueSearchView"
                                },
                                {
                                    "kind": "FragmentSpread",
                                    "name": "main_issueNavigator_IssueNavigatorUI_viewResult"
                                }
                            ]
                        }
                    ]
                }
            ],
            "type": "Query"
        };
    }();
    $361a37bcf594286c$var$node.hash = "a8a5b16b53504d70b85e1406e1fc87ca";
    var $361a37bcf594286c$export$2e2bcd8739ae039 = $361a37bcf594286c$var$node;
});
parcelRegister("2CtcG", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$894e01c189a8309f$export$2e2bcd8739ae039);
    var $hLeia;
    var $bqIzm;
    var $5oQtH;
    var $1ZvTA;
    var $G39h7;
    var $6vKPN;
    var $5y5Fx;
    var $1kHcz;
    const $894e01c189a8309f$var$node = function() {
        var v0 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "after"
        }, v1 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "amountOfColumns"
        }, v2 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "before"
        }, v3 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cloudId"
        }, v4 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "fieldSetIds"
        }, v5 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "filterId"
        }, v6 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "first"
        }, v7 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "issueSearchInput"
        }, v8 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "issueTypeId"
        }, v9 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "last"
        }, v10 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "namespace"
        }, v11 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "options"
        }, v12 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "projectId"
        }, v13 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "shouldQueryFieldSetsById"
        }, v14 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "staticViewInput"
        }, v15 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "viewId"
        }, v16 = {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
        }, v17 = {
            "kind": "Variable",
            "name": "issueSearchInput",
            "variableName": "issueSearchInput"
        }, v18 = {
            "kind": "ScalarField",
            "name": "key"
        }, v19 = {
            "kind": "ScalarField",
            "name": "id"
        }, v20 = {
            "kind": "ScalarField",
            "name": "__typename"
        }, v21 = {
            "kind": "ScalarField",
            "name": "fieldId"
        }, v22 = {
            "kind": "ScalarField",
            "name": "statusCategoryId"
        }, v23 = {
            "concreteType": "JiraStatusCategory",
            "kind": "LinkedField",
            "name": "statusCategory",
            "plural": false,
            "selections": [
                v22,
                v19
            ]
        }, v24 = {
            "kind": "Variable",
            "name": "filterId",
            "variableName": "filterId"
        }, v25 = {
            "kind": "Variable",
            "name": "first",
            "variableName": "amountOfColumns"
        }, v26 = {
            "kind": "Variable",
            "name": "namespace",
            "variableName": "namespace"
        }, v27 = {
            "kind": "Variable",
            "name": "viewId",
            "variableName": "viewId"
        }, v28 = [
            {
                "fields": [
                    {
                        "fields": [
                            {
                                "kind": "Variable",
                                "name": "issueType",
                                "variableName": "issueTypeId"
                            },
                            {
                                "kind": "Variable",
                                "name": "project",
                                "variableName": "projectId"
                            }
                        ],
                        "kind": "ObjectValue",
                        "name": "projectContext"
                    }
                ],
                "kind": "ObjectValue",
                "name": "context"
            },
            v24,
            v25,
            v26,
            v27
        ], v29 = {
            "kind": "ScalarField",
            "name": "name"
        }, v30 = {
            "kind": "ScalarField",
            "name": "text"
        }, v31 = {
            "kind": "ScalarField",
            "name": "picture"
        }, v32 = {
            "concreteType": "JiraAvatar",
            "kind": "LinkedField",
            "name": "avatar",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "small"
                }
            ]
        }, v33 = {
            "kind": "ScalarField",
            "name": "type"
        }, v34 = {
            "kind": "ScalarField",
            "name": "fieldSetId"
        }, v35 = {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isJiraIssueField"
        }, v36 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraAffectedServiceConnection",
                    "kind": "LinkedField",
                    "name": "selectedAffectedServicesConnection",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraAffectedServiceEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "concreteType": "JiraAffectedService",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "name": "serviceId"
                                        },
                                        v29,
                                        v19
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            "type": "JiraAffectedServicesField"
        }, v37 = {
            "kind": "ScalarField",
            "name": "message"
        }, v38 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraCmdbObjectConnection",
                    "kind": "LinkedField",
                    "name": "selectedCmdbObjectsConnection",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraCmdbObjectEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "concreteType": "JiraCmdbObject",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "name": "objectId"
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "name": "label"
                                        },
                                        {
                                            "concreteType": "JiraCmdbAvatar",
                                            "kind": "LinkedField",
                                            "name": "avatar",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider",
                                                    "kind": "Condition",
                                                    "passingValue": true,
                                                    "selections": [
                                                        {
                                                            "concreteType": "JiraCmdbMediaClientConfig",
                                                            "kind": "LinkedField",
                                                            "name": "mediaClientConfig",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "kind": "ScalarField",
                                                                    "name": "clientId"
                                                                },
                                                                {
                                                                    "kind": "ScalarField",
                                                                    "name": "fileId"
                                                                },
                                                                {
                                                                    "kind": "ScalarField",
                                                                    "name": "mediaBaseUrl"
                                                                },
                                                                {
                                                                    "kind": "ScalarField",
                                                                    "name": "mediaJwtToken"
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "name": "url48"
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "concreteType": "QueryError",
                            "kind": "LinkedField",
                            "name": "errors",
                            "plural": true,
                            "selections": [
                                v37
                            ]
                        }
                    ]
                }
            ],
            "type": "JiraCMDBField"
        }, v39 = [
            v29,
            v19
        ], v40 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraComponentConnection",
                    "kind": "LinkedField",
                    "name": "selectedComponentsConnection",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraComponentEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "concreteType": "JiraComponent",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": v39
                                }
                            ]
                        }
                    ]
                }
            ],
            "type": "JiraComponentsField"
        }, v41 = {
            "kind": "ScalarField",
            "name": "date"
        }, v42 = {
            "concreteType": "JiraFieldConfig",
            "kind": "LinkedField",
            "name": "fieldConfig",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "isRequired"
                }
            ]
        }, v43 = {
            "kind": "ScalarField",
            "name": "lazyIsEditableInIssueView"
        }, v44 = {
            "kind": "ClientExtension",
            "selections": [
                v43
            ]
        }, v45 = {
            "kind": "InlineFragment",
            "selections": [
                v41,
                v29,
                v42,
                v44
            ],
            "type": "JiraDatePickerField"
        }, v46 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "dateTime"
                }
            ],
            "type": "JiraDateTimePickerField"
        }, v47 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraEpic",
                    "kind": "LinkedField",
                    "name": "epic",
                    "plural": false,
                    "selections": [
                        v18,
                        {
                            "kind": "ScalarField",
                            "name": "summary"
                        },
                        {
                            "kind": "ScalarField",
                            "name": "color"
                        },
                        v19
                    ]
                }
            ],
            "type": "JiraEpicLinkField"
        }, v48 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "renderedFieldHtml"
                }
            ],
            "type": "JiraFallbackField"
        }, v49 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraStatusCategory",
                    "kind": "LinkedField",
                    "name": "statusCategory",
                    "plural": false,
                    "selections": [
                        v29,
                        v22,
                        v19
                    ]
                }
            ],
            "type": "JiraStatusCategoryField"
        }, v50 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraStatus",
                    "kind": "LinkedField",
                    "name": "status",
                    "plural": false,
                    "selections": [
                        v29,
                        {
                            "kind": "ScalarField",
                            "name": "statusId"
                        },
                        v23,
                        v19
                    ]
                }
            ],
            "type": "JiraStatusField"
        }, v51 = [
            v29
        ], v52 = {
            "kind": "ScalarField",
            "name": "totalCount"
        }, v53 = {
            "args": [
                {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1000
                }
            ],
            "concreteType": "JiraLabelConnection",
            "kind": "LinkedField",
            "name": "selectedLabelsConnection",
            "plural": false,
            "selections": [
                {
                    "concreteType": "JiraLabelEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "concreteType": "JiraLabel",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": v51
                        }
                    ]
                },
                v52
            ],
            "storageKey": "selectedLabelsConnection(first:1000)"
        }, v54 = {
            "kind": "InlineFragment",
            "selections": [
                v53,
                v29,
                v44
            ],
            "type": "JiraLabelsField"
        }, v55 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraIssueType",
                    "kind": "LinkedField",
                    "name": "issueType",
                    "plural": false,
                    "selections": [
                        v32,
                        v29,
                        v19
                    ]
                }
            ],
            "type": "JiraIssueTypeField"
        }, v56 = {
            "concreteType": "JiraAvatar",
            "kind": "LinkedField",
            "name": "avatar",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "xsmall"
                }
            ]
        }, v57 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraIssue",
                    "kind": "LinkedField",
                    "name": "parentIssue",
                    "plural": false,
                    "selections": [
                        v18,
                        {
                            "concreteType": "JiraSingleLineTextField",
                            "kind": "LinkedField",
                            "name": "summaryField",
                            "plural": false,
                            "selections": [
                                v30,
                                v19
                            ]
                        },
                        v19,
                        {
                            "concreteType": "JiraColorField",
                            "kind": "LinkedField",
                            "name": "issueColorField",
                            "plural": false,
                            "selections": [
                                {
                                    "concreteType": "JiraColor",
                                    "kind": "LinkedField",
                                    "name": "color",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "name": "colorKey"
                                        },
                                        v19
                                    ]
                                },
                                v19
                            ]
                        },
                        {
                            "concreteType": "JiraIssueTypeField",
                            "kind": "LinkedField",
                            "name": "issueTypeField",
                            "plural": false,
                            "selections": [
                                {
                                    "concreteType": "JiraIssueType",
                                    "kind": "LinkedField",
                                    "name": "issueType",
                                    "plural": false,
                                    "selections": [
                                        v56,
                                        v29,
                                        v19
                                    ]
                                },
                                v19
                            ]
                        }
                    ]
                }
            ],
            "type": "JiraParentIssueField"
        }, v58 = {
            "concreteType": "JiraPriority",
            "kind": "LinkedField",
            "name": "priority",
            "plural": false,
            "selections": [
                v29,
                {
                    "kind": "ScalarField",
                    "name": "iconUrl"
                },
                v19
            ]
        }, v59 = {
            "kind": "InlineFragment",
            "selections": [
                v58,
                v29,
                v44
            ],
            "type": "JiraPriorityField"
        }, v60 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                        v29,
                        v18,
                        {
                            "concreteType": "JiraAvatar",
                            "kind": "LinkedField",
                            "name": "avatar",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "name": "large"
                                }
                            ]
                        },
                        v19
                    ]
                }
            ],
            "type": "JiraProjectField"
        }, v61 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraResolution",
                    "kind": "LinkedField",
                    "name": "resolution",
                    "plural": false,
                    "selections": v39
                }
            ],
            "type": "JiraResolutionField"
        }, v62 = {
            "concreteType": "JiraSprintConnection",
            "kind": "LinkedField",
            "name": "selectedSprintsConnection",
            "plural": false,
            "selections": [
                {
                    "concreteType": "JiraSprintEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "concreteType": "JiraSprint",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                v19,
                                {
                                    "kind": "ScalarField",
                                    "name": "sprintId"
                                },
                                v29,
                                {
                                    "kind": "ScalarField",
                                    "name": "state"
                                },
                                {
                                    "kind": "ScalarField",
                                    "name": "endDate"
                                }
                            ]
                        }
                    ]
                }
            ]
        }, v63 = {
            "kind": "InlineFragment",
            "selections": [
                v62,
                v29,
                v44
            ],
            "type": "JiraSprintField"
        }, v64 = {
            "kind": "ScalarField",
            "name": "accountId"
        }, v65 = {
            "kind": "ScalarField",
            "name": "accountStatus"
        }, v66 = {
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
                v20,
                v64,
                v31,
                v29,
                v19,
                v65
            ]
        }, v67 = {
            "kind": "InlineFragment",
            "selections": [
                v29,
                {
                    "concreteType": "JiraVersionConnection",
                    "kind": "LinkedField",
                    "name": "selectedVersionsConnection",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraVersionEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "concreteType": "JiraVersion",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": v39
                                }
                            ]
                        }
                    ]
                }
            ],
            "type": "JiraMultipleVersionPickerField"
        }, v68 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraRichText",
                    "kind": "LinkedField",
                    "name": "richText",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraADF",
                            "kind": "LinkedField",
                            "name": "adfValue",
                            "plural": false,
                            "selections": [
                                {
                                    "concreteType": "JiraAdfToConvertedPlainText",
                                    "kind": "LinkedField",
                                    "name": "convertedPlainText",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "name": "plainText"
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            "type": "JiraRichTextField"
        }, v69 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "concreteType": "JiraServiceManagementRequestType",
                    "kind": "LinkedField",
                    "name": "requestType",
                    "plural": false,
                    "selections": [
                        v29,
                        v56,
                        v19
                    ]
                }
            ],
            "type": "JiraServiceManagementRequestTypeField"
        }, v70 = {
            "kind": "InlineFragment",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "first",
                            "variableName": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
                        }
                    ],
                    "concreteType": "JiraIssueLinkConnection",
                    "kind": "LinkedField",
                    "name": "issueLinkConnection",
                    "plural": false,
                    "selections": [
                        {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
                            "kind": "Condition",
                            "passingValue": false,
                            "selections": [
                                {
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "name": "hasNextPage"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                                v52
                            ]
                        },
                        {
                            "concreteType": "JiraIssueLinkEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "concreteType": "JiraIssueLink",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "concreteType": "JiraIssue",
                                            "kind": "LinkedField",
                                            "name": "issue",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "ScalarField",
                                                    "name": "webUrl"
                                                },
                                                v18,
                                                {
                                                    "args": [
                                                        {
                                                            "kind": "Literal",
                                                            "name": "ids",
                                                            "value": [
                                                                "statusCategory"
                                                            ]
                                                        }
                                                    ],
                                                    "concreteType": "JiraIssueFieldConnection",
                                                    "kind": "LinkedField",
                                                    "name": "fieldsById",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "concreteType": "JiraIssueFieldEdge",
                                                            "kind": "LinkedField",
                                                            "name": "edges",
                                                            "plural": true,
                                                            "selections": [
                                                                {
                                                                    "kind": "LinkedField",
                                                                    "name": "node",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        v20,
                                                                        {
                                                                            "kind": "InlineFragment",
                                                                            "selections": [
                                                                                v23
                                                                            ],
                                                                            "type": "JiraStatusCategoryField"
                                                                        },
                                                                        v19
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ],
                                                    "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                                                },
                                                v19
                                            ]
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "name": "relationName"
                                        },
                                        v19
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            "type": "JiraIssueLinkField"
        }, v71 = {
            "kind": "ScalarField",
            "name": "isStoryPointField"
        }, v72 = {
            "kind": "ScalarField",
            "name": "number"
        }, v73 = {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
                {
                    "kind": "InlineFragment",
                    "selections": [
                        v71,
                        v72,
                        v29,
                        v44
                    ],
                    "type": "JiraNumberField"
                }
            ]
        }, v74 = {
            "condition": "shouldQueryFieldSetsById",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "fieldSetIds",
                            "variableName": "fieldSetIds"
                        },
                        v25
                    ],
                    "concreteType": "JiraIssueFieldSetConnection",
                    "kind": "LinkedField",
                    "name": "fieldSetsById",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraIssueFieldSetEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "concreteType": "JiraIssueFieldSet",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        v33,
                                        v34,
                                        {
                                            "concreteType": "JiraIssueFieldConnection",
                                            "kind": "LinkedField",
                                            "name": "fields",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "concreteType": "JiraIssueFieldEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                v20,
                                                                v21,
                                                                v33,
                                                                v35,
                                                                v19,
                                                                v36,
                                                                v38,
                                                                v40,
                                                                v45,
                                                                v46,
                                                                v47,
                                                                v48,
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        v30,
                                                                        v29,
                                                                        v44
                                                                    ],
                                                                    "type": "JiraSingleLineTextField"
                                                                },
                                                                v49,
                                                                v50,
                                                                v54,
                                                                v55,
                                                                v57,
                                                                v59,
                                                                v60,
                                                                v61,
                                                                v63,
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        v66,
                                                                        v29,
                                                                        v42,
                                                                        v44
                                                                    ],
                                                                    "type": "JiraSingleSelectUserPickerField"
                                                                },
                                                                v67,
                                                                v68,
                                                                v69,
                                                                v70,
                                                                v73
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }, v75 = {
            "condition": "shouldQueryFieldSetsById",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
                {
                    "args": v28,
                    "concreteType": "JiraIssueFieldSetConnection",
                    "kind": "LinkedField",
                    "name": "fieldSetsForIssueSearchView",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraIssueFieldSetEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "concreteType": "JiraIssueFieldSet",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        v33,
                                        v34,
                                        {
                                            "concreteType": "JiraIssueFieldConnection",
                                            "kind": "LinkedField",
                                            "name": "fields",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "concreteType": "JiraIssueFieldEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                v21,
                                                                v33,
                                                                v35,
                                                                v36,
                                                                v38,
                                                                v40,
                                                                v45,
                                                                v46,
                                                                v47,
                                                                v48,
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        v33,
                                                                        v44
                                                                    ],
                                                                    "type": "JiraSingleLineTextField"
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        {
                                                                            "concreteType": "JiraStatusCategory",
                                                                            "kind": "LinkedField",
                                                                            "name": "statusCategory",
                                                                            "plural": false,
                                                                            "selections": v51
                                                                        }
                                                                    ],
                                                                    "type": "JiraStatusCategoryField"
                                                                },
                                                                v50,
                                                                v54,
                                                                v57,
                                                                v59,
                                                                v60,
                                                                v61,
                                                                v63,
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        v33,
                                                                        {
                                                                            "kind": "LinkedField",
                                                                            "name": "user",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                v64,
                                                                                v65
                                                                            ]
                                                                        },
                                                                        v42,
                                                                        v44
                                                                    ],
                                                                    "type": "JiraSingleSelectUserPickerField"
                                                                },
                                                                v67,
                                                                v68,
                                                                v69,
                                                                v70,
                                                                v73
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }, v76 = {
            "kind": "ClientExtension",
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "__id"
                }
            ]
        }, v77 = {
            "kind": "ScalarField",
            "name": "cursor"
        }, v78 = {
            "kind": "ScalarField",
            "name": "isCurrent"
        }, v79 = {
            "kind": "ScalarField",
            "name": "pageNumber"
        }, v80 = {
            "kind": "ScalarField",
            "name": "displayName"
        };
        return {
            "fragment": {
                "argumentDefinitions": [
                    v0,
                    v1,
                    v2,
                    v3,
                    v4,
                    v5,
                    v6,
                    v7,
                    v8,
                    v9,
                    v10,
                    v11,
                    v12,
                    v13,
                    v14,
                    v15
                ],
                "kind": "Fragment",
                "name": "IssueNavigatorResultsRefetchQuery",
                "selections": [
                    {
                        "kind": "FragmentSpread",
                        "name": "main_issueNavigator_IssueNavigator_refetchQueryOld"
                    }
                ],
                "type": "Query"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": [
                    v0,
                    v1,
                    v2,
                    v3,
                    v4,
                    v5,
                    v6,
                    v7,
                    v8,
                    v9,
                    v10,
                    v11,
                    v12,
                    v13,
                    v14,
                    v15,
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
                    }
                ],
                "kind": "Operation",
                "name": "IssueNavigatorResultsRefetchQuery",
                "selections": [
                    {
                        "concreteType": "JiraQuery",
                        "kind": "LinkedField",
                        "name": "jira",
                        "plural": false,
                        "selections": [
                            {
                                "args": [
                                    {
                                        "kind": "Variable",
                                        "name": "after",
                                        "variableName": "after"
                                    },
                                    {
                                        "kind": "Variable",
                                        "name": "before",
                                        "variableName": "before"
                                    },
                                    v16,
                                    {
                                        "kind": "Variable",
                                        "name": "first",
                                        "variableName": "first"
                                    },
                                    v17,
                                    {
                                        "kind": "Variable",
                                        "name": "last",
                                        "variableName": "last"
                                    },
                                    {
                                        "kind": "Variable",
                                        "name": "options",
                                        "variableName": "options"
                                    }
                                ],
                                "concreteType": "JiraIssueConnection",
                                "kind": "LinkedField",
                                "name": "issueSearchStable",
                                "plural": false,
                                "selections": [
                                    {
                                        "concreteType": "JiraIssueEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                            {
                                                "concreteType": "JiraIssue",
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                    v18,
                                                    v19,
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "issueId"
                                                    },
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "isResolved"
                                                    },
                                                    {
                                                        "args": [
                                                            {
                                                                "kind": "Literal",
                                                                "name": "ids",
                                                                "value": [
                                                                    "status"
                                                                ]
                                                            }
                                                        ],
                                                        "concreteType": "JiraIssueFieldConnection",
                                                        "kind": "LinkedField",
                                                        "name": "fieldsById",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "concreteType": "JiraIssueFieldEdge",
                                                                "kind": "LinkedField",
                                                                "name": "edges",
                                                                "plural": true,
                                                                "selections": [
                                                                    {
                                                                        "kind": "LinkedField",
                                                                        "name": "node",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            v20,
                                                                            {
                                                                                "kind": "InlineFragment",
                                                                                "selections": [
                                                                                    v21,
                                                                                    {
                                                                                        "concreteType": "JiraStatus",
                                                                                        "kind": "LinkedField",
                                                                                        "name": "status",
                                                                                        "plural": false,
                                                                                        "selections": [
                                                                                            v23,
                                                                                            v19
                                                                                        ]
                                                                                    }
                                                                                ],
                                                                                "type": "JiraStatusField"
                                                                            },
                                                                            v19
                                                                        ]
                                                                    }
                                                                ]
                                                            }
                                                        ],
                                                        "storageKey": "fieldsById(ids:[\"status\"])"
                                                    },
                                                    {
                                                        "args": v28,
                                                        "concreteType": "JiraIssueFieldSetConnection",
                                                        "kind": "LinkedField",
                                                        "name": "fieldSetsForIssueSearchView",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "concreteType": "JiraIssueFieldSetEdge",
                                                                "kind": "LinkedField",
                                                                "name": "edges",
                                                                "plural": true,
                                                                "selections": [
                                                                    {
                                                                        "concreteType": "JiraIssueFieldSet",
                                                                        "kind": "LinkedField",
                                                                        "name": "node",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            {
                                                                                "concreteType": "JiraIssueFieldConnection",
                                                                                "kind": "LinkedField",
                                                                                "name": "fields",
                                                                                "plural": false,
                                                                                "selections": [
                                                                                    {
                                                                                        "concreteType": "JiraIssueFieldEdge",
                                                                                        "kind": "LinkedField",
                                                                                        "name": "edges",
                                                                                        "plural": true,
                                                                                        "selections": [
                                                                                            {
                                                                                                "kind": "LinkedField",
                                                                                                "name": "node",
                                                                                                "plural": false,
                                                                                                "selections": [
                                                                                                    v20,
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            v21,
                                                                                                            v29,
                                                                                                            v30
                                                                                                        ],
                                                                                                        "type": "JiraSingleLineTextField"
                                                                                                    },
                                                                                                    v19,
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            v21,
                                                                                                            v29,
                                                                                                            {
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "user",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    v20,
                                                                                                                    v29,
                                                                                                                    v31,
                                                                                                                    v19
                                                                                                                ]
                                                                                                            }
                                                                                                        ],
                                                                                                        "type": "JiraSingleSelectUserPickerField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            v21,
                                                                                                            {
                                                                                                                "concreteType": "JiraIssueType",
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "issueType",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    v29,
                                                                                                                    v32,
                                                                                                                    v19
                                                                                                                ]
                                                                                                            }
                                                                                                        ],
                                                                                                        "type": "JiraIssueTypeField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            v21,
                                                                                                            v23
                                                                                                        ],
                                                                                                        "type": "JiraStatusCategoryField"
                                                                                                    }
                                                                                                ]
                                                                                            }
                                                                                        ]
                                                                                    }
                                                                                ]
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider",
                                                        "kind": "Condition",
                                                        "passingValue": true,
                                                        "selections": [
                                                            {
                                                                "kind": "ScalarField",
                                                                "name": "hasChildren"
                                                            },
                                                            {
                                                                "concreteType": "JiraIssueTypeField",
                                                                "kind": "LinkedField",
                                                                "name": "issueTypeField",
                                                                "plural": false,
                                                                "selections": [
                                                                    {
                                                                        "concreteType": "JiraIssueType",
                                                                        "kind": "LinkedField",
                                                                        "name": "issueType",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            {
                                                                                "concreteType": "JiraIssueTypeHierarchyLevel",
                                                                                "kind": "LinkedField",
                                                                                "name": "hierarchy",
                                                                                "plural": false,
                                                                                "selections": [
                                                                                    {
                                                                                        "kind": "ScalarField",
                                                                                        "name": "level"
                                                                                    }
                                                                                ]
                                                                            },
                                                                            v19
                                                                        ]
                                                                    },
                                                                    v19
                                                                ]
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider",
                                                        "kind": "Condition",
                                                        "passingValue": false,
                                                        "selections": [
                                                            v74,
                                                            v75
                                                        ]
                                                    },
                                                    {
                                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider",
                                                        "kind": "Condition",
                                                        "passingValue": true,
                                                        "selections": [
                                                            v74,
                                                            v75,
                                                            {
                                                                "kind": "ClientExtension",
                                                                "selections": [
                                                                    {
                                                                        "concreteType": "JiraIssueFieldSetConnection",
                                                                        "kind": "LinkedField",
                                                                        "name": "issueRowFieldSets",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            {
                                                                                "concreteType": "JiraIssueFieldSetEdge",
                                                                                "kind": "LinkedField",
                                                                                "name": "edges",
                                                                                "plural": true,
                                                                                "selections": [
                                                                                    {
                                                                                        "concreteType": "JiraIssueFieldSet",
                                                                                        "kind": "LinkedField",
                                                                                        "name": "node",
                                                                                        "plural": false,
                                                                                        "selections": [
                                                                                            v33,
                                                                                            v34,
                                                                                            {
                                                                                                "concreteType": "JiraIssueFieldConnection",
                                                                                                "kind": "LinkedField",
                                                                                                "name": "fields",
                                                                                                "plural": false,
                                                                                                "selections": [
                                                                                                    {
                                                                                                        "concreteType": "JiraIssueFieldEdge",
                                                                                                        "kind": "LinkedField",
                                                                                                        "name": "edges",
                                                                                                        "plural": true,
                                                                                                        "selections": [
                                                                                                            {
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "node",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    v20,
                                                                                                                    v21,
                                                                                                                    v33,
                                                                                                                    v35,
                                                                                                                    v19,
                                                                                                                    v36,
                                                                                                                    v38,
                                                                                                                    v40,
                                                                                                                    {
                                                                                                                        "kind": "InlineFragment",
                                                                                                                        "selections": [
                                                                                                                            v41,
                                                                                                                            v43,
                                                                                                                            v29,
                                                                                                                            v42
                                                                                                                        ],
                                                                                                                        "type": "JiraDatePickerField"
                                                                                                                    },
                                                                                                                    v46,
                                                                                                                    v47,
                                                                                                                    v48,
                                                                                                                    {
                                                                                                                        "kind": "InlineFragment",
                                                                                                                        "selections": [
                                                                                                                            v30,
                                                                                                                            v43,
                                                                                                                            v29
                                                                                                                        ],
                                                                                                                        "type": "JiraSingleLineTextField"
                                                                                                                    },
                                                                                                                    v49,
                                                                                                                    v50,
                                                                                                                    {
                                                                                                                        "kind": "InlineFragment",
                                                                                                                        "selections": [
                                                                                                                            v53,
                                                                                                                            v43,
                                                                                                                            v29
                                                                                                                        ],
                                                                                                                        "type": "JiraLabelsField"
                                                                                                                    },
                                                                                                                    v55,
                                                                                                                    v57,
                                                                                                                    {
                                                                                                                        "kind": "InlineFragment",
                                                                                                                        "selections": [
                                                                                                                            v43,
                                                                                                                            v58,
                                                                                                                            v29
                                                                                                                        ],
                                                                                                                        "type": "JiraPriorityField"
                                                                                                                    },
                                                                                                                    v60,
                                                                                                                    v61,
                                                                                                                    {
                                                                                                                        "kind": "InlineFragment",
                                                                                                                        "selections": [
                                                                                                                            v43,
                                                                                                                            v62,
                                                                                                                            v29
                                                                                                                        ],
                                                                                                                        "type": "JiraSprintField"
                                                                                                                    },
                                                                                                                    {
                                                                                                                        "kind": "InlineFragment",
                                                                                                                        "selections": [
                                                                                                                            v66,
                                                                                                                            v43,
                                                                                                                            v29,
                                                                                                                            v42
                                                                                                                        ],
                                                                                                                        "type": "JiraSingleSelectUserPickerField"
                                                                                                                    },
                                                                                                                    v67,
                                                                                                                    v68,
                                                                                                                    v69,
                                                                                                                    v70,
                                                                                                                    {
                                                                                                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
                                                                                                                        "kind": "Condition",
                                                                                                                        "passingValue": true,
                                                                                                                        "selections": [
                                                                                                                            {
                                                                                                                                "kind": "InlineFragment",
                                                                                                                                "selections": [
                                                                                                                                    v71,
                                                                                                                                    v72,
                                                                                                                                    v43,
                                                                                                                                    v29
                                                                                                                                ],
                                                                                                                                "type": "JiraNumberField"
                                                                                                                            }
                                                                                                                        ]
                                                                                                                    }
                                                                                                                ]
                                                                                                            }
                                                                                                        ]
                                                                                                    }
                                                                                                ]
                                                                                            }
                                                                                        ]
                                                                                    }
                                                                                ]
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        "kind": "ClientExtension",
                                                        "selections": [
                                                            {
                                                                "kind": "ScalarField",
                                                                "name": "isHighlightedIssueRow"
                                                            }
                                                        ]
                                                    }
                                                ]
                                            },
                                            v20,
                                            v76
                                        ]
                                    },
                                    {
                                        "kind": "ScalarField",
                                        "name": "totalIssueSearchResultCount"
                                    },
                                    {
                                        "args": [
                                            {
                                                "kind": "Literal",
                                                "name": "maxCursors",
                                                "value": 7
                                            }
                                        ],
                                        "concreteType": "JiraPageCursors",
                                        "kind": "LinkedField",
                                        "name": "pageCursors",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "concreteType": "JiraPageCursor",
                                                "kind": "LinkedField",
                                                "name": "around",
                                                "plural": true,
                                                "selections": [
                                                    v77,
                                                    v78,
                                                    v79
                                                ]
                                            },
                                            {
                                                "concreteType": "JiraPageCursor",
                                                "kind": "LinkedField",
                                                "name": "last",
                                                "plural": false,
                                                "selections": [
                                                    v79,
                                                    v77,
                                                    v78
                                                ]
                                            },
                                            {
                                                "concreteType": "JiraPageCursor",
                                                "kind": "LinkedField",
                                                "name": "first",
                                                "plural": false,
                                                "selections": [
                                                    v77,
                                                    v79,
                                                    v78
                                                ]
                                            }
                                        ],
                                        "storageKey": "pageCursors(maxCursors:7)"
                                    },
                                    {
                                        "concreteType": "JiraIssueNavigatorPageInfo",
                                        "kind": "LinkedField",
                                        "name": "issueNavigatorPageInfo",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "ScalarField",
                                                "name": "firstIssuePosition"
                                            },
                                            {
                                                "kind": "ScalarField",
                                                "name": "lastIssuePosition"
                                            },
                                            {
                                                "kind": "ScalarField",
                                                "name": "firstIssueKeyFromNextPage"
                                            },
                                            {
                                                "kind": "ScalarField",
                                                "name": "lastIssueKeyFromPreviousPage"
                                            }
                                        ]
                                    },
                                    {
                                        "kind": "LinkedField",
                                        "name": "issueSearchError",
                                        "plural": false,
                                        "selections": [
                                            v20,
                                            {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "messages"
                                                    }
                                                ],
                                                "type": "JiraInvalidJqlError"
                                            },
                                            {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                    v37,
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "errorType"
                                                    }
                                                ],
                                                "type": "JiraInvalidSyntaxError"
                                            }
                                        ]
                                    },
                                    {
                                        "kind": "ScalarField",
                                        "name": "isCappingIssueSearchResult"
                                    },
                                    v76,
                                    {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": [
                                            {
                                                "concreteType": "JiraIssueEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                    {
                                                        "concreteType": "JiraIssue",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "args": v28,
                                                                "concreteType": "JiraIssueFieldSetConnection",
                                                                "kind": "LinkedField",
                                                                "name": "fieldSetsForIssueSearchView",
                                                                "plural": false,
                                                                "selections": [
                                                                    {
                                                                        "concreteType": "JiraIssueFieldSetEdge",
                                                                        "kind": "LinkedField",
                                                                        "name": "edges",
                                                                        "plural": true,
                                                                        "selections": [
                                                                            {
                                                                                "concreteType": "JiraIssueFieldSet",
                                                                                "kind": "LinkedField",
                                                                                "name": "node",
                                                                                "plural": false,
                                                                                "selections": [
                                                                                    {
                                                                                        "concreteType": "JiraIssueFieldConnection",
                                                                                        "kind": "LinkedField",
                                                                                        "name": "fields",
                                                                                        "plural": false,
                                                                                        "selections": [
                                                                                            {
                                                                                                "concreteType": "JiraIssueFieldEdge",
                                                                                                "kind": "LinkedField",
                                                                                                "name": "edges",
                                                                                                "plural": true,
                                                                                                "selections": [
                                                                                                    {
                                                                                                        "kind": "LinkedField",
                                                                                                        "name": "node",
                                                                                                        "plural": false,
                                                                                                        "selections": [
                                                                                                            v35,
                                                                                                            v21
                                                                                                        ]
                                                                                                    }
                                                                                                ]
                                                                                            }
                                                                                        ]
                                                                                    }
                                                                                ]
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "args": [
                                    v16,
                                    v24,
                                    v17,
                                    v26,
                                    v27
                                ],
                                "kind": "LinkedField",
                                "name": "issueSearchViewResult",
                                "plural": false,
                                "selections": [
                                    v20,
                                    {
                                        "kind": "TypeDiscriminator",
                                        "abstractKey": "__isJiraIssueSearchViewResult"
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            {
                                                "kind": "ScalarField",
                                                "name": "viewId"
                                            },
                                            {
                                                "args": [
                                                    {
                                                        "kind": "Literal",
                                                        "name": "filter",
                                                        "value": {
                                                            "fieldSetSelectedState": "SELECTED"
                                                        }
                                                    },
                                                    v25
                                                ],
                                                "concreteType": "JiraIssueSearchFieldSetConnection",
                                                "kind": "LinkedField",
                                                "name": "fieldSets",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "concreteType": "JiraIssueSearchFieldSetEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                            {
                                                                "concreteType": "JiraIssueSearchFieldSet",
                                                                "kind": "LinkedField",
                                                                "name": "node",
                                                                "plural": false,
                                                                "selections": [
                                                                    v34,
                                                                    v33,
                                                                    v80,
                                                                    {
                                                                        "kind": "ScalarField",
                                                                        "name": "jqlTerm"
                                                                    },
                                                                    {
                                                                        "kind": "ScalarField",
                                                                        "name": "isSortable"
                                                                    },
                                                                    {
                                                                        "concreteType": "JiraFieldSetPreferences",
                                                                        "kind": "LinkedField",
                                                                        "name": "fieldSetPreferences",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            {
                                                                                "kind": "ScalarField",
                                                                                "name": "width"
                                                                            }
                                                                        ]
                                                                    },
                                                                    {
                                                                        "concreteType": "JiraFieldType",
                                                                        "kind": "LinkedField",
                                                                        "name": "fieldType",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            v80
                                                                        ]
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    },
                                                    v52,
                                                    v76
                                                ]
                                            },
                                            v19,
                                            {
                                                "kind": "ScalarField",
                                                "name": "hasDefaultFieldSets"
                                            },
                                            {
                                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider",
                                                "kind": "Condition",
                                                "passingValue": true,
                                                "selections": [
                                                    {
                                                        "args": [
                                                            {
                                                                "kind": "Variable",
                                                                "name": "staticViewInput",
                                                                "variableName": "staticViewInput"
                                                            }
                                                        ],
                                                        "concreteType": "JiraIssueSearchViewConfigSettings",
                                                        "kind": "LinkedField",
                                                        "name": "viewConfigSettings",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "kind": "ScalarField",
                                                                "name": "isHierarchyEnabled"
                                                            },
                                                            {
                                                                "kind": "ScalarField",
                                                                "name": "canEnableHierarchy"
                                                            },
                                                            v76
                                                        ]
                                                    }
                                                ]
                                            }
                                        ],
                                        "type": "JiraIssueSearchView"
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            v37,
                                            {
                                                "kind": "LinkedField",
                                                "name": "extensions",
                                                "plural": true,
                                                "selections": [
                                                    v20,
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "statusCode"
                                                    }
                                                ]
                                            }
                                        ],
                                        "type": "QueryError"
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            v19
                                        ],
                                        "type": "Node",
                                        "abstractKey": "__isNode"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "params": {
                "id": "df99dbc835defd8c5d95ee28505a6f59c1febbcb8147f48b24b7c040d5325621",
                "metadata": {},
                "name": "IssueNavigatorResultsRefetchQuery",
                "operationKind": "query",
                "text": null,
                "providedVariables": {
                    "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider": (0, (0, parcelRequire("bqIzm")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider": (0, (0, parcelRequire("5y5Fx")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider": (0, (0, parcelRequire("6vKPN")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": (0, (0, parcelRequire("1kHcz")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider": (0, (0, parcelRequire("hLeia")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider": (0, (0, parcelRequire("G39h7")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider": (0, (0, parcelRequire("1ZvTA")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": (0, (0, parcelRequire("5oQtH")).default)
                }
            }
        };
    }();
    $894e01c189a8309f$var$node.hash = "a8a5b16b53504d70b85e1406e1fc87ca";
    var $894e01c189a8309f$export$2e2bcd8739ae039 = $894e01c189a8309f$var$node;
});
parcelRegister("l5FbD", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$5c45b6cdf0660508$export$2e2bcd8739ae039);
    const $5c45b6cdf0660508$var$node = {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
            }
        ],
        "kind": "Fragment",
        "name": "main_issueNavigator_IssueNavigator_issueQuery",
        "selections": [
            {
                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                    {
                        "kind": "FragmentSpread",
                        "name": "main_issueNavigator_IssueNavigator_refetchQueryOld"
                    }
                ]
            },
            {
                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                    {
                        "kind": "FragmentSpread",
                        "name": "main_issueNavigator_IssueNavigator_refetchQueryNew"
                    }
                ]
            }
        ],
        "type": "Query"
    };
    $5c45b6cdf0660508$var$node.hash = "0296d13020f68034a057103bb2c3501c";
    var $5c45b6cdf0660508$export$2e2bcd8739ae039 = $5c45b6cdf0660508$var$node;
});
parcelRegister("a8lIB", function(module, exports) {
    $parcel$export(module.exports, "marks", ()=>$9dd1b22816aa1595$export$3a8025f2f3e0c64);
    $parcel$export(module.exports, "NINJqlBuilderAsyncLoad", ()=>$9dd1b22816aa1595$export$8ba5b9245ec3f6f9);
    $parcel$export(module.exports, "NINPageLoad", ()=>$9dd1b22816aa1595$export$248c6c208e271182);
    $parcel$export(module.exports, "markOnce", ()=>$9dd1b22816aa1595$export$243d158112bae094);
    var $dGrdR;
    var $8vNru;
    const $9dd1b22816aa1595$export$3a8025f2f3e0c64 = {
        ISSUE_NAVIGATOR_START: 'ISSUE_NAVIGATOR_START',
        ISSUE_NAVIGATOR_END: 'ISSUE_NAVIGATOR_END',
        ISSUE_NAVIGATOR_UI_START: 'ISSUE_NAVIGATOR_UI_START',
        ISSUE_NAVIGATOR_UI_END: 'ISSUE_NAVIGATOR_UI_END',
        PAGE_ACTIONS_START: 'PAGE_ACTIONS_START',
        PAGE_ACTIONS_END: 'PAGE_ACTIONS_END',
        PAGE_ACTIONS_VIEW_SWITCHER_START: 'PAGE_ACTIONS_VIEW_SWITCHER_START',
        PAGE_ACTIONS_VIEW_SWITCHER_END: 'PAGE_ACTIONS_VIEW_SWITCHER_END',
        REFINEMENT_START: 'REFINEMENT_START',
        REFINEMENT_END: 'REFINEMENT_END',
        ISSUE_RESULTS_START: 'ISSUE_RESULTS_START',
        ISSUE_RESULTS_END: 'ISSUE_RESULTS_END',
        ISSUE_RESULTS_DETAIL_VIEW_START: 'ISSUE_RESULTS_DETAIL_VIEW_START',
        ISSUE_RESULTS_DETAIL_VIEW_END: 'ISSUE_RESULTS_DETAIL_VIEW_END',
        ISSUE_RESULTS_DETAIL_VIEW_CARD_LIST_START: 'ISSUE_RESULTS_DETAIL_VIEW_CARD_LIST_START',
        ISSUE_RESULTS_DETAIL_VIEW_CARD_LIST_END: 'ISSUE_RESULTS_DETAIL_VIEW_CARD_LIST_END',
        ISSUE_RESULTS_LIST_VIEW_START: 'ISSUE_RESULTS_LIST_VIEW_START',
        ISSUE_RESULTS_LIST_VIEW_END: 'ISSUE_RESULTS_LIST_VIEW_END',
        ISSUE_RESULTS_LIST_VIEW_FOOTER_START: 'ISSUE_RESULTS_LIST_VIEW_FOOTER_START',
        ISSUE_RESULTS_LIST_VIEW_FOOTER_END: 'ISSUE_RESULTS_LIST_VIEW_FOOTER_END',
        ISSUE_APP_START: 'ISSUE_APP_START',
        ISSUE_APP_END: 'ISSUE_APP_END',
        ...(0, (0, parcelRequire("8vNru")).JQL_BUILDER_PERFORMANCE_MARKS)
    };
    const $9dd1b22816aa1595$var$featureFlags = [
        'nin_expose_text_option_jql_builder_4kwzo'
    ];
    const $9dd1b22816aa1595$export$8ba5b9245ec3f6f9 = (0, (0, parcelRequire("dGrdR")).metrics).pageSegmentLoad({
        key: 'new-issue-navigator-jql-builder',
        timings: [
            {
                key: 'product/issue-navigator/ui/jql-builder',
                startMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.JQL_BUILDER_START,
                endMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.JQL_BUILDER_END
            },
            {
                key: 'product/issue-navigator/ui/jql-builder/hydrate',
                startMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.JQL_BUILDER_HYDRATE_START,
                endMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.JQL_BUILDER_HYDRATE_END
            },
            {
                key: 'product/issue-navigator/ui/jql-builder/jql-builder-basic',
                startMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.JQL_BUILDER_BASIC_START,
                endMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.JQL_BUILDER_BASIC_END
            },
            {
                key: 'product/issue-navigator/ui/jql-builder/jql-builder-basic/js',
                startMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.JQL_BUILDER_BASIC_JS_START,
                endMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.JQL_BUILDER_BASIC_JS_END
            },
            {
                key: 'product/issue-navigator/ui/jql-builder/jql-builder-advanced',
                startMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.JQL_BUILDER_ADVANCED_START,
                endMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.JQL_BUILDER_ADVANCED_END
            },
            {
                key: 'product/issue-navigator/ui/jql-builder/jql-builder-advanced/js',
                startMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.JQL_BUILDER_ADVANCED_JS_START,
                endMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.JQL_BUILDER_ADVANCED_JS_END
            }
        ],
        histogram: {
            initial: {
                fmp: '100_200_500_1000_2500_3000_4000_5000_10000',
                tti: '100_200_500_1000_2500_3000_4000_5000_10000'
            },
            transition: {
                fmp: '100_200_500_1000_2500_3000_4000_5000_10000',
                tti: '100_200_500_1000_2500_3000_4000_5000_10000'
            }
        },
        featureFlags: $9dd1b22816aa1595$var$featureFlags
    });
    const $9dd1b22816aa1595$export$248c6c208e271182 = (0, (0, parcelRequire("dGrdR")).metrics).pageLoad({
        key: 'new-issue-navigator',
        slo: {
            fmp: {
                initial: {
                    threshold: 3000
                },
                transition: {
                    threshold: 3000
                }
            },
            tti: {
                initial: {
                    threshold: 4000
                },
                transition: {
                    threshold: 4000
                }
            }
        },
        ssr: {
            doneAsFmp: true
        },
        timings: [
            {
                key: 'product/issue-navigator',
                startMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.ISSUE_NAVIGATOR_START,
                endMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.ISSUE_NAVIGATOR_END
            },
            {
                key: 'product/issue-navigator/ui',
                startMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.ISSUE_NAVIGATOR_UI_START,
                endMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.ISSUE_NAVIGATOR_UI_END
            },
            {
                key: 'product/issue-navigator/ui/page-actions',
                startMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.PAGE_ACTIONS_START,
                endMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.PAGE_ACTIONS_END
            },
            {
                key: 'product/issue-navigator/ui/page-actions/view-switcher',
                startMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.PAGE_ACTIONS_VIEW_SWITCHER_START,
                endMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.PAGE_ACTIONS_VIEW_SWITCHER_END
            },
            {
                key: 'product/issue-navigator/ui/refinement',
                startMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.REFINEMENT_START,
                endMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.REFINEMENT_END
            },
            {
                key: 'product/issue-navigator/ui/issue-results',
                startMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.ISSUE_RESULTS_START,
                endMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.ISSUE_RESULTS_END
            },
            {
                key: 'product/issue-navigator/ui/issue-results/detail-view',
                startMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.ISSUE_RESULTS_DETAIL_VIEW_START,
                endMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.ISSUE_RESULTS_DETAIL_VIEW_END
            },
            {
                key: 'product/issue-navigator/ui/issue-results/detail-view/card-list',
                startMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.ISSUE_RESULTS_DETAIL_VIEW_CARD_LIST_START,
                endMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.ISSUE_RESULTS_DETAIL_VIEW_CARD_LIST_END
            },
            {
                key: 'product/issue-navigator/ui/issue-results/list-view',
                startMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.ISSUE_RESULTS_LIST_VIEW_START,
                endMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.ISSUE_RESULTS_LIST_VIEW_END
            },
            {
                key: 'product/issue-navigator/ui/issue-results/list-view/footer',
                startMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.ISSUE_RESULTS_LIST_VIEW_FOOTER_START,
                endMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.ISSUE_RESULTS_LIST_VIEW_FOOTER_END
            },
            {
                key: 'product/issue-navigator/ui/issue-app',
                startMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.ISSUE_APP_START,
                endMark: $9dd1b22816aa1595$export$3a8025f2f3e0c64.ISSUE_APP_END
            }
        ],
        featureFlags: $9dd1b22816aa1595$var$featureFlags
    });
    const $9dd1b22816aa1595$var$consumedMarks = [];
    const $9dd1b22816aa1595$export$243d158112bae094 = (mark)=>{
        if (!$9dd1b22816aa1595$var$consumedMarks.includes(mark)) {
            $9dd1b22816aa1595$var$consumedMarks.push(mark);
            $9dd1b22816aa1595$export$248c6c208e271182.mark(mark);
            $9dd1b22816aa1595$export$8ba5b9245ec3f6f9.mark(mark);
        }
    };
});
parcelRegister("8vNru", function(module, exports) {
    $parcel$export(module.exports, "JQL_BUILDER_PERFORMANCE_MARKS", ()=>$491ce87882605bf7$export$5fd6d8ece357700d);
    const $491ce87882605bf7$var$JQL_BUILDER_START = 'JQL_BUILDER_START';
    const $491ce87882605bf7$var$JQL_BUILDER_END = 'JQL_BUILDER_END';
    const $491ce87882605bf7$var$JQL_BUILDER_HYDRATE_START = 'JQL_BUILDER_HYDRATE_START';
    const $491ce87882605bf7$var$JQL_BUILDER_HYDRATE_END = 'JQL_BUILDER_HYDRATE_END';
    const $491ce87882605bf7$var$JQL_BUILDER_BASIC_START = 'JQL_BUILDER_BASIC_START';
    const $491ce87882605bf7$var$JQL_BUILDER_BASIC_END = 'JQL_BUILDER_BASIC_END';
    const $491ce87882605bf7$var$JQL_BUILDER_BASIC_JS_START = 'JQL_BUILDER_BASIC_JS_START';
    const $491ce87882605bf7$var$JQL_BUILDER_BASIC_JS_END = 'JQL_BUILDER_BASIC_JS_END';
    const $491ce87882605bf7$var$JQL_BUILDER_ADVANCED_START = 'JQL_BUILDER_ADVANCED_START';
    const $491ce87882605bf7$var$JQL_BUILDER_ADVANCED_END = 'JQL_BUILDER_ADVANCED_END';
    const $491ce87882605bf7$var$JQL_BUILDER_ADVANCED_JS_START = 'JQL_BUILDER_ADVANCED_JS_START';
    const $491ce87882605bf7$var$JQL_BUILDER_ADVANCED_JS_END = 'JQL_BUILDER_ADVANCED_JS_END';
    const $491ce87882605bf7$export$5fd6d8ece357700d = {
        JQL_BUILDER_START: $491ce87882605bf7$var$JQL_BUILDER_START,
        JQL_BUILDER_END: $491ce87882605bf7$var$JQL_BUILDER_END,
        JQL_BUILDER_HYDRATE_START: $491ce87882605bf7$var$JQL_BUILDER_HYDRATE_START,
        JQL_BUILDER_HYDRATE_END: $491ce87882605bf7$var$JQL_BUILDER_HYDRATE_END,
        JQL_BUILDER_BASIC_JS_START: $491ce87882605bf7$var$JQL_BUILDER_BASIC_JS_START,
        JQL_BUILDER_BASIC_JS_END: $491ce87882605bf7$var$JQL_BUILDER_BASIC_JS_END,
        JQL_BUILDER_BASIC_START: $491ce87882605bf7$var$JQL_BUILDER_BASIC_START,
        JQL_BUILDER_BASIC_END: $491ce87882605bf7$var$JQL_BUILDER_BASIC_END,
        JQL_BUILDER_ADVANCED_JS_START: $491ce87882605bf7$var$JQL_BUILDER_ADVANCED_JS_START,
        JQL_BUILDER_ADVANCED_JS_END: $491ce87882605bf7$var$JQL_BUILDER_ADVANCED_JS_END,
        JQL_BUILDER_ADVANCED_START: $491ce87882605bf7$var$JQL_BUILDER_ADVANCED_START,
        JQL_BUILDER_ADVANCED_END: $491ce87882605bf7$var$JQL_BUILDER_ADVANCED_END
    };
});
parcelRegister("1z7Yr", function(module, exports) {
    $parcel$export(module.exports, "useAppContextualAnalyticsActions", ()=>$48ac6de4ef0bf23b$export$8aad1a4e4d637906);
    $parcel$export(module.exports, "AppContextualAnalytics", ()=>$48ac6de4ef0bf23b$export$2942dddca20a92ac);
    var $5uXOq = parcelRequire("5uXOq");
    var $7Jxgt = parcelRequire("7Jxgt");
    var $iXVV6;
    var $3sQ5x;
    var $gg7kZ;
    var $iXqE5;
    const $48ac6de4ef0bf23b$var$Container = (0, (0, parcelRequire("gg7kZ")).createContainer)();
    const $48ac6de4ef0bf23b$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        containedBy: $48ac6de4ef0bf23b$var$Container,
        initialState: {
            issueNavigatorSearchSessionId: undefined
        },
        actions: {
            onJourneyStart: ()=>({ setState: setState })=>{
                    const issueNavigatorSearchSessionId = (0, ($parcel$interopDefault($7Jxgt)))();
                    setState({
                        issueNavigatorSearchSessionId: issueNavigatorSearchSessionId
                    });
                    return {
                        issueNavigatorSearchSessionId: issueNavigatorSearchSessionId
                    };
                }
        }
    });
    const $48ac6de4ef0bf23b$export$e1e7a4797df1a656 = (0, (0, parcelRequire("3sQ5x")).createHook)($48ac6de4ef0bf23b$var$Store, {
        selector: (state)=>state.issueNavigatorSearchSessionId
    });
    const $48ac6de4ef0bf23b$export$8aad1a4e4d637906 = (0, (0, parcelRequire("3sQ5x")).createActionsHook)($48ac6de4ef0bf23b$var$Store);
    const $48ac6de4ef0bf23b$var$AppContextualAnalyticsData = ({ children: children })=>{
        const [issueNavigatorSearchSessionId] = $48ac6de4ef0bf23b$export$e1e7a4797df1a656();
        const attributes = (0, $5uXOq.useMemo)(()=>({
                issueNavigatorSearchSessionId: issueNavigatorSearchSessionId
            }), [
            issueNavigatorSearchSessionId
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            sourceName: "issueNavigator",
            sourceType: (0, $4d5e0871c06cee03$export$f636b5b56f1d624a),
            attributes: attributes
        }, children);
    };
    const $48ac6de4ef0bf23b$export$2942dddca20a92ac = ({ children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement($48ac6de4ef0bf23b$var$Container, null, (0, ($parcel$interopDefault($5uXOq))).createElement($48ac6de4ef0bf23b$var$AppContextualAnalyticsData, null, children));
});
const $4d5e0871c06cee03$export$f636b5b56f1d624a = 'Screen';
const $4d5e0871c06cee03$export$9d2de6eebf7462b5 = 'Drawer';
const $4d5e0871c06cee03$export$1171103ea9083ffa = 'Modal';
const $4d5e0871c06cee03$export$cae2783c9279926 = 'InlineDialog';
const $4d5e0871c06cee03$export$d1123261ca8831d2 = 'Dropdown';
const $4d5e0871c06cee03$export$238d30785c4bca84 = 'Banner';
const $4d5e0871c06cee03$export$a1562d08625f5d7e = 'UI';
const $4d5e0871c06cee03$export$947dac6c72b26f81 = 'TRACK';
const $4d5e0871c06cee03$export$f1d537d508d2945a = 'SCREEN';
const $4d5e0871c06cee03$export$12346a674644c156 = 'OPERATIONAL';
parcelRegister("gtUFk", function(module, exports) {
    $parcel$export(module.exports, "selectionState", ()=>$3b394fabc3b44251$export$a01d720722806cd3);
    $parcel$export(module.exports, "SelectedIssueContainer", ()=>$3b394fabc3b44251$export$8b7bb55917259a5d);
    $parcel$export(module.exports, "useSelectedIssueKey", ()=>$3b394fabc3b44251$export$7cedcbfb2da7e49d);
    $parcel$export(module.exports, "useSelectedIssueIndex", ()=>$3b394fabc3b44251$export$a4b375f8f9b094ad);
    $parcel$export(module.exports, "useFocusedIssueIndex", ()=>$3b394fabc3b44251$export$4399fa4a8958cb76);
    $parcel$export(module.exports, "useSelectedIssueDebouncedState", ()=>$3b394fabc3b44251$export$ed5cb04ac9d88e95);
    $parcel$export(module.exports, "useSelectedIssueStateOldActions", ()=>$3b394fabc3b44251$export$6f74178a4465fc7e);
    $parcel$export(module.exports, "useIssueSelectionState", ()=>$3b394fabc3b44251$export$d703c03c23d04b26);
    $parcel$export(module.exports, "useIsForcedSelectionOnReset", ()=>$3b394fabc3b44251$export$800a67a9f72a46a2);
    $parcel$export(module.exports, "useIsFullPageIssueAppMode", ()=>$3b394fabc3b44251$export$988cbddd89e853c0);
    var $iLtRV;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $g5X3G;
    var $3sQ5x;
    var $gg7kZ;
    var $iXqE5;
    var $6UAdF;
    var $eJ0Zs;
    var $isIOR;
    var $ih9zY;
    var $1On4W;
    const $3b394fabc3b44251$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: {
            _rememoize: Date.now(),
            searchKey: '',
            selectedIssue: [
                null,
                (0, (0, parcelRequire("g5X3G")).toIssueKey)('')
            ],
            focusedIssue: null,
            nextResetStrategy: (0, $f455add29e1ec026$export$6953d2fd44491a2f),
            isFullPageIssueAppMode: false
        },
        actions: (0, parcelRequire("1On4W")).default,
        name: 'IssueNavigatorSelectedIssueState'
    });
    const $3b394fabc3b44251$export$a01d720722806cd3 = {
        ISSUE_SELECTED: 'ISSUE_SELECTED',
        BLANK_ISSUE_SELECTED: 'BLANK_ISSUE_SELECTED',
        NO_SELECTION: 'NO_SELECTION'
    };
    const $3b394fabc3b44251$var$getSelectedIndex = (issueKeys, selectedIssueKey)=>{
        const index = selectedIssueKey && issueKeys ? issueKeys.indexOf(selectedIssueKey) : null;
        if (index !== null && index >= 0) return index;
        return null;
    };
    const $3b394fabc3b44251$var$Container = (0, (0, parcelRequire("gg7kZ")).createContainer)($3b394fabc3b44251$var$Store, {
        onInit: ()=>({ dispatch: dispatch, setState: setState }, { selectedIssueKey: selectedIssueKey, issueKeys: issueKeys, searchKey: searchKey, view: view })=>{
                const isFullPageIssueAppMode = Boolean(selectedIssueKey) && view === (0, (0, parcelRequire("6UAdF")).views).list;
                const initialIssueKey = selectedIssueKey || dispatch((0, (0, parcelRequire("1On4W")).privateActions).getDefaultIssueKey()) || (0, (0, parcelRequire("g5X3G")).toIssueKey)('');
                const initialIndex = $3b394fabc3b44251$var$getSelectedIndex(issueKeys, initialIssueKey);
                setState({
                    _rememoize: Date.now(),
                    selectedIssue: [
                        initialIndex,
                        initialIssueKey
                    ],
                    searchKey: searchKey,
                    isFullPageIssueAppMode: isFullPageIssueAppMode
                });
            },
        onUpdate: ()=>({ getState: getState, setState: setState, dispatch: dispatch }, { selectedIssueKey: nextIssueKey, isFetching: isFetching, searchKey: searchKey, firstIssueKeyFromNextPage: firstIssueKeyFromNextPage, lastIssueKeyFromPreviousPage: lastIssueKeyFromPreviousPage })=>{
                if (isFetching) return;
                const state = getState();
                if (searchKey !== state.searchKey) {
                    dispatch((0, (0, parcelRequire("1On4W")).privateActions).resetSelectedIssue(!!nextIssueKey));
                    setState({
                        searchKey: searchKey
                    });
                } else if (nextIssueKey) {
                    dispatch($3b394fabc3b44251$var$Store.actions.setSelectedIssueByKey(nextIssueKey, false, false, false));
                    if (dispatch((0, (0, parcelRequire("1On4W")).privateActions).isListViewWithoutIssueApp())) dispatch($3b394fabc3b44251$var$Store.actions.enterFullPageIssueAppMode());
                    if (nextIssueKey === firstIssueKeyFromNextPage) dispatch($3b394fabc3b44251$var$Store.actions.selectFirstIssueOnNextPage());
                    else if (nextIssueKey === lastIssueKeyFromPreviousPage) dispatch($3b394fabc3b44251$var$Store.actions.selectLastIssueOnPreviousPage());
                } else if (dispatch((0, (0, parcelRequire("1On4W")).privateActions).isListViewWithIssueAppAndKey())) dispatch($3b394fabc3b44251$var$Store.actions.exitFullPageIssueAppMode());
            }
    });
    const $3b394fabc3b44251$export$8b7bb55917259a5d = ({ selectedIssueKey: selectedIssueKey, issueResults: issueResults, ...props })=>{
        const [{ view: view }] = (0, (0, parcelRequire("ih9zY")).useSelectedViewState)();
        const { isFetching: isFetching, searchKey: searchKey, onIssueSearchForPage: onIssueSearchForPage, onIssueSearchForNextPage: onIssueSearchForNextPage, onIssueSearchForPreviousPage: onIssueSearchForPreviousPage } = (0, (0, parcelRequire("isIOR")).useIssueSearchQuery)();
        const issueData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("iLtRV")).default), issueResults);
        const { firstIssuePosition: firstIssuePosition, lastIssuePosition: lastIssuePosition, firstIssueKeyFromNextPage: firstIssueKeyFromNextPage, lastIssueKeyFromPreviousPage: lastIssueKeyFromPreviousPage } = issueData?.issueNavigatorPageInfo ?? {};
        const issueKeys = (0, $5uXOq.useMemo)(()=>issueData?.edges?.map((edge)=>(0, (0, parcelRequire("g5X3G")).toIssueKey)(edge?.node?.key ?? '')) ?? [], [
            issueData?.edges
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($3b394fabc3b44251$var$Container, {
            isFetching: isFetching,
            searchKey: searchKey,
            selectedIssueKey: selectedIssueKey,
            issueKeys: issueKeys,
            view: (0, (0, parcelRequire("eJ0Zs")).convertToView)(view),
            onPageChange: onIssueSearchForPage,
            onNextPage: onIssueSearchForNextPage,
            onPreviousPage: onIssueSearchForPreviousPage,
            firstIssuePosition: firstIssuePosition ?? null,
            lastIssuePosition: lastIssuePosition ?? null,
            firstIssueKeyFromNextPage: firstIssueKeyFromNextPage ?? null,
            lastIssueKeyFromPreviousPage: lastIssueKeyFromPreviousPage ?? null,
            ...props
        });
    };
    const $3b394fabc3b44251$export$7cedcbfb2da7e49d = (0, (0, parcelRequire("3sQ5x")).createStateHook)($3b394fabc3b44251$var$Store, {
        selector: ({ selectedIssue: selectedIssue })=>selectedIssue[1]
    });
    const $3b394fabc3b44251$export$a4b375f8f9b094ad = (0, (0, parcelRequire("3sQ5x")).createStateHook)($3b394fabc3b44251$var$Store, {
        selector: ({ selectedIssue: selectedIssue })=>selectedIssue[0]
    });
    const $3b394fabc3b44251$export$4399fa4a8958cb76 = (0, (0, parcelRequire("3sQ5x")).createHook)($3b394fabc3b44251$var$Store, {
        selector: ({ focusedIssue: focusedIssue })=>focusedIssue
    });
    const $3b394fabc3b44251$export$ed5cb04ac9d88e95 = (0, (0, parcelRequire("3sQ5x")).createStateHook)($3b394fabc3b44251$var$Store, {
        selector: (0, (0, parcelRequire("eJ0Zs")).createRememoizeSelector)((state)=>state, ({ selectedIssue: selectedIssue })=>selectedIssue[1])
    });
    const $3b394fabc3b44251$export$6f74178a4465fc7e = (0, (0, parcelRequire("3sQ5x")).createActionsHook)($3b394fabc3b44251$var$Store);
    const $3b394fabc3b44251$export$d703c03c23d04b26 = (0, (0, parcelRequire("3sQ5x")).createHook)($3b394fabc3b44251$var$Store, {
        selector: ({ selectedIssue: selectedIssue })=>{
            if (selectedIssue[0] !== null && selectedIssue[1].length > 0) return $3b394fabc3b44251$export$a01d720722806cd3.ISSUE_SELECTED;
            if (selectedIssue[0] !== null && selectedIssue[1].length === 0) return $3b394fabc3b44251$export$a01d720722806cd3.BLANK_ISSUE_SELECTED;
            return $3b394fabc3b44251$export$a01d720722806cd3.NO_SELECTION;
        }
    });
    const $3b394fabc3b44251$export$800a67a9f72a46a2 = (0, (0, parcelRequire("3sQ5x")).createStateHook)($3b394fabc3b44251$var$Store, {
        selector: ({ nextResetStrategy: nextResetStrategy })=>nextResetStrategy === (0, $f455add29e1ec026$export$7438b768c88f1c5b) || nextResetStrategy === (0, $f455add29e1ec026$export$9d24b8100c251252)
    });
    const $3b394fabc3b44251$export$988cbddd89e853c0 = (0, (0, parcelRequire("3sQ5x")).createStateHook)($3b394fabc3b44251$var$Store, {
        selector: ({ isFullPageIssueAppMode: isFullPageIssueAppMode })=>isFullPageIssueAppMode
    });
});
parcelRegister("iLtRV", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$783350784ca64578$export$2e2bcd8739ae039);
    const $783350784ca64578$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "selectedIssueStateOld_issueNavigator_SelectedIssueContainer",
        "selections": [
            {
                "concreteType": "JiraIssueEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                    {
                        "concreteType": "JiraIssue",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "name": "key"
                            }
                        ]
                    }
                ]
            },
            {
                "concreteType": "JiraIssueNavigatorPageInfo",
                "kind": "LinkedField",
                "name": "issueNavigatorPageInfo",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "name": "firstIssuePosition"
                    },
                    {
                        "kind": "ScalarField",
                        "name": "lastIssuePosition"
                    },
                    {
                        "kind": "ScalarField",
                        "name": "firstIssueKeyFromNextPage"
                    },
                    {
                        "kind": "ScalarField",
                        "name": "lastIssueKeyFromPreviousPage"
                    }
                ]
            }
        ],
        "type": "JiraIssueConnection"
    };
    $783350784ca64578$var$node.hash = "337a019a583c3a28f545e54926fd4e34";
    var $783350784ca64578$export$2e2bcd8739ae039 = $783350784ca64578$var$node;
});
parcelRegister("isIOR", function(module, exports) {
    $parcel$export(module.exports, "useIssueSearchQuery", ()=>$51670fcdef73f961$export$11659072884978f5);
    $parcel$export(module.exports, "IssueSearchQueryProvider", ()=>$51670fcdef73f961$export$3f36a9df094c3109);
    var $dh0OE;
    var $94MjA;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Rkzz = parcelRequire("8Rkzz");
    var $8Uumt = parcelRequire("8Uumt");
    var $9Nvh7;
    var $1XJ20;
    var $6Z846;
    var $iXVV6;
    var $8zOmE;
    var $2NMNM;
    var $cZpsT;
    var $6UAdF;
    var $eJ0Zs;
    var $gwtSG;
    var $1GlUV;
    var $lavGr;
    var $foKFw;
    var $aHzNA;
    var $fgjcR;
    const $51670fcdef73f961$var$MAX_BACKFILL_RETRIES = 1;
    const $51670fcdef73f961$var$IssueSearchQueryContext = (0, $5uXOq.createContext)({
        isFetching: false,
        isRefreshing: false,
        hasViewIdChanged: false,
        isNetworkError: false,
        isStalePage: false,
        onDeleteIssue: ()=>'',
        onFetchUncappedTotalIssueCount: (0, ($parcel$interopDefault($8Rkzz))),
        onIssueSearch: (0, ($parcel$interopDefault($8Rkzz))),
        onIssueSearchForPage: (0, ($parcel$interopDefault($8Rkzz))),
        onIssueSearchForCurrentPage: (0, ($parcel$interopDefault($8Rkzz))),
        onIssueSearchForNextPage: ()=>false,
        onIssueSearchForPreviousPage: ()=>false,
        onIssueSearchForView: (0, ($parcel$interopDefault($8Rkzz))),
        onIssueSearchRefresh: (0, ($parcel$interopDefault($8Rkzz))),
        onIssueByFieldsRefetch: (0, ($parcel$interopDefault($8Rkzz))),
        searchKey: '',
        uncappedTotalIssueCount: {
            ...(0, (0, parcelRequire("foKFw")).uncappedTotalInitialState)
        }
    });
    $51670fcdef73f961$var$IssueSearchQueryContext.displayName = 'IssueSearchQueryContext';
    const $51670fcdef73f961$export$11659072884978f5 = ()=>(0, $5uXOq.useContext)($51670fcdef73f961$var$IssueSearchQueryContext);
    const $51670fcdef73f961$export$3f36a9df094c3109 = ({ refetch: refetch, issueResults: issueResults, view: view, query: query, onPageDataLoad: onPageDataLoad, onChangePage: onChangePage, children: children })=>{
        const issueResultsData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("94MjA")).default), issueResults);
        const viewData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("dh0OE")).default), view);
        const [isRefreshing, setIsRefreshing] = (0, $5uXOq.useState)(false);
        const { jql: jql, filterId: filterId } = (0, (0, parcelRequire("gwtSG")).useActiveJql)();
        const willFetch = (0, $5uXOq.useRef)(false);
        const selectedViewId = (0, $5uXOq.useRef)((0, (0, parcelRequire("eJ0Zs")).parseIssueNavigatorViewIdOrDefault)(viewData?.viewId, (0, (0, parcelRequire("6UAdF")).DEFAULT_VIEW_ID)));
        const issueSearchInput = (0, $5uXOq.useMemo)(()=>jql === undefined && (0, (0, parcelRequire("9Nvh7")).isFilterId)(filterId) ? {
                filterId: filterId
            } : {
                jql: jql
            }, [
            filterId,
            jql
        ]);
        const prevIssueSearchInput = (0, (0, parcelRequire("1XJ20")).usePreviousWithInitial)(issueSearchInput);
        const fieldSetIds = (0, $5uXOq.useMemo)(()=>viewData?.fieldSets?.edges.filter((0, (0, parcelRequire("eJ0Zs")).isNonNullish)).map(({ node: { fieldSetId: fieldSetId } })=>fieldSetId) ?? [], [
            viewData?.fieldSets?.edges
        ]);
        const { onIssueSearchFail: onIssueSearchFail } = (0, (0, parcelRequire("1GlUV")).useExperienceAnalytics)(onPageDataLoad, selectedViewId.current);
        const { isFetching: isFetching, hasViewIdChanged: hasViewIdChanged, isNetworkError: isNetworkError, onIssueSearchRefetch: onIssueSearchRefetch, onIssueByFieldsRefetch: onIssueByFieldsRefetch, searchKey: searchKey, uncappedTotalIssueCount: uncappedTotalIssueCount, onFetchUncappedTotalIssueCount: onFetchUncappedTotalIssueCount } = (0, (0, parcelRequire("lavGr")).useIssueSearchRefetch)(refetch, query, issueSearchInput, selectedViewId.current, issueResultsData?.__id, fieldSetIds, onIssueSearchFail);
        const onIssueSearch = (0, $5uXOq.useCallback)(()=>{
            onIssueSearchRefetch({}, {
                fetchPolicy: 'network-only'
            });
        }, [
            onIssueSearchRefetch
        ]);
        const hasIssueSearchInputChanged = !(0, (0, parcelRequire("6Z846")).isShallowEqual)(issueSearchInput, prevIssueSearchInput);
        if (hasIssueSearchInputChanged) willFetch.current = true;
        (0, $5uXOq.useEffect)(()=>{
            if (hasIssueSearchInputChanged) {
                willFetch.current = false;
                const shouldLoadWithFilterViewId = selectedViewId.current !== 'detail' && issueSearchInput.filterId && (0, (0, parcelRequire("9Nvh7")).isCustomFilter)(issueSearchInput.filterId);
                const isCurrentViewIdInvalid = (0, (0, parcelRequire("eJ0Zs")).isFilterViewId)(selectedViewId.current) && selectedViewId.current !== `list_filter_${filterId}`;
                if (issueSearchInput.filterId && shouldLoadWithFilterViewId) {
                    selectedViewId.current = (0, (0, parcelRequire("eJ0Zs")).convertFilterIdToIssueNavigatorId)(issueSearchInput.filterId);
                    onIssueSearchRefetch({
                        viewId: selectedViewId.current
                    }, {
                        fetchPolicy: 'network-only'
                    });
                } else if (isCurrentViewIdInvalid) {
                    selectedViewId.current = (0, (0, parcelRequire("6UAdF")).DEFAULT_VIEW_ID);
                    onIssueSearchRefetch({
                        viewId: selectedViewId.current
                    }, {
                        fetchPolicy: 'network-only'
                    });
                } else onIssueSearch();
            }
        }, [
            filterId,
            hasIssueSearchInputChanged,
            issueSearchInput,
            onIssueSearch,
            onIssueSearchRefetch,
            prevIssueSearchInput
        ]);
        const cursors = issueResultsData?.pageCursors?.around;
        const currentPageIndex = (0, $5uXOq.useMemo)(()=>cursors?.findIndex((cursor)=>cursor?.isCurrent) ?? -1, [
            cursors
        ]);
        const currentPage = currentPageIndex > -1 ? cursors?.[currentPageIndex] : undefined;
        const currentPageCursor = currentPage?.cursor ?? null;
        const currentPageNumber = currentPage?.pageNumber ?? 1;
        const onSelectedViewMutation = (0, (0, parcelRequire("aHzNA")).useSelectedViewMutation)();
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        const onIssueSearchForView = (0, $5uXOq.useCallback)((newViewId)=>{
            if (!isAnonymous && (0, (0, parcelRequire("eJ0Zs")).convertToView)(newViewId) !== (0, (0, parcelRequire("eJ0Zs")).convertToView)(selectedViewId.current)) onSelectedViewMutation(newViewId);
            selectedViewId.current = newViewId;
            const options = {
                isNewSearchKey: false,
                fetchPolicy: 'network-only'
            };
            onIssueSearchRefetch({
                after: currentPageCursor,
                viewId: selectedViewId.current
            }, options);
        }, [
            currentPageCursor,
            isAnonymous,
            onIssueSearchRefetch,
            onSelectedViewMutation
        ]);
        const onDeleteIssue = (0, (0, parcelRequire("fgjcR")).useOnDeleteIssue)(issueResultsData);
        const onIssueSearchForPage = (0, $5uXOq.useCallback)((after, options)=>{
            onIssueSearchRefetch({
                after: after
            }, options);
            onChangePage && onChangePage();
        }, [
            onChangePage,
            onIssueSearchRefetch
        ]);
        const onIssueSearchForNextPage = (0, $5uXOq.useCallback)((options)=>{
            if (currentPageIndex > -1) {
                const nextPage = cursors?.[currentPageIndex + 1];
                if (nextPage && nextPage.cursor != null) {
                    onIssueSearchForPage(nextPage.cursor, options);
                    return true;
                }
            }
            return false;
        }, [
            currentPageIndex,
            cursors,
            onIssueSearchForPage
        ]);
        const onIssueSearchForPreviousPage = (0, $5uXOq.useCallback)((options)=>{
            if (currentPageIndex > 0) {
                const previousPage = cursors?.[currentPageIndex - 1];
                if (previousPage && previousPage.cursor != null) {
                    onIssueSearchForPage(previousPage.cursor, options);
                    return true;
                }
            }
            return false;
        }, [
            currentPageIndex,
            cursors,
            onIssueSearchForPage
        ]);
        const onIssueSearchForCurrentPage = (0, $5uXOq.useCallback)(()=>{
            onIssueSearchRefetch({
                after: currentPageCursor
            }, {
                fetchPolicy: 'network-only',
                isNewSearchKey: false
            });
        }, [
            currentPageCursor,
            onIssueSearchRefetch
        ]);
        const onIssueSearchRefresh = (0, $5uXOq.useCallback)((pageNumber = currentPageNumber)=>{
            setIsRefreshing(true);
            onIssueSearchRefetch({
                first: pageNumber * (0, (0, parcelRequire("6UAdF")).MAX_ISSUES_PER_PAGE),
                last: (0, (0, parcelRequire("6UAdF")).MAX_ISSUES_PER_PAGE)
            }, {
                fetchPolicy: 'network-only',
                onComplete: ()=>{
                    setIsRefreshing(false);
                },
                onError: ()=>{
                    setIsRefreshing(false);
                },
                onUnsubscribe: ()=>{
                    setIsRefreshing(false);
                }
            });
        }, [
            currentPageNumber,
            onIssueSearchRefetch
        ]);
        const backfillRetryCount = (0, $5uXOq.useRef)(0);
        const edges = issueResultsData?.edges ?? [];
        const total = issueResultsData?.totalIssueSearchResultCount ?? 0;
        const isStalePage = total > 0 && edges.length === 0;
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const prevIssueResultsData = (0, (0, parcelRequire("1XJ20")).usePrevious)(issueResultsData);
        (0, $5uXOq.useLayoutEffect)(()=>{
            if (issueResultsData !== prevIssueResultsData) {
                if (isStalePage && backfillRetryCount.current <= $51670fcdef73f961$var$MAX_BACKFILL_RETRIES) {
                    const pageNumber = backfillRetryCount.current === $51670fcdef73f961$var$MAX_BACKFILL_RETRIES ? 1 : Math.ceil(total / (0, (0, parcelRequire("6UAdF")).MAX_ISSUES_PER_PAGE));
                    onIssueSearchRefresh(pageNumber);
                    backfillRetryCount.current += 1;
                    (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'issueSearchResults backfill');
                    return;
                }
                backfillRetryCount.current = 0;
            }
        }, [
            createAnalyticsEvent,
            issueResultsData,
            prevIssueResultsData,
            isStalePage,
            onIssueSearchRefresh,
            total
        ]);
        const contextValue = (0, $5uXOq.useMemo)(()=>({
                isFetching: isFetching || willFetch.current,
                isRefreshing: isRefreshing,
                hasViewIdChanged: hasViewIdChanged,
                isNetworkError: isNetworkError,
                isStalePage: isStalePage,
                searchKey: searchKey,
                onDeleteIssue: onDeleteIssue,
                onIssueSearch: onIssueSearch,
                onIssueSearchForPage: onIssueSearchForPage,
                onIssueSearchForCurrentPage: onIssueSearchForCurrentPage,
                onIssueSearchForNextPage: onIssueSearchForNextPage,
                onIssueSearchForPreviousPage: onIssueSearchForPreviousPage,
                onIssueSearchForView: onIssueSearchForView,
                onIssueSearchRefresh: onIssueSearchRefresh,
                onIssueByFieldsRefetch: onIssueByFieldsRefetch,
                onFetchUncappedTotalIssueCount: onFetchUncappedTotalIssueCount,
                uncappedTotalIssueCount: uncappedTotalIssueCount
            }), [
            isFetching,
            isRefreshing,
            hasViewIdChanged,
            isNetworkError,
            isStalePage,
            searchKey,
            onDeleteIssue,
            onIssueSearch,
            onIssueSearchForPage,
            onIssueSearchForCurrentPage,
            onIssueSearchForNextPage,
            onIssueSearchForPreviousPage,
            onIssueSearchForView,
            onIssueSearchRefresh,
            onIssueByFieldsRefetch,
            onFetchUncappedTotalIssueCount,
            uncappedTotalIssueCount
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            attributes: {
                issuesLoaded: total
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($51670fcdef73f961$var$IssueSearchQueryContext.Provider, {
            value: contextValue
        }, children));
    };
});
parcelRegister("dh0OE", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$8573f724cfbdbde5$export$2e2bcd8739ae039);
    const $8573f724cfbdbde5$var$node = {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "amountOfColumns"
            }
        ],
        "kind": "Fragment",
        "name": "issueSearchQuery_issueNavigator_IssueSearchQueryProvider_view",
        "selections": [
            {
                "kind": "RequiredField",
                "field": {
                    "kind": "ScalarField",
                    "name": "viewId"
                },
                "action": "THROW",
                "path": "viewId"
            },
            {
                "kind": "RequiredField",
                "field": {
                    "args": [
                        {
                            "kind": "Literal",
                            "name": "filter",
                            "value": {
                                "fieldSetSelectedState": "SELECTED"
                            }
                        },
                        {
                            "kind": "Variable",
                            "name": "first",
                            "variableName": "amountOfColumns"
                        }
                    ],
                    "concreteType": "JiraIssueSearchFieldSetConnection",
                    "kind": "LinkedField",
                    "name": "fieldSets",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "RequiredField",
                            "field": {
                                "concreteType": "JiraIssueSearchFieldSetEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "kind": "RequiredField",
                                        "field": {
                                            "concreteType": "JiraIssueSearchFieldSet",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "RequiredField",
                                                    "field": {
                                                        "kind": "ScalarField",
                                                        "name": "fieldSetId"
                                                    },
                                                    "action": "THROW",
                                                    "path": "fieldSets.edges.node.fieldSetId"
                                                }
                                            ]
                                        },
                                        "action": "THROW",
                                        "path": "fieldSets.edges.node"
                                    }
                                ]
                            },
                            "action": "THROW",
                            "path": "fieldSets.edges"
                        }
                    ]
                },
                "action": "THROW",
                "path": "fieldSets"
            }
        ],
        "type": "JiraIssueSearchView"
    };
    $8573f724cfbdbde5$var$node.hash = "73c821f489985ab33d96817dee8a6600";
    var $8573f724cfbdbde5$export$2e2bcd8739ae039 = $8573f724cfbdbde5$var$node;
});
parcelRegister("94MjA", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$3eb136e3833a201c$export$2e2bcd8739ae039);
    const $3eb136e3833a201c$var$node = function() {
        var v0 = {
            "kind": "ClientExtension",
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "__id"
                }
            ]
        };
        return {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "name": "issueSearchQuery_issueNavigator_IssueSearchQueryProvider_issueResults",
            "selections": [
                {
                    "concreteType": "JiraIssueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "concreteType": "JiraIssue",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "name": "key"
                                }
                            ]
                        },
                        v0
                    ]
                },
                {
                    "kind": "ScalarField",
                    "name": "totalIssueSearchResultCount"
                },
                {
                    "args": [
                        {
                            "kind": "Literal",
                            "name": "maxCursors",
                            "value": 7
                        }
                    ],
                    "concreteType": "JiraPageCursors",
                    "kind": "LinkedField",
                    "name": "pageCursors",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraPageCursor",
                            "kind": "LinkedField",
                            "name": "around",
                            "plural": true,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "name": "cursor"
                                },
                                {
                                    "kind": "ScalarField",
                                    "name": "isCurrent"
                                },
                                {
                                    "kind": "ScalarField",
                                    "name": "pageNumber"
                                }
                            ]
                        }
                    ],
                    "storageKey": "pageCursors(maxCursors:7)"
                },
                v0
            ],
            "type": "JiraIssueConnection"
        };
    }();
    $3eb136e3833a201c$var$node.hash = "be5101b94f9ee67c52f693de30818ccb";
    var $3eb136e3833a201c$export$2e2bcd8739ae039 = $3eb136e3833a201c$var$node;
});
parcelRegister("gwtSG", function(module, exports) {
    $parcel$export(module.exports, "useActiveJql", ()=>$403641b131a496d8$export$caeaf867313163e4);
    $parcel$export(module.exports, "ActiveJqlProvider", ()=>$403641b131a496d8$export$c69a9a3114157ae7);
    $parcel$export(module.exports, "useResolvedJql", ()=>$403641b131a496d8$export$23e17984875d36b4);
    var $5uXOq = parcelRequire("5uXOq");
    var $ebyGx = parcelRequire("ebyGx");
    var $9Nvh7;
    const $403641b131a496d8$var$ActiveJqlContext = (0, $5uXOq.createContext)({
        jql: ''
    });
    $403641b131a496d8$var$ActiveJqlContext.displayName = 'ActiveJqlContext';
    const $403641b131a496d8$export$caeaf867313163e4 = ()=>(0, $5uXOq.useContext)($403641b131a496d8$var$ActiveJqlContext);
    const $403641b131a496d8$export$c69a9a3114157ae7 = ({ jql: jql, filterId: filterId, isFilterEditable: isFilterEditable, filterJql: filterJql, children: children })=>{
        const contextValue = (0, $5uXOq.useMemo)(()=>({
                jql: jql,
                filterId: filterId,
                isFilterEditable: isFilterEditable,
                filterJql: filterJql
            }), [
            jql,
            filterId,
            isFilterEditable,
            filterJql
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($403641b131a496d8$var$ActiveJqlContext.Provider, {
            value: contextValue
        }, children);
    };
    const $403641b131a496d8$export$23e17984875d36b4 = ()=>{
        const { jql: jql, filterJql: filterJql } = $403641b131a496d8$export$caeaf867313163e4();
        const filter = filterJql !== undefined ? filterJql : '';
        return jql !== undefined ? jql : filter;
    };
    const $403641b131a496d8$export$5d3c8489d1ae8bee = ()=>{
        const { filterId: filterId, jql: jql } = $403641b131a496d8$export$caeaf867313163e4();
        return `/issues/?${(0, $ebyGx.stringify)({
            jql: jql,
            ...(0, (0, parcelRequire("9Nvh7")).isFilterId)(filterId) ? {
                filter: filterId
            } : {}
        })}`;
    };
});
parcelRegister("1GlUV", function(module, exports) {
    $parcel$export(module.exports, "useExperienceAnalytics", ()=>$5bd41227ba98547a$export$523b242362f30838);
    var $5uXOq = parcelRequire("5uXOq");
    var $lFKmC = parcelRequire("lFKmC");
    var $93jKl;
    var $kaHBi;
    var $3bQtK;
    var $6s1PB;
    var $8zOmE;
    var $2NMNM;
    var $6UAdF;
    const $5bd41227ba98547a$var$sharedDebouncer = (0, ($parcel$interopDefault($lFKmC)))((action)=>action(), 0);
    const $5bd41227ba98547a$export$523b242362f30838 = (onPageDataLoad, viewOld, attributes)=>{
        const onExperienceStart = (0, (0, parcelRequire("kaHBi")).useExperienceStart)({
            experience: (0, (0, parcelRequire("6UAdF")).EXPERIENCE_NAME),
            experienceId: undefined,
            analyticsSource: (0, (0, parcelRequire("6UAdF")).ANALYTICS_SOURCE)
        });
        const onExperienceSuccess = (0, (0, parcelRequire("3bQtK")).useExperienceSuccess)({
            experience: (0, (0, parcelRequire("6UAdF")).EXPERIENCE_NAME),
            attributes: attributes
        });
        const onExperienceFail = (0, (0, parcelRequire("93jKl")).useExperienceFail)({
            experience: (0, (0, parcelRequire("6UAdF")).EXPERIENCE_NAME),
            attributes: attributes
        });
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const onFireUIAnalyticsEvent = (0, $5uXOq.useCallback)(()=>{
            const event = createAnalyticsEvent({
                action: 'viewed',
                actionSubject: 'screen'
            });
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(event, 'issueSearchResults', attributes ?? {});
        }, [
            attributes,
            createAnalyticsEvent
        ]);
        const onIssueSearchSuccess = (0, $5uXOq.useCallback)((view)=>{
            $5bd41227ba98547a$var$sharedDebouncer(()=>{
                onExperienceStart();
                onExperienceSuccess();
                onFireUIAnalyticsEvent();
            });
            onPageDataLoad && onPageDataLoad((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false) ? view : viewOld);
        }, [
            onExperienceStart,
            onExperienceSuccess,
            onFireUIAnalyticsEvent,
            onPageDataLoad,
            viewOld
        ]);
        const onIssueSearchFail = (0, $5uXOq.useCallback)((location, error, view)=>{
            $5bd41227ba98547a$var$sharedDebouncer(()=>{
                onExperienceStart();
                onExperienceFail(location, error);
            });
            onPageDataLoad && onPageDataLoad((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false) ? view : viewOld);
        }, [
            onExperienceFail,
            onExperienceStart,
            onPageDataLoad,
            viewOld
        ]);
        return {
            onIssueSearchSuccess: onIssueSearchSuccess,
            onIssueSearchFail: onIssueSearchFail
        };
    };
});
parcelRegister("lavGr", function(module, exports) {
    $parcel$export(module.exports, "useIssueSearchRefetch", ()=>$aeb7b1acdf8f6e00$export$b550b6912621dc5d);
    var $5uXOq = parcelRequire("5uXOq");
    var $7nsd3 = parcelRequire("7nsd3");
    var $ajpes = parcelRequire("ajpes");
    var $8Uumt = parcelRequire("8Uumt");
    var $eiowN = parcelRequire("eiowN");
    var $7Jxgt = parcelRequire("7Jxgt");
    var $7HGHK;
    var $pa9q9;
    var $1XJ20;
    var $9OXo1;
    var $6UAdF;
    var $foKFw;
    var $6Hhfn;
    var $4M3mV;
    const $aeb7b1acdf8f6e00$var$searchErrorFlagId = (0, (0, parcelRequire("7HGHK")).toFlagId)('ISSUE_NAVIGATOR_SEARCH_ERROR_FLAG');
    const $aeb7b1acdf8f6e00$var$searchErrorFlag = {
        id: $aeb7b1acdf8f6e00$var$searchErrorFlagId,
        type: 'error',
        title: (0, (0, parcelRequire("4M3mV")).default).searchFailedTitle,
        description: (0, (0, parcelRequire("4M3mV")).default).searchFailedDescription
    };
    const $aeb7b1acdf8f6e00$export$b550b6912621dc5d = (refetch, query, issueSearchInput, viewId, connectionId, fieldSetIds, onIssueSearchFail)=>{
        const lastVariables = (0, $5uXOq.useRef)();
        const networkOnlyVariables = (0, $5uXOq.useRef)(new Set());
        const connectionIds = (0, $5uXOq.useRef)(new Set());
        const disposableOperations = (0, $5uXOq.useRef)(new Set());
        const [searchKey, setSearchKey] = (0, $5uXOq.useState)((0, ($parcel$interopDefault($7Jxgt)))());
        const [isFetching, setIsFetching] = (0, $5uXOq.useState)(false);
        const [isNetworkError, setNetworkError] = (0, $5uXOq.useState)(false);
        const { cloudId: cloudId } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const { uncappedTotalIssueCount: uncappedTotalIssueCount, resetTotalIssueCount: resetTotalIssueCount, onFetchUncappedTotalIssueCount: onFetchUncappedTotalIssueCount } = (0, (0, parcelRequire("foKFw")).useIssueSearchTotalCount)({
            cloudId: cloudId,
            issueSearchInput: issueSearchInput
        });
        const environment = (0, $8Uumt.useRelayEnvironment)();
        const { showFlag: showFlag, dismissFlag: dismissFlag } = (0, (0, parcelRequire("pa9q9")).useFlagsService)();
        const prevViewId = (0, (0, parcelRequire("1XJ20")).usePreviousWithInitial)(viewId);
        const prevFieldSetIds = (0, (0, parcelRequire("1XJ20")).usePreviousWithInitial)(fieldSetIds);
        const inFlightRequest = (0, $5uXOq.useRef)();
        const onDisposeOperations = (0, $5uXOq.useCallback)(()=>{
            disposableOperations.current.forEach((disposable)=>disposable.dispose());
            disposableOperations.current.clear();
        }, []);
        (0, $5uXOq.useEffect)(()=>onDisposeOperations, [
            onDisposeOperations
        ]);
        const onIssueByFieldsRefetch = (0, (0, parcelRequire("6Hhfn")).useIssueByFieldsRefetch)(fieldSetIds);
        const onInvalidateIssueConnection = (0, $5uXOq.useCallback)(()=>{
            (0, $eiowN.commitLocalUpdate)(environment, (store)=>{
                connectionIds.current.forEach((id)=>{
                    const connection = store.get(id);
                    connection && connection.invalidateRecord();
                });
                connectionIds.current.clear();
            });
        }, [
            environment
        ]);
        const onInvalidateAndDisposeIssueConnection = (0, $5uXOq.useCallback)(()=>{
            onInvalidateIssueConnection();
            onDisposeOperations();
        }, [
            onDisposeOperations,
            onInvalidateIssueConnection
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (!(0, ($parcel$interopDefault($ajpes)))(fieldSetIds, prevFieldSetIds) && viewId === prevViewId) onInvalidateIssueConnection();
            if (connectionId !== undefined) connectionIds.current.add(connectionId);
            else if (lastVariables.current) networkOnlyVariables.current.add(lastVariables.current);
        });
        const onIssueSearchRefetch = (0, $5uXOq.useCallback)((variables, options)=>{
            inFlightRequest.current?.unsubscribe();
            const finalVariables = {
                cloudId: cloudId,
                issueSearchInput: issueSearchInput,
                first: (0, (0, parcelRequire("6UAdF")).MAX_ISSUES_PER_PAGE),
                after: null,
                namespace: 'ISSUE_NAVIGATOR',
                viewId: viewId,
                options: null,
                fieldSetIds: [],
                shouldQueryFieldSetsById: false,
                amountOfColumns: (0, (0, parcelRequire("6UAdF")).MAX_AMOUNT_OF_COLUMNS),
                filterId: null,
                ...variables
            };
            setIsFetching(true);
            setNetworkError(false);
            dismissFlag($aeb7b1acdf8f6e00$var$searchErrorFlagId);
            let fetchPolicy = options?.fetchPolicy ?? 'store-or-network';
            networkOnlyVariables.current.forEach((value)=>{
                if ((0, ($parcel$interopDefault($ajpes)))(finalVariables, value)) {
                    fetchPolicy = 'network-only';
                    networkOnlyVariables.current.delete(value);
                }
            });
            if (fetchPolicy === 'network-only') onInvalidateAndDisposeIssueConnection();
            inFlightRequest.current = (0, $eiowN.fetchQuery)(environment, query, finalVariables, {
                fetchPolicy: fetchPolicy,
                networkCacheConfig: {
                    metadata: {
                        META_SLOW_ENDPOINT: true
                    }
                }
            }).subscribe({
                complete: ()=>{
                    const operation = (0, $eiowN.createOperationDescriptor)((0, $eiowN.getRequest)(query), finalVariables);
                    disposableOperations.current.add(environment.retain(operation));
                    lastVariables.current = finalVariables;
                    let hasStoreRefetchCompleted = false;
                    (0, ($parcel$interopDefault($7nsd3))).unstable_batchedUpdates(()=>{
                        refetch(finalVariables, {
                            fetchPolicy: 'store-only',
                            onComplete: ()=>{
                                if (hasStoreRefetchCompleted) return;
                                hasStoreRefetchCompleted = true;
                                setIsFetching(false);
                                options?.onComplete && options.onComplete();
                                if (options?.isNewSearchKey ?? true) {
                                    setSearchKey((0, ($parcel$interopDefault($7Jxgt)))());
                                    if (!finalVariables.after) resetTotalIssueCount();
                                }
                            }
                        });
                    });
                },
                error: (error)=>{
                    setIsFetching(false);
                    setNetworkError(true);
                    onIssueSearchFail(`${(0, (0, parcelRequire("6UAdF")).PACKAGE_NAME)}.issue-search-refetch`, error, viewId);
                    showFlag($aeb7b1acdf8f6e00$var$searchErrorFlag);
                    options?.onError && options.onError();
                },
                unsubscribe: ()=>{
                    options?.onUnsubscribe && options.onUnsubscribe();
                }
            });
        }, [
            cloudId,
            issueSearchInput,
            viewId,
            dismissFlag,
            environment,
            query,
            onInvalidateAndDisposeIssueConnection,
            refetch,
            resetTotalIssueCount,
            onIssueSearchFail,
            showFlag
        ]);
        return {
            isFetching: isFetching,
            hasViewIdChanged: viewId !== prevViewId,
            isNetworkError: isNetworkError,
            onFetchUncappedTotalIssueCount: onFetchUncappedTotalIssueCount,
            onIssueSearchRefetch: onIssueSearchRefetch,
            onIssueByFieldsRefetch: onIssueByFieldsRefetch,
            searchKey: searchKey,
            uncappedTotalIssueCount: uncappedTotalIssueCount
        };
    };
});
parcelRegister("foKFw", function(module, exports) {
    $parcel$export(module.exports, "uncappedTotalInitialState", ()=>$b9c1ec4b15549a72$export$31250fb09166d65c);
    $parcel$export(module.exports, "useIssueSearchTotalCount", ()=>$b9c1ec4b15549a72$export$409cb8d89f7323a6);
    var $fE00s;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    const $b9c1ec4b15549a72$export$31250fb09166d65c = {
        count: undefined,
        isFetching: false,
        isError: false
    };
    const $b9c1ec4b15549a72$export$409cb8d89f7323a6 = ({ cloudId: cloudId, issueSearchInput: issueSearchInput })=>{
        const environment = (0, $8Uumt.useRelayEnvironment)();
        const [uncappedTotalIssueCount, setUncappedTotalIssueCount] = (0, $5uXOq.useState)({
            ...$b9c1ec4b15549a72$export$31250fb09166d65c
        });
        const resetTotalIssueCount = (0, $5uXOq.useCallback)(()=>{
            setUncappedTotalIssueCount({
                ...$b9c1ec4b15549a72$export$31250fb09166d65c
            });
        }, []);
        const onFetchUncappedTotalIssueCount = (0, $5uXOq.useCallback)(()=>{
            setUncappedTotalIssueCount({
                count: undefined,
                isFetching: true,
                isError: false
            });
            (0, $8Uumt.fetchQuery)(environment, (0, (0, parcelRequire("fE00s")).default), {
                cloudId: cloudId,
                issueSearchInput: issueSearchInput
            }, {
                fetchPolicy: 'network-only',
                networkCacheConfig: {
                    metadata: {
                        META_SLOW_ENDPOINT: true
                    }
                }
            }).toPromise().then((data)=>{
                const count = data ? data.jira?.issueSearchTotalCount : null;
                let isError = false;
                if (count === null || count === undefined) isError = true;
                setUncappedTotalIssueCount({
                    count: count,
                    isFetching: false,
                    isError: isError
                });
            }).catch(()=>{
                setUncappedTotalIssueCount({
                    count: null,
                    isFetching: false,
                    isError: true
                });
            });
        }, [
            cloudId,
            environment,
            issueSearchInput
        ]);
        return (0, $5uXOq.useMemo)(()=>({
                uncappedTotalIssueCount: uncappedTotalIssueCount,
                resetTotalIssueCount: resetTotalIssueCount,
                onFetchUncappedTotalIssueCount: onFetchUncappedTotalIssueCount
            }), [
            uncappedTotalIssueCount,
            resetTotalIssueCount,
            onFetchUncappedTotalIssueCount
        ]);
    };
});
parcelRegister("fE00s", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$0436ddab441f1d11$export$2e2bcd8739ae039);
    const $0436ddab441f1d11$var$node = function() {
        var v0 = [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cloudId"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "issueSearchInput"
            }
        ], v1 = [
            {
                "concreteType": "JiraQuery",
                "kind": "LinkedField",
                "name": "jira",
                "plural": false,
                "selections": [
                    {
                        "args": [
                            {
                                "kind": "Variable",
                                "name": "cloudId",
                                "variableName": "cloudId"
                            },
                            {
                                "kind": "Variable",
                                "name": "issueSearchInput",
                                "variableName": "issueSearchInput"
                            }
                        ],
                        "kind": "ScalarField",
                        "name": "issueSearchTotalCount"
                    }
                ]
            }
        ];
        return {
            "fragment": {
                "argumentDefinitions": v0,
                "kind": "Fragment",
                "name": "issueSearchTotalCountQuery",
                "selections": v1,
                "type": "Query"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": v0,
                "kind": "Operation",
                "name": "issueSearchTotalCountQuery",
                "selections": v1
            },
            "params": {
                "id": "a3810891dc29174a6ad47903cc92470a66dff964fe69f524a8d29c29992b37a1",
                "metadata": {},
                "name": "issueSearchTotalCountQuery",
                "operationKind": "query",
                "text": null
            }
        };
    }();
    $0436ddab441f1d11$var$node.hash = "37773194a6f16231c4993e21b36ed872";
    var $0436ddab441f1d11$export$2e2bcd8739ae039 = $0436ddab441f1d11$var$node;
});
parcelRegister("6Hhfn", function(module, exports) {
    $parcel$export(module.exports, "useIssueByFieldsRefetch", ()=>$49b7b4d37d56a65b$export$a3f7fddb3e08cea5);
    var $8nnfc;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $eiowN = parcelRequire("eiowN");
    var $9OXo1;
    var $6UAdF;
    const $49b7b4d37d56a65b$export$a3f7fddb3e08cea5 = (fieldSets)=>{
        const { cloudId: cloudId } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const environment = (0, $8Uumt.useRelayEnvironment)();
        return (0, $5uXOq.useCallback)((issuekey)=>(0, $eiowN.fetchQuery)(environment, (0, (0, parcelRequire("8nnfc")).default), {
                cloudId: cloudId,
                issueSearchInput: {
                    jql: `issueKey=${issuekey}`
                },
                fieldSetIds: fieldSets,
                amountOfColumns: (0, (0, parcelRequire("6UAdF")).MAX_AMOUNT_OF_COLUMNS)
            }, {
                fetchPolicy: 'network-only'
            }).toPromise(), [
            cloudId,
            environment,
            fieldSets
        ]);
    };
});
parcelRegister("8nnfc", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$70ed14087c56651a$export$2e2bcd8739ae039);
    var $hLeia;
    var $1ZvTA;
    var $G39h7;
    var $1kHcz;
    const $70ed14087c56651a$var$node = function() {
        var v0 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "amountOfColumns"
        }, v1 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cloudId"
        }, v2 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "fieldSetIds"
        }, v3 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "issueSearchInput"
        }, v4 = [
            {
                "kind": "Variable",
                "name": "cloudId",
                "variableName": "cloudId"
            },
            {
                "kind": "Literal",
                "name": "first",
                "value": 1
            },
            {
                "kind": "Variable",
                "name": "issueSearchInput",
                "variableName": "issueSearchInput"
            }
        ], v5 = [
            {
                "kind": "Variable",
                "name": "fieldSetIds",
                "variableName": "fieldSetIds"
            },
            {
                "kind": "Variable",
                "name": "first",
                "variableName": "amountOfColumns"
            }
        ], v6 = {
            "kind": "ScalarField",
            "name": "type"
        }, v7 = {
            "kind": "ScalarField",
            "name": "__typename"
        }, v8 = {
            "kind": "ScalarField",
            "name": "id"
        }, v9 = {
            "kind": "ScalarField",
            "name": "name"
        }, v10 = [
            v9,
            v8
        ], v11 = {
            "concreteType": "JiraFieldConfig",
            "kind": "LinkedField",
            "name": "fieldConfig",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "isRequired"
                }
            ]
        }, v12 = {
            "kind": "ClientExtension",
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "lazyIsEditableInIssueView"
                }
            ]
        }, v13 = {
            "kind": "ScalarField",
            "name": "key"
        }, v14 = {
            "kind": "ScalarField",
            "name": "text"
        }, v15 = {
            "kind": "ScalarField",
            "name": "statusCategoryId"
        }, v16 = {
            "concreteType": "JiraStatusCategory",
            "kind": "LinkedField",
            "name": "statusCategory",
            "plural": false,
            "selections": [
                v15,
                v8
            ]
        }, v17 = {
            "kind": "ScalarField",
            "name": "totalCount"
        }, v18 = {
            "concreteType": "JiraAvatar",
            "kind": "LinkedField",
            "name": "avatar",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "xsmall"
                }
            ]
        };
        return {
            "fragment": {
                "argumentDefinitions": [
                    v0,
                    v1,
                    v2,
                    v3
                ],
                "kind": "Fragment",
                "name": "refetchIssue_IssueNavigatorQuery",
                "selections": [
                    {
                        "concreteType": "JiraQuery",
                        "kind": "LinkedField",
                        "name": "jira",
                        "plural": false,
                        "selections": [
                            {
                                "args": v4,
                                "concreteType": "JiraIssueConnection",
                                "kind": "LinkedField",
                                "name": "issueSearchStable",
                                "plural": false,
                                "selections": [
                                    {
                                        "concreteType": "JiraIssueEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                            {
                                                "concreteType": "JiraIssue",
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "args": v5,
                                                        "concreteType": "JiraIssueFieldSetConnection",
                                                        "kind": "LinkedField",
                                                        "name": "fieldSetsById",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "args": [
                                                                    {
                                                                        "kind": "Literal",
                                                                        "name": "isDensityFull",
                                                                        "value": false
                                                                    },
                                                                    {
                                                                        "kind": "Literal",
                                                                        "name": "isInlineEditingEnabled",
                                                                        "value": true
                                                                    }
                                                                ],
                                                                "kind": "FragmentSpread",
                                                                "name": "issueRow_nativeIssueTable_IssueRowWithFragments_fieldSetsForIssueSearchView"
                                                            },
                                                            {
                                                                "kind": "FragmentSpread",
                                                                "name": "main_issueNavigator_Card_fieldSetsForIssueSearchView"
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ],
                "type": "Query"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": [
                    v1,
                    v3,
                    v2,
                    v0,
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider"
                    },
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
                    }
                ],
                "kind": "Operation",
                "name": "refetchIssue_IssueNavigatorQuery",
                "selections": [
                    {
                        "concreteType": "JiraQuery",
                        "kind": "LinkedField",
                        "name": "jira",
                        "plural": false,
                        "selections": [
                            {
                                "args": v4,
                                "concreteType": "JiraIssueConnection",
                                "kind": "LinkedField",
                                "name": "issueSearchStable",
                                "plural": false,
                                "selections": [
                                    {
                                        "concreteType": "JiraIssueEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                            {
                                                "concreteType": "JiraIssue",
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "args": v5,
                                                        "concreteType": "JiraIssueFieldSetConnection",
                                                        "kind": "LinkedField",
                                                        "name": "fieldSetsById",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "concreteType": "JiraIssueFieldSetEdge",
                                                                "kind": "LinkedField",
                                                                "name": "edges",
                                                                "plural": true,
                                                                "selections": [
                                                                    {
                                                                        "concreteType": "JiraIssueFieldSet",
                                                                        "kind": "LinkedField",
                                                                        "name": "node",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            v6,
                                                                            {
                                                                                "kind": "ScalarField",
                                                                                "name": "fieldSetId"
                                                                            },
                                                                            {
                                                                                "concreteType": "JiraIssueFieldConnection",
                                                                                "kind": "LinkedField",
                                                                                "name": "fields",
                                                                                "plural": false,
                                                                                "selections": [
                                                                                    {
                                                                                        "concreteType": "JiraIssueFieldEdge",
                                                                                        "kind": "LinkedField",
                                                                                        "name": "edges",
                                                                                        "plural": true,
                                                                                        "selections": [
                                                                                            {
                                                                                                "kind": "LinkedField",
                                                                                                "name": "node",
                                                                                                "plural": false,
                                                                                                "selections": [
                                                                                                    v7,
                                                                                                    {
                                                                                                        "kind": "ScalarField",
                                                                                                        "name": "fieldId"
                                                                                                    },
                                                                                                    v6,
                                                                                                    {
                                                                                                        "kind": "TypeDiscriminator",
                                                                                                        "abstractKey": "__isJiraIssueField"
                                                                                                    },
                                                                                                    v8,
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            {
                                                                                                                "concreteType": "JiraAffectedServiceConnection",
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "selectedAffectedServicesConnection",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    {
                                                                                                                        "concreteType": "JiraAffectedServiceEdge",
                                                                                                                        "kind": "LinkedField",
                                                                                                                        "name": "edges",
                                                                                                                        "plural": true,
                                                                                                                        "selections": [
                                                                                                                            {
                                                                                                                                "concreteType": "JiraAffectedService",
                                                                                                                                "kind": "LinkedField",
                                                                                                                                "name": "node",
                                                                                                                                "plural": false,
                                                                                                                                "selections": [
                                                                                                                                    {
                                                                                                                                        "kind": "ScalarField",
                                                                                                                                        "name": "serviceId"
                                                                                                                                    },
                                                                                                                                    v9,
                                                                                                                                    v8
                                                                                                                                ]
                                                                                                                            }
                                                                                                                        ]
                                                                                                                    }
                                                                                                                ]
                                                                                                            }
                                                                                                        ],
                                                                                                        "type": "JiraAffectedServicesField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            {
                                                                                                                "concreteType": "JiraCmdbObjectConnection",
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "selectedCmdbObjectsConnection",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    {
                                                                                                                        "concreteType": "JiraCmdbObjectEdge",
                                                                                                                        "kind": "LinkedField",
                                                                                                                        "name": "edges",
                                                                                                                        "plural": true,
                                                                                                                        "selections": [
                                                                                                                            {
                                                                                                                                "concreteType": "JiraCmdbObject",
                                                                                                                                "kind": "LinkedField",
                                                                                                                                "name": "node",
                                                                                                                                "plural": false,
                                                                                                                                "selections": [
                                                                                                                                    {
                                                                                                                                        "kind": "ScalarField",
                                                                                                                                        "name": "objectId"
                                                                                                                                    },
                                                                                                                                    {
                                                                                                                                        "kind": "ScalarField",
                                                                                                                                        "name": "label"
                                                                                                                                    },
                                                                                                                                    {
                                                                                                                                        "concreteType": "JiraCmdbAvatar",
                                                                                                                                        "kind": "LinkedField",
                                                                                                                                        "name": "avatar",
                                                                                                                                        "plural": false,
                                                                                                                                        "selections": [
                                                                                                                                            {
                                                                                                                                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider",
                                                                                                                                                "kind": "Condition",
                                                                                                                                                "passingValue": true,
                                                                                                                                                "selections": [
                                                                                                                                                    {
                                                                                                                                                        "concreteType": "JiraCmdbMediaClientConfig",
                                                                                                                                                        "kind": "LinkedField",
                                                                                                                                                        "name": "mediaClientConfig",
                                                                                                                                                        "plural": false,
                                                                                                                                                        "selections": [
                                                                                                                                                            {
                                                                                                                                                                "kind": "ScalarField",
                                                                                                                                                                "name": "clientId"
                                                                                                                                                            },
                                                                                                                                                            {
                                                                                                                                                                "kind": "ScalarField",
                                                                                                                                                                "name": "fileId"
                                                                                                                                                            },
                                                                                                                                                            {
                                                                                                                                                                "kind": "ScalarField",
                                                                                                                                                                "name": "mediaBaseUrl"
                                                                                                                                                            },
                                                                                                                                                            {
                                                                                                                                                                "kind": "ScalarField",
                                                                                                                                                                "name": "mediaJwtToken"
                                                                                                                                                            }
                                                                                                                                                        ]
                                                                                                                                                    }
                                                                                                                                                ]
                                                                                                                                            },
                                                                                                                                            {
                                                                                                                                                "kind": "ScalarField",
                                                                                                                                                "name": "url48"
                                                                                                                                            }
                                                                                                                                        ]
                                                                                                                                    }
                                                                                                                                ]
                                                                                                                            }
                                                                                                                        ]
                                                                                                                    },
                                                                                                                    {
                                                                                                                        "concreteType": "QueryError",
                                                                                                                        "kind": "LinkedField",
                                                                                                                        "name": "errors",
                                                                                                                        "plural": true,
                                                                                                                        "selections": [
                                                                                                                            {
                                                                                                                                "kind": "ScalarField",
                                                                                                                                "name": "message"
                                                                                                                            }
                                                                                                                        ]
                                                                                                                    }
                                                                                                                ]
                                                                                                            }
                                                                                                        ],
                                                                                                        "type": "JiraCMDBField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            {
                                                                                                                "concreteType": "JiraComponentConnection",
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "selectedComponentsConnection",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    {
                                                                                                                        "concreteType": "JiraComponentEdge",
                                                                                                                        "kind": "LinkedField",
                                                                                                                        "name": "edges",
                                                                                                                        "plural": true,
                                                                                                                        "selections": [
                                                                                                                            {
                                                                                                                                "concreteType": "JiraComponent",
                                                                                                                                "kind": "LinkedField",
                                                                                                                                "name": "node",
                                                                                                                                "plural": false,
                                                                                                                                "selections": v10
                                                                                                                            }
                                                                                                                        ]
                                                                                                                    }
                                                                                                                ]
                                                                                                            }
                                                                                                        ],
                                                                                                        "type": "JiraComponentsField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            {
                                                                                                                "kind": "ScalarField",
                                                                                                                "name": "date"
                                                                                                            },
                                                                                                            v9,
                                                                                                            v11,
                                                                                                            v12
                                                                                                        ],
                                                                                                        "type": "JiraDatePickerField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            {
                                                                                                                "kind": "ScalarField",
                                                                                                                "name": "dateTime"
                                                                                                            }
                                                                                                        ],
                                                                                                        "type": "JiraDateTimePickerField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            {
                                                                                                                "concreteType": "JiraEpic",
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "epic",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    v13,
                                                                                                                    {
                                                                                                                        "kind": "ScalarField",
                                                                                                                        "name": "summary"
                                                                                                                    },
                                                                                                                    {
                                                                                                                        "kind": "ScalarField",
                                                                                                                        "name": "color"
                                                                                                                    },
                                                                                                                    v8
                                                                                                                ]
                                                                                                            }
                                                                                                        ],
                                                                                                        "type": "JiraEpicLinkField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            {
                                                                                                                "kind": "ScalarField",
                                                                                                                "name": "renderedFieldHtml"
                                                                                                            }
                                                                                                        ],
                                                                                                        "type": "JiraFallbackField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            v14,
                                                                                                            v9,
                                                                                                            v12
                                                                                                        ],
                                                                                                        "type": "JiraSingleLineTextField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            {
                                                                                                                "concreteType": "JiraStatusCategory",
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "statusCategory",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    v9,
                                                                                                                    v15,
                                                                                                                    v8
                                                                                                                ]
                                                                                                            }
                                                                                                        ],
                                                                                                        "type": "JiraStatusCategoryField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            {
                                                                                                                "concreteType": "JiraStatus",
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "status",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    v9,
                                                                                                                    {
                                                                                                                        "kind": "ScalarField",
                                                                                                                        "name": "statusId"
                                                                                                                    },
                                                                                                                    v16,
                                                                                                                    v8
                                                                                                                ]
                                                                                                            }
                                                                                                        ],
                                                                                                        "type": "JiraStatusField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            {
                                                                                                                "args": [
                                                                                                                    {
                                                                                                                        "kind": "Literal",
                                                                                                                        "name": "first",
                                                                                                                        "value": 1000
                                                                                                                    }
                                                                                                                ],
                                                                                                                "concreteType": "JiraLabelConnection",
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "selectedLabelsConnection",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    {
                                                                                                                        "concreteType": "JiraLabelEdge",
                                                                                                                        "kind": "LinkedField",
                                                                                                                        "name": "edges",
                                                                                                                        "plural": true,
                                                                                                                        "selections": [
                                                                                                                            {
                                                                                                                                "concreteType": "JiraLabel",
                                                                                                                                "kind": "LinkedField",
                                                                                                                                "name": "node",
                                                                                                                                "plural": false,
                                                                                                                                "selections": [
                                                                                                                                    v9
                                                                                                                                ]
                                                                                                                            }
                                                                                                                        ]
                                                                                                                    },
                                                                                                                    v17
                                                                                                                ],
                                                                                                                "storageKey": "selectedLabelsConnection(first:1000)"
                                                                                                            },
                                                                                                            v9,
                                                                                                            v12
                                                                                                        ],
                                                                                                        "type": "JiraLabelsField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            {
                                                                                                                "concreteType": "JiraIssueType",
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "issueType",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    {
                                                                                                                        "concreteType": "JiraAvatar",
                                                                                                                        "kind": "LinkedField",
                                                                                                                        "name": "avatar",
                                                                                                                        "plural": false,
                                                                                                                        "selections": [
                                                                                                                            {
                                                                                                                                "kind": "ScalarField",
                                                                                                                                "name": "small"
                                                                                                                            }
                                                                                                                        ]
                                                                                                                    },
                                                                                                                    v9,
                                                                                                                    v8
                                                                                                                ]
                                                                                                            }
                                                                                                        ],
                                                                                                        "type": "JiraIssueTypeField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            {
                                                                                                                "concreteType": "JiraIssue",
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "parentIssue",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    v13,
                                                                                                                    {
                                                                                                                        "concreteType": "JiraSingleLineTextField",
                                                                                                                        "kind": "LinkedField",
                                                                                                                        "name": "summaryField",
                                                                                                                        "plural": false,
                                                                                                                        "selections": [
                                                                                                                            v14,
                                                                                                                            v8
                                                                                                                        ]
                                                                                                                    },
                                                                                                                    v8,
                                                                                                                    {
                                                                                                                        "concreteType": "JiraColorField",
                                                                                                                        "kind": "LinkedField",
                                                                                                                        "name": "issueColorField",
                                                                                                                        "plural": false,
                                                                                                                        "selections": [
                                                                                                                            {
                                                                                                                                "concreteType": "JiraColor",
                                                                                                                                "kind": "LinkedField",
                                                                                                                                "name": "color",
                                                                                                                                "plural": false,
                                                                                                                                "selections": [
                                                                                                                                    {
                                                                                                                                        "kind": "ScalarField",
                                                                                                                                        "name": "colorKey"
                                                                                                                                    },
                                                                                                                                    v8
                                                                                                                                ]
                                                                                                                            },
                                                                                                                            v8
                                                                                                                        ]
                                                                                                                    },
                                                                                                                    {
                                                                                                                        "concreteType": "JiraIssueTypeField",
                                                                                                                        "kind": "LinkedField",
                                                                                                                        "name": "issueTypeField",
                                                                                                                        "plural": false,
                                                                                                                        "selections": [
                                                                                                                            {
                                                                                                                                "concreteType": "JiraIssueType",
                                                                                                                                "kind": "LinkedField",
                                                                                                                                "name": "issueType",
                                                                                                                                "plural": false,
                                                                                                                                "selections": [
                                                                                                                                    v18,
                                                                                                                                    v9,
                                                                                                                                    v8
                                                                                                                                ]
                                                                                                                            },
                                                                                                                            v8
                                                                                                                        ]
                                                                                                                    }
                                                                                                                ]
                                                                                                            }
                                                                                                        ],
                                                                                                        "type": "JiraParentIssueField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            {
                                                                                                                "concreteType": "JiraPriority",
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "priority",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    v9,
                                                                                                                    {
                                                                                                                        "kind": "ScalarField",
                                                                                                                        "name": "iconUrl"
                                                                                                                    },
                                                                                                                    v8
                                                                                                                ]
                                                                                                            },
                                                                                                            v9,
                                                                                                            v12
                                                                                                        ],
                                                                                                        "type": "JiraPriorityField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            {
                                                                                                                "concreteType": "JiraProject",
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "project",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    v9,
                                                                                                                    v13,
                                                                                                                    {
                                                                                                                        "concreteType": "JiraAvatar",
                                                                                                                        "kind": "LinkedField",
                                                                                                                        "name": "avatar",
                                                                                                                        "plural": false,
                                                                                                                        "selections": [
                                                                                                                            {
                                                                                                                                "kind": "ScalarField",
                                                                                                                                "name": "large"
                                                                                                                            }
                                                                                                                        ]
                                                                                                                    },
                                                                                                                    v8
                                                                                                                ]
                                                                                                            }
                                                                                                        ],
                                                                                                        "type": "JiraProjectField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            {
                                                                                                                "concreteType": "JiraResolution",
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "resolution",
                                                                                                                "plural": false,
                                                                                                                "selections": v10
                                                                                                            }
                                                                                                        ],
                                                                                                        "type": "JiraResolutionField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            {
                                                                                                                "concreteType": "JiraSprintConnection",
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "selectedSprintsConnection",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    {
                                                                                                                        "concreteType": "JiraSprintEdge",
                                                                                                                        "kind": "LinkedField",
                                                                                                                        "name": "edges",
                                                                                                                        "plural": true,
                                                                                                                        "selections": [
                                                                                                                            {
                                                                                                                                "concreteType": "JiraSprint",
                                                                                                                                "kind": "LinkedField",
                                                                                                                                "name": "node",
                                                                                                                                "plural": false,
                                                                                                                                "selections": [
                                                                                                                                    v8,
                                                                                                                                    {
                                                                                                                                        "kind": "ScalarField",
                                                                                                                                        "name": "sprintId"
                                                                                                                                    },
                                                                                                                                    v9,
                                                                                                                                    {
                                                                                                                                        "kind": "ScalarField",
                                                                                                                                        "name": "state"
                                                                                                                                    },
                                                                                                                                    {
                                                                                                                                        "kind": "ScalarField",
                                                                                                                                        "name": "endDate"
                                                                                                                                    }
                                                                                                                                ]
                                                                                                                            }
                                                                                                                        ]
                                                                                                                    }
                                                                                                                ]
                                                                                                            },
                                                                                                            v9,
                                                                                                            v12
                                                                                                        ],
                                                                                                        "type": "JiraSprintField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            {
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "user",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    v7,
                                                                                                                    {
                                                                                                                        "kind": "ScalarField",
                                                                                                                        "name": "accountId"
                                                                                                                    },
                                                                                                                    {
                                                                                                                        "kind": "ScalarField",
                                                                                                                        "name": "picture"
                                                                                                                    },
                                                                                                                    v9,
                                                                                                                    v8,
                                                                                                                    {
                                                                                                                        "kind": "ScalarField",
                                                                                                                        "name": "accountStatus"
                                                                                                                    }
                                                                                                                ]
                                                                                                            },
                                                                                                            v9,
                                                                                                            v11,
                                                                                                            v12
                                                                                                        ],
                                                                                                        "type": "JiraSingleSelectUserPickerField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            v9,
                                                                                                            {
                                                                                                                "concreteType": "JiraVersionConnection",
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "selectedVersionsConnection",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    {
                                                                                                                        "concreteType": "JiraVersionEdge",
                                                                                                                        "kind": "LinkedField",
                                                                                                                        "name": "edges",
                                                                                                                        "plural": true,
                                                                                                                        "selections": [
                                                                                                                            {
                                                                                                                                "concreteType": "JiraVersion",
                                                                                                                                "kind": "LinkedField",
                                                                                                                                "name": "node",
                                                                                                                                "plural": false,
                                                                                                                                "selections": v10
                                                                                                                            }
                                                                                                                        ]
                                                                                                                    }
                                                                                                                ]
                                                                                                            }
                                                                                                        ],
                                                                                                        "type": "JiraMultipleVersionPickerField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            {
                                                                                                                "concreteType": "JiraRichText",
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "richText",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    {
                                                                                                                        "concreteType": "JiraADF",
                                                                                                                        "kind": "LinkedField",
                                                                                                                        "name": "adfValue",
                                                                                                                        "plural": false,
                                                                                                                        "selections": [
                                                                                                                            {
                                                                                                                                "concreteType": "JiraAdfToConvertedPlainText",
                                                                                                                                "kind": "LinkedField",
                                                                                                                                "name": "convertedPlainText",
                                                                                                                                "plural": false,
                                                                                                                                "selections": [
                                                                                                                                    {
                                                                                                                                        "kind": "ScalarField",
                                                                                                                                        "name": "plainText"
                                                                                                                                    }
                                                                                                                                ]
                                                                                                                            }
                                                                                                                        ]
                                                                                                                    }
                                                                                                                ]
                                                                                                            }
                                                                                                        ],
                                                                                                        "type": "JiraRichTextField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            {
                                                                                                                "concreteType": "JiraServiceManagementRequestType",
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "requestType",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    v9,
                                                                                                                    v18,
                                                                                                                    v8
                                                                                                                ]
                                                                                                            }
                                                                                                        ],
                                                                                                        "type": "JiraServiceManagementRequestTypeField"
                                                                                                    },
                                                                                                    {
                                                                                                        "kind": "InlineFragment",
                                                                                                        "selections": [
                                                                                                            {
                                                                                                                "args": [
                                                                                                                    {
                                                                                                                        "kind": "Variable",
                                                                                                                        "name": "first",
                                                                                                                        "variableName": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
                                                                                                                    }
                                                                                                                ],
                                                                                                                "concreteType": "JiraIssueLinkConnection",
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "issueLinkConnection",
                                                                                                                "plural": false,
                                                                                                                "selections": [
                                                                                                                    {
                                                                                                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
                                                                                                                        "kind": "Condition",
                                                                                                                        "passingValue": false,
                                                                                                                        "selections": [
                                                                                                                            {
                                                                                                                                "concreteType": "PageInfo",
                                                                                                                                "kind": "LinkedField",
                                                                                                                                "name": "pageInfo",
                                                                                                                                "plural": false,
                                                                                                                                "selections": [
                                                                                                                                    {
                                                                                                                                        "kind": "ScalarField",
                                                                                                                                        "name": "hasNextPage"
                                                                                                                                    }
                                                                                                                                ]
                                                                                                                            }
                                                                                                                        ]
                                                                                                                    },
                                                                                                                    {
                                                                                                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
                                                                                                                        "kind": "Condition",
                                                                                                                        "passingValue": true,
                                                                                                                        "selections": [
                                                                                                                            v17
                                                                                                                        ]
                                                                                                                    },
                                                                                                                    {
                                                                                                                        "concreteType": "JiraIssueLinkEdge",
                                                                                                                        "kind": "LinkedField",
                                                                                                                        "name": "edges",
                                                                                                                        "plural": true,
                                                                                                                        "selections": [
                                                                                                                            {
                                                                                                                                "concreteType": "JiraIssueLink",
                                                                                                                                "kind": "LinkedField",
                                                                                                                                "name": "node",
                                                                                                                                "plural": false,
                                                                                                                                "selections": [
                                                                                                                                    {
                                                                                                                                        "concreteType": "JiraIssue",
                                                                                                                                        "kind": "LinkedField",
                                                                                                                                        "name": "issue",
                                                                                                                                        "plural": false,
                                                                                                                                        "selections": [
                                                                                                                                            {
                                                                                                                                                "kind": "ScalarField",
                                                                                                                                                "name": "webUrl"
                                                                                                                                            },
                                                                                                                                            v13,
                                                                                                                                            {
                                                                                                                                                "args": [
                                                                                                                                                    {
                                                                                                                                                        "kind": "Literal",
                                                                                                                                                        "name": "ids",
                                                                                                                                                        "value": [
                                                                                                                                                            "statusCategory"
                                                                                                                                                        ]
                                                                                                                                                    }
                                                                                                                                                ],
                                                                                                                                                "concreteType": "JiraIssueFieldConnection",
                                                                                                                                                "kind": "LinkedField",
                                                                                                                                                "name": "fieldsById",
                                                                                                                                                "plural": false,
                                                                                                                                                "selections": [
                                                                                                                                                    {
                                                                                                                                                        "concreteType": "JiraIssueFieldEdge",
                                                                                                                                                        "kind": "LinkedField",
                                                                                                                                                        "name": "edges",
                                                                                                                                                        "plural": true,
                                                                                                                                                        "selections": [
                                                                                                                                                            {
                                                                                                                                                                "kind": "LinkedField",
                                                                                                                                                                "name": "node",
                                                                                                                                                                "plural": false,
                                                                                                                                                                "selections": [
                                                                                                                                                                    v7,
                                                                                                                                                                    {
                                                                                                                                                                        "kind": "InlineFragment",
                                                                                                                                                                        "selections": [
                                                                                                                                                                            v16
                                                                                                                                                                        ],
                                                                                                                                                                        "type": "JiraStatusCategoryField"
                                                                                                                                                                    },
                                                                                                                                                                    v8
                                                                                                                                                                ]
                                                                                                                                                            }
                                                                                                                                                        ]
                                                                                                                                                    }
                                                                                                                                                ],
                                                                                                                                                "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                                                                                                                                            },
                                                                                                                                            v8
                                                                                                                                        ]
                                                                                                                                    },
                                                                                                                                    {
                                                                                                                                        "kind": "ScalarField",
                                                                                                                                        "name": "relationName"
                                                                                                                                    },
                                                                                                                                    v8
                                                                                                                                ]
                                                                                                                            }
                                                                                                                        ]
                                                                                                                    }
                                                                                                                ]
                                                                                                            }
                                                                                                        ],
                                                                                                        "type": "JiraIssueLinkField"
                                                                                                    },
                                                                                                    {
                                                                                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
                                                                                                        "kind": "Condition",
                                                                                                        "passingValue": true,
                                                                                                        "selections": [
                                                                                                            {
                                                                                                                "kind": "InlineFragment",
                                                                                                                "selections": [
                                                                                                                    {
                                                                                                                        "kind": "ScalarField",
                                                                                                                        "name": "isStoryPointField"
                                                                                                                    },
                                                                                                                    {
                                                                                                                        "kind": "ScalarField",
                                                                                                                        "name": "number"
                                                                                                                    },
                                                                                                                    v9,
                                                                                                                    v12
                                                                                                                ],
                                                                                                                "type": "JiraNumberField"
                                                                                                            }
                                                                                                        ]
                                                                                                    }
                                                                                                ]
                                                                                            }
                                                                                        ]
                                                                                    }
                                                                                ]
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    },
                                                    v8
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "params": {
                "id": "9c05412a3175170638a4818ec81b4514e584287b1f72eefa967a4f080dc7147d",
                "metadata": {},
                "name": "refetchIssue_IssueNavigatorQuery",
                "operationKind": "query",
                "text": null,
                "providedVariables": {
                    "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": (0, (0, parcelRequire("1kHcz")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider": (0, (0, parcelRequire("hLeia")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider": (0, (0, parcelRequire("G39h7")).default),
                    "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider": (0, (0, parcelRequire("1ZvTA")).default)
                }
            }
        };
    }();
    $70ed14087c56651a$var$node.hash = "a1923415f412cb59e7b5d21b9573d30f";
    var $70ed14087c56651a$export$2e2bcd8739ae039 = $70ed14087c56651a$var$node;
});
parcelRegister("4M3mV", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$b78ba074f422fa2b$export$2e2bcd8739ae039);
    var $7VHMR;
    var $b78ba074f422fa2b$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        searchFailedTitle: {
            "id": "issue-navigator.services.issue-search-refetch.search-failed-title",
            "defaultMessage": "We couldn't load your search results"
        },
        searchFailedDescription: {
            "id": "issue-navigator.services.issue-search-refetch.search-failed-description",
            "defaultMessage": "Refresh and try again."
        }
    });
});
parcelRegister("aHzNA", function(module, exports) {
    $parcel$export(module.exports, "useSelectedViewMutation", ()=>$2944951b3352cd6b$export$765f658bb8bac50f);
    var $aQlsP;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $jcw0u;
    var $koeKL;
    var $6UAdF;
    const $2944951b3352cd6b$export$5786a571e562003d = (view)=>view.startsWith('list_') ? 'LIST' : 'DETAIL';
    const $2944951b3352cd6b$export$765f658bb8bac50f = ()=>{
        const userCloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const [commitMutation] = (0, $8Uumt.useMutation)((0, (0, parcelRequire("aQlsP")).default));
        return (0, $5uXOq.useCallback)((view)=>{
            commitMutation({
                variables: {
                    cloudId: userCloudId,
                    searchLayout: $2944951b3352cd6b$export$5786a571e562003d(view)
                },
                onError: (error)=>{
                    (0, (0, parcelRequire("jcw0u")).default)({
                        sendToPrivacyUnsafeSplunk: true,
                        error: error,
                        meta: {
                            id: 'mainSelectedViewMutation',
                            packageName: (0, (0, parcelRequire("6UAdF")).PACKAGE_NAME),
                            teamName: (0, (0, parcelRequire("6UAdF")).TEAM_NAME)
                        }
                    });
                },
                optimisticResponse: {
                    jira: {
                        userPreferences: {
                            setIssueNavigatorSearchLayout: {
                                success: true,
                                errors: [],
                                issueNavigatorSearchLayout: $2944951b3352cd6b$export$5786a571e562003d(view)
                            }
                        }
                    }
                }
            });
        }, [
            commitMutation,
            userCloudId
        ]);
    };
});
parcelRegister("aQlsP", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$92fa5e8811139c3f$export$2e2bcd8739ae039);
    const $92fa5e8811139c3f$var$node = function() {
        var v0 = [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cloudId"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "searchLayout"
            }
        ], v1 = [
            {
                "concreteType": "JiraMutation",
                "kind": "LinkedField",
                "name": "jira",
                "plural": false,
                "selections": [
                    {
                        "args": [
                            {
                                "kind": "Variable",
                                "name": "cloudId",
                                "variableName": "cloudId"
                            }
                        ],
                        "concreteType": "JiraUserPreferencesMutation",
                        "kind": "LinkedField",
                        "name": "userPreferences",
                        "plural": false,
                        "selections": [
                            {
                                "args": [
                                    {
                                        "kind": "Variable",
                                        "name": "searchLayout",
                                        "variableName": "searchLayout"
                                    }
                                ],
                                "concreteType": "JiraIssueNavigatorSearchLayoutMutationPayload",
                                "kind": "LinkedField",
                                "name": "setIssueNavigatorSearchLayout",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "ScalarField",
                                        "name": "success"
                                    },
                                    {
                                        "concreteType": "MutationError",
                                        "kind": "LinkedField",
                                        "name": "errors",
                                        "plural": true,
                                        "selections": [
                                            {
                                                "kind": "ScalarField",
                                                "name": "message"
                                            }
                                        ]
                                    },
                                    {
                                        "kind": "ScalarField",
                                        "name": "issueNavigatorSearchLayout"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ];
        return {
            "fragment": {
                "argumentDefinitions": v0,
                "kind": "Fragment",
                "name": "selectedView_updateUserViewModeMutation",
                "selections": v1,
                "type": "Mutation"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": v0,
                "kind": "Operation",
                "name": "selectedView_updateUserViewModeMutation",
                "selections": v1
            },
            "params": {
                "id": "f11f424abd082a8324c0f881b823fe5b84699dcb133b3f8c6933ee47e9907505",
                "metadata": {},
                "name": "selectedView_updateUserViewModeMutation",
                "operationKind": "mutation",
                "text": null
            }
        };
    }();
    $92fa5e8811139c3f$var$node.hash = "57781762cebd4351d1b1402acc6af4c4";
    var $92fa5e8811139c3f$export$2e2bcd8739ae039 = $92fa5e8811139c3f$var$node;
});
parcelRegister("fgjcR", function(module, exports) {
    $parcel$export(module.exports, "useOnDeleteIssue", ()=>$24b03457fbd48166$export$45e2b3c3de4980ed);
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $eiowN = parcelRequire("eiowN");
    const $24b03457fbd48166$export$45e2b3c3de4980ed = (data)=>{
        const environment = (0, $8Uumt.useRelayEnvironment)();
        return (0, $5uXOq.useCallback)((issueKeyToDelete)=>{
            const issueResultEdges = data?.edges ?? [];
            let previousIssueKey;
            let nextIssueKey;
            (0, $eiowN.commitLocalUpdate)(environment, (store)=>{
                let record;
                for(let i = 0; i < issueResultEdges.length; i++){
                    const edge = issueResultEdges[i];
                    const key = edge?.node?.key;
                    if (!record) {
                        if (edge && key === issueKeyToDelete) record = store.get(edge.__id);
                        else if (key != null) previousIssueKey = key;
                    } else if (key != null) {
                        nextIssueKey = key;
                        break;
                    }
                }
                record?.setValue(null, 'node');
            });
            return nextIssueKey ?? previousIssueKey ?? '';
        }, [
            environment,
            data?.edges
        ]);
    };
});
parcelRegister("ih9zY", function(module, exports) {
    $parcel$export(module.exports, "Container", ()=>$03e3ed82cee3c5c7$export$42a852a2b6b56249);
    $parcel$export(module.exports, "useSelectedViewState", ()=>$03e3ed82cee3c5c7$export$b94206faaa871f9e);
    var $gg7kZ;
    var $3sQ5x;
    var $iXqE5;
    var $6UAdF;
    const $03e3ed82cee3c5c7$export$42a852a2b6b56249 = (0, (0, parcelRequire("gg7kZ")).createContainer)();
    const $03e3ed82cee3c5c7$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: {
            view: (0, (0, parcelRequire("6UAdF")).DEFAULT_VIEW_ID)
        },
        containedBy: $03e3ed82cee3c5c7$export$42a852a2b6b56249,
        actions: {
            setView: (view, issueKey)=>({ setState: setState }, { onSetView: onSetView, onIssueSearchForView: onIssueSearchForView })=>{
                    setState({
                        view: view
                    });
                    onSetView(view, issueKey);
                    onIssueSearchForView && onIssueSearchForView(view);
                }
        },
        handlers: {
            onInit: ()=>({ setState: setState }, { view: view })=>{
                    setState({
                        view: view
                    });
                },
            onContainerUpdate: (nextProps, prevProps)=>({ setState: setState })=>{
                    if (nextProps.view !== prevProps.view) setState({
                        view: nextProps.view
                    });
                }
        },
        name: 'IssueNavigatorSelectedViewStateNew'
    });
    const $03e3ed82cee3c5c7$export$b94206faaa871f9e = (0, (0, parcelRequire("3sQ5x")).createHook)($03e3ed82cee3c5c7$var$Store);
});
parcelRegister("1On4W", function(module, exports) {
    $parcel$export(module.exports, "privateActions", ()=>$16c8c1e1a5eb8155$export$3f1be494d407b77a);
    $parcel$export(module.exports, "default", ()=>$16c8c1e1a5eb8155$export$2e2bcd8739ae039);
    var $lFKmC = parcelRequire("lFKmC");
    var $g5X3G;
    var $6UAdF;
    const $16c8c1e1a5eb8155$var$doSideEffectUpdate = ({ setState: setState, getState: getState, onChange: onChange, shouldNotifyOnChange: shouldNotifyOnChange, isSelectedByUserInteraction: isSelectedByUserInteraction })=>{
        const { selectedIssue: selectedIssue } = getState();
        setState({
            _rememoize: Date.now()
        });
        const key = selectedIssue[1];
        shouldNotifyOnChange && onChange && onChange(key, isSelectedByUserInteraction);
    };
    const $16c8c1e1a5eb8155$var$doDebouncedSideEffectUpdate = (0, ($parcel$interopDefault($lFKmC)))($16c8c1e1a5eb8155$var$doSideEffectUpdate, 600);
    const $16c8c1e1a5eb8155$var$doSideEffectUpdates = (shouldDebounce, setState, getState, onChange, shouldNotifyOnChange, isSelectedByUserInteraction)=>{
        if (shouldDebounce) $16c8c1e1a5eb8155$var$doDebouncedSideEffectUpdate({
            setState: setState,
            getState: getState,
            onChange: onChange,
            shouldNotifyOnChange: shouldNotifyOnChange,
            isSelectedByUserInteraction: isSelectedByUserInteraction
        });
        else $16c8c1e1a5eb8155$var$doSideEffectUpdate({
            setState: setState,
            getState: getState,
            onChange: onChange,
            shouldNotifyOnChange: shouldNotifyOnChange,
            isSelectedByUserInteraction: isSelectedByUserInteraction
        });
    };
    const $16c8c1e1a5eb8155$var$setSelectedIssueByKey = (issueKey, shouldDebounce = false, shouldNotifyOnChange = true, isSelectedByUserInteraction = true)=>({ setState: setState, getState: getState }, { onChange: onChange, issueKeys: issueKeys })=>{
            if (!issueKey.length) return;
            const index = issueKey.length ? issueKeys?.findIndex((key)=>key === issueKey) : -1;
            if (index !== -1) setState({
                selectedIssue: [
                    index,
                    issueKey
                ]
            });
            else setState({
                selectedIssue: [
                    null,
                    issueKey
                ]
            });
            $16c8c1e1a5eb8155$var$doSideEffectUpdates(shouldDebounce, setState, getState, onChange, shouldNotifyOnChange, isSelectedByUserInteraction);
        };
    const $16c8c1e1a5eb8155$var$setSelectedIssueByIndex = (issueIndex, shouldDebounce = false, shouldNotifyOnChange = true, isSelectedByUserInteraction = true)=>({ setState: setState, getState: getState }, { onChange: onChange, issueKeys: issueKeys })=>{
            if (issueIndex >= issueKeys.length) return;
            const key = issueKeys[issueIndex];
            setState({
                selectedIssue: [
                    issueIndex,
                    key
                ]
            });
            $16c8c1e1a5eb8155$var$doSideEffectUpdates(shouldDebounce, setState, getState, onChange, shouldNotifyOnChange, isSelectedByUserInteraction);
        };
    const $16c8c1e1a5eb8155$var$getSelectedIssuePosition = ()=>({ getState: getState }, { firstIssuePosition: firstIssuePosition, lastIssuePosition: lastIssuePosition, firstIssueKeyFromNextPage: firstIssueKeyFromNextPage, lastIssueKeyFromPreviousPage: lastIssueKeyFromPreviousPage })=>{
            if (firstIssuePosition === null || lastIssuePosition === null) return null;
            const { selectedIssue: selectedIssue, nextResetStrategy: nextResetStrategy } = getState();
            const [selectedIssueIndex, selectedIssueKey] = selectedIssue;
            if (selectedIssueKey === firstIssueKeyFromNextPage || nextResetStrategy === (0, $f455add29e1ec026$export$7438b768c88f1c5b)) return lastIssuePosition + 1;
            if (selectedIssueKey === lastIssueKeyFromPreviousPage || nextResetStrategy === (0, $f455add29e1ec026$export$9d24b8100c251252)) return firstIssuePosition - 1;
            if (selectedIssueIndex !== null) return selectedIssueIndex + firstIssuePosition;
            return null;
        };
    const $16c8c1e1a5eb8155$var$isSelectedIssueOnPage = ()=>({ dispatch: dispatch }, { firstIssuePosition: firstIssuePosition, lastIssuePosition: lastIssuePosition })=>{
            const selectedIssuePosition = dispatch($16c8c1e1a5eb8155$var$getSelectedIssuePosition());
            return selectedIssuePosition !== null && firstIssuePosition !== null && lastIssuePosition !== null && selectedIssuePosition >= firstIssuePosition && selectedIssuePosition <= lastIssuePosition;
        };
    const $16c8c1e1a5eb8155$var$isFirstIssueOnPage = ()=>({ dispatch: dispatch }, { firstIssuePosition: firstIssuePosition })=>{
            const selectedIssuePosition = dispatch($16c8c1e1a5eb8155$var$getSelectedIssuePosition());
            return selectedIssuePosition !== null && selectedIssuePosition === firstIssuePosition;
        };
    const $16c8c1e1a5eb8155$var$isLastIssueOnPage = ()=>({ dispatch: dispatch }, { lastIssuePosition: lastIssuePosition })=>{
            const selectedIssuePosition = dispatch($16c8c1e1a5eb8155$var$getSelectedIssuePosition());
            return selectedIssuePosition !== null && selectedIssuePosition === lastIssuePosition;
        };
    const $16c8c1e1a5eb8155$var$getNextIssue = ()=>({ getState: getState, dispatch: dispatch }, { firstIssueKeyFromNextPage: firstIssueKeyFromNextPage, issueKeys: issueKeys })=>{
            const { selectedIssue: selectedIssue } = getState();
            const [selectedIssueIndex] = selectedIssue;
            let nextIssueKey = '';
            let nextIssueIndex = null;
            let isOnNextPage = false;
            if (dispatch($16c8c1e1a5eb8155$var$isLastIssueOnPage())) {
                nextIssueKey = firstIssueKeyFromNextPage ?? '';
                isOnNextPage = true;
            } else if (selectedIssueIndex !== null && dispatch($16c8c1e1a5eb8155$var$isSelectedIssueOnPage())) {
                nextIssueIndex = selectedIssueIndex + 1;
                nextIssueKey = issueKeys[nextIssueIndex];
            }
            return {
                issueKey: nextIssueKey,
                issueIndex: nextIssueIndex,
                isOnNextPage: isOnNextPage
            };
        };
    const $16c8c1e1a5eb8155$var$getPreviousIssue = ()=>({ getState: getState, dispatch: dispatch }, { lastIssueKeyFromPreviousPage: lastIssueKeyFromPreviousPage, issueKeys: issueKeys })=>{
            const { selectedIssue: selectedIssue } = getState();
            const [selectedIssueIndex] = selectedIssue;
            let previousIssueKey = '';
            let previousIssueIndex = null;
            let isOnPreviousPage = false;
            const selectedIssuePosition = dispatch($16c8c1e1a5eb8155$var$getSelectedIssuePosition());
            if (selectedIssuePosition === 1) return {
                issueKey: previousIssueKey,
                issueIndex: previousIssueIndex,
                isOnPreviousPage: isOnPreviousPage
            };
            if (dispatch($16c8c1e1a5eb8155$var$isFirstIssueOnPage())) {
                previousIssueKey = lastIssueKeyFromPreviousPage ?? '';
                isOnPreviousPage = true;
            } else if (selectedIssueIndex !== null && dispatch($16c8c1e1a5eb8155$var$isSelectedIssueOnPage())) {
                previousIssueIndex = selectedIssueIndex - 1;
                previousIssueKey = issueKeys[previousIssueIndex];
            }
            return {
                issueKey: previousIssueKey,
                issueIndex: previousIssueIndex,
                isOnPreviousPage: isOnPreviousPage
            };
        };
    const $16c8c1e1a5eb8155$var$selectFirstIssueOnNextPage = ()=>({ setState: setState }, { onNextPage: onNextPage })=>{
            setState({
                nextResetStrategy: (0, $f455add29e1ec026$export$7438b768c88f1c5b)
            });
            const revertStrategy = ()=>setState({
                    nextResetStrategy: (0, $f455add29e1ec026$export$6953d2fd44491a2f)
                });
            const handled = onNextPage({
                onError: revertStrategy,
                onUnsubscribe: revertStrategy
            });
            if (!handled) revertStrategy();
        };
    const $16c8c1e1a5eb8155$var$selectLastIssueOnPreviousPage = ()=>({ setState: setState }, { onPreviousPage: onPreviousPage })=>{
            setState({
                nextResetStrategy: (0, $f455add29e1ec026$export$9d24b8100c251252)
            });
            const revertStrategy = ()=>setState({
                    nextResetStrategy: (0, $f455add29e1ec026$export$6953d2fd44491a2f)
                });
            const handled = onPreviousPage({
                onError: revertStrategy,
                onUnsubscribe: revertStrategy
            });
            if (!handled) revertStrategy();
        };
    const $16c8c1e1a5eb8155$var$selectIssueOnPage = (after, shouldSelectLastIssue)=>({ setState: setState }, { onPageChange: onPageChange })=>{
            setState({
                nextResetStrategy: shouldSelectLastIssue ? (0, $f455add29e1ec026$export$9d24b8100c251252) : (0, $f455add29e1ec026$export$7438b768c88f1c5b)
            });
            const revertStrategy = ()=>setState({
                    nextResetStrategy: (0, $f455add29e1ec026$export$6953d2fd44491a2f)
                });
            onPageChange(after, {
                onError: revertStrategy,
                onUnsubscribe: revertStrategy
            });
        };
    const $16c8c1e1a5eb8155$var$selectNextIssue = (shouldDebounce)=>({ dispatch: dispatch })=>{
            const { issueKey: issueKey, issueIndex: issueIndex, isOnNextPage: isOnNextPage } = dispatch($16c8c1e1a5eb8155$var$getNextIssue());
            if (issueIndex !== null) {
                dispatch($16c8c1e1a5eb8155$var$setSelectedIssueByIndex(issueIndex, shouldDebounce));
                return true;
            }
            if (issueKey !== '') {
                dispatch($16c8c1e1a5eb8155$var$setSelectedIssueByKey(issueKey, shouldDebounce));
                return true;
            }
            if (isOnNextPage) {
                dispatch($16c8c1e1a5eb8155$var$selectFirstIssueOnNextPage());
                return true;
            }
            return false;
        };
    const $16c8c1e1a5eb8155$var$selectPreviousIssue = (shouldDebounce)=>({ dispatch: dispatch })=>{
            const { issueKey: issueKey, issueIndex: issueIndex, isOnPreviousPage: isOnPreviousPage } = dispatch($16c8c1e1a5eb8155$var$getPreviousIssue());
            if (issueIndex !== null) {
                dispatch($16c8c1e1a5eb8155$var$setSelectedIssueByIndex(issueIndex, shouldDebounce));
                return true;
            }
            if (issueKey !== '') {
                dispatch($16c8c1e1a5eb8155$var$setSelectedIssueByKey(issueKey, shouldDebounce));
                return true;
            }
            if (isOnPreviousPage) {
                dispatch($16c8c1e1a5eb8155$var$selectLastIssueOnPreviousPage());
                return true;
            }
            return false;
        };
    const $16c8c1e1a5eb8155$var$setFocusedIssueByIndex = (issueIndex)=>({ setState: setState })=>{
            setState({
                focusedIssue: issueIndex
            });
        };
    const $16c8c1e1a5eb8155$var$resetFocusedIssue = ()=>({ setState: setState })=>{
            setState({
                focusedIssue: null
            });
        };
    const $16c8c1e1a5eb8155$var$deselectIssue = (shouldDebounce = false, shouldNotifyOnChange = true, isSelectedByUserInteraction = true)=>({ setState: setState, getState: getState }, { onChange: onChange })=>{
            setState({
                selectedIssue: [
                    null,
                    (0, (0, parcelRequire("g5X3G")).toIssueKey)('')
                ]
            });
            $16c8c1e1a5eb8155$var$doSideEffectUpdates(shouldDebounce, setState, getState, onChange, shouldNotifyOnChange, isSelectedByUserInteraction);
        };
    const $16c8c1e1a5eb8155$var$enterFullPageIssueAppMode = ()=>({ setState: setState })=>setState({
                isFullPageIssueAppMode: true
            });
    const $16c8c1e1a5eb8155$var$exitFullPageIssueAppMode = ()=>({ setState: setState })=>setState({
                isFullPageIssueAppMode: false
            });
    const $16c8c1e1a5eb8155$var$getDefaultIssueKey = ()=>(_, { issueKeys: issueKeys })=>issueKeys.find((nonEmptyKey)=>Boolean(nonEmptyKey));
    const $16c8c1e1a5eb8155$var$isListViewWithoutIssueApp = ()=>({ getState: getState }, { view: view })=>{
            const { isFullPageIssueAppMode: isFullPageIssueAppMode } = getState();
            return view === (0, (0, parcelRequire("6UAdF")).views).list && !isFullPageIssueAppMode;
        };
    const $16c8c1e1a5eb8155$var$isListViewWithIssueAppAndKey = ()=>({ getState: getState }, { view: view })=>{
            const { isFullPageIssueAppMode: isFullPageIssueAppMode, selectedIssue: selectedIssue } = getState();
            return view === (0, (0, parcelRequire("6UAdF")).views).list && isFullPageIssueAppMode && !!selectedIssue[1];
        };
    const $16c8c1e1a5eb8155$var$resetSelectedIssue = (hasNextIssueKey)=>({ dispatch: dispatch, getState: getState, setState: setState }, { issueKeys: issueKeys })=>{
            const { nextResetStrategy: nextResetStrategy } = getState();
            setState({
                nextResetStrategy: (0, $f455add29e1ec026$export$6953d2fd44491a2f)
            });
            const shouldNotifyOnChange = !dispatch($16c8c1e1a5eb8155$var$isListViewWithoutIssueApp());
            const isSelectedByUserInteraction = hasNextIssueKey;
            const lastIssueIndex = issueKeys.length - 1;
            switch(nextResetStrategy){
                case 0, $f455add29e1ec026$export$9d24b8100c251252:
                    dispatch($16c8c1e1a5eb8155$var$setSelectedIssueByIndex(lastIssueIndex, false, shouldNotifyOnChange, isSelectedByUserInteraction));
                    dispatch($16c8c1e1a5eb8155$var$setFocusedIssueByIndex(lastIssueIndex));
                    break;
                case 0, $f455add29e1ec026$export$7438b768c88f1c5b:
                    dispatch($16c8c1e1a5eb8155$var$setSelectedIssueByIndex(0, false, shouldNotifyOnChange, isSelectedByUserInteraction));
                    dispatch($16c8c1e1a5eb8155$var$setFocusedIssueByIndex(0));
                    break;
                case 0, $f455add29e1ec026$export$6953d2fd44491a2f:
                default:
                    dispatch($16c8c1e1a5eb8155$var$setSelectedIssueByIndex(0, false, shouldNotifyOnChange, isSelectedByUserInteraction));
                    break;
            }
        };
    const $16c8c1e1a5eb8155$export$e324594224ef24da = {
        setSelectedIssueByKey: $16c8c1e1a5eb8155$var$setSelectedIssueByKey,
        setSelectedIssueByIndex: $16c8c1e1a5eb8155$var$setSelectedIssueByIndex,
        getSelectedIssuePosition: $16c8c1e1a5eb8155$var$getSelectedIssuePosition,
        getNextIssue: $16c8c1e1a5eb8155$var$getNextIssue,
        getPreviousIssue: $16c8c1e1a5eb8155$var$getPreviousIssue,
        selectNextIssue: $16c8c1e1a5eb8155$var$selectNextIssue,
        selectPreviousIssue: $16c8c1e1a5eb8155$var$selectPreviousIssue,
        setFocusedIssueByIndex: $16c8c1e1a5eb8155$var$setFocusedIssueByIndex,
        deselectIssue: $16c8c1e1a5eb8155$var$deselectIssue,
        selectFirstIssueOnNextPage: $16c8c1e1a5eb8155$var$selectFirstIssueOnNextPage,
        selectLastIssueOnPreviousPage: $16c8c1e1a5eb8155$var$selectLastIssueOnPreviousPage,
        selectIssueOnPage: $16c8c1e1a5eb8155$var$selectIssueOnPage,
        enterFullPageIssueAppMode: $16c8c1e1a5eb8155$var$enterFullPageIssueAppMode,
        exitFullPageIssueAppMode: $16c8c1e1a5eb8155$var$exitFullPageIssueAppMode,
        resetFocusedIssue: $16c8c1e1a5eb8155$var$resetFocusedIssue
    };
    const $16c8c1e1a5eb8155$export$3f1be494d407b77a = {
        getDefaultIssueKey: $16c8c1e1a5eb8155$var$getDefaultIssueKey,
        isListViewWithoutIssueApp: $16c8c1e1a5eb8155$var$isListViewWithoutIssueApp,
        isListViewWithIssueAppAndKey: $16c8c1e1a5eb8155$var$isListViewWithIssueAppAndKey,
        resetSelectedIssue: $16c8c1e1a5eb8155$var$resetSelectedIssue
    };
    var $16c8c1e1a5eb8155$export$2e2bcd8739ae039 = $16c8c1e1a5eb8155$export$e324594224ef24da;
});
const $f455add29e1ec026$export$6953d2fd44491a2f = 'DEFAULT_FIRST';
const $f455add29e1ec026$export$7438b768c88f1c5b = 'FORCE_FIRST';
const $f455add29e1ec026$export$9d24b8100c251252 = 'FORCE_LAST';
parcelRegister("cKK2E", function(module, exports) {
    $parcel$export(module.exports, "useFilterQuery", ()=>$964d8c42bd13cd02$export$5576614cd48b552);
    $parcel$export(module.exports, "FilterQueryProvider", ()=>$964d8c42bd13cd02$export$78b7af8c530bd7a4);
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $9Nvh7;
    var $qXt2q;
    var $dXq4S;
    var $2NMNM;
    var $fY39E;
    var $koeKL;
    const $964d8c42bd13cd02$var$FilterQueryContext = (0, $5uXOq.createContext)({
        filterQueryIsFetching: false,
        filterQueryIsNetworkError: false
    });
    $964d8c42bd13cd02$var$FilterQueryContext.displayName = 'FilterQueryContext';
    const $964d8c42bd13cd02$export$5576614cd48b552 = ()=>(0, $5uXOq.useContext)($964d8c42bd13cd02$var$FilterQueryContext);
    const $964d8c42bd13cd02$export$78b7af8c530bd7a4 = ({ refetch: refetch, query: query, children: children, filterId: filterId })=>{
        const [filterQueryIsFetching, setFilterQueryIsFetching] = (0, $5uXOq.useState)(false);
        const [filterQueryIsNetworkError, setFilterQueryNetworkError] = (0, $5uXOq.useState)(false);
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const activationId = (0, (0, parcelRequire("fY39E")).useActivationId)();
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const environment = (0, $8Uumt.useRelayEnvironment)();
        const inFlightRequest = (0, $5uXOq.useRef)();
        (0, $5uXOq.useEffect)(()=>{
            if (filterId !== undefined && (0, (0, parcelRequire("9Nvh7")).isFilterId)(filterId)) {
                inFlightRequest.current?.unsubscribe();
                const variables = {
                    filterAri: (0, (0, parcelRequire("dXq4S")).createAri)({
                        resourceOwner: 'jira',
                        cloudId: cloudId,
                        resourceType: 'filter',
                        resourceId: filterId,
                        activationId: activationId
                    }),
                    includeFilter: true
                };
                setFilterQueryIsFetching(true);
                setFilterQueryNetworkError(false);
                inFlightRequest.current = (0, $8Uumt.fetchQuery)(environment, query, variables, {
                    fetchPolicy: 'store-or-network'
                }).subscribe({
                    complete: ()=>{
                        refetch(variables, {
                            fetchPolicy: 'store-only'
                        });
                        setFilterQueryIsFetching(false);
                    },
                    error: ()=>{
                        setFilterQueryIsFetching(false);
                        setFilterQueryNetworkError(true);
                    }
                });
            } else refetch({
                includeFilter: false,
                filterAri: ''
            }, {
                fetchPolicy: 'store-or-network'
            });
        }, [
            activationId,
            cloudId,
            environment,
            filterId,
            query,
            refetch,
            setFilterQueryIsFetching,
            setFilterQueryNetworkError
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (filterId !== undefined && (0, (0, parcelRequire("9Nvh7")).isFilterId)(filterId)) {
                const analyticsEvent = createAnalyticsEvent({});
                (0, (0, parcelRequire("qXt2q")).addFilterToRecent)(filterId, analyticsEvent);
            }
        }, [
            filterId,
            createAnalyticsEvent
        ]);
        const contextValue = (0, $5uXOq.useMemo)(()=>({
                filterQueryIsFetching: filterQueryIsFetching,
                filterQueryIsNetworkError: filterQueryIsNetworkError
            }), [
            filterQueryIsFetching,
            filterQueryIsNetworkError
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($964d8c42bd13cd02$var$FilterQueryContext.Provider, {
            value: contextValue
        }, children);
    };
});
parcelRegister("qXt2q", function(module, exports) {
    $parcel$export(module.exports, "addFilterToRecent", ()=>$d3ebef4b85856e17$export$1f166118234b128e);
    var $jcw0u;
    var $eusud;
    const $d3ebef4b85856e17$export$1f166118234b128e = async (filterId, analyticsEvent)=>{
        try {
            await (0, (0, parcelRequire("eusud")).performPostRequest)((0, $530a97584bf6c339$export$3fdeac1311ebf841) + filterId);
        } catch (error) {
            (0, (0, parcelRequire("jcw0u")).default)({
                error: error,
                event: analyticsEvent,
                meta: {
                    id: 'addFilterToRecent',
                    packageName: 'jiraIssueNavigatorCustomFilters'
                }
            });
        }
    };
});
const $530a97584bf6c339$export$3fdeac1311ebf841 = '/rest/issueNav/1/history/saveFilter/';
parcelRegister("a5eZM", function(module, exports) {
    $parcel$export(module.exports, "IssueSearch", ()=>$b919584441152507$export$ce2913cefc6e57dc);
    var $26JO5;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $7HGHK;
    var $pa9q9;
    var $9Nvh7;
    var $koeKL;
    var $6UAdF;
    var $eJ0Zs;
    var $gwtSG;
    var $1GlUV;
    var $1PJ7c;
    var $aHzNA;
    var $hkDYg;
    const $b919584441152507$var$searchErrorFlagId = (0, (0, parcelRequire("7HGHK")).toFlagId)('ISSUE_NAVIGATOR_SEARCH_ERROR_FLAG');
    const $b919584441152507$var$searchErrorFlag = {
        id: $b919584441152507$var$searchErrorFlagId,
        type: 'error',
        title: (0, (0, parcelRequire("hkDYg")).default).searchFailedTitle,
        description: (0, (0, parcelRequire("hkDYg")).default).searchFailedDescription
    };
    const $b919584441152507$export$ce2913cefc6e57dc = ({ children: children, onPageDataLoad: onPageDataLoad, view: view, refetch: refetch, onLoadNext: onLoadNext, onLoadPrevious: onLoadPrevious, isLoadingNext: isLoadingNext, isLoadingPrevious: isLoadingPrevious, hasNext: hasNext, hasPrevious: hasPrevious, isIssueHierarchySupportEnabled: isIssueHierarchySupportEnabled })=>{
        const { showFlag: showFlag, dismissFlag: dismissFlag } = (0, (0, parcelRequire("pa9q9")).useFlagsService)();
        const environment = (0, $8Uumt.useRelayEnvironment)();
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const { jql: jql, filterId: filterId } = (0, (0, parcelRequire("gwtSG")).useActiveJql)();
        const issueSearchInput = (0, $5uXOq.useMemo)(()=>jql === undefined && (0, (0, parcelRequire("9Nvh7")).isFilterId)(filterId) ? {
                filterId: filterId
            } : {
                jql: jql
            }, [
            filterId,
            jql
        ]);
        const onSelectedViewMutation = (0, (0, parcelRequire("aHzNA")).useSelectedViewMutation)();
        const viewData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("26JO5")).default), view);
        const onRefetchStart = (0, $5uXOq.useCallback)(()=>{
            dismissFlag($b919584441152507$var$searchErrorFlagId);
        }, [
            dismissFlag
        ]);
        const viewId = (0, (0, parcelRequire("eJ0Zs")).parseIssueNavigatorViewIdOrDefault)(viewData?.viewId);
        const { onIssueSearchFail: onIssueSearchFail } = (0, (0, parcelRequire("1GlUV")).useExperienceAnalytics)(onPageDataLoad, viewId);
        const onRefetchError = (0, $5uXOq.useCallback)((error, refetchView)=>{
            onIssueSearchFail(`${(0, (0, parcelRequire("6UAdF")).PACKAGE_NAME)}.issue-search-refetch`, error, refetchView);
            showFlag($b919584441152507$var$searchErrorFlag);
        }, [
            onIssueSearchFail,
            showFlag
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1PJ7c")).IssueSearchServiceContainer), {
            cloudId: cloudId,
            environment: environment,
            issueSearchInput: issueSearchInput,
            viewId: viewId,
            filterId: filterId,
            onPageDataLoad: onPageDataLoad,
            onRefetchStart: onRefetchStart,
            onRefetchError: onRefetchError,
            onSelectedViewMutation: onSelectedViewMutation,
            refetch: refetch,
            onLoadNext: onLoadNext,
            onLoadPrevious: onLoadPrevious,
            isLoadingNext: isLoadingNext,
            isLoadingPrevious: isLoadingPrevious,
            hasNext: hasNext,
            hasPrevious: hasPrevious,
            isHierarchyEnabled: Boolean(isIssueHierarchySupportEnabled && viewData?.viewConfigSettings?.isHierarchyEnabled)
        }, children);
    };
});
parcelRegister("26JO5", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$ca6e5afb96fea766$export$2e2bcd8739ae039);
    const $ca6e5afb96fea766$var$node = {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider"
            },
            {
                "kind": "RootArgument",
                "name": "staticViewInput"
            }
        ],
        "kind": "Fragment",
        "name": "issueSearch_issueNavigator",
        "selections": [
            {
                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                    {
                        "args": [
                            {
                                "kind": "Variable",
                                "name": "staticViewInput",
                                "variableName": "staticViewInput"
                            }
                        ],
                        "concreteType": "JiraIssueSearchViewConfigSettings",
                        "kind": "LinkedField",
                        "name": "viewConfigSettings",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "name": "isHierarchyEnabled"
                            }
                        ]
                    }
                ]
            },
            {
                "kind": "RequiredField",
                "field": {
                    "kind": "ScalarField",
                    "name": "viewId"
                },
                "action": "THROW",
                "path": "viewId"
            }
        ],
        "type": "JiraIssueSearchView"
    };
    $ca6e5afb96fea766$var$node.hash = "90664ed4e279039c63da57e40da847c6";
    var $ca6e5afb96fea766$export$2e2bcd8739ae039 = $ca6e5afb96fea766$var$node;
});
parcelRegister("1PJ7c", function(module, exports) {
    $parcel$export(module.exports, "IssueSearchServiceContainer", ()=>$4a9e937233ca8a4b$export$3744f0b7d0dd1936);
    $parcel$export(module.exports, "Store", ()=>$4a9e937233ca8a4b$export$390f32400eaf98c9);
    var $8Rkzz = parcelRequire("8Rkzz");
    var $7Jxgt = parcelRequire("7Jxgt");
    var $6Z846;
    var $gg7kZ;
    var $iXqE5;
    var $012Fv;
    const $4a9e937233ca8a4b$var$initialState = {
        onLoadNext: (0, ($parcel$interopDefault($8Rkzz))),
        onLoadPrevious: (0, ($parcel$interopDefault($8Rkzz))),
        isLoadingNext: false,
        isLoadingPrevious: false,
        hasNext: false,
        hasPrevious: false,
        disposables: [],
        isFetching: false,
        isFetchingView: false,
        isRefreshing: false,
        isNetworkError: false,
        searchKey: '',
        totalIssueCount: null,
        totalIssueCountIsFetching: false,
        totalIssueCountIsError: false
    };
    const $4a9e937233ca8a4b$export$3744f0b7d0dd1936 = (0, (0, parcelRequire("gg7kZ")).createContainer)();
    const $4a9e937233ca8a4b$export$390f32400eaf98c9 = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: $4a9e937233ca8a4b$var$initialState,
        actions: (0, parcelRequire("012Fv")).actions,
        containedBy: $4a9e937233ca8a4b$export$3744f0b7d0dd1936,
        handlers: {
            onInit: ()=>({ dispatch: dispatch, setState: setState })=>{
                    dispatch((0, (0, parcelRequire("012Fv")).onContainerUpdate)());
                    setState({
                        searchKey: (0, ($parcel$interopDefault($7Jxgt)))()
                    });
                },
            onContainerUpdate: (nextProps, prevProps)=>({ dispatch: dispatch })=>{
                    dispatch((0, (0, parcelRequire("012Fv")).onContainerUpdate)());
                    const hasIssueSearchInputChanged = !(0, (0, parcelRequire("6Z846")).isShallowEqual)(nextProps.issueSearchInput, prevProps.issueSearchInput) || nextProps.isHierarchyEnabled !== prevProps.isHierarchyEnabled;
                    if (hasIssueSearchInputChanged) dispatch((0, (0, parcelRequire("012Fv")).onSearchInputUpdate)());
                },
            onDestroy: ()=>({ getState: getState })=>{
                    const { disposables: disposables } = getState();
                    disposables.forEach((disposable)=>disposable.dispose());
                }
        }
    });
});
parcelRegister("012Fv", function(module, exports) {
    $parcel$export(module.exports, "onContainerUpdate", ()=>$a4a2e9f3a0dcbb02$export$ab8f0de40a3a2d2d);
    $parcel$export(module.exports, "onSearchInputUpdate", ()=>$a4a2e9f3a0dcbb02$export$61bc3f1fa2f74c3c);
    $parcel$export(module.exports, "actions", ()=>$a4a2e9f3a0dcbb02$export$e324594224ef24da);
    var $eANr0;
    var $7nsd3 = parcelRequire("7nsd3");
    var $eiowN = parcelRequire("eiowN");
    var $7Jxgt = parcelRequire("7Jxgt");
    var $4R6GH;
    var $9Nvh7;
    var $kF1V3;
    var $6UAdF;
    var $eJ0Zs;
    const $a4a2e9f3a0dcbb02$export$ab8f0de40a3a2d2d = ()=>({ setState: setState }, { onLoadNext: onLoadNext, onLoadPrevious: onLoadPrevious, isLoadingNext: isLoadingNext, isLoadingPrevious: isLoadingPrevious, hasNext: hasNext, hasPrevious: hasPrevious })=>{
            const infiniteScrollProps = {
                onLoadNext: onLoadNext,
                onLoadPrevious: onLoadPrevious,
                isLoadingNext: isLoadingNext,
                isLoadingPrevious: isLoadingPrevious,
                hasNext: hasNext,
                hasPrevious: hasPrevious
            };
            setState(infiniteScrollProps);
        };
    const $a4a2e9f3a0dcbb02$export$61bc3f1fa2f74c3c = ()=>({ dispatch: dispatch }, { issueSearchInput: issueSearchInput, filterId: filterId, viewId: viewId })=>{
            const shouldLoadWithFilterViewId = viewId !== 'detail' && issueSearchInput.filterId && (0, (0, parcelRequire("9Nvh7")).isCustomFilter)(issueSearchInput.filterId);
            const isCurrentViewIdInvalid = (0, (0, parcelRequire("eJ0Zs")).isFilterViewId)(viewId) && viewId !== `list_filter_${filterId}`;
            let variables = {};
            if (issueSearchInput.filterId && shouldLoadWithFilterViewId) variables = {
                viewId: (0, (0, parcelRequire("eJ0Zs")).convertFilterIdToIssueNavigatorId)(issueSearchInput.filterId)
            };
            else if (isCurrentViewIdInvalid) variables = {
                viewId: (0, (0, parcelRequire("6UAdF")).DEFAULT_VIEW_ID)
            };
            dispatch($a4a2e9f3a0dcbb02$export$e324594224ef24da.onRefetch(variables));
        };
    const $a4a2e9f3a0dcbb02$export$9eb6b63e55022ba0 = ()=>({ setState: setState })=>{
            setState({
                totalIssueCount: null,
                totalIssueCountIsFetching: false,
                totalIssueCountIsError: false
            });
        };
    const $a4a2e9f3a0dcbb02$export$e324594224ef24da = {
        onChangeView: (viewId)=>({ dispatch: dispatch }, { onSelectedViewMutation: onSelectedViewMutation })=>{
                onSelectedViewMutation(viewId);
                dispatch($a4a2e9f3a0dcbb02$export$e324594224ef24da.onRefetch({
                    viewId: viewId
                }));
            },
        onRefetch: (variables = {}, options = {})=>({ dispatch: dispatch, getState: getState, setState: setState }, { cloudId: cloudId, environment: environment, issueSearchInput: issueSearchInput, onRefetchStart: onRefetchStart, onRefetchError: onRefetchError, refetch: refetch, viewId: viewId, isHierarchyEnabled: isHierarchyEnabled })=>{
                let { inFlightRequest: inFlightRequest } = getState();
                inFlightRequest?.unsubscribe();
                const staticViewInput = {
                    isHierarchyEnabled: isHierarchyEnabled
                };
                const finalVariables = {
                    cloudId: cloudId,
                    issueSearchInput: issueSearchInput,
                    first: (0, (0, parcelRequire("6UAdF")).MAX_ISSUES_PER_PAGE),
                    after: null,
                    namespace: 'ISSUE_NAVIGATOR',
                    viewId: viewId,
                    options: null,
                    fieldSetIds: [],
                    shouldQueryFieldSetsById: false,
                    amountOfColumns: (0, (0, parcelRequire("6UAdF")).MAX_AMOUNT_OF_COLUMNS),
                    filterId: null,
                    isRefactorNinToViewSchemaEnabled: (0, (0, parcelRequire("4R6GH")).fg)('refactor_nin_to_jira_view_schema'),
                    staticViewInput: staticViewInput,
                    viewConfigInput: {
                        staticViewInput: staticViewInput
                    },
                    ...variables
                };
                inFlightRequest = (0, $eiowN.fetchQuery)(environment, (0, (0, parcelRequire("kF1V3")).default), finalVariables, {
                    fetchPolicy: 'network-only',
                    networkCacheConfig: {
                        metadata: {
                            META_SLOW_ENDPOINT: true
                        }
                    }
                }).subscribe({
                    complete: ()=>{
                        const operation = (0, $eiowN.createOperationDescriptor)((0, $eiowN.getRequest)((0, (0, parcelRequire("kF1V3")).default)), finalVariables);
                        const { disposables: disposables } = getState();
                        setState({
                            disposables: disposables.concat([
                                environment.retain(operation)
                            ])
                        });
                        let hasStoreRefetchCompleted = false;
                        (0, ($parcel$interopDefault($7nsd3))).unstable_batchedUpdates(()=>{
                            refetch(finalVariables, {
                                fetchPolicy: 'store-only',
                                onComplete: ()=>{
                                    if (hasStoreRefetchCompleted) return;
                                    hasStoreRefetchCompleted = true;
                                    queueMicrotask(()=>{
                                        setState({
                                            isFetching: false,
                                            isFetchingView: false,
                                            searchKey: (0, ($parcel$interopDefault($7Jxgt)))()
                                        });
                                        dispatch($a4a2e9f3a0dcbb02$export$9eb6b63e55022ba0());
                                        options.onComplete?.();
                                    });
                                }
                            });
                        });
                    },
                    error: (error)=>{
                        setState({
                            isFetching: false,
                            isFetchingView: false,
                            isNetworkError: true
                        });
                        options.onError?.();
                        onRefetchError?.(error, (0, (0, parcelRequire("eJ0Zs")).parseIssueNavigatorViewIdOrDefault)(finalVariables.viewId));
                    },
                    unsubscribe: ()=>{
                        options.onUnsubscribe?.();
                    }
                });
                onRefetchStart?.();
                setState({
                    inFlightRequest: inFlightRequest,
                    isFetching: true,
                    isFetchingView: viewId !== finalVariables.viewId,
                    isNetworkError: false
                });
            },
        onRefresh: ()=>({ setState: setState, dispatch: dispatch })=>{
                setState({
                    isRefreshing: true
                });
                const onFinish = ()=>{
                    setState({
                        isRefreshing: false
                    });
                };
                dispatch($a4a2e9f3a0dcbb02$export$e324594224ef24da.onRefetch({}, {
                    onComplete: onFinish,
                    onError: onFinish,
                    onUnsubscribe: onFinish
                }));
            },
        onFetchTotalIssueCount: ()=>({ setState: setState }, { environment: environment, cloudId: cloudId, issueSearchInput: issueSearchInput })=>{
                setState({
                    totalIssueCount: null,
                    totalIssueCountIsFetching: true,
                    totalIssueCountIsError: false
                });
                (0, $eiowN.fetchQuery)(environment, (0, (0, parcelRequire("eANr0")).default), {
                    cloudId: cloudId,
                    issueSearchInput: issueSearchInput
                }, {
                    fetchPolicy: 'network-only',
                    networkCacheConfig: {
                        metadata: {
                            META_SLOW_ENDPOINT: true
                        }
                    }
                }).subscribe({
                    next: (data)=>{
                        const totalIssueCount = data?.jira?.issueSearchTotalCount ?? null;
                        const totalIssueCountIsError = totalIssueCount === null;
                        setState({
                            totalIssueCount: totalIssueCount,
                            totalIssueCountIsFetching: false,
                            totalIssueCountIsError: totalIssueCountIsError
                        });
                    },
                    error: ()=>{
                        setState({
                            totalIssueCount: null,
                            totalIssueCountIsFetching: false,
                            totalIssueCountIsError: true
                        });
                    }
                });
            }
    };
});
parcelRegister("eANr0", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$6bed1c47c19db2e0$export$2e2bcd8739ae039);
    const $6bed1c47c19db2e0$var$node = function() {
        var v0 = [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cloudId"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "issueSearchInput"
            }
        ], v1 = [
            {
                "concreteType": "JiraQuery",
                "kind": "LinkedField",
                "name": "jira",
                "plural": false,
                "selections": [
                    {
                        "args": [
                            {
                                "kind": "Variable",
                                "name": "cloudId",
                                "variableName": "cloudId"
                            },
                            {
                                "kind": "Variable",
                                "name": "issueSearchInput",
                                "variableName": "issueSearchInput"
                            }
                        ],
                        "kind": "ScalarField",
                        "name": "issueSearchTotalCount"
                    }
                ]
            }
        ];
        return {
            "fragment": {
                "argumentDefinitions": v0,
                "kind": "Fragment",
                "name": "actions_issueSearchTotalCountQuery",
                "selections": v1,
                "type": "Query"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": v0,
                "kind": "Operation",
                "name": "actions_issueSearchTotalCountQuery",
                "selections": v1
            },
            "params": {
                "id": "ea1a03f3c5fe0687fade2c03e1ce38b97eb1888360eb9a34dfc69dfbbb066f32",
                "metadata": {},
                "name": "actions_issueSearchTotalCountQuery",
                "operationKind": "query",
                "text": null
            }
        };
    }();
    $6bed1c47c19db2e0$var$node.hash = "9220620a1c9450bcc153a38c7d2c2de0";
    var $6bed1c47c19db2e0$export$2e2bcd8739ae039 = $6bed1c47c19db2e0$var$node;
});
parcelRegister("hkDYg", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$cc996088b4c53457$export$2e2bcd8739ae039);
    var $7VHMR;
    var $cc996088b4c53457$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        searchFailedTitle: {
            "id": "issue-navigator.issue-search.search-failed-title",
            "defaultMessage": "We couldn't load your search results"
        },
        searchFailedDescription: {
            "id": "issue-navigator.issue-search.search-failed-description",
            "defaultMessage": "Refresh and try again."
        }
    });
});
parcelRegister("en3WN", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$a0c308f64e79b7ea$export$2e2bcd8739ae039);
    var $1lc8w;
    var $gYyDI;
    var $9wiT5;
    var $hH7GY;
    var $gWLDR;
    var $lxams;
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Rkzz = parcelRequire("8Rkzz");
    var $8Uumt = parcelRequire("8Uumt");
    var $fraBZ;
    var $fvbIR;
    var $4mO32;
    var $6s1PB;
    var $co1wz;
    var $4R6GH;
    var $jJZqs;
    var $koVbs;
    var $9Nvh7;
    var $5Czte;
    var $48rMm;
    var $bSg1p;
    var $1XJ20;
    var $5oFIy;
    var $6UAdF;
    var $bbbXL;
    var $a8lIB;
    var $kMfdg;
    var $5NwJk;
    var $ih9zY;
    var $gwtSG;
    var $cKK2E;
    var $isIOR;
    var $38r5c;
    var $7jipW;
    var $jKHs3;
    var $hWyif;
    var $aTDVl;
    var $gOpmP;
    var $7Cqtr;
    var $5RTlC;
    var $6tXCF;
    var $dqObP;
    const $a0c308f64e79b7ea$var$staticEntrypointParams = {};
    const $a0c308f64e79b7ea$var$staticUseEntryPointFGOff = {
        entryPointActions: {
            load: (0, ($parcel$interopDefault($8Rkzz)))
        },
        entryPointReferenceSubject: undefined
    };
    const $a0c308f64e79b7ea$var$ConditionalIssueTableSelectedIssuesContainer = ({ children: children, onIssueSelected: onIssueSelected })=>{
        if (!(0, (0, parcelRequire("4R6GH")).fg)('bulk_operations_in_nin')) return children;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("48rMm")).IssueTableSelectedIssuesContainer), {
            onIssueSelected: onIssueSelected
        }, children);
    };
    const $a0c308f64e79b7ea$export$397fa57fff06920f = ({ CustomHeader: CustomHeader, ActionMenu: ActionMenu, HeaderSkeletonImage: HeaderSkeletonImage, filter: filter, onPageDataLoad: onPageDataLoad, onChangeColumnConfiguration: onChangeColumnConfiguration, onChangeJql: onChangeJql, onChangeIssue: onChangeIssue, onChangeFilter: onChangeFilter, defaultJql: defaultJql, onRefinement: onRefinement, issueResults: issueResults, groupResults: groupResults, viewResult: viewResult, project: project, userPreferences: userPreferences, jqlBuilderProps: jqlBuilderProps, jqlBuilderWithAiKey: jqlBuilderWithAiKey, isFeedbackButtonDisabled: isFeedbackButtonDisabled, issueTableProps: issueTableProps, isIssueHierarchySupportEnabled: isIssueHierarchySupportEnabled })=>{
        (0, (0, parcelRequire("a8lIB")).markOnce)((0, (0, parcelRequire("a8lIB")).marks).ISSUE_NAVIGATOR_UI_START);
        (0, $5uXOq.useLayoutEffect)(()=>{
            (0, (0, parcelRequire("a8lIB")).markOnce)((0, (0, parcelRequire("a8lIB")).marks).ISSUE_NAVIGATOR_UI_END);
        }, []);
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const [caughtIssueSearchError, setCaughtIssueSearchError] = (0, $5uXOq.useState)();
        const { filterId: filterId } = (0, (0, parcelRequire("gwtSG")).useActiveJql)();
        const { filterQueryIsFetching: filterQueryIsFetching } = (0, (0, parcelRequire("cKK2E")).useFilterQuery)();
        const handleReset = (0, $5uXOq.useCallback)((isResetToFilter = false)=>{
            if (isResetToFilter) onChangeFilter && onChangeFilter();
            else onChangeJql && onChangeJql(defaultJql, true);
        }, [
            onChangeFilter,
            onChangeJql,
            defaultJql
        ]);
        const onChange = (0, $5uXOq.useCallback)((jql)=>{
            onRefinement && onRefinement();
            onChangeJql && onChangeJql(jql, !filterQueryIsFetching && filter === null && (0, (0, parcelRequire("9Nvh7")).isFilterId)(filterId));
        }, [
            filter,
            filterId,
            filterQueryIsFetching,
            onChangeJql,
            onRefinement
        ]);
        let searchKey;
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) searchKey = (0, (0, parcelRequire("38r5c")).useSearchKey)();
        else searchKey = (0, (0, parcelRequire("isIOR")).useIssueSearchQuery)().searchKey;
        const previousData = (0, (0, parcelRequire("1XJ20")).usePrevious)(searchKey);
        const hasSearchChanged = Boolean(searchKey && searchKey !== previousData);
        const [{ view: selectedView }] = (0, (0, parcelRequire("ih9zY")).useSelectedViewState)();
        const issueResultsData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("lxams")).default), issueResults);
        const groupResultsData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("gWLDR")).default), groupResults);
        const viewResultData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("hH7GY")).default), viewResult);
        const viewResultError = (0, $5uXOq.useMemo)(()=>viewResultData?.__typename === 'QueryError' && viewResultData?.extensions?.[0]?.statusCode !== undefined && viewResultData?.extensions?.[0]?.statusCode !== null ? new (0, (0, parcelRequire("jJZqs")).default)(viewResultData?.extensions?.[0]?.statusCode, viewResultData?.message ?? '') : undefined, [
            viewResultData
        ]);
        const userPreferencesData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("9wiT5")).default), userPreferences);
        const jqlBuilderWithAiData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("gYyDI")).default), jqlBuilderWithAiKey);
        const projectData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("1lc8w")).default), project);
        const shouldAnnounceInfo = (0, $5uXOq.useMemo)(()=>issueResultsData !== null && hasSearchChanged && typeof issueResultsData.totalIssueSearchResultCount === 'number', [
            issueResultsData,
            hasSearchChanged
        ]);
        const [isAiEnabled, setIsAiEnabled] = (0, $5uXOq.useState)(false);
        const onToggleAi = (0, $5uXOq.useCallback)((isEnabled)=>{
            setIsAiEnabled(isEnabled);
        }, []);
        const noFilterReason = (0, $5uXOq.useMemo)(()=>{
            if (!filterQueryIsFetching && filter === null && (0, (0, parcelRequire("9Nvh7")).isFilterId)(filterId)) return 'Filter query mismatch';
            if (viewResultData?.__typename === 'QueryError' && viewResultData?.extensions?.[0]?.statusCode === 404) return 'Filter not found in view result';
            return undefined;
        }, [
            filter,
            filterId,
            filterQueryIsFetching,
            viewResultData?.__typename,
            viewResultData?.extensions
        ]);
        const IssueNavigatorTopBar = (0, $5uXOq.useMemo)(()=>{
            const jqlBuilder = (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gOpmP")).default), {
                onChangeJql: onChange,
                defaultJql: defaultJql,
                filter: filter,
                onReset: handleReset,
                onFilterSave: onChangeFilter,
                issueResults: issueResultsData,
                onToggleAi: onToggleAi,
                jqlBuilderWithAiKey: jqlBuilderWithAiData,
                jqlBuilderProps: jqlBuilderProps
            });
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, ActionMenu && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dqObP")).default), {
                ActionMenu: ActionMenu,
                filter: filter,
                issueResults: issueResultsData,
                HeaderSkeletonImage: HeaderSkeletonImage,
                jqlBuilder: jqlBuilder,
                viewResult: viewResultData?.__typename === 'JiraIssueSearchView' ? viewResultData : null
            }), CustomHeader && (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hWyif")).default), {
                CustomHeader: CustomHeader,
                filter: filter,
                issueResults: issueResultsData,
                HeaderSkeletonImage: HeaderSkeletonImage,
                viewResult: viewResultData?.__typename === 'JiraIssueSearchView' ? viewResultData : null
            }), jqlBuilder));
        }, [
            defaultJql,
            filter,
            handleReset,
            issueResultsData,
            jqlBuilderProps,
            jqlBuilderWithAiData,
            onChange,
            onChangeFilter,
            onToggleAi,
            ActionMenu,
            CustomHeader,
            HeaderSkeletonImage,
            viewResultData
        ]);
        const { entryPointActions: entryPointActions, entryPointReferenceSubject: entryPointReferenceSubject } = (0, (0, parcelRequire("4R6GH")).fg)('bulk_operations_in_nin') ? (0, (0, parcelRequire("4mO32")).useEntryPoint)((0, (0, parcelRequire("fvbIR")).asyncBulkOpsCoreEntryPoint), $a0c308f64e79b7ea$var$staticEntrypointParams) : $a0c308f64e79b7ea$var$staticUseEntryPointFGOff;
        let IssueNavigatorContent = (0, $5uXOq.useMemo)(()=>{
            const getMessageString = ()=>{
                if (issueResultsData?.isCappingIssueSearchResult) return (0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("7Cqtr")).default).screenReaderSearchResultMoreInfoIssueTermRefresh : (0, (0, parcelRequire("7Cqtr")).default).screenReaderSearchResultMoreInfo;
                return (0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("7Cqtr")).default).screenReaderSearchResultInfoIssueTermRefresh : (0, (0, parcelRequire("7Cqtr")).default).screenReaderSearchResultInfo;
            };
            const HierarchyOnboardingProviderComponent = (0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isHierarchyEnabled', false) && (0, (0, parcelRequire("4R6GH")).fg)('jsc_-_m2_hierarchy_api_updates') ? (0, (0, parcelRequire("bSg1p")).HierarchyOnboardingProvider) : (0, $5uXOq.Fragment);
            return (0, ($parcel$interopDefault($5uXOq))).createElement($a0c308f64e79b7ea$var$ConditionalIssueTableSelectedIssuesContainer, {
                onIssueSelected: entryPointActions.load
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7jipW")).BulkOperationsWrapper), {
                bulkOpsCoreEntryPointReference: entryPointReferenceSubject,
                fragmentRef: issueResultsData
            }, (0, ($parcel$interopDefault($5uXOq))).createElement(HierarchyOnboardingProviderComponent, null, (0, ($parcel$interopDefault($5uXOq))).createElement($a0c308f64e79b7ea$var$IssueNavigatorContentContainer, {
                selectedView: selectedView
            }, IssueNavigatorTopBar, !filterQueryIsFetching && filter === null && (0, (0, parcelRequire("9Nvh7")).isFilterId)(filterId) || viewResultData?.__typename === 'QueryError' && viewResultData?.extensions?.[0]?.statusCode === 404 ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bbbXL")).NoFilter), {
                reason: noFilterReason
            }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fraBZ")).AnnouncerV2), {
                message: formatMessage(getMessageString(), {
                    total: issueResultsData?.totalIssueSearchResultCount
                }),
                shouldAnnounce: shouldAnnounceInfo,
                testId: "issue-navigator.ui.search-announcer"
            }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("aTDVl")).default), {
                onChangeColumnConfiguration: onChangeColumnConfiguration,
                onChangeJql: onChangeJql,
                onCaughtIssueSearchError: setCaughtIssueSearchError,
                issueResults: issueResultsData,
                viewResult: viewResultData,
                project: projectData,
                groupResults: groupResultsData,
                isFeedbackButtonDisabled: isFeedbackButtonDisabled,
                issueTableProps: issueTableProps,
                isIssueHierarchySupportEnabled: isIssueHierarchySupportEnabled,
                filter: filter
            })), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6tXCF")).default), {
                hasIssueResults: !!issueResultsData?.totalIssueSearchResultCount,
                userPreferences: userPreferencesData,
                isAiEnabled: isAiEnabled
            })))));
        }, [
            entryPointActions.load,
            entryPointReferenceSubject,
            issueResultsData,
            selectedView,
            IssueNavigatorTopBar,
            filterQueryIsFetching,
            filter,
            filterId,
            viewResultData,
            noFilterReason,
            formatMessage,
            shouldAnnounceInfo,
            onChangeColumnConfiguration,
            onChangeJql,
            projectData,
            groupResultsData,
            isFeedbackButtonDisabled,
            issueTableProps,
            isIssueHierarchySupportEnabled,
            userPreferencesData,
            isAiEnabled
        ]);
        const isFullPageIssueAppMode = (0, (0, parcelRequire("5NwJk")).useIsFullPageIssueAppMode)();
        if (isFullPageIssueAppMode) IssueNavigatorContent = (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jKHs3")).default), {
            onChangeIssue: onChangeIssue,
            issueResults: issueResultsData,
            view: viewResultData?.__typename === 'JiraIssueSearchView' ? viewResultData : null
        });
        (0, (0, parcelRequire("co1wz")).ff)('nin.global-scope_aqec8') && (0, (0, parcelRequire("5Czte")).useGlobalScopeOptInFlag)();
        const isFailedIssueSearch = !issueResultsData || issueResultsData.issueSearchError?.__typename === 'JiraServerError' || caughtIssueSearchError !== undefined || !viewResultData || viewResultData?.__typename === 'QueryError' && viewResultData?.extensions?.[0]?.statusCode !== 404;
        return (0, $5uXOq.useMemo)(()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, IssueNavigatorContent, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5RTlC")).default), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireScreenAnalytics), null), isFailedIssueSearch ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kMfdg")).IssueSearchFail), {
                location: `${(0, (0, parcelRequire("6UAdF")).PACKAGE_NAME)}.ui`,
                error: caughtIssueSearchError || viewResultError || new Error('There was an error searching for issues'),
                onPageDataLoad: onPageDataLoad,
                attributes: {
                    isAiEnabled: isAiEnabled
                }
            }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kMfdg")).IssueSearchSuccess), {
                onPageDataLoad: onPageDataLoad,
                attributes: {
                    isAiEnabled: isAiEnabled
                }
            })), [
            IssueNavigatorContent,
            isFailedIssueSearch,
            caughtIssueSearchError,
            viewResultError,
            onPageDataLoad,
            isAiEnabled
        ]);
    };
    $a0c308f64e79b7ea$export$397fa57fff06920f.whyDidYouRender = true;
    var $a0c308f64e79b7ea$export$2e2bcd8739ae039 = (0, $5uXOq.memo)($a0c308f64e79b7ea$export$397fa57fff06920f);
    const $a0c308f64e79b7ea$var$IssueNavigatorContentContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { selectedView: selectedView, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _2lx21bp4 _4t3i1osq _18s8fkly",
                __cmplp.selectedView !== 'detail' && "_n3tdv47k _vchhusvi",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("1lc8w", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$7d058da013b06c2d$export$2e2bcd8739ae039);
    const $7d058da013b06c2d$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_IssueNavigatorUI_project",
        "selections": [
            {
                "kind": "FragmentSpread",
                "name": "main_issueNavigator_IssueResults_project"
            }
        ],
        "type": "JiraProject"
    };
    $7d058da013b06c2d$var$node.hash = "81c34d1aab5f8b19d5c03d75b9b44193";
    var $7d058da013b06c2d$export$2e2bcd8739ae039 = $7d058da013b06c2d$var$node;
});
parcelRegister("gYyDI", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$aaeb5527acfb0176$export$2e2bcd8739ae039);
    const $aaeb5527acfb0176$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_IssueNavigatorUI_jqlBuilderWithAiKey",
        "selections": [
            {
                "kind": "FragmentSpread",
                "name": "jqlBuilder_issueNavigator_JQLBuilderWrapper_jqlBuilderWithAiKey"
            }
        ],
        "type": "JiraQuery"
    };
    $aaeb5527acfb0176$var$node.hash = "e9088c921273ca13e8f1d0f476e2f2fb";
    var $aaeb5527acfb0176$export$2e2bcd8739ae039 = $aaeb5527acfb0176$var$node;
});
parcelRegister("9wiT5", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$98d194ce17a15e42$export$2e2bcd8739ae039);
    const $98d194ce17a15e42$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_IssueNavigatorUI_userPreferences",
        "selections": [
            {
                "kind": "FragmentSpread",
                "name": "spotlight_issueNavigator_IssueNavigatorSpotlight"
            }
        ],
        "type": "JiraUserPreferences"
    };
    $98d194ce17a15e42$var$node.hash = "aa84b2b1589fd8e695aa782c018fd60c";
    var $98d194ce17a15e42$export$2e2bcd8739ae039 = $98d194ce17a15e42$var$node;
});
parcelRegister("hH7GY", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9d27e1baab967744$export$2e2bcd8739ae039);
    const $9d27e1baab967744$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_IssueNavigatorUI_viewResult",
        "selections": [
            {
                "kind": "ScalarField",
                "name": "__typename"
            },
            {
                "kind": "FragmentSpread",
                "name": "main_issueNavigator_IssueResults_viewResult"
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    {
                        "kind": "FragmentSpread",
                        "name": "main_issueNavigator_FullPageIssueApp_view"
                    },
                    {
                        "kind": "FragmentSpread",
                        "name": "main_issueNavigator_Header_viewResult"
                    },
                    {
                        "kind": "FragmentSpread",
                        "name": "topBar_issueNavigator_viewResult"
                    }
                ],
                "type": "JiraIssueSearchView"
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    {
                        "kind": "ScalarField",
                        "name": "message"
                    },
                    {
                        "kind": "LinkedField",
                        "name": "extensions",
                        "plural": true,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "name": "statusCode"
                            }
                        ]
                    }
                ],
                "type": "QueryError"
            }
        ],
        "type": "JiraIssueSearchViewResult",
        "abstractKey": "__isJiraIssueSearchViewResult"
    };
    $9d27e1baab967744$var$node.hash = "734c65301c902c4873569a6d77b57db1";
    var $9d27e1baab967744$export$2e2bcd8739ae039 = $9d27e1baab967744$var$node;
});
parcelRegister("gWLDR", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$fe56e9af641ec4ca$export$2e2bcd8739ae039);
    const $fe56e9af641ec4ca$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_IssueNavigatorUI_groupResults",
        "selections": [
            {
                "kind": "FragmentSpread",
                "name": "main_issueNavigator_IssueResults_groupResults"
            }
        ],
        "type": "JiraSpreadsheetGroupConnection"
    };
    $fe56e9af641ec4ca$var$node.hash = "f2f55a211a624c01670e60a1355bce53";
    var $fe56e9af641ec4ca$export$2e2bcd8739ae039 = $fe56e9af641ec4ca$var$node;
});
parcelRegister("lxams", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$6d22552440100cae$export$2e2bcd8739ae039);
    const $6d22552440100cae$var$node = {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider"
            }
        ],
        "kind": "Fragment",
        "name": "main_issueNavigator_IssueNavigatorUI_issueResults",
        "selections": [
            {
                "kind": "FragmentSpread",
                "name": "topBar_issueNavigator_issueResults"
            },
            {
                "kind": "FragmentSpread",
                "name": "main_issueNavigator_Header_issueResults"
            },
            {
                "kind": "FragmentSpread",
                "name": "jqlBuilder_issueNavigator_JQLBuilderWrapper_issueResults"
            },
            {
                "kind": "FragmentSpread",
                "name": "main_issueNavigator_IssueResults_issueResults"
            },
            {
                "kind": "FragmentSpread",
                "name": "main_issueNavigator_FullPageIssueApp_issueResults"
            },
            {
                "kind": "LinkedField",
                "name": "issueSearchError",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "name": "__typename"
                    }
                ]
            },
            {
                "kind": "ScalarField",
                "name": "totalIssueSearchResultCount"
            },
            {
                "kind": "ScalarField",
                "name": "isCappingIssueSearchResult"
            },
            {
                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                    {
                        "kind": "FragmentSpread",
                        "name": "bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore"
                    }
                ]
            }
        ],
        "type": "JiraIssueConnection"
    };
    $6d22552440100cae$var$node.hash = "1d67ca3694c49f00f7baba87c2260ba8";
    var $6d22552440100cae$export$2e2bcd8739ae039 = $6d22552440100cae$var$node;
});
parcelRegister("fraBZ", function(module, exports) {
    $parcel$export(module.exports, "AnnouncerV2", ()=>$f73a83ae5cba96ea$export$a43c8a2f4981e0e);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $dqoiX;
    const $f73a83ae5cba96ea$var$NON_BREAKING_SPACE = '\u00A0';
    const $f73a83ae5cba96ea$export$a43c8a2f4981e0e = (0, $5uXOq.memo)(({ message: originalMessage, liveMode: liveMode = 'polite', shouldAnnounce: shouldAnnounce = true, testId: testId })=>{
        const previousMessage = (0, $5uXOq.useRef)('');
        const setAlternatePolite = (0, $5uXOq.useRef)(false);
        const addSuffix = (0, $5uXOq.useRef)(false);
        const [oldMessage, setOldMessage] = (0, $5uXOq.useState)('');
        const [newMessage, setNewMessage] = (0, $5uXOq.useState)('');
        (0, $5uXOq.useEffect)(()=>{
            let message = shouldAnnounce ? originalMessage : $f73a83ae5cba96ea$var$NON_BREAKING_SPACE;
            if (previousMessage.current === message) {
                addSuffix.current = !addSuffix.current;
                if (addSuffix.current) message += $f73a83ae5cba96ea$var$NON_BREAKING_SPACE;
            }
            setOldMessage(setAlternatePolite.current ? '' : message);
            setNewMessage(setAlternatePolite.current ? message : '');
            if (shouldAnnounce) setAlternatePolite.current = !setAlternatePolite.current;
            previousMessage.current = originalMessage;
        }, [
            originalMessage,
            shouldAnnounce
        ]);
        return $5uXOq.createElement("div", {
            "data-testid": testId,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_kqswh2mm"
            ])
        }, $5uXOq.createElement((0, (0, parcelRequire("dqoiX")).ScreenReaderText), {
            role: "status",
            "aria-live": liveMode,
            "aria-relevant": "additions text",
            "aria-atomic": "true"
        }, oldMessage), $5uXOq.createElement((0, (0, parcelRequire("dqoiX")).ScreenReaderText), {
            role: "status",
            "aria-live": liveMode,
            "aria-relevant": "additions text",
            "aria-atomic": "true"
        }, newMessage));
    });
    const $f73a83ae5cba96ea$var$positionRelative = null;
});
parcelRegister("dqoiX", function(module, exports) {
    $parcel$export(module.exports, "visuallyHiddenStyles", ()=>$11827e0613b04108$export$8dd524ceb40e3d16);
    $parcel$export(module.exports, "ScreenReaderText", ()=>$11827e0613b04108$export$88fad6aa294f111c);
    $parcel$export(module.exports, "ScreenReaderCaption", ()=>$11827e0613b04108$export$200c8e5d652aa9af);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    const $11827e0613b04108$export$8dd524ceb40e3d16 = {
        clip: 'rect(1px, 1px, 1px, 1px)',
        clipPath: 'inset(50%)',
        height: '1px',
        width: '1px',
        margin: '-1px',
        overflow: 'hidden',
        padding: 0,
        position: 'absolute'
    };
    const $11827e0613b04108$export$88fad6aa294f111c = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_ogto7mnp _mkrz1wmp _4t3it94y _1bsbt94y _19pk1n1a _2hwx1n1a _otyr1n1a _18u01n1a _1reo15vq _18m915vq _ca0qidpf _u5f3idpf _n3tdidpf _19bvidpf _kqswstnw",
                __cmplp.className
            ])
        });
    });
    const $11827e0613b04108$export$200c8e5d652aa9af = (0, $5uXOq.forwardRef)(({ as: C = "caption", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_ogto7mnp _mkrz1wmp _4t3it94y _1bsbt94y _19pk1n1a _2hwx1n1a _otyr1n1a _18u01n1a _1reo15vq _18m915vq _ca0qidpf _u5f3idpf _n3tdidpf _19bvidpf _kqswstnw",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("fvbIR", function(module, exports) {
    $parcel$export(module.exports, "asyncBulkOpsCoreEntryPoint", ()=>$187b82d9aad4ec6a$export$d90d57132636bb1);
    var $eufEc;
    var $fEDBv;
    const $187b82d9aad4ec6a$export$d90d57132636bb1 = (0, (0, parcelRequire("fEDBv")).createEntryPoint)({
        root: (0, (0, parcelRequire("eufEc")).JSResourceForInteraction)(()=>(parcelRequire("aan64")), {
            moduleId: "f81933b6"
        }),
        getPreloadProps: ()=>({})
    });
});
parcelRegister("aan64", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("hXddo")
    ]).then(()=>parcelRequire('k9wdm'));
});
parcelRegister("5Czte", function(module, exports) {
    $parcel$export(module.exports, "NinChangeboardingContext", ()=>$56f2750f0b6ae1db$export$b349f970aff316d8);
    $parcel$export(module.exports, "EnableNinChangeboardingProvider", ()=>$56f2750f0b6ae1db$export$dd1deacfc61c978a);
    $parcel$export(module.exports, "NinChangeboardingTourTarget", ()=>$56f2750f0b6ae1db$export$eb95770b90ecc1f3);
    $parcel$export(module.exports, "NinChangeboardingTourCard", ()=>$56f2750f0b6ae1db$export$7c27d65549066b5);
    $parcel$export(module.exports, "useGlobalScopeOptInFlag", ()=>$56f2750f0b6ae1db$export$9e9f507adee88e58);
    var $5uXOq = parcelRequire("5uXOq");
    var $6s1PB;
    var $co1wz;
    var $pa9q9;
    var $5Zxxp;
    var $1oOi0;
    var $fB2N8;
    var $6s4Eg;
    var $eiUE8;
    const $56f2750f0b6ae1db$export$b349f970aff316d8 = (0, $5uXOq.createContext)(false);
    const $56f2750f0b6ae1db$export$dd1deacfc61c978a = ({ children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement($56f2750f0b6ae1db$export$b349f970aff316d8.Provider, {
            value: true
        }, children);
    const $56f2750f0b6ae1db$export$eb95770b90ecc1f3 = ({ children: children, engagementId: engagementId })=>{
        const isTourEnabled = (0, $5uXOq.useContext)($56f2750f0b6ae1db$export$b349f970aff316d8);
        if (!(0, (0, parcelRequire("co1wz")).ff)('nin.global-scope_aqec8') || !isTourEnabled) return children;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6s4Eg")).default), {
            engagementId: engagementId
        }, children);
    };
    const $56f2750f0b6ae1db$export$7c27d65549066b5 = ({ mode: mode, view: view, hasIssueResults: hasIssueResults, isAiEnabled: isAiEnabled })=>{
        const isTourEnabled = (0, $5uXOq.useContext)($56f2750f0b6ae1db$export$b349f970aff316d8);
        if (!(0, (0, parcelRequire("co1wz")).ff)('nin.global-scope_aqec8') || !isTourEnabled) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fB2N8")).AsyncSpotlightCard), {
            mode: mode,
            view: view,
            hasIssueResults: hasIssueResults,
            isAiEnabled: isAiEnabled
        });
    };
    const $56f2750f0b6ae1db$export$9e9f507adee88e58 = ()=>{
        const isFlagEnabled = (0, $5uXOq.useContext)($56f2750f0b6ae1db$export$b349f970aff316d8);
        const { showFlag: showFlag } = (0, (0, parcelRequire("pa9q9")).useFlagsService)();
        const onOpenInProductHelpArticle = (0, (0, parcelRequire("5Zxxp")).useHelpPanelLink)();
        const onLearnMore = (0, $5uXOq.useCallback)(()=>{
            if (onOpenInProductHelpArticle !== undefined) onOpenInProductHelpArticle?.((0, (0, parcelRequire("1oOi0")).LEARN_MORE_IPH_ARTICLE_ID));
            else window.open((0, (0, parcelRequire("1oOi0")).FALLBACK_SUPPORT_ARTICLE));
        }, [
            onOpenInProductHelpArticle
        ]);
        (0, $5uXOq.useEffect)(()=>{
            const shouldShowOptInFlag = sessionStorage.getItem('showNinOptInFlag');
            if ((0, (0, parcelRequire("co1wz")).ff)('nin.global-scope_aqec8') && isFlagEnabled && shouldShowOptInFlag === 'true') {
                showFlag({
                    type: 'success',
                    title: (0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("eiUE8")).default).successOptInTitleIssueTermRefresh : (0, (0, parcelRequire("eiUE8")).default).successOptInTitle,
                    description: (0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("eiUE8")).default).successOptInDescIssueTermRefresh : (0, (0, parcelRequire("eiUE8")).default).successOptInDesc,
                    actions: [
                        {
                            content: (0, (0, parcelRequire("eiUE8")).default).successActionLearnMoreLink,
                            onClick: onLearnMore
                        }
                    ],
                    isAutoDismiss: false,
                    testId: 'issue-navigator-changeboarding.optin-success-flag'
                });
                sessionStorage.removeItem('showNinOptInFlag');
            }
        }, []);
    };
});
parcelRegister("5Zxxp", function(module, exports) {
    $parcel$export(module.exports, "useHelpPanelLink", ()=>$b0d1b3c2fea05784$export$dc5c3cc39ce0d138);
    $parcel$export(module.exports, "default", ()=>$b0d1b3c2fea05784$export$2e2bcd8739ae039);
    var $bwpsq;
    var $5uXOq = parcelRequire("5uXOq");
    var $fVnbM;
    var $bHDKL;
    var $e4yMr;
    var $jpY8v;
    var $4R6GH;
    var $6BNJk;
    var $kspz4;
    var $hsmeR;
    const $b0d1b3c2fea05784$export$dc5c3cc39ce0d138 = (resetHistory = false)=>{
        const { openRightSidebar: openRightSidebar } = (0, (0, parcelRequire("hsmeR")).useRightSidebarController)();
        const [, { setNavigationData: setNavigationData }] = (0, (0, parcelRequire("kspz4")).useHelpPanelActions)();
        const handleOnClick = (id, type = 'HELP_ARTICLE')=>{
            openRightSidebar((0, (0, parcelRequire("6BNJk")).HELP_PANEL_ID), (0, (0, parcelRequire("6BNJk")).HELP_PANEL_WIDTH), false, true);
            if (resetHistory) setNavigationData({
                articleId: {
                    id: id,
                    type: (0, (0, parcelRequire("bHDKL")).ARTICLE_TYPE)[type]
                },
                history: []
            });
            else setNavigationData({
                articleId: {
                    id: id,
                    type: (0, (0, parcelRequire("bHDKL")).ARTICLE_TYPE)[type]
                }
            });
        };
        return !(0, (0, parcelRequire("jpY8v")).isIE11)() ? handleOnClick : undefined;
    };
    const $b0d1b3c2fea05784$var$HelpPanelLink = ({ articleId: articleId = '', type: type = 'HELP_ARTICLE', children: children, fallbackComponent: fallbackComponent = null, onClick: onClick, href: href, ...props })=>{
        const onOpenInProductHelpArticle = $b0d1b3c2fea05784$export$dc5c3cc39ce0d138();
        const handleOnClick = (event, analyticsEvent)=>{
            if ((0, (0, parcelRequire("4R6GH")).fg)('help_panel_as_link')) event.preventDefault();
            onClick?.(event, analyticsEvent);
            onOpenInProductHelpArticle?.(articleId, (0, (0, parcelRequire("bHDKL")).ARTICLE_TYPE)[type]);
        };
        if ((0, (0, parcelRequire("4R6GH")).fg)('help_panel_as_link')) return onOpenInProductHelpArticle ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("e4yMr")).default), {
            ...props,
            onClick: handleOnClick,
            href: href ?? 'https://support.atlassian.com',
            appearance: "default",
            testId: props.testId
        }, children) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, fallbackComponent);
        return onOpenInProductHelpArticle ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            ...props,
            onClick: handleOnClick,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                props.appearance === 'link' && "_4bfu18uv _1hms8stv _ajmmnqa1 _9oik18uv _1bnxglyw _jf4cnqa1"
            ])
        }, children) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, fallbackComponent);
    };
    var $b0d1b3c2fea05784$export$2e2bcd8739ae039 = $b0d1b3c2fea05784$var$HelpPanelLink;
    const $b0d1b3c2fea05784$var$buttonWrapperStyles = null;
});
parcelRegister("1oOi0", function(module, exports) {
    $parcel$export(module.exports, "FALLBACK_SUPPORT_ARTICLE", ()=>$43fa307c6841ead5$export$ff66aa7a4971d979);
    $parcel$export(module.exports, "LEARN_MORE_IPH_ARTICLE_ID", ()=>$43fa307c6841ead5$export$88d16e35389ab3c6);
    $parcel$export(module.exports, "getEngagementPlatformlMessageId", ()=>$43fa307c6841ead5$export$91074f0ada4f4533);
    $parcel$export(module.exports, "TOUR_MESSAGE_ID", ()=>$43fa307c6841ead5$export$4ac775bda929f47);
    $parcel$export(module.exports, "views", ()=>$43fa307c6841ead5$export$d0079916fe6800ac);
    const $43fa307c6841ead5$export$565b01733f42b193 = 'https://community.atlassian.com/t5/Jira-articles/We-re-bringing-updates-to-the-issue-navigator/ba-p/2096660';
    const $43fa307c6841ead5$export$ff66aa7a4971d979 = 'https://support.atlassian.com/jira-software-cloud/docs/search-for-issues-in-jira/';
    const $43fa307c6841ead5$export$88d16e35389ab3c6 = '3FGQv6n7fB93yWWTYqY0hq';
    const $43fa307c6841ead5$export$91074f0ada4f4533 = (id)=>`${id}-march2024`;
    const $43fa307c6841ead5$export$4ac775bda929f47 = 'nin-changeboarding-start';
    const $43fa307c6841ead5$export$6c69c3d1b5f6fe55 = {
        basic: 'basic',
        advanced: 'advanced'
    };
    const $43fa307c6841ead5$export$d0079916fe6800ac = {
        list: 'list-view',
        detail: 'split-view'
    };
});
parcelRegister("fB2N8", function(module, exports) {
    $parcel$export(module.exports, "AsyncSpotlightCard", ()=>$a20090c0d2e5eb6a$export$40e85aa59c198f98);
    var $5uXOq = parcelRequire("5uXOq");
    var $39xOx;
    var $lfTZh;
    var $kxZPA;
    const $a20090c0d2e5eb6a$var$SpotlightCard = (0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("62aH7")), {
        ssr: false,
        moduleId: "./src/packages/issue-navigator/changeboarding/src/common/ui/spotlight/spotlight-card/index.tsx"
    });
    const $a20090c0d2e5eb6a$export$40e85aa59c198f98 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            packageName: "jiraIssueNavigatorChangeboarding",
            id: "async-spotlight-card",
            sendToPrivacyUnsafeSplunk: true,
            fallback: "unmount"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "async-spotlight-card",
            fallback: null
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($a20090c0d2e5eb6a$var$SpotlightCard, props)));
});
parcelRegister("62aH7", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("epMgw")
    ]).then(()=>parcelRequire('dqR1S'));
});
parcelRegister("6s4Eg", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$60c5b26aae55f177$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $5Fk4Z;
    var $39xOx;
    var $lfTZh;
    var $kxZPA;
    const $60c5b26aae55f177$var$EngagementSpotlightAsync = (0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("4VxUX")).then(({ EngagementSpotlight: EngagementSpotlight })=>EngagementSpotlight), {
        ssr: false,
        moduleId: "../platform/packages/personalization/engagekit-ts/src/index.ts"
    });
    const $60c5b26aae55f177$var$SpotlightTourTarget = ({ children: children, engagementId: engagementId })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5Fk4Z")).default), {
            name: engagementId
        }, children), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            packageName: "jiraIssueNavigatorChangeboarding",
            id: "async-engagement-spotlight",
            sendToPrivacyUnsafeSplunk: true,
            fallback: "unmount"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "async-engagement-spotlight"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($60c5b26aae55f177$var$EngagementSpotlightAsync, {
            engagementId: engagementId
        }))));
    var $60c5b26aae55f177$export$2e2bcd8739ae039 = $60c5b26aae55f177$var$SpotlightTourTarget;
});
parcelRegister("4VxUX", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("hPzOn"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("ePxTo")
    ]).then(()=>parcelRequire('k4MwF'));
});
parcelRegister("eiUE8", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$172614e7cfb99d43$export$2e2bcd8739ae039);
    var $7VHMR;
    const $172614e7cfb99d43$var$messages = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        successOptInTitle: {
            "id": "issue-navigator-changeboarding.controllers.enable-nin-changeboarding.success-opt-in-title",
            "defaultMessage": "You've switched to the new issue search"
        },
        successOptInDesc: {
            "id": "issue-navigator-changeboarding.controllers.enable-nin-changeboarding.success-opt-in-desc",
            "defaultMessage": "Say hello to a faster, cleaner, and more intuitive experience while searching for issues across Jira."
        },
        successActionLearnMoreLink: {
            "id": "issue-navigator-changeboarding.controllers.enable-nin-changeboarding.success-action-learn-more-link",
            "defaultMessage": "Learn more"
        },
        successOptInTitleIssueTermRefresh: {
            "id": "issue-navigator-changeboarding.controllers.enable-nin-changeboarding.success-opt-in-title-issue-term-refresh",
            "defaultMessage": "You've switched to the new search"
        },
        successOptInDescIssueTermRefresh: {
            "id": "issue-navigator-changeboarding.controllers.enable-nin-changeboarding.success-opt-in-desc-issue-term-refresh",
            "defaultMessage": "Say hello to a faster, cleaner, and more intuitive experience while searching for work across Jira."
        }
    });
    var $172614e7cfb99d43$export$2e2bcd8739ae039 = $172614e7cfb99d43$var$messages;
});
parcelRegister("48rMm", function(module, exports) {
    $parcel$export(module.exports, "IssueTableSelectedIssuesContainer", ()=>$c785af315de8dae0$export$b11112517798dbed);
    $parcel$export(module.exports, "useIssueTableSelectedIssuesActions", ()=>$c785af315de8dae0$export$b4602482fdfd31ed);
    $parcel$export(module.exports, "useIssueTableSelectedIssues", ()=>$c785af315de8dae0$export$ad5f97924b14314b);
    $parcel$export(module.exports, "useIsIssueSelected", ()=>$c785af315de8dae0$export$cfefd69fc8a5ac52);
    $parcel$export(module.exports, "useAreAllIssuesSelected", ()=>$c785af315de8dae0$export$949e47631f4a60a5);
    var $3sQ5x;
    var $gg7kZ;
    var $iXqE5;
    var $es90k;
    const $c785af315de8dae0$export$b11112517798dbed = (0, (0, parcelRequire("gg7kZ")).createContainer)();
    const $c785af315de8dae0$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: (0, parcelRequire("es90k")).initialState,
        actions: (0, parcelRequire("es90k")).actions,
        name: 'jira-native-issue-table-selected-issues-state',
        containedBy: $c785af315de8dae0$export$b11112517798dbed
    });
    const $c785af315de8dae0$export$b4602482fdfd31ed = (0, (0, parcelRequire("3sQ5x")).createActionsHook)($c785af315de8dae0$var$Store);
    const $c785af315de8dae0$var$getSelectedIssueIdsSelector = (state)=>{
        return Array.from(state.selectedIssueIds);
    };
    const $c785af315de8dae0$export$ad5f97924b14314b = (0, (0, parcelRequire("3sQ5x")).createStateHook)($c785af315de8dae0$var$Store, {
        selector: $c785af315de8dae0$var$getSelectedIssueIdsSelector
    });
    const $c785af315de8dae0$var$getIsIssueIdSelectedSelector = (state, issueId)=>{
        return state.selectedIssueIds.has(issueId);
    };
    const $c785af315de8dae0$export$cfefd69fc8a5ac52 = (0, (0, parcelRequire("3sQ5x")).createStateHook)($c785af315de8dae0$var$Store, {
        selector: $c785af315de8dae0$var$getIsIssueIdSelectedSelector
    });
    const $c785af315de8dae0$var$getAreAllIssuesSelectedSelector = (state, issueIds)=>{
        const selectedIssueIds = $c785af315de8dae0$var$getSelectedIssueIdsSelector(state);
        return issueIds.length > 0 && issueIds.every((issueId)=>selectedIssueIds.includes(issueId));
    };
    const $c785af315de8dae0$export$949e47631f4a60a5 = (0, (0, parcelRequire("3sQ5x")).createStateHook)($c785af315de8dae0$var$Store, {
        selector: $c785af315de8dae0$var$getAreAllIssuesSelectedSelector
    });
});
parcelRegister("es90k", function(module, exports) {
    $parcel$export(module.exports, "initialState", ()=>$75d6ff2ec2275c29$export$d4c72bab9d6cc13a);
    $parcel$export(module.exports, "actions", ()=>$75d6ff2ec2275c29$export$e324594224ef24da);
    const $75d6ff2ec2275c29$export$d4c72bab9d6cc13a = {
        selectedIssueIds: new Set([])
    };
    const $75d6ff2ec2275c29$export$e324594224ef24da = {
        insertSelectedIssueIds: (selectedIssueIds)=>({ setState: setState, getState: getState }, { onIssueSelected: onIssueSelected })=>{
                const currentState = getState();
                onIssueSelected?.(selectedIssueIds);
                setState({
                    selectedIssueIds: new Set([
                        ...currentState.selectedIssueIds,
                        ...selectedIssueIds
                    ])
                });
            },
        removeSelectedIssueIds: (selectedIssueIds)=>({ setState: setState, getState: getState })=>{
                const currentState = getState();
                const updatedSelectedIssueIds = new Set(currentState.selectedIssueIds);
                selectedIssueIds.forEach((selectedIssueId)=>{
                    updatedSelectedIssueIds.delete(selectedIssueId);
                });
                setState({
                    selectedIssueIds: updatedSelectedIssueIds
                });
            },
        insertSelectedIssueId: (selectedIssueId)=>({ setState: setState, getState: getState }, { onIssueSelected: onIssueSelected })=>{
                const currentState = getState();
                const updatedSelectedIssueIds = new Set(currentState.selectedIssueIds);
                updatedSelectedIssueIds.add(selectedIssueId);
                onIssueSelected?.([
                    selectedIssueId
                ]);
                setState({
                    selectedIssueIds: updatedSelectedIssueIds
                });
            },
        removeSelectedIssueId: (selectedIssueId)=>({ setState: setState, getState: getState })=>{
                const currentState = getState();
                const updatedSelectedIssueIds = new Set(currentState.selectedIssueIds);
                updatedSelectedIssueIds.delete(selectedIssueId);
                setState({
                    selectedIssueIds: updatedSelectedIssueIds
                });
            },
        reset: ()=>({ setState: setState })=>{
                setState($75d6ff2ec2275c29$export$d4c72bab9d6cc13a);
            }
    };
});
parcelRegister("bSg1p", function(module, exports) {
    $parcel$export(module.exports, "HierarchyOnboardingProvider", ()=>$4708ffe3b7f78317$export$61ea399940cf79d6);
    $parcel$export(module.exports, "useHierarchyOnboardingContext", ()=>$4708ffe3b7f78317$export$34916c588fba606d);
    $parcel$export(module.exports, "HierarchyOnboarding", ()=>$4708ffe3b7f78317$export$19eb9bdb0cf578f5);
    $parcel$export(module.exports, "HierarchyToggleOnboarding", ()=>$4708ffe3b7f78317$export$e350c43a524c2f15);
    var $5uXOq = parcelRequire("5uXOq");
    var $kbqIt;
    var $1QiBP;
    var $ioD1B;
    var $eGLgs;
    var $koVbs;
    var $ehdJY;
    var $koeKL;
    var $2tT7R;
    var $7fpPT;
    const $4708ffe3b7f78317$export$2d29273a16589c02 = 'jira-nin-hierarchy-onboarding-stg';
    const $4708ffe3b7f78317$export$e7deeb52c7c3e7a9 = 'jira-nin-hierarchy-onboarding';
    const $4708ffe3b7f78317$var$ENV_STAGING = 'staging';
    const $4708ffe3b7f78317$var$STARGATE_URL = '/gateway/api';
    const $4708ffe3b7f78317$var$HierarchyOnboardingContext = (0, $5uXOq.createContext)(null);
    const $4708ffe3b7f78317$export$61ea399940cf79d6 = ({ children: children })=>{
        const [showToggleOnboarding, setShowToggleOnboarding] = (0, $5uXOq.useState)(false);
        const value = (0, $5uXOq.useMemo)(()=>({
                showToggleOnboarding: showToggleOnboarding,
                setShowToggleOnboarding: setShowToggleOnboarding
            }), [
            showToggleOnboarding
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($4708ffe3b7f78317$var$HierarchyOnboardingContext.Provider, {
            value: value
        }, children);
    };
    const $4708ffe3b7f78317$export$34916c588fba606d = ()=>{
        const hierarchyContext = (0, $5uXOq.useContext)($4708ffe3b7f78317$var$HierarchyOnboardingContext);
        if (!hierarchyContext) throw new Error('useHierarchyOnboardingContext must be used within a HierarchyOnboardingProvider');
        return hierarchyContext;
    };
    const $4708ffe3b7f78317$export$19eb9bdb0cf578f5 = ()=>{
        const ONBOARDING_ENGAGEMENT_ID = (0, (0, parcelRequire("2tT7R")).useEnvironment)() === $4708ffe3b7f78317$var$ENV_STAGING ? $4708ffe3b7f78317$export$2d29273a16589c02 : $4708ffe3b7f78317$export$e7deeb52c7c3e7a9;
        const { setShowToggleOnboarding: setShowToggleOnboarding } = $4708ffe3b7f78317$export$34916c588fba606d();
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const coordinationClient = (0, $5uXOq.useMemo)(()=>new (0, (0, parcelRequire("eGLgs")).CoordinationClient)(cloudId, $4708ffe3b7f78317$var$STARGATE_URL, false, '', 'engagement'), [
            cloudId
        ]);
        const [isSpotlightActive, setIsSpotlightActive] = (0, $5uXOq.useState)(false);
        const [isEnrolled, stopEnrolment] = (0, (0, parcelRequire("kbqIt")).useCoordination)(coordinationClient, ONBOARDING_ENGAGEMENT_ID);
        (0, $5uXOq.useEffect)(()=>{
            if (isEnrolled) {
                stopEnrolment(false);
                setIsSpotlightActive(true);
            }
        }, [
            isEnrolled,
            stopEnrolment
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ioD1B")).ChoreographerContextProvider), {
            isChoreographed: coordinationClient.enableChoreographer
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1QiBP")).default), null, isEnrolled && isSpotlightActive && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ehdJY")).JiraSpotlight), {
            messageId: "native-issue-table.ui.hierarchy-onboarding.jira-spotlight.expand-column-header",
            messageType: "engagement",
            actionsBeforeElement: "1/2",
            actions: [
                {
                    onClick: ()=>{
                        setIsSpotlightActive(false);
                        setShowToggleOnboarding(true);
                    },
                    text: formatMessage((0, (0, parcelRequire("7fpPT")).default).next)
                },
                {
                    appearance: 'subtle',
                    onClick: ()=>setIsSpotlightActive(false),
                    text: formatMessage((0, (0, parcelRequire("7fpPT")).default).dismiss)
                }
            ],
            dialogWidth: 320,
            heading: formatMessage((0, (0, parcelRequire("7fpPT")).default).onboardingHeading),
            target: "expand-column-header",
            key: "expand-column-header",
            targetRadius: 3
        }, formatMessage((0, (0, parcelRequire("7fpPT")).default).onboardingDescription))));
    };
    const $4708ffe3b7f78317$export$e350c43a524c2f15 = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { showToggleOnboarding: showToggleOnboarding, setShowToggleOnboarding: setShowToggleOnboarding } = $4708ffe3b7f78317$export$34916c588fba606d();
        const [isSpotlightActive, setIsSpotlightActive] = (0, $5uXOq.useState)(true);
        if (!isSpotlightActive || !showToggleOnboarding) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1QiBP")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ehdJY")).JiraSpotlight), {
            messageId: "native-issue-table.ui.hierarchy-onboarding.jira-spotlight.hierarchy-feature-toggle",
            messageType: "engagement",
            actionsBeforeElement: "2/2",
            actions: [
                {
                    onClick: ()=>{
                        setShowToggleOnboarding(false);
                        setIsSpotlightActive(false);
                    },
                    text: formatMessage((0, (0, parcelRequire("7fpPT")).default).ok)
                }
            ],
            dialogWidth: 320,
            target: "hierarchy-feature-toggle",
            key: "hierarchy-feature-toggle",
            targetRadius: 3
        }, formatMessage((0, (0, parcelRequire("7fpPT")).default).toggleOnboarding)));
    };
});
parcelRegister("ioD1B", function(module, exports) {
    $parcel$export(module.exports, "ChoreographerContextProvider", ()=>$c141e5ba3428702a$export$c3226151caaf2fbf);
    var $5uXOq = parcelRequire("5uXOq");
    var $fG5AJ;
    const $c141e5ba3428702a$var$choreographedStatusContextValue = {
        isAlreadyChoreographedAtAncestor: true
    };
    const $c141e5ba3428702a$export$c3226151caaf2fbf = ({ isChoreographed: isChoreographed, children: children })=>{
        const { isAlreadyChoreographedAtAncestor: isAlreadyChoreographedAtAncestor } = (0, (0, parcelRequire("fG5AJ")).useChoreographedStatusContext)();
        const shouldUseChoreographerContextProvider = !isAlreadyChoreographedAtAncestor && isChoreographed;
        return shouldUseChoreographerContextProvider ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fG5AJ")).ChoreographedStatusContext).Provider, {
            value: $c141e5ba3428702a$var$choreographedStatusContextValue
        }, children) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children);
    };
});
parcelRegister("eGLgs", function(module, exports) {
    $parcel$export(module.exports, "CoordinationClient", ()=>$754c27d37ccd1133$export$580a2294c917898c);
    $parcel$export(module.exports, "default", ()=>$754c27d37ccd1133$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $iZhen;
    var $jcYbC;
    var $8Mq6c;
    var $TTRxY;
    var $ioD1B;
    var $fG5AJ;
    var $10xFf;
    var $4Pf62;
    var $4R6GH;
    var $j8DrX;
    var $14XJw;
    var $7qPn9;
    const $754c27d37ccd1133$var$isSyntheticTenant = ()=>(0, (0, parcelRequire("j8DrX")).default)('ajs-is-synthetic') === 'true';
    class $754c27d37ccd1133$export$580a2294c917898c extends (0, (0, parcelRequire("8Mq6c")).default) {
        constructor(cloudId, stargateUrl, shouldAllowOnSynthetic = false, orgId = '', messageType = 'transactional', onMessageDisposition, isAlreadyChoreographedAtAncestor = false){
            const environment = (0, (0, parcelRequire("7qPn9")).toEnvironment)((0, (0, parcelRequire("j8DrX")).default)('ajs-environment'));
            const getEnvironmentType = (env)=>{
                if (env === 'prod') return (0, (0, parcelRequire("iZhen")).envType).PROD;
                if (env === 'staging') return (0, (0, parcelRequire("iZhen")).envType).STAGING;
                return (0, (0, parcelRequire("iZhen")).envType).DEV;
            };
            const enableChoreographer = (0, (0, parcelRequire("10xFf")).shouldBeChoreographed)({
                messageType: messageType
            }) && !isAlreadyChoreographedAtAncestor;
            const coordinationOptions = {
                enableChoreographer: enableChoreographer,
                envType: getEnvironmentType(environment),
                additionalData: {
                    sourceProductId: (0, (0, parcelRequire("TTRxY")).ProductIds).JIRA
                }
            };
            super({
                cloudId: cloudId,
                stargateUrl: stargateUrl,
                orgId: orgId,
                isFedRAMPEnvironment: false,
                coordinationOptions: coordinationOptions
            });
            this.shouldUseMockClient = $754c27d37ccd1133$var$isSyntheticTenant() && !shouldAllowOnSynthetic;
            this.enableChoreographer = enableChoreographer;
            this.onMessageDisposition = onMessageDisposition;
            this.isDebugModeEnabled = (0, (0, parcelRequire("4R6GH")).fg)('ep-external-message-debug-mode');
        }
        async start(messageId) {
            if (this.isDebugModeEnabled) {
                const serverResponse = await super.start(messageId);
                if (this.enableChoreographer) this.onMessageDisposition?.(serverResponse ? (0, (0, parcelRequire("TTRxY")).MessageDeliveryStatus).STARTED : (0, (0, parcelRequire("TTRxY")).MessageDeliveryStatus).BLOCKED);
                const shouldShowMessage = confirm(`\u{1F41E} \u{1D5D8}\u{1D5E3} \u{1D5D8}\u{1D605}\u{1D601}\u{1D5F2}\u{1D5FF}\u{1D5FB}\u{1D5EE}\u{1D5F9} \u{1D5E0}\u{1D5F2}\u{1D600}\u{1D600}\u{1D5EE}\u{1D5F4}\u{1D5F2} \u{1D5D7}\u{1D5F2}\u{1D5EF}\u{1D602}\u{1D5F4}\u{1D5F4}\u{1D5F2}\u{1D5FF}

Message ID: ${messageId}
Start call server response: ${serverResponse}

Do you want to display the message?`);
                if (!shouldShowMessage) {
                    const didStop = await super.stop(messageId);
                    if (this.enableChoreographer) this.onMessageDisposition?.(didStop ? (0, (0, parcelRequire("TTRxY")).MessageDeliveryStatus).STOPPED : (0, (0, parcelRequire("TTRxY")).MessageDeliveryStatus).BLOCKED);
                }
                return shouldShowMessage;
            }
            return this.shouldUseMockClient ? false : super.start(messageId).then((didStart)=>{
                if (this.enableChoreographer) this.onMessageDisposition?.(didStart ? (0, (0, parcelRequire("TTRxY")).MessageDeliveryStatus).STARTED : (0, (0, parcelRequire("TTRxY")).MessageDeliveryStatus).BLOCKED);
                return didStart;
            });
        }
        async stop(messageId) {
            return this.shouldUseMockClient ? true : super.stop(messageId).then((didStop)=>{
                if (this.enableChoreographer) this.onMessageDisposition?.(didStop ? (0, (0, parcelRequire("TTRxY")).MessageDeliveryStatus).STOPPED : (0, (0, parcelRequire("TTRxY")).MessageDeliveryStatus).BLOCKED);
                return didStop;
            });
        }
    }
    function $754c27d37ccd1133$export$2e2bcd8739ae039(props) {
        const { cloudId: cloudId } = (0, (0, parcelRequire("4Pf62")).getTenantContext_DEPRECATED_DO_NOT_USE)();
        const stargateUrl = '/gateway/api';
        const { data: orgId } = (0, (0, parcelRequire("14XJw")).useOrgId)();
        const { isAlreadyChoreographedAtAncestor: isAlreadyChoreographedAtAncestor } = (0, (0, parcelRequire("fG5AJ")).useChoreographedStatusContext)();
        const client = new $754c27d37ccd1133$export$580a2294c917898c(cloudId, stargateUrl, props.shouldAllowOnSynthetic, orgId, props.messageType, props.onMessageDisposition, isAlreadyChoreographedAtAncestor);
        const { fallback: fallback, messageId: messageId } = props;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ioD1B")).ChoreographerContextProvider), {
            isChoreographed: client.enableChoreographer
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jcYbC")).default), {
            client: client,
            fallback: fallback,
            messageId: messageId
        }, props.children));
    }
});
parcelRegister("7fpPT", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$6c397747d610703a$export$2e2bcd8739ae039);
    var $7VHMR;
    var $6c397747d610703a$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        onboardingHeading: {
            "id": "business-conditional-formatting-sidebar.format-rules-sidebar.sidebar.onboarding.onboarding-heading",
            "defaultMessage": "View issue hierarchy"
        },
        onboardingDescription: {
            "id": "business-conditional-formatting-sidebar.format-rules-sidebar.sidebar.onboarding.onboarding-description",
            "defaultMessage": "Collapse or expand the hierarchy to see parent and child issues."
        },
        next: {
            "id": "business-conditional-formatting-sidebar.format-rules-sidebar.sidebar.onboarding.next",
            "defaultMessage": "Next"
        },
        toggleOnboarding: {
            "id": "business-conditional-formatting-sidebar.format-rules-sidebar.sidebar.onboarding.rules-order-onboarding",
            "defaultMessage": "Use the toggle to show or hide the issue hierarchy."
        },
        ok: {
            "id": "business-conditional-formatting-sidebar.format-rules-sidebar.sidebar.onboarding.done",
            "defaultMessage": "Ok"
        },
        dismiss: {
            "id": "business-conditional-formatting-sidebar.format-rules-sidebar.sidebar.onboarding.dismiss",
            "defaultMessage": "Dismiss"
        }
    });
});
parcelRegister("bbbXL", function(module, exports) {
    $parcel$export(module.exports, "NoFilter", ()=>$4564153678e1805d$export$fba2cdeecf4e314d);
    var $5uXOq = parcelRequire("5uXOq");
    var $fVnbM;
    var $hTEpF;
    var $dC5iT;
    var $8CUq3;
    var $6s1PB;
    var $c7i3D;
    var $koVbs;
    var $iXVV6;
    var $5oFIy;
    var $6YP2a;
    const $4564153678e1805d$var$ANOTHER_FILTER_LINK = '/jira/filters';
    const $4564153678e1805d$export$fba2cdeecf4e314d = ({ reason: reason })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            sourceName: "noFilter",
            sourceType: (0, $4d5e0871c06cee03$export$f636b5b56f1d624a)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireScreenAnalytics), {
            attributes: reason ? {
                reason: reason
            } : undefined
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hTEpF")).default), {
            header: formatMessage((0, (0, parcelRequire("6YP2a")).default).weCouldntFindThisFilter),
            description: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement("p", null, formatMessage((0, (0, parcelRequire("6YP2a")).default).filterNoExistOrNoPermissions)), (0, ($parcel$interopDefault($5uXOq))).createElement("p", null, formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("6YP2a")).default).tryAnotherFilterIssueTermRefresh : (0, (0, parcelRequire("6YP2a")).default).tryAnotherFilter))),
            secondaryAction: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                href: $4564153678e1805d$var$ANOTHER_FILTER_LINK
            }, formatMessage((0, (0, parcelRequire("6YP2a")).default).viewFilters)),
            primaryAction: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                appearance: "primary",
                href: window.location.pathname
            }, formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("6YP2a")).default).searchIssuesIssueTermRefresh : (0, (0, parcelRequire("6YP2a")).default).searchIssues)),
            renderImage: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
                    xcss: $4564153678e1805d$var$ImageWrapperStyles
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("c7i3D")).default), {
                    alt: ""
                }))
        }));
    };
    var $4564153678e1805d$export$2e2bcd8739ae039 = $4564153678e1805d$export$fba2cdeecf4e314d;
    const $4564153678e1805d$var$ImageWrapperStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        maxHeight: '146px',
        maxWidth: '160px',
        margin: 'auto',
        marginBottom: 'space.300'
    });
});
parcelRegister("c7i3D", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$e6eff2dc5f73fdbc$export$2e2bcd8739ae039);
    $parcel$export(module.exports, "srcLight", ()=>($parcel$interopDefault((parcelRequire("5BNLu")))));
    var $dCLgq;
    var $f3FKt = parcelRequire("f3FKt");
    var $5BNLu = parcelRequire("5BNLu");
    const $e6eff2dc5f73fdbc$var$Illustration = (0, (0, parcelRequire("dCLgq")).createIllustration)({
        srcLight: ($parcel$interopDefault($5BNLu)),
        srcDark: ($parcel$interopDefault($f3FKt))
    });
    var $e6eff2dc5f73fdbc$export$2e2bcd8739ae039 = $e6eff2dc5f73fdbc$var$Illustration;
    const $e6eff2dc5f73fdbc$export$a179c88182cb9d56 = [
        0,
        0,
        208,
        190
    ];
});
parcelRegister("f3FKt", function(module, exports) {
    module.exports = new URL("search-no-results-darkmode.41158025.svg", import.meta.url).toString();
});
parcelRegister("5BNLu", function(module, exports) {
    module.exports = new URL("search-no-results.10b14394.svg", import.meta.url).toString();
});
parcelRegister("6YP2a", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$3fe733e75fd165d2$export$2e2bcd8739ae039);
    var $7VHMR;
    var $3fe733e75fd165d2$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        weCouldntFindThisFilter: {
            "id": "issue-navigator.common.no-filter.we-couldnt-find-this-filter",
            "defaultMessage": "We couldn't find this filter"
        },
        filterNoExistOrNoPermissions: {
            "id": "issue-navigator.common.no-filter.filter-no-exist-or-no-permissions",
            "defaultMessage": "This filter may not exist, or you don\u2019t have permissions to view it."
        },
        tryAnotherFilter: {
            "id": "issue-navigator.common.no-filter.try-another-filter",
            "defaultMessage": "Try searching for another filter, or start a new issue search."
        },
        viewFilters: {
            "id": "issue-navigator.common.no-filter.view-filters",
            "defaultMessage": "View filters"
        },
        searchIssues: {
            "id": "issue-navigator.common.no-filter.search-issues",
            "defaultMessage": "Search issues"
        },
        tryAnotherFilterIssueTermRefresh: {
            "id": "issue-navigator.common.no-filter.try-another-filter-issue-term-refresh",
            "defaultMessage": "Try searching for another filter, or start a new search."
        },
        searchIssuesIssueTermRefresh: {
            "id": "issue-navigator.common.no-filter.search-issues-issue-term-refresh",
            "defaultMessage": "Search work items"
        }
    });
});
parcelRegister("kMfdg", function(module, exports) {
    $parcel$export(module.exports, "IssueSearchSuccess", ()=>$b2794e71f9164ec7$export$3ee6d2830e5ead03);
    $parcel$export(module.exports, "IssueSearchFail", ()=>$b2794e71f9164ec7$export$91886a55521e4fad);
    var $5uXOq = parcelRequire("5uXOq");
    var $6s1PB;
    var $1XJ20;
    var $1GlUV;
    var $isIOR;
    var $38r5c;
    var $ih9zY;
    const $b2794e71f9164ec7$export$3ee6d2830e5ead03 = ({ attributes: attributes, onPageDataLoad: onPageDataLoad })=>{
        const [{ view: view }] = (0, (0, parcelRequire("ih9zY")).useSelectedViewState)();
        const { onIssueSearchSuccess: onIssueSearchSuccess } = (0, (0, parcelRequire("1GlUV")).useExperienceAnalytics)(onPageDataLoad, view, attributes);
        let isFetching;
        let isNetworkError;
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
            isFetching = (0, (0, parcelRequire("38r5c")).useIsFetching)();
            isNetworkError = (0, (0, parcelRequire("38r5c")).useIsNetworkError)();
        } else ({ isFetching: isFetching, isNetworkError: isNetworkError } = (0, (0, parcelRequire("isIOR")).useIssueSearchQuery)());
        const prevIsFetching = (0, (0, parcelRequire("1XJ20")).usePrevious)(isFetching);
        (0, $5uXOq.useEffect)(()=>{
            if (prevIsFetching !== isFetching && !isFetching && !isNetworkError) onIssueSearchSuccess(view);
        }, [
            isFetching,
            isNetworkError,
            onIssueSearchSuccess,
            prevIsFetching,
            view
        ]);
        return null;
    };
    const $b2794e71f9164ec7$export$91886a55521e4fad = ({ attributes: attributes, location: location, error: error, onPageDataLoad: onPageDataLoad })=>{
        const [{ view: view }] = (0, (0, parcelRequire("ih9zY")).useSelectedViewState)();
        const { onIssueSearchFail: onIssueSearchFail } = (0, (0, parcelRequire("1GlUV")).useExperienceAnalytics)(onPageDataLoad, view, attributes);
        let isFetching;
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) isFetching = (0, (0, parcelRequire("38r5c")).useIsFetching)();
        else ({ isFetching: isFetching } = (0, (0, parcelRequire("isIOR")).useIssueSearchQuery)());
        const prevIsFetching = (0, (0, parcelRequire("1XJ20")).usePrevious)(isFetching);
        (0, $5uXOq.useEffect)(()=>{
            if (prevIsFetching !== isFetching && !isFetching) onIssueSearchFail(location, error, view);
        }, [
            error,
            isFetching,
            location,
            onIssueSearchFail,
            prevIsFetching,
            view
        ]);
        return null;
    };
});
parcelRegister("38r5c", function(module, exports) {
    $parcel$export(module.exports, "useSearchKey", ()=>$e1f660d76cb8d0f0$export$89b7d8d2a901e7f3);
    $parcel$export(module.exports, "useIsFetching", ()=>$e1f660d76cb8d0f0$export$625b2ff71316bcd3);
    $parcel$export(module.exports, "useIsFetchingView", ()=>$e1f660d76cb8d0f0$export$bb4d69b04e77d5fc);
    $parcel$export(module.exports, "useIsNetworkError", ()=>$e1f660d76cb8d0f0$export$e42540e6c753cf0d);
    $parcel$export(module.exports, "useIsRefreshing", ()=>$e1f660d76cb8d0f0$export$f0abaf46a1fbe05d);
    $parcel$export(module.exports, "useTotalIssueCount", ()=>$e1f660d76cb8d0f0$export$2913c5459976c260);
    $parcel$export(module.exports, "useTotalIssueCountIsFetching", ()=>$e1f660d76cb8d0f0$export$597fd4c2a21a6427);
    $parcel$export(module.exports, "useTotalIssueCountIsError", ()=>$e1f660d76cb8d0f0$export$8ca94746675b8a5f);
    $parcel$export(module.exports, "useIssueSearchActions", ()=>$e1f660d76cb8d0f0$export$77a801a298598c57);
    $parcel$export(module.exports, "useInfiniteScroll", ()=>$e1f660d76cb8d0f0$export$a973cef5093a8d22);
    var $5uXOq = parcelRequire("5uXOq");
    var $3sQ5x;
    var $6UAdF;
    var $1PJ7c;
    const $e1f660d76cb8d0f0$var$INFINITE_SCROLL_DEFAULT_OPTIONS = {
        pageSize: (0, (0, parcelRequire("6UAdF")).MAX_ISSUES_PER_PAGE)
    };
    const $e1f660d76cb8d0f0$export$89b7d8d2a901e7f3 = (0, (0, parcelRequire("3sQ5x")).createStateHook)((0, (0, parcelRequire("1PJ7c")).Store), {
        selector: (state)=>state.searchKey
    });
    const $e1f660d76cb8d0f0$export$625b2ff71316bcd3 = (0, (0, parcelRequire("3sQ5x")).createStateHook)((0, (0, parcelRequire("1PJ7c")).Store), {
        selector: (state)=>state.isFetching
    });
    const $e1f660d76cb8d0f0$export$bb4d69b04e77d5fc = (0, (0, parcelRequire("3sQ5x")).createStateHook)((0, (0, parcelRequire("1PJ7c")).Store), {
        selector: (state)=>state.isFetchingView
    });
    const $e1f660d76cb8d0f0$export$e42540e6c753cf0d = (0, (0, parcelRequire("3sQ5x")).createStateHook)((0, (0, parcelRequire("1PJ7c")).Store), {
        selector: (state)=>state.isNetworkError
    });
    const $e1f660d76cb8d0f0$export$f0abaf46a1fbe05d = (0, (0, parcelRequire("3sQ5x")).createStateHook)((0, (0, parcelRequire("1PJ7c")).Store), {
        selector: (state)=>state.isRefreshing
    });
    const $e1f660d76cb8d0f0$export$2913c5459976c260 = (0, (0, parcelRequire("3sQ5x")).createStateHook)((0, (0, parcelRequire("1PJ7c")).Store), {
        selector: (state)=>state.totalIssueCount
    });
    const $e1f660d76cb8d0f0$export$597fd4c2a21a6427 = (0, (0, parcelRequire("3sQ5x")).createStateHook)((0, (0, parcelRequire("1PJ7c")).Store), {
        selector: (state)=>state.totalIssueCountIsFetching
    });
    const $e1f660d76cb8d0f0$export$8ca94746675b8a5f = (0, (0, parcelRequire("3sQ5x")).createStateHook)((0, (0, parcelRequire("1PJ7c")).Store), {
        selector: (state)=>state.totalIssueCountIsError
    });
    const $e1f660d76cb8d0f0$export$77a801a298598c57 = (0, (0, parcelRequire("3sQ5x")).createActionsHook)((0, (0, parcelRequire("1PJ7c")).Store));
    const $e1f660d76cb8d0f0$var$useInfiniteScrollProps = (0, (0, parcelRequire("3sQ5x")).createStateHook)((0, (0, parcelRequire("1PJ7c")).Store), {
        selector: ({ onLoadNext: onLoadNext, onLoadPrevious: onLoadPrevious, isLoadingNext: isLoadingNext, isLoadingPrevious: isLoadingPrevious, hasNext: hasNext, hasPrevious: hasPrevious })=>({
                onLoadNext: onLoadNext,
                onLoadPrevious: onLoadPrevious,
                isLoadingNext: isLoadingNext,
                isLoadingPrevious: isLoadingPrevious,
                hasNext: hasNext,
                hasPrevious: hasPrevious
            })
    });
    function $e1f660d76cb8d0f0$export$a973cef5093a8d22(options) {
        const { pageSize: pageSize, threshold: threshold } = {
            ...$e1f660d76cb8d0f0$var$INFINITE_SCROLL_DEFAULT_OPTIONS,
            ...options
        };
        const { onLoadNext: onLoadNextProp, onLoadPrevious: onLoadPreviousProp, ...rest } = $e1f660d76cb8d0f0$var$useInfiniteScrollProps();
        const onLoadNext = (0, $5uXOq.useCallback)(()=>onLoadNextProp(pageSize), [
            onLoadNextProp,
            pageSize
        ]);
        const onLoadPrevious = (0, $5uXOq.useCallback)(()=>onLoadPreviousProp(pageSize), [
            onLoadPreviousProp,
            pageSize
        ]);
        const infiniteScrollProps = (0, $5uXOq.useMemo)(()=>({
                onLoadNext: onLoadNext,
                onLoadPrevious: onLoadPrevious,
                activationThreshold: threshold,
                ...rest
            }), [
            onLoadNext,
            onLoadPrevious,
            threshold,
            rest
        ]);
        return infiniteScrollProps;
    }
});
parcelRegister("5NwJk", function(module, exports) {
    $parcel$export(module.exports, "useSelectedIssueKey", ()=>$0bb54236b3bd8bd1$export$7cedcbfb2da7e49d);
    $parcel$export(module.exports, "useSelectedIssueIndex", ()=>$0bb54236b3bd8bd1$export$a4b375f8f9b094ad);
    $parcel$export(module.exports, "useSelectedIssueDebouncedState", ()=>$0bb54236b3bd8bd1$export$ed5cb04ac9d88e95);
    $parcel$export(module.exports, "useIsFullPageIssueAppMode", ()=>$0bb54236b3bd8bd1$export$988cbddd89e853c0);
    var $6s1PB;
    var $gtUFk;
    var $7OTah;
    const $0bb54236b3bd8bd1$export$7cedcbfb2da7e49d = ()=>(0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false) ? (0, (0, parcelRequire("7OTah")).useSelectedIssueKey)() : (0, (0, parcelRequire("gtUFk")).useSelectedIssueKey)();
    const $0bb54236b3bd8bd1$export$a4b375f8f9b094ad = ()=>(0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false) ? (0, (0, parcelRequire("7OTah")).useSelectedIssueIndex)() : (0, (0, parcelRequire("gtUFk")).useSelectedIssueIndex)();
    const $0bb54236b3bd8bd1$export$ed5cb04ac9d88e95 = ()=>(0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false) ? (0, (0, parcelRequire("7OTah")).useSelectedIssueDebouncedState)() : (0, (0, parcelRequire("gtUFk")).useSelectedIssueDebouncedState)();
    const $0bb54236b3bd8bd1$export$988cbddd89e853c0 = ()=>(0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false) ? (0, (0, parcelRequire("7OTah")).useIsFullPageIssueAppMode)() : (0, (0, parcelRequire("gtUFk")).useIsFullPageIssueAppMode)();
});
parcelRegister("7OTah", function(module, exports) {
    $parcel$export(module.exports, "useSelectedIssueIndex", ()=>$45ced29c2f763b85$export$a4b375f8f9b094ad);
    $parcel$export(module.exports, "useNextIssueKey", ()=>$45ced29c2f763b85$export$a357f91232534409);
    $parcel$export(module.exports, "usePreviousIssueKey", ()=>$45ced29c2f763b85$export$ee05f65713c12ae3);
    $parcel$export(module.exports, "useSelectedIssueKey", ()=>$45ced29c2f763b85$export$7cedcbfb2da7e49d);
    $parcel$export(module.exports, "useIsSelectedIssue", ()=>$45ced29c2f763b85$export$ccd07516e284d793);
    $parcel$export(module.exports, "useSelectedIssueDebouncedState", ()=>$45ced29c2f763b85$export$ed5cb04ac9d88e95);
    $parcel$export(module.exports, "useIsFullPageIssueAppMode", ()=>$45ced29c2f763b85$export$988cbddd89e853c0);
    $parcel$export(module.exports, "useSelectedIssueActions", ()=>$45ced29c2f763b85$export$d1c4608e18de4f02);
    var $3sQ5x;
    var $eJ0Zs;
    var $hnJJ3;
    var $gz4II;
    const $45ced29c2f763b85$export$a4b375f8f9b094ad = (0, (0, parcelRequire("3sQ5x")).createStateHook)((0, (0, parcelRequire("hnJJ3")).Store), {
        selector: (0, (0, parcelRequire("gz4II")).getSelectedIssueIndex)
    });
    const $45ced29c2f763b85$export$a357f91232534409 = (0, (0, parcelRequire("3sQ5x")).createStateHook)((0, (0, parcelRequire("hnJJ3")).Store), {
        selector: (0, (0, parcelRequire("gz4II")).getNextIssueKey)
    });
    const $45ced29c2f763b85$export$ee05f65713c12ae3 = (0, (0, parcelRequire("3sQ5x")).createStateHook)((0, (0, parcelRequire("hnJJ3")).Store), {
        selector: (0, (0, parcelRequire("gz4II")).getPreviousIssueKey)
    });
    const $45ced29c2f763b85$export$7cedcbfb2da7e49d = (0, (0, parcelRequire("3sQ5x")).createStateHook)((0, (0, parcelRequire("hnJJ3")).Store), {
        selector: ({ selectedIssueKey: selectedIssueKey })=>selectedIssueKey
    });
    const $45ced29c2f763b85$export$ccd07516e284d793 = (0, (0, parcelRequire("3sQ5x")).createStateHook)((0, (0, parcelRequire("hnJJ3")).Store), {
        selector: ({ selectedIssueKey: selectedIssueKey }, issueKey)=>selectedIssueKey === issueKey
    });
    const $45ced29c2f763b85$export$ed5cb04ac9d88e95 = (0, (0, parcelRequire("3sQ5x")).createStateHook)((0, (0, parcelRequire("hnJJ3")).Store), {
        selector: (0, (0, parcelRequire("eJ0Zs")).createRememoizeSelector)((state)=>state, ({ selectedIssueKey: selectedIssueKey })=>selectedIssueKey)
    });
    const $45ced29c2f763b85$export$988cbddd89e853c0 = (0, (0, parcelRequire("3sQ5x")).createStateHook)((0, (0, parcelRequire("hnJJ3")).Store), {
        selector: ({ isFullPageIssueAppMode: isFullPageIssueAppMode })=>isFullPageIssueAppMode
    });
    const $45ced29c2f763b85$export$d1c4608e18de4f02 = (0, (0, parcelRequire("3sQ5x")).createActionsHook)((0, (0, parcelRequire("hnJJ3")).Store));
});
parcelRegister("hnJJ3", function(module, exports) {
    $parcel$export(module.exports, "SelectedIssueContainer", ()=>$2e98f8dbaf47ae14$export$8b7bb55917259a5d);
    $parcel$export(module.exports, "Store", ()=>$2e98f8dbaf47ae14$export$390f32400eaf98c9);
    var $g5X3G;
    var $gg7kZ;
    var $iXqE5;
    var $6UAdF;
    var $kyTMp;
    const $2e98f8dbaf47ae14$export$8b7bb55917259a5d = (0, (0, parcelRequire("gg7kZ")).createContainer)();
    const $2e98f8dbaf47ae14$var$initialState = {
        _rememoize: Date.now(),
        selectedIssueKey: (0, (0, parcelRequire("g5X3G")).toIssueKey)(''),
        isFullPageIssueAppMode: false,
        issueKeys: []
    };
    const $2e98f8dbaf47ae14$export$390f32400eaf98c9 = (0, (0, parcelRequire("iXqE5")).createStore)({
        containedBy: $2e98f8dbaf47ae14$export$8b7bb55917259a5d,
        initialState: $2e98f8dbaf47ae14$var$initialState,
        actions: (0, parcelRequire("kyTMp")).default,
        handlers: {
            onInit: ()=>({ setState: setState, dispatch: dispatch }, { selectedIssueKey: selectedIssueKey, view: view, issueKeys: issueKeys })=>{
                    dispatch((0, (0, parcelRequire("kyTMp")).privateActions).onContainerUpdate());
                    const isFullPageIssueAppMode = Boolean(selectedIssueKey) && view === (0, (0, parcelRequire("6UAdF")).views).list;
                    const initialIssueKey = selectedIssueKey || issueKeys[0] || (0, (0, parcelRequire("g5X3G")).toIssueKey)('');
                    setState({
                        _rememoize: Date.now(),
                        selectedIssueKey: initialIssueKey,
                        isFullPageIssueAppMode: isFullPageIssueAppMode
                    });
                },
            onContainerUpdate: ({ searchKey: searchKey, selectedIssueKey: selectedIssueKey }, prevProps)=>({ dispatch: dispatch })=>{
                    dispatch((0, (0, parcelRequire("kyTMp")).privateActions).onContainerUpdate());
                    if (searchKey !== prevProps.searchKey) dispatch((0, (0, parcelRequire("kyTMp")).privateActions).resetSelectedIssue());
                    else if (selectedIssueKey && selectedIssueKey !== prevProps.selectedIssueKey) {
                        dispatch($2e98f8dbaf47ae14$export$390f32400eaf98c9.actions.setSelectedIssueByKey(selectedIssueKey, {
                            shouldDebounce: false,
                            shouldNotifyOnChange: false,
                            isSelectedByUserInteraction: false
                        }));
                        if (dispatch((0, (0, parcelRequire("kyTMp")).privateActions).isListViewWithoutIssueApp())) dispatch($2e98f8dbaf47ae14$export$390f32400eaf98c9.actions.enterFullPageIssueAppMode());
                    } else if (!selectedIssueKey && dispatch((0, (0, parcelRequire("kyTMp")).privateActions).isListViewWithIssueAppAndKey())) dispatch($2e98f8dbaf47ae14$export$390f32400eaf98c9.actions.exitFullPageIssueAppMode());
                }
        }
    });
});
parcelRegister("kyTMp", function(module, exports) {
    $parcel$export(module.exports, "privateActions", ()=>$ecb0ae8c94bab407$export$3f1be494d407b77a);
    $parcel$export(module.exports, "default", ()=>$ecb0ae8c94bab407$export$2e2bcd8739ae039);
    var $lFKmC = parcelRequire("lFKmC");
    var $g5X3G;
    var $6UAdF;
    var $gz4II;
    const $ecb0ae8c94bab407$var$doSideEffectUpdate = ({ getState: getState, setState: setState }, { onChange: onChange, shouldNotifyOnChange: shouldNotifyOnChange = true, isSelectedByUserInteraction: isSelectedByUserInteraction = true })=>{
        const { selectedIssueKey: selectedIssueKey } = getState();
        setState({
            _rememoize: Date.now()
        });
        shouldNotifyOnChange && onChange && onChange(selectedIssueKey, isSelectedByUserInteraction);
    };
    const $ecb0ae8c94bab407$var$doDebouncedSideEffectUpdate = (0, ($parcel$interopDefault($lFKmC)))($ecb0ae8c94bab407$var$doSideEffectUpdate, 600);
    const $ecb0ae8c94bab407$var$doSideEffectUpdates = (actions, options)=>{
        options.shouldDebounce ? $ecb0ae8c94bab407$var$doDebouncedSideEffectUpdate(actions, options) : $ecb0ae8c94bab407$var$doSideEffectUpdate(actions, options);
    };
    const $ecb0ae8c94bab407$var$setSelectedIssueByKey = (selectedIssueKey, options = {})=>(actions, { onChange: onChange })=>{
            if (!selectedIssueKey.length) return false;
            actions.setState({
                selectedIssueKey: selectedIssueKey
            });
            $ecb0ae8c94bab407$var$doSideEffectUpdates(actions, {
                ...options,
                onChange: onChange
            });
            return true;
        };
    const $ecb0ae8c94bab407$var$setSelectedIssueByIndex = (issueIndex, options)=>({ dispatch: dispatch }, { issueKeys: issueKeys })=>{
            return dispatch($ecb0ae8c94bab407$var$setSelectedIssueByKey(issueKeys[issueIndex] ?? (0, (0, parcelRequire("g5X3G")).toIssueKey)(''), options));
        };
    const $ecb0ae8c94bab407$var$selectNextIssue = (shouldDebounce)=>({ dispatch: dispatch, getState: getState })=>{
            return dispatch($ecb0ae8c94bab407$var$setSelectedIssueByKey((0, (0, parcelRequire("gz4II")).getNextIssueKey)(getState()) ?? (0, (0, parcelRequire("g5X3G")).toIssueKey)(''), {
                shouldDebounce: shouldDebounce
            }));
        };
    const $ecb0ae8c94bab407$var$selectPreviousIssue = (shouldDebounce)=>({ dispatch: dispatch, getState: getState })=>{
            return dispatch($ecb0ae8c94bab407$var$setSelectedIssueByKey((0, (0, parcelRequire("gz4II")).getPreviousIssueKey)(getState()) ?? (0, (0, parcelRequire("g5X3G")).toIssueKey)(''), {
                shouldDebounce: shouldDebounce
            }));
        };
    const $ecb0ae8c94bab407$var$deselectIssue = (options)=>(actions, { onChange: onChange })=>{
            actions.setState({
                selectedIssueKey: (0, (0, parcelRequire("g5X3G")).toIssueKey)('')
            });
            $ecb0ae8c94bab407$var$doSideEffectUpdates(actions, {
                ...options,
                onChange: onChange
            });
        };
    const $ecb0ae8c94bab407$var$enterFullPageIssueAppMode = ()=>({ setState: setState })=>setState({
                isFullPageIssueAppMode: true
            });
    const $ecb0ae8c94bab407$var$exitFullPageIssueAppMode = ()=>({ setState: setState })=>setState({
                isFullPageIssueAppMode: false
            });
    const $ecb0ae8c94bab407$var$isListViewWithoutIssueApp = ()=>({ getState: getState }, { view: view })=>{
            const { isFullPageIssueAppMode: isFullPageIssueAppMode } = getState();
            return view === (0, (0, parcelRequire("6UAdF")).views).list && !isFullPageIssueAppMode;
        };
    const $ecb0ae8c94bab407$var$isListViewWithIssueAppAndKey = ()=>({ getState: getState }, { view: view })=>{
            const { isFullPageIssueAppMode: isFullPageIssueAppMode, selectedIssueKey: selectedIssueKey } = getState();
            return view === (0, (0, parcelRequire("6UAdF")).views).list && isFullPageIssueAppMode && !!selectedIssueKey;
        };
    const $ecb0ae8c94bab407$var$resetSelectedIssue = ()=>({ dispatch: dispatch })=>{
            const shouldNotifyOnChange = !dispatch($ecb0ae8c94bab407$var$isListViewWithoutIssueApp());
            dispatch($ecb0ae8c94bab407$var$setSelectedIssueByIndex(0, {
                shouldDebounce: false,
                shouldNotifyOnChange: shouldNotifyOnChange,
                isSelectedByUserInteraction: false
            }));
        };
    const $ecb0ae8c94bab407$export$ab8f0de40a3a2d2d = ()=>({ setState: setState }, { issueKeys: issueKeys })=>{
            setState({
                issueKeys: issueKeys
            });
        };
    const $ecb0ae8c94bab407$export$e324594224ef24da = {
        setSelectedIssueByKey: $ecb0ae8c94bab407$var$setSelectedIssueByKey,
        setSelectedIssueByIndex: $ecb0ae8c94bab407$var$setSelectedIssueByIndex,
        selectNextIssue: $ecb0ae8c94bab407$var$selectNextIssue,
        selectPreviousIssue: $ecb0ae8c94bab407$var$selectPreviousIssue,
        deselectIssue: $ecb0ae8c94bab407$var$deselectIssue,
        enterFullPageIssueAppMode: $ecb0ae8c94bab407$var$enterFullPageIssueAppMode,
        exitFullPageIssueAppMode: $ecb0ae8c94bab407$var$exitFullPageIssueAppMode
    };
    const $ecb0ae8c94bab407$export$3f1be494d407b77a = {
        isListViewWithoutIssueApp: $ecb0ae8c94bab407$var$isListViewWithoutIssueApp,
        isListViewWithIssueAppAndKey: $ecb0ae8c94bab407$var$isListViewWithIssueAppAndKey,
        resetSelectedIssue: $ecb0ae8c94bab407$var$resetSelectedIssue,
        onContainerUpdate: $ecb0ae8c94bab407$export$ab8f0de40a3a2d2d
    };
    var $ecb0ae8c94bab407$export$2e2bcd8739ae039 = $ecb0ae8c94bab407$export$e324594224ef24da;
});
parcelRegister("gz4II", function(module, exports) {
    $parcel$export(module.exports, "getSelectedIssueIndex", ()=>$a1a1b31f57e99fe6$export$b5bba5025e9754c0);
    $parcel$export(module.exports, "getNextIssueKey", ()=>$a1a1b31f57e99fe6$export$2a413bfa3aeed781);
    $parcel$export(module.exports, "getPreviousIssueKey", ()=>$a1a1b31f57e99fe6$export$5e257a264fce89f7);
    var $6dDSs;
    const $a1a1b31f57e99fe6$export$b5bba5025e9754c0 = (0, (0, parcelRequire("6dDSs")).createSelector)((state)=>state.selectedIssueKey, (state)=>state.issueKeys, (selectedIssueKey, issueKeys)=>issueKeys.indexOf(selectedIssueKey));
    const $a1a1b31f57e99fe6$export$2a413bfa3aeed781 = (state)=>{
        const index = $a1a1b31f57e99fe6$export$b5bba5025e9754c0(state);
        const { issueKeys: issueKeys } = state;
        return index > -1 && index < issueKeys.length - 1 ? issueKeys[index + 1] : null;
    };
    const $a1a1b31f57e99fe6$export$5e257a264fce89f7 = (state)=>{
        const index = $a1a1b31f57e99fe6$export$b5bba5025e9754c0(state);
        const { issueKeys: issueKeys } = state;
        return index > 0 ? issueKeys[index - 1] : null;
    };
});
parcelRegister("7jipW", function(module, exports) {
    $parcel$export(module.exports, "BulkOperationsWrapper", ()=>$1689161e44158dbb$export$88a2a475d15f0a71);
    var $hOOQf;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Rkzz = parcelRequire("8Rkzz");
    var $8Uumt = parcelRequire("8Uumt");
    var $dC5iT;
    var $kgoHi;
    var $8CUq3;
    var $91LZJ;
    var $8RB1G;
    var $4R6GH;
    var $48rMm;
    const $1689161e44158dbb$var$LoadBulkOpsCore = ({ bulkOpsCoreEntryPointReference: bulkOpsCoreEntryPointReference, fragmentRef: fragmentRef })=>{
        const selectedIssues = (0, (0, parcelRequire("48rMm")).useIssueTableSelectedIssues)();
        const { reset: reset, insertSelectedIssueIds: insertSelectedIssueIds } = (0, (0, parcelRequire("48rMm")).useIssueTableSelectedIssuesActions)();
        const data = (0, $8Uumt.useFragment)((0, (0, parcelRequire("hOOQf")).default), fragmentRef);
        const gqlFields = (0, $5uXOq.useMemo)(()=>data.edges?.find(Boolean)?.node?.fieldSetsForIssueSearchView?.edges?.map((outerEdge)=>outerEdge?.node?.fields?.edges?.map((innerEdge)=>innerEdge?.node)).flat().filter(Boolean).map((field)=>({
                    fieldId: field.fieldId,
                    gqlType: field.__typename
                })) ?? [], [
            data.edges
        ]);
        const issueIds = (0, $5uXOq.useMemo)(()=>{
            const strings = data.edges?.map((edge)=>edge?.node?.issueId).filter(Boolean) ?? [];
            return {
                strings: strings,
                numbers: strings.map((issueId)=>Number(issueId))
            };
        }, [
            data.edges
        ]);
        const onSelectAll = (0, $5uXOq.useCallback)(()=>{
            insertSelectedIssueIds(issueIds.strings);
        }, [
            issueIds.strings,
            insertSelectedIssueIds
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8RB1G")).AsyncBulkOpsCoreFull), {
            gqlFields: gqlFields,
            bulkOpsCoreEntryPointReference: bulkOpsCoreEntryPointReference,
            selectedIssueIds: selectedIssues.map((issue)=>Number(issue)),
            onClearAll: reset,
            onSelectAll: onSelectAll,
            selectableIssueIds: issueIds.numbers,
            onClearAllVisible: (0, ($parcel$interopDefault($8Rkzz)))
        });
    };
    const $1689161e44158dbb$export$88a2a475d15f0a71 = ({ children: children, bulkOpsCoreEntryPointReference: bulkOpsCoreEntryPointReference, fragmentRef: fragmentRef })=>{
        if (!(0, (0, parcelRequire("4R6GH")).fg)('bulk_operations_in_nin')) return children;
        const isBulkOpsInProgress = (0, (0, parcelRequire("91LZJ")).useIsBulkOperationsInProgress)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kgoHi")).default), {
            xcss: $1689161e44158dbb$var$wrapperStyles,
            direction: "row"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: [
                $1689161e44158dbb$var$childContainerStyles,
                isBulkOpsInProgress && $1689161e44158dbb$var$childContainerDisabledStyles
            ]
        }, children), fragmentRef && (0, ($parcel$interopDefault($5uXOq))).createElement($1689161e44158dbb$var$LoadBulkOpsCore, {
            fragmentRef: fragmentRef,
            bulkOpsCoreEntryPointReference: bulkOpsCoreEntryPointReference
        }));
    };
    const $1689161e44158dbb$var$wrapperStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        height: '100%',
        overflowY: 'auto'
    });
    const $1689161e44158dbb$var$childContainerStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0,
        overflowY: 'auto'
    });
    const $1689161e44158dbb$var$childContainerDisabledStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        opacity: 0.5,
        pointerEvents: 'none'
    });
});
parcelRegister("hOOQf", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$7dbfbc599ad06ce9$export$2e2bcd8739ae039);
    const $7dbfbc599ad06ce9$var$node = {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "amountOfColumns"
            },
            {
                "kind": "RootArgument",
                "name": "filterId"
            },
            {
                "kind": "RootArgument",
                "name": "issueTypeId"
            },
            {
                "kind": "RootArgument",
                "name": "namespace"
            },
            {
                "kind": "RootArgument",
                "name": "projectId"
            },
            {
                "kind": "RootArgument",
                "name": "viewId"
            }
        ],
        "kind": "Fragment",
        "name": "bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore",
        "selections": [
            {
                "concreteType": "JiraIssueEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                    {
                        "concreteType": "JiraIssue",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "name": "issueId"
                            },
                            {
                                "kind": "RequiredField",
                                "field": {
                                    "args": [
                                        {
                                            "fields": [
                                                {
                                                    "fields": [
                                                        {
                                                            "kind": "Variable",
                                                            "name": "issueType",
                                                            "variableName": "issueTypeId"
                                                        },
                                                        {
                                                            "kind": "Variable",
                                                            "name": "project",
                                                            "variableName": "projectId"
                                                        }
                                                    ],
                                                    "kind": "ObjectValue",
                                                    "name": "projectContext"
                                                }
                                            ],
                                            "kind": "ObjectValue",
                                            "name": "context"
                                        },
                                        {
                                            "kind": "Variable",
                                            "name": "filterId",
                                            "variableName": "filterId"
                                        },
                                        {
                                            "kind": "Variable",
                                            "name": "first",
                                            "variableName": "amountOfColumns"
                                        },
                                        {
                                            "kind": "Variable",
                                            "name": "namespace",
                                            "variableName": "namespace"
                                        },
                                        {
                                            "kind": "Variable",
                                            "name": "viewId",
                                            "variableName": "viewId"
                                        }
                                    ],
                                    "concreteType": "JiraIssueFieldSetConnection",
                                    "kind": "LinkedField",
                                    "name": "fieldSetsForIssueSearchView",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "concreteType": "JiraIssueFieldSetEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "concreteType": "JiraIssueFieldSet",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "concreteType": "JiraIssueFieldConnection",
                                                            "kind": "LinkedField",
                                                            "name": "fields",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "concreteType": "JiraIssueFieldEdge",
                                                                    "kind": "LinkedField",
                                                                    "name": "edges",
                                                                    "plural": true,
                                                                    "selections": [
                                                                        {
                                                                            "kind": "LinkedField",
                                                                            "name": "node",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                {
                                                                                    "kind": "ScalarField",
                                                                                    "name": "fieldId"
                                                                                },
                                                                                {
                                                                                    "kind": "ScalarField",
                                                                                    "name": "__typename"
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                "action": "THROW",
                                "path": "edges.node.fieldSetsForIssueSearchView"
                            }
                        ]
                    }
                ]
            }
        ],
        "type": "JiraIssueConnection"
    };
    $7dbfbc599ad06ce9$var$node.hash = "0a4d62fb7518ba0b4add8cfe9ff88015";
    var $7dbfbc599ad06ce9$export$2e2bcd8739ae039 = $7dbfbc599ad06ce9$var$node;
});
parcelRegister("91LZJ", function(module, exports) {
    $parcel$export(module.exports, "useBulkOperations", ()=>$655e95f3b81da53e$export$940587be18a93fc4);
    $parcel$export(module.exports, "useBulkOperationsActions", ()=>$655e95f3b81da53e$export$355499dfde532d1a);
    $parcel$export(module.exports, "useIsBulkOperationsInProgress", ()=>$655e95f3b81da53e$export$760458c2204457a1);
    $parcel$export(module.exports, "useShouldRenderSpotlight", ()=>$655e95f3b81da53e$export$4a165cf2377f023a);
    $parcel$export(module.exports, "useBulkTransitionsStage", ()=>$655e95f3b81da53e$export$b3afff39a0c663ad);
    $parcel$export(module.exports, "useBulkOperationInForm", ()=>$655e95f3b81da53e$export$6794339387d6d562);
    $parcel$export(module.exports, "useBulkOperationType", ()=>$655e95f3b81da53e$export$4289f82da677ffac);
    $parcel$export(module.exports, "useCancelDialogTrigger", ()=>$655e95f3b81da53e$export$babceeeb2408337b);
    $parcel$export(module.exports, "useCurrentView", ()=>$655e95f3b81da53e$export$8ce7af7b2f8c770);
    var $4R6GH;
    var $3sQ5x;
    var $iXqE5;
    var $5ltfA;
    var $liV8G;
    const $655e95f3b81da53e$export$d4c72bab9d6cc13a = {
        operationType: null,
        isSidebarOpen: false,
        cancelDialogTrigger: undefined,
        currentView: 'edit',
        firstFieldId: undefined
    };
    const $655e95f3b81da53e$var$actions = {
        setSummaryView: ()=>({ setState: setState })=>{
                setState({
                    currentView: 'summary'
                });
            },
        setFirstFieldId: (firstFieldId)=>({ setState: setState })=>{
                setState({
                    firstFieldId: firstFieldId
                });
            },
        setEditView: ()=>({ setState: setState })=>{
                setState({
                    currentView: 'edit'
                });
            },
        setCancelDialogTrigger: (newTrigger = 'user-triggered')=>({ setState: setState, getState: getState })=>{
                const { cancelDialogTrigger: currentTrigger } = getState();
                if (currentTrigger === 'route-change' && newTrigger === 'user-triggered') return;
                setState({
                    cancelDialogTrigger: newTrigger
                });
            },
        clearDialogTrigger: ()=>({ setState: setState })=>{
                setState({
                    cancelDialogTrigger: undefined
                });
            },
        startEdit: (args)=>({ setState: setState })=>{
                const { shouldRenderSpotlight: shouldRenderSpotlight } = args || {
                    shouldRenderSpotlight: false
                };
                (0, (0, parcelRequire("4R6GH")).fg)('bulk_edit_discoverability') ? setState({
                    operationType: {
                        type: (0, (0, parcelRequire("liV8G")).BulkOperationsTypes).BULK_EDIT
                    },
                    shouldRenderSpotlight: shouldRenderSpotlight,
                    isSidebarOpen: true
                }) : setState({
                    operationType: {
                        type: (0, (0, parcelRequire("liV8G")).BulkOperationsTypes).BULK_EDIT
                    },
                    isSidebarOpen: true
                });
            },
        loadTransitions: ()=>({ setState: setState })=>{
                setState({
                    operationType: {
                        type: (0, (0, parcelRequire("liV8G")).BulkOperationsTypes).BULK_TRANSITION,
                        stage: (0, (0, parcelRequire("liV8G")).BulkTransitionsStage).LOAD_BULK_TRANSITIONS
                    },
                    isSidebarOpen: false
                });
            },
        startQuickTransitions: ()=>({ setState: setState })=>{
                setState({
                    operationType: {
                        type: (0, (0, parcelRequire("liV8G")).BulkOperationsTypes).BULK_TRANSITION,
                        stage: (0, (0, parcelRequire("liV8G")).BulkTransitionsStage).START_QUICK_TRANSITIONS
                    },
                    isSidebarOpen: false
                });
            },
        startSidebarTransition: ()=>({ setState: setState })=>{
                setState({
                    operationType: {
                        type: (0, (0, parcelRequire("liV8G")).BulkOperationsTypes).BULK_TRANSITION,
                        stage: (0, (0, parcelRequire("liV8G")).BulkTransitionsStage).START_SIDEBAR_TRANSITION
                    },
                    isSidebarOpen: true
                });
            },
        startTransitionScreen: ()=>({ setState: setState })=>{
                setState({
                    operationType: {
                        type: (0, (0, parcelRequire("liV8G")).BulkOperationsTypes).BULK_TRANSITION,
                        stage: (0, (0, parcelRequire("liV8G")).BulkTransitionsStage).START_TRANSITION_SCREEN
                    },
                    isSidebarOpen: true
                });
            },
        closeSidebar: ()=>({ setState: setState })=>{
                setState($655e95f3b81da53e$export$d4c72bab9d6cc13a);
            },
        dismissBulkOperation: ()=>({ setState: setState })=>{
                (0, (0, parcelRequire("5ltfA")).extraFormDataService).clear();
                setState($655e95f3b81da53e$export$d4c72bab9d6cc13a);
            }
    };
    const $655e95f3b81da53e$export$6f57813fe9f31bf9 = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: $655e95f3b81da53e$export$d4c72bab9d6cc13a,
        actions: $655e95f3b81da53e$var$actions,
        name: 'bulk-operations-store'
    });
    const $655e95f3b81da53e$export$940587be18a93fc4 = (0, (0, parcelRequire("3sQ5x")).createHook)($655e95f3b81da53e$export$6f57813fe9f31bf9);
    const $655e95f3b81da53e$export$355499dfde532d1a = (0, (0, parcelRequire("3sQ5x")).createActionsHook)($655e95f3b81da53e$export$6f57813fe9f31bf9);
    const $655e95f3b81da53e$export$760458c2204457a1 = (0, (0, parcelRequire("3sQ5x")).createStateHook)($655e95f3b81da53e$export$6f57813fe9f31bf9, {
        selector: (state)=>state.operationType !== null
    });
    const $655e95f3b81da53e$export$4a165cf2377f023a = (0, (0, parcelRequire("3sQ5x")).createStateHook)($655e95f3b81da53e$export$6f57813fe9f31bf9, {
        selector: (state)=>!!state.shouldRenderSpotlight
    });
    const $655e95f3b81da53e$export$b3afff39a0c663ad = (0, (0, parcelRequire("3sQ5x")).createStateHook)($655e95f3b81da53e$export$6f57813fe9f31bf9, {
        selector: (state)=>{
            const { operationType: operationType } = state;
            if (operationType?.type === (0, (0, parcelRequire("liV8G")).BulkOperationsTypes).BULK_TRANSITION) return operationType.stage;
            return null;
        }
    });
    const $655e95f3b81da53e$export$6794339387d6d562 = (0, (0, parcelRequire("3sQ5x")).createStateHook)($655e95f3b81da53e$export$6f57813fe9f31bf9, {
        selector: (state)=>{
            if (state.isSidebarOpen && state.operationType) return state.operationType.type;
            return null;
        }
    });
    const $655e95f3b81da53e$export$4289f82da677ffac = (0, (0, parcelRequire("3sQ5x")).createStateHook)($655e95f3b81da53e$export$6f57813fe9f31bf9, {
        selector: (state)=>state.operationType
    });
    const $655e95f3b81da53e$export$babceeeb2408337b = (0, (0, parcelRequire("3sQ5x")).createStateHook)($655e95f3b81da53e$export$6f57813fe9f31bf9, {
        selector: (state)=>state.cancelDialogTrigger
    });
    const $655e95f3b81da53e$export$8ce7af7b2f8c770 = (0, (0, parcelRequire("3sQ5x")).createStateHook)($655e95f3b81da53e$export$6f57813fe9f31bf9, {
        selector: (state)=>state.currentView
    });
});
parcelRegister("5ltfA", function(module, exports) {
    $parcel$export(module.exports, "extraFormDataService", ()=>$3546d70b36caa00c$export$3cd9d97d155e8e66);
    class $3546d70b36caa00c$var$ExtraFormData {
        clear() {
            this.data = {};
        }
        addByKey(key, value) {
            this.data[key] = value;
        }
        getByKey(key) {
            return this.data[key];
        }
        getAll() {
            return this.data;
        }
        constructor(){
            this.data = {};
        }
    }
    const $3546d70b36caa00c$var$extraFormData = new $3546d70b36caa00c$var$ExtraFormData();
    const $3546d70b36caa00c$var$addDataByKey = (key, value)=>{
        $3546d70b36caa00c$var$extraFormData.addByKey(key, value);
    };
    const $3546d70b36caa00c$var$clear = ()=>{
        $3546d70b36caa00c$var$extraFormData.clear();
    };
    const $3546d70b36caa00c$var$getDataByKey = (key)=>$3546d70b36caa00c$var$extraFormData.getByKey(key);
    const $3546d70b36caa00c$var$getData = ()=>$3546d70b36caa00c$var$extraFormData.getAll();
    const $3546d70b36caa00c$export$3cd9d97d155e8e66 = {
        addDataByKey: $3546d70b36caa00c$var$addDataByKey,
        getDataByKey: $3546d70b36caa00c$var$getDataByKey,
        clear: $3546d70b36caa00c$var$clear,
        getData: $3546d70b36caa00c$var$getData
    };
});
parcelRegister("liV8G", function(module, exports) {
    $parcel$export(module.exports, "BulkOperationsTypes", ()=>$41c61f309c49c414$export$7ec2782f56bb6e02);
    $parcel$export(module.exports, "BulkTransitionsStage", ()=>$41c61f309c49c414$export$a6f51fa6c8971716);
    const $41c61f309c49c414$export$7ec2782f56bb6e02 = {
        BULK_EDIT: 'BULK_EDIT',
        BULK_TRANSITION: 'BULK_TRANSITION'
    };
    const $41c61f309c49c414$export$a6f51fa6c8971716 = {
        LOAD_BULK_TRANSITIONS: 'LOAD_BULK_TRANSITIONS',
        START_QUICK_TRANSITIONS: 'START_QUICK_TRANSITIONS',
        START_SIDEBAR_TRANSITION: 'START_SIDEBAR_TRANSITION',
        START_TRANSITION_SCREEN: 'START_TRANSITION_SCREEN'
    };
});
parcelRegister("8RB1G", function(module, exports) {
    $parcel$export(module.exports, "AsyncBulkOpsCoreFull", ()=>$0713926df1958104$export$5a522923c01d18f2);
    var $5uXOq = parcelRequire("5uXOq");
    var $7XluA;
    var $4mO32;
    var $koeKL;
    var $52qhI;
    const $0713926df1958104$export$5a522923c01d18f2 = (props)=>{
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const entryPointParams = (0, $5uXOq.useMemo)(()=>({
                cloudId: cloudId
            }), [
            cloudId
        ]);
        const { entryPointActions: entryPointActions, entryPointReferenceSubject: entryPointReferenceSubject } = (0, (0, parcelRequire("4mO32")).useEntryPoint)((0, (0, parcelRequire("52qhI")).asyncBulkOpsCoreFullEntryPoint), entryPointParams);
        (0, $5uXOq.useEffect)(()=>{
            entryPointActions.load();
        }, [
            entryPointActions
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7XluA")).JiraEntryPointContainer), {
            id: "jira.backlog.bulk.operations.core.full",
            packageName: "jiraBulkOperationsCoreFull",
            entryPointReferenceSubject: entryPointReferenceSubject,
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null),
            runtimeProps: props
        });
    };
});
parcelRegister("52qhI", function(module, exports) {
    $parcel$export(module.exports, "asyncBulkOpsCoreFullEntryPoint", ()=>$42e200e10d2ccac9$export$4586bee469e9f8fc);
    var $61xJ7;
    var $eufEc;
    var $fEDBv;
    const $42e200e10d2ccac9$export$4586bee469e9f8fc = (0, (0, parcelRequire("fEDBv")).createEntryPoint)({
        root: (0, (0, parcelRequire("eufEc")).JSResourceForInteraction)(()=>(parcelRequire("itqIn")), {
            moduleId: "46b63f73"
        }),
        getPreloadProps: ({ cloudId: cloudId })=>({
                queries: {
                    queryReference: {
                        options: {
                            fetchPolicy: 'store-and-network'
                        },
                        parameters: (0, parcelRequire("61xJ7")).default,
                        variables: {
                            cloudId: cloudId
                        }
                    }
                }
            })
    });
});
parcelRegister("61xJ7", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$dcabe91a5fb1367e$export$2e2bcd8739ae039);
    const $dcabe91a5fb1367e$var$node = {
        "kind": "PreloadableConcreteRequest",
        "params": {
            "id": "0fb631b50dc895a9ef3b13273858a2ee58a5321bad4ca6c6300ff6ab4ffe4f36",
            "metadata": {},
            "name": "srcFullCoreQuery",
            "operationKind": "query",
            "text": null
        }
    };
    var $dcabe91a5fb1367e$export$2e2bcd8739ae039 = $dcabe91a5fb1367e$var$node;
});
parcelRegister("itqIn", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("3smSX").then(()=>parcelRequire('anh2a'));
});
parcelRegister("jKHs3", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$a32f27c74f4bd66c$export$2e2bcd8739ae039);
    var $3mkkt;
    var $ragIq;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $fVnbM;
    var $3Tty1;
    var $6s1PB;
    var $koVbs;
    var $iXVV6;
    var $8zOmE;
    var $2NMNM;
    var $g5X3G;
    var $imS60;
    var $6UAdF;
    var $eojy4;
    var $gtUFk;
    var $5NwJk;
    var $7OTah;
    var $isIOR;
    var $38r5c;
    var $5lE9L;
    const $a32f27c74f4bd66c$var$FullPageIssueApp = ({ onChangeIssue: onChangeIssue, issueResults: issueResults, view: view })=>{
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        let isFetching;
        let isForcedSelectionOnReset;
        let exitFullPageIssueAppMode;
        let selectNextIssue;
        let selectPreviousIssue;
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
            isFetching = (0, (0, parcelRequire("38r5c")).useIsFetching)();
            isForcedSelectionOnReset = false;
            ({ exitFullPageIssueAppMode: exitFullPageIssueAppMode, selectNextIssue: selectNextIssue, selectPreviousIssue: selectPreviousIssue } = (0, (0, parcelRequire("7OTah")).useSelectedIssueActions)());
        } else {
            ({ isFetching: isFetching } = (0, (0, parcelRequire("isIOR")).useIssueSearchQuery)());
            isForcedSelectionOnReset = (0, (0, parcelRequire("gtUFk")).useIsForcedSelectionOnReset)();
            ({ exitFullPageIssueAppMode: exitFullPageIssueAppMode, selectNextIssue: selectNextIssue, selectPreviousIssue: selectPreviousIssue } = (0, (0, parcelRequire("gtUFk")).useSelectedIssueStateOldActions)());
        }
        const onBackToSearch = (0, $5uXOq.useCallback)(()=>{
            onChangeIssue((0, (0, parcelRequire("g5X3G")).toIssueKey)(''), true);
            exitFullPageIssueAppMode();
        }, [
            exitFullPageIssueAppMode,
            onChangeIssue
        ]);
        const onBackToSearchByButton = (0, $5uXOq.useCallback)((e, analyticsEvent)=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent.clone(), 'returnToSearch');
            onBackToSearch();
        }, [
            onBackToSearch
        ]);
        const onBackToSearchByKeyboardShortcut = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                action: 'toggled',
                actionSubject: 'issue'
            }), 'returnToSearch', {
                keyPressed: 'z',
                keyboardShortcut: true
            });
            onBackToSearch();
        }, [
            onBackToSearch,
            createAnalyticsEvent
        ]);
        const onNextIssue = (0, $5uXOq.useCallback)(()=>{
            if (selectNextIssue()) (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                action: 'clicked',
                actionSubject: 'button'
            }), 'nextIssue', {
                keyboardShortcut: true
            });
        }, [
            createAnalyticsEvent,
            selectNextIssue
        ]);
        const onPreviousIssue = (0, $5uXOq.useCallback)(()=>{
            if (selectPreviousIssue()) (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                action: 'clicked',
                actionSubject: 'button'
            }), 'previousIssue', {
                keyboardShortcut: true
            });
        }, [
            createAnalyticsEvent,
            selectPreviousIssue
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (isFetching && !isForcedSelectionOnReset) exitFullPageIssueAppMode();
        }, [
            exitFullPageIssueAppMode,
            isFetching,
            isForcedSelectionOnReset
        ]);
        const backToSearch = (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            testId: "issue-navigator.ui.full-page-issue-app.return-to-search",
            onClick: onBackToSearchByButton,
            interactionName: "issue-navigator-full-page-issue-app-return-to-search"
        }, formatMessage((0, (0, parcelRequire("5lE9L")).default).returnText));
        const issueResultsData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("ragIq")).default), issueResults);
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
            const { hasPrevious: hasPrevious, isLoadingPrevious: isLoadingPrevious, onLoadPrevious: onLoadPrevious, hasNext: hasNext, isLoadingNext: isLoadingNext, onLoadNext: onLoadNext } = (0, (0, parcelRequire("38r5c")).useInfiniteScroll)();
            const selectedIssueKey = (0, (0, parcelRequire("5NwJk")).useSelectedIssueKey)();
            const edges = issueResultsData?.edges ?? [];
            const isFirstIssueSelected = edges[0]?.node?.key === selectedIssueKey;
            const isLastIssueSelected = edges[edges.length - 1]?.node?.key === selectedIssueKey;
            (0, $5uXOq.useEffect)(()=>{
                if (isFirstIssueSelected && hasPrevious && !isLoadingPrevious) onLoadPrevious?.();
            }, [
                hasPrevious,
                isFirstIssueSelected,
                isLoadingPrevious,
                onLoadPrevious
            ]);
            (0, $5uXOq.useEffect)(()=>{
                if (isLastIssueSelected && hasNext && !isLoadingNext) onLoadNext?.();
            }, [
                hasNext,
                isLastIssueSelected,
                isLoadingNext,
                onLoadNext
            ]);
        }
        const viewData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("3mkkt")).default), view);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("imS60")).default), {
            name: "full-page-issue-app"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            sourceName: (0, (0, parcelRequire("6UAdF")).ANALYTICS_LIST_VIEW_SOURCE_NAME),
            sourceType: (0, $4d5e0871c06cee03$export$f636b5b56f1d624a)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3Tty1")).default), {
            keyMap: {
                z: {
                    callback: onBackToSearchByKeyboardShortcut
                },
                j: {
                    callback: onNextIssue
                },
                k: {
                    callback: onPreviousIssue
                }
            }
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eojy4")).default), {
            isEmbedView: false,
            extraHeaderActions: backToSearch,
            issueResults: issueResultsData,
            view: viewData
        })));
    };
    var $a32f27c74f4bd66c$export$2e2bcd8739ae039 = $a32f27c74f4bd66c$var$FullPageIssueApp;
});
parcelRegister("3mkkt", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$d3361b148cbcd4cd$export$2e2bcd8739ae039);
    const $d3361b148cbcd4cd$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_FullPageIssueApp_view",
        "selections": [
            {
                "kind": "FragmentSpread",
                "name": "main_issueNavigator_IssueAppWithData_view"
            }
        ],
        "type": "JiraIssueSearchView"
    };
    $d3361b148cbcd4cd$var$node.hash = "468ea16df2cfa07839ca03ecd97948b9";
    var $d3361b148cbcd4cd$export$2e2bcd8739ae039 = $d3361b148cbcd4cd$var$node;
});
parcelRegister("ragIq", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$e254396e0247514b$export$2e2bcd8739ae039);
    const $e254396e0247514b$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_FullPageIssueApp_issueResults",
        "selections": [
            {
                "concreteType": "JiraIssueEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                    {
                        "concreteType": "JiraIssue",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "name": "key"
                            }
                        ]
                    }
                ]
            },
            {
                "kind": "FragmentSpread",
                "name": "main_issueNavigator_IssueAppWithData_issueResults"
            }
        ],
        "type": "JiraIssueConnection"
    };
    $e254396e0247514b$var$node.hash = "c76ea1c159a6e1be56c13b6262324ca3";
    var $e254396e0247514b$export$2e2bcd8739ae039 = $e254396e0247514b$var$node;
});
parcelRegister("3Tty1", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$6ba57d5dc6c0de0a$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $9IeM0;
    var $4lkOX;
    var $h0GOb;
    var $k4r7E;
    var $9fuVs;
    var $kspvB;
    let $6ba57d5dc6c0de0a$var$shortcutsCount = 0;
    let $6ba57d5dc6c0de0a$var$subscription = null;
    const $6ba57d5dc6c0de0a$var$ShortcutsSubscriber = false ? ()=>null : ({ keyMap: keyMap, label: label, location: location })=>{
        const parentRegistry = (0, $5uXOq.useContext)((0, (0, parcelRequire("9fuVs")).ShortcutRegistryContext)) || (0, (0, parcelRequire("k4r7E")).rootRegistry);
        const addKeyMap = (0, $5uXOq.useCallback)(()=>parentRegistry.addKeyMap(()=>({
                    keyMap: (0, (0, parcelRequire("kspvB")).bindKeyMapWithErrorHandling)(keyMap, location),
                    label: label
                })), [
            label,
            parentRegistry,
            keyMap,
            location
        ]);
        (0, $5uXOq.useEffect)(()=>{
            $6ba57d5dc6c0de0a$var$shortcutsCount += 1;
            const removeKeyMap = addKeyMap();
            return ()=>{
                removeKeyMap();
                $6ba57d5dc6c0de0a$var$shortcutsCount -= 1;
                if ($6ba57d5dc6c0de0a$var$subscription && $6ba57d5dc6c0de0a$var$shortcutsCount === 0) {
                    $6ba57d5dc6c0de0a$var$subscription.unsubscribe();
                    $6ba57d5dc6c0de0a$var$subscription = null;
                }
            };
        }, [
            addKeyMap
        ]);
        (0, $5uXOq.useEffect)(()=>{
            $6ba57d5dc6c0de0a$var$subscription = $6ba57d5dc6c0de0a$var$subscription || (0, (0, parcelRequire("h0GOb")).default)().subscribe((0, (0, parcelRequire("4lkOX")).default));
        }, [
            parentRegistry,
            keyMap
        ]);
        return null;
    };
    var $6ba57d5dc6c0de0a$export$2e2bcd8739ae039 = ({ useKeyboardShortcutsToggle: useKeyboardShortcutsToggle = (0, (0, parcelRequire("9IeM0")).useKeyboardShortcutsToggle), ...props })=>{
        const [{ isEnabled: isEnabled }] = useKeyboardShortcutsToggle();
        return isEnabled && !false ? (0, ($parcel$interopDefault($5uXOq))).createElement($6ba57d5dc6c0de0a$var$ShortcutsSubscriber, props) : null;
    };
});
parcelRegister("9IeM0", function(module, exports) {
    $parcel$export(module.exports, "useKeyboardShortcutsToggle", ()=>$dc712fc9bec05022$export$a33a81f1fc5458ad);
    var $jcw0u;
    var $eusud;
    var $j8DrX;
    var $8zOmE;
    var $3sQ5x;
    var $iXqE5;
    const $dc712fc9bec05022$var$URL_FRAGMENT = '/rest/internal/latest/user/preferences/keyboardShortcuts?turn=';
    const $dc712fc9bec05022$export$b62f67be35e65859 = (analyticsEvent)=>async ({ setState: setState, getState: getState })=>{
            const currentState = getState().isEnabled;
            const status = currentState ? 'off' : 'on';
            const url = `${$dc712fc9bec05022$var$URL_FRAGMENT}${status}`;
            try {
                await (0, (0, parcelRequire("eusud")).performPutRequest)(url);
                setState({
                    isEnabled: !currentState,
                    error: null
                });
                (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'keyboardShortcutsToggle', {
                    isEnabled: !currentState
                });
            } catch (err) {
                setState({
                    error: err
                });
                (0, (0, parcelRequire("jcw0u")).default)({
                    meta: {
                        id: 'keyboardShortcutToggleFailure',
                        packageName: 'jiraCommonLegacyDoNotAddAnythingNew'
                    },
                    error: err
                });
            }
        };
    const $dc712fc9bec05022$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: {
            isEnabled: false ? false : !((0, (0, parcelRequire("j8DrX")).default)('ajs-keyboard-shortcuts-enabled') === 'false'),
            error: null
        },
        actions: {
            toggle: $dc712fc9bec05022$export$b62f67be35e65859
        }
    });
    const $dc712fc9bec05022$export$a33a81f1fc5458ad = (0, (0, parcelRequire("3sQ5x")).createHook)($dc712fc9bec05022$var$Store);
});
parcelRegister("4lkOX", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$f11d50ddafbb6f85$export$2e2bcd8739ae039);
    var $h0GOb;
    var $k4r7E;
    const $f11d50ddafbb6f85$var$matches = (prefix)=>(definedSequence)=>definedSequence.startsWith(`${prefix} `);
    var $f11d50ddafbb6f85$export$2e2bcd8739ae039 = (keySequence)=>{
        const { sequence: sequence, e: e } = keySequence || {};
        const activeKeyMap = (0, (0, parcelRequire("k4r7E")).rootRegistry).getActiveKeyMap();
        if (sequence !== undefined && activeKeyMap[sequence]) {
            activeKeyMap[sequence].callback(e);
            (0, (0, parcelRequire("h0GOb")).flush)();
        } else {
            const isFutureMatchPossible = sequence !== undefined && Object.keys(activeKeyMap).some($f11d50ddafbb6f85$var$matches(sequence));
            if (!isFutureMatchPossible) (0, (0, parcelRequire("h0GOb")).flush)();
        }
    };
});
parcelRegister("h0GOb", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$849cd54ac4017470$export$2e2bcd8739ae039);
    $parcel$export(module.exports, "flush", ()=>$849cd54ac4017470$export$4909b86c8034bf73);
    var $kVLXA = parcelRequire("kVLXA");
    var $cab1K = parcelRequire("cab1K");
    parcelRequire("jG6Un");
    parcelRequire("eSGL3");
    parcelRequire("1oQta");
    parcelRequire("4kRRr");
    parcelRequire("d0pqD");
    parcelRequire("iQCJJ");
    parcelRequire("fjPtF");
    parcelRequire("2R2to");
    var $lIZQG;
    const $849cd54ac4017470$var$ignoredKeys = {
        Control: true,
        Meta: true,
        Shift: true,
        Alt: true,
        AltGraph: true,
        CapsLock: true,
        Fn: true,
        FnLock: true,
        Hyper: true,
        NumLock: true,
        OS: true,
        ScrollLock: true,
        Super: true,
        Symbol: true,
        SymbolLock: true
    };
    const $849cd54ac4017470$var$isNonTextInput = ({ inputMode: inputMode = '', type: type })=>inputMode === 'none' || type === 'checkbox';
    const $849cd54ac4017470$var$isEditable = (node)=>node.nodeName === 'INPUT' && !$849cd54ac4017470$var$isNonTextInput(node) || node.nodeName === 'TEXTAREA' || node.isContentEditable === true;
    const $849cd54ac4017470$var$isRelevantKeyEvent = (e)=>typeof e.key === 'string' && (e.key === 'Escape' || !$849cd54ac4017470$var$isEditable(e.target) && !$849cd54ac4017470$var$ignoredKeys[e.key]);
    const $849cd54ac4017470$export$25d1b8252b4c49b7 = ()=>{
        const elBlankets = document.querySelectorAll('.aui-blanket');
        if (elBlankets.length === 0) return true;
        const isAllHidden = Array.from(elBlankets || []).every((el)=>{
            if (el.getAttribute('aria-hidden') === 'true' || el.getAttribute('hidden') !== null) return true;
            return false;
        });
        return isAllHidden;
    };
    let $849cd54ac4017470$var$key$;
    const $849cd54ac4017470$var$flush$ = new (0, $cab1K.Subject)();
    var $849cd54ac4017470$export$2e2bcd8739ae039 = ()=>{
        if (!$849cd54ac4017470$var$key$) {
            $849cd54ac4017470$var$key$ = false ? new (0, $cab1K.Subject)() : (0, $kVLXA.Observable).fromEvent(document, 'keydown').filter($849cd54ac4017470$var$isRelevantKeyEvent).filter($849cd54ac4017470$export$25d1b8252b4c49b7).map((0, (0, parcelRequire("lIZQG")).toKeyWithModifiers));
            const sequence$ = $849cd54ac4017470$var$key$.scan((seq, key)=>{
                if (!key || !seq) return undefined;
                return {
                    sequence: `${String(seq.sequence)} ${String(key.sequence)}`,
                    e: key.e
                };
            });
            $849cd54ac4017470$var$key$ = $849cd54ac4017470$var$key$.debounceTime(1000).startWith(null).merge($849cd54ac4017470$var$flush$).switchMapTo(sequence$);
        }
        return $849cd54ac4017470$var$key$;
    };
    const $849cd54ac4017470$export$4909b86c8034bf73 = ()=>{
        $849cd54ac4017470$var$flush$.next();
    };
});
parcelRegister("lIZQG", function(module, exports) {
    $parcel$export(module.exports, "toKeyWithModifiers", ()=>$ca3344250c7b7406$export$8cf0d2c3d060a0c2);
    $parcel$export(module.exports, "isValidShortcut", ()=>$ca3344250c7b7406$export$8e231506c5580162);
    $parcel$export(module.exports, "isShortcutCollision", ()=>$ca3344250c7b7406$export$4add9e50cb49d130);
    const $ca3344250c7b7406$var$isNonCharacterKey = (e)=>e.key.length > 1 || e.key === ' ';
    const $ca3344250c7b7406$var$modifiers = [
        {
            name: 'ctrl',
            test: (e)=>e.ctrlKey
        },
        {
            name: 'shift',
            test: (e)=>e.shiftKey && $ca3344250c7b7406$var$isNonCharacterKey(e)
        },
        {
            name: 'meta',
            test: (e)=>e.metaKey || e.getModifierState && e.getModifierState('OS')
        }
    ];
    const $ca3344250c7b7406$var$toKey = (e)=>{
        if ($ca3344250c7b7406$var$isNonCharacterKey(e)) return e.key.toLowerCase();
        return e.shiftKey ? e.key.toUpperCase() : e.key.toLowerCase();
    };
    const $ca3344250c7b7406$export$8cf0d2c3d060a0c2 = (e)=>{
        if (!e || !e.key) return {
            sequence: '',
            e: e
        };
        return {
            sequence: $ca3344250c7b7406$var$modifiers.filter((modifier)=>modifier.test(e)).map((modifier)=>modifier.name).concat($ca3344250c7b7406$var$toKey(e)).join('+'),
            e: e
        };
    };
    const $ca3344250c7b7406$var$orSpecialChars = ' |arrowdown|arrowleft|arrowright|arrowup|backspace|clear|delete|end|enter|escape|home|insert|pagedown|pageup|pause|printscreen|tab';
    const $ca3344250c7b7406$var$singleShortcutEntry = `(ctrl\\+)?(shift\\+(?=(?:(?:meta\\+)?(?:${$ca3344250c7b7406$var$orSpecialChars}))))?(meta\\+)?(${$ca3344250c7b7406$var$orSpecialChars}|[!-~])`;
    const $ca3344250c7b7406$var$shortcutRegex = RegExp(`^${$ca3344250c7b7406$var$singleShortcutEntry}( ${$ca3344250c7b7406$var$singleShortcutEntry})*$`);
    const $ca3344250c7b7406$export$8e231506c5580162 = (shortcut)=>$ca3344250c7b7406$var$shortcutRegex.test(shortcut);
    const $ca3344250c7b7406$export$4add9e50cb49d130 = (leftShortcut, rightShortcut)=>{
        if (!leftShortcut || !rightShortcut) return leftShortcut === rightShortcut;
        if (rightShortcut === leftShortcut) return true;
        if (leftShortcut.indexOf(rightShortcut) === 0) return leftShortcut[rightShortcut.length] === ' ';
        if (rightShortcut.indexOf(leftShortcut) === 0) return rightShortcut[leftShortcut.length] === ' ';
        return false;
    };
});
parcelRegister("k4r7E", function(module, exports) {
    $parcel$export(module.exports, "ShortcutRegistry", ()=>$a65fecdbaed23de2$export$ca1c75b575abf5ff);
    $parcel$export(module.exports, "rootRegistry", ()=>$a65fecdbaed23de2$export$1dd4c61d1ce1fa15);
    var $lbWm4 = parcelRequire("lbWm4");
    var $561FV = parcelRequire("561FV");
    var $hGgHi = parcelRequire("hGgHi");
    var $FISJv = parcelRequire("FISJv");
    var $lIZQG;
    const $a65fecdbaed23de2$var$validateKeyMaps = (keyMaps)=>{
        (0, ($parcel$interopDefault($lbWm4)))(keyMaps, (keyMap, keyMapIndex)=>{
            const shortcuts = Object.keys(keyMap);
            (0, ($parcel$interopDefault($lbWm4)))(shortcuts, (shortcut, shortcutIndex)=>{
                if (!(0, (0, parcelRequire("lIZQG")).isValidShortcut)(shortcut)) console.error(`"${shortcut}" is an incorrect shortcut. Please, update it according to a playbook entry on shortcuts.`);
                for(let indexForOtherKeymaps = keyMapIndex + 1; indexForOtherKeymaps < keyMaps.length; indexForOtherKeymaps += 1){
                    const anotherKeyMap = keyMaps[indexForOtherKeymaps];
                    (0, ($parcel$interopDefault($lbWm4)))(anotherKeyMap, (anotherAction, anotherShortcut)=>{
                        if ((0, (0, parcelRequire("lIZQG")).isShortcutCollision)(anotherShortcut, shortcut)) {
                            if ((0, ($parcel$interopDefault($hGgHi)))(keyMap[shortcut].priority) && (0, ($parcel$interopDefault($hGgHi)))(anotherKeyMap[anotherShortcut].priority) || keyMap[shortcut].priority === anotherKeyMap[anotherShortcut].priority) console.error(`"${shortcut}" has a collision with "${anotherShortcut}" shortcut from another keyMap.`);
                        }
                    });
                }
                for(let indexToCompareSelf = shortcutIndex + 1; indexToCompareSelf < shortcuts.length; indexToCompareSelf += 1)if ((0, (0, parcelRequire("lIZQG")).isShortcutCollision)(shortcut, shortcuts[indexToCompareSelf])) console.error(`"${shortcut}" has a collision with "${shortcuts[indexToCompareSelf]}" shortcut from the same keyMap.`);
            });
        });
    };
    const $a65fecdbaed23de2$var$mergeKeyMaps = (keyMapsToMerge)=>keyMapsToMerge.reduce((prev, keyMap)=>({
                ...prev,
                ...Object.keys(keyMap).reduce((newAccumulator, newKey)=>{
                    if (!(0, ($parcel$interopDefault($561FV)))(prev, newKey) || (prev[newKey].priority ?? 0) < (keyMap[newKey].priority ?? 1)) return {
                        ...newAccumulator,
                        [newKey]: keyMap[newKey]
                    };
                    return newAccumulator;
                }, {})
            }), {});
    class $a65fecdbaed23de2$export$ca1c75b575abf5ff {
        addChildRegistry(child) {
            this.children.push(child);
            return ()=>{
                (0, ($parcel$interopDefault($FISJv)))(this.children, child);
            };
        }
        addKeyMap(getKeyMap) {
            this.keyMapRetrievers.push(getKeyMap);
            return ()=>{
                (0, ($parcel$interopDefault($FISJv)))(this.keyMapRetrievers, getKeyMap);
            };
        }
        getActiveKeyMap() {
            const keyMaps = this.children.length ? this.children.map((child)=>child.getActiveKeyMap()) : this.keyMapRetrievers.map((getKeyMap)=>getKeyMap().keyMap);
            return {
                ...$a65fecdbaed23de2$var$mergeKeyMaps(keyMaps)
            };
        }
        getKeyMapInfo() {
            const info = this.children.length ? this.children.map((child)=>child.getKeyMapInfo()) : this.keyMapRetrievers.map((getInfo)=>getInfo());
            const flattenedInfo = [].concat(...info);
            return flattenedInfo;
        }
        constructor(){
            this.children = [];
            this.keyMapRetrievers = [];
        }
    }
    const $a65fecdbaed23de2$export$1dd4c61d1ce1fa15 = new $a65fecdbaed23de2$export$ca1c75b575abf5ff();
});
parcelRegister("9fuVs", function(module, exports) {
    $parcel$export(module.exports, "ShortcutRegistryContext", ()=>$1dd87679d9ca363b$export$ebbb59327adb551a);
    $parcel$export(module.exports, "default", ()=>$1dd87679d9ca363b$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $k4r7E;
    const $1dd87679d9ca363b$export$ebbb59327adb551a = (0, $5uXOq.createContext)((0, (0, parcelRequire("k4r7E")).rootRegistry));
    const $1dd87679d9ca363b$var$ShortcutScope = ({ children: children = null })=>{
        const registry = (0, $5uXOq.useMemo)(()=>new (0, (0, parcelRequire("k4r7E")).ShortcutRegistry)(), []);
        const parentRegistry = (0, $5uXOq.useContext)($1dd87679d9ca363b$export$ebbb59327adb551a) || (0, (0, parcelRequire("k4r7E")).rootRegistry);
        (0, $5uXOq.useEffect)(()=>{
            const removeRegistry = parentRegistry.addChildRegistry(registry);
            return removeRegistry;
        }, []);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($1dd87679d9ca363b$export$ebbb59327adb551a.Provider, {
            value: registry
        }, children);
    };
    var $1dd87679d9ca363b$export$2e2bcd8739ae039 = false ? ({ children: children = null })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children) : $1dd87679d9ca363b$var$ShortcutScope;
});
parcelRegister("kspvB", function(module, exports) {
    $parcel$export(module.exports, "bindKeyMapWithErrorHandling", ()=>$f9e30ab2465f6f16$export$77477772a9b904da);
    var $dJl1T;
    const $f9e30ab2465f6f16$var$wrapCallbackWithErrorHandling = (key, callback, location = 'keyboard-shortcuts.common')=>(e)=>{
            try {
                callback(e);
            } catch (error) {
                (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData(location, `Error firing shortcut '${key}': ${error.message} (commandPaletteBlockNativeShortcutFF=true)`, error);
            }
        };
    const $f9e30ab2465f6f16$export$77477772a9b904da = (keyMap, location)=>Object.keys(keyMap).reduce((map, key)=>({
                ...map,
                [key]: {
                    ...keyMap[key],
                    callback: $f9e30ab2465f6f16$var$wrapCallbackWithErrorHandling(key, keyMap[key].callback, location)
                }
            }), {});
});
parcelRegister("eojy4", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$757f42b291a6a665$export$2e2bcd8739ae039);
    var $7loir;
    var $efKfR;
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Uumt = parcelRequire("8Uumt");
    var $6s1PB;
    var $7LYcV;
    var $b3DTW;
    var $FTaAj;
    var $7eqRV;
    var $5gJcp;
    var $iXVV6;
    var $g5X3G;
    var $dZw8H;
    var $kxZPA;
    var $2su1H;
    var $gtUFk;
    var $5NwJk;
    var $7OTah;
    var $isIOR;
    var $cqgcE;
    var $6Hhfn;
    var $6UAdF;
    var $a8lIB;
    var $9aomo;
    const $757f42b291a6a665$var$AsyncIssueAppComponent = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("jO7eF")), {
        ssr: false,
        moduleId: "./src/packages/issue/issue-view/src/views/issue-details/issue-app.tsx"
    });
    const $757f42b291a6a665$var$AsyncIssueAppUsingLoadingPhases = ({ isEmbedView: isEmbedView, ...rest })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7eqRV")).IssueBoundary), {
            packageName: "issue-navigator",
            isEmbedView: isEmbedView,
            render: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("b3DTW")).UnknownErrorView), null)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($757f42b291a6a665$var$AsyncIssueAppComponent, rest));
    const $757f42b291a6a665$var$issueCardChangeExcludedEventTypes = [
        (0, (0, parcelRequire("FTaAj")).ChangeEventTypes).FIELD_CHANGE_REQUESTED,
        (0, (0, parcelRequire("FTaAj")).ChangeEventTypes).FIELD_CHANGE_FAILED,
        (0, (0, parcelRequire("FTaAj")).ChangeEventTypes).CHILD_ISSUE_ADDED,
        (0, (0, parcelRequire("FTaAj")).ChangeEventTypes).ISSUE_CHILDREN_ORDER_CHANGED
    ];
    const $757f42b291a6a665$var$useJSCM1CompatibleActions = (connectionId, fieldSetIds)=>{
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
            const { deselectIssue: deselectIssue, exitFullPageIssueAppMode: exitFullPageIssueAppMode, setSelectedIssueByKey: setSelectedIssueByKey } = (0, (0, parcelRequire("7OTah")).useSelectedIssueActions)();
            const onDeleteIssue = (0, (0, parcelRequire("cqgcE")).useOnDeleteIssue)(connectionId);
            const onIssueByFieldsRefetch = (0, (0, parcelRequire("6Hhfn")).useIssueByFieldsRefetch)(fieldSetIds);
            return {
                deselectIssue: deselectIssue,
                exitFullPageIssueAppMode: exitFullPageIssueAppMode,
                setSelectedIssueByKey: setSelectedIssueByKey,
                onDeleteIssue: onDeleteIssue,
                onIssueByFieldsRefetch: onIssueByFieldsRefetch
            };
        }
        const { deselectIssue: deselectIssue, exitFullPageIssueAppMode: exitFullPageIssueAppMode, setSelectedIssueByKey: setSelectedIssueByKey } = (0, (0, parcelRequire("gtUFk")).useSelectedIssueStateOldActions)();
        const { onDeleteIssue: onDeleteIssue, onIssueByFieldsRefetch: onIssueByFieldsRefetch } = (0, (0, parcelRequire("isIOR")).useIssueSearchQuery)();
        return {
            deselectIssue: deselectIssue,
            exitFullPageIssueAppMode: exitFullPageIssueAppMode,
            setSelectedIssueByKey: setSelectedIssueByKey,
            onDeleteIssue: onDeleteIssue,
            onIssueByFieldsRefetch: onIssueByFieldsRefetch
        };
    };
    const $757f42b291a6a665$var$IssueAppWithData = ({ IssueApp: IssueApp = $757f42b291a6a665$var$AsyncIssueAppUsingLoadingPhases, extraHeaderActions: extraHeaderActions, isEmbedView: isEmbedView, issueResults: issueResults, view: view })=>{
        (0, (0, parcelRequire("a8lIB")).markOnce)((0, (0, parcelRequire("a8lIB")).marks).ISSUE_APP_START);
        (0, $5uXOq.useLayoutEffect)(()=>{
            (0, (0, parcelRequire("a8lIB")).markOnce)((0, (0, parcelRequire("a8lIB")).marks).ISSUE_APP_END);
        }, []);
        const selectedIssueKey = (0, (0, parcelRequire("5NwJk")).useSelectedIssueDebouncedState)();
        const issueResultsData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("efKfR")).default), issueResults);
        let fieldSetIds = [];
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
            const viewData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("7loir")).default), view);
            fieldSetIds = (0, $5uXOq.useMemo)(()=>viewData?.fieldSets?.edges?.map((edge)=>edge?.node?.fieldSetId).filter(Boolean) ?? [], [
                viewData?.fieldSets?.edges
            ]);
        }
        const { onInteractive: onInteractive } = (0, (0, parcelRequire("2su1H")).useStartNinIssueAppAnalytics)(selectedIssueKey);
        const issueAppMetrics = (0, $5uXOq.useMemo)(()=>({
                onInteractive: onInteractive
            }), [
            onInteractive
        ]);
        const { deselectIssue: deselectIssue, exitFullPageIssueAppMode: exitFullPageIssueAppMode, setSelectedIssueByKey: setSelectedIssueByKey, onDeleteIssue: onDeleteIssue, onIssueByFieldsRefetch: onIssueByFieldsRefetch } = $757f42b291a6a665$var$useJSCM1CompatibleActions(issueResultsData?.__id, fieldSetIds);
        const refetchCallback = (0, $5uXOq.useRef)(onIssueByFieldsRefetch);
        refetchCallback.current = onIssueByFieldsRefetch;
        const onIssueDeleteSuccess = (0, $5uXOq.useCallback)(({ issueKey: deletedIssueKey })=>{
            const issueKeyToSelect = onDeleteIssue(deletedIssueKey);
            if (!issueKeyToSelect.length) {
                deselectIssue();
                exitFullPageIssueAppMode();
            } else setSelectedIssueByKey((0, (0, parcelRequire("g5X3G")).toIssueKey)(issueKeyToSelect));
        }, [
            deselectIssue,
            exitFullPageIssueAppMode,
            onDeleteIssue,
            setSelectedIssueByKey
        ]);
        const onIssueKeyChange = (0, $5uXOq.useCallback)((issueKeys)=>{
            setSelectedIssueByKey(issueKeys.toIssueKey);
        }, [
            setSelectedIssueByKey
        ]);
        const maxWidth = !isEmbedView ? (0, (0, parcelRequire("5gJcp")).getIssueContainerMaxWidth)() : undefined;
        if (!(0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
            const [issueSelection] = (0, (0, parcelRequire("gtUFk")).useIssueSelectionState)();
            if (issueSelection === (0, (0, parcelRequire("gtUFk")).selectionState).BLANK_ISSUE_SELECTED) return (0, ($parcel$interopDefault($5uXOq))).createElement($757f42b291a6a665$var$IssueAppContainer, {
                isEmbedView: isEmbedView
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9aomo")).default), {
                issueResults: issueResultsData,
                extraHeaderActions: extraHeaderActions,
                isEmbedView: isEmbedView,
                maxWidth: maxWidth
            }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7LYcV")).PermissionErrorView), null));
        }
        if (!selectedIssueKey) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dZw8H")).SpaStatePageErrorReady), null);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($757f42b291a6a665$var$IssueAppContainer, {
            isEmbedView: isEmbedView
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9aomo")).default), {
            issueResults: issueResultsData,
            extraHeaderActions: extraHeaderActions,
            isEmbedView: isEmbedView,
            maxWidth: maxWidth
        }), (0, ($parcel$interopDefault($5uXOq))).createElement($757f42b291a6a665$var$IssueAppHeightAdjustment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            sourceName: "issueNavigatorEmbeddedIssueView",
            sourceType: (0, $4d5e0871c06cee03$export$f636b5b56f1d624a)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement(IssueApp, {
            metrics: issueAppMetrics,
            key: selectedIssueKey,
            isEmbedView: isEmbedView,
            analyticsSource: (0, (0, parcelRequire("6UAdF")).ANALYTICS_SOURCE),
            issueKey: (0, (0, parcelRequire("g5X3G")).toIssueKey)(selectedIssueKey),
            issueMaxWidth: maxWidth,
            onIssueKeyChange: onIssueKeyChange,
            onChange: (event)=>{
                if (event && !$757f42b291a6a665$var$issueCardChangeExcludedEventTypes.includes(event.type)) refetchCallback.current(String(selectedIssueKey));
            },
            onIssueDeleteSuccess: onIssueDeleteSuccess,
            shouldSetInitialFocus: false
        }))));
    };
    var $757f42b291a6a665$export$2e2bcd8739ae039 = $757f42b291a6a665$var$IssueAppWithData;
    const $757f42b291a6a665$var$IssueAppContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isEmbedView: isEmbedView, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_kqswh2mm _16jlkb7n _4t3i1osq _1ul9idpf _1e0c1txw _2lx21bp4 _vchhusvi",
                !__cmplp.isEmbedView && "_ca0qxy5q",
                __cmplp.className
            ])
        });
    });
    const $757f42b291a6a665$var$IssueAppHeightAdjustment = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_4t3i1osq _1tkeidpf",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("7loir", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$5cec674991979ea9$export$2e2bcd8739ae039);
    const $5cec674991979ea9$var$node = {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "amountOfColumns"
            }
        ],
        "kind": "Fragment",
        "name": "main_issueNavigator_IssueAppWithData_view",
        "selections": [
            {
                "args": [
                    {
                        "kind": "Literal",
                        "name": "filter",
                        "value": {
                            "fieldSetSelectedState": "SELECTED"
                        }
                    },
                    {
                        "kind": "Variable",
                        "name": "first",
                        "variableName": "amountOfColumns"
                    }
                ],
                "concreteType": "JiraIssueSearchFieldSetConnection",
                "kind": "LinkedField",
                "name": "fieldSets",
                "plural": false,
                "selections": [
                    {
                        "concreteType": "JiraIssueSearchFieldSetEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "concreteType": "JiraIssueSearchFieldSet",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "ScalarField",
                                        "name": "fieldSetId"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ],
        "type": "JiraIssueSearchView"
    };
    $5cec674991979ea9$var$node.hash = "ce25af81610df0649d4af4ae354822f1";
    var $5cec674991979ea9$export$2e2bcd8739ae039 = $5cec674991979ea9$var$node;
});
parcelRegister("efKfR", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$3e62e5ab28765e44$export$2e2bcd8739ae039);
    const $3e62e5ab28765e44$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_IssueAppWithData_issueResults",
        "selections": [
            {
                "kind": "FragmentSpread",
                "name": "headerActions_issueNavigator"
            },
            {
                "kind": "ClientExtension",
                "selections": [
                    {
                        "kind": "ScalarField",
                        "name": "__id"
                    }
                ]
            }
        ],
        "type": "JiraIssueConnection"
    };
    $3e62e5ab28765e44$var$node.hash = "267fadd3844f360bfc7e5d9f255f3ee7";
    var $3e62e5ab28765e44$export$2e2bcd8739ae039 = $3e62e5ab28765e44$var$node;
});
parcelRegister("7LYcV", function(module, exports) {
    $parcel$export(module.exports, "PermissionErrorView", ()=>$46330205bd9e339c$export$1bd3db7a0a8884d3);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $6s1PB;
    var $4R6GH;
    var $2JXc1;
    var $koVbs;
    var $f4P4a = parcelRequire("f4P4a");
    var $iEg2R;
    var $5FlWj;
    const $46330205bd9e339c$var$ErrorImage = ()=>(0, (0, parcelRequire("4R6GH")).fg)('move_issue_view_assets_into_central_location') ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2JXc1")).default), {
            width: 90,
            height: 130,
            alt: ""
        }) : (0, ($parcel$interopDefault($5uXOq))).createElement($46330205bd9e339c$var$Image, {
            src: (0, ($parcel$interopDefault($f4P4a))),
            alt: ""
        });
    const $46330205bd9e339c$export$1bd3db7a0a8884d3 = ()=>{
        const intl = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iEg2R")).ErrorDisplay), {
            header: intl.formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("5FlWj")).messages).permissionErrorHeaderIssueTermRefresh : (0, (0, parcelRequire("5FlWj")).messages).permissionErrorHeader),
            description: intl.formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("5FlWj")).messages).permissionErrorDescriptionIssueTermRefresh : (0, (0, parcelRequire("5FlWj")).messages).permissionErrorDescription),
            image: (0, ($parcel$interopDefault($5uXOq))).createElement($46330205bd9e339c$var$ErrorImage, null)
        });
    };
    const $46330205bd9e339c$var$Image = (0, $5uXOq.forwardRef)(({ as: C = "img", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_4t3i53f4 _1bsb10bg",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("2JXc1", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$c5d3fe7c3133ec07$export$2e2bcd8739ae039);
    var $dCLgq;
    var $cid60 = parcelRequire("cid60");
    var $6y2Qu = parcelRequire("6y2Qu");
    const $c5d3fe7c3133ec07$var$Illustration = (0, (0, parcelRequire("dCLgq")).createIllustration)({
        srcLight: ($parcel$interopDefault($6y2Qu)),
        srcDark: ($parcel$interopDefault($cid60))
    });
    var $c5d3fe7c3133ec07$export$2e2bcd8739ae039 = $c5d3fe7c3133ec07$var$Illustration;
    const $c5d3fe7c3133ec07$export$a179c88182cb9d56 = [
        0,
        0,
        191,
        222
    ];
});
parcelRegister("cid60", function(module, exports) {
    module.exports = new URL("lock-closed-darkmode.26d51607.svg", import.meta.url).toString();
});
parcelRegister("6y2Qu", function(module, exports) {
    module.exports = new URL("lock-closed.22dbfd6e.svg", import.meta.url).toString();
});
parcelRegister("f4P4a", function(module, exports) {
    module.exports = new URL("permission-error.da9ec123.svg", import.meta.url).toString();
});
parcelRegister("iEg2R", function(module, exports) {
    $parcel$export(module.exports, "ErrorDisplay", ()=>$42c3be45cf82ac6a$export$d56b0569bceb92ae);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    const $42c3be45cf82ac6a$export$d56b0569bceb92ae = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement($42c3be45cf82ac6a$var$Container, {
            "data-testid": "issue-view-errors.common.ui.error-display.permission-error-display"
        }, props.image, (0, ($parcel$interopDefault($5uXOq))).createElement($42c3be45cf82ac6a$var$Heading, null, props.header), (0, ($parcel$interopDefault($5uXOq))).createElement($42c3be45cf82ac6a$var$Description, null, props.description), props.children);
    $42c3be45cf82ac6a$export$d56b0569bceb92ae.defaultProps = {
        description: undefined,
        children: null
    };
    const $42c3be45cf82ac6a$var$modalDialogContainerSelectorName = 'jira.issue-view.issue-details.modal-dialog-container';
    const $42c3be45cf82ac6a$var$MODAL_DIALOG_CONTAINER_COMPONENT_SELECTOR = `[data-component-selector="${$42c3be45cf82ac6a$var$modalDialogContainerSelectorName}"]`;
    const $42c3be45cf82ac6a$var$Container = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_y3gn1h6o _2lx21bp4 _syaz1fxt _1e0c1txw _1bah1h6o _4cvr1h6o _ca0qxncg _17ipxncg _1ksgidpf",
                __cmplp.className
            ])
        });
    });
    const $42c3be45cf82ac6a$var$Heading = (0, $5uXOq.forwardRef)(({ as: C = "h2", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_11c8o0gd _19pkutpp _otyrutpp _p12f1osq",
                __cmplp.className
            ])
        });
    });
    const $42c3be45cf82ac6a$var$Description = (0, $5uXOq.forwardRef)(({ as: C = "p", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_p12f1osq",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("5FlWj", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$7e93cdae4f6f7e95$export$defe85febe8b728c);
    var $7VHMR;
    const $7e93cdae4f6f7e95$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        permissionErrorHeader: {
            "id": "issue-view-errors.permission-error-view.permission-error-header",
            "defaultMessage": "You don't have access to this issue"
        },
        permissionErrorDescription: {
            "id": "issue-view-errors.permission-error-view.permission-error-description",
            "defaultMessage": "Make sure the issue exists in this project. If it does, ask a project admin for permission to see the project's issues."
        },
        permissionErrorHeaderIssueTermRefresh: {
            "id": "issue-view-errors.permission-error-view.permission-error-header-issue-term-refresh",
            "defaultMessage": "You don't have access to this work item"
        },
        permissionErrorDescriptionIssueTermRefresh: {
            "id": "issue-view-errors.permission-error-view.permission-error-description-issue-term-refresh",
            "defaultMessage": "Make sure the work item exists in this project. If it does, ask a project admin for permission to see work in this project."
        }
    });
});
parcelRegister("b3DTW", function(module, exports) {
    $parcel$export(module.exports, "UnknownErrorView", ()=>$37e65e4dbd5a0c18$export$f23f26771420affd);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $6s1PB;
    var $4R6GH;
    var $bH03R;
    var $koVbs;
    var $7UZi2 = parcelRequire("7UZi2");
    var $iEg2R;
    var $1HGxj;
    const $37e65e4dbd5a0c18$var$ErrorImage = ()=>(0, (0, parcelRequire("4R6GH")).fg)('move_issue_view_assets_into_central_location') ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bH03R")).default), {
            width: 90,
            height: 130,
            alt: ""
        }) : (0, ($parcel$interopDefault($5uXOq))).createElement($37e65e4dbd5a0c18$var$Image, {
            src: (0, ($parcel$interopDefault($7UZi2))),
            alt: ""
        });
    const $37e65e4dbd5a0c18$export$f23f26771420affd = ()=>{
        const intl = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iEg2R")).ErrorDisplay), {
            header: intl.formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("1HGxj")).messages).unknownErrorHeaderIssueTermRefresh : (0, (0, parcelRequire("1HGxj")).messages).unknownErrorHeader),
            description: intl.formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("1HGxj")).messages).unknownErrorDescriptionIssueTermRefresh : (0, (0, parcelRequire("1HGxj")).messages).unknownErrorDescription),
            image: (0, ($parcel$interopDefault($5uXOq))).createElement($37e65e4dbd5a0c18$var$ErrorImage, null)
        });
    };
    const $37e65e4dbd5a0c18$var$Image = (0, $5uXOq.forwardRef)(({ as: C = "img", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_4t3i1j4a _1bsb68cl",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("bH03R", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$2a8185200722e377$export$2e2bcd8739ae039);
    var $dCLgq;
    var $6FRgI = parcelRequire("6FRgI");
    const $2a8185200722e377$var$Illustration = (0, (0, parcelRequire("dCLgq")).createIllustration)({
        srcLight: ($parcel$interopDefault($6FRgI)),
        srcDark: ($parcel$interopDefault($6FRgI))
    });
    var $2a8185200722e377$export$2e2bcd8739ae039 = $2a8185200722e377$var$Illustration;
    const $2a8185200722e377$export$a179c88182cb9d56 = [
        0,
        0,
        104,
        140
    ];
});
parcelRegister("6FRgI", function(module, exports) {
    module.exports = new URL("empty-search-error-colour.f0aa9e5e.svg", import.meta.url).toString();
});
parcelRegister("7UZi2", function(module, exports) {
    module.exports = new URL("unknown-error.af4eba12.svg", import.meta.url).toString();
});
parcelRegister("1HGxj", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$5157af2738c78f52$export$defe85febe8b728c);
    var $7VHMR;
    const $5157af2738c78f52$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        unknownErrorHeader: {
            "id": "issue-view-errors.unknown-error-view.unknown-error-header",
            "defaultMessage": "We couldn't connect to that issue"
        },
        unknownErrorDescription: {
            "id": "issue-view-errors.unknown-error-view.unknown-error-description",
            "defaultMessage": "Make sure that this issue actually exists in that project. If it does, try again in a few minutes. If you still can't link to the issue, contact your Jira admin."
        },
        unknownErrorHeaderIssueTermRefresh: {
            "id": "issue-view-errors.unknown-error-view.unknown-error-header-issue-term-refresh",
            "defaultMessage": "We couldn't connect to that work item."
        },
        unknownErrorDescriptionIssueTermRefresh: {
            "id": "issue-view-errors.unknown-error-view.unknown-error-description-issue-term-refresh",
            "defaultMessage": "Make sure that this work item actually exists in that project. If it does, try again in a few minutes. If you still can't link to the work item, contact your Jira admin."
        }
    });
});
parcelRegister("FTaAj", function(module, exports) {
    $parcel$export(module.exports, "ChangeEventTypes", ()=>$0a7f3a2a8450d0c8$export$8db21fc94823e1cc);
    const $0a7f3a2a8450d0c8$var$FIELD_CHANGE_REQUESTED = 'FIELD_CHANGE_REQUESTED';
    const $0a7f3a2a8450d0c8$var$FIELD_CHANGED = 'FIELD_CHANGED';
    const $0a7f3a2a8450d0c8$var$FIELD_CHANGE_FAILED = 'FIELD_CHANGE_FAILED';
    const $0a7f3a2a8450d0c8$var$ISSUE_TYPE_CHANGED = 'ISSUE_TYPE_CHANGED';
    const $0a7f3a2a8450d0c8$var$ISSUE_RELATIONSHIP_UPDATED = 'ISSUE_RELATIONSHIP_UPDATED';
    const $0a7f3a2a8450d0c8$var$ISSUE_LINKS_CREATED = 'ISSUE_LINKS_CREATED';
    const $0a7f3a2a8450d0c8$var$ISSUE_LINK_DELETED = 'ISSUE_LINK_DELETED';
    const $0a7f3a2a8450d0c8$var$CHILD_ISSUE_ADDED = 'CHILD_ISSUE_ADDED';
    const $0a7f3a2a8450d0c8$var$SUBTASK_ADDED = 'SUBTASK_ADDED';
    const $0a7f3a2a8450d0c8$var$ISSUE_CHILDREN_ORDER_CHANGED = 'ISSUE_CHILDREN_ORDER_CHANGED';
    const $0a7f3a2a8450d0c8$var$CHILD_ISSUE_FIELD_UPDATED = 'CHILD_ISSUE_FIELD_UPDATED';
    const $0a7f3a2a8450d0c8$var$CREATE_CONFLUENCE_PAGE_LINK_SUCCESS = 'CREATE_CONFLUENCE_PAGE_LINK_SUCCESS';
    const $0a7f3a2a8450d0c8$var$DELETE_CONFLUENCE_PAGE_LINK_SUCCESS = 'DELETE_CONFLUENCE_PAGE_LINK_SUCCESS';
    const $0a7f3a2a8450d0c8$export$8db21fc94823e1cc = {
        [$0a7f3a2a8450d0c8$var$FIELD_CHANGE_REQUESTED]: $0a7f3a2a8450d0c8$var$FIELD_CHANGE_REQUESTED,
        [$0a7f3a2a8450d0c8$var$FIELD_CHANGE_FAILED]: $0a7f3a2a8450d0c8$var$FIELD_CHANGE_FAILED,
        [$0a7f3a2a8450d0c8$var$FIELD_CHANGED]: $0a7f3a2a8450d0c8$var$FIELD_CHANGED,
        [$0a7f3a2a8450d0c8$var$ISSUE_TYPE_CHANGED]: $0a7f3a2a8450d0c8$var$ISSUE_TYPE_CHANGED,
        [$0a7f3a2a8450d0c8$var$ISSUE_RELATIONSHIP_UPDATED]: $0a7f3a2a8450d0c8$var$ISSUE_RELATIONSHIP_UPDATED,
        [$0a7f3a2a8450d0c8$var$ISSUE_LINKS_CREATED]: $0a7f3a2a8450d0c8$var$ISSUE_LINKS_CREATED,
        [$0a7f3a2a8450d0c8$var$ISSUE_LINK_DELETED]: $0a7f3a2a8450d0c8$var$ISSUE_LINK_DELETED,
        [$0a7f3a2a8450d0c8$var$CHILD_ISSUE_ADDED]: $0a7f3a2a8450d0c8$var$CHILD_ISSUE_ADDED,
        [$0a7f3a2a8450d0c8$var$SUBTASK_ADDED]: $0a7f3a2a8450d0c8$var$SUBTASK_ADDED,
        [$0a7f3a2a8450d0c8$var$ISSUE_CHILDREN_ORDER_CHANGED]: $0a7f3a2a8450d0c8$var$ISSUE_CHILDREN_ORDER_CHANGED,
        [$0a7f3a2a8450d0c8$var$CHILD_ISSUE_FIELD_UPDATED]: $0a7f3a2a8450d0c8$var$CHILD_ISSUE_FIELD_UPDATED,
        [$0a7f3a2a8450d0c8$var$CREATE_CONFLUENCE_PAGE_LINK_SUCCESS]: $0a7f3a2a8450d0c8$var$CREATE_CONFLUENCE_PAGE_LINK_SUCCESS,
        [$0a7f3a2a8450d0c8$var$DELETE_CONFLUENCE_PAGE_LINK_SUCCESS]: $0a7f3a2a8450d0c8$var$DELETE_CONFLUENCE_PAGE_LINK_SUCCESS
    };
});
parcelRegister("7eqRV", function(module, exports) {
    $parcel$export(module.exports, "IssueBoundary", ()=>$e3c8771effba3477$export$fbf48ede0cf52b5a);
    $parcel$export(module.exports, "IssueModalBoundary", ()=>$e3c8771effba3477$export$74d408982642a541);
    var $5uXOq = parcelRequire("5uXOq");
    var $heNNl;
    var $gjEtj;
    var $lfTZh;
    var $c65JB;
    const $e3c8771effba3477$export$fbf48ede0cf52b5a = ({ children: children, isEmbedView: isEmbedView = true, ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("heNNl")).default), {
            id: "issue",
            ...props
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "issue-boundary",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("c65JB")).IssueViewSkeleton), {
                isEmbedView: isEmbedView
            })
        }, children));
    const $e3c8771effba3477$export$74d408982642a541 = ({ children: children, ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gjEtj")).ModalBoundary), {
            id: "issue-modal",
            ...props
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "issue-modal-boundary",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("c65JB")).IssueViewSkeleton), {
                isModalView: true
            })
        }, children));
});
parcelRegister("gjEtj", function(module, exports) {
    $parcel$export(module.exports, "ModalBoundary", ()=>$c322f875acea1654$export$c3f500e812fb1bb3);
    var $5uXOq = parcelRequire("5uXOq");
    var $gAeCk;
    var $iqr2y;
    var $heNNl;
    var $lfTZh;
    const $c322f875acea1654$export$c3f500e812fb1bb3 = ({ children: children, id: id, metricKey: metricKey, packageName: packageName, onError: onError })=>{
        const ErrorState = (0, $5uXOq.useCallback)((args)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iqr2y")).ErrorFlagRenderer), {
                id: id,
                error: args.error
            }), [
            id
        ]);
        const fallback = typeof metricKey === 'string' && metricKey.length > 0 ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gAeCk")).default), {
            metricKey: metricKey
        }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("heNNl")).default), {
            id: id,
            packageName: packageName,
            render: ErrorState,
            onError: onError
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: id,
            fallback: fallback
        }, children));
    };
});
parcelRegister("5gJcp", function(module, exports) {
    $parcel$export(module.exports, "HEADER_BREADCRUMBS_BEGIN_MARK_KEY", ()=>$0350c967e71738f5$export$eb0ef5297b0cdc23);
    $parcel$export(module.exports, "HEADER_BREADCRUMBS_END_MARK_KEY", ()=>$0350c967e71738f5$export$9c0a7052eb43d49c);
    $parcel$export(module.exports, "HEADER_ACTIONS_BEGIN_MARK_KEY", ()=>$0350c967e71738f5$export$ff64d9f93169b1f0);
    $parcel$export(module.exports, "HEADER_ACTIONS_END_MARK_KEY", ()=>$0350c967e71738f5$export$e696e07345d019c7);
    $parcel$export(module.exports, "ISSUE_NAME_BEGIN_MARK_KEY", ()=>$0350c967e71738f5$export$39f2c7a88d6952c7);
    $parcel$export(module.exports, "ISSUE_NAME_END_MARK_KEY", ()=>$0350c967e71738f5$export$6b38db117e6a59c3);
    $parcel$export(module.exports, "QUICK_ADD_ITEMS_BEGIN_MARK_KEY", ()=>$0350c967e71738f5$export$e4ec603cd2ae64c3);
    $parcel$export(module.exports, "QUICK_ADD_ITEMS_END_MARK_KEY", ()=>$0350c967e71738f5$export$485e8138df2fade3);
    $parcel$export(module.exports, "CONTENT_ITEMS_BEGIN_MARK_KEY", ()=>$0350c967e71738f5$export$37c39403f63dfe50);
    $parcel$export(module.exports, "CONTENT_ITEMS_END_MARK_KEY", ()=>$0350c967e71738f5$export$bcac34901554ce05);
    $parcel$export(module.exports, "ACTIVITY_FEED_BEGIN_MARK_KEY", ()=>$0350c967e71738f5$export$cfd354167a2170f3);
    $parcel$export(module.exports, "ACTIVITY_FEED_END_MARK_KEY", ()=>$0350c967e71738f5$export$e20857c483438d46);
    $parcel$export(module.exports, "FOOTNOTE_BEGIN_MARK_KEY", ()=>$0350c967e71738f5$export$2f7e8d259a68175d);
    $parcel$export(module.exports, "FOOTNOTE_END_MARK_KEY", ()=>$0350c967e71738f5$export$8c4857e697bb9a6b);
    $parcel$export(module.exports, "ISSUE_STATUS_BEGIN_MARK_KEY", ()=>$0350c967e71738f5$export$6669013ba0004c0);
    $parcel$export(module.exports, "ISSUE_STATUS_END_MARK_KEY", ()=>$0350c967e71738f5$export$ff2ebe781d7bd44e);
    $parcel$export(module.exports, "defaultRatioRightColumn", ()=>$0350c967e71738f5$export$7fec582ac43ca9da);
    $parcel$export(module.exports, "getIssueContainerMaxWidth", ()=>$0350c967e71738f5$export$c76181843186885);
    const $0350c967e71738f5$export$eb0ef5297b0cdc23 = 'HEADER_BREADCRUMBS_BEGIN_MARK';
    const $0350c967e71738f5$export$9c0a7052eb43d49c = 'HEADER_BREADCRUMBS_END_MARK';
    const $0350c967e71738f5$export$ff64d9f93169b1f0 = 'HEADER_ACTIONS_BEGIN_MARK';
    const $0350c967e71738f5$export$e696e07345d019c7 = 'HEADER_ACTIONS_END_MARK';
    const $0350c967e71738f5$export$39f2c7a88d6952c7 = 'ISSUE_NAME_BEGIN_MARK';
    const $0350c967e71738f5$export$6b38db117e6a59c3 = 'ISSUE_NAME_END_MARK';
    const $0350c967e71738f5$export$e4ec603cd2ae64c3 = 'QUICK_ADD_ITEMS_BEGIN_MARK';
    const $0350c967e71738f5$export$485e8138df2fade3 = 'QUICK_ADD_ITEMS_END_MARK';
    const $0350c967e71738f5$export$37c39403f63dfe50 = 'CONTENT_ITEMS_BEGIN_MARK';
    const $0350c967e71738f5$export$bcac34901554ce05 = 'CONTENT_ITEMS_END_MARK';
    const $0350c967e71738f5$export$cfd354167a2170f3 = 'ACTIVITY_FEED_BEGIN_MARK';
    const $0350c967e71738f5$export$e20857c483438d46 = 'ACTIVITY_FEED_END_MARK';
    const $0350c967e71738f5$export$2f7e8d259a68175d = 'FOOTNOTE_BEGIN_MARK';
    const $0350c967e71738f5$export$8c4857e697bb9a6b = 'FOOTNOTE_END_MARK';
    const $0350c967e71738f5$export$6669013ba0004c0 = 'ISSUE_STATUS_BEGIN_MARK';
    const $0350c967e71738f5$export$ff2ebe781d7bd44e = 'ISSUE_STATUS_END_MARK';
    const $0350c967e71738f5$export$7fec582ac43ca9da = 0.35;
    const $0350c967e71738f5$export$c76181843186885 = ()=>1680;
});
parcelRegister("2su1H", function(module, exports) {
    $parcel$export(module.exports, "useStartNinIssueAppAnalytics", ()=>$58a286de31151ea6$export$39482b240f0b451f);
    var $5uXOq = parcelRequire("5uXOq");
    var $dGrdR;
    var $1XJ20;
    var $ih9zY;
    const $58a286de31151ea6$export$b9b395b52bdc3f4a = (0, (0, parcelRequire("dGrdR")).metrics).pageSegmentLoad({
        key: 'new-issue-navigator.issue-app'
    });
    const $58a286de31151ea6$export$39482b240f0b451f = (issueKey)=>{
        const [{ view: view }] = (0, (0, parcelRequire("ih9zY")).useSelectedViewState)();
        const previousIssueKey = (0, (0, parcelRequire("1XJ20")).usePrevious)(issueKey);
        if (issueKey !== previousIssueKey) $58a286de31151ea6$export$b9b395b52bdc3f4a.start();
        const onInteractive = (0, $5uXOq.useCallback)(()=>{
            $58a286de31151ea6$export$b9b395b52bdc3f4a.stop({
                customData: {
                    view: view
                }
            });
        }, [
            view
        ]);
        return {
            onInteractive: onInteractive
        };
    };
});
parcelRegister("cqgcE", function(module, exports) {
    $parcel$export(module.exports, "useOnDeleteIssue", ()=>$4b147560fdf5ea67$export$45e2b3c3de4980ed);
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $eiowN = parcelRequire("eiowN");
    const $4b147560fdf5ea67$var$getKeyForEdge = (edge)=>{
        const { NODE: NODE } = (0, $eiowN.ConnectionInterface).get();
        return edge?.getLinkedRecord(NODE)?.getValue('key');
    };
    const $4b147560fdf5ea67$var$getFirstEdgeCursor = (connectionRecord)=>{
        const { CURSOR: CURSOR, EDGES: EDGES } = (0, $eiowN.ConnectionInterface).get();
        const edges = connectionRecord.getLinkedRecords(EDGES) ?? [];
        const cursor = edges[0]?.getValue(CURSOR);
        return cursor ? String(cursor) : null;
    };
    const $4b147560fdf5ea67$var$getLastEdgeCursor = (connectionRecord)=>{
        const { CURSOR: CURSOR, EDGES: EDGES } = (0, $eiowN.ConnectionInterface).get();
        const edges = connectionRecord.getLinkedRecords(EDGES) ?? [];
        const cursor = edges[edges.length - 1]?.getValue(CURSOR);
        return cursor ? String(cursor) : null;
    };
    const $4b147560fdf5ea67$var$updatePageInfoCursor = (pageInfo, pageInfoField, replacementCursor, edgeToDelete)=>{
        if (!pageInfo || !replacementCursor) return;
        const { CURSOR: CURSOR } = (0, $eiowN.ConnectionInterface).get();
        const pageInfoCursor = pageInfo.getValue(pageInfoField);
        if (pageInfoCursor && pageInfoCursor === edgeToDelete.getValue(CURSOR)) pageInfo.setValue(replacementCursor, pageInfoField);
    };
    const $4b147560fdf5ea67$var$updatePageInfo = (connectionRecord, edgeToDelete)=>{
        const { START_CURSOR: START_CURSOR, END_CURSOR: END_CURSOR, PAGE_INFO: PAGE_INFO } = (0, $eiowN.ConnectionInterface).get();
        const pageInfo = connectionRecord.getLinkedRecord(PAGE_INFO);
        $4b147560fdf5ea67$var$updatePageInfoCursor(pageInfo, START_CURSOR, $4b147560fdf5ea67$var$getFirstEdgeCursor(connectionRecord), edgeToDelete);
        $4b147560fdf5ea67$var$updatePageInfoCursor(pageInfo, END_CURSOR, $4b147560fdf5ea67$var$getLastEdgeCursor(connectionRecord), edgeToDelete);
    };
    const $4b147560fdf5ea67$export$45e2b3c3de4980ed = (connectionId)=>{
        const environment = (0, $8Uumt.useRelayEnvironment)();
        return (0, $5uXOq.useCallback)((issueKeyToDelete)=>{
            let previousIssueKey;
            let nextIssueKey;
            (0, $eiowN.commitLocalUpdate)(environment, (store)=>{
                const connectionRecord = connectionId ? store.get(connectionId) : undefined;
                if (!connectionRecord) return;
                const { EDGES: EDGES } = (0, $eiowN.ConnectionInterface).get();
                const edges = connectionRecord.getLinkedRecords(EDGES) ?? [];
                const index = edges.findIndex((edge)=>$4b147560fdf5ea67$var$getKeyForEdge(edge) === issueKeyToDelete);
                if (index > -1) {
                    nextIssueKey = $4b147560fdf5ea67$var$getKeyForEdge(edges[index + 1]);
                    previousIssueKey = $4b147560fdf5ea67$var$getKeyForEdge(edges[index - 1]);
                    connectionRecord.setLinkedRecords(edges.slice(0, index).concat(edges.slice(index + 1)), EDGES);
                    $4b147560fdf5ea67$var$updatePageInfo(connectionRecord, edges[index]);
                    const totalCount = connectionRecord.getValue('totalIssueSearchResultCount');
                    if (typeof totalCount === 'number') connectionRecord.setValue(totalCount - 1, 'totalIssueSearchResultCount');
                }
            });
            return nextIssueKey ?? previousIssueKey ?? '';
        }, [
            environment,
            connectionId
        ]);
    };
});
parcelRegister("9aomo", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$341a198b9adf9a7a$export$2e2bcd8739ae039);
    var $bG6g7;
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $lD23V;
    var $8Uumt = parcelRequire("8Uumt");
    var $81Uw2;
    var $6s1PB;
    var $gtUFk;
    var $7OTah;
    var $b9kNb;
    const $341a198b9adf9a7a$var$HeaderActionsFallback = ({ extraHeaderActions: extraHeaderActions, isEmbedView: isEmbedView, maxWidth: maxWidth })=>extraHeaderActions ? (0, ($parcel$interopDefault($5uXOq))).createElement($341a198b9adf9a7a$var$HeaderActionsContainer, {
            maxWidth: maxWidth,
            isEmbedView: isEmbedView
        }, extraHeaderActions) : null;
    const $341a198b9adf9a7a$var$HeaderActions = (props)=>{
        const { extraHeaderActions: extraHeaderActions, issueResults: issueResults, maxWidth: maxWidth, isEmbedView: isEmbedView } = props;
        const issueResultsData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("bG6g7")).default), issueResults);
        let selectedIssuePosition;
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
            const selectedIssueIndex = (0, (0, parcelRequire("7OTah")).useSelectedIssueIndex)();
            if (selectedIssueIndex === -1 || issueResultsData == null) return (0, ($parcel$interopDefault($5uXOq))).createElement($341a198b9adf9a7a$var$HeaderActionsFallback, props);
        } else {
            const { getSelectedIssuePosition: getSelectedIssuePosition } = (0, (0, parcelRequire("gtUFk")).useSelectedIssueStateOldActions)();
            selectedIssuePosition = getSelectedIssuePosition();
            if (selectedIssuePosition == null || issueResultsData == null) return (0, ($parcel$interopDefault($5uXOq))).createElement($341a198b9adf9a7a$var$HeaderActionsFallback, props);
        }
        return (0, ($parcel$interopDefault($5uXOq))).createElement($341a198b9adf9a7a$var$HeaderActionsContainer, {
            maxWidth: maxWidth,
            isEmbedView: isEmbedView
        }, extraHeaderActions ?? null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("b9kNb")).default), {
            issueResults: issueResultsData,
            selectedIssuePosition: selectedIssuePosition
        }));
    };
    var $341a198b9adf9a7a$export$2e2bcd8739ae039 = (0, (0, parcelRequire("81Uw2")).withRecoverableErrorBoundary)($341a198b9adf9a7a$var$HeaderActions, {
        recoveryProp: 'issueResults',
        Fallback: $341a198b9adf9a7a$var$HeaderActionsFallback
    });
    const $341a198b9adf9a7a$var$HeaderActionsContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isEmbedView: isEmbedView, maxWidth: maxWidth, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: {
                ...__cmpls,
                "--_1et2rhb": (0, (0, parcelRequire("lD23V")).default)(__cmplp.maxWidth, "px")
            },
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_4cvr1h6o _1bahesu3 _19pkidpf _2hwx1wug _otyridpf _18u01wug _1e0c1txw _1bsb1osq _vchhusvi _10nju2gc _7zw6v47k",
                __cmplp.isEmbedView && "_n3tdu2gc",
                __cmplp.maxWidth && "_p12f1ayu",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("bG6g7", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$0dfb9eb4249d2403$export$2e2bcd8739ae039);
    const $0dfb9eb4249d2403$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "headerActions_issueNavigator",
        "selections": [
            {
                "kind": "FragmentSpread",
                "name": "issuePagination_issueNavigator"
            }
        ],
        "type": "JiraIssueConnection"
    };
    $0dfb9eb4249d2403$var$node.hash = "701bdde8a1a96783e68d573053dd8362";
    var $0dfb9eb4249d2403$export$2e2bcd8739ae039 = $0dfb9eb4249d2403$var$node;
});
parcelRegister("81Uw2", function(module, exports) {
    $parcel$export(module.exports, "withRecoverableErrorBoundary", ()=>$8612eca041c6f5de$export$c25a1c850f00ce39);
    var $5uXOq = parcelRequire("5uXOq");
    function $8612eca041c6f5de$export$c25a1c850f00ce39(WrappedComponent, { recoveryProp: recoveryProp, Fallback: Fallback }) {
        class RecoverableErrorBoundary extends (0, $5uXOq.Component) {
            componentDidUpdate(prevProps) {
                if (this.state.errorProps && prevProps[recoveryProp] !== this.props[recoveryProp]) this.setState({
                    errorProps: null
                });
            }
            componentDidCatch(error, errorInfo) {
                this.setState({
                    errorProps: {
                        error: error,
                        errorInfo: errorInfo
                    }
                });
            }
            render() {
                if (this.state.errorProps) return Fallback ? (0, ($parcel$interopDefault($5uXOq))).createElement(Fallback, {
                    ...this.props,
                    ...this.state.errorProps
                }) : null;
                return (0, ($parcel$interopDefault($5uXOq))).createElement(WrappedComponent, this.props);
            }
            constructor(...args){
                super(...args);
                this.state = {
                    errorProps: null
                };
            }
        }
        return RecoverableErrorBoundary;
    }
});
parcelRegister("b9kNb", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$3dc85153e8f22912$export$2e2bcd8739ae039);
    var $cwNnY;
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Uumt = parcelRequire("8Uumt");
    var $ldJSL;
    var $6s1PB;
    var $79uRg;
    var $hFOAl;
    var $68wbI;
    var $9psUt;
    const $3dc85153e8f22912$var$IssuePagination = ({ issueResults: issueResults, selectedIssuePosition: selectedIssuePosition })=>{
        const issueResultsData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("cwNnY")).default), issueResults);
        const { totalIssueSearchResultCount: totalIssueSearchResultCount, isCappingIssueSearchResult: isCappingIssueSearchResult } = issueResultsData;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, !(0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false) && (0, ($parcel$interopDefault($5uXOq))).createElement($3dc85153e8f22912$var$PaginationTextContainer, {
            "data-testid": "issue-pagination.pagination-text"
        }, totalIssueSearchResultCount <= 0 ? selectedIssuePosition : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("79uRg")).default), {
            prependMessage: (0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("hFOAl")).default).issueIndexTotalKnownIssueTermRefresh : (0, (0, parcelRequire("hFOAl")).default).issueIndexTotalKnown,
            prependMessageValues: {
                issueIndex: selectedIssuePosition
            },
            isCapped: isCappingIssueSearchResult ?? false,
            stableCount: totalIssueSearchResultCount
        })), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ldJSL")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9psUt")).default), {
            selectedIssuePosition: selectedIssuePosition
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("68wbI")).default), {
            totalResultCount: totalIssueSearchResultCount,
            selectedIssuePosition: selectedIssuePosition,
            isCappingIssueSearchResult: isCappingIssueSearchResult ?? false
        })));
    };
    var $3dc85153e8f22912$export$2e2bcd8739ae039 = $3dc85153e8f22912$var$IssuePagination;
    const $3dc85153e8f22912$var$PaginationTextContainer = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _4cvr1h6o",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("cwNnY", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$7240c8715a77c997$export$2e2bcd8739ae039);
    const $7240c8715a77c997$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "issuePagination_issueNavigator",
        "selections": [
            {
                "kind": "RequiredField",
                "field": {
                    "kind": "ScalarField",
                    "name": "totalIssueSearchResultCount"
                },
                "action": "THROW",
                "path": "totalIssueSearchResultCount"
            },
            {
                "kind": "ScalarField",
                "name": "isCappingIssueSearchResult"
            }
        ],
        "type": "JiraIssueConnection"
    };
    $7240c8715a77c997$var$node.hash = "252cad0134e2ce14b78595c597895b8f";
    var $7240c8715a77c997$export$2e2bcd8739ae039 = $7240c8715a77c997$var$node;
});
parcelRegister("79uRg", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$da78c4decc98820b$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $39xOx;
    var $koVbs;
    var $cR57N;
    const $da78c4decc98820b$var$TotalIssueCountWrapper = (props)=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "TotalIssueCount",
            packageName: "jiraIssueNavigator",
            render: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, formatMessage(props.prependMessage, {
                    ...props.prependMessageValues,
                    totalNumberIssues: props.isCapped ? `${props.stableCount}+` : props.stableCount
                })),
            sendToPrivacyUnsafeSplunk: true,
            teamName: "empanada"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cR57N")).default), props));
    };
    var $da78c4decc98820b$export$2e2bcd8739ae039 = $da78c4decc98820b$var$TotalIssueCountWrapper;
});
parcelRegister("cR57N", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$12abfe1ef23c73a0$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $7nnPp;
    var $dC5iT;
    var $eZJjh;
    var $3bDjY;
    var $6s1PB;
    var $koVbs;
    var $5oFIy;
    var $isIOR;
    var $38r5c;
    var $c3DxU;
    var $dZ9aY;
    const $12abfe1ef23c73a0$var$TotalIssueCount = ({ prependMessage: prependMessage, prependMessageValues: prependMessageValues, isCapped: isCapped, stableCount: stableCount })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        let totalIssueCount;
        let totalIssueCountIsFetching;
        let totalIssueCountIsError;
        let onFetchTotalIssueCount;
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
            totalIssueCount = (0, (0, parcelRequire("38r5c")).useTotalIssueCount)();
            totalIssueCountIsFetching = (0, (0, parcelRequire("38r5c")).useTotalIssueCountIsFetching)();
            totalIssueCountIsError = (0, (0, parcelRequire("38r5c")).useTotalIssueCountIsError)();
            ({ onFetchTotalIssueCount: onFetchTotalIssueCount } = (0, (0, parcelRequire("38r5c")).useIssueSearchActions)());
        } else {
            const { uncappedTotalIssueCount: uncappedTotalIssueCount, onFetchUncappedTotalIssueCount: onFetchUncappedTotalIssueCount } = (0, (0, parcelRequire("isIOR")).useIssueSearchQuery)();
            totalIssueCount = uncappedTotalIssueCount.count;
            totalIssueCountIsFetching = uncappedTotalIssueCount.isFetching;
            totalIssueCountIsError = uncappedTotalIssueCount.isError;
            onFetchTotalIssueCount = onFetchUncappedTotalIssueCount;
        }
        if (isCapped) {
            if (totalIssueCountIsError) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
                as: "span"
            }, formatMessage(prependMessage, {
                ...prependMessageValues,
                totalNumberIssues: `${stableCount}+`
            })), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
                position: "top",
                content: formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("dZ9aY")).default).errorTooltipTextIssueTermRefresh : (0, (0, parcelRequire("dZ9aY")).default).errorTooltipText)
            }, (tooltipProps)=>(0, ($parcel$interopDefault($5uXOq))).createElement($12abfe1ef23c73a0$var$ErrorIconContainer, {
                    ...tooltipProps,
                    "data-testid": "issue-navigator.common.ui.total-issue-count.error-icon"
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("7nnPp"))))), {
                    label: formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("dZ9aY")).default).errorIconLabelIssueTermRefresh : (0, (0, parcelRequire("dZ9aY")).default).errorIconLabel)
                }))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireUiAnalytics), {
                action: "viewed",
                actionSubject: "errorIcon",
                actionSubjectId: "totalIssueCount"
            }));
            if (totalIssueCountIsFetching) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
                as: "span"
            }, formatMessage(prependMessage, {
                ...prependMessageValues,
                totalNumberIssues: ''
            })), (0, ($parcel$interopDefault($5uXOq))).createElement($12abfe1ef23c73a0$var$SpinnerContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eZJjh")).default), {
                size: "small",
                testId: "issue-navigator.common.ui.total-issue-count.spinner"
            })));
            if (!totalIssueCount) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
                as: "span"
            }, formatMessage(prependMessage, {
                ...prependMessageValues,
                totalNumberIssues: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("c3DxU")).default), {
                    displayText: `${stableCount}+`,
                    onFetchCount: onFetchTotalIssueCount
                })
            }));
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
                as: "span"
            }, formatMessage(prependMessage, {
                ...prependMessageValues,
                totalNumberIssues: totalIssueCount
            }));
        }
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            as: "span"
        }, formatMessage(prependMessage, {
            ...prependMessageValues,
            totalNumberIssues: stableCount
        }));
    };
    var $12abfe1ef23c73a0$export$2e2bcd8739ae039 = (0, $5uXOq.memo)($12abfe1ef23c73a0$var$TotalIssueCount);
    const $12abfe1ef23c73a0$var$ErrorIconContainer = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1o8l _4t3i1tcg _syaz1rc1",
                __cmplp.className
            ])
        });
    });
    const $12abfe1ef23c73a0$var$SpinnerContainer = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1o8l _otyr1l7b _18u01y44",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("c3DxU", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$760aa3895a440d03$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $fVnbM;
    var $3bDjY;
    var $6s1PB;
    var $koVbs;
    var $8zOmE;
    var $dZ9aY;
    const $760aa3895a440d03$var$LoadUncappedCountButton = ({ onFetchCount: onFetchCount, displayText: displayText })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const fetchCount = (0, $5uXOq.useCallback)((event, analyticsEvent)=>{
            onFetchCount();
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'totalIssueCountButton');
        }, [
            onFetchCount
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
            content: formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("dZ9aY")).default).cappedCountTooltipIssueTermRefresh : (0, (0, parcelRequire("dZ9aY")).default).cappedCountTooltip),
            tag: "span"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            tabIndex: 0,
            appearance: "link",
            spacing: "none",
            onClick: fetchCount,
            "aria-label": formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("dZ9aY")).default).cappedCountTooltipIssueTermRefresh : (0, (0, parcelRequire("dZ9aY")).default).cappedCountTooltip),
            testId: "issue-navigator.common.ui.total-issue-count.load-uncapped-count-button.button"
        }, displayText));
    };
    var $760aa3895a440d03$export$2e2bcd8739ae039 = $760aa3895a440d03$var$LoadUncappedCountButton;
});
parcelRegister("dZ9aY", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$765e50af4a6c461b$export$2e2bcd8739ae039);
    var $7VHMR;
    var $765e50af4a6c461b$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        errorTooltipText: {
            "id": "issue-navigator.common.total-issue-count.error-tooltip-text",
            "defaultMessage": "We couldn\u2019t load the total number of issues. Refresh to try again."
        },
        errorIconLabel: {
            "id": "issue-navigator.common.total-issue-count.error-icon-label",
            "defaultMessage": "Error loading total issue count"
        },
        cappedCountTooltip: {
            "id": "issue-navigator.common.total-issue-count.capped-count-tooltip",
            "defaultMessage": "See the total number of issues."
        },
        errorTooltipTextIssueTermRefresh: {
            "id": "issue-navigator.common.total-issue-count.error-tooltip-text-issue-term-refresh",
            "defaultMessage": "We couldn\u2019t load the total number of work items. Refresh to try again."
        },
        errorIconLabelIssueTermRefresh: {
            "id": "issue-navigator.common.total-issue-count.error-icon-label-issue-term-refresh",
            "defaultMessage": "Error loading total work item count"
        },
        cappedCountTooltipIssueTermRefresh: {
            "id": "issue-navigator.common.total-issue-count.capped-count-tooltip-issue-term-refresh",
            "defaultMessage": "See the total number of work items."
        }
    });
});
parcelRegister("hFOAl", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$2dfb5e4bfcb2ceca$export$2e2bcd8739ae039);
    var $7VHMR;
    const $2dfb5e4bfcb2ceca$var$messages = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        issueIndexTotalKnown: {
            "id": "issue-navigator.common.issue-app.header-actions.issue-pagination.issue-index-total-known",
            "defaultMessage": "{issueIndex} of {totalNumberIssues}"
        },
        issueIndexTotalKnownIssueTermRefresh: {
            "id": "issue-navigator.common.issue-app.header-actions.issue-pagination.issue-index-total-known-issue-term-refresh",
            "defaultMessage": "{issueIndex} of {totalNumberIssues}"
        }
    });
    var $2dfb5e4bfcb2ceca$export$2e2bcd8739ae039 = $2dfb5e4bfcb2ceca$var$messages;
});
parcelRegister("68wbI", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$5b717c6c97877d28$export$2e2bcd8739ae039);
    var $bwpsq;
    var $5uXOq = parcelRequire("5uXOq");
    var $42CjB;
    var $c8Oei;
    var $3bDjY;
    var $jQtKF;
    var $6s1PB;
    var $koVbs;
    var $8zOmE;
    var $gtUFk;
    var $7OTah;
    var $2siHS;
    const $5b717c6c97877d28$var$TooltipTag = (0, $5uXOq.forwardRef)((props, ref)=>(0, ($parcel$interopDefault($5uXOq))).createElement($5b717c6c97877d28$var$StyledTooltipTag, {
            ref: ref,
            ...props
        }));
    const $5b717c6c97877d28$var$IssuePaginationNextButton = ({ selectedIssuePosition: selectedIssuePosition, totalResultCount: totalResultCount, isCappingIssueSearchResult: isCappingIssueSearchResult })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        let selectNextIssue;
        let issueKey;
        let isDisabled;
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
            const actions = (0, (0, parcelRequire("7OTah")).useSelectedIssueActions)();
            selectNextIssue = actions.selectNextIssue;
            issueKey = (0, (0, parcelRequire("7OTah")).useNextIssueKey)();
            isDisabled = !issueKey;
        } else {
            const actions = (0, (0, parcelRequire("gtUFk")).useSelectedIssueStateOldActions)();
            selectNextIssue = actions.selectNextIssue;
            issueKey = actions.getNextIssue().issueKey;
            isDisabled = selectedIssuePosition === totalResultCount;
        }
        const nextIssueMessage = issueKey ? formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("2siHS")).default).nextIssueTooltipIssueTermRefresh : (0, (0, parcelRequire("2siHS")).default).nextIssueTooltip, {
            issueKey: issueKey
        }) : formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("2siHS")).default).nextIssueWithoutKeyTooltipIssueTermRefresh : (0, (0, parcelRequire("2siHS")).default).nextIssueWithoutKeyTooltip);
        const onClick = (0, $5uXOq.useCallback)((_, analyticsEvent)=>{
            if (selectNextIssue()) (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'nextIssue');
        }, [
            selectNextIssue
        ]);
        if (isDisabled && isCappingIssueSearchResult) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
            content: formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("2siHS")).default).disabledButtonTooltipIssueTermRefresh : (0, (0, parcelRequire("2siHS")).default).disabledButtonTooltip),
            tag: $5b717c6c97877d28$var$TooltipTag
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jQtKF")).default), {
            tabIndex: 0,
            isDisabled: true,
            "data-testid": "issue-navigator.common.ui.issue-app.header-actions.issue-pagination.next-button.next-disabled-button"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("42CjB")).default), {
            icon: (0, ($parcel$interopDefault((0, parcelRequire("c8Oei"))))),
            label: formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("2siHS")).default).disabledButtonTooltipIssueTermRefresh : (0, (0, parcelRequire("2siHS")).default).disabledButtonTooltip),
            onClick: onClick,
            appearance: "subtle",
            testId: "issue-navigator.common.ui.issue-app.header-actions.issue-pagination.next-button",
            isDisabled: isDisabled,
            spacing: "compact"
        }), ")"));
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("42CjB")).default), {
            icon: (0, ($parcel$interopDefault((0, parcelRequire("c8Oei"))))),
            label: isDisabled ? formatMessage((0, (0, parcelRequire("2siHS")).default).disabledButtonLabel, {
                issueCount: totalResultCount
            }) : nextIssueMessage,
            onClick: onClick,
            appearance: "subtle",
            testId: "issue-navigator.common.ui.issue-app.header-actions.issue-pagination.next-button",
            isDisabled: isDisabled,
            spacing: "compact",
            isTooltipDisabled: isDisabled
        });
    };
    var $5b717c6c97877d28$export$2e2bcd8739ae039 = $5b717c6c97877d28$var$IssuePaginationNextButton;
    const $5b717c6c97877d28$var$StyledTooltipTag = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c116y",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("jQtKF", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$4dbfcf6b8255255f$export$2e2bcd8739ae039);
    var $47bDC = parcelRequire("47bDC");
    const $4dbfcf6b8255255f$var$DisabledButtonWithTooltip = (0, $47bDC.default).span.withConfig({
        componentId: "yhjexx-0"
    })({
        display: 'inline-flex'
    }, (props)=>props.isDisabled ? (0, $47bDC.css)({
            cursor: 'not-allowed',
            '> button': {
                pointerEvents: 'none'
            }
        }) : '');
    var $4dbfcf6b8255255f$export$2e2bcd8739ae039 = $4dbfcf6b8255255f$var$DisabledButtonWithTooltip;
});
parcelRegister("2siHS", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$b5ef87bb8421fa8e$export$2e2bcd8739ae039);
    var $7VHMR;
    const $b5ef87bb8421fa8e$var$messages = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        nextIssueTooltip: {
            "id": "issue-navigator.common.issue-app.header-actions.issue-pagination.next-button.next-issue-tooltip",
            "defaultMessage": "Next issue ''{issueKey}'' (Type 'j')"
        },
        nextIssueWithoutKeyTooltip: {
            "id": "issue-navigator.common.issue-app.header-actions.issue-pagination.next-button.next-issue-without-key-tooltip",
            "defaultMessage": "Next issue (Type 'j')"
        },
        disabledButtonTooltip: {
            "id": "issue-navigator.common.issue-app.header-actions.issue-pagination.next-button.disabled-button-tooltip",
            "defaultMessage": "We can\u2019t load more pages. Refine your search criteria to see more issues."
        },
        disabledButtonLabel: {
            "id": "issue-navigator.common.issue-app.header-actions.issue-pagination.next-button.disabled-button-label",
            "defaultMessage": "{issueCount} of {issueCount} issues, select previous issues to go back"
        },
        nextIssueTooltipIssueTermRefresh: {
            "id": "issue-navigator.common.issue-app.header-actions.issue-pagination.next-button.next-issue-tooltip-issue-term-refresh",
            "defaultMessage": "Next work item ''{issueKey}'' (Type 'j')"
        },
        nextIssueWithoutKeyTooltipIssueTermRefresh: {
            "id": "issue-navigator.common.issue-app.header-actions.issue-pagination.next-button.next-issue-without-key-tooltip-issue-term-refresh",
            "defaultMessage": "Next work item (Type 'j')"
        },
        disabledButtonTooltipIssueTermRefresh: {
            "id": "issue-navigator.common.issue-app.header-actions.issue-pagination.next-button.disabled-button-tooltip-issue-term-refresh",
            "defaultMessage": "We can\u2019t load more pages. Refine your search criteria to see more work items."
        }
    });
    var $b5ef87bb8421fa8e$export$2e2bcd8739ae039 = $b5ef87bb8421fa8e$var$messages;
});
parcelRegister("9psUt", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$ca6a8c917bf734bd$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $42CjB;
    var $6Pmud;
    var $6s1PB;
    var $koVbs;
    var $8zOmE;
    var $gtUFk;
    var $7OTah;
    var $iLFTD;
    const $ca6a8c917bf734bd$var$IssuePaginationPreviousButton = ({ selectedIssuePosition: selectedIssuePosition })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        let selectPreviousIssue;
        let issueKey;
        let isDisabled;
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
            const actions = (0, (0, parcelRequire("7OTah")).useSelectedIssueActions)();
            selectPreviousIssue = actions.selectPreviousIssue;
            issueKey = (0, (0, parcelRequire("7OTah")).usePreviousIssueKey)();
            isDisabled = !issueKey;
        } else {
            const actions = (0, (0, parcelRequire("gtUFk")).useSelectedIssueStateOldActions)();
            selectPreviousIssue = actions.selectPreviousIssue;
            issueKey = actions.getPreviousIssue().issueKey;
            isDisabled = selectedIssuePosition === 1;
        }
        const previousIssueMessage = issueKey ? formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("iLFTD")).default).previousIssueTooltipIssueTermRefresh : (0, (0, parcelRequire("iLFTD")).default).previousIssueTooltip, {
            issueKey: issueKey
        }) : formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("iLFTD")).default).previousIssueWithoutKeyTooltipIssueTermRefresh : (0, (0, parcelRequire("iLFTD")).default).previousIssueWithoutKeyTooltip);
        const onClick = (0, $5uXOq.useCallback)((_, analyticsEvent)=>{
            if (selectPreviousIssue()) (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'previousIssue');
        }, [
            selectPreviousIssue
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("42CjB")).default), {
            icon: (0, ($parcel$interopDefault((0, parcelRequire("6Pmud"))))),
            label: isDisabled ? formatMessage((0, (0, parcelRequire("iLFTD")).default).disabledButtonLabel) : previousIssueMessage,
            onClick: onClick,
            appearance: "subtle",
            testId: "issue-navigator.common.ui.issue-app.header-actions.issue-pagination.previous-button",
            isDisabled: isDisabled,
            spacing: "compact",
            isTooltipDisabled: isDisabled
        });
    };
    var $ca6a8c917bf734bd$export$2e2bcd8739ae039 = $ca6a8c917bf734bd$var$IssuePaginationPreviousButton;
});
parcelRegister("6Pmud", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $4f88f2d3e506ed12$var$_react = $4f88f2d3e506ed12$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $4f88f2d3e506ed12$var$_chevronUp = $4f88f2d3e506ed12$var$_interopRequireDefault((parcelRequire("jj3Gi")));
    function $4f88f2d3e506ed12$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $4f88f2d3e506ed12$var$ChevronUpIcon = (props)=>$4f88f2d3e506ed12$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><path fill="currentcolor" fill-rule="evenodd" d="m11.221 9.322-2.929 2.955a1.01 1.01 0 0 0 0 1.419.986.986 0 0 0 1.405 0l2.298-2.317 2.307 2.327a.99.99 0 0 0 1.407 0 1.01 1.01 0 0 0 0-1.419l-2.94-2.965A1.1 1.1 0 0 0 11.991 9c-.279 0-.557.107-.77.322"/></svg>`
        }, props, {
            newIcon: $4f88f2d3e506ed12$var$_chevronUp.default,
            iconType: "utility"
        }));
    $4f88f2d3e506ed12$var$ChevronUpIcon.displayName = 'ChevronUpIcon';
    var $4f88f2d3e506ed12$var$_default = module.exports.default = $4f88f2d3e506ed12$var$ChevronUpIcon;
});
parcelRegister("iLFTD", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$41497c8b4d0b6d68$export$2e2bcd8739ae039);
    var $7VHMR;
    const $41497c8b4d0b6d68$var$messages = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        previousIssueTooltip: {
            "id": "issue-navigator.common.issue-app.header-actions.issue-pagination.previous-button.previous-issue-tooltip",
            "defaultMessage": "Previous issue ''{issueKey}'' (Type 'k')"
        },
        previousIssueWithoutKeyTooltip: {
            "id": "issue-navigator.common.issue-app.header-actions.issue-pagination.previous-button.previous-issue-without-key-tooltip",
            "defaultMessage": "Previous issue (Type 'k')"
        },
        disabledButtonLabel: {
            "id": "issue-navigator.common.issue-app.header-actions.issue-pagination.previous-button.disabled-button-label",
            "defaultMessage": "No previous issue, select next issue"
        },
        previousIssueTooltipIssueTermRefresh: {
            "id": "issue-navigator.common.issue-app.header-actions.issue-pagination.previous-button.previous-issue-tooltip-issue-term-refresh",
            "defaultMessage": "Previous work item ''{issueKey}'' (Type 'k')"
        },
        previousIssueWithoutKeyTooltipIssueTermRefresh: {
            "id": "issue-navigator.common.issue-app.header-actions.issue-pagination.previous-button.previous-issue-without-key-tooltip-issue-term-refresh",
            "defaultMessage": "Previous work item (Type 'k')"
        }
    });
    var $41497c8b4d0b6d68$export$2e2bcd8739ae039 = $41497c8b4d0b6d68$var$messages;
});
parcelRegister("jO7eF", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("i5RRW"),
        $6fhBS("dsiAd"),
        $6fhBS("kWrFZ"),
        $6fhBS("kfXhT"),
        $6fhBS("2Lfdl"),
        $6fhBS("eNmuD"),
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("hPzOn"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("cggv6")
    ]).then(()=>parcelRequire('6qbjz'));
});
parcelRegister("5lE9L", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$752b8206000afac8$export$2e2bcd8739ae039);
    var $7VHMR;
    const $752b8206000afac8$var$messages = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        returnText: {
            "id": "issue-navigator.full-page-issue-app.return-text",
            "defaultMessage": "Return to search"
        }
    });
    var $752b8206000afac8$export$2e2bcd8739ae039 = $752b8206000afac8$var$messages;
});
parcelRegister("hWyif", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$eef80bc13fe78613$export$2e2bcd8739ae039);
    var $aPygs;
    var $9bW4Y;
    var $exNQa;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $gcl1l;
    var $imS60;
    var $6UAdF;
    var $jjbNu;
    var $1igGa;
    var $ih9zY;
    var $gwtSG;
    var $cKK2E;
    const $eef80bc13fe78613$var$getLastPage = (pageCursors)=>{
        if (pageCursors?.last) return pageCursors.last.pageNumber;
        if (pageCursors?.around?.length) return pageCursors.around[pageCursors.around.length - 1]?.pageNumber;
        return undefined;
    };
    const $eef80bc13fe78613$var$Header = ({ CustomHeader: CustomHeader, filter: filter, issueResults: issueResults, HeaderSkeletonImage: HeaderSkeletonImage, viewResult: viewResult })=>{
        const issueResultsData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("exNQa")).default), issueResults);
        const offset = (issueResultsData?.issueNavigatorPageInfo?.firstIssuePosition ?? 1) - 1;
        const pages = $eef80bc13fe78613$var$getLastPage(issueResultsData?.pageCursors) ?? 0;
        const total = issueResultsData?.totalIssueSearchResultCount ?? 0;
        const searchResultPageData = (0, $5uXOq.useMemo)(()=>({
                offset: offset,
                pages: pages,
                total: total
            }), [
            offset,
            pages,
            total
        ]);
        const { filterId: filterId } = (0, (0, parcelRequire("gwtSG")).useActiveJql)();
        const jql = (0, (0, parcelRequire("gwtSG")).useResolvedJql)();
        const { filterQueryIsFetching: filterQueryIsFetching } = (0, (0, parcelRequire("cKK2E")).useFilterQuery)();
        const filterData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("9bW4Y")).default), filter);
        const viewResultData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("aPygs")).default), viewResult);
        const filterDetails = (0, $5uXOq.useMemo)(()=>filterData ? {
                filterId: filterData.filterId,
                name: filterData.name,
                jql: filterData.jql,
                isFavourite: Boolean(filterData.isFavourite)
            } : undefined, [
            filterData
        ]);
        const [{ view: view }] = (0, (0, parcelRequire("ih9zY")).useSelectedViewState)();
        const HierarchyToggle = viewResultData && view !== 'detail' ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jjbNu")).HierarchyToggleItem), {
            viewResult: viewResultData
        }) : null;
        if (filterQueryIsFetching) return (0, ($parcel$interopDefault($5uXOq))).createElement(HeaderSkeletonImage, null);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("imS60")).default), {
            name: "header"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement(CustomHeader, {
            jql: jql,
            filterId: filterId,
            filterDetails: filterDetails,
            searchResultPageData: searchResultPageData,
            HierarchyToggle: HierarchyToggle
        }));
    };
    var $eef80bc13fe78613$export$2e2bcd8739ae039 = (0, (0, parcelRequire("1igGa")).withReportErrors)($eef80bc13fe78613$var$Header, {
        id: 'ui.header.unhandled',
        packageName: (0, (0, parcelRequire("6UAdF")).PACKAGE_NAME),
        teamName: (0, (0, parcelRequire("6UAdF")).TEAM_NAME),
        sendToPrivacyUnsafeSplunk: true,
        attributes: (0, (0, parcelRequire("gcl1l")).isLoaderErrorAttributes)
    });
});
parcelRegister("aPygs", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$926f4cfc73576864$export$2e2bcd8739ae039);
    const $926f4cfc73576864$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_Header_viewResult",
        "selections": [
            {
                "kind": "FragmentSpread",
                "name": "hierarchyToggle_issueNavigator_HierarchyToggleItem"
            }
        ],
        "type": "JiraIssueSearchView"
    };
    $926f4cfc73576864$var$node.hash = "3a995e6dfd4368af6efd0437b7e320e0";
    var $926f4cfc73576864$export$2e2bcd8739ae039 = $926f4cfc73576864$var$node;
});
parcelRegister("9bW4Y", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$28f411246a53b131$export$2e2bcd8739ae039);
    const $28f411246a53b131$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_Header_filter",
        "selections": [
            {
                "kind": "ScalarField",
                "name": "filterId"
            },
            {
                "kind": "ScalarField",
                "name": "name"
            },
            {
                "kind": "ScalarField",
                "name": "isFavourite"
            },
            {
                "kind": "ScalarField",
                "name": "jql"
            }
        ],
        "type": "JiraFilter",
        "abstractKey": "__isJiraFilter"
    };
    $28f411246a53b131$var$node.hash = "49ae72938f954e62114776575c662bd8";
    var $28f411246a53b131$export$2e2bcd8739ae039 = $28f411246a53b131$var$node;
});
parcelRegister("exNQa", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$049e4d6502bf1381$export$2e2bcd8739ae039);
    const $049e4d6502bf1381$var$node = function() {
        var v0 = [
            {
                "kind": "ScalarField",
                "name": "pageNumber"
            }
        ];
        return {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "name": "main_issueNavigator_Header_issueResults",
            "selections": [
                {
                    "concreteType": "JiraIssueNavigatorPageInfo",
                    "kind": "LinkedField",
                    "name": "issueNavigatorPageInfo",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "name": "firstIssuePosition"
                        }
                    ]
                },
                {
                    "args": [
                        {
                            "kind": "Literal",
                            "name": "maxCursors",
                            "value": 7
                        }
                    ],
                    "concreteType": "JiraPageCursors",
                    "kind": "LinkedField",
                    "name": "pageCursors",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraPageCursor",
                            "kind": "LinkedField",
                            "name": "around",
                            "plural": true,
                            "selections": v0
                        },
                        {
                            "concreteType": "JiraPageCursor",
                            "kind": "LinkedField",
                            "name": "last",
                            "plural": false,
                            "selections": v0
                        }
                    ],
                    "storageKey": "pageCursors(maxCursors:7)"
                },
                {
                    "kind": "ScalarField",
                    "name": "totalIssueSearchResultCount"
                }
            ],
            "type": "JiraIssueConnection"
        };
    }();
    $049e4d6502bf1381$var$node.hash = "a6845643ea9e8dc218ebb8df7988c3f7";
    var $049e4d6502bf1381$export$2e2bcd8739ae039 = $049e4d6502bf1381$var$node;
});
parcelRegister("jjbNu", function(module, exports) {
    $parcel$export(module.exports, "HierarchyToggleItem", ()=>$e1e12482daecc3c5$export$357080ffd90225cc);
    var $clHlv;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $imZsD;
    var $5Fk4Z;
    var $lx26o;
    var $bSg1p;
    const $e1e12482daecc3c5$export$357080ffd90225cc = ({ viewResult: viewResult })=>{
        const viewResultData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("clHlv")).default), viewResult);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("imZsD")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5Fk4Z")).default), {
            name: "hierarchy-feature-toggle"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lx26o")).HierarchyToggle), {
            viewResult: viewResultData
        })), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bSg1p")).HierarchyToggleOnboarding), null));
    };
});
parcelRegister("clHlv", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$12a7ed8434d3053c$export$2e2bcd8739ae039);
    const $12a7ed8434d3053c$var$node = {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider"
            },
            {
                "kind": "RootArgument",
                "name": "staticViewInput"
            }
        ],
        "kind": "Fragment",
        "name": "hierarchyToggle_issueNavigator_HierarchyToggleItem",
        "selections": [
            {
                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                    {
                        "args": [
                            {
                                "kind": "Variable",
                                "name": "staticViewInput",
                                "variableName": "staticViewInput"
                            }
                        ],
                        "kind": "FragmentSpread",
                        "name": "ui_issueTableHierarchyToggle_HierarchyToggle"
                    }
                ]
            }
        ],
        "type": "JiraIssueSearchView"
    };
    $12a7ed8434d3053c$var$node.hash = "963561a968d3c37206d8a42b2396fc1f";
    var $12a7ed8434d3053c$export$2e2bcd8739ae039 = $12a7ed8434d3053c$var$node;
});
parcelRegister("lx26o", function(module, exports) {
    $parcel$export(module.exports, "HierarchyToggle", ()=>$018b012745a5c896$export$3954d500c5aed66);
    var $5uXOq = parcelRequire("5uXOq");
    var $39xOx;
    var $4hYz1;
    const $018b012745a5c896$export$3954d500c5aed66 = (0, $5uXOq.memo)((props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            fallback: "unmount",
            id: "issue-table-hierarchy-toggle",
            packageName: (0, $bb4c54ee542a60ec$export$b8b9d90f9e5bd72b),
            teamName: (0, $bb4c54ee542a60ec$export$d57b6b390e78d90f)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4hYz1")).HierarchyToggle), props)));
});
const $bb4c54ee542a60ec$export$b8b9d90f9e5bd72b = 'jiraIssueTableHierarchyToggle';
const $bb4c54ee542a60ec$export$d57b6b390e78d90f = 'empanada';
parcelRegister("4hYz1", function(module, exports) {
    $parcel$export(module.exports, "HierarchyToggle", ()=>$2ecf2b723bc56fca$export$3954d500c5aed66);
    var $BSGGQ;
    var $il1iJ;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $f5TWP;
    var $dTlW6;
    var $koVbs;
    var $8zOmE;
    var $2NMNM;
    var $5Lp4g;
    const $2ecf2b723bc56fca$export$3954d500c5aed66 = ({ viewResult: viewResult, forwardRef: forwardRef })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const viewResultData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("il1iJ")).default), viewResult);
        const { viewConfigSettings: viewConfigSettings, id: id, viewId: viewId } = viewResultData;
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const toggleIssueHierarchy = $2ecf2b723bc56fca$export$fb4258c5b554d925();
        const { isHierarchyEnabled: isHierarchyEnabled, canEnableHierarchy: canEnableHierarchy, __id: __id } = viewConfigSettings ?? {};
        const onChange = (0, $5uXOq.useCallback)(()=>{
            if (id && __id) {
                (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({}), 'hierarchyToggle clicked', {
                    isHierarchyEnabled: !isHierarchyEnabled
                });
                toggleIssueHierarchy(id, __id, !isHierarchyEnabled);
            }
        }, [
            createAnalyticsEvent,
            isHierarchyEnabled,
            toggleIssueHierarchy,
            id,
            __id
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("f5TWP")).default), {
            spread: "space-between",
            alignBlock: "center"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("label", {
            htmlFor: `${viewId}_hierarchy_toggle`
        }, formatMessage((0, (0, parcelRequire("5Lp4g")).default).showHierarchy)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dTlW6")).default), {
            isDisabled: !canEnableHierarchy,
            isChecked: Boolean(isHierarchyEnabled && canEnableHierarchy),
            id: `${viewId}_hierarchy_toggle`,
            ref: forwardRef,
            onChange: onChange
        }));
    };
    const $2ecf2b723bc56fca$export$fb4258c5b554d925 = ()=>{
        const [commit] = (0, $8Uumt.useMutation)((0, (0, parcelRequire("BSGGQ")).default));
        const updateHierarchy = (0, $5uXOq.useCallback)((issueSearchViewResultAri, issueSearchViewResultRelayId, isHierarchyEnabled)=>{
            commit({
                variables: {
                    viewId: issueSearchViewResultAri,
                    isHierarchyEnabled: isHierarchyEnabled
                },
                updater (store, data) {
                    if (data?.jira?.updateIssueSearchHierarchyPreference?.success) {
                        const issueSearchViewResult = store.get(issueSearchViewResultRelayId);
                        issueSearchViewResult?.setValue(isHierarchyEnabled, 'isHierarchyEnabled');
                    }
                }
            });
        }, [
            commit
        ]);
        return updateHierarchy;
    };
});
parcelRegister("BSGGQ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$348ab9fdc65ea106$export$2e2bcd8739ae039);
    const $348ab9fdc65ea106$var$node = function() {
        var v0 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "isHierarchyEnabled"
        }, v1 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "viewId"
        }, v2 = [
            {
                "concreteType": "JiraMutation",
                "kind": "LinkedField",
                "name": "jira",
                "plural": false,
                "selections": [
                    {
                        "args": [
                            {
                                "kind": "Variable",
                                "name": "isHierarchyEnabled",
                                "variableName": "isHierarchyEnabled"
                            },
                            {
                                "kind": "Variable",
                                "name": "viewId",
                                "variableName": "viewId"
                            }
                        ],
                        "concreteType": "JiraIssueSearchHierarchyPreferenceMutationPayload",
                        "kind": "LinkedField",
                        "name": "updateIssueSearchHierarchyPreference",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "name": "success"
                            }
                        ]
                    }
                ]
            }
        ];
        return {
            "fragment": {
                "argumentDefinitions": [
                    v0,
                    v1
                ],
                "kind": "Fragment",
                "name": "ui_issueTableHierarchyToggle_HierarchyToggleMutation",
                "selections": v2,
                "type": "Mutation"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": [
                    v1,
                    v0
                ],
                "kind": "Operation",
                "name": "ui_issueTableHierarchyToggle_HierarchyToggleMutation",
                "selections": v2
            },
            "params": {
                "id": "d0399e1dcc5bef453e30083f43581d3c86d60aab8f1a485736e24c3b95e46665",
                "metadata": {},
                "name": "ui_issueTableHierarchyToggle_HierarchyToggleMutation",
                "operationKind": "mutation",
                "text": null
            }
        };
    }();
    $348ab9fdc65ea106$var$node.hash = "caf43b06bb046e13cf83843bc009eeea";
    var $348ab9fdc65ea106$export$2e2bcd8739ae039 = $348ab9fdc65ea106$var$node;
});
parcelRegister("il1iJ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$e3875b3b3287d94c$export$2e2bcd8739ae039);
    const $e3875b3b3287d94c$var$node = {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "staticViewInput"
            }
        ],
        "kind": "Fragment",
        "name": "ui_issueTableHierarchyToggle_HierarchyToggle",
        "selections": [
            {
                "kind": "ScalarField",
                "name": "id"
            },
            {
                "kind": "ScalarField",
                "name": "viewId"
            },
            {
                "args": [
                    {
                        "kind": "Variable",
                        "name": "staticViewInput",
                        "variableName": "staticViewInput"
                    }
                ],
                "concreteType": "JiraIssueSearchViewConfigSettings",
                "kind": "LinkedField",
                "name": "viewConfigSettings",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "name": "isHierarchyEnabled"
                    },
                    {
                        "kind": "ScalarField",
                        "name": "canEnableHierarchy"
                    },
                    {
                        "kind": "ClientExtension",
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "name": "__id"
                            }
                        ]
                    }
                ]
            }
        ],
        "type": "JiraIssueSearchView"
    };
    $e3875b3b3287d94c$var$node.hash = "a90a5d974c65ce1f9f6a8252c6cdb107";
    var $e3875b3b3287d94c$export$2e2bcd8739ae039 = $e3875b3b3287d94c$var$node;
});
parcelRegister("dTlW6", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$3db77843cf29c460$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $f8Sb3;
    var $6QvkM;
    var $eGmKJ;
    var $9KnKW;
    var $7fA4P;
    var $aFYTH;
    var $lahQ6;
    var $hzG2v;
    const $3db77843cf29c460$var$noop = (0, (0, parcelRequire("eGmKJ")).default);
    const $3db77843cf29c460$var$analyticsAttributes = {
        componentName: 'toggle',
        packageName: "platform-pkg",
        packageVersion: "0.0.0-use.local"
    };
    const $3db77843cf29c460$var$iconSizeMap = {
        regular: 'small',
        large: 'medium'
    };
    const $3db77843cf29c460$var$Toggle = (0, $5uXOq.memo)((0, $5uXOq.forwardRef)((props, ref)=>{
        const { defaultChecked: defaultChecked = false, isDisabled: isDisabled = false, onBlur: providedOnBlur = $3db77843cf29c460$var$noop, onChange: providedChange = $3db77843cf29c460$var$noop, onFocus: providedFocus = $3db77843cf29c460$var$noop, size: size = 'regular', name: name = '', value: value = '', isChecked: isChecked, analyticsContext: analyticsContext, id: id, testId: testId, label: label, descriptionId: descriptionId } = props;
        const isControlled = typeof isChecked === 'undefined';
        const [checked, setChecked] = (0, $5uXOq.useState)(defaultChecked);
        const handleBlur = (0, (0, parcelRequire("6QvkM")).usePlatformLeafEventHandler)({
            fn: providedOnBlur,
            action: 'blur',
            analyticsData: analyticsContext,
            ...$3db77843cf29c460$var$analyticsAttributes
        });
        const handleFocus = (0, (0, parcelRequire("6QvkM")).usePlatformLeafEventHandler)({
            fn: providedFocus,
            action: 'focus',
            analyticsData: analyticsContext,
            ...$3db77843cf29c460$var$analyticsAttributes
        });
        const handleChange = (0, (0, parcelRequire("6QvkM")).usePlatformLeafEventHandler)({
            fn: (event, analyticsEvent)=>{
                if (isControlled) setChecked((checked)=>!checked);
                return providedChange(event, analyticsEvent);
            },
            action: 'change',
            analyticsData: analyticsContext,
            ...$3db77843cf29c460$var$analyticsAttributes
        });
        const shouldChecked = isControlled ? checked : isChecked;
        const controlProps = {
            'data-checked': shouldChecked ? shouldChecked : undefined,
            'data-disabled': isDisabled ? isDisabled : undefined,
            'data-size': size,
            'data-testid': testId ? testId : undefined
        };
        const toggleStyles = (0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("hzG2v")).getStyles)(size), [
            size
        ]);
        const legacyIconSize = $3db77843cf29c460$var$iconSizeMap[size];
        const labelId = (0, (0, parcelRequire("9KnKW")).useId)();
        return ((0, (0, parcelRequire("f8Sb3")).jsx)("label", {
            ...controlProps,
            css: toggleStyles
        }, label ? (0, (0, parcelRequire("f8Sb3")).jsx)("span", {
            id: labelId,
            hidden: true
        }, label) : null, (0, (0, parcelRequire("f8Sb3")).jsx)("input", {
            ref: ref,
            checked: shouldChecked,
            disabled: isDisabled,
            id: id,
            name: name,
            onBlur: handleBlur,
            onChange: handleChange,
            onFocus: handleFocus,
            type: "checkbox",
            value: value,
            "data-testid": testId && `${testId}--input`,
            "aria-labelledby": label ? labelId : undefined,
            "aria-describedby": descriptionId
        }), (0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("lahQ6")).default), {
            size: size,
            isHidden: !shouldChecked,
            position: "left"
        }, (0, (0, parcelRequire("f8Sb3")).jsx)((0, ($parcel$interopDefault((0, parcelRequire("7fA4P"))))), {
            label: "",
            color: "currentColor",
            LEGACY_size: legacyIconSize,
            testId: testId && `${testId}--toggle-check-icon`
        })), (0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("lahQ6")).default), {
            size: size,
            isHidden: shouldChecked,
            position: "right"
        }, (0, (0, parcelRequire("f8Sb3")).jsx)((0, ($parcel$interopDefault((0, parcelRequire("aFYTH"))))), {
            label: "",
            color: "currentColor",
            LEGACY_size: legacyIconSize,
            testId: testId && `${testId}--toggle-cross-icon`
        }))));
    }));
    $3db77843cf29c460$var$Toggle.displayName = 'Toggle';
    var $3db77843cf29c460$export$2e2bcd8739ae039 = $3db77843cf29c460$var$Toggle;
});
parcelRegister("7fA4P", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $5475eb3f94e0b044$var$_react = $5475eb3f94e0b044$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $5475eb3f94e0b044$var$_checkMark = $5475eb3f94e0b044$var$_interopRequireDefault((parcelRequire("f22iM")));
    var $5475eb3f94e0b044$var$_done = $5475eb3f94e0b044$var$_interopRequireDefault((parcelRequire("4DXCl")));
    function $5475eb3f94e0b044$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $5475eb3f94e0b044$var$CheckMarkIcon = (props)=>$5475eb3f94e0b044$var$_react.default.createElement($5475eb3f94e0b044$var$_checkMark.default, Object.assign({
            LEGACY_fallbackIcon: $5475eb3f94e0b044$var$_done.default
        }, props));
    $5475eb3f94e0b044$var$CheckMarkIcon.Name = 'CheckMarkIconMigration';
    var $5475eb3f94e0b044$var$_default = module.exports.default = $5475eb3f94e0b044$var$CheckMarkIcon;
});
parcelRegister("f22iM", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $af18d40ba5b744b4$var$_react = $af18d40ba5b744b4$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $af18d40ba5b744b4$var$_UNSAFE_baseNew = $af18d40ba5b744b4$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $af18d40ba5b744b4$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $af18d40ba5b744b4$var$CheckMarkIcon = (props)=>$af18d40ba5b744b4$var$_react.default.createElement($af18d40ba5b744b4$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-linejoin="round" stroke-width="1.5" d="m1.5 6 2.75 3.5 6.25-7"/>`,
            type: 'utility'
        }, props));
    $af18d40ba5b744b4$var$CheckMarkIcon.displayName = 'CheckMarkIcon';
    var $af18d40ba5b744b4$var$_default = module.exports.default = $af18d40ba5b744b4$var$CheckMarkIcon;
});
parcelRegister("4DXCl", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $36190cbd288fec69$var$_react = $36190cbd288fec69$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $36190cbd288fec69$var$_checkMark = $36190cbd288fec69$var$_interopRequireDefault((parcelRequire("bjo8S")));
    function $36190cbd288fec69$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $36190cbd288fec69$var$EditorDoneIcon = (props)=>$36190cbd288fec69$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><path fill="currentcolor" d="M7.356 10.942a.497.497 0 0 0-.713 0l-.7.701a.5.5 0 0 0-.003.71l3.706 3.707a.5.5 0 0 0 .705.003l7.712-7.712a.493.493 0 0 0-.006-.708l-.7-.7a.504.504 0 0 0-.714 0l-6.286 6.286a.506.506 0 0 1-.713 0z"/></svg>`
        }, props, {
            newIcon: $36190cbd288fec69$var$_checkMark.default
        }));
    $36190cbd288fec69$var$EditorDoneIcon.displayName = 'EditorDoneIcon';
    var $36190cbd288fec69$var$_default = module.exports.default = $36190cbd288fec69$var$EditorDoneIcon;
});
parcelRegister("bjo8S", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $83c43234bc70a485$var$_react = $83c43234bc70a485$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $83c43234bc70a485$var$_UNSAFE_baseNew = $83c43234bc70a485$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $83c43234bc70a485$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $83c43234bc70a485$var$CheckMarkIcon = (props)=>$83c43234bc70a485$var$_react.default.createElement($83c43234bc70a485$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-linejoin="round" stroke-width="1.5" d="m1.5 8 4.25 5.25 8.75-10.5"/>`
        }, props));
    $83c43234bc70a485$var$CheckMarkIcon.displayName = 'CheckMarkIcon';
    var $83c43234bc70a485$var$_default = module.exports.default = $83c43234bc70a485$var$CheckMarkIcon;
});
parcelRegister("aFYTH", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $7c5cf8a572bea882$var$_react = $7c5cf8a572bea882$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $7c5cf8a572bea882$var$_cross = $7c5cf8a572bea882$var$_interopRequireDefault((parcelRequire("bjGSs")));
    var $7c5cf8a572bea882$var$_close = $7c5cf8a572bea882$var$_interopRequireDefault((parcelRequire("2C7j9")));
    function $7c5cf8a572bea882$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $7c5cf8a572bea882$var$CrossIcon = (props)=>$7c5cf8a572bea882$var$_react.default.createElement($7c5cf8a572bea882$var$_cross.default, Object.assign({
            LEGACY_fallbackIcon: $7c5cf8a572bea882$var$_close.default
        }, props));
    $7c5cf8a572bea882$var$CrossIcon.Name = 'CrossIconMigration';
    var $7c5cf8a572bea882$var$_default = module.exports.default = $7c5cf8a572bea882$var$CrossIcon;
});
parcelRegister("bjGSs", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $83d2ba975692c1a3$var$_react = $83d2ba975692c1a3$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $83d2ba975692c1a3$var$_UNSAFE_baseNew = $83d2ba975692c1a3$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $83d2ba975692c1a3$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $83d2ba975692c1a3$var$CrossIcon = (props)=>$83d2ba975692c1a3$var$_react.default.createElement($83d2ba975692c1a3$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-linejoin="round" stroke-width="1.5" d="m2 10 4-4m4-4L6 6m0 0L2 2m4 4 4 4"/>`,
            type: 'utility'
        }, props));
    $83d2ba975692c1a3$var$CrossIcon.displayName = 'CrossIcon';
    var $83d2ba975692c1a3$var$_default = module.exports.default = $83d2ba975692c1a3$var$CrossIcon;
});
parcelRegister("2C7j9", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $1e74cc92a54dd799$var$_react = $1e74cc92a54dd799$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $1e74cc92a54dd799$var$_close = $1e74cc92a54dd799$var$_interopRequireDefault((parcelRequire("i28qQ")));
    function $1e74cc92a54dd799$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $1e74cc92a54dd799$var$EditorCloseIcon = (props)=>$1e74cc92a54dd799$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><path fill="currentcolor" fill-rule="evenodd" d="m15.185 7.4-3.184 3.185-3.186-3.186a.507.507 0 0 0-.712.003l-.7.701a.496.496 0 0 0-.004.712l3.185 3.184L7.4 15.185a.507.507 0 0 0 .004.712l.7.7c.206.207.516.2.712.004l3.186-3.185 3.184 3.185a.507.507 0 0 0 .712-.004l.701-.7a.496.496 0 0 0 .003-.712l-3.186-3.186 3.186-3.184a.507.507 0 0 0-.003-.712l-.7-.7a.5.5 0 0 0-.36-.153.5.5 0 0 0-.353.15"/></svg>`
        }, props, {
            newIcon: $1e74cc92a54dd799$var$_close.default
        }));
    $1e74cc92a54dd799$var$EditorCloseIcon.displayName = 'EditorCloseIcon';
    var $1e74cc92a54dd799$var$_default = module.exports.default = $1e74cc92a54dd799$var$EditorCloseIcon;
});
parcelRegister("lahQ6", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$ca1cdaa1eb34599d$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $VSDnB;
    var $dC5iT;
    var $8CUq3;
    const $ca1cdaa1eb34599d$var$iconContainerStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'opacity 0.2s ease',
        position: 'absolute',
        insetBlockStart: 'space.025'
    });
    const $ca1cdaa1eb34599d$var$iconContainerRegularStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        width: '16px',
        height: '16px'
    });
    const $ca1cdaa1eb34599d$var$iconContainerLargeStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        width: '20px',
        height: '20px'
    });
    const $ca1cdaa1eb34599d$var$hiddenStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        opacity: 0
    });
    const $ca1cdaa1eb34599d$var$leftStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        insetInlineStart: '3px'
    });
    const $ca1cdaa1eb34599d$var$rightStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        insetInlineEnd: '3px'
    });
    const $ca1cdaa1eb34599d$var$IconContainer = ({ children: children, size: size, isHidden: isHidden, position: position })=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            as: "span",
            xcss: [
                $ca1cdaa1eb34599d$var$iconContainerStyles,
                isHidden && (0, (0, parcelRequire("VSDnB")).fg)('platform-visual-refresh-icons') && $ca1cdaa1eb34599d$var$hiddenStyles,
                size === 'regular' && $ca1cdaa1eb34599d$var$iconContainerRegularStyles,
                size === 'large' && $ca1cdaa1eb34599d$var$iconContainerLargeStyles,
                position === 'left' && $ca1cdaa1eb34599d$var$leftStyles,
                position === 'right' && $ca1cdaa1eb34599d$var$rightStyles
            ]
        }, children);
    };
    var $ca1cdaa1eb34599d$export$2e2bcd8739ae039 = $ca1cdaa1eb34599d$var$IconContainer;
});
parcelRegister("hzG2v", function(module, exports) {
    $parcel$export(module.exports, "getStyles", ()=>$3af6a5b9114f4fd0$export$ffd58b7f6f099a57);
    var $f8Sb3;
    var $VSDnB;
    var $k5uoT;
    var $3VjLl;
    const $3af6a5b9114f4fd0$var$globalGridSize = (0, (0, parcelRequire("k5uoT")).gridSize)();
    const $3af6a5b9114f4fd0$var$dimensions = {
        regular: {
            height: $3af6a5b9114f4fd0$var$globalGridSize * 2,
            width: $3af6a5b9114f4fd0$var$globalGridSize * 4
        },
        large: {
            height: $3af6a5b9114f4fd0$var$globalGridSize * 2 + $3af6a5b9114f4fd0$var$globalGridSize / 2,
            width: $3af6a5b9114f4fd0$var$globalGridSize * 5
        }
    };
    const $3af6a5b9114f4fd0$var$getHeight = ({ size: size })=>$3af6a5b9114f4fd0$var$dimensions[size].height;
    const $3af6a5b9114f4fd0$var$getWidth = ({ size: size })=>$3af6a5b9114f4fd0$var$dimensions[size].width;
    const $3af6a5b9114f4fd0$var$borderWidth = 2;
    const $3af6a5b9114f4fd0$var$paddingUnitless = $3af6a5b9114f4fd0$var$globalGridSize / 4;
    const $3af6a5b9114f4fd0$var$transition = 'transform 0.2s ease';
    const $3af6a5b9114f4fd0$export$ffd58b7f6f099a57 = (size)=>{
        const colors = (0, (0, parcelRequire("3VjLl")).getColors)();
        return (0, (0, parcelRequire("f8Sb3")).css)({
            boxSizing: 'content-box',
            display: 'inline-block',
            padding: $3af6a5b9114f4fd0$var$borderWidth,
            margin: $3af6a5b9114f4fd0$var$borderWidth,
            backgroundClip: 'content-box',
            backgroundColor: colors.backgroundColorUnchecked,
            borderRadius: `${$3af6a5b9114f4fd0$var$getHeight({
                size: size
            })}px`,
            border: `${$3af6a5b9114f4fd0$var$borderWidth}px solid transparent`,
            height: `${$3af6a5b9114f4fd0$var$getHeight({
                size: size
            })}px`,
            position: 'relative',
            transition: `${$3af6a5b9114f4fd0$var$transition}`,
            width: `${$3af6a5b9114f4fd0$var$getWidth({
                size: size
            })}px`,
            '&[data-checked]': {
                backgroundColor: colors.backgroundColorChecked,
                color: colors.iconColorChecked
            },
            '&[data-disabled]:not([data-checked])': {
                backgroundColor: colors.backgroundColorUncheckedDisabled
            },
            '&[data-disabled][data-checked],&[data-disabled][data-checked]:hover': {
                backgroundColor: colors.backgroundColorCheckedDisabled
            },
            '&:focus-within': {
                border: `${$3af6a5b9114f4fd0$var$borderWidth}px solid ${colors.borderColorFocus}`
            },
            '&:hover': {
                backgroundColor: colors.backgroundColorUncheckedHover,
                cursor: 'pointer'
            },
            '&[data-disabled]:hover,&[data-disabled][data-checked]:hover,&[data-disabled]:not([data-checked]):hover': {
                cursor: 'not-allowed'
            },
            '&[data-checked]:hover': {
                backgroundColor: colors.backgroundColorCheckedHover
            },
            '&:not([data-checked]):hover': {
                backgroundColor: colors.backgroundColorUncheckedHover
            },
            '&[data-disabled]:not([data-checked]):hover': {
                backgroundColor: colors.backgroundColorCheckedDisabled
            },
            color: colors.iconColorUnchecked,
            '&[data-disabled], &[data-disabled][data-checked], &[data-disabled][data-checked]:hover': {
                color: colors.iconColorDisabled
            },
            'input[type="checkbox"]': {
                opacity: 0,
                margin: 0,
                padding: 0,
                border: 'none',
                '&:focus': {
                    outline: 'none !important'
                }
            },
            '::before': {
                backgroundColor: colors.handleBackgroundColor,
                borderRadius: "var(--ds-border-radius-circle, 50%)",
                content: '""',
                position: 'absolute',
                transform: 'initial',
                transition: $3af6a5b9114f4fd0$var$transition,
                insetBlockEnd: `${2 * $3af6a5b9114f4fd0$var$paddingUnitless}px`,
                height: `${$3af6a5b9114f4fd0$var$getHeight({
                    size: size
                }) - $3af6a5b9114f4fd0$var$paddingUnitless * 2}px`,
                width: `${$3af6a5b9114f4fd0$var$getHeight({
                    size: size
                }) - $3af6a5b9114f4fd0$var$paddingUnitless * 2}px`,
                insetInlineStart: `${2 * $3af6a5b9114f4fd0$var$paddingUnitless}px`
            },
            '&[data-checked]::before': {
                backgroundColor: colors.handleBackgroundColorChecked,
                transform: `translateX(${$3af6a5b9114f4fd0$var$getHeight({
                    size: size
                })}px)`
            },
            '&[data-disabled]::before': {
                backgroundColor: colors.handleBackgroundColorDisabled,
                zIndex: 1
            },
            ...size === 'large' && !(0, (0, parcelRequire("VSDnB")).fg)('platform-visual-refresh-icons') && {
                '> span > span': {
                    height: '20px',
                    width: '20px'
                }
            },
            '@media screen and (forced-colors: active)': {
                '::before': {
                    filter: 'grayscale(100%) invert(1)'
                },
                '&:focus-within': {
                    outline: '1px solid'
                }
            }
        });
    };
});
parcelRegister("3VjLl", function(module, exports) {
    $parcel$export(module.exports, "getColors", ()=>$7761f9acd89472cc$export$6cee60e8aa85e528);
    const $7761f9acd89472cc$var$colorMap = {
        backgroundColorChecked: `var(--ds-background-success-bold, ${(0, $829f609a65d26a98$export$a49394138f7b8e85)})`,
        backgroundColorCheckedHover: `var(--ds-background-success-bold-hovered, ${(0, $829f609a65d26a98$export$6fa04827ac68a4fb)})`,
        backgroundColorCheckedDisabled: `var(--ds-background-disabled, ${(0, $829f609a65d26a98$export$9fdfb749e33cdf97)})`,
        backgroundColorUnchecked: `var(--ds-background-neutral-bold, ${(0, $829f609a65d26a98$export$f563ee71e9ee74cd)})`,
        backgroundColorUncheckedHover: `var(--ds-background-neutral-bold-hovered, ${(0, $829f609a65d26a98$export$eece5f492e429f08)})`,
        backgroundColorUncheckedDisabled: `var(--ds-background-disabled, ${(0, $829f609a65d26a98$export$9fdfb749e33cdf97)})`,
        borderColorFocus: `var(--ds-border-focused, ${(0, $829f609a65d26a98$export$215b7063051591f1)})`,
        iconColorChecked: `var(--ds-icon-inverse, ${(0, $829f609a65d26a98$export$29a4f05edc054374)})`,
        iconColorDisabled: `var(--ds-icon-disabled, ${(0, $829f609a65d26a98$export$5f71e19f3a7ad9de)})`,
        iconColorUnchecked: `var(--ds-icon-inverse, ${(0, $829f609a65d26a98$export$29a4f05edc054374)})`,
        handleBackgroundColor: `var(--ds-icon-inverse, ${(0, $829f609a65d26a98$export$29a4f05edc054374)})`,
        handleBackgroundColorChecked: `var(--ds-icon-inverse, ${(0, $829f609a65d26a98$export$29a4f05edc054374)})`,
        handleBackgroundColorDisabled: `var(--ds-icon-inverse, ${(0, $829f609a65d26a98$export$29a4f05edc054374)})`
    };
    const $7761f9acd89472cc$export$6cee60e8aa85e528 = ()=>$7761f9acd89472cc$var$colorMap;
});
var $829f609a65d26a98$exports = {};
$parcel$export($829f609a65d26a98$exports, "R50", ()=>$829f609a65d26a98$export$4b84fd0e2c617bd);
$parcel$export($829f609a65d26a98$exports, "R75", ()=>$829f609a65d26a98$export$b34b069eff244586);
$parcel$export($829f609a65d26a98$exports, "R100", ()=>$829f609a65d26a98$export$be802dd0d8b57f35);
$parcel$export($829f609a65d26a98$exports, "R200", ()=>$829f609a65d26a98$export$60847d42f9da92a6);
$parcel$export($829f609a65d26a98$exports, "R300", ()=>$829f609a65d26a98$export$4a7404f376229999);
$parcel$export($829f609a65d26a98$exports, "R400", ()=>$829f609a65d26a98$export$2010d0fb4295b346);
$parcel$export($829f609a65d26a98$exports, "R500", ()=>$829f609a65d26a98$export$2837b5a1a71d5c02);
$parcel$export($829f609a65d26a98$exports, "Y50", ()=>$829f609a65d26a98$export$8d9596b529bbb9cf);
$parcel$export($829f609a65d26a98$exports, "Y75", ()=>$829f609a65d26a98$export$41060a744ab0b7ae);
$parcel$export($829f609a65d26a98$exports, "Y100", ()=>$829f609a65d26a98$export$1cc05bbbf340e559);
$parcel$export($829f609a65d26a98$exports, "Y200", ()=>$829f609a65d26a98$export$5aae31392d72caaa);
$parcel$export($829f609a65d26a98$exports, "Y300", ()=>$829f609a65d26a98$export$c6930421db9fa514);
$parcel$export($829f609a65d26a98$exports, "Y400", ()=>$829f609a65d26a98$export$59619663aa6500fb);
$parcel$export($829f609a65d26a98$exports, "Y500", ()=>$829f609a65d26a98$export$874739ea9e379ab6);
$parcel$export($829f609a65d26a98$exports, "G50", ()=>$829f609a65d26a98$export$8a6341c32b6a3ee6);
$parcel$export($829f609a65d26a98$exports, "G75", ()=>$829f609a65d26a98$export$69a3bf21b9686c2b);
$parcel$export($829f609a65d26a98$exports, "G100", ()=>$829f609a65d26a98$export$afbce1382ec95f73);
$parcel$export($829f609a65d26a98$exports, "G200", ()=>$829f609a65d26a98$export$747d86715d4175de);
$parcel$export($829f609a65d26a98$exports, "G300", ()=>$829f609a65d26a98$export$940e1f69e873f202);
$parcel$export($829f609a65d26a98$exports, "G400", ()=>$829f609a65d26a98$export$a49394138f7b8e85);
$parcel$export($829f609a65d26a98$exports, "G500", ()=>$829f609a65d26a98$export$6fa04827ac68a4fb);
$parcel$export($829f609a65d26a98$exports, "B50", ()=>$829f609a65d26a98$export$226de0355ed012f8);
$parcel$export($829f609a65d26a98$exports, "B75", ()=>$829f609a65d26a98$export$8c13c2ee36917869);
$parcel$export($829f609a65d26a98$exports, "B100", ()=>$829f609a65d26a98$export$ecfc1dff386a3e7a);
$parcel$export($829f609a65d26a98$exports, "B200", ()=>$829f609a65d26a98$export$215b7063051591f1);
$parcel$export($829f609a65d26a98$exports, "B300", ()=>$829f609a65d26a98$export$e62e8919cca44969);
$parcel$export($829f609a65d26a98$exports, "B400", ()=>$829f609a65d26a98$export$b3de0ae08da2ce64);
$parcel$export($829f609a65d26a98$exports, "B500", ()=>$829f609a65d26a98$export$a6ee3d71bc067634);
$parcel$export($829f609a65d26a98$exports, "B600", ()=>$829f609a65d26a98$export$67f15409e87642a1);
$parcel$export($829f609a65d26a98$exports, "B700", ()=>$829f609a65d26a98$export$f0539dbc42dbb067);
$parcel$export($829f609a65d26a98$exports, "P50", ()=>$829f609a65d26a98$export$363e4475840015bb);
$parcel$export($829f609a65d26a98$exports, "P75", ()=>$829f609a65d26a98$export$4b0a4417ed59760f);
$parcel$export($829f609a65d26a98$exports, "P100", ()=>$829f609a65d26a98$export$6011520a64d9a187);
$parcel$export($829f609a65d26a98$exports, "P200", ()=>$829f609a65d26a98$export$a7175d4e9206bc80);
$parcel$export($829f609a65d26a98$exports, "P300", ()=>$829f609a65d26a98$export$b48405b95304b5a1);
$parcel$export($829f609a65d26a98$exports, "P400", ()=>$829f609a65d26a98$export$c71e4279dd54a18d);
$parcel$export($829f609a65d26a98$exports, "P500", ()=>$829f609a65d26a98$export$147899de772240ec);
$parcel$export($829f609a65d26a98$exports, "T50", ()=>$829f609a65d26a98$export$2cf711fe14c66f06);
$parcel$export($829f609a65d26a98$exports, "T75", ()=>$829f609a65d26a98$export$7a8f4d33f6f9772c);
$parcel$export($829f609a65d26a98$exports, "T100", ()=>$829f609a65d26a98$export$776c9c497ef08533);
$parcel$export($829f609a65d26a98$exports, "T200", ()=>$829f609a65d26a98$export$69cb3ca10c1e330e);
$parcel$export($829f609a65d26a98$exports, "T300", ()=>$829f609a65d26a98$export$9dc1ef0f3f48059b);
$parcel$export($829f609a65d26a98$exports, "T400", ()=>$829f609a65d26a98$export$7c835c78b6fcdef8);
$parcel$export($829f609a65d26a98$exports, "T500", ()=>$829f609a65d26a98$export$6036577f95c3877e);
$parcel$export($829f609a65d26a98$exports, "N0", ()=>$829f609a65d26a98$export$29a4f05edc054374);
$parcel$export($829f609a65d26a98$exports, "N10", ()=>$829f609a65d26a98$export$e655ef011c4aa1a2);
$parcel$export($829f609a65d26a98$exports, "N20", ()=>$829f609a65d26a98$export$9fdfb749e33cdf97);
$parcel$export($829f609a65d26a98$exports, "N30", ()=>$829f609a65d26a98$export$3e51e8f1440314ba);
$parcel$export($829f609a65d26a98$exports, "N40", ()=>$829f609a65d26a98$export$1ce2d1575d444ceb);
$parcel$export($829f609a65d26a98$exports, "N50", ()=>$829f609a65d26a98$export$8754fc7e6d8b3000);
$parcel$export($829f609a65d26a98$exports, "N60", ()=>$829f609a65d26a98$export$c2e91cbeada39b8b);
$parcel$export($829f609a65d26a98$exports, "N70", ()=>$829f609a65d26a98$export$5f71e19f3a7ad9de);
$parcel$export($829f609a65d26a98$exports, "N80", ()=>$829f609a65d26a98$export$eaa8c8a70ac22806);
$parcel$export($829f609a65d26a98$exports, "N90", ()=>$829f609a65d26a98$export$7c87d337fb91a97d);
$parcel$export($829f609a65d26a98$exports, "N100", ()=>$829f609a65d26a98$export$d6447c357493214f);
$parcel$export($829f609a65d26a98$exports, "N200", ()=>$829f609a65d26a98$export$f563ee71e9ee74cd);
$parcel$export($829f609a65d26a98$exports, "N300", ()=>$829f609a65d26a98$export$e2ef2124cf4024b1);
$parcel$export($829f609a65d26a98$exports, "N400", ()=>$829f609a65d26a98$export$eece5f492e429f08);
$parcel$export($829f609a65d26a98$exports, "N500", ()=>$829f609a65d26a98$export$c39c0fe0f26207ae);
$parcel$export($829f609a65d26a98$exports, "N600", ()=>$829f609a65d26a98$export$7090862dc3038a9f);
$parcel$export($829f609a65d26a98$exports, "N700", ()=>$829f609a65d26a98$export$ad0bacf983b7ddaa);
$parcel$export($829f609a65d26a98$exports, "N800", ()=>$829f609a65d26a98$export$7d61b15e0da132af);
$parcel$export($829f609a65d26a98$exports, "N900", ()=>$829f609a65d26a98$export$fbda843f0a27fe96);
$parcel$export($829f609a65d26a98$exports, "N10A", ()=>$829f609a65d26a98$export$791e57b27ca738ef);
$parcel$export($829f609a65d26a98$exports, "N20A", ()=>$829f609a65d26a98$export$8d43898f60b96a82);
$parcel$export($829f609a65d26a98$exports, "N30A", ()=>$829f609a65d26a98$export$920c0d5c49070351);
$parcel$export($829f609a65d26a98$exports, "N40A", ()=>$829f609a65d26a98$export$46987b49aab6d1a8);
$parcel$export($829f609a65d26a98$exports, "N50A", ()=>$829f609a65d26a98$export$7d3359664e8bb0e5);
$parcel$export($829f609a65d26a98$exports, "N60A", ()=>$829f609a65d26a98$export$d2d3bedfb276cac0);
$parcel$export($829f609a65d26a98$exports, "N70A", ()=>$829f609a65d26a98$export$6e5e7499ff054f6d);
$parcel$export($829f609a65d26a98$exports, "N80A", ()=>$829f609a65d26a98$export$59264b346c877fa7);
$parcel$export($829f609a65d26a98$exports, "N90A", ()=>$829f609a65d26a98$export$d092757d7c64925c);
$parcel$export($829f609a65d26a98$exports, "N100A", ()=>$829f609a65d26a98$export$486ab785e4bac3c6);
$parcel$export($829f609a65d26a98$exports, "N200A", ()=>$829f609a65d26a98$export$fba592bbc6419fea);
$parcel$export($829f609a65d26a98$exports, "N300A", ()=>$829f609a65d26a98$export$13609db0e6812ab8);
$parcel$export($829f609a65d26a98$exports, "N400A", ()=>$829f609a65d26a98$export$fbae4efaa892d883);
$parcel$export($829f609a65d26a98$exports, "N500A", ()=>$829f609a65d26a98$export$f13946d26f440ce6);
$parcel$export($829f609a65d26a98$exports, "N600A", ()=>$829f609a65d26a98$export$19f58225ac52a306);
$parcel$export($829f609a65d26a98$exports, "N700A", ()=>$829f609a65d26a98$export$6384f09da70e7b62);
$parcel$export($829f609a65d26a98$exports, "N800A", ()=>$829f609a65d26a98$export$d0dccc730683edc2);
$parcel$export($829f609a65d26a98$exports, "DN900", ()=>$829f609a65d26a98$export$2f6319cf7d736c2a);
$parcel$export($829f609a65d26a98$exports, "DN800", ()=>$829f609a65d26a98$export$95126bc8884d6634);
$parcel$export($829f609a65d26a98$exports, "DN700", ()=>$829f609a65d26a98$export$8338d8c35d44ff1);
$parcel$export($829f609a65d26a98$exports, "DN600", ()=>$829f609a65d26a98$export$5368d76ae4fbbda7);
$parcel$export($829f609a65d26a98$exports, "DN500", ()=>$829f609a65d26a98$export$9f0a771d16ec8d36);
$parcel$export($829f609a65d26a98$exports, "DN400", ()=>$829f609a65d26a98$export$abcf8c38338b4aa5);
$parcel$export($829f609a65d26a98$exports, "DN300", ()=>$829f609a65d26a98$export$97e81faa16a182c7);
$parcel$export($829f609a65d26a98$exports, "DN200", ()=>$829f609a65d26a98$export$bfe21c59123ecf33);
$parcel$export($829f609a65d26a98$exports, "DN100", ()=>$829f609a65d26a98$export$ee82aa2ea6a1558);
$parcel$export($829f609a65d26a98$exports, "DN90", ()=>$829f609a65d26a98$export$682927c6ed76be02);
$parcel$export($829f609a65d26a98$exports, "DN80", ()=>$829f609a65d26a98$export$91fd9994c5be9757);
$parcel$export($829f609a65d26a98$exports, "DN70", ()=>$829f609a65d26a98$export$d281fbfe3cff2df6);
$parcel$export($829f609a65d26a98$exports, "DN60", ()=>$829f609a65d26a98$export$3902c91d8b98a753);
$parcel$export($829f609a65d26a98$exports, "DN50", ()=>$829f609a65d26a98$export$8e8133c1ba5845b5);
$parcel$export($829f609a65d26a98$exports, "DN40", ()=>$829f609a65d26a98$export$33ff357f6620b39);
$parcel$export($829f609a65d26a98$exports, "DN30", ()=>$829f609a65d26a98$export$633847cf062b69e0);
$parcel$export($829f609a65d26a98$exports, "DN20", ()=>$829f609a65d26a98$export$9776a6e503bdbf76);
$parcel$export($829f609a65d26a98$exports, "DN10", ()=>$829f609a65d26a98$export$6c813d5160dff5db);
$parcel$export($829f609a65d26a98$exports, "DN0", ()=>$829f609a65d26a98$export$3d4f271a500072a);
$parcel$export($829f609a65d26a98$exports, "DN800A", ()=>$829f609a65d26a98$export$fe151285c22759ec);
$parcel$export($829f609a65d26a98$exports, "DN700A", ()=>$829f609a65d26a98$export$a101c6190d0da34);
$parcel$export($829f609a65d26a98$exports, "DN600A", ()=>$829f609a65d26a98$export$4b16f52932fda51);
$parcel$export($829f609a65d26a98$exports, "DN500A", ()=>$829f609a65d26a98$export$a74bcd7b9845b0d8);
$parcel$export($829f609a65d26a98$exports, "DN400A", ()=>$829f609a65d26a98$export$9209c179316d1751);
$parcel$export($829f609a65d26a98$exports, "DN300A", ()=>$829f609a65d26a98$export$88f55133f636c8dd);
$parcel$export($829f609a65d26a98$exports, "DN200A", ()=>$829f609a65d26a98$export$51126f85d1009512);
$parcel$export($829f609a65d26a98$exports, "DN100A", ()=>$829f609a65d26a98$export$a069b547bd23af78);
$parcel$export($829f609a65d26a98$exports, "DN90A", ()=>$829f609a65d26a98$export$40cce05af3b2600a);
$parcel$export($829f609a65d26a98$exports, "DN80A", ()=>$829f609a65d26a98$export$b78942185925a0d);
$parcel$export($829f609a65d26a98$exports, "DN70A", ()=>$829f609a65d26a98$export$e058fb44b8eaafca);
$parcel$export($829f609a65d26a98$exports, "DN60A", ()=>$829f609a65d26a98$export$92b0b8de26920c18);
$parcel$export($829f609a65d26a98$exports, "DN50A", ()=>$829f609a65d26a98$export$67564f7459d3fc9e);
$parcel$export($829f609a65d26a98$exports, "DN40A", ()=>$829f609a65d26a98$export$77868e1ca884ffa3);
$parcel$export($829f609a65d26a98$exports, "DN30A", ()=>$829f609a65d26a98$export$2afd71d7d3ea2cd5);
$parcel$export($829f609a65d26a98$exports, "DN20A", ()=>$829f609a65d26a98$export$f2a0acaab9972c70);
$parcel$export($829f609a65d26a98$exports, "DN10A", ()=>$829f609a65d26a98$export$9ab70e67d6475361);
$parcel$export($829f609a65d26a98$exports, "T30", ()=>$829f609a65d26a98$export$d2adc5dedf09fced);
$parcel$export($829f609a65d26a98$exports, "L50", ()=>$829f609a65d26a98$export$f51762073d2bb381);
$parcel$export($829f609a65d26a98$exports, "L75", ()=>$829f609a65d26a98$export$95e26d54def96891);
const $829f609a65d26a98$export$4b84fd0e2c617bd = '#FFEBE6';
const $829f609a65d26a98$export$b34b069eff244586 = '#FFBDAD';
const $829f609a65d26a98$export$be802dd0d8b57f35 = '#FF8F73';
const $829f609a65d26a98$export$60847d42f9da92a6 = '#FF7452';
const $829f609a65d26a98$export$4a7404f376229999 = '#FF5630';
const $829f609a65d26a98$export$2010d0fb4295b346 = '#DE350B';
const $829f609a65d26a98$export$2837b5a1a71d5c02 = '#BF2600';
const $829f609a65d26a98$export$8d9596b529bbb9cf = '#FFFAE6';
const $829f609a65d26a98$export$41060a744ab0b7ae = '#FFF0B3';
const $829f609a65d26a98$export$1cc05bbbf340e559 = '#FFE380';
const $829f609a65d26a98$export$5aae31392d72caaa = '#FFC400';
const $829f609a65d26a98$export$c6930421db9fa514 = '#FFAB00';
const $829f609a65d26a98$export$59619663aa6500fb = '#FF991F';
const $829f609a65d26a98$export$874739ea9e379ab6 = '#FF8B00';
const $829f609a65d26a98$export$8a6341c32b6a3ee6 = '#E3FCEF';
const $829f609a65d26a98$export$69a3bf21b9686c2b = '#ABF5D1';
const $829f609a65d26a98$export$afbce1382ec95f73 = '#79F2C0';
const $829f609a65d26a98$export$747d86715d4175de = '#57D9A3';
const $829f609a65d26a98$export$940e1f69e873f202 = '#36B37E';
const $829f609a65d26a98$export$a49394138f7b8e85 = '#00875A';
const $829f609a65d26a98$export$6fa04827ac68a4fb = '#006644';
const $829f609a65d26a98$export$226de0355ed012f8 = '#DEEBFF';
const $829f609a65d26a98$export$8c13c2ee36917869 = '#B3D4FF';
const $829f609a65d26a98$export$ecfc1dff386a3e7a = '#4C9AFF';
const $829f609a65d26a98$export$215b7063051591f1 = '#2684FF';
const $829f609a65d26a98$export$e62e8919cca44969 = '#0065FF';
const $829f609a65d26a98$export$b3de0ae08da2ce64 = '#0052CC';
const $829f609a65d26a98$export$a6ee3d71bc067634 = '#0747A6';
const $829f609a65d26a98$export$67f15409e87642a1 = '#669DF1';
const $829f609a65d26a98$export$f0539dbc42dbb067 = '#8FB8F6';
const $829f609a65d26a98$export$363e4475840015bb = '#EAE6FF';
const $829f609a65d26a98$export$4b0a4417ed59760f = '#C0B6F2';
const $829f609a65d26a98$export$6011520a64d9a187 = '#998DD9';
const $829f609a65d26a98$export$a7175d4e9206bc80 = '#8777D9';
const $829f609a65d26a98$export$b48405b95304b5a1 = '#6554C0';
const $829f609a65d26a98$export$c71e4279dd54a18d = '#5243AA';
const $829f609a65d26a98$export$147899de772240ec = '#403294';
const $829f609a65d26a98$export$2cf711fe14c66f06 = '#E6FCFF';
const $829f609a65d26a98$export$7a8f4d33f6f9772c = '#B3F5FF';
const $829f609a65d26a98$export$776c9c497ef08533 = '#79E2F2';
const $829f609a65d26a98$export$69cb3ca10c1e330e = '#00C7E6';
const $829f609a65d26a98$export$9dc1ef0f3f48059b = '#00B8D9';
const $829f609a65d26a98$export$7c835c78b6fcdef8 = '#00A3BF';
const $829f609a65d26a98$export$6036577f95c3877e = '#008DA6';
const $829f609a65d26a98$export$29a4f05edc054374 = '#FFFFFF';
const $829f609a65d26a98$export$e655ef011c4aa1a2 = '#FAFBFC';
const $829f609a65d26a98$export$9fdfb749e33cdf97 = '#F4F5F7';
const $829f609a65d26a98$export$3e51e8f1440314ba = '#EBECF0';
const $829f609a65d26a98$export$1ce2d1575d444ceb = '#DFE1E6';
const $829f609a65d26a98$export$8754fc7e6d8b3000 = '#C1C7D0';
const $829f609a65d26a98$export$c2e91cbeada39b8b = '#B3BAC5';
const $829f609a65d26a98$export$5f71e19f3a7ad9de = '#A5ADBA';
const $829f609a65d26a98$export$eaa8c8a70ac22806 = '#97A0AF';
const $829f609a65d26a98$export$7c87d337fb91a97d = '#8993A4';
const $829f609a65d26a98$export$d6447c357493214f = '#7A869A';
const $829f609a65d26a98$export$f563ee71e9ee74cd = '#6B778C';
const $829f609a65d26a98$export$e2ef2124cf4024b1 = '#5E6C84';
const $829f609a65d26a98$export$eece5f492e429f08 = '#505F79';
const $829f609a65d26a98$export$c39c0fe0f26207ae = '#42526E';
const $829f609a65d26a98$export$7090862dc3038a9f = '#344563';
const $829f609a65d26a98$export$ad0bacf983b7ddaa = '#253858';
const $829f609a65d26a98$export$7d61b15e0da132af = '#172B4D';
const $829f609a65d26a98$export$fbda843f0a27fe96 = '#091E42';
const $829f609a65d26a98$export$791e57b27ca738ef = 'rgba(9, 30, 66, 0.02)';
const $829f609a65d26a98$export$8d43898f60b96a82 = 'rgba(9, 30, 66, 0.04)';
const $829f609a65d26a98$export$920c0d5c49070351 = 'rgba(9, 30, 66, 0.08)';
const $829f609a65d26a98$export$46987b49aab6d1a8 = 'rgba(9, 30, 66, 0.13)';
const $829f609a65d26a98$export$7d3359664e8bb0e5 = 'rgba(9, 30, 66, 0.25)';
const $829f609a65d26a98$export$d2d3bedfb276cac0 = 'rgba(9, 30, 66, 0.31)';
const $829f609a65d26a98$export$6e5e7499ff054f6d = 'rgba(9, 30, 66, 0.36)';
const $829f609a65d26a98$export$59264b346c877fa7 = 'rgba(9, 30, 66, 0.42)';
const $829f609a65d26a98$export$d092757d7c64925c = 'rgba(9, 30, 66, 0.48)';
const $829f609a65d26a98$export$486ab785e4bac3c6 = 'rgba(9, 30, 66, 0.54)';
const $829f609a65d26a98$export$fba592bbc6419fea = 'rgba(9, 30, 66, 0.60)';
const $829f609a65d26a98$export$13609db0e6812ab8 = 'rgba(9, 30, 66, 0.66)';
const $829f609a65d26a98$export$fbae4efaa892d883 = 'rgba(9, 30, 66, 0.71)';
const $829f609a65d26a98$export$f13946d26f440ce6 = 'rgba(9, 30, 66, 0.77)';
const $829f609a65d26a98$export$19f58225ac52a306 = 'rgba(9, 30, 66, 0.82)';
const $829f609a65d26a98$export$6384f09da70e7b62 = 'rgba(9, 30, 66, 0.89)';
const $829f609a65d26a98$export$d0dccc730683edc2 = 'rgba(9, 30, 66, 0.95)';
const $829f609a65d26a98$export$2f6319cf7d736c2a = '#E6EDFA';
const $829f609a65d26a98$export$95126bc8884d6634 = '#DCE5F5';
const $829f609a65d26a98$export$8338d8c35d44ff1 = '#CED9EB';
const $829f609a65d26a98$export$5368d76ae4fbbda7 = '#B8C7E0';
const $829f609a65d26a98$export$9f0a771d16ec8d36 = '#ABBBD6';
const $829f609a65d26a98$export$abcf8c38338b4aa5 = '#9FB0CC';
const $829f609a65d26a98$export$97e81faa16a182c7 = '#8C9CB8';
const $829f609a65d26a98$export$bfe21c59123ecf33 = '#7988A3';
const $829f609a65d26a98$export$ee82aa2ea6a1558 = '#67758F';
const $829f609a65d26a98$export$682927c6ed76be02 = '#56637A';
const $829f609a65d26a98$export$91fd9994c5be9757 = '#455166';
const $829f609a65d26a98$export$d281fbfe3cff2df6 = '#3B475C';
const $829f609a65d26a98$export$3902c91d8b98a753 = '#313D52';
const $829f609a65d26a98$export$8e8133c1ba5845b5 = '#283447';
const $829f609a65d26a98$export$33ff357f6620b39 = '#202B3D';
const $829f609a65d26a98$export$633847cf062b69e0 = '#1B2638';
const $829f609a65d26a98$export$9776a6e503bdbf76 = '#121A29';
const $829f609a65d26a98$export$6c813d5160dff5db = '#0E1624';
const $829f609a65d26a98$export$3d4f271a500072a = '#0D1424';
const $829f609a65d26a98$export$fe151285c22759ec = 'rgba(13, 20, 36, 0.06)';
const $829f609a65d26a98$export$a101c6190d0da34 = 'rgba(13, 20, 36, 0.14)';
const $829f609a65d26a98$export$4b16f52932fda51 = 'rgba(13, 20, 36, 0.18)';
const $829f609a65d26a98$export$a74bcd7b9845b0d8 = 'rgba(13, 20, 36, 0.29)';
const $829f609a65d26a98$export$9209c179316d1751 = 'rgba(13, 20, 36, 0.36)';
const $829f609a65d26a98$export$88f55133f636c8dd = 'rgba(13, 20, 36, 0.40)';
const $829f609a65d26a98$export$51126f85d1009512 = 'rgba(13, 20, 36, 0.47)';
const $829f609a65d26a98$export$a069b547bd23af78 = 'rgba(13, 20, 36, 0.53)';
const $829f609a65d26a98$export$40cce05af3b2600a = 'rgba(13, 20, 36, 0.63)';
const $829f609a65d26a98$export$b78942185925a0d = 'rgba(13, 20, 36, 0.73)';
const $829f609a65d26a98$export$e058fb44b8eaafca = 'rgba(13, 20, 36, 0.78)';
const $829f609a65d26a98$export$92b0b8de26920c18 = 'rgba(13, 20, 36, 0.81)';
const $829f609a65d26a98$export$67564f7459d3fc9e = 'rgba(13, 20, 36, 0.85)';
const $829f609a65d26a98$export$77868e1ca884ffa3 = 'rgba(13, 20, 36, 0.89)';
const $829f609a65d26a98$export$2afd71d7d3ea2cd5 = 'rgba(13, 20, 36, 0.92)';
const $829f609a65d26a98$export$f2a0acaab9972c70 = 'rgba(13, 20, 36, 0.95)';
const $829f609a65d26a98$export$9ab70e67d6475361 = 'rgba(13, 20, 36, 0.97)';
const $829f609a65d26a98$export$d2adc5dedf09fced = '#292A2E';
const $829f609a65d26a98$export$f51762073d2bb381 = '#94C748';
const $829f609a65d26a98$export$95e26d54def96891 = '#B3DF72';
parcelRegister("5Lp4g", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$620fcc9c943a9068$export$2e2bcd8739ae039);
    var $7VHMR;
    var $620fcc9c943a9068$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        showHierarchy: {
            "id": "issue-table-group-by-dropdown.group-by-label",
            "defaultMessage": "Show hierarchy"
        }
    });
});
parcelRegister("1igGa", function(module, exports) {
    $parcel$export(module.exports, "withReportErrors", ()=>$8e450634a454d1b1$export$89614e972c8b110);
    var $5uXOq = parcelRequire("5uXOq");
    var $7iJwP;
    const $8e450634a454d1b1$export$89614e972c8b110 = (WrappedComponent, config)=>(props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7iJwP")).default), config, (0, ($parcel$interopDefault($5uXOq))).createElement(WrappedComponent, props));
});
parcelRegister("aTDVl", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$b2a9e5a070a2144b$export$2e2bcd8739ae039);
    var $4oTzW;
    var $wsH9y;
    var $d6cUj;
    var $1NCNs;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $1u3Jv;
    var $gcl1l;
    var $6s1PB;
    var $jJZqs;
    var $iXVV6;
    var $imS60;
    var $lVJAU;
    var $6UAdF;
    var $6QnB8;
    var $1igGa;
    var $a8lIB;
    var $gtUFk;
    var $7OTah;
    var $ih9zY;
    var $krnTs;
    var $gwtSG;
    var $isIOR;
    var $38r5c;
    var $4LmBp;
    var $3vkQx;
    var $dTymG;
    const $b2a9e5a070a2144b$var$IssueResults = ({ onChangeColumnConfiguration: onChangeColumnConfiguration, onChangeJql: onChangeJql, onCaughtIssueSearchError: onCaughtIssueSearchError, issueResults: issueResults, groupResults: groupResults, viewResult: viewResult, project: project, isFeedbackButtonDisabled: isFeedbackButtonDisabled, issueTableProps: issueTableProps, filter: filter, isIssueHierarchySupportEnabled: isIssueHierarchySupportEnabled })=>{
        (0, (0, parcelRequire("a8lIB")).markOnce)((0, (0, parcelRequire("a8lIB")).marks).ISSUE_RESULTS_START);
        const [{ view: selectedView }] = (0, (0, parcelRequire("ih9zY")).useSelectedViewState)();
        const jql = (0, (0, parcelRequire("gwtSG")).useResolvedJql)();
        const { push: push } = (0, (0, parcelRequire("lVJAU")).useRouterActions)();
        let onSelectedRowChange;
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
            const { setSelectedIssueByIndex: setSelectedIssueByIndex } = (0, (0, parcelRequire("7OTah")).useSelectedIssueActions)();
            onSelectedRowChange = (0, $5uXOq.useCallback)((rowIndex)=>{
                setSelectedIssueByIndex(rowIndex, {
                    shouldDebounce: false,
                    shouldNotifyOnChange: false,
                    isSelectedByUserInteraction: true
                });
            }, [
                setSelectedIssueByIndex
            ]);
        } else {
            const { setSelectedIssueByIndex: setSelectedIssueByIndex } = (0, (0, parcelRequire("gtUFk")).useSelectedIssueStateOldActions)();
            onSelectedRowChange = (0, $5uXOq.useCallback)((rowIndex)=>{
                setSelectedIssueByIndex(rowIndex, false, false, true);
            }, [
                setSelectedIssueByIndex
            ]);
        }
        let isFetching;
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) isFetching = (0, (0, parcelRequire("38r5c")).useIsFetching)();
        else ({ isFetching: isFetching } = (0, (0, parcelRequire("isIOR")).useIssueSearchQuery)());
        const issueResultsData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("1NCNs")).default), issueResults);
        const groupResultsData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("d6cUj")).default), groupResults);
        const viewResultData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("wsH9y")).default), viewResult);
        const projectData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("4oTzW")).default), project);
        const viewResultError = (0, $5uXOq.useMemo)(()=>viewResultData?.__typename === 'QueryError' && viewResultData?.extensions?.[0]?.statusCode !== undefined && viewResultData?.extensions?.[0]?.statusCode !== null ? new (0, (0, parcelRequire("jJZqs")).default)(viewResultData?.extensions?.[0]?.statusCode, viewResultData?.message ?? '') : undefined, [
            viewResultData
        ]);
        (0, $5uXOq.useLayoutEffect)(()=>{
            (0, (0, parcelRequire("a8lIB")).markOnce)((0, (0, parcelRequire("a8lIB")).marks).ISSUE_RESULTS_END);
        }, []);
        const onNavigateToIssue = (0, $5uXOq.useCallback)((issueKey)=>push(`/browse/${issueKey}`), [
            push
        ]);
        const sourceName = selectedView === 'detail' ? (0, (0, parcelRequire("6UAdF")).ANALYTICS_DETAIL_VIEW_SOURCE_NAME) : (0, (0, parcelRequire("6UAdF")).ANALYTICS_LIST_VIEW_SOURCE_NAME);
        if (!issueResultsData || issueResultsData.issueSearchError?.__typename === 'JiraServerError' || !viewResultData || viewResultData?.__typename === 'QueryError') return isFetching ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6QnB8")).ListViewSkeleton), null) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3vkQx")).default), {
            error: viewResultError
        });
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("imS60")).default), {
            name: "issue-results"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            sourceName: sourceName,
            sourceType: (0, $4d5e0871c06cee03$export$f636b5b56f1d624a)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3vkQx")).RecoverableErrorViewBoundary), {
            recoveryProp: isFetching,
            onCaughtIssueSearchError: onCaughtIssueSearchError
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("krnTs")).SortContainer), {
            jql: jql,
            onChangeJql: onChangeJql
        }, selectedView !== 'detail' ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dTymG")).default), {
            loading: isFetching,
            onSelectedRowChange: onSelectedRowChange,
            issueResults: issueResultsData,
            groupResults: groupResultsData,
            view: viewResultData,
            project: projectData,
            onChangeColumnConfiguration: onChangeColumnConfiguration,
            isFeedbackButtonDisabled: isFeedbackButtonDisabled,
            issueTableProps: issueTableProps,
            isIssueHierarchySupportEnabled: isIssueHierarchySupportEnabled,
            filter: filter
        }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4LmBp")).default), {
            loading: isFetching,
            onNavigateToIssue: onNavigateToIssue,
            issueResults: issueResultsData,
            view: viewResultData,
            isFeedbackButtonDisabled: isFeedbackButtonDisabled
        })), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1u3Jv")).default), {
            op: ()=>{
                onCaughtIssueSearchError && onCaughtIssueSearchError(undefined);
            }
        }))));
    };
    var $b2a9e5a070a2144b$export$2e2bcd8739ae039 = (0, (0, parcelRequire("1igGa")).withReportErrors)($b2a9e5a070a2144b$var$IssueResults, {
        id: 'ui.issue-results.unhandled',
        packageName: (0, (0, parcelRequire("6UAdF")).PACKAGE_NAME),
        teamName: (0, (0, parcelRequire("6UAdF")).TEAM_NAME),
        sendToPrivacyUnsafeSplunk: true,
        attributes: (0, (0, parcelRequire("gcl1l")).isLoaderErrorAttributes)
    });
});
parcelRegister("4oTzW", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$f2140bab55204986$export$2e2bcd8739ae039);
    const $f2140bab55204986$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_IssueResults_project",
        "selections": [
            {
                "kind": "FragmentSpread",
                "name": "main_issueNavigator_ListView_project"
            }
        ],
        "type": "JiraProject"
    };
    $f2140bab55204986$var$node.hash = "933876bf3e3ea405c912812993974510";
    var $f2140bab55204986$export$2e2bcd8739ae039 = $f2140bab55204986$var$node;
});
parcelRegister("wsH9y", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$8502fb9eb5f67b01$export$2e2bcd8739ae039);
    const $8502fb9eb5f67b01$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_IssueResults_viewResult",
        "selections": [
            {
                "kind": "InlineFragment",
                "selections": [
                    {
                        "kind": "FragmentSpread",
                        "name": "main_issueNavigator_ListView_view"
                    },
                    {
                        "kind": "FragmentSpread",
                        "name": "main_issueNavigator_DetailView_view"
                    }
                ],
                "type": "JiraIssueSearchView"
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    {
                        "kind": "ScalarField",
                        "name": "message"
                    },
                    {
                        "kind": "LinkedField",
                        "name": "extensions",
                        "plural": true,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "name": "statusCode"
                            }
                        ]
                    },
                    {
                        "kind": "ScalarField",
                        "name": "__typename"
                    }
                ],
                "type": "QueryError"
            }
        ],
        "type": "JiraIssueSearchViewResult",
        "abstractKey": "__isJiraIssueSearchViewResult"
    };
    $8502fb9eb5f67b01$var$node.hash = "c62e6e48eccad98fb8d9de957a0d79e9";
    var $8502fb9eb5f67b01$export$2e2bcd8739ae039 = $8502fb9eb5f67b01$var$node;
});
parcelRegister("d6cUj", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$e3b7bc380ba724e0$export$2e2bcd8739ae039);
    const $e3b7bc380ba724e0$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_IssueResults_groupResults",
        "selections": [
            {
                "kind": "FragmentSpread",
                "name": "main_issueNavigator_ListView_groupResults"
            }
        ],
        "type": "JiraSpreadsheetGroupConnection"
    };
    $e3b7bc380ba724e0$var$node.hash = "bd26c6389db3c9646f1f157951f21d31";
    var $e3b7bc380ba724e0$export$2e2bcd8739ae039 = $e3b7bc380ba724e0$var$node;
});
parcelRegister("1NCNs", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$c580d7f20c072557$export$2e2bcd8739ae039);
    const $c580d7f20c072557$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_IssueResults_issueResults",
        "selections": [
            {
                "kind": "FragmentSpread",
                "name": "main_issueNavigator_ListView_issueResults"
            },
            {
                "kind": "FragmentSpread",
                "name": "main_issueNavigator_DetailView_issueResults"
            },
            {
                "kind": "LinkedField",
                "name": "issueSearchError",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "name": "__typename"
                    }
                ]
            }
        ],
        "type": "JiraIssueConnection"
    };
    $c580d7f20c072557$var$node.hash = "f2381a118526d5996bae8676671dd01c";
    var $c580d7f20c072557$export$2e2bcd8739ae039 = $c580d7f20c072557$var$node;
});
parcelRegister("6QnB8", function(module, exports) {
    $parcel$export(module.exports, "ListViewSkeleton", ()=>$8f321f8af9d08cfe$export$8465c21c33452ecb);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $3ZuB3;
    const $8f321f8af9d08cfe$export$8465c21c33452ecb = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement($8f321f8af9d08cfe$var$StyledListViewContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3ZuB3")).NativeIssueTableSkeleton), null));
    const $8f321f8af9d08cfe$var$StyledListViewContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1reo15vq _18m915vq",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("3ZuB3", function(module, exports) {
    $parcel$export(module.exports, "NativeIssueTableSkeleton", ()=>$7ccc2556c713810d$export$309a6db85e3f2aaa);
    var $bwpsq;
    var $5uXOq = parcelRequire("5uXOq");
    var $gnABE;
    var $8g71g = parcelRequire("8g71g");
    const $7ccc2556c713810d$var$customCssStyles = null;
    const $7ccc2556c713810d$var$containerStyles = {
        height: '448px'
    };
    const $7ccc2556c713810d$export$309a6db85e3f2aaa = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gnABE")).AppSkeletonImage), {
            src: (0, ($parcel$interopDefault($8g71g))),
            containerStyles: $7ccc2556c713810d$var$containerStyles,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1wgmglyw"
            ])
        });
});
parcelRegister("8g71g", function(module, exports) {
    module.exports = new URL("native-issue-table-skeleton.tpl.e6209376.svg", import.meta.url).toString();
});
parcelRegister("krnTs", function(module, exports) {
    $parcel$export(module.exports, "SortContainer", ()=>$5a9a6c0829096e54$export$f2d9f14f583efb1a);
    $parcel$export(module.exports, "useSortFieldAndDirection", ()=>$5a9a6c0829096e54$export$951ead8cec7da319);
    var $5uXOq = parcelRequire("5uXOq");
    var $gg7kZ;
    var $3sQ5x;
    var $iXqE5;
    var $amUxN;
    const $5a9a6c0829096e54$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: {
            field: 'created',
            direction: 'DESC'
        },
        actions: {
            setField: (field, aliases)=>({ getState: getState, setState: setState }, { onChangeJql: onChangeJql, jql: jql, JqlUtils: JqlUtils })=>{
                    const { field: prevField } = getState();
                    if (field !== prevField) {
                        setState({
                            field: field
                        });
                        const newJql = JqlUtils?.jql(jql).setPrimarySortField(field, undefined, aliases).toJql();
                        typeof newJql === 'string' && onChangeJql && onChangeJql(newJql);
                    }
                },
            setFieldAndDirection: (field, direction, aliases)=>({ getState: getState, setState: setState }, { onChangeJql: onChangeJql, jql: jql, JqlUtils: JqlUtils })=>{
                    const { field: prevField, direction: prevDirection } = getState();
                    if (field !== prevField || direction !== prevDirection) {
                        setState({
                            field: field,
                            direction: direction
                        });
                        if (direction !== 'NONE') {
                            const newJql = JqlUtils?.jql(jql).setPrimarySortField(field, direction, aliases).toJql();
                            typeof newJql === 'string' && onChangeJql && onChangeJql(newJql);
                        }
                    }
                }
        },
        name: 'IssueNavigatorSelectedViewState'
    });
    const $5a9a6c0829096e54$export$f4b3414b22b0750b = ()=>({ getState: getState, setState: setState }, { jql: jql, JqlUtils: JqlUtils })=>{
            const field = JqlUtils?.jql(jql).getPrimarySortField();
            const direction = JqlUtils?.jql(jql).getPrimarySortDirection();
            const { field: prevField, direction: prevDirection } = getState();
            if (field !== prevField || direction !== prevDirection) setState({
                field: field,
                direction: direction
            });
        };
    const $5a9a6c0829096e54$export$42a852a2b6b56249 = (0, (0, parcelRequire("gg7kZ")).createContainer)($5a9a6c0829096e54$var$Store, {
        onInit: $5a9a6c0829096e54$export$f4b3414b22b0750b,
        onUpdate: $5a9a6c0829096e54$export$f4b3414b22b0750b
    });
    const $5a9a6c0829096e54$export$f2d9f14f583efb1a = (props)=>{
        const Utils = (0, (0, parcelRequire("amUxN")).useJqlUtils)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement($5a9a6c0829096e54$export$42a852a2b6b56249, {
            JqlUtils: Utils,
            ...props
        });
    };
    const $5a9a6c0829096e54$export$951ead8cec7da319 = (0, (0, parcelRequire("3sQ5x")).createHook)($5a9a6c0829096e54$var$Store, {
        selector: ({ field: field, direction: direction })=>({
                field: field,
                direction: direction
            })
    });
});
parcelRegister("amUxN", function(module, exports) {
    $parcel$export(module.exports, "useJqlUtils", ()=>$7e8f6b46153d968f$export$c7c2706ff288264f);
    var $5uXOq = parcelRequire("5uXOq");
    const $7e8f6b46153d968f$export$4a178a021cc96ab5 = async ()=>{
        const module = await (parcelRequire("aH9Ys"));
        const { JqlUtils: JqlUtils } = module;
        return new JqlUtils();
    };
    const $7e8f6b46153d968f$export$c7c2706ff288264f = ()=>{
        const [Utils, setUtils] = (0, $5uXOq.useState)();
        const asyncEffect = async ()=>{
            const ResolvedUtils = await $7e8f6b46153d968f$export$4a178a021cc96ab5();
            setUtils(ResolvedUtils);
        };
        (0, $5uXOq.useEffect)(()=>{
            asyncEffect();
        }, []);
        return Utils;
    };
});
parcelRegister("aH9Ys", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("dsheJ").then(()=>parcelRequire('aWG9Z'));
});
parcelRegister("4LmBp", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$53522b5e6e51bfef$export$2e2bcd8739ae039);
    var $dhc4s;
    var $2Vq1u;
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Uumt = parcelRequire("8Uumt");
    var $dC5iT;
    var $8CUq3;
    var $gcl1l;
    var $6s1PB;
    var $7XYc4;
    var $kYFm9;
    var $3HwlS;
    var $5oFIy;
    var $imS60;
    var $6UAdF;
    var $eojy4;
    var $1igGa;
    var $a8lIB;
    var $hWtSW;
    var $428r9;
    const $53522b5e6e51bfef$var$ConditionalContentContainer = ({ children: children })=>(0, (0, parcelRequire("3HwlS")).getWillShowNav4)() ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $53522b5e6e51bfef$var$ContentContainerStyles
        }, children) : (0, ($parcel$interopDefault($5uXOq))).createElement($53522b5e6e51bfef$var$ContentStyledContainer, null, children);
    const $53522b5e6e51bfef$var$MaybeListElementProvider = (0, (0, parcelRequire("7XYc4")).componentWithCondition)(()=>(0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false), (0, (0, parcelRequire("kYFm9")).ElementSizeProvider), ({ children: children })=>children);
    const $53522b5e6e51bfef$var$DetailView = ({ IssueApp: IssueApp = (0, (0, parcelRequire("eojy4")).default), issueResults: issueResults, view: view, isFeedbackButtonDisabled: isFeedbackButtonDisabled, ...props })=>{
        const listRef = (0, $5uXOq.useRef)(null);
        (0, (0, parcelRequire("a8lIB")).markOnce)((0, (0, parcelRequire("a8lIB")).marks).ISSUE_RESULTS_DETAIL_VIEW_START);
        (0, $5uXOq.useLayoutEffect)(()=>{
            (0, (0, parcelRequire("a8lIB")).markOnce)((0, (0, parcelRequire("a8lIB")).marks).ISSUE_RESULTS_DETAIL_VIEW_END);
        }, []);
        const detailViewData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("2Vq1u")).default), issueResults);
        const viewData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("dhc4s")).default), view);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("imS60")).default), {
            name: "detail-view"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($53522b5e6e51bfef$var$ConditionalContentContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement($53522b5e6e51bfef$var$MaybeListElementProvider, {
            elementRef: listRef
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hWtSW")).default), {
            issueResults: detailViewData,
            isFeedbackButtonDisabled: isFeedbackButtonDisabled
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("428r9")).default), {
            fragment: detailViewData,
            ...props,
            listRef: listRef
        }))), (0, ($parcel$interopDefault($5uXOq))).createElement(IssueApp, {
            isEmbedView: true,
            issueResults: detailViewData,
            view: viewData
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireScreenAnalytics), null)));
    };
    $53522b5e6e51bfef$var$DetailView.whyDidYouRender = true;
    var $53522b5e6e51bfef$export$2e2bcd8739ae039 = (0, (0, parcelRequire("1igGa")).withReportErrors)($53522b5e6e51bfef$var$DetailView, {
        id: 'ui.issue-results.detail-view.unhandled',
        packageName: (0, (0, parcelRequire("6UAdF")).PACKAGE_NAME),
        teamName: (0, (0, parcelRequire("6UAdF")).TEAM_NAME),
        sendToPrivacyUnsafeSplunk: true,
        attributes: (0, (0, parcelRequire("gcl1l")).isLoaderErrorAttributes)
    });
    const $53522b5e6e51bfef$var$ContentStyledContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _4t3i1osq _16jlkb7n _1reo15vq _18m915vq",
                __cmplp.className
            ])
        });
    });
    const $53522b5e6e51bfef$var$ContentContainerStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        display: 'flex',
        height: '100%',
        flexGrow: 1,
        overflow: 'hidden',
        flexBasis: 0
    });
});
parcelRegister("dhc4s", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$105aa14c9d2fd9d2$export$2e2bcd8739ae039);
    const $105aa14c9d2fd9d2$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_DetailView_view",
        "selections": [
            {
                "kind": "FragmentSpread",
                "name": "main_issueNavigator_IssueAppWithData_view"
            }
        ],
        "type": "JiraIssueSearchView"
    };
    $105aa14c9d2fd9d2$var$node.hash = "1b2ee54f76732e67326f37ebaa7aeb47";
    var $105aa14c9d2fd9d2$export$2e2bcd8739ae039 = $105aa14c9d2fd9d2$var$node;
});
parcelRegister("2Vq1u", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$30bc58ed3773e514$export$2e2bcd8739ae039);
    const $30bc58ed3773e514$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_DetailView_issueResults",
        "selections": [
            {
                "kind": "FragmentSpread",
                "name": "main_issueNavigator_CardList_fragment"
            },
            {
                "kind": "FragmentSpread",
                "name": "cardContainer_issueNavigator"
            },
            {
                "kind": "FragmentSpread",
                "name": "main_issueNavigator_IssueAppWithData_issueResults"
            }
        ],
        "type": "JiraIssueConnection"
    };
    $30bc58ed3773e514$var$node.hash = "acd05b914a5bafcb574b727c9c0ecbc7";
    var $30bc58ed3773e514$export$2e2bcd8739ae039 = $30bc58ed3773e514$var$node;
});
parcelRegister("kYFm9", function(module, exports) {
    $parcel$export(module.exports, "DEFAULT_SIZE", ()=>$9fb6972593b23474$export$e4c0458e30711294);
    $parcel$export(module.exports, "useElementSizeSelector", ()=>$9fb6972593b23474$export$6af5bd31f067d7e5);
    $parcel$export(module.exports, "useElementSize", ()=>$9fb6972593b23474$export$2b86bedf890eab8);
    $parcel$export(module.exports, "ElementSizeProvider", ()=>$9fb6972593b23474$export$73bf76c50625186);
    var $5uXOq = parcelRequire("5uXOq");
    var $gg7kZ;
    var $3sQ5x;
    var $iXqE5;
    const $9fb6972593b23474$export$e4c0458e30711294 = {
        height: 0,
        width: 0,
        scrollHeight: 0,
        scrollWidth: 0
    };
    const $9fb6972593b23474$export$638cf470f916deb7 = (0, (0, parcelRequire("gg7kZ")).createContainer)();
    const $9fb6972593b23474$export$3378d8ea7d02abd0 = {
        height: 0,
        width: 0
    };
    const $9fb6972593b23474$var$actions = {
        update: (newState)=>({ setState: setState, getState: getState })=>{
                const state = getState();
                setState({
                    ...state,
                    ...newState
                });
            }
    };
    const $9fb6972593b23474$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        containedBy: $9fb6972593b23474$export$638cf470f916deb7,
        initialState: $9fb6972593b23474$export$3378d8ea7d02abd0,
        name: 'ElementSize',
        actions: $9fb6972593b23474$var$actions,
        handlers: {
            onInit: ()=>({ setState: setState, getState: getState }, { width: width, height: height })=>{
                    const state = getState();
                    setState({
                        ...state,
                        width: width,
                        height: height
                    });
                },
            onContainerUpdate: ()=>({ setState: setState, getState: getState }, { width: width, height: height })=>{
                    const state = getState();
                    setState({
                        ...state,
                        width: width,
                        height: height
                    });
                }
        }
    });
    const $9fb6972593b23474$export$6af5bd31f067d7e5 = (0, (0, parcelRequire("3sQ5x")).createStateHook)($9fb6972593b23474$var$Store, {
        selector: (state, cb)=>cb(state)
    });
    const $9fb6972593b23474$export$2b86bedf890eab8 = (elementRef)=>{
        const [size, setSize] = (0, $5uXOq.useState)($9fb6972593b23474$export$e4c0458e30711294);
        (0, $5uXOq.useEffect)(()=>{
            const element = elementRef.current;
            if (element) {
                const resizeObserver = new ResizeObserver(()=>{
                    setSize({
                        height: element.clientHeight,
                        width: element.clientWidth,
                        scrollHeight: element.scrollHeight,
                        scrollWidth: element.scrollWidth
                    });
                });
                resizeObserver.observe(element);
                return ()=>{
                    resizeObserver.disconnect();
                };
            }
        }, [
            elementRef
        ]);
        return size;
    };
    const $9fb6972593b23474$export$73bf76c50625186 = ({ elementRef: elementRef, children: children })=>{
        const { width: width, height: height } = $9fb6972593b23474$export$2b86bedf890eab8(elementRef);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($9fb6972593b23474$export$638cf470f916deb7, {
            width: width,
            height: height
        }, children);
    };
});
parcelRegister("hWtSW", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$75bb3ad05744ab39$export$2e2bcd8739ae039);
    var $96vwt;
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Uumt = parcelRequire("8Uumt");
    var $dC5iT;
    var $f5TWP;
    var $luBm6;
    var $8CUq3;
    var $6s1PB;
    var $4R6GH;
    var $i9xcC;
    var $9CFBJ;
    var $dCm1o;
    var $cm6vO;
    var $cyCoT;
    var $eLt1F;
    var $38r5c;
    var $coNDq;
    var $gWk5r;
    var $3uIgp;
    var $kwVxA;
    const $75bb3ad05744ab39$var$infiniteScrollOptions = {
        threshold: 350
    };
    const $75bb3ad05744ab39$var$CardContainer = ({ children: children, issueResults: issueResults, isFeedbackButtonDisabled: isFeedbackButtonDisabled })=>{
        const data = (0, $8Uumt.useFragment)((0, (0, parcelRequire("96vwt")).default), issueResults);
        if (!(0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) return (0, ($parcel$interopDefault($5uXOq))).createElement($75bb3ad05744ab39$var$StyledContainer, {
            "data-testid": "issue-navigator.ui.issue-results.detail-view.card-container.styled-container",
            visualRefresh: (0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_beta_drop_1') && (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)()
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($75bb3ad05744ab39$var$HeaderStyledContainer, {
            visualRefresh: (0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_beta_drop_1') && (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)()
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gWk5r")).default), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kwVxA")).default), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eLt1F")).default), null)), (0, ($parcel$interopDefault($5uXOq))).createElement($75bb3ad05744ab39$var$CardListStyledContainer, null, children), (0, ($parcel$interopDefault($5uXOq))).createElement($75bb3ad05744ab39$var$FooterStyledContainer, {
            visualRefresh: (0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_beta_drop_1') && (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)()
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3uIgp")).default), {
            issueResults: data
        })), !(0, (0, parcelRequire("4R6GH")).fg)('jira_spreadsheet_component_m1_api_updates') && !isFeedbackButtonDisabled && (0, ($parcel$interopDefault($5uXOq))).createElement($75bb3ad05744ab39$var$FeedbackWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cm6vO")).default), null)));
        const infiniteScrollProps = (0, (0, parcelRequire("38r5c")).useInfiniteScroll)($75bb3ad05744ab39$var$infiniteScrollOptions);
        const { isLoadingPrevious: isLoadingPrevious, isLoadingNext: isLoadingNext } = infiniteScrollProps;
        const { onScroll: onScroll, onLoadingThresholdCheck: onLoadingThresholdCheck } = (0, (0, parcelRequire("i9xcC")).useInfiniteScrollHandler)(infiniteScrollProps);
        const scrollContainerRef = (0, $5uXOq.useRef)(null);
        const totalIssues = data.totalIssueSearchResultCount ?? 0;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("luBm6")).default), {
            space: (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() && (0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_beta_drop_1') ? undefined : 'space.025',
            grow: "fill",
            xcss: (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() && (0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_beta_drop_1') ? $75bb3ad05744ab39$var$containerStyles : $75bb3ad05744ab39$var$containerStylesOld,
            testId: "issue-navigator.ui.issue-results.detail-view.card-container.styled-container"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("f5TWP")).default), {
            space: "space.050",
            xcss: (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() && (0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_beta_drop_1') ? $75bb3ad05744ab39$var$headerStyles : $75bb3ad05744ab39$var$headerStylesOld,
            alignBlock: (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() && (0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_beta_drop_1') ? 'center' : undefined
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gWk5r")).default), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kwVxA")).default), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eLt1F")).default), null)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9CFBJ")).ScrollStateProvider), {
            scrollRef: scrollContainerRef
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("luBm6")).default), {
            grow: "fill",
            xcss: $75bb3ad05744ab39$var$loadingIndicatorContainerStyles
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            ref: scrollContainerRef,
            xcss: $75bb3ad05744ab39$var$cardListStyles,
            onScroll: onScroll
        }, children), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("coNDq")).LoadingManager), {
            isLoadingNext: isLoadingNext,
            isLoadingPrevious: isLoadingPrevious,
            onLoadingThresholdCheck: onLoadingThresholdCheck
        }))), totalIssues != null && totalIssues > 0 && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("f5TWP")).default), {
            alignBlock: "center",
            alignInline: "center",
            xcss: $75bb3ad05744ab39$var$footerStyles
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cyCoT")).default), {
            startIssueRange: -1,
            endIssueRange: -1,
            total: totalIssues,
            countExceedsMaxResults: !!data.isCappingIssueSearchResult,
            loadedIssuesCount: data.edges?.length ?? 0,
            isInfiniteScrollEnabled: true
        })));
    };
    var $75bb3ad05744ab39$export$2e2bcd8739ae039 = $75bb3ad05744ab39$var$CardContainer;
    const $75bb3ad05744ab39$var$StyledContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { visualRefresh: visualRefresh, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _2lx21bp4 _2rko1y44 _otyru2gc _2hwxpxbi _1ul91k99 _p12f1k99",
                __cmplp.visualRefresh ? "_18zrv77o _bfhkqbzx" : "_1yt4v77o _bfhk1s4m",
                __cmplp.className
            ])
        });
    });
    const $75bb3ad05744ab39$var$containerStylesOld = (0, (0, parcelRequire("8CUq3")).xcss)({
        borderRadius: '4px',
        backgroundColor: 'color.background.neutral',
        boxSizing: 'content-box',
        padding: 'space.025',
        marginBottom: 'space.100',
        marginRight: 'space.200',
        minWidth: '256px',
        maxWidth: '256px'
    });
    const $75bb3ad05744ab39$var$containerStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        borderRadius: '4px',
        backgroundColor: 'elevation.surface.sunken',
        boxSizing: 'content-box',
        paddingInline: 'space.025',
        marginBottom: 'space.100',
        marginRight: 'space.200',
        minWidth: '256px',
        maxWidth: '256px'
    });
    const $75bb3ad05744ab39$var$CardListStyledContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1reo1wug _18m91wug _16jlkb7n _1o9zkb7n _i0dl1kxc",
                __cmplp.className
            ])
        });
    });
    const $75bb3ad05744ab39$var$HeaderStyledContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { visualRefresh: visualRefresh, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _16jlkb7n _1o9zidpf _zulp1b66",
                __cmplp.visualRefresh ? "_4t3i1ylp _4cvr1h6o _18zrv77o _otyr1i6y" : "_n3tdv77o _4t3izwfg",
                __cmplp.className
            ])
        });
    });
    const $75bb3ad05744ab39$var$headerStylesOld = (0, (0, parcelRequire("8CUq3")).xcss)({
        flexGrow: 1,
        flexShrink: 0,
        height: '32px'
    });
    const $75bb3ad05744ab39$var$headerStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        flexGrow: 1,
        flexShrink: 0,
        height: '40px',
        marginBottom: 'space.negative.025',
        paddingInline: 'space.025'
    });
    const $75bb3ad05744ab39$var$loadingIndicatorContainerStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        flexGrow: '1',
        flexShrink: '1',
        flexBasis: '100vh',
        overflow: 'hidden',
        position: 'relative'
    });
    const $75bb3ad05744ab39$var$cardListStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        flexGrow: '1',
        overflow: 'auto'
    });
    const $75bb3ad05744ab39$var$FooterStyledContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { visualRefresh: visualRefresh, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _4cvr1h6o _1bah1h6o",
                __cmplp.visualRefresh ? "_18zrv77o _ca0qv77o" : "_1yt4sb0y",
                __cmplp.className
            ])
        });
    });
    const $75bb3ad05744ab39$var$footerStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        color: 'color.text.subtlest',
        paddingBlock: 'space.050',
        textAlign: 'center'
    });
    const $75bb3ad05744ab39$var$FeedbackWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_bfhkvuon _1bsbu6a3 _ca0qpxbi _n3tdv77o _1e0c1txw _1bah1h6o _19pkidpf _otyrx0bf _18u0x0bf _2hwxx0bf",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("96vwt", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$5c5dbfbaae703896$export$2e2bcd8739ae039);
    const $5c5dbfbaae703896$var$node = {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
            }
        ],
        "kind": "Fragment",
        "name": "cardContainer_issueNavigator",
        "selections": [
            {
                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                    {
                        "kind": "FragmentSpread",
                        "name": "main_issueNavigator_Paginator"
                    }
                ]
            },
            {
                "concreteType": "JiraIssueEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                    {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "name": "__typename"
                            }
                        ]
                    }
                ]
            },
            {
                "kind": "ScalarField",
                "name": "totalIssueSearchResultCount"
            },
            {
                "kind": "ScalarField",
                "name": "isCappingIssueSearchResult"
            }
        ],
        "type": "JiraIssueConnection"
    };
    $5c5dbfbaae703896$var$node.hash = "66b29bf73a464e68344ae2f85de0b147";
    var $5c5dbfbaae703896$export$2e2bcd8739ae039 = $5c5dbfbaae703896$var$node;
});
parcelRegister("i9xcC", function(module, exports) {
    $parcel$export(module.exports, "useInfiniteScrollHandler", ()=>$b2b86989bea72b31$export$ee8d81e7d8c9f22d);
    var $5uXOq = parcelRequire("5uXOq");
    var $czfFt;
    var $dbaUr;
    const $b2b86989bea72b31$var$DEFAULT_ACTIVATION_THRESHOLD = 10 * (0, (0, parcelRequire("czfFt")).ROW_HEIGHT);
    const $b2b86989bea72b31$export$ee8d81e7d8c9f22d = ({ onLoadNext: onLoadNextProp, onLoadPrevious: onLoadPreviousProp, ...dataProps })=>{
        const dataPropsRef = (0, $5uXOq.useRef)(dataProps);
        dataPropsRef.current = dataProps;
        const onLoadNext = (0, (0, parcelRequire("dbaUr")).useThrottle)(onLoadNextProp);
        const onLoadPrevious = (0, (0, parcelRequire("dbaUr")).useThrottle)(onLoadPreviousProp);
        const onLoadingThresholdCheck = (0, $5uXOq.useCallback)(({ clientHeight: clientHeight, scrollHeight: scrollHeight, scrollTop: scrollTop })=>{
            const { isLoadingNext: isLoadingNext, isLoadingPrevious: isLoadingPrevious, hasNext: hasNext, hasPrevious: hasPrevious, activationThreshold: activationThreshold = $b2b86989bea72b31$var$DEFAULT_ACTIVATION_THRESHOLD } = dataPropsRef.current;
            const scrollBottom = scrollHeight - clientHeight - scrollTop;
            const isLoading = isLoadingNext || isLoadingPrevious;
            if (hasNext && !isLoading && scrollBottom <= activationThreshold) onLoadNext?.();
            else if (hasPrevious && !isLoading && scrollTop <= activationThreshold) onLoadPrevious?.();
        }, [
            onLoadNext,
            onLoadPrevious
        ]);
        const onScroll = (0, $5uXOq.useCallback)((event)=>{
            onLoadingThresholdCheck(event.currentTarget);
        }, [
            onLoadingThresholdCheck
        ]);
        return {
            onLoadingThresholdCheck: onLoadingThresholdCheck,
            onScroll: onScroll
        };
    };
});
parcelRegister("dbaUr", function(module, exports) {
    $parcel$export(module.exports, "useThrottle", ()=>$9793367875371ab2$export$8b7b9481d6186f21);
    var $5uXOq = parcelRequire("5uXOq");
    const $9793367875371ab2$export$8b7b9481d6186f21 = (fn, wait = 100)=>{
        const fnRef = (0, $5uXOq.useRef)(fn);
        fnRef.current = fn;
        const waitRef = (0, $5uXOq.useRef)(wait);
        waitRef.current = wait;
        const timeout = (0, $5uXOq.useRef)(undefined);
        const throttled = (0, $5uXOq.useCallback)(()=>{
            if (timeout.current !== undefined) return;
            fnRef.current?.();
            timeout.current = setTimeout(()=>{
                timeout.current = undefined;
            }, waitRef.current);
        }, []);
        (0, $5uXOq.useEffect)(()=>{
            return ()=>{
                if (timeout.current !== undefined) clearTimeout(timeout.current);
            };
        }, []);
        return throttled;
    };
});
parcelRegister("9CFBJ", function(module, exports) {
    $parcel$export(module.exports, "useScrollStateSelector", ()=>$502b0ac1011ec6e7$export$a2d79f83724ed591);
    $parcel$export(module.exports, "useScrollElement", ()=>$502b0ac1011ec6e7$export$fae04c81b2201f65);
    $parcel$export(module.exports, "ScrollStateProvider", ()=>$502b0ac1011ec6e7$export$9f9741e7dc6bdb9b);
    var $5uXOq = parcelRequire("5uXOq");
    var $3sQ5x;
    var $gg7kZ;
    var $iXqE5;
    const $502b0ac1011ec6e7$export$50b6b7783d156e1b = 200;
    const $502b0ac1011ec6e7$export$f9abcb6f103965a2 = (0, (0, parcelRequire("gg7kZ")).createContainer)();
    const $502b0ac1011ec6e7$var$INITIAL_SCROLL_STATE = {
        height: 0,
        width: 0,
        scrollHeight: 0,
        scrollWidth: 0,
        scrollTop: 0,
        scrollLeft: 0,
        isScrolling: false,
        scrollElement: null
    };
    const $502b0ac1011ec6e7$var$actions = {
        update: (newState)=>({ setState: setState, getState: getState })=>{
                const state = getState();
                setState({
                    ...state,
                    ...newState
                });
            }
    };
    const $502b0ac1011ec6e7$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        containedBy: $502b0ac1011ec6e7$export$f9abcb6f103965a2,
        initialState: $502b0ac1011ec6e7$var$INITIAL_SCROLL_STATE,
        name: 'ScrollStateManager',
        actions: $502b0ac1011ec6e7$var$actions
    });
    const $502b0ac1011ec6e7$var$useScrollStateActions = (0, (0, parcelRequire("3sQ5x")).createActionsHook)($502b0ac1011ec6e7$var$Store);
    const $502b0ac1011ec6e7$export$a2d79f83724ed591 = (0, (0, parcelRequire("3sQ5x")).createStateHook)($502b0ac1011ec6e7$var$Store, {
        selector: (state, cb)=>cb(state)
    });
    const $502b0ac1011ec6e7$export$fae04c81b2201f65 = (0, (0, parcelRequire("3sQ5x")).createStateHook)($502b0ac1011ec6e7$var$Store, {
        selector: (state)=>state.scrollElement
    });
    const $502b0ac1011ec6e7$var$attachResizeObserver = (callback, targetEl)=>{
        if (!targetEl) return ()=>{};
        let resizeObserver = null;
        if (typeof ResizeObserver !== 'undefined') resizeObserver = new ResizeObserver(()=>callback());
        if (targetEl instanceof Window) targetEl.addEventListener('resize', callback);
        else resizeObserver?.observe(targetEl);
        return ()=>{
            if (targetEl instanceof Window) targetEl.removeEventListener('resize', callback);
            else resizeObserver?.disconnect();
        };
    };
    const $502b0ac1011ec6e7$var$useElementScrollState = (scrollRef)=>{
        const { update: update } = $502b0ac1011ec6e7$var$useScrollStateActions();
        (0, $5uXOq.useEffect)(()=>{
            const targetEl = scrollRef.current;
            if (!targetEl) return undefined;
            let clearScrollingTimeoutId;
            const onScroll = ()=>{
                update({
                    scrollTop: targetEl.scrollTop,
                    scrollLeft: targetEl.scrollLeft,
                    isScrolling: true
                });
                clearTimeout(clearScrollingTimeoutId);
                clearScrollingTimeoutId = setTimeout(()=>{
                    update({
                        scrollTop: targetEl.scrollTop,
                        scrollLeft: targetEl.scrollLeft,
                        isScrolling: false
                    });
                }, $502b0ac1011ec6e7$export$50b6b7783d156e1b);
            };
            const onResize = ()=>{
                update({
                    scrollTop: targetEl.scrollTop,
                    scrollLeft: targetEl.scrollLeft,
                    height: targetEl.clientHeight,
                    width: targetEl.clientWidth,
                    scrollHeight: targetEl.scrollHeight,
                    scrollWidth: targetEl.scrollWidth,
                    isScrolling: true
                });
                clearTimeout(clearScrollingTimeoutId);
                clearScrollingTimeoutId = setTimeout(()=>{
                    update({
                        scrollTop: targetEl.scrollTop,
                        scrollLeft: targetEl.scrollLeft,
                        isScrolling: false
                    });
                }, $502b0ac1011ec6e7$export$50b6b7783d156e1b);
            };
            const cleanupResizeObserver = $502b0ac1011ec6e7$var$attachResizeObserver(onResize, targetEl);
            targetEl.addEventListener('scroll', onScroll);
            update({
                height: targetEl.clientHeight,
                width: targetEl.clientWidth,
                scrollHeight: targetEl.scrollHeight,
                scrollWidth: targetEl.scrollWidth,
                scrollTop: targetEl.scrollTop,
                scrollLeft: targetEl.scrollLeft,
                isScrolling: false,
                scrollElement: targetEl
            });
            return ()=>{
                targetEl.removeEventListener('scroll', onScroll);
                cleanupResizeObserver();
                clearTimeout(clearScrollingTimeoutId);
            };
        }, [
            scrollRef,
            update
        ]);
    };
    const $502b0ac1011ec6e7$var$ScrollStateProviderInner = ({ scrollRef: scrollRef, children: children })=>{
        $502b0ac1011ec6e7$var$useElementScrollState(scrollRef);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children);
    };
    const $502b0ac1011ec6e7$export$9f9741e7dc6bdb9b = (props)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement($502b0ac1011ec6e7$export$f9abcb6f103965a2, null, (0, ($parcel$interopDefault($5uXOq))).createElement($502b0ac1011ec6e7$var$ScrollStateProviderInner, props));
    };
});
parcelRegister("cm6vO", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$8973d9c69bbbfef1$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $dC5iT;
    var $luBm6;
    var $cgL5K;
    var $8CUq3;
    var $6s1PB;
    var $7iULw;
    var $coTzA;
    var $koVbs;
    var $bw7Gm;
    var $fl4Hs;
    const $8973d9c69bbbfef1$var$FEEDBACK_GROUP_ID = 'issue-navigator';
    const $8973d9c69bbbfef1$var$FeedbackTitleDetails = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $8973d9c69bbbfef1$var$feedbackTitleDetailsContainerStyles
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("luBm6")).default), {
            space: "space.150"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
            as: "p"
        }, formatMessage((0, (0, parcelRequire("fl4Hs")).default).feedbackTitleDetails)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
            as: "p"
        }, formatMessage((0, (0, parcelRequire("fl4Hs")).default).feedbackTitleDetailsContactSupport))));
    };
    const $8973d9c69bbbfef1$var$Feedback = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const feedbackContext = [
            {
                id: (0, (0, parcelRequire("7iULw")).EntryKeys).LOCATION,
                value: (0, (0, parcelRequire("bw7Gm")).default)(window?.location?.href ?? '')
            },
            {
                id: (0, (0, parcelRequire("7iULw")).EntryKeys).SCREEN_RESOLUTION,
                value: `${window?.screen?.width ?? 'unknown'} x ${window?.screen?.height ?? 'unknown'}`
            },
            {
                id: (0, (0, parcelRequire("7iULw")).EntryKeys).USER_AGENT,
                value: window?.navigator?.userAgent ?? 'unknown'
            }
        ];
        const feedbackGroupLabels = {
            empty: {
                selectOptionLabel: formatMessage((0, (0, parcelRequire("fl4Hs")).default).feedbackTypePlaceholder)
            },
            bug: {},
            comment: {},
            suggestion: {},
            question: {}
        };
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("coTzA")).FeedbackCollectorButton), {
            testId: "issue-navigator.common.ui.feedback.feedback-button",
            entrypointId: (0, (0, parcelRequire("7iULw")).ENTRYPOINT_ID_JIRA_PLATFORM),
            feedbackGroupId: $8973d9c69bbbfef1$var$FEEDBACK_GROUP_ID,
            feedbackTitle: formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("fl4Hs")).default).feedbackTitleIssueTermRefresh : (0, (0, parcelRequire("fl4Hs")).default).feedbackTitle),
            feedbackTitleDetails: (0, ($parcel$interopDefault($5uXOq))).createElement($8973d9c69bbbfef1$var$FeedbackTitleDetails, null),
            feedbackContext: feedbackContext,
            feedbackGroupLabels: feedbackGroupLabels
        });
    };
    var $8973d9c69bbbfef1$export$2e2bcd8739ae039 = $8973d9c69bbbfef1$var$Feedback;
    const $8973d9c69bbbfef1$var$feedbackTitleDetailsContainerStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        marginBottom: 'space.300'
    });
});
parcelRegister("coTzA", function(module, exports) {
    $parcel$export(module.exports, "FeedbackCollectorButton", ()=>$85f5e51eda76fbac$export$b39479f42d974760);
    var $5uXOq = parcelRequire("5uXOq");
    var $aEHvW;
    var $3bDjY;
    var $koVbs;
    var $koeKL;
    var $7iULw;
    var $dseDh;
    var $4uMrp;
    var $78EsV;
    const $85f5e51eda76fbac$var$TooltipWrapper = ({ children: children, appearance: appearance, customLabel: customLabel })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        if (appearance === 'icon') return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
            content: customLabel || formatMessage((0, (0, parcelRequire("4uMrp")).messages).leaveFeedback),
            position: "top"
        }, children);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children);
    };
    const $85f5e51eda76fbac$export$b39479f42d974760 = ({ buttonAppearance: buttonAppearance = 'normal', testId: testId, icon: Icon = (0, ($parcel$interopDefault((0, parcelRequire("aEHvW"))))), feedbackButtonCustomLabel: feedbackButtonCustomLabel, ...feedbackCollectorProps })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const { openFeedbackCollector: openFeedbackCollector } = (0, (0, parcelRequire("dseDh")).useFeedbackCollectorActions)();
        const buttonRef = (0, $5uXOq.useRef)(null);
        const onOpen = (0, $5uXOq.useCallback)(()=>{
            const hasCloudId = feedbackCollectorProps?.feedbackContext?.some((entry)=>entry.id === (0, (0, parcelRequire("7iULw")).EntryKeys).CLOUD_ID);
            const updatedFeedbackContext = hasCloudId ? feedbackCollectorProps.feedbackContext : [
                ...feedbackCollectorProps.feedbackContext || [],
                {
                    id: (0, (0, parcelRequire("7iULw")).EntryKeys).CLOUD_ID,
                    value: cloudId
                }
            ];
            openFeedbackCollector({
                ...feedbackCollectorProps,
                feedbackContext: updatedFeedbackContext,
                triggerRef: buttonRef
            });
        }, [
            cloudId,
            feedbackCollectorProps,
            openFeedbackCollector
        ]);
        const displayAsLink = buttonAppearance === 'link';
        return (0, ($parcel$interopDefault($5uXOq))).createElement($85f5e51eda76fbac$var$TooltipWrapper, {
            appearance: buttonAppearance,
            customLabel: feedbackButtonCustomLabel
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("78EsV")).StyledButton), {
            testId: testId,
            ref: buttonRef,
            appearance: displayAsLink ? 'link' : 'subtle',
            isHighlight: buttonAppearance === 'highlight',
            spacing: displayAsLink ? 'none' : 'default',
            onClick: onOpen,
            iconBefore: displayAsLink ? undefined : (0, ($parcel$interopDefault($5uXOq))).createElement(Icon, {
                color: buttonAppearance === 'highlight' ? "var(--ds-text-discovery, #5E4DB2)" : "var(--ds-text, #172B4D)",
                label: ""
            }),
            "aria-label": feedbackButtonCustomLabel || formatMessage((0, (0, parcelRequire("4uMrp")).messages).leaveFeedback)
        }, buttonAppearance !== 'icon' && (feedbackButtonCustomLabel || formatMessage((0, (0, parcelRequire("4uMrp")).messages).leaveFeedback))));
    };
});
parcelRegister("aEHvW", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $7c1f64cf4f9c421f$var$_react = $7c1f64cf4f9c421f$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $7c1f64cf4f9c421f$var$_feedback = $7c1f64cf4f9c421f$var$_interopRequireDefault((parcelRequire("5dqGX")));
    var $7c1f64cf4f9c421f$var$_feedback2 = $7c1f64cf4f9c421f$var$_interopRequireDefault((parcelRequire("3g1Fk")));
    function $7c1f64cf4f9c421f$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $7c1f64cf4f9c421f$var$FeedbackIcon = (props)=>$7c1f64cf4f9c421f$var$_react.default.createElement($7c1f64cf4f9c421f$var$_feedback.default, Object.assign({
            LEGACY_fallbackIcon: $7c1f64cf4f9c421f$var$_feedback2.default
        }, props));
    $7c1f64cf4f9c421f$var$FeedbackIcon.Name = 'FeedbackIconMigration';
    var $7c1f64cf4f9c421f$var$_default = module.exports.default = $7c1f64cf4f9c421f$var$FeedbackIcon;
});
parcelRegister("5dqGX", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $3cc2df171aca9493$var$_react = $3cc2df171aca9493$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $3cc2df171aca9493$var$_UNSAFE_baseNew = $3cc2df171aca9493$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $3cc2df171aca9493$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $3cc2df171aca9493$var$FeedbackIcon = (props)=>$3cc2df171aca9493$var$_react.default.createElement($3cc2df171aca9493$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-linejoin="round" stroke-width="1.5" d="M1.75 9.5V15L6 12.25h7c.69 0 1.25-.56 1.25-1.25V3c0-.69-.56-1.25-1.25-1.25H3c-.69 0-1.25.56-1.25 1.25v2.75C1.75 6.44 2.31 7 3 7h6.75m-2-2.5 2.5 2.5-2.5 2.5"/>`
        }, props));
    $3cc2df171aca9493$var$FeedbackIcon.displayName = 'FeedbackIcon';
    var $3cc2df171aca9493$var$_default = module.exports.default = $3cc2df171aca9493$var$FeedbackIcon;
});
parcelRegister("3g1Fk", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $25f4287f17f73dc4$var$_react = $25f4287f17f73dc4$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $25f4287f17f73dc4$var$_feedback = $25f4287f17f73dc4$var$_interopRequireDefault((parcelRequire("5dqGX")));
    function $25f4287f17f73dc4$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $25f4287f17f73dc4$var$FeedbackIcon = (props)=>$25f4287f17f73dc4$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><path fill="currentcolor" fill-rule="evenodd" d="m10.88 5.48-8.43 6.83c-.4.32-.58.96-.41 1.42l.1.27c.17.46.73.83 1.23.82l1.13-.02 6.06-.1 3.65-.06c.51-.01.79-.38.62-.86l-2.92-8.03c-.1-.29-.32-.44-.57-.44a.73.73 0 0 0-.46.18m-5.9 10.46 1.75 4.82a1 1 0 1 0 1.88-.68l-1.54-4.23zm13.71-9.11-2.82 1.03a1 1 0 1 0 .68 1.88l2.82-1.03a1 1 0 1 0-.68-1.88m-1.79 3.85a1 1 0 0 0-.64.77 1 1 0 0 0 .81 1.16l2.96.52a1 1 0 0 0 1.12-1.3l-.02-.05a1 1 0 0 0-.76-.62l-2.95-.52a.97.97 0 0 0-.51.05m-.56-7.65-1.93 2.3a1 1 0 0 0 1.53 1.29l1.93-2.3a1 1 0 0 0-.76-1.64 1 1 0 0 0-.77.36"/></svg>`
        }, props, {
            newIcon: $25f4287f17f73dc4$var$_feedback.default
        }));
    $25f4287f17f73dc4$var$FeedbackIcon.displayName = 'FeedbackIcon';
    var $25f4287f17f73dc4$var$_default = module.exports.default = $25f4287f17f73dc4$var$FeedbackIcon;
});
parcelRegister("4uMrp", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$0c42512912e196d1$export$defe85febe8b728c);
    var $7VHMR;
    const $0c42512912e196d1$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        leaveFeedback: {
            "id": "platform.feedback.feedback-collector.button.leave-feedback",
            "defaultMessage": "Give feedback"
        }
    });
});
parcelRegister("78EsV", function(module, exports) {
    $parcel$export(module.exports, "StyledButton", ()=>$7dd11fab8c31bfcb$export$2d5d2ba3c7c8c40b);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $fVnbM;
    const $7dd11fab8c31bfcb$export$2d5d2ba3c7c8c40b = (0, $5uXOq.forwardRef)(({ as: C = (0, (0, parcelRequire("fVnbM")).default), style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "",
                __cmplp.isHighlight && "_1w901gqr _16hg1y1p _1fh11gqr _u1mb1hhp _1l5a1gfe _r7sm8kjj",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("fl4Hs", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$889474e72c6fb59f$export$2e2bcd8739ae039);
    var $7VHMR;
    const $889474e72c6fb59f$var$messages = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        feedbackTitle: {
            "id": "issue-navigator.common.feedback.feedback-title",
            "defaultMessage": "Give feedback for issue search"
        },
        feedbackTitleDetails: {
            "id": "issue-navigator.common.feedback.feedback-title-details",
            "defaultMessage": "Let us know how we can improve this experience."
        },
        feedbackTitleDetailsContactSupport: {
            "id": "issue-navigator.common.feedback.feedback-title-details-contact-support",
            "defaultMessage": "If you\u2019re having technical issues, contact our support team."
        },
        feedbackTypePlaceholder: {
            "id": "issue-navigator.common.feedback.feedback-type-placeholder",
            "defaultMessage": "Select an option"
        },
        feedbackTitleIssueTermRefresh: {
            "id": "issue-navigator.common.feedback.feedback-title-issue-term-refresh",
            "defaultMessage": "Give feedback"
        }
    });
    var $889474e72c6fb59f$export$2e2bcd8739ae039 = $889474e72c6fb59f$var$messages;
});
parcelRegister("cyCoT", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$8cb075bc3ee6def7$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $koVbs;
    var $79uRg;
    var $b71Xi;
    var $8cb075bc3ee6def7$export$2e2bcd8739ae039 = ({ startIssueRange: startIssueRange, endIssueRange: endIssueRange, loadedIssuesCount: loadedIssuesCount, total: total, countExceedsMaxResults: countExceedsMaxResults, isInfiniteScrollEnabled: isInfiniteScrollEnabled })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const isTotalUnknown = total <= 0;
        if (isInfiniteScrollEnabled) {
            if (isTotalUnknown) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, loadedIssuesCount);
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("79uRg")).default), {
                prependMessage: (0, (0, parcelRequire("b71Xi")).default).loadedIssuesTotalKnown,
                prependMessageValues: {
                    loadedIssuesCount: loadedIssuesCount
                },
                stableCount: total,
                isCapped: countExceedsMaxResults
            });
        }
        if (isTotalUnknown) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, formatMessage((0, (0, parcelRequire("b71Xi")).default).issueRangeUnknown, {
            startIssueRange: startIssueRange,
            endIssueRange: endIssueRange
        }));
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("79uRg")).default), {
            prependMessage: (0, (0, parcelRequire("b71Xi")).default).issueRangeKnown,
            prependMessageValues: {
                startIssueRange: startIssueRange,
                endIssueRange: endIssueRange
            },
            stableCount: total,
            isCapped: countExceedsMaxResults
        });
    };
});
parcelRegister("b71Xi", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$db9773df3a4a055b$export$2e2bcd8739ae039);
    var $7VHMR;
    var $db9773df3a4a055b$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        loadedIssuesTotalKnown: {
            "id": "issue-navigator.common.page-text.loaded-issues-total-known",
            "defaultMessage": "{loadedIssuesCount} of {totalNumberIssues}"
        },
        issueRangeKnown: {
            "id": "issue-navigator.card-container.paginator.issue-range-known",
            "defaultMessage": "{startIssueRange}-{endIssueRange} of {totalNumberIssues}"
        },
        issueRangeUnknown: {
            "id": "issue-navigator.card-container.paginator.issue-range-unknown",
            "defaultMessage": "{startIssueRange}-{endIssueRange}"
        }
    });
});
parcelRegister("eLt1F", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$b5cafba943267706$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $42CjB;
    var $jOrGg;
    var $6s1PB;
    var $koVbs;
    var $8zOmE;
    var $isIOR;
    var $38r5c;
    var $lrrHu;
    const $b5cafba943267706$var$RefreshButton = ()=>{
        let isRefreshing;
        let onRefresh;
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
            isRefreshing = (0, (0, parcelRequire("38r5c")).useIsRefreshing)();
            ({ onRefresh: onRefresh } = (0, (0, parcelRequire("38r5c")).useIssueSearchActions)());
        } else ({ isRefreshing: isRefreshing, onIssueSearchRefresh: onRefresh } = (0, (0, parcelRequire("isIOR")).useIssueSearchQuery)());
        const handleClick = (0, $5uXOq.useCallback)((_, analyticsEvent)=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'reloadIssueListButton');
            onRefresh();
        }, [
            onRefresh
        ]);
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("42CjB")).default), {
            appearance: "subtle",
            onClick: handleClick,
            testId: "issue-navigator.common.ui.refresh-button.refresh-button",
            label: formatMessage((0, (0, parcelRequire("lrrHu")).default).tooltip),
            icon: (iconProps)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("jOrGg"))))), {
                    ...iconProps,
                    color: "var(--ds-icon-subtle, #626F86)",
                    LEGACY_size: "small",
                    LEGACY_primaryColor: "color.icon.subtle"
                }),
            isLoading: isRefreshing
        });
    };
    var $b5cafba943267706$export$2e2bcd8739ae039 = $b5cafba943267706$var$RefreshButton;
});
parcelRegister("jOrGg", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $e6c828a42ee64c30$var$_react = $e6c828a42ee64c30$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $e6c828a42ee64c30$var$_refresh = $e6c828a42ee64c30$var$_interopRequireDefault((parcelRequire("gPelT")));
    var $e6c828a42ee64c30$var$_refresh2 = $e6c828a42ee64c30$var$_interopRequireDefault((parcelRequire("75Jbm")));
    function $e6c828a42ee64c30$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $e6c828a42ee64c30$var$RefreshIcon = (props)=>$e6c828a42ee64c30$var$_react.default.createElement($e6c828a42ee64c30$var$_refresh.default, Object.assign({
            LEGACY_fallbackIcon: $e6c828a42ee64c30$var$_refresh2.default
        }, props));
    $e6c828a42ee64c30$var$RefreshIcon.Name = 'RefreshIconMigration';
    var $e6c828a42ee64c30$var$_default = module.exports.default = $e6c828a42ee64c30$var$RefreshIcon;
});
parcelRegister("gPelT", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $c3fc8ad083e2745c$var$_react = $c3fc8ad083e2745c$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c3fc8ad083e2745c$var$_UNSAFE_baseNew = $c3fc8ad083e2745c$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $c3fc8ad083e2745c$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $c3fc8ad083e2745c$var$RefreshIcon = (props)=>$c3fc8ad083e2745c$var$_react.default.createElement($c3fc8ad083e2745c$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-linejoin="round" stroke-width="1.5" d="M6.25 6V1.75H2m5.5 12.48c-3.218-.254-5.75-2.447-5.75-5.73 0-3.368 2.42-4.921 4-6.29m4 7.77v4.25H14M8.5 1.75c3.218.255 5.75 2.447 5.75 5.73 0 3.352-2.397 4.906-3.977 6.27"/>`
        }, props));
    $c3fc8ad083e2745c$var$RefreshIcon.displayName = 'RefreshIcon';
    var $c3fc8ad083e2745c$var$_default = module.exports.default = $c3fc8ad083e2745c$var$RefreshIcon;
});
parcelRegister("75Jbm", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $529c0c456318696f$var$_react = $529c0c456318696f$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $529c0c456318696f$var$_refresh = $529c0c456318696f$var$_interopRequireDefault((parcelRequire("gPelT")));
    function $529c0c456318696f$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $529c0c456318696f$var$RefreshIcon = (props)=>$529c0c456318696f$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><g fill="currentcolor" fill-rule="evenodd"><path fill-rule="nonzero" d="M8 6.003v2.995a1 1 0 1 0 2 0V5.102C10 4.494 9.507 4 8.9 4H5a1 1 0 0 0 0 2.003z"/><path fill-rule="nonzero" d="M9.428 18.018C7.351 16.989 6 14.807 6 12.37c0-2.266 1.167-4.319 3.02-5.425.48-.286.646-.922.371-1.421a.98.98 0 0 0-1.364-.386C5.557 6.611 4 9.35 4 12.37c0 3.248 1.802 6.158 4.57 7.529.498.247 1.095.026 1.332-.493.237-.52.025-1.141-.474-1.388"/><path d="M14 15.002v3.896c0 .608.493 1.102 1.1 1.102H19a1 1 0 0 0 0-2.003h-3v-2.995a1 1 0 1 0-2 0"/><path d="M14.097 4.596c-.237.52-.025 1.14.474 1.387 2.077 1.029 3.428 3.21 3.428 5.647 0 2.266-1.167 4.32-3.021 5.425a1.063 1.063 0 0 0-.37 1.42c.274.5.885.673 1.364.387 2.47-1.473 4.027-4.212 4.027-7.232 0-3.248-1.802-6.158-4.57-7.528A.96.96 0 0 0 15.002 4a1 1 0 0 0-.905.596"/></g></svg>`
        }, props, {
            newIcon: $529c0c456318696f$var$_refresh.default
        }));
    $529c0c456318696f$var$RefreshIcon.displayName = 'RefreshIcon';
    var $529c0c456318696f$var$_default = module.exports.default = $529c0c456318696f$var$RefreshIcon;
});
parcelRegister("lrrHu", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$3d0ea2577d797cdc$export$2e2bcd8739ae039);
    var $7VHMR;
    var $3d0ea2577d797cdc$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        tooltip: {
            "id": "issue-navigator.refresh-button.tooltip",
            "defaultMessage": "Refresh"
        },
        loading: {
            "id": "issue-navigator.refresh-button.loading",
            "defaultMessage": "Loading"
        }
    });
});
parcelRegister("coNDq", function(module, exports) {
    $parcel$export(module.exports, "LoadingManager", ()=>$af47037513790f77$export$99cc795e99919eed);
    var $5uXOq = parcelRequire("5uXOq");
    var $dqoiX;
    var $koVbs;
    var $kYFm9;
    var $9CFBJ;
    var $1XJ20;
    var $50QiF;
    var $arPIt;
    const $af47037513790f77$export$99cc795e99919eed = ({ isLoadingPrevious: isLoadingPrevious, isLoadingNext: isLoadingNext, onLoadingThresholdCheck: onLoadingThresholdCheck })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const hasListHeight = (0, (0, parcelRequire("kYFm9")).useElementSizeSelector)((listElm)=>Boolean(listElm.height));
        const scrollContainerHeight = (0, (0, parcelRequire("9CFBJ")).useScrollStateSelector)((scrollState)=>scrollState.height);
        const scrollElement = (0, (0, parcelRequire("9CFBJ")).useScrollElement)();
        (0, $5uXOq.useEffect)(()=>{
            if (scrollElement && onLoadingThresholdCheck && hasListHeight && !isLoadingPrevious && !isLoadingNext) onLoadingThresholdCheck(scrollElement);
        }, [
            hasListHeight,
            isLoadingPrevious,
            isLoadingNext,
            onLoadingThresholdCheck,
            scrollElement
        ]);
        const prevHeight = (0, (0, parcelRequire("1XJ20")).usePreviousWithInitial)(scrollContainerHeight);
        (0, $5uXOq.useEffect)(()=>{
            if (scrollElement && onLoadingThresholdCheck && hasListHeight && scrollContainerHeight > prevHeight) onLoadingThresholdCheck(scrollElement);
        }, [
            hasListHeight,
            scrollContainerHeight,
            prevHeight,
            onLoadingThresholdCheck,
            scrollElement
        ]);
        const prevIsLoadingPrevious = (0, (0, parcelRequire("1XJ20")).usePreviousWithInitial)(isLoadingPrevious);
        const hasStartedLoadingPrevious = !prevIsLoadingPrevious && isLoadingPrevious;
        const hasFinishedLoadingPrevious = prevIsLoadingPrevious && !isLoadingPrevious;
        const scrollHeightRef = (0, $5uXOq.useRef)(0);
        (0, $5uXOq.useEffect)(()=>{
            if (scrollElement && hasStartedLoadingPrevious) scrollHeightRef.current = scrollElement.scrollHeight;
        }, [
            hasStartedLoadingPrevious,
            scrollElement
        ]);
        (0, $5uXOq.useLayoutEffect)(()=>{
            if (scrollElement && hasFinishedLoadingPrevious) {
                const newScrollHeight = scrollElement.scrollHeight;
                if (newScrollHeight > scrollHeightRef.current) {
                    const heightIncrease = newScrollHeight - scrollHeightRef.current;
                    scrollElement.scrollBy(0, heightIncrease);
                }
                scrollHeightRef.current = newScrollHeight;
            }
        }, [
            hasFinishedLoadingPrevious,
            scrollElement
        ]);
        const prevIsLoadingNext = (0, (0, parcelRequire("1XJ20")).usePreviousWithInitial)(isLoadingNext);
        const hasFinishedLoadingNext = prevIsLoadingNext && !isLoadingNext;
        const loadingCompleteLiveMessage = (0, $5uXOq.useRef)(undefined);
        if (isLoadingPrevious) loadingCompleteLiveMessage.current = (0, (0, parcelRequire("arPIt")).default).loadStartA11YMessageTop;
        else if (hasFinishedLoadingPrevious) loadingCompleteLiveMessage.current = (0, (0, parcelRequire("arPIt")).default).loadEndA11YMessageTop;
        else if (isLoadingNext) loadingCompleteLiveMessage.current = (0, (0, parcelRequire("arPIt")).default).loadStartA11YMessageBottom;
        else if (hasFinishedLoadingNext) loadingCompleteLiveMessage.current = (0, (0, parcelRequire("arPIt")).default).loadEndA11YMessageBottom;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, isLoadingPrevious && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("50QiF")).LoadingIndicator), {
            position: "top"
        }), isLoadingNext && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("50QiF")).LoadingIndicator), {
            position: "bottom"
        }), loadingCompleteLiveMessage.current && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dqoiX")).ScreenReaderText), {
            role: "alert",
            "aria-live": "polite"
        }, formatMessage(loadingCompleteLiveMessage.current)));
    };
});
parcelRegister("50QiF", function(module, exports) {
    $parcel$export(module.exports, "LoadingIndicator", ()=>$143e097710c21fde$export$7c20be4c1d342248);
    var $5uXOq = parcelRequire("5uXOq");
    var $f5TWP;
    var $cgL5K;
    var $8CUq3;
    var $eZJjh;
    var $koVbs;
    var $kAT4r;
    const $143e097710c21fde$export$7c20be4c1d342248 = ({ position: position })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("f5TWP")).default), {
            alignInline: "center",
            xcss: [
                $143e097710c21fde$var$containerStyles,
                position === 'top' ? $143e097710c21fde$var$positionTopStyles : $143e097710c21fde$var$positionBottomStyles
            ]
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("f5TWP")).default), {
            space: "space.100",
            alignBlock: "center",
            grow: "hug",
            xcss: $143e097710c21fde$var$loadingIndicatorStyles
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eZJjh")).default), {
            appearance: "invert",
            size: "small"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
            color: "color.text.inverse"
        }, formatMessage((0, (0, parcelRequire("kAT4r")).default).loadingMessage))));
    };
    const $143e097710c21fde$var$positionTopStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        top: 'space.0'
    });
    const $143e097710c21fde$var$positionBottomStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        bottom: 'space.0'
    });
    const $143e097710c21fde$var$loadingIndicatorStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        backgroundColor: 'color.background.neutral.bold',
        borderRadius: 'border.radius',
        boxShadow: 'elevation.shadow.overlay',
        color: 'color.text.inverse',
        marginBlock: 'space.200',
        padding: 'space.100'
    });
    const $143e097710c21fde$var$containerStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        position: 'absolute',
        width: '100%'
    });
});
parcelRegister("kAT4r", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$da5373b4b1dc1638$export$2e2bcd8739ae039);
    var $7VHMR;
    var $da5373b4b1dc1638$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        loadingMessage: {
            "id": "issue-navigator.issue-results.detail-view.card-container.loading-manager.loading-indicator.loading-message",
            "defaultMessage": "Loading more issues"
        }
    });
});
parcelRegister("arPIt", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$d111789e86749f72$export$2e2bcd8739ae039);
    var $7VHMR;
    var $d111789e86749f72$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        loadStartA11YMessageTop: {
            "id": "issue-navigator.issue-results.detail-view.card-container.loading-manager.load-start-a-11-y-message-top",
            "defaultMessage": "Loading more issues at the top of the list."
        },
        loadEndA11YMessageTop: {
            "id": "issue-navigator.issue-results.detail-view.card-container.loading-manager.load-end-a-11-y-message-top",
            "defaultMessage": "Finished loading more issues at the top of the list."
        },
        loadStartA11YMessageBottom: {
            "id": "issue-navigator.issue-results.detail-view.card-container.loading-manager.load-start-a-11-y-message-bottom",
            "defaultMessage": "Loading more issues at the bottom of the list."
        },
        loadEndA11YMessageBottom: {
            "id": "issue-navigator.issue-results.detail-view.card-container.loading-manager.load-end-a-11-y-message-bottom",
            "defaultMessage": "Finished loading more issues at the bottom of the list."
        }
    });
});
parcelRegister("gWk5r", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$8e8ad171d10df24f$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Rkzz = parcelRequire("8Rkzz");
    var $fVnbM;
    var $BLPHT;
    var $a5n5E;
    var $b2TJP;
    var $6Ingq;
    var $8oe80;
    var $6s1PB;
    var $aWse0;
    var $8zOmE;
    var $2NMNM;
    var $6UAdF;
    var $eJ0Zs;
    var $63r1u;
    var $krnTs;
    var $5NdVO;
    const $8e8ad171d10df24f$export$e813e910f52c9aab = {
        appearance: 'subtle',
        'data-testid': 'issue-navigator.ui.issue-results.detail-view.card-container.order-dropdown.button'
    };
    const $8e8ad171d10df24f$var$customFieldIdPattern = /^cf\[\d+\]$/;
    const $8e8ad171d10df24f$var$DropdownItemRadioWithAnalytics = (0, (0, parcelRequire("8oe80")).default)('dropdownItemRadio', {
        onClick: 'clicked'
    })((0, (0, parcelRequire("a5n5E")).default));
    class $8e8ad171d10df24f$export$50f2c227513bcdb7 extends (0, $5uXOq.Component) {
        static{
            this.defaultProps = {
                onChange: (0, ($parcel$interopDefault($8Rkzz))),
                onFocus: (0, ($parcel$interopDefault($8Rkzz))),
                onBlur: (0, ($parcel$interopDefault($8Rkzz)))
            };
        }
        onItemClick(order, aliases) {
            const { onChange: onChange, onBlur: onBlur } = this.props;
            onChange(order, aliases);
            onBlur();
        }
        renderItems() {
            const { intl: { formatMessage: formatMessage }, orderField: orderField } = this.props;
            return Object.values((0, (0, parcelRequire("6UAdF")).supportedOrderFields)).filter((0, (0, parcelRequire("eJ0Zs")).isNonNullish)).map(({ jqlTerm: jqlTerm, aliases: aliases })=>{
                let jqlTermForMessage = jqlTerm;
                if ([
                    'issuetype'
                ].includes(jqlTermForMessage)) jqlTermForMessage = `${jqlTerm}${(0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? 'IssueTermRefresh' : ''}`;
                const messageDescriptor = (0, (0, parcelRequire("5NdVO")).messageLookup)[jqlTermForMessage];
                const fieldName = messageDescriptor ? formatMessage(messageDescriptor) : jqlTerm;
                const props = {
                    id: jqlTerm,
                    onClick: ()=>{
                        this.onItemClick(jqlTerm, aliases);
                    },
                    isSelected: jqlTerm === orderField || orderField && aliases.includes(orderField)
                };
                return (0, ($parcel$interopDefault($5uXOq))).createElement($8e8ad171d10df24f$var$DropdownItemRadioWithAnalytics, {
                    key: jqlTerm,
                    ...props
                }, fieldName);
            });
        }
        render() {
            const { intl: { formatMessage: formatMessage }, orderField: orderField, isOpen: isOpen, className: className } = this.props;
            let fallbackMessage = '';
            let fieldName = '';
            if (typeof orderField === 'string') {
                const isCustomFieldId = $8e8ad171d10df24f$var$customFieldIdPattern.test(orderField);
                const expectedOrderField = (0, (0, parcelRequire("6UAdF")).supportedOrderFields)[(0, (0, parcelRequire("6UAdF")).getSupportedFieldForAlias)(orderField) ?? orderField];
                const fallbackMessageDescriptor = expectedOrderField ? (0, (0, parcelRequire("5NdVO")).messageLookup)[expectedOrderField.jqlTerm] : null;
                if (isCustomFieldId) fallbackMessage = formatMessage((0, (0, parcelRequire("5NdVO")).default).customField);
                else if (expectedOrderField && fallbackMessageDescriptor) fallbackMessage = formatMessage(fallbackMessageDescriptor);
                else fieldName = orderField;
            } else fallbackMessage = formatMessage((0, (0, parcelRequire("5NdVO")).default).defaultLabel);
            return (0, ($parcel$interopDefault($5uXOq))).createElement($8e8ad171d10df24f$var$StyledContainer, {
                className: className
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("BLPHT")).default), {
                isOpen: isOpen,
                onOpenChange: this.onOpenChange,
                testId: "issue-navigator.ui.issue-results.detail-view.card-container.order-dropdown",
                trigger: ({ triggerRef: triggerRef, ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement($8e8ad171d10df24f$var$StyledButtonContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                        ...props,
                        appearance: "subtle",
                        ref: triggerRef,
                        iconAfter: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("6Ingq"))))), {
                            label: formatMessage((0, (0, parcelRequire("5NdVO")).default).defaultLabel)
                        })
                    }, fieldName || fallbackMessage)),
                shouldFlip: true,
                placement: "bottom-start"
            }, (0, ($parcel$interopDefault($5uXOq))).createElement($8e8ad171d10df24f$var$StyledDropdownMenuContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("b2TJP")).default), {
                id: "sort-field-group",
                title: formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("5NdVO")).default).fieldGroupIssueTermRefresh : (0, (0, parcelRequire("5NdVO")).default).fieldGroup)
            }, this.renderItems()))));
        }
        constructor(...args){
            super(...args);
            this.onOpenChange = ({ isOpen: isOpen } = {
                isOpen: false
            })=>{
                if (isOpen) this.props.onFocus();
                else this.props.onBlur();
            };
        }
    }
    const $8e8ad171d10df24f$export$f6d8103ec9453c7f = ()=>{
        const [{ isOpen: isOpen }, { open: open, close: close }] = (0, (0, parcelRequire("63r1u")).useOrderByOpenState)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const intl = (0, (0, parcelRequire("aWse0")).useIntlV2)();
        const [{ field: field, direction: direction }, { setFieldAndDirection: setFieldAndDirection }] = (0, (0, parcelRequire("krnTs")).useSortFieldAndDirection)();
        const handleOnChange = (0, $5uXOq.useCallback)((orderField, aliases)=>{
            setFieldAndDirection(orderField, direction, aliases);
            const analyticsEvent = createAnalyticsEvent({
                action: 'clicked',
                actionSubject: 'dropdownItemRadio'
            });
            const { fieldType: fieldType = null } = (0, (0, parcelRequire("6UAdF")).supportedOrderFields)[(0, (0, parcelRequire("6UAdF")).getSupportedFieldForAlias)(orderField) ?? orderField] ?? {};
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'sortFieldDropdownItemRadio', {
                fieldType: fieldType,
                order: direction
            });
        }, [
            createAnalyticsEvent,
            direction,
            setFieldAndDirection
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($8e8ad171d10df24f$export$50f2c227513bcdb7, {
            orderField: field,
            onChange: handleOnChange,
            isOpen: isOpen,
            onFocus: open,
            onBlur: close,
            intl: intl
        });
    };
    var $8e8ad171d10df24f$export$2e2bcd8739ae039 = $8e8ad171d10df24f$export$f6d8103ec9453c7f;
    const $8e8ad171d10df24f$var$StyledButtonContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1s3g12gs",
                __cmplp.className
            ])
        });
    });
    const $8e8ad171d10df24f$var$StyledDropdownMenuContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_4t3inklw",
                __cmplp.className
            ])
        });
    });
    const $8e8ad171d10df24f$var$StyledContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_16jlkb7n _1reo15vq _18m915vq",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("63r1u", function(module, exports) {
    $parcel$export(module.exports, "useOrderByOpenState", ()=>$eaf17a9c035669f9$export$32869ae0582ab3ff);
    var $3sQ5x;
    var $iXqE5;
    var $51nlc;
    const $eaf17a9c035669f9$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: {
            isOpen: false
        },
        actions: {
            open: ()=>({ setState: setState })=>{
                    setState({
                        isOpen: true
                    });
                },
            close: ()=>({ setState: setState })=>{
                    setState({
                        isOpen: false
                    });
                }
        },
        name: 'IssueNavigatorOrderByOpenState'
    });
    const $eaf17a9c035669f9$export$2ba8216bf1290828 = (0, (0, parcelRequire("51nlc")).createSubscriber)($eaf17a9c035669f9$var$Store);
    const $eaf17a9c035669f9$export$32869ae0582ab3ff = (0, (0, parcelRequire("3sQ5x")).createHook)($eaf17a9c035669f9$var$Store);
});
parcelRegister("5NdVO", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$d30701c7e9a99b3c$export$2e2bcd8739ae039);
    $parcel$export(module.exports, "messageLookup", ()=>$d30701c7e9a99b3c$export$3a16bd55b6aaaf91);
    var $7VHMR;
    const $d30701c7e9a99b3c$var$messages = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        loading: {
            "id": "issue-navigator.card-container.order-dropdown.loading",
            "defaultMessage": "Loading"
        },
        fieldGroup: {
            "id": "issue-navigator.card-container.order-dropdown.field-group",
            "defaultMessage": "Order issues by"
        },
        created: {
            "id": "issue-navigator.card-container.order-dropdown.created",
            "defaultMessage": "Created"
        },
        updated: {
            "id": "issue-navigator.card-container.order-dropdown.updated",
            "defaultMessage": "Updated"
        },
        lastviewed: {
            "id": "issue-navigator.card-container.order-dropdown.lastviewed",
            "defaultMessage": "Last viewed"
        },
        status: {
            "id": "issue-navigator.card-container.order-dropdown.status",
            "defaultMessage": "Status"
        },
        priority: {
            "id": "issue-navigator.card-container.order-dropdown.priority",
            "defaultMessage": "Priority"
        },
        assignee: {
            "id": "issue-navigator.card-container.order-dropdown.assignee",
            "defaultMessage": "Assignee"
        },
        resolved: {
            "id": "issue-navigator.card-container.order-dropdown.resolved",
            "defaultMessage": "Resolved"
        },
        issuekey: {
            "id": "issue-navigator.card-container.order-dropdown.issuekey",
            "defaultMessage": "Key"
        },
        key: {
            "id": "issue-navigator.card-container.order-dropdown.key",
            "defaultMessage": "Key"
        },
        issuetype: {
            "id": "issue-navigator.card-container.order-dropdown.issuetype",
            "defaultMessage": "Issue Type"
        },
        duedate: {
            "id": "issue-navigator.card-container.order-dropdown.duedate",
            "defaultMessage": "Due date"
        },
        summary: {
            "id": "issue-navigator.card-container.order-dropdown.summary",
            "defaultMessage": "Summary"
        },
        reporter: {
            "id": "issue-navigator.card-container.order-dropdown.reporter",
            "defaultMessage": "Reporter"
        },
        customField: {
            "id": "issue-navigator.card-container.order-dropdown.custom-field",
            "defaultMessage": "Custom field"
        },
        defaultLabel: {
            "id": "issue-navigator.card-container.order-dropdown.default-label",
            "defaultMessage": "Order by"
        },
        fieldGroupIssueTermRefresh: {
            "id": "issue-navigator.card-container.order-dropdown.field-group-issue-term-refresh",
            "defaultMessage": "Order work items by"
        },
        issuetypeIssueTermRefresh: {
            "id": "issue-navigator.card-container.order-dropdown.issuetype-issue-term-refresh",
            "defaultMessage": "Work type"
        }
    });
    var $d30701c7e9a99b3c$export$2e2bcd8739ae039 = $d30701c7e9a99b3c$var$messages;
    const $d30701c7e9a99b3c$export$3a16bd55b6aaaf91 = $d30701c7e9a99b3c$var$messages;
});
parcelRegister("3uIgp", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$45da2bcc5731351b$export$2e2bcd8739ae039);
    var $cVNGL;
    var $bwpsq;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $fVnbM;
    var $a88oa;
    var $h1toL;
    var $3bDjY;
    var $3Tty1;
    var $jQtKF;
    var $6s1PB;
    var $koVbs;
    var $8zOmE;
    var $2NMNM;
    var $cyCoT;
    var $isIOR;
    var $acq6k;
    const $45da2bcc5731351b$var$getLastPage = (pageCursors)=>{
        if (pageCursors?.last) return pageCursors.last.pageNumber;
        if (pageCursors?.around?.length) return pageCursors.around[pageCursors.around.length - 1]?.pageNumber;
        return undefined;
    };
    const $45da2bcc5731351b$var$getPageCursor = (pageCursors, page)=>{
        const { first: first, last: last, around: around } = pageCursors ?? {};
        if (page === first?.pageNumber) return first?.cursor ?? undefined;
        if (page === last?.pageNumber) return last?.cursor ?? undefined;
        return around?.find((pageCursor)=>page === pageCursor?.pageNumber)?.cursor ?? undefined;
    };
    const $45da2bcc5731351b$var$TooltipTag = (0, $5uXOq.forwardRef)((props, ref)=>(0, ($parcel$interopDefault($5uXOq))).createElement($45da2bcc5731351b$var$StyledTooltipTag, {
            ref: ref,
            ...props
        }));
    const $45da2bcc5731351b$var$Paginator = ({ issueResults: issueResults })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const data = (0, $8Uumt.useFragment)((0, (0, parcelRequire("cVNGL")).default), issueResults);
        const currentPage = data.pageCursors?.around?.find((pageCursor)=>pageCursor?.isCurrent)?.pageNumber ?? 1;
        const lastPage = $45da2bcc5731351b$var$getLastPage(data.pageCursors) ?? 1;
        const { isFetching: isFetching, isStalePage: isStalePage, onIssueSearchForPage: onIssueSearchForPage } = (0, (0, parcelRequire("isIOR")).useIssueSearchQuery)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const onChange = (0, $5uXOq.useCallback)((pageNumber)=>{
            const pageCursor = $45da2bcc5731351b$var$getPageCursor(data.pageCursors, pageNumber);
            if (pageCursor == null) return;
            onIssueSearchForPage(pageCursor);
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                action: 'changed',
                actionSubject: 'pageNumber'
            }), 'issueListPagination', {
                currentPage: currentPage,
                nextPage: pageNumber
            });
        }, [
            data.pageCursors,
            onIssueSearchForPage,
            createAnalyticsEvent,
            currentPage
        ]);
        const onChangePrevious = (0, $5uXOq.useCallback)(()=>onChange(currentPage - 1), [
            currentPage,
            onChange
        ]);
        const onChangeNext = (0, $5uXOq.useCallback)(()=>onChange(currentPage + 1), [
            currentPage,
            onChange
        ]);
        const keyMap = (0, $5uXOq.useMemo)(()=>({
                arrowleft: {
                    callback: onChangePrevious,
                    label: (0, ($parcel$interopDefault($5uXOq))).createElement("div", null, formatMessage((0, (0, parcelRequire("acq6k")).default).nextPageShortcutLabel))
                },
                arrowright: {
                    callback: onChangeNext,
                    label: (0, ($parcel$interopDefault($5uXOq))).createElement("div", null, formatMessage((0, (0, parcelRequire("acq6k")).default).prevPageShortcutLabel))
                }
            }), [
            formatMessage,
            onChangeNext,
            onChangePrevious
        ]);
        const totalIssues = data.totalIssueSearchResultCount ?? 0;
        if (totalIssues === 0 || isFetching || isStalePage) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement($45da2bcc5731351b$var$StyledContainer, {
            "data-testid": "issue-navigator.ui.issue-results.detail-view.card-container.paginator"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3Tty1")).default), {
            keyMap: keyMap
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("a88oa"))))), {
                label: formatMessage((0, (0, parcelRequire("acq6k")).default).prevPageButton)
            }),
            appearance: "subtle",
            onClick: onChangePrevious,
            isDisabled: currentPage === 1,
            testId: "issue-navigator.ui.issue-results.detail-view.card-container.paginator.prev-button"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement($45da2bcc5731351b$var$PageAndCountContainer, {
            "data-testid": "issue-navigator.ui.issue-results.detail-view.card-container.paginator.div"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cyCoT")).default), {
            startIssueRange: data.issueNavigatorPageInfo?.firstIssuePosition ?? 0,
            endIssueRange: data.issueNavigatorPageInfo?.lastIssuePosition ?? 0,
            total: totalIssues,
            countExceedsMaxResults: !!data.isCappingIssueSearchResult,
            loadedIssuesCount: -1,
            isInfiniteScrollEnabled: false
        })), currentPage === lastPage && data?.isCappingIssueSearchResult ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
            content: formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("acq6k")).default).disabledButtonTooltipIssueTermRefresh : (0, (0, parcelRequire("acq6k")).default).disabledButtonTooltip),
            tag: $45da2bcc5731351b$var$TooltipTag
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jQtKF")).default), {
            tabIndex: 0,
            isDisabled: true,
            "aria-label": formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("acq6k")).default).disabledButtonTooltipIssueTermRefresh : (0, (0, parcelRequire("acq6k")).default).disabledButtonTooltip),
            "data-testid": "issue-navigator.ui.issue-results.detail-view.card-container.paginator.next-disabled-button"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("h1toL"))))), {
                label: formatMessage((0, (0, parcelRequire("acq6k")).default).nextPageButton)
            }),
            appearance: "subtle",
            isDisabled: true,
            onClick: onChangeNext,
            testId: "issue-navigator.ui.issue-results.detail-view.card-container.paginator.next-button"
        }))) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("h1toL"))))), {
                label: formatMessage((0, (0, parcelRequire("acq6k")).default).nextPageButton)
            }),
            appearance: "subtle",
            isDisabled: currentPage === lastPage,
            onClick: onChangeNext,
            testId: "issue-navigator.ui.issue-results.detail-view.card-container.paginator.next-button"
        }));
    };
    var $45da2bcc5731351b$export$2e2bcd8739ae039 = $45da2bcc5731351b$var$Paginator;
    const $45da2bcc5731351b$var$StyledContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_16jlkb7n _1o9zkb7n _i0dlf1ug _1e0c1txw _4cvr1h6o _1bah1yb4 _syaz1rc1",
                __cmplp.className
            ])
        });
    });
    const $45da2bcc5731351b$var$PageAndCountContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _4cvr1h6o _1bah1h6o",
                __cmplp.className
            ])
        });
    });
    const $45da2bcc5731351b$var$StyledTooltipTag = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c116y",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("cVNGL", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$f871d130665c9b22$export$2e2bcd8739ae039);
    const $f871d130665c9b22$var$node = function() {
        var v0 = [
            {
                "kind": "ScalarField",
                "name": "cursor"
            },
            {
                "kind": "ScalarField",
                "name": "pageNumber"
            },
            {
                "kind": "ScalarField",
                "name": "isCurrent"
            }
        ];
        return {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "name": "main_issueNavigator_Paginator",
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "totalIssueSearchResultCount"
                },
                {
                    "kind": "ScalarField",
                    "name": "isCappingIssueSearchResult"
                },
                {
                    "concreteType": "JiraIssueNavigatorPageInfo",
                    "kind": "LinkedField",
                    "name": "issueNavigatorPageInfo",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "name": "firstIssuePosition"
                        },
                        {
                            "kind": "ScalarField",
                            "name": "lastIssuePosition"
                        }
                    ]
                },
                {
                    "args": [
                        {
                            "kind": "Literal",
                            "name": "maxCursors",
                            "value": 7
                        }
                    ],
                    "concreteType": "JiraPageCursors",
                    "kind": "LinkedField",
                    "name": "pageCursors",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraPageCursor",
                            "kind": "LinkedField",
                            "name": "first",
                            "plural": false,
                            "selections": v0
                        },
                        {
                            "concreteType": "JiraPageCursor",
                            "kind": "LinkedField",
                            "name": "around",
                            "plural": true,
                            "selections": v0
                        },
                        {
                            "concreteType": "JiraPageCursor",
                            "kind": "LinkedField",
                            "name": "last",
                            "plural": false,
                            "selections": v0
                        }
                    ],
                    "storageKey": "pageCursors(maxCursors:7)"
                }
            ],
            "type": "JiraIssueConnection"
        };
    }();
    $f871d130665c9b22$var$node.hash = "a148b81aa698fb69850df944c78ece55";
    var $f871d130665c9b22$export$2e2bcd8739ae039 = $f871d130665c9b22$var$node;
});
parcelRegister("a88oa", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $7601289634e055f6$var$_react = $7601289634e055f6$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $7601289634e055f6$var$_chevronLeft = $7601289634e055f6$var$_interopRequireDefault((parcelRequire("lRheX")));
    function $7601289634e055f6$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $7601289634e055f6$var$ChevronLeftLargeIcon = (props)=>$7601289634e055f6$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><path fill="currentcolor" fill-rule="evenodd" d="m9.005 10.995 4.593-4.593a.99.99 0 1 1 1.4 1.4l-3.9 3.9 3.9 3.9a.99.99 0 0 1-1.4 1.4L9.005 12.41a1 1 0 0 1 0-1.414"/></svg>`
        }, props, {
            newIcon: $7601289634e055f6$var$_chevronLeft.default,
            iconType: "utility"
        }));
    $7601289634e055f6$var$ChevronLeftLargeIcon.displayName = 'ChevronLeftLargeIcon';
    var $7601289634e055f6$var$_default = module.exports.default = $7601289634e055f6$var$ChevronLeftLargeIcon;
});
parcelRegister("lRheX", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $fe9bebc953515e63$var$_react = $fe9bebc953515e63$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $fe9bebc953515e63$var$_UNSAFE_baseNew = $fe9bebc953515e63$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $fe9bebc953515e63$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $fe9bebc953515e63$var$ChevronLeftIcon = (props)=>$fe9bebc953515e63$var$_react.default.createElement($fe9bebc953515e63$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-linejoin="round" stroke-width="1.5" d="M7.5 10.5 3 6l4.5-4.5"/>`,
            type: 'utility'
        }, props));
    $fe9bebc953515e63$var$ChevronLeftIcon.displayName = 'ChevronLeftIcon';
    var $fe9bebc953515e63$var$_default = module.exports.default = $fe9bebc953515e63$var$ChevronLeftIcon;
});
parcelRegister("h1toL", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $c6495598d3d7027d$var$_react = $c6495598d3d7027d$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $c6495598d3d7027d$var$_chevronRight = $c6495598d3d7027d$var$_interopRequireDefault((parcelRequire("3dGN0")));
    function $c6495598d3d7027d$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $c6495598d3d7027d$var$ChevronRightLargeIcon = (props)=>$c6495598d3d7027d$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><path fill="currentcolor" fill-rule="evenodd" d="M14.995 10.995a1 1 0 0 1 0 1.414l-4.593 4.593a.99.99 0 0 1-1.4-1.4l3.9-3.9-3.9-3.9a.99.99 0 0 1 1.4-1.4z"/></svg>`
        }, props, {
            newIcon: $c6495598d3d7027d$var$_chevronRight.default,
            iconType: "utility"
        }));
    $c6495598d3d7027d$var$ChevronRightLargeIcon.displayName = 'ChevronRightLargeIcon';
    var $c6495598d3d7027d$var$_default = module.exports.default = $c6495598d3d7027d$var$ChevronRightLargeIcon;
});
parcelRegister("acq6k", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$049a94890312f7fb$export$2e2bcd8739ae039);
    var $7VHMR;
    var $049a94890312f7fb$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        nextPageShortcutLabel: {
            "id": "issue-navigator.issue-results.detail-view.card-container.paginator.next-page-shortcut-label",
            "defaultMessage": "Next page"
        },
        prevPageShortcutLabel: {
            "id": "issue-navigator.issue-results.detail-view.card-container.paginator.prev-page-shortcut-label",
            "defaultMessage": "Previous page"
        },
        prevPageButton: {
            "id": "issue-navigator.issue-results.detail-view.card-container.paginator.prev-page-button",
            "defaultMessage": "Previous page"
        },
        nextPageButton: {
            "id": "issue-navigator.issue-results.detail-view.card-container.paginator.next-page-button",
            "defaultMessage": "Next page"
        },
        disabledButtonTooltip: {
            "id": "issue-navigator.issue-results.detail-view.card-container.paginator.disabled-button-tooltip",
            "defaultMessage": "We can\u2019t load more pages. Refine your search criteria to see more issues."
        },
        disabledButtonTooltipIssueTermRefresh: {
            "id": "issue-navigator.issue-results.detail-view.card-container.paginator.disabled-button-tooltip-issue-term-refresh",
            "defaultMessage": "We can\u2019t load more pages. Refine your search criteria to see more work items."
        }
    });
});
parcelRegister("kwVxA", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$824c7ec1b60577e2$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $8Rkzz = parcelRequire("8Rkzz");
    var $42CjB;
    var $5LWLA;
    var $jpYlM;
    var $enBAU;
    var $3bDjY;
    var $koVbs;
    var $8zOmE;
    var $2NMNM;
    var $dCm1o;
    var $6UAdF;
    var $krnTs;
    var $cKBXV;
    var $5ngER;
    var $lJCod;
    class $824c7ec1b60577e2$export$e464399b18ba2124 extends (0, $5uXOq.Component) {
        static{
            this.defaultProps = {
                onSort: (0, ($parcel$interopDefault($8Rkzz)))
            };
        }
        shouldComponentUpdate(nextProps) {
            return this.props.sortDirection !== nextProps.sortDirection || this.props.isDisabled !== nextProps.isDisabled;
        }
        render() {
            const { intl: { formatMessage: formatMessage }, sortDirection: sortDirection, isDisabled: isDisabled } = this.props;
            const tooltipText = sortDirection === (0, (0, parcelRequire("6UAdF")).sortDirections).ASC ? formatMessage((0, (0, parcelRequire("lJCod")).default).tooltipAsc) : formatMessage((0, (0, parcelRequire("lJCod")).default).tooltipDesc);
            const glyph = sortDirection === (0, (0, parcelRequire("6UAdF")).sortDirections).ASC ? (0, (0, parcelRequire("5ngER")).ArrowUpIcon) : (0, (0, parcelRequire("cKBXV")).ArrowDownIcon);
            const SortIcon = sortDirection === (0, (0, parcelRequire("6UAdF")).sortDirections).ASC ? (0, ($parcel$interopDefault((0, parcelRequire("jpYlM"))))) : (0, ($parcel$interopDefault((0, parcelRequire("enBAU")))));
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
                content: tooltipText
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("42CjB")).default), {
                appearance: "subtle",
                onClick: this.onClick,
                testId: "issue-navigator.ui.issue-results.detail-view.card-container.sort-button.sort-button",
                isDisabled: isDisabled,
                icon: (props)=>(0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() ? (0, ($parcel$interopDefault($5uXOq))).createElement(SortIcon, {
                        ...props,
                        color: "currentColor"
                    }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5LWLA")).default), {
                        ...props,
                        size: "small",
                        glyph: glyph
                    }),
                label: tooltipText
            }));
        }
        constructor(...args){
            super(...args);
            this.onClick = ()=>{
                const { onSort: onSort, sortDirection: sortDirection } = this.props;
                const newSortDirection = sortDirection === (0, (0, parcelRequire("6UAdF")).sortDirections).ASC ? (0, (0, parcelRequire("6UAdF")).sortDirections).DESC : (0, (0, parcelRequire("6UAdF")).sortDirections).ASC;
                onSort(newSortDirection);
            };
        }
    }
    const $824c7ec1b60577e2$export$86b24d49f595c6f2 = (props)=>{
        const intl = (0, (0, parcelRequire("koVbs")).default)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const [{ field: field, direction: direction }, { setFieldAndDirection: setFieldAndDirection }] = (0, (0, parcelRequire("krnTs")).useSortFieldAndDirection)();
        const handleOnSort = (0, $5uXOq.useCallback)((newSortDirection)=>{
            typeof field === 'string' && setFieldAndDirection(field, newSortDirection, []);
            const analyticsEvent = createAnalyticsEvent({
                action: 'clicked',
                actionSubject: 'button'
            });
            const orderField = field ? (0, (0, parcelRequire("6UAdF")).supportedOrderFields)[(0, (0, parcelRequire("6UAdF")).getSupportedFieldForAlias)(field) ?? field] : undefined;
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'sortOrderChangeButton', {
                fieldType: orderField?.fieldType ?? null,
                sortOrder: newSortDirection
            });
            return true;
        }, [
            createAnalyticsEvent,
            field,
            setFieldAndDirection
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($824c7ec1b60577e2$export$e464399b18ba2124, {
            isDisabled: typeof field !== 'string',
            sortDirection: direction,
            onSort: handleOnSort,
            ...props,
            intl: intl
        });
    };
    var $824c7ec1b60577e2$export$2e2bcd8739ae039 = $824c7ec1b60577e2$export$86b24d49f595c6f2;
});
parcelRegister("jpYlM", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $e22f2a6ad3c1f502$var$_react = $e22f2a6ad3c1f502$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $e22f2a6ad3c1f502$var$_UNSAFE_baseNew = $e22f2a6ad3c1f502$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $e22f2a6ad3c1f502$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $e22f2a6ad3c1f502$var$SortAscendingIcon = (props)=>$e22f2a6ad3c1f502$var$_react.default.createElement($e22f2a6ad3c1f502$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-linejoin="round" stroke-width="1.5" d="M1 13.25h14M1 2.75h5M1 8h7m1.5-3.25 3-3 3 3m-3 4v-6.5"/>`
        }, props));
    $e22f2a6ad3c1f502$var$SortAscendingIcon.displayName = 'SortAscendingIcon';
    var $e22f2a6ad3c1f502$var$_default = module.exports.default = $e22f2a6ad3c1f502$var$SortAscendingIcon;
});
parcelRegister("enBAU", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $a780601c67e57879$var$_react = $a780601c67e57879$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $a780601c67e57879$var$_UNSAFE_baseNew = $a780601c67e57879$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $a780601c67e57879$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $a780601c67e57879$var$SortDescendingIcon = (props)=>$a780601c67e57879$var$_react.default.createElement($a780601c67e57879$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-linejoin="round" stroke-width="1.5" d="M1 2.75h14M1 13.25h5M1 8h7m1.5 3.25 3 3 3-3m-3-4v6.5"/>`
        }, props));
    $a780601c67e57879$var$SortDescendingIcon.displayName = 'SortDescendingIcon';
    var $a780601c67e57879$var$_default = module.exports.default = $a780601c67e57879$var$SortDescendingIcon;
});
parcelRegister("cKBXV", function(module, exports) {
    $parcel$export(module.exports, "ArrowDownIcon", ()=>$a0c24d18d28d22b1$export$1c939d3c9b6931b);
    var $5uXOq = parcelRequire("5uXOq");
    const $a0c24d18d28d22b1$export$1c939d3c9b6931b = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24px",
            height: "24px",
            viewBox: "0 0 24 24",
            version: "1.1",
            xmlns: "http://www.w3.org/2000/svg"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            stroke: "none",
            strokeWidth: "1",
            fill: "none",
            fillRule: "evenodd"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M6.8,4.3 C6.6,4.1 6.3,4 6,4 C5.7,4 5.4,4.1 5.2,4.3 L2.3,7.2 C1.9,7.6 1.9,8.3 2.3,8.7 C2.7,9.1 3.3,9.1 3.7,8.7 L5,7.4 L5,19.1 C5,19.6 5.4,20 6,20 C6.6,20 7,19.6 7,19.1 L7,7.4 L8.3,8.7 C8.7,9.1 9.3,9.1 9.7,8.7 C10.1,8.3 10.1,7.6 9.7,7.2 C9.04754611,6.54754611 8.5582057,6.0582057 8.23197876,5.73197876 C7.91376126,5.41376126 7.436435,4.936435 6.8,4.3 Z",
            id: "Path",
            fill: "currentColor",
            fillRule: "nonzero",
            transform: "translate(6.000000, 12.000000) rotate(180.000000) translate(-6.000000, -12.000000) "
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M21,5 L13,5 C12.4,5 12,5.4 12,6 C12,6.6 12.4,7 13,7 L21,7 C21.6,7 22,6.6 22,6 C22,5.4 21.6,5 21,5 Z",
            id: "Path",
            fill: "currentColor",
            fillRule: "nonzero"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M13,13 L18,13 C18.6,13 19,12.6 19,12 C19,11.4 18.6,11 18,11 L13,11 C12.4,11 12,11.4 12,12 C12,12.6 12.4,13 13,13 Z",
            id: "Path",
            fill: "currentColor",
            fillRule: "nonzero"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M13,19 L16,19 C16.6,19 17,18.6 17,18 C17,17.4 16.6,17 16,17 L13,17 C12.4,17 12,17.4 12,18 C12,18.6 12.4,19 13,19 Z",
            id: "Path",
            fill: "currentColor",
            fillRule: "nonzero"
        })));
});
parcelRegister("5ngER", function(module, exports) {
    $parcel$export(module.exports, "ArrowUpIcon", ()=>$8b9dee4ad337223b$export$f1a507b991affc46);
    var $5uXOq = parcelRequire("5uXOq");
    const $8b9dee4ad337223b$export$f1a507b991affc46 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24px",
            height: "24px",
            viewBox: "0 0 24 24",
            version: "1.1",
            xmlns: "http://www.w3.org/2000/svg"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            stroke: "none",
            strokeWidth: "1",
            fill: "none",
            fillRule: "evenodd"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M7.8,4.3 C7.6,4.1 7.3,4 7,4 C6.7,4 6.4,4.1 6.2,4.3 L3.3,7.2 C2.9,7.6 2.9,8.3 3.3,8.7 C3.7,9.1 4.3,9.1 4.7,8.7 L6,7.4 L6,19.1 C6,19.6 6.4,20 7,20 C7.6,20 8,19.6 8,19.1 L8,7.4 L9.3,8.7 C9.7,9.1 10.3,9.1 10.7,8.7 C11.1,8.3 11.1,7.6 10.7,7.2 C10.0475461,6.54754611 9.5582057,6.0582057 9.23197876,5.73197876 C8.91376126,5.41376126 8.436435,4.936435 7.8,4.3 Z",
            id: "Path",
            fill: "currentColor",
            fillRule: "nonzero"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M21,17 L13,17 C12.4,17 12,17.4 12,18 C12,18.6 12.4,19 13,19 L21,19 C21.6,19 22,18.6 22,18 C22,17.4 21.6,17 21,17 Z",
            id: "Path",
            fill: "currentColor",
            fillRule: "nonzero"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M13,13 L18,13 C18.6,13 19,12.6 19,12 C19,11.4 18.6,11 18,11 L13,11 C12.4,11 12,11.4 12,12 C12,12.6 12.4,13 13,13 Z",
            id: "Path",
            fill: "currentColor",
            fillRule: "nonzero"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M13,7 L16,7 C16.6,7 17,6.6 17,6 C17,5.4 16.6,5 16,5 L13,5 C12.4,5 12,5.4 12,6 C12,6.6 12.4,7 13,7 Z",
            id: "Path",
            fill: "currentColor",
            fillRule: "nonzero"
        })));
});
parcelRegister("lJCod", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$ed0328825ac5e155$export$2e2bcd8739ae039);
    var $7VHMR;
    var $ed0328825ac5e155$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        tooltipAsc: {
            "id": "issue-navigator.card-container.sort-button.tooltip-asc",
            "defaultMessage": "Sort ascending"
        },
        tooltipDesc: {
            "id": "issue-navigator.card-container.sort-button.tooltip-desc",
            "defaultMessage": "Sort descending"
        }
    });
});
parcelRegister("428r9", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$f84771d642bef682$export$2e2bcd8739ae039);
    var $xFU5V;
    var $1pTAk;
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Rkzz = parcelRequire("8Rkzz");
    var $8Uumt = parcelRequire("8Uumt");
    var $6s1PB;
    var $koVbs;
    var $8zOmE;
    var $2NMNM;
    var $6UAdF;
    var $eJ0Zs;
    var $a8lIB;
    var $gtUFk;
    var $5NwJk;
    var $7OTah;
    var $bUpV8;
    var $74FKa;
    var $ly5B7;
    var $1ocSs;
    var $4f2Gl;
    var $2UJgO;
    const $f84771d642bef682$var$BLANK_CARD_ANALYTICS_ID = 'blankIssueCard';
    const $f84771d642bef682$var$ISSUE_CARD_ANALYTICS_ID = 'issueCard';
    const $f84771d642bef682$var$CardWithSelectedIssue = ({ fragment: fragment, isFocused: isFocused, onFocus: onFocus, onBlur: onBlur })=>{
        const fragmentData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("1pTAk")).default), fragment);
        const { key: key } = fragmentData;
        const isSelected = (0, (0, parcelRequire("7OTah")).useIsSelectedIssue)(key);
        const { setSelectedIssueByKey: setSelectedIssueByKey } = (0, (0, parcelRequire("7OTah")).useSelectedIssueActions)();
        const onCardFocus = (0, $5uXOq.useCallback)(()=>{
            onFocus(key);
            setSelectedIssueByKey(key, {
                shouldDebounce: true
            });
        }, [
            key,
            onFocus,
            setSelectedIssueByKey
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($f84771d642bef682$var$CardWithAnalytics, {
            fragment: fragmentData,
            isFocused: isFocused,
            isSelected: isSelected,
            onFocus: onCardFocus,
            onBlur: onBlur
        });
    };
    const $f84771d642bef682$var$CardWithSelectedIssueStateOld = ({ index: index, fragment: fragment })=>{
        const selectedIssueIndex = (0, (0, parcelRequire("5NwJk")).useSelectedIssueIndex)();
        const { setSelectedIssueByIndex: setSelectedIssueByIndex } = (0, (0, parcelRequire("gtUFk")).useSelectedIssueStateOldActions)();
        const [focusedIssueIndex, { setFocusedIssueByIndex: setFocusedIssueByIndex, resetFocusedIssue: resetFocusedIssue }] = (0, (0, parcelRequire("gtUFk")).useFocusedIssueIndex)();
        const isSelected = selectedIssueIndex === index;
        const isFocused = focusedIssueIndex === index;
        const onCardFocus = (0, $5uXOq.useCallback)(()=>{
            setFocusedIssueByIndex(index);
            setSelectedIssueByIndex(index, true);
        }, [
            index,
            setFocusedIssueByIndex,
            setSelectedIssueByIndex
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($f84771d642bef682$var$CardWithAnalytics, {
            fragment: fragment,
            index: index,
            isFocused: isFocused,
            isSelected: isSelected,
            onFocus: onCardFocus,
            onBlur: resetFocusedIssue
        });
    };
    const $f84771d642bef682$var$CardWithAnalytics = ({ index: index, isFocused: isFocused, isSelected: isSelected, fragment: fragment, onFocus: onFocus, onBlur: onBlur })=>{
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const cardRef = (0, $5uXOq.useRef)(null);
        (0, $5uXOq.useLayoutEffect)(()=>{
            if (isSelected) {
                cardRef.current?.scrollIntoView && cardRef.current?.scrollIntoView({
                    block: 'nearest'
                });
                isFocused && cardRef.current?.focus();
            }
        }, [
            isSelected,
            isFocused
        ]);
        const onClickWithAnalytics = (0, $5uXOq.useCallback)((event)=>{
            const id = fragment ? $f84771d642bef682$var$ISSUE_CARD_ANALYTICS_ID : $f84771d642bef682$var$BLANK_CARD_ANALYTICS_ID;
            const attributes = {
                keyboardShortcut: false,
                issueIndex: index !== undefined ? index + 1 : undefined
            };
            if ((0, (0, parcelRequire("eJ0Zs")).isNormalClick)(event)) {
                event.preventDefault();
                const analyticsEvent = createAnalyticsEvent({
                    action: 'selected',
                    actionSubject: 'card'
                });
                (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, id, attributes);
            } else {
                const analyticsEvent = createAnalyticsEvent({
                    action: 'clicked',
                    actionSubject: 'card'
                });
                (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, id, attributes);
            }
        }, [
            createAnalyticsEvent,
            fragment,
            index
        ]);
        const onContextMenuHandler = (0, $5uXOq.useCallback)(()=>{
            const analyticsEvent = createAnalyticsEvent({
                action: 'contextMenu clicked',
                actionSubject: 'card'
            });
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, $f84771d642bef682$var$ISSUE_CARD_ANALYTICS_ID, {
                keyboardShortcut: false,
                issueIndex: index !== undefined ? index + 1 : undefined
            });
        }, [
            createAnalyticsEvent,
            index
        ]);
        return fragment == null ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ly5B7")).default), {
            ref: cardRef,
            isSelected: isSelected,
            onClick: onClickWithAnalytics,
            onFocus: onFocus
        }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1ocSs")).default), {
            ref: cardRef,
            fragment: fragment,
            onClick: onClickWithAnalytics,
            onFocus: onFocus,
            onBlur: onBlur,
            isSelected: isSelected,
            onContextMenu: onContextMenuHandler
        });
    };
    const $f84771d642bef682$export$4683131014ccb7f3 = ()=>(0, $5uXOq.useState)(null);
    const $f84771d642bef682$var$CardList = ({ fragment: fragment, loading: loading, onNavigateToIssue: onNavigateToIssue, listRef: listRef })=>{
        (0, (0, parcelRequire("a8lIB")).markOnce)((0, (0, parcelRequire("a8lIB")).marks).ISSUE_RESULTS_DETAIL_VIEW_CARD_LIST_START);
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        let focusedIssueIndex = null;
        let setFocusedIssueByIndex = (0, ($parcel$interopDefault($8Rkzz)));
        if (!(0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
            const result = (0, (0, parcelRequire("gtUFk")).useFocusedIssueIndex)();
            focusedIssueIndex = result[0];
            setFocusedIssueByIndex = result[1].setFocusedIssueByIndex;
        }
        const [focusedIssueKey, setFocusedIssueKey] = $f84771d642bef682$export$4683131014ccb7f3();
        const maxIssuesPerPage = (0, (0, parcelRequire("6UAdF")).getMaxIssuesPerPage)();
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        (0, $5uXOq.useLayoutEffect)(()=>{
            (0, (0, parcelRequire("a8lIB")).markOnce)((0, (0, parcelRequire("a8lIB")).marks).ISSUE_RESULTS_DETAIL_VIEW_CARD_LIST_END);
        }, []);
        const fragmentData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("xFU5V")).default), fragment);
        const onIssueChangeEvent = (0, $5uXOq.useCallback)((issueKey, issueIndex)=>{
            if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
                if (issueKey) setFocusedIssueKey(issueKey);
            } else if (issueIndex !== null) setFocusedIssueByIndex(issueIndex);
            const id = issueKey ? $f84771d642bef682$var$ISSUE_CARD_ANALYTICS_ID : $f84771d642bef682$var$BLANK_CARD_ANALYTICS_ID;
            const analyticsEvent = createAnalyticsEvent({
                action: 'selected',
                actionSubject: 'card'
            });
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, id, {
                keyboardShortcut: true,
                issueIndex: issueIndex == null ? null : issueIndex + 1
            });
        }, [
            createAnalyticsEvent,
            setFocusedIssueByIndex,
            setFocusedIssueKey
        ]);
        let cards;
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) cards = (0, $5uXOq.useMemo)(()=>fragmentData?.edges?.map((edge)=>edge?.node)?.filter(Boolean).map((node)=>(0, ($parcel$interopDefault($5uXOq))).createElement($f84771d642bef682$var$CardWithSelectedIssue, {
                    key: node.key,
                    isFocused: node.key === focusedIssueKey,
                    fragment: node,
                    onFocus: setFocusedIssueKey,
                    onBlur: ()=>setFocusedIssueKey(null)
                })) ?? [], [
            fragmentData?.edges,
            focusedIssueKey,
            setFocusedIssueKey
        ]);
        else cards = fragmentData?.edges?.filter((0, (0, parcelRequire("eJ0Zs")).isNonNullish)).map((edge, index)=>(0, ($parcel$interopDefault($5uXOq))).createElement($f84771d642bef682$var$CardWithSelectedIssueStateOld, {
                key: index,
                index: index,
                fragment: edge.node ?? undefined
            })) ?? [];
        if (loading) return (0, ($parcel$interopDefault($5uXOq))).createElement($f84771d642bef682$export$22a81bdd3a723b46, {
            "aria-label": formatMessage((0, (0, parcelRequire("2UJgO")).default).issues)
        }, [
            ...Array(maxIssuesPerPage)
        ].map((__, index)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("74FKa")).default), {
                key: index,
                issueIndex: index
            })));
        if (cards.length === 0) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bUpV8")).default), null);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4f2Gl")).default), {
            focusedIssueIndex: focusedIssueIndex,
            focusedIssueKey: focusedIssueKey,
            onIssueChangeEvent: onIssueChangeEvent,
            onNavigateToIssue: onNavigateToIssue,
            disableListControls: loading
        }), (0, ($parcel$interopDefault($5uXOq))).createElement($f84771d642bef682$export$22a81bdd3a723b46, {
            ref: (0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false) ? listRef : undefined,
            "aria-label": formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("2UJgO")).default).issuesIssueTermRefresh : (0, (0, parcelRequire("2UJgO")).default).issues)
        }, cards));
    };
    var $f84771d642bef682$export$2e2bcd8739ae039 = $f84771d642bef682$var$CardList;
    const $f84771d642bef682$export$22a81bdd3a723b46 = (0, $5uXOq.forwardRef)(({ as: C = "ul", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_qtt8glyw _19bvidpf",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("xFU5V", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$ef209f17a9c7453f$export$2e2bcd8739ae039);
    const $ef209f17a9c7453f$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_CardList_fragment",
        "selections": [
            {
                "concreteType": "JiraIssueEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                    {
                        "concreteType": "JiraIssue",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "name": "key"
                            },
                            {
                                "kind": "FragmentSpread",
                                "name": "main_issueNavigator_Card_fragment"
                            },
                            {
                                "kind": "FragmentSpread",
                                "name": "main_issueNavigator_CardWithSelectedIssue_fragment"
                            }
                        ]
                    }
                ]
            }
        ],
        "type": "JiraIssueConnection"
    };
    $ef209f17a9c7453f$var$node.hash = "d3cb3598f2ff377ca459674b752821e1";
    var $ef209f17a9c7453f$export$2e2bcd8739ae039 = $ef209f17a9c7453f$var$node;
});
parcelRegister("1pTAk", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$569ed27684326088$export$2e2bcd8739ae039);
    const $569ed27684326088$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_CardWithSelectedIssue_fragment",
        "selections": [
            {
                "kind": "ScalarField",
                "name": "key"
            },
            {
                "kind": "FragmentSpread",
                "name": "main_issueNavigator_Card_fragment"
            }
        ],
        "type": "JiraIssue"
    };
    $569ed27684326088$var$node.hash = "193ffe36e5f982599fda1349dc93ab4d";
    var $569ed27684326088$export$2e2bcd8739ae039 = $569ed27684326088$var$node;
});
parcelRegister("bUpV8", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$7b23b0330efbf510$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $4wcJR;
    const $7b23b0330efbf510$export$36d139f95680ab4e = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement($7b23b0330efbf510$var$StyledContainer, {
            "data-testid": "issue-navigator.issue-results.detail-view.card-list.card-empty-list"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4wcJR")).default), null));
    var $7b23b0330efbf510$export$2e2bcd8739ae039 = $7b23b0330efbf510$export$36d139f95680ab4e;
    const $7b23b0330efbf510$var$StyledContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _2lx21bp4 _4cvr1h6o _y3gn1h6o _4t3i1osq _1bah1h6o",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("4wcJR", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$5b8e75c7cc60728f$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $dC5iT;
    var $8CUq3;
    var $6s1PB;
    var $c7i3D;
    var $az4zD;
    var $beQyQ;
    const $5b8e75c7cc60728f$export$f3f2e7ed2b6256b1 = ({ intl: { formatMessage: formatMessage } })=>{
        const noIssues = formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("beQyQ")).default).noIssuesIssueTermRefresh : (0, (0, parcelRequire("beQyQ")).default).noIssues);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $5b8e75c7cc60728f$var$ImageWrapperStyles
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("c7i3D")).default), {
            alt: ""
        })), (0, ($parcel$interopDefault($5uXOq))).createElement("p", null, noIssues));
    };
    var $5b8e75c7cc60728f$export$2e2bcd8739ae039 = (0, (0, parcelRequire("az4zD")).injectIntlV2)($5b8e75c7cc60728f$export$f3f2e7ed2b6256b1);
    const $5b8e75c7cc60728f$var$ImageWrapperStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        height: '146px',
        width: '160px'
    });
});
parcelRegister("beQyQ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$ba07fcf1bf5ac48f$export$2e2bcd8739ae039);
    var $7VHMR;
    var $ba07fcf1bf5ac48f$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        noIssues: {
            "id": "issue-navigator.common.empty-list-icon.no-issues",
            "defaultMessage": "No issues were found matching your search"
        },
        noIssuesIssueTermRefresh: {
            "id": "issue-navigator.common.empty-list-icon.no-issues-issue-term-refresh",
            "defaultMessage": "There's nothing matching your search"
        }
    });
});
parcelRegister("74FKa", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$349ff8ff8da44cac$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $7nnPp;
    var $dC5iT;
    var $8CUq3;
    var $4R6GH;
    var $koVbs;
    var $dCm1o;
    var $5xVPG;
    var $jXcGy;
    var $4IWp9;
    var $2fvFU;
    const $349ff8ff8da44cac$var$IssueCardSkeleton = ({ hasError: hasError = false, issueIndex: issueIndex })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            as: "li",
            xcss: (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() && (0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_beta_drop_1') ? $349ff8ff8da44cac$var$ListItemStyle : $349ff8ff8da44cac$var$ListItemStyleOld,
            "aria-label": formatMessage((0, (0, parcelRequire("4IWp9")).default).issueLoadingLabel, {
                number: issueIndex
            })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($349ff8ff8da44cac$var$CardContainer, {
            visualRefresh: (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() && (0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_beta_drop_1')
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2fvFU")).SkeletonContainer), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2fvFU")).SkeletonBackground), {
            hasError: hasError,
            "data-component-selector": (0, (0, parcelRequire("2fvFU")).skeletonBackgroundSelector)
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2fvFU")).ColumnMask), {
            "data-component-selector": (0, (0, parcelRequire("2fvFU")).columnMaskSelector)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2fvFU")).SummaryMask), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2fvFU")).TextLineMask), {
            fontSize: 20,
            lineHeight: 24,
            intrusion: 20
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2fvFU")).TextLineMask), {
            fontSize: 20,
            lineHeight: 24,
            intrusion: 60
        })), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2fvFU")).FooterMask), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2fvFU")).TypeMask), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2fvFU")).SeparatorMask), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2fvFU")).IssueKeyMask), null), hasError ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2fvFU")).ErrorIconContainer), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("7nnPp"))))), {
            primaryColor: `var(--ds-icon-danger, ${(0, $829f609a65d26a98$exports).R400})`,
            label: ""
        })) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2fvFU")).BlankMask), null))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2fvFU")).ErrorWrapper), {
            "data-component-selector": (0, (0, parcelRequire("2fvFU")).errorWrapperSelector)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jXcGy")).default), null)))));
    };
    var $349ff8ff8da44cac$export$2e2bcd8739ae039 = $349ff8ff8da44cac$var$IssueCardSkeleton;
    const $349ff8ff8da44cac$var$ListItemStyleOld = (0, (0, parcelRequire("8CUq3")).xcss)({
        marginBlockStart: 'space.0'
    });
    const $349ff8ff8da44cac$var$ListItemStyle = (0, (0, parcelRequire("8CUq3")).xcss)({
        marginBlockStart: 'space.0',
        marginBlockEnd: 'space.050'
    });
    const $349ff8ff8da44cac$var$CardContainer = (0, $5uXOq.forwardRef)(({ as: C = (0, (0, parcelRequire("5xVPG")).CardBaseStyle), style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("5xVPG", function(module, exports) {
    $parcel$export(module.exports, "CardBaseStyle", ()=>$489d3a2e61cd5aa2$export$d10d52f6c0613cc3);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $lD23V;
    var $jQtKF;
    const $489d3a2e61cd5aa2$var$backgroundColorSelected = `var(--ds-background-selected, ${(0, $829f609a65d26a98$exports).B50})`;
    const $489d3a2e61cd5aa2$var$backgroundColor = `var(--ds-surface-raised, ${(0, $829f609a65d26a98$exports).N0})`;
    const $489d3a2e61cd5aa2$var$borderColor = `var(--ds-border, ${(0, $829f609a65d26a98$exports).N30})`;
    const $489d3a2e61cd5aa2$var$boxShadowColor = `1px 0px ${`var(--ds-shadow-raised, ${(0, $829f609a65d26a98$exports).N40})`}`;
    const $489d3a2e61cd5aa2$export$d10d52f6c0613cc3 = (0, $5uXOq.forwardRef)(({ as: C = "a", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { visualRefresh: visualRefresh, isSelected: isSelected, ...__cmpldp } = __cmplp;
        return $5uXOq.createElement(C, {
            ...__cmpldp,
            style: {
                ...__cmpls,
                "--_1vy64tb": (0, (0, parcelRequire("lD23V")).default)(__cmplp.isSelected ? "var(--ds-background-accent-blue-subtlest, #E9F2FF)" : "var(--ds-surface-raised, #FFFFFF)"),
                "--_11cvo1a": (0, (0, parcelRequire("lD23V")).default)(__cmplp.isSelected ? $489d3a2e61cd5aa2$var$backgroundColorSelected : $489d3a2e61cd5aa2$var$backgroundColor),
                "--_37cwmb": (0, (0, parcelRequire("lD23V")).default)($489d3a2e61cd5aa2$var$borderColor),
                "--_ykidgo": (0, (0, parcelRequire("lD23V")).default)($489d3a2e61cd5aa2$var$boxShadowColor)
            },
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1ule _1yt4u2gc _vchhusvi _7ehihmda _1a3b18uv _4fprglyw _5goinqa1 _nt751r31 _49pcglyw _1hvw1o36 _9oik18uv _1bnxglyw _jf4cnqa1",
                __cmplp.visualRefresh ? "_16qszucj _bfhk1sss _2rkoiti9 _18s8v77o _1yt4utpp" : "_bfhk2rqg _x3doidpf _n7zl1jb4 _1kl71jb4 _1jc11j8p _14t01jb4 _13jw1lq3 _e6md1se7 _1tzh1y44",
                __cmplp.className
            ])
        });
    });
    const $489d3a2e61cd5aa2$export$b15a6cf2987e4b39 = (0, $5uXOq.forwardRef)(({ as: C = (0, (0, parcelRequire("jQtKF")).default), style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_4t3i1osq",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("jXcGy", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$b1838c9b738176ad$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $8Rkzz = parcelRequire("8Rkzz");
    var $fVnbM;
    var $6s1PB;
    var $az4zD;
    var $7Csvc;
    var $3C2D9;
    class $b1838c9b738176ad$export$e8132eb38e030ec6 extends (0, $5uXOq.Component) {
        static{
            this.defaultProps = {
                onResetErrors: (0, ($parcel$interopDefault($8Rkzz)))
            };
        }
        render() {
            const { intl: { formatMessage: formatMessage } } = this.props;
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3C2D9")).Container), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3C2D9")).InfoMessage), null, formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("7Csvc")).default).infoTextIssueTermRefresh : (0, (0, parcelRequire("7Csvc")).default).infoText)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                appearance: "link",
                onClick: this.onReloadIssueClick,
                spacing: "none"
            }, formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("7Csvc")).default).reloadIssueIssueTermRefresh : (0, (0, parcelRequire("7Csvc")).default).reloadIssue)));
        }
        constructor(...args){
            super(...args);
            this.onReloadIssueClick = (...attrs)=>{
                const analyticsEvent = attrs.pop();
                this.props.onResetErrors(analyticsEvent);
            };
        }
    }
    var $b1838c9b738176ad$export$2e2bcd8739ae039 = (0, (0, parcelRequire("az4zD")).injectIntlV2)($b1838c9b738176ad$export$e8132eb38e030ec6);
});
parcelRegister("7Csvc", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$18a133c57e2faa08$export$2e2bcd8739ae039);
    var $7VHMR;
    var $18a133c57e2faa08$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        infoText: {
            "id": "issue-navigator.navigator.content.issue-list.issue-card-skeleton.error-info.info-text",
            "defaultMessage": "We couldn't load this issue."
        },
        reloadIssue: {
            "id": "issue-navigator.navigator.content.issue-list.issue-card-skeleton.error-info.reload-issue",
            "defaultMessage": "Reload issue"
        },
        infoTextIssueTermRefresh: {
            "id": "issue-navigator.navigator.content.issue-list.issue-card-skeleton.error-info.info-text-issue-term-refresh",
            "defaultMessage": "We couldn't load this work item."
        },
        reloadIssueIssueTermRefresh: {
            "id": "issue-navigator.navigator.content.issue-list.issue-card-skeleton.error-info.reload-issue-issue-term-refresh",
            "defaultMessage": "Reload work item"
        }
    });
});
parcelRegister("3C2D9", function(module, exports) {
    $parcel$export(module.exports, "Container", ()=>$c3e0a8031c737ebe$export$42a852a2b6b56249);
    $parcel$export(module.exports, "InfoMessage", ()=>$c3e0a8031c737ebe$export$4bf8682ab34d3383);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    const $c3e0a8031c737ebe$export$42a852a2b6b56249 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_syaz1kw7 _1e0c1txw _2lx21bp4",
                __cmplp.className
            ])
        });
    });
    const $c3e0a8031c737ebe$export$4bf8682ab34d3383 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("4IWp9", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$078c5bf6273acc7a$export$2e2bcd8739ae039);
    var $7VHMR;
    var $078c5bf6273acc7a$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        issueLoadingLabel: {
            "id": "issue-navigator.issue-results.detail-view.card-list.card-skeleton.issue-loading-label",
            "defaultMessage": "Issue card {number} loading"
        }
    });
});
parcelRegister("2fvFU", function(module, exports) {
    $parcel$export(module.exports, "SkeletonContainer", ()=>$3769c2df00d1a140$export$66b8cea0c448285);
    $parcel$export(module.exports, "SkeletonBackground", ()=>$3769c2df00d1a140$export$c3b0ebed856b8b1a);
    $parcel$export(module.exports, "ColumnMask", ()=>$3769c2df00d1a140$export$439b7c87164a9b43);
    $parcel$export(module.exports, "SummaryMask", ()=>$3769c2df00d1a140$export$792e72b41c80f830);
    $parcel$export(module.exports, "FooterMask", ()=>$3769c2df00d1a140$export$ab3fe3ddc5c7354f);
    $parcel$export(module.exports, "TextLineMask", ()=>$3769c2df00d1a140$export$f3e0fd2d5209f88b);
    $parcel$export(module.exports, "TypeMask", ()=>$3769c2df00d1a140$export$59d16c2cd3bc3a2);
    $parcel$export(module.exports, "IssueKeyMask", ()=>$3769c2df00d1a140$export$ca1c048394ed3dbb);
    $parcel$export(module.exports, "SeparatorMask", ()=>$3769c2df00d1a140$export$bdc7d72765b181c3);
    $parcel$export(module.exports, "BlankMask", ()=>$3769c2df00d1a140$export$a1b9063b4cbc0844);
    $parcel$export(module.exports, "ErrorIconContainer", ()=>$3769c2df00d1a140$export$90993d2d9550d725);
    $parcel$export(module.exports, "ErrorWrapper", ()=>$3769c2df00d1a140$export$bb27b7e39840238f);
    $parcel$export(module.exports, "errorWrapperSelector", ()=>$3769c2df00d1a140$export$a03455d77d6a4ea);
    $parcel$export(module.exports, "skeletonBackgroundSelector", ()=>$3769c2df00d1a140$export$85fe8f1edff78b98);
    $parcel$export(module.exports, "columnMaskSelector", ()=>$3769c2df00d1a140$export$67b279fb398d016e);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $lD23V;
    const $3769c2df00d1a140$var$defaultIssueCardHeight = 97;
    const $3769c2df00d1a140$export$66b8cea0c448285 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_4t3i1rbs _1e0c1txw _kqswh2mm",
                __cmplp.className
            ])
        });
    });
    const $3769c2df00d1a140$var$shimmer = null;
    const $3769c2df00d1a140$export$c3b0ebed856b8b1a = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { hasError: hasError, ...__cmpldp } = __cmplp;
        return $5uXOq.createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_5sag126j _1o51q7pw _j7hq1en8 _1pglp3kn _bfhk1u4r _1itkxsyt _12vemgnk _kqswstnw _1ltvidpf _1xi2idpf _154iidpf _94n5idpf",
                __cmplp.hasError ? "_tip8idpf" : "_tip812c5",
                __cmplp.className
            ])
        });
    });
    const $3769c2df00d1a140$export$439b7c87164a9b43 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_kqswh2mm _1bsb1osq _19pkidpf _2hwxidpf _otyridpf _18u0idpf _1kl715mc _1s7z15mc",
                __cmplp.className
            ])
        });
    });
    const $3769c2df00d1a140$export$792e72b41c80f830 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_n7zl15mc",
                __cmplp.className
            ])
        });
    });
    const $3769c2df00d1a140$export$ab3fe3ddc5c7354f = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _2lx2vrvc _kqswh2mm _n7zlv4vr _x3do16sd",
                __cmplp.className
            ])
        });
    });
    const $3769c2df00d1a140$export$f3e0fd2d5209f88b = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { intrusion: intrusion, lineHeight: lineHeight, ...__cmpldp } = __cmplp;
        return $5uXOq.createElement(C, {
            ...__cmpldp,
            style: {
                ...__cmpls,
                "--_rjhebn": (0, (0, parcelRequire("lD23V")).default)(__cmplp.fontSize, "px"),
                "--_1oqupad": (0, (0, parcelRequire("lD23V")).default)(__cmplp.intrusion, "px"),
                "--_ppkzip": (0, (0, parcelRequire("lD23V")).default)((__cmplp.lineHeight - __cmplp.fontSize) / 2, "px")
            },
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_kqswh2mm _4t3i3gti _1s7z87mc _x3do1age _n7zl1age",
                __cmplp.className
            ])
        });
    });
    const $3769c2df00d1a140$export$59d16c2cd3bc3a2 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_kqswh2mm _4t3i1tcg _1bsb1tcg _2hwxu2gc",
                __cmplp.className
            ])
        });
    });
    const $3769c2df00d1a140$export$ca1c048394ed3dbb = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_kqswh2mm _4t3i1tcg _1bsb16xz",
                __cmplp.className
            ])
        });
    });
    const $3769c2df00d1a140$export$bdc7d72765b181c3 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1bsbftgi _bfhkvuon",
                __cmplp.className
            ])
        });
    });
    const $3769c2df00d1a140$export$a1b9063b4cbc0844 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_16jlkb7n _bfhkvuon _1e0c1txw _1bahesu3",
                __cmplp.className
            ])
        });
    });
    const $3769c2df00d1a140$export$90993d2d9550d725 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_bfhkvuon _16jlkb7n _1e0c1txw _1bahesu3",
                __cmplp.className
            ])
        });
    });
    const $3769c2df00d1a140$export$bb27b7e39840238f = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0cglyw _1bsb1osq _19pkidpf _2hwxidpf _otyridpf _18u0idpf _4cvr1h6o _18u0u2gc _2hwxu2gc",
                __cmplp.className
            ])
        });
    });
    const $3769c2df00d1a140$export$a03455d77d6a4ea = 'jira-issue-navigator-card-skeleton-error-wrapper';
    const $3769c2df00d1a140$export$85fe8f1edff78b98 = 'jira-issue-navigator-card-skeleton-skeleton-background';
    const $3769c2df00d1a140$export$67b279fb398d016e = 'jira-issue-navigator-card-skeleton-column-mask';
});
parcelRegister("ly5B7", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$f8caaf0a251343b7$export$2e2bcd8739ae039);
    var $bwpsq;
    var $lD23V;
    var $5uXOq = parcelRequire("5uXOq");
    var $dC5iT;
    var $8CUq3;
    var $6s1PB;
    var $4R6GH;
    var $koVbs;
    var $5oFIy;
    var $dCm1o;
    var $5xVPG;
    var $iwwo9;
    const $f8caaf0a251343b7$var$EmptyCard = (0, $5uXOq.forwardRef)(({ isSelected: isSelected = false, onClick: onClick, onFocus: onFocus }, ref)=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            as: "li",
            xcss: $f8caaf0a251343b7$var$ListItemStyle
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($f8caaf0a251343b7$var$EmptyCardContainer, {
            tabIndex: isSelected ? 0 : -1,
            ref: ref ?? undefined,
            isSelected: isSelected,
            onClick: onClick,
            href: "#",
            onFocus: onFocus,
            visualRefresh: (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() && (0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_beta_drop_1')
        }, formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("iwwo9")).default).emptyCardTextIssueTermRefresh : (0, (0, parcelRequire("iwwo9")).default).emptyCardText), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireUiAnalytics), {
            action: "viewed",
            actionSubject: "blankIssue"
        })));
    });
    var $f8caaf0a251343b7$export$2e2bcd8739ae039 = $f8caaf0a251343b7$var$EmptyCard;
    const $f8caaf0a251343b7$var$textColorActiveState = `var(--ds-text-selected, ${(0, $829f609a65d26a98$exports).N600})`;
    const $f8caaf0a251343b7$var$textColor = `var(--ds-text, ${(0, $829f609a65d26a98$exports).N200})`;
    const $f8caaf0a251343b7$var$EmptyCardContainer = (0, $5uXOq.forwardRef)(({ as: C = (0, (0, parcelRequire("5xVPG")).CardBaseStyle), style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: {
                ...__cmpls,
                "--_1ebr96o": (0, (0, parcelRequire("lD23V")).default)($f8caaf0a251343b7$var$textColorActiveState),
                "--_srel9g": (0, (0, parcelRequire("lD23V")).default)($f8caaf0a251343b7$var$textColor)
            },
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1wyb1skh _80omtlke",
                __cmplp.isSelected ? "_syaz1c3g" : "_syaz1u00",
                __cmplp.isSelected ? "_f8pj1c3g _30l31c3g" : "_f8pj1u00 _30l31u00",
                __cmplp.className
            ])
        });
    });
    const $f8caaf0a251343b7$var$ListItemStyle = (0, (0, parcelRequire("8CUq3")).xcss)({
        marginBlockStart: 'space.0'
    });
});
parcelRegister("iwwo9", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$ffb369d8ea4f3908$export$2e2bcd8739ae039);
    var $7VHMR;
    var $ffb369d8ea4f3908$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        blankText: {
            "id": "issue-navigator.issue-results.detail-view.card-list.card.empty-card.blank-text",
            "defaultMessage": "We can't display this issue"
        },
        emptyCardText: {
            "id": "issue-navigator.issue-results.detail-view.card-list.card.empty-card.empty-card-text",
            "defaultMessage": "This issue cannot be opened because it may have been deleted or you do not have access to it"
        },
        tooltip: {
            "id": "issue-navigator.issue-results.detail-view.card-list.card.empty-card.tooltip",
            "defaultMessage": "Either this issue has been deleted, or you don\u2019t have access to it."
        },
        blankTextIssueTermRefresh: {
            "id": "issue-navigator.issue-results.detail-view.card-list.card.empty-card.blank-text-issue-term-refresh",
            "defaultMessage": "We can't display this work item"
        },
        emptyCardTextIssueTermRefresh: {
            "id": "issue-navigator.issue-results.detail-view.card-list.card.empty-card.empty-card-text-issue-term-refresh",
            "defaultMessage": "This work item cannot be opened because it may have been deleted or you do not have access to it"
        },
        tooltipIssueTermRefresh: {
            "id": "issue-navigator.issue-results.detail-view.card-list.card.empty-card.tooltip-issue-term-refresh",
            "defaultMessage": "Either this work item has been deleted, or you don\u2019t have access to it."
        }
    });
});
parcelRegister("1ocSs", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$04760746f23b3b20$export$2e2bcd8739ae039);
    var $7Hztx;
    var $h1FQb;
    var $bwpsq;
    var $lD23V;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $4R6GH;
    var $g5X3G;
    var $dCm1o;
    var $5xVPG;
    var $1BB6R;
    const $04760746f23b3b20$var$backgroundColorSelected = `var(--ds-background-selected, ${(0, $829f609a65d26a98$exports).B50})`;
    const $04760746f23b3b20$var$backgroundColorHovered = `var(--ds-background-neutral-subtle-hovered, ${(0, $829f609a65d26a98$exports).N10})`;
    const $04760746f23b3b20$var$StyledListElement = (0, $5uXOq.forwardRef)(({ as: C = "li", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { visualRefresh: visualRefresh, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_19pkidpf",
                __cmplp.visualRefresh && "_otyr1b66",
                __cmplp.className
            ])
        });
    });
    const $04760746f23b3b20$export$1d41361302b577a1 = (0, $5uXOq.forwardRef)(({ as: C = (0, (0, parcelRequire("5xVPG")).CardBaseStyle), style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: {
                ...__cmpls,
                "--_1hwiu8a": (0, (0, parcelRequire("lD23V")).default)($04760746f23b3b20$var$backgroundColorSelected),
                "--_1xad3ln": (0, (0, parcelRequire("lD23V")).default)($04760746f23b3b20$var$backgroundColorHovered)
            },
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_kqswh2mm _f8pj1kw7 _30l31kw7",
                __cmplp.isSelected ? "_syaz1ldt" : "_syaz1kw7",
                __cmplp.isSelected ? "_irr312tn" : "_irr31iq0",
                __cmplp.className
            ])
        });
    });
    const $04760746f23b3b20$export$c3fc4c88db352deb = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { visualRefresh: visualRefresh, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_slp31hna _1i4q1hna _1nmz1hna",
                __cmplp.visualRefresh ? "_otyru2gc" : "_otyr1b66",
                __cmplp.className
            ])
        });
    });
    const $04760746f23b3b20$var$Card = (0, $5uXOq.forwardRef)(({ style: style, onMounted: onMounted, onClick: onClick, onFocus: onFocus, onBlur: onBlur, fragment: fragment, isSelected: isSelected, onContextMenu: onContextMenu }, ref)=>{
        const fragmentData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("h1FQb")).default), fragment);
        const fieldSetsForIssueSearchViewData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("7Hztx")).default), fragmentData.fieldSetsForIssueSearchView);
        const issueKey = fragmentData.key;
        (0, $5uXOq.useEffect)(()=>{
            onMounted && onMounted();
        }, [
            onMounted
        ]);
        const fieldEdges = fieldSetsForIssueSearchViewData?.edges ?? null;
        const summary = (0, (0, parcelRequire("1BB6R")).byFieldId)(fieldEdges, 'summary', (summaryEdge)=>summaryEdge?.node?.text);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($04760746f23b3b20$var$StyledListElement, {
            "data-testid": "issue-navigator.ui.issue-results.detail-view.card-list.card.list-item",
            visualRefresh: (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() && (0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_beta_drop_1')
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($04760746f23b3b20$export$1d41361302b577a1, {
            ref: ref ?? undefined,
            onContextMenu: onContextMenu,
            isSelected: isSelected,
            onBlur: onBlur,
            tabIndex: isSelected ? 0 : -1,
            onClick: onClick,
            onFocus: onFocus,
            style: style,
            "data-testid": "issue-navigator.ui.issue-results.detail-view.card-list.card",
            href: `/browse/${issueKey}`,
            visualRefresh: (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() && (0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_beta_drop_1')
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($04760746f23b3b20$export$c3fc4c88db352deb, {
            "data-testid": "issue-navigator.ui.issue-results.detail-view.card-list.card.summary",
            visualRefresh: (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() && (0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_beta_drop_1')
        }, summary), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1BB6R")).Footer), {
            issueKey: (0, (0, parcelRequire("g5X3G")).toIssueKey)(issueKey),
            isResolved: fragmentData.isResolved,
            fieldSets: fragmentData.fieldSetsForIssueSearchView,
            isSelected: isSelected
        })));
    });
    var $04760746f23b3b20$export$2e2bcd8739ae039 = (0, $5uXOq.memo)($04760746f23b3b20$var$Card);
});
parcelRegister("7Hztx", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$7cca77d6f5e45957$export$2e2bcd8739ae039);
    const $7cca77d6f5e45957$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_Card_fieldSetsForIssueSearchView",
        "selections": [
            {
                "concreteType": "JiraIssueFieldSetEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                    {
                        "concreteType": "JiraIssueFieldSet",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                            {
                                "concreteType": "JiraIssueFieldConnection",
                                "kind": "LinkedField",
                                "name": "fields",
                                "plural": false,
                                "selections": [
                                    {
                                        "concreteType": "JiraIssueFieldEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                            {
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                            {
                                                                "kind": "ScalarField",
                                                                "name": "fieldId"
                                                            },
                                                            {
                                                                "kind": "ScalarField",
                                                                "name": "name"
                                                            },
                                                            {
                                                                "kind": "ScalarField",
                                                                "name": "text"
                                                            }
                                                        ],
                                                        "type": "JiraSingleLineTextField"
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ],
        "type": "JiraIssueFieldSetConnection"
    };
    $7cca77d6f5e45957$var$node.hash = "af3f8561029a83ccd09c464401256fc5";
    var $7cca77d6f5e45957$export$2e2bcd8739ae039 = $7cca77d6f5e45957$var$node;
});
parcelRegister("h1FQb", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9ba56474e5731e6a$export$2e2bcd8739ae039);
    const $9ba56474e5731e6a$var$node = {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "amountOfColumns"
            },
            {
                "kind": "RootArgument",
                "name": "filterId"
            },
            {
                "kind": "RootArgument",
                "name": "issueTypeId"
            },
            {
                "kind": "RootArgument",
                "name": "namespace"
            },
            {
                "kind": "RootArgument",
                "name": "projectId"
            },
            {
                "kind": "RootArgument",
                "name": "viewId"
            }
        ],
        "kind": "Fragment",
        "name": "main_issueNavigator_Card_fragment",
        "selections": [
            {
                "kind": "RequiredField",
                "field": {
                    "kind": "ScalarField",
                    "name": "key"
                },
                "action": "THROW",
                "path": "key"
            },
            {
                "kind": "ScalarField",
                "name": "isResolved"
            },
            {
                "kind": "RequiredField",
                "field": {
                    "args": [
                        {
                            "fields": [
                                {
                                    "fields": [
                                        {
                                            "kind": "Variable",
                                            "name": "issueType",
                                            "variableName": "issueTypeId"
                                        },
                                        {
                                            "kind": "Variable",
                                            "name": "project",
                                            "variableName": "projectId"
                                        }
                                    ],
                                    "kind": "ObjectValue",
                                    "name": "projectContext"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "context"
                        },
                        {
                            "kind": "Variable",
                            "name": "filterId",
                            "variableName": "filterId"
                        },
                        {
                            "kind": "Variable",
                            "name": "first",
                            "variableName": "amountOfColumns"
                        },
                        {
                            "kind": "Variable",
                            "name": "namespace",
                            "variableName": "namespace"
                        },
                        {
                            "kind": "Variable",
                            "name": "viewId",
                            "variableName": "viewId"
                        }
                    ],
                    "concreteType": "JiraIssueFieldSetConnection",
                    "kind": "LinkedField",
                    "name": "fieldSetsForIssueSearchView",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "main_issueNavigator_Card_fieldSetsForIssueSearchView"
                        },
                        {
                            "kind": "FragmentSpread",
                            "name": "footer_issueNavigator_FooterInternal"
                        }
                    ]
                },
                "action": "THROW",
                "path": "fieldSetsForIssueSearchView"
            }
        ],
        "type": "JiraIssue"
    };
    $9ba56474e5731e6a$var$node.hash = "a635b991dd89a748dce8375f793ab03f";
    var $9ba56474e5731e6a$export$2e2bcd8739ae039 = $9ba56474e5731e6a$var$node;
});
parcelRegister("1BB6R", function(module, exports) {
    $parcel$export(module.exports, "byFieldId", ()=>$508c659e42eab501$export$7a6e1e67b4eca178);
    $parcel$export(module.exports, "Footer", ()=>$508c659e42eab501$export$a06f1c675e846f6f);
    var $cA9Wh;
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Uumt = parcelRequire("8Uumt");
    var $ujWmd;
    var $3bDjY;
    var $dqoiX;
    var $gULOk;
    var $6s1PB;
    var $koVbs;
    var $ku7XM;
    var $dCm1o;
    var $eJ0Zs;
    var $aEaMJ;
    const $508c659e42eab501$export$7a6e1e67b4eca178 = (edges, fieldId, mapperFn)=>edges?.map((edge)=>edge?.node?.fields?.edges?.[0]).filter((edge)=>edge?.node?.fieldId === fieldId).filter((0, (0, parcelRequire("eJ0Zs")).isNonNullish)).map(mapperFn)[0];
    const $508c659e42eab501$var$FooterInternal = ({ issueKey: issueKey, isResolved: isResolvedProp, isSelected: isSelected, fieldSets: fieldSets })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const fieldSetsData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("cA9Wh")).default), fieldSets);
        const fieldSetEdges = fieldSetsData?.edges;
        const assignee = $508c659e42eab501$export$7a6e1e67b4eca178(fieldSetEdges, 'assignee', (assigneeEdge)=>assigneeEdge?.node?.user);
        const issueType = $508c659e42eab501$export$7a6e1e67b4eca178(fieldSetEdges, 'issuetype', (issueTypeEdge)=>issueTypeEdge?.node?.issueType);
        const statusCategoryId = $508c659e42eab501$export$7a6e1e67b4eca178(fieldSetEdges, 'statusCategory', (statusCategoryEdge)=>statusCategoryEdge?.node?.statusCategory?.statusCategoryId);
        const isResolved = (0, (0, parcelRequire("ku7XM")).shouldDisplayStrikethrough)(isResolvedProp ?? undefined, (0, (0, parcelRequire("gULOk")).statusCategoryForId)(statusCategoryId));
        return (0, ($parcel$interopDefault($5uXOq))).createElement($508c659e42eab501$var$StyledContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement($508c659e42eab501$var$TypeIcon, {
            src: issueType?.avatar?.small ?? undefined,
            alt: issueType?.name ?? undefined
        }), (0, ($parcel$interopDefault($5uXOq))).createElement($508c659e42eab501$export$263c092b561e7355, {
            isSelected: isSelected,
            isResolved: isResolved,
            isVisualRefresh: (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)()
        }, issueKey, isResolved && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dqoiX")).ScreenReaderText), {
            role: "status"
        }, formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("aEaMJ")).default).screenReaderFooterKeyResolvedIssueTermRefresh : (0, (0, parcelRequire("aEaMJ")).default).screenReaderFooterKeyResolved, {
            issueKey: issueKey
        }))), (0, ($parcel$interopDefault($5uXOq))).createElement($508c659e42eab501$var$AvatarStyledContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
            content: assignee?.name
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ujWmd")).default), {
            src: assignee?.picture,
            name: assignee?.name,
            size: "small"
        }))));
    };
    const $508c659e42eab501$export$a06f1c675e846f6f = (0, $5uXOq.memo)($508c659e42eab501$var$FooterInternal);
    const $508c659e42eab501$var$StyledContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _4cvr1h6o",
                __cmplp.className
            ])
        });
    });
    const $508c659e42eab501$var$TypeIcon = (0, $5uXOq.forwardRef)(({ as: C = "img", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1bsb7vkz _4t3i7vkz _2hwx1b66 _2rkoyh40",
                __cmplp.className
            ])
        });
    });
    const $508c659e42eab501$var$AvatarStyledContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_4t3i1f4h _19bvu2gc _18u01wug _80omtlke",
                __cmplp.className
            ])
        });
    });
    const $508c659e42eab501$export$263c092b561e7355 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isVisualRefresh: isVisualRefresh, isSelected: isSelected, isResolved: isResolved, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_11c8qk37 _kqswh2mm",
                __cmplp.isVisualRefresh ? "_k48p1wq8" : "_k48pmoej",
                __cmplp.isSelected ? "_syaz1ldt" : "_syazloe7",
                __cmplp.isResolved ? "_4bfu18uv _1hms1911 _ajmmnqa1" : "_4bfu18uv _1hmsglyw _ajmmnqa1",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("cA9Wh", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9702b926129cb95c$export$2e2bcd8739ae039);
    const $9702b926129cb95c$var$node = function() {
        var v0 = {
            "kind": "ScalarField",
            "name": "fieldId"
        }, v1 = {
            "kind": "ScalarField",
            "name": "name"
        };
        return {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "name": "footer_issueNavigator_FooterInternal",
            "selections": [
                {
                    "concreteType": "JiraIssueFieldSetEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "concreteType": "JiraIssueFieldSet",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "concreteType": "JiraIssueFieldConnection",
                                    "kind": "LinkedField",
                                    "name": "fields",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "concreteType": "JiraIssueFieldEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                                v0,
                                                                {
                                                                    "kind": "ScalarField",
                                                                    "name": "text"
                                                                }
                                                            ],
                                                            "type": "JiraSingleLineTextField"
                                                        },
                                                        {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                                v0,
                                                                v1,
                                                                {
                                                                    "kind": "LinkedField",
                                                                    "name": "user",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        v1,
                                                                        {
                                                                            "kind": "ScalarField",
                                                                            "name": "picture"
                                                                        }
                                                                    ]
                                                                }
                                                            ],
                                                            "type": "JiraSingleSelectUserPickerField"
                                                        },
                                                        {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                                v0,
                                                                {
                                                                    "concreteType": "JiraIssueType",
                                                                    "kind": "LinkedField",
                                                                    "name": "issueType",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        v1,
                                                                        {
                                                                            "concreteType": "JiraAvatar",
                                                                            "kind": "LinkedField",
                                                                            "name": "avatar",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                {
                                                                                    "kind": "ScalarField",
                                                                                    "name": "small"
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                }
                                                            ],
                                                            "type": "JiraIssueTypeField"
                                                        },
                                                        {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                                v0,
                                                                {
                                                                    "concreteType": "JiraStatusCategory",
                                                                    "kind": "LinkedField",
                                                                    "name": "statusCategory",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        {
                                                                            "kind": "ScalarField",
                                                                            "name": "statusCategoryId"
                                                                        }
                                                                    ]
                                                                }
                                                            ],
                                                            "type": "JiraStatusCategoryField"
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            "type": "JiraIssueFieldSetConnection"
        };
    }();
    $9702b926129cb95c$var$node.hash = "80bd71836423cbf18b560dfc0418dc69";
    var $9702b926129cb95c$export$2e2bcd8739ae039 = $9702b926129cb95c$var$node;
});
parcelRegister("ku7XM", function(module, exports) {
    $parcel$export(module.exports, "shouldDisplayStrikethrough", ()=>$5e2ee485e17261c7$export$1e1e848245d3a6f7);
    var $gULOk;
    const { DONE: $5e2ee485e17261c7$var$DONE } = (0, (0, parcelRequire("gULOk")));
    const $5e2ee485e17261c7$export$1e1e848245d3a6f7 = (isResolved, statusCategory)=>{
        if (isResolved !== undefined) return isResolved;
        return statusCategory === $5e2ee485e17261c7$var$DONE;
    };
});
parcelRegister("aEaMJ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$f8db5e8f8694ecf3$export$2e2bcd8739ae039);
    var $7VHMR;
    var $f8db5e8f8694ecf3$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        screenReaderFooterKeyResolved: {
            "id": "issue-navigator.issue-results.detail-view.card-list.card.footer.screen-reader-footer-key-resolved",
            "defaultMessage": "Resolved issue: {issueKey}"
        },
        screenReaderFooterKeyDone: {
            "id": "issue-navigator.issue-results.detail-view.card-list.card.footer.screen-reader-footer-key-done",
            "defaultMessage": "Done issue: {issueKey}"
        },
        screenReaderFooterKeyResolvedIssueTermRefresh: {
            "id": "issue-navigator.issue-results.detail-view.card-list.card.footer.screen-reader-footer-key-resolved-issue-term-refresh",
            "defaultMessage": "Resolved issue: {issueKey}"
        },
        screenReaderFooterKeyDoneIssueTermRefresh: {
            "id": "issue-navigator.issue-results.detail-view.card-list.card.footer.screen-reader-footer-key-done-issue-term-refresh",
            "defaultMessage": "Done work item: {issueKey}"
        }
    });
});
parcelRegister("4f2Gl", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$b3513a249a124c25$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $3Tty1;
    var $6s1PB;
    var $koVbs;
    var $g5X3G;
    var $63r1u;
    var $gtUFk;
    var $5NwJk;
    var $7OTah;
    var $eaucu;
    const $b3513a249a124c25$var$useUpdatingRef = (value)=>{
        const ref = (0, $5uXOq.useRef)(value);
        ref.current = value;
        return ref;
    };
    const $b3513a249a124c25$var$KeyboardShortcuts = (props)=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const propsRef = $b3513a249a124c25$var$useUpdatingRef(props);
        const openStateRef = $b3513a249a124c25$var$useUpdatingRef((0, (0, parcelRequire("63r1u")).useOrderByOpenState)());
        const selectedIssueKey = (0, (0, parcelRequire("5NwJk")).useSelectedIssueKey)();
        const selectedIssueKeyRef = $b3513a249a124c25$var$useUpdatingRef(selectedIssueKey);
        let handleNext;
        let handlePrevious;
        let selectedIssueIndex = null;
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
            const { selectNextIssue: selectNextIssue, selectPreviousIssue: selectPreviousIssue } = (0, (0, parcelRequire("7OTah")).useSelectedIssueActions)();
            const nextIssueKey = (0, (0, parcelRequire("7OTah")).useNextIssueKey)() ?? (0, (0, parcelRequire("g5X3G")).toIssueKey)('');
            const nextIssueKeyRef = (0, $5uXOq.useRef)(nextIssueKey);
            nextIssueKeyRef.current = nextIssueKey;
            handleNext = (0, $5uXOq.useCallback)(()=>{
                const { onIssueChangeEvent: onIssueChangeEvent } = propsRef.current;
                if (selectNextIssue(true)) onIssueChangeEvent && onIssueChangeEvent(nextIssueKeyRef.current);
            }, [
                propsRef,
                selectNextIssue
            ]);
            const previousIssueKey = (0, (0, parcelRequire("7OTah")).usePreviousIssueKey)() ?? (0, (0, parcelRequire("g5X3G")).toIssueKey)('');
            const previousIssueKeyRef = (0, $5uXOq.useRef)(previousIssueKey);
            previousIssueKeyRef.current = previousIssueKey;
            handlePrevious = (0, $5uXOq.useCallback)(()=>{
                const { onIssueChangeEvent: onIssueChangeEvent } = propsRef.current;
                if (selectPreviousIssue(true)) onIssueChangeEvent && onIssueChangeEvent(previousIssueKeyRef.current);
            }, [
                propsRef,
                selectPreviousIssue
            ]);
        } else {
            const { getNextIssue: getNextIssue, selectNextIssue: selectNextIssue, getPreviousIssue: getPreviousIssue, selectPreviousIssue: selectPreviousIssue } = (0, (0, parcelRequire("gtUFk")).useSelectedIssueStateOldActions)();
            handleNext = (0, $5uXOq.useCallback)(()=>{
                const { onIssueChangeEvent: onIssueChangeEvent } = propsRef.current;
                const { issueKey: issueKey, issueIndex: issueIndex } = getNextIssue();
                if (selectNextIssue(true)) onIssueChangeEvent && onIssueChangeEvent(issueKey, issueIndex);
            }, [
                propsRef,
                getNextIssue,
                selectNextIssue
            ]);
            handlePrevious = (0, $5uXOq.useCallback)(()=>{
                const { onIssueChangeEvent: onIssueChangeEvent } = propsRef.current;
                const { issueKey: issueKey, issueIndex: issueIndex } = getPreviousIssue();
                if (selectPreviousIssue(true)) onIssueChangeEvent && onIssueChangeEvent(issueKey, issueIndex);
            }, [
                propsRef,
                getPreviousIssue,
                selectPreviousIssue
            ]);
            selectedIssueIndex = (0, (0, parcelRequire("5NwJk")).useSelectedIssueIndex)();
        }
        const selectedIssueIndexRef = $b3513a249a124c25$var$useUpdatingRef(selectedIssueIndex);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3Tty1")).default), {
            keyMap: (0, $5uXOq.useMemo)(()=>({
                    j: {
                        callback: ()=>{
                            const { disableListControls: disableListControls } = propsRef.current;
                            if (disableListControls === true) return;
                            handleNext();
                        },
                        label: (0, ($parcel$interopDefault($5uXOq))).createElement("div", null, formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("eaucu")).default).nextIssueIssueTermRefresh : (0, (0, parcelRequire("eaucu")).default).nextIssue))
                    },
                    k: {
                        callback: ()=>{
                            const { disableListControls: disableListControls } = propsRef.current;
                            if (disableListControls === true) return;
                            handlePrevious();
                        },
                        label: (0, ($parcel$interopDefault($5uXOq))).createElement("div", null, formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("eaucu")).default).previousIssueIssueTermRefresh : (0, (0, parcelRequire("eaucu")).default).previousIssue))
                    },
                    y: {
                        callback: ()=>{
                            const [{ isOpen: isOpen }, { open: open, close: close }] = openStateRef.current;
                            isOpen ? close() : open();
                        },
                        label: (0, ($parcel$interopDefault($5uXOq))).createElement("div", null, formatMessage((0, (0, parcelRequire("eaucu")).default).displaySortFields))
                    },
                    z: {
                        callback: ()=>{
                            if (selectedIssueKeyRef.current) propsRef.current.onNavigateToIssue(selectedIssueKeyRef.current);
                        },
                        label: (0, ($parcel$interopDefault($5uXOq))).createElement("div", null, formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("eaucu")).default).openIssueFullScreenIssueTermRefresh : (0, (0, parcelRequire("eaucu")).default).openIssueFullScreen))
                    },
                    arrowup: {
                        callback: ()=>{
                            const { disableListControls: disableListControls, focusedIssueIndex: focusedIssueIndex, focusedIssueKey: focusedIssueKey } = propsRef.current;
                            if (disableListControls === true) return;
                            let isSelectedIssueFocused;
                            if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) isSelectedIssueFocused = focusedIssueKey !== null && focusedIssueKey === selectedIssueKeyRef.current;
                            else isSelectedIssueFocused = focusedIssueIndex !== null && focusedIssueIndex === selectedIssueIndexRef.current;
                            if (isSelectedIssueFocused) handlePrevious();
                        },
                        label: (0, ($parcel$interopDefault($5uXOq))).createElement("div", null, formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("eaucu")).default).previousIssueIssueTermRefresh : (0, (0, parcelRequire("eaucu")).default).previousIssue))
                    },
                    arrowdown: {
                        callback: ()=>{
                            const { disableListControls: disableListControls, focusedIssueIndex: focusedIssueIndex, focusedIssueKey: focusedIssueKey } = propsRef.current;
                            if (disableListControls === true) return;
                            let isSelectedIssueFocused;
                            if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) isSelectedIssueFocused = focusedIssueKey !== null && focusedIssueKey === selectedIssueKeyRef.current;
                            else isSelectedIssueFocused = focusedIssueIndex !== null && focusedIssueIndex === selectedIssueIndexRef.current;
                            if (isSelectedIssueFocused) handleNext();
                        },
                        label: (0, ($parcel$interopDefault($5uXOq))).createElement("div", null, formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("eaucu")).default).nextIssueIssueTermRefresh : (0, (0, parcelRequire("eaucu")).default).nextIssue))
                    }
                }), [])
        });
    };
    $b3513a249a124c25$var$KeyboardShortcuts.defaultProps = {
        disableListControls: false
    };
    var $b3513a249a124c25$export$2e2bcd8739ae039 = $b3513a249a124c25$var$KeyboardShortcuts;
});
parcelRegister("eaucu", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$1f07eece787fe1a7$export$2e2bcd8739ae039);
    var $7VHMR;
    var $1f07eece787fe1a7$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        nextIssue: {
            "id": "issue-navigator.card-list.keyboard-shortcuts.next-issue",
            "defaultMessage": "Next issue"
        },
        previousIssue: {
            "id": "issue-navigator.card-list.keyboard-shortcuts.previous-issue",
            "defaultMessage": "Previous issue"
        },
        displaySortFields: {
            "id": "issue-navigator.card-list.keyboard-shortcuts.display-sort-fields",
            "defaultMessage": "Detail view order by"
        },
        openIssueFullScreen: {
            "id": "issue-navigator.card-list.keyboard-shortcuts.open-issue-full-screen",
            "defaultMessage": "Open issue fullscreen"
        },
        nextIssueIssueTermRefresh: {
            "id": "issue-navigator.card-list.keyboard-shortcuts.next-issue-issue-term-refresh",
            "defaultMessage": "Next work item"
        },
        previousIssueIssueTermRefresh: {
            "id": "issue-navigator.card-list.keyboard-shortcuts.previous-issue-issue-term-refresh",
            "defaultMessage": "Previous work item"
        },
        openIssueFullScreenIssueTermRefresh: {
            "id": "issue-navigator.card-list.keyboard-shortcuts.open-issue-full-screen-issue-term-refresh",
            "defaultMessage": "Open the work item in fullscreen"
        }
    });
});
parcelRegister("2UJgO", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$28be3dd688e218d3$export$2e2bcd8739ae039);
    var $7VHMR;
    var $28be3dd688e218d3$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        issues: {
            "id": "issue-navigator.issue-results.detail-view.card-list.issues",
            "defaultMessage": "Issues"
        },
        issuesIssueTermRefresh: {
            "id": "issue-navigator.issue-results.detail-view.card-list.issues-issue-term-refresh",
            "defaultMessage": "Work items"
        }
    });
});
parcelRegister("3vkQx", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$8745c6e002add02e$export$2e2bcd8739ae039);
    $parcel$export(module.exports, "RecoverableErrorViewBoundary", ()=>$8745c6e002add02e$export$d42c1f6e3e564ee9);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $fVnbM;
    var $hTEpF;
    var $dC5iT;
    var $8CUq3;
    var $81Uw2;
    var $6s1PB;
    var $c7i3D;
    var $koVbs;
    var $8zOmE;
    var $5oFIy;
    var $isIOR;
    var $38r5c;
    var $hlGjX;
    const $8745c6e002add02e$var$ErrorView = ({ onCaughtIssueSearchError: onCaughtIssueSearchError, error: errorProp })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        let onRefresh;
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) ({ onRefresh: onRefresh } = (0, (0, parcelRequire("38r5c")).useIssueSearchActions)());
        else ({ onIssueSearchRefresh: onRefresh } = (0, (0, parcelRequire("isIOR")).useIssueSearchQuery)());
        const onClick = (0, $5uXOq.useCallback)((e, analyticsEvent)=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent.clone(), 'refreshFromError');
            onRefresh();
        }, [
            onRefresh
        ]);
        const error = (0, $5uXOq.useMemo)(()=>errorProp || new Error('There was an error searching for issues'), [
            errorProp
        ]);
        (0, $5uXOq.useLayoutEffect)(()=>{
            onCaughtIssueSearchError && onCaughtIssueSearchError(error);
        }, [
            onCaughtIssueSearchError,
            error
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($8745c6e002add02e$var$Container, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hTEpF")).default), {
            header: formatMessage((0, (0, parcelRequire("hlGjX")).default).emptyStateErrorHeader),
            description: formatMessage((0, (0, parcelRequire("hlGjX")).default).emptyStateErrorDescription),
            testId: "issue-navigator.ui.issue-results.error-view.error-message",
            primaryAction: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                appearance: "primary",
                onClick: onClick
            }, formatMessage((0, (0, parcelRequire("hlGjX")).default).emptyStateErrorAction)),
            renderImage: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
                    xcss: $8745c6e002add02e$var$ImageWrapperStyles
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("c7i3D")).default), {
                    alt: ""
                }))
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireUiAnalytics), {
            action: "viewed",
            actionSubject: "screen",
            actionSubjectId: "issueSearchError"
        }));
    };
    var $8745c6e002add02e$export$2e2bcd8739ae039 = $8745c6e002add02e$var$ErrorView;
    const $8745c6e002add02e$export$d42c1f6e3e564ee9 = (0, (0, parcelRequire("81Uw2")).withRecoverableErrorBoundary)(({ children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children), {
        recoveryProp: 'recoveryProp',
        Fallback: $8745c6e002add02e$var$ErrorView
    });
    const $8745c6e002add02e$var$Container = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _16jlkb7n _4cvr1h6o",
                __cmplp.className
            ])
        });
    });
    const $8745c6e002add02e$var$ImageWrapperStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        maxHeight: '146px',
        maxWidth: '160px',
        margin: 'auto',
        marginBottom: 'space.300'
    });
});
parcelRegister("hlGjX", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$2c7ed0162ee24bd2$export$2e2bcd8739ae039);
    var $7VHMR;
    var $2c7ed0162ee24bd2$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        emptyStateErrorHeader: {
            "id": "issue-navigator.issue-results.error-view.empty-state-error-header",
            "defaultMessage": "We couldn\u2019t load your search results"
        },
        emptyStateErrorDescription: {
            "id": "issue-navigator.issue-results.error-view.empty-state-error-description",
            "defaultMessage": "Refresh to try again."
        },
        emptyStateErrorAction: {
            "id": "issue-navigator.issue-results.error-view.empty-state-error-action",
            "defaultMessage": "Refresh"
        }
    });
});
parcelRegister("dTymG", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9c7151db16a02806$export$2e2bcd8739ae039);
    var $59Iqo;
    var $gDdVw;
    var $bW3jF;
    var $1Fibi;
    var $2fw1T;
    var $krXnE;
    var $ebHAX;
    var $5uXOq = parcelRequire("5uXOq");
    var $7nsd3 = parcelRequire("7nsd3");
    var $8Uumt = parcelRequire("8Uumt");
    var $dC5iT;
    var $8CUq3;
    var $jcw0u;
    var $gcl1l;
    var $6s1PB;
    var $4R6GH;
    var $pa9q9;
    var $6bs0c;
    var $gkVdw;
    var $czfFt;
    var $5iGOJ;
    var $32GaU;
    var $3HwlS;
    var $iXVV6;
    var $5oFIy;
    var $koeKL;
    var $cZpsT;
    var $imS60;
    var $6UAdF;
    var $1igGa;
    var $eJ0Zs;
    var $a8lIB;
    var $hZQx0;
    var $gtUFk;
    var $5NwJk;
    var $ih9zY;
    var $krnTs;
    var $gwtSG;
    var $kiKYF;
    var $isIOR;
    var $38r5c;
    var $8BTWe;
    var $7UBlg;
    var $2lRxk;
    var $2hlL8;
    var $df5k9;
    var $cKSn6;
    var $1g3qz;
    var $ePwTF;
    var $dHKP1;
    var $dYvVi;
    const $9c7151db16a02806$var$reorderColumnsErrorFlag = {
        type: 'error',
        title: (0, (0, parcelRequire("df5k9")).default).reorderColumnsErrorTitle,
        description: (0, (0, parcelRequire("df5k9")).default).columnConfigMutationErrorDescription,
        isAutoDismiss: true
    };
    const $9c7151db16a02806$var$resetColumnsErrorFlag = {
        type: 'error',
        title: (0, (0, parcelRequire("df5k9")).default).resetColumnsErrorTitle,
        description: (0, (0, parcelRequire("df5k9")).default).columnConfigMutationErrorDescription,
        isAutoDismiss: true
    };
    const $9c7151db16a02806$var$TableContainer = ({ children: children })=>(0, (0, parcelRequire("3HwlS")).getWillShowNav4)() ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $9c7151db16a02806$var$TableContainerStyles
        }, children) : children;
    const $9c7151db16a02806$export$bec6bbce63eee986 = ()=>{
        const [isRestoringDefaultColumns, setIsRestoringDefaultColumns] = (0, $5uXOq.useState)(false);
        return {
            isRestoringDefaultColumns: isRestoringDefaultColumns,
            setIsRestoringDefaultColumns: setIsRestoringDefaultColumns
        };
    };
    const $9c7151db16a02806$export$b4f6a715a9e57e85 = ()=>{
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        const { isFilterEditable: isFilterEditable } = (0, (0, parcelRequire("gwtSG")).useActiveJql)();
        const [{ view: view }] = (0, (0, parcelRequire("ih9zY")).useSelectedViewState)();
        let isFetchingView;
        let isRefreshing;
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
            isFetchingView = (0, (0, parcelRequire("38r5c")).useIsFetchingView)();
            isRefreshing = (0, (0, parcelRequire("38r5c")).useIsRefreshing)();
        } else {
            const state = (0, (0, parcelRequire("isIOR")).useIssueSearchQuery)();
            isFetchingView = state.isFetching && state.hasViewIdChanged;
            isRefreshing = state.isRefreshing;
        }
        const { isRestoringDefaultColumns: isRestoringDefaultColumns } = $9c7151db16a02806$export$bec6bbce63eee986();
        return !isAnonymous && (!(0, (0, parcelRequire("eJ0Zs")).isFilterViewId)(view) || isFilterEditable === true) && !isFetchingView && !isRefreshing && !isRestoringDefaultColumns;
    };
    const $9c7151db16a02806$export$539ae4acd804b244 = (onRestoreDefaultColumns, viewData)=>{
        const { isFilterEditable: isFilterEditable } = (0, (0, parcelRequire("gwtSG")).useActiveJql)();
        const [{ view: selectedView }] = (0, (0, parcelRequire("ih9zY")).useSelectedViewState)();
        return !((0, (0, parcelRequire("eJ0Zs")).isFilterViewId)(selectedView) && isFilterEditable === false) && viewData?.hasDefaultFieldSets === false ? onRestoreDefaultColumns : undefined;
    };
    const $9c7151db16a02806$export$3e7a75a1659bbaae = (onColumnsChange)=>{
        const canEditColumns = $9c7151db16a02806$export$b4f6a715a9e57e85();
        return canEditColumns ? onColumnsChange : undefined;
    };
    const $9c7151db16a02806$export$d7623a323994e4cc = (selectedColumns)=>{
        const [{ view: selectedView }] = (0, (0, parcelRequire("ih9zY")).useSelectedViewState)();
        const canEditColumns = $9c7151db16a02806$export$b4f6a715a9e57e85();
        return (0, (0, parcelRequire("kiKYF")).useColumnLoader)(selectedView, canEditColumns, selectedColumns);
    };
    const $9c7151db16a02806$export$90b784197d8a906f = ()=>{
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        return !isAnonymous ? $9c7151db16a02806$export$d7623a323994e4cc : undefined;
    };
    const $9c7151db16a02806$export$6a2cc3745a117f8 = ()=>{
        let isFetchingView;
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) isFetchingView = (0, (0, parcelRequire("38r5c")).useIsFetchingView)();
        else {
            const { hasViewIdChanged: hasViewIdChanged, isFetching: isFetching } = (0, (0, parcelRequire("isIOR")).useIssueSearchQuery)();
            isFetchingView = isFetching && hasViewIdChanged;
        }
        const { isRestoringDefaultColumns: isRestoringDefaultColumns } = $9c7151db16a02806$export$bec6bbce63eee986();
        return {
            isColumnPickerLoading: isRestoringDefaultColumns || isFetchingView
        };
    };
    const $9c7151db16a02806$var$ListView = ({ loading: loading, onChangeColumnConfiguration: onChangeColumnConfiguration, onSelectedRowChange: onSelectedRowChange, issueResults: issueResults, groupResults: groupResults, view: view, project: project, isFeedbackButtonDisabled: isFeedbackButtonDisabled, issueTableProps: issueTableProps, isIssueHierarchySupportEnabled: isIssueHierarchySupportEnabled, filter: filter })=>{
        (0, (0, parcelRequire("a8lIB")).markOnce)((0, (0, parcelRequire("a8lIB")).marks).ISSUE_RESULTS_LIST_VIEW_START);
        (0, $5uXOq.useLayoutEffect)(()=>{
            (0, (0, parcelRequire("a8lIB")).markOnce)((0, (0, parcelRequire("a8lIB")).marks).ISSUE_RESULTS_LIST_VIEW_END);
        }, []);
        const issueResultsData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("ebHAX")).default), issueResults);
        const groupResultsData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("krXnE")).default), groupResults);
        const viewData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("2fw1T")).default), view);
        const projectData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("1Fibi")).default), project);
        const filterData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("bW3jF")).default), filter);
        const selectedIssueKey = (0, (0, parcelRequire("5NwJk")).useSelectedIssueKey)();
        const selectedIssueIndex = (0, (0, parcelRequire("5NwJk")).useSelectedIssueIndex)();
        const currentPage = issueResultsData.pageCursors?.around?.find((cursor)=>cursor?.isCurrent)?.pageNumber ?? 1;
        const [{ field: field, direction: direction }, { setFieldAndDirection: setFieldAndDirection }] = (0, (0, parcelRequire("krnTs")).useSortFieldAndDirection)();
        let onIssueSearchForColumnConfig;
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) ({ onRefetch: onIssueSearchForColumnConfig } = (0, (0, parcelRequire("38r5c")).useIssueSearchActions)());
        else ({ onIssueSearchForCurrentPage: onIssueSearchForColumnConfig } = (0, (0, parcelRequire("isIOR")).useIssueSearchQuery)());
        const { isRestoringDefaultColumns: isRestoringDefaultColumns, setIsRestoringDefaultColumns: setIsRestoringDefaultColumns } = $9c7151db16a02806$export$bec6bbce63eee986();
        const onModifyColumns = (0, $5uXOq.useCallback)(()=>{
            onChangeColumnConfiguration && onChangeColumnConfiguration();
            onIssueSearchForColumnConfig();
        }, [
            onChangeColumnConfiguration,
            onIssueSearchForColumnConfig
        ]);
        let onPageChange;
        let fieldSetIds = [];
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) fieldSetIds = (0, $5uXOq.useMemo)(()=>viewData.fieldSets.edges?.map((edge)=>edge?.node?.fieldSetId).filter(Boolean) ?? [], [
            viewData.fieldSets.edges
        ]);
        else {
            const { selectIssueOnPage: selectIssueOnPage } = (0, (0, parcelRequire("gtUFk")).useSelectedIssueStateOldActions)();
            onPageChange = (0, $5uXOq.useCallback)((cursor, shouldSelectLastIssue)=>{
                selectIssueOnPage(cursor, shouldSelectLastIssue);
            }, [
                selectIssueOnPage
            ]);
        }
        const eventHandlers = (0, (0, parcelRequire("dYvVi")).useEventHandlers)(issueResultsData.__id, fieldSetIds);
        let infiniteScrollProps = {};
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) infiniteScrollProps = (0, (0, parcelRequire("38r5c")).useInfiniteScroll)();
        const onSortOrderChange = (0, $5uXOq.useCallback)((sortField, sortDirection)=>setFieldAndDirection(sortField, sortDirection, []), [
            setFieldAndDirection
        ]);
        const overriddenTableComponents = issueTableProps?.components;
        const projectContext = issueTableProps?.projectContext;
        const isGroupingSupported = issueTableProps?.isGroupingSupported;
        const hasMaxSelectedColumns = viewData?.fieldSets?.totalCount ? viewData?.fieldSets?.totalCount >= (0, (0, parcelRequire("czfFt")).MAX_COLUMNS) : false;
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        const { showFlag: showFlag } = (0, (0, parcelRequire("pa9q9")).useFlagService)();
        let Footer;
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) Footer = undefined;
        else if ((0, (0, parcelRequire("4R6GH")).fg)('jira_spreadsheet_component_m1_api_updates')) Footer = (0, (0, parcelRequire("2lRxk")).Footer);
        else Footer = (0, (0, parcelRequire("2lRxk")).useFooterOverride)(isFeedbackButtonDisabled);
        const tableComponents = (0, $5uXOq.useMemo)(()=>({
                columnPicker: {
                    Container: ({ children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8BTWe")).ColumnPickerContainer), {
                            viewIdFromResponse: (0, (0, parcelRequire("eJ0Zs")).parseIssueNavigatorViewIdOrDefault)(viewData.viewId),
                            isRestoringDefaults: isRestoringDefaultColumns
                        }, children),
                    PopupComponent: (0, (0, parcelRequire("7UBlg")).default)
                },
                Footer: Footer,
                IssueCount: (0, parcelRequire("2hlL8")).IssueCount,
                NoColumns: (0, parcelRequire("cKSn6")).NoColumns,
                NoIssues: (0, parcelRequire("1g3qz")).NoIssues,
                ...overriddenTableComponents
            }), [
            Footer,
            isRestoringDefaultColumns,
            overriddenTableComponents,
            viewData.viewId
        ]);
        const [replaceListViewFieldSets] = (0, $8Uumt.useMutation)((0, (0, parcelRequire("gDdVw")).default));
        const onColumnsChange = (0, $5uXOq.useCallback)((columnIds, changeType, optimisticFieldSets)=>{
            (0, ($parcel$interopDefault($7nsd3))).unstable_batchedUpdates(()=>{
                if (changeType === (0, (0, parcelRequire("czfFt")).REORDER_COLUMNS)) (0, (0, parcelRequire("dHKP1")).dragAndDropExperience).start();
                replaceListViewFieldSets({
                    variables: {
                        id: viewData.id,
                        amountOfColumns: (0, (0, parcelRequire("czfFt")).MAX_COLUMNS),
                        fieldSetsInput: {
                            replaceFieldSetsInput: {
                                nodes: columnIds
                            }
                        },
                        includeView: changeType === (0, (0, parcelRequire("czfFt")).REORDER_COLUMNS)
                    },
                    optimisticResponse: changeType === (0, (0, parcelRequire("czfFt")).REORDER_COLUMNS) && optimisticFieldSets ? {
                        jira: {
                            replaceIssueSearchViewFieldSets: {
                                success: true,
                                view: {
                                    id: viewData.id,
                                    viewId: viewData.viewId,
                                    hasDefaultFieldSets: viewData.hasDefaultFieldSets,
                                    fieldSets: {
                                        ...optimisticFieldSets
                                    }
                                }
                            }
                        }
                    } : undefined,
                    onCompleted (data) {
                        if (data.jira?.replaceIssueSearchViewFieldSets?.success) {
                            if (changeType === (0, (0, parcelRequire("czfFt")).MODIFY_COLUMNS)) onModifyColumns();
                            else if (changeType === (0, (0, parcelRequire("czfFt")).REORDER_COLUMNS)) (0, (0, parcelRequire("dHKP1")).dragAndDropExperience).success();
                        } else {
                            showFlag($9c7151db16a02806$var$reorderColumnsErrorFlag);
                            (0, (0, parcelRequire("jcw0u")).default)({
                                meta: {
                                    id: 'commitReplaceListViewFieldSetsMutationUnsuccessful',
                                    packageName: (0, (0, parcelRequire("6UAdF")).PACKAGE_NAME),
                                    teamName: (0, (0, parcelRequire("6UAdF")).TEAM_NAME)
                                },
                                sendToPrivacyUnsafeSplunk: true,
                                attributes: {
                                    changeType: changeType
                                }
                            });
                            if (changeType === (0, (0, parcelRequire("czfFt")).REORDER_COLUMNS)) (0, (0, parcelRequire("dHKP1")).dragAndDropExperience).failure();
                        }
                    },
                    onError (error) {
                        showFlag($9c7151db16a02806$var$reorderColumnsErrorFlag);
                        (0, (0, parcelRequire("jcw0u")).default)({
                            meta: {
                                id: 'commitReplaceListViewFieldSetsMutationError',
                                packageName: (0, (0, parcelRequire("6UAdF")).PACKAGE_NAME),
                                teamName: (0, (0, parcelRequire("6UAdF")).TEAM_NAME)
                            },
                            error: error,
                            sendToPrivacyUnsafeSplunk: true,
                            attributes: {
                                changeType: changeType
                            }
                        });
                        if (changeType === (0, (0, parcelRequire("czfFt")).REORDER_COLUMNS)) (0, (0, parcelRequire("dHKP1")).dragAndDropExperience).failure();
                    }
                });
            });
        }, [
            replaceListViewFieldSets,
            viewData.id,
            viewData.viewId,
            viewData.hasDefaultFieldSets,
            onModifyColumns,
            showFlag
        ]);
        const [updateFieldSetPreferences] = (0, $8Uumt.useMutation)((0, (0, parcelRequire("59Iqo")).default));
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const updateColumnWidth = (0, (0, parcelRequire("5iGOJ")).useColumnWidthUpdater)(viewData.fieldSets.__id);
        const onColumnResize = (0, $5uXOq.useCallback)((columnId, width)=>{
            if (!isAnonymous) updateFieldSetPreferences({
                variables: {
                    cloudId: cloudId,
                    input: {
                        nodes: [
                            {
                                fieldSetId: columnId,
                                width: width ?? null
                            }
                        ]
                    }
                },
                onCompleted (data) {
                    if (!data.jira?.updateUserFieldSetPreferences?.success) (0, (0, parcelRequire("jcw0u")).default)({
                        meta: {
                            id: 'commitUpdateFieldSetPreferencesMutationUnsuccessful',
                            packageName: (0, (0, parcelRequire("6UAdF")).PACKAGE_NAME),
                            teamName: (0, (0, parcelRequire("6UAdF")).TEAM_NAME)
                        },
                        sendToPrivacyUnsafeSplunk: true
                    });
                },
                onError (error) {
                    (0, (0, parcelRequire("jcw0u")).default)({
                        meta: {
                            id: 'commitUpdateFieldSetPreferencesMutationError',
                            packageName: (0, (0, parcelRequire("6UAdF")).PACKAGE_NAME),
                            teamName: (0, (0, parcelRequire("6UAdF")).TEAM_NAME)
                        },
                        error: error,
                        sendToPrivacyUnsafeSplunk: true
                    });
                }
            });
            updateColumnWidth(columnId, width);
        }, [
            cloudId,
            isAnonymous,
            updateColumnWidth,
            updateFieldSetPreferences
        ]);
        (0, $5uXOq.useLayoutEffect)(()=>{
            (0, (0, parcelRequire("dHKP1")).dragAndDropExperience).markInlineResponse();
        });
        const contextValue = (0, $5uXOq.useMemo)(()=>({
                hasMaxSelectedColumns: hasMaxSelectedColumns,
                filterName: filterData?.name
            }), [
            filterData?.name,
            hasMaxSelectedColumns
        ]);
        let onRefresh;
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) ({ onRefresh: onRefresh } = (0, (0, parcelRequire("38r5c")).useIssueSearchActions)());
        else ({ onIssueSearchRefresh: onRefresh } = (0, (0, parcelRequire("isIOR")).useIssueSearchQuery)());
        const onRestoreDefaultColumns = (0, $5uXOq.useCallback)(()=>{
            setIsRestoringDefaultColumns(true);
            replaceListViewFieldSets({
                variables: {
                    id: viewData.id,
                    amountOfColumns: (0, (0, parcelRequire("czfFt")).MAX_COLUMNS),
                    includeView: true,
                    fieldSetsInput: {
                        resetToDefaultFieldSets: true
                    }
                },
                onCompleted (data) {
                    setIsRestoringDefaultColumns(false);
                    if (data.jira?.replaceIssueSearchViewFieldSets?.success) onRefresh();
                    else {
                        showFlag($9c7151db16a02806$var$resetColumnsErrorFlag);
                        (0, (0, parcelRequire("jcw0u")).default)({
                            meta: {
                                id: 'commitReplaceListViewUsingResetFieldSetsMutationUnsuccessful',
                                packageName: (0, (0, parcelRequire("6UAdF")).PACKAGE_NAME),
                                teamName: (0, (0, parcelRequire("6UAdF")).TEAM_NAME)
                            },
                            sendToPrivacyUnsafeSplunk: true
                        });
                    }
                },
                onError (error) {
                    setIsRestoringDefaultColumns(false);
                    showFlag($9c7151db16a02806$var$resetColumnsErrorFlag);
                    (0, (0, parcelRequire("jcw0u")).default)({
                        meta: {
                            id: 'commitReplaceListViewUsingResetFieldSetsMutationError',
                            packageName: (0, (0, parcelRequire("6UAdF")).PACKAGE_NAME),
                            teamName: (0, (0, parcelRequire("6UAdF")).TEAM_NAME)
                        },
                        error: error,
                        sendToPrivacyUnsafeSplunk: true
                    });
                }
            });
        }, [
            replaceListViewFieldSets,
            onRefresh,
            setIsRestoringDefaultColumns,
            showFlag,
            viewData.id
        ]);
        const conditionalOnRestoreDefaults = $9c7151db16a02806$export$539ae4acd804b244(onRestoreDefaultColumns, viewData);
        const conditionalOnChange = $9c7151db16a02806$export$3e7a75a1659bbaae(onColumnsChange);
        const { isColumnPickerLoading: isColumnPickerLoading } = $9c7151db16a02806$export$6a2cc3745a117f8();
        const conditionalColumnLoader = $9c7151db16a02806$export$90b784197d8a906f();
        const isHierarchyEnabled = viewData?.viewConfigSettings?.isHierarchyEnabled && viewData.viewConfigSettings.canEnableHierarchy && isIssueHierarchySupportEnabled;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("imS60")).default), {
            name: "list-view"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gkVdw")).DialogContextContainer), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            attributes: {
                currentPage: currentPage,
                isAnonymous: isAnonymous
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ePwTF")).AsyncRegisterShortcutDialogActions), {
            issues: issueResultsData,
            selectedIssueIndex: selectedIssueIndex,
            selectedIssueKey: selectedIssueKey,
            eventHandlers: eventHandlers
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hZQx0")).ColumnPickerMessageContext).Provider, {
            value: contextValue
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($9c7151db16a02806$var$TableContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("32GaU")).default), {
            columns: viewData.fieldSets,
            onColumnsChange: conditionalOnChange,
            onRestoreDefaultColumns: conditionalOnRestoreDefaults,
            useColumnLoader: conditionalColumnLoader,
            loading: loading,
            eventHandlers: eventHandlers,
            onSelectedRowChange: onSelectedRowChange,
            onPageChange: onPageChange,
            selectedRow: selectedIssueIndex,
            sortField: field,
            sortDirection: direction,
            onSortChange: onSortOrderChange,
            components: tableComponents,
            issueSearchBaseUrl: "",
            isColumnPickerLoading: isColumnPickerLoading,
            projectContext: projectContext,
            isGroupingSupported: isGroupingSupported,
            isIssueCreateEnabled: true,
            onColumnResize: onColumnResize,
            project: projectData,
            isIssueHierarchyEnabled: Boolean(isHierarchyEnabled),
            ...(0, (0, parcelRequire("4R6GH")).fg)('bulk_operations_in_nin') && {
                enableMultiSelect: true
            },
            ...infiniteScrollProps,
            ...groupResultsData ? {
                groups: groupResultsData,
                issues: null
            } : {
                issues: issueResultsData
            }
        })))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6bs0c")).default), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireScreenAnalytics), null)));
    };
    $9c7151db16a02806$var$ListView.whyDidYouRender = true;
    var $9c7151db16a02806$export$2e2bcd8739ae039 = (0, (0, parcelRequire("1igGa")).withReportErrors)($9c7151db16a02806$var$ListView, {
        id: 'ui.issue-results.list-view.unhandled',
        packageName: (0, (0, parcelRequire("6UAdF")).PACKAGE_NAME),
        teamName: (0, (0, parcelRequire("6UAdF")).TEAM_NAME),
        sendToPrivacyUnsafeSplunk: true,
        attributes: (0, (0, parcelRequire("gcl1l")).isLoaderErrorAttributes)
    });
    const $9c7151db16a02806$var$TableContainerStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        flexGrow: 1,
        flexBasis: 0,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        flexDirection: 'column'
    });
});
parcelRegister("59Iqo", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$02853882c92b5022$export$2e2bcd8739ae039);
    const $02853882c92b5022$var$node = function() {
        var v0 = [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cloudId"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "input"
            }
        ], v1 = [
            {
                "concreteType": "JiraMutation",
                "kind": "LinkedField",
                "name": "jira",
                "plural": false,
                "selections": [
                    {
                        "args": [
                            {
                                "kind": "Variable",
                                "name": "cloudId",
                                "variableName": "cloudId"
                            },
                            {
                                "kind": "Variable",
                                "name": "fieldSetPreferencesInput",
                                "variableName": "input"
                            }
                        ],
                        "concreteType": "JiraFieldSetPreferencesUpdatePayload",
                        "kind": "LinkedField",
                        "name": "updateUserFieldSetPreferences",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "name": "success"
                            }
                        ]
                    }
                ]
            }
        ];
        return {
            "fragment": {
                "argumentDefinitions": v0,
                "kind": "Fragment",
                "name": "main_updateFieldSetPreferencesMutation",
                "selections": v1,
                "type": "Mutation"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": v0,
                "kind": "Operation",
                "name": "main_updateFieldSetPreferencesMutation",
                "selections": v1
            },
            "params": {
                "id": "aa78c7137d4bf2c426f358e5e8e25a3d05afb5062d08ddd15fa808b550e9004e",
                "metadata": {},
                "name": "main_updateFieldSetPreferencesMutation",
                "operationKind": "mutation",
                "text": null
            }
        };
    }();
    $02853882c92b5022$var$node.hash = "5e33dd509f20cf3d934020e4f1ce2386";
    var $02853882c92b5022$export$2e2bcd8739ae039 = $02853882c92b5022$var$node;
});
parcelRegister("gDdVw", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$d20acc54f93c21cf$export$2e2bcd8739ae039);
    var $5y5Fx;
    const $d20acc54f93c21cf$var$node = function() {
        var v0 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "amountOfColumns"
        }, v1 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "fieldSetsInput"
        }, v2 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        }, v3 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "includeView"
        }, v4 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }, v5 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "staticViewInput"
        }, v6 = [
            {
                "kind": "Variable",
                "name": "fieldSetsInput",
                "variableName": "fieldSetsInput"
            },
            {
                "kind": "Variable",
                "name": "id",
                "variableName": "id"
            },
            {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
            }
        ], v7 = {
            "kind": "ScalarField",
            "name": "success"
        }, v8 = {
            "kind": "ScalarField",
            "name": "viewId"
        }, v9 = {
            "kind": "ScalarField",
            "name": "displayName"
        };
        return {
            "fragment": {
                "argumentDefinitions": [
                    v0,
                    v1,
                    v2,
                    v3,
                    v4,
                    v5
                ],
                "kind": "Fragment",
                "name": "main_replaceListViewFieldSetsMutation",
                "selections": [
                    {
                        "concreteType": "JiraMutation",
                        "kind": "LinkedField",
                        "name": "jira",
                        "plural": false,
                        "selections": [
                            {
                                "args": v6,
                                "concreteType": "JiraIssueSearchViewPayload",
                                "kind": "LinkedField",
                                "name": "replaceIssueSearchViewFieldSets",
                                "plural": false,
                                "selections": [
                                    v7,
                                    {
                                        "condition": "includeView",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": [
                                            {
                                                "concreteType": "JiraIssueSearchView",
                                                "kind": "LinkedField",
                                                "name": "view",
                                                "plural": false,
                                                "selections": [
                                                    v8,
                                                    {
                                                        "args": [
                                                            {
                                                                "kind": "Literal",
                                                                "name": "shouldSkipHierarchy",
                                                                "value": true
                                                            }
                                                        ],
                                                        "kind": "FragmentSpread",
                                                        "name": "main_issueNavigator_ListView_view"
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ],
                "type": "Mutation"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": [
                    v2,
                    v4,
                    v1,
                    v0,
                    v3,
                    v5,
                    {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider"
                    }
                ],
                "kind": "Operation",
                "name": "main_replaceListViewFieldSetsMutation",
                "selections": [
                    {
                        "concreteType": "JiraMutation",
                        "kind": "LinkedField",
                        "name": "jira",
                        "plural": false,
                        "selections": [
                            {
                                "args": v6,
                                "concreteType": "JiraIssueSearchViewPayload",
                                "kind": "LinkedField",
                                "name": "replaceIssueSearchViewFieldSets",
                                "plural": false,
                                "selections": [
                                    v7,
                                    {
                                        "condition": "includeView",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": [
                                            {
                                                "concreteType": "JiraIssueSearchView",
                                                "kind": "LinkedField",
                                                "name": "view",
                                                "plural": false,
                                                "selections": [
                                                    v8,
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "id"
                                                    },
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "hasDefaultFieldSets"
                                                    },
                                                    {
                                                        "args": [
                                                            {
                                                                "kind": "Literal",
                                                                "name": "filter",
                                                                "value": {
                                                                    "fieldSetSelectedState": "SELECTED"
                                                                }
                                                            },
                                                            {
                                                                "kind": "Variable",
                                                                "name": "first",
                                                                "variableName": "amountOfColumns"
                                                            }
                                                        ],
                                                        "concreteType": "JiraIssueSearchFieldSetConnection",
                                                        "kind": "LinkedField",
                                                        "name": "fieldSets",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "kind": "ScalarField",
                                                                "name": "totalCount"
                                                            },
                                                            {
                                                                "concreteType": "JiraIssueSearchFieldSetEdge",
                                                                "kind": "LinkedField",
                                                                "name": "edges",
                                                                "plural": true,
                                                                "selections": [
                                                                    {
                                                                        "concreteType": "JiraIssueSearchFieldSet",
                                                                        "kind": "LinkedField",
                                                                        "name": "node",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            {
                                                                                "kind": "ScalarField",
                                                                                "name": "fieldSetId"
                                                                            },
                                                                            {
                                                                                "kind": "ScalarField",
                                                                                "name": "type"
                                                                            },
                                                                            v9,
                                                                            {
                                                                                "kind": "ScalarField",
                                                                                "name": "jqlTerm"
                                                                            },
                                                                            {
                                                                                "kind": "ScalarField",
                                                                                "name": "isSortable"
                                                                            },
                                                                            {
                                                                                "concreteType": "JiraFieldSetPreferences",
                                                                                "kind": "LinkedField",
                                                                                "name": "fieldSetPreferences",
                                                                                "plural": false,
                                                                                "selections": [
                                                                                    {
                                                                                        "kind": "ScalarField",
                                                                                        "name": "width"
                                                                                    }
                                                                                ]
                                                                            },
                                                                            {
                                                                                "concreteType": "JiraFieldType",
                                                                                "kind": "LinkedField",
                                                                                "name": "fieldType",
                                                                                "plural": false,
                                                                                "selections": [
                                                                                    v9
                                                                                ]
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                            },
                                                            {
                                                                "kind": "ClientExtension",
                                                                "selections": [
                                                                    {
                                                                        "kind": "ScalarField",
                                                                        "name": "__id"
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "params": {
                "id": "cb637222e2e5129fea36c125f2836b90ccb549107b0b60ca3c9c2379ae4dfb8f",
                "metadata": {},
                "name": "main_replaceListViewFieldSetsMutation",
                "operationKind": "mutation",
                "text": null,
                "providedVariables": {
                    "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider": (0, (0, parcelRequire("5y5Fx")).default)
                }
            }
        };
    }();
    $d20acc54f93c21cf$var$node.hash = "f45d2a46bef4789b43f5954623634539";
    var $d20acc54f93c21cf$export$2e2bcd8739ae039 = $d20acc54f93c21cf$var$node;
});
parcelRegister("bW3jF", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$b474cf232be6876b$export$2e2bcd8739ae039);
    const $b474cf232be6876b$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_ListView_filter",
        "selections": [
            {
                "kind": "ScalarField",
                "name": "name"
            }
        ],
        "type": "JiraFilter",
        "abstractKey": "__isJiraFilter"
    };
    $b474cf232be6876b$var$node.hash = "42fd583d4b19e59270b0aa45271f2d67";
    var $b474cf232be6876b$export$2e2bcd8739ae039 = $b474cf232be6876b$var$node;
});
parcelRegister("1Fibi", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$98a08b67ac7f6a8a$export$2e2bcd8739ae039);
    const $98a08b67ac7f6a8a$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_ListView_project",
        "selections": [
            {
                "kind": "FragmentSpread",
                "name": "ui_nativeIssueTable_NativeIssueTable_project"
            }
        ],
        "type": "JiraProject"
    };
    $98a08b67ac7f6a8a$var$node.hash = "2c238584c7aa26733a9466fcb38e2f91";
    var $98a08b67ac7f6a8a$export$2e2bcd8739ae039 = $98a08b67ac7f6a8a$var$node;
});
parcelRegister("2fw1T", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9a78a0369755798f$export$2e2bcd8739ae039);
    const $9a78a0369755798f$var$node = {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider"
            },
            {
                "kind": "RootArgument",
                "name": "amountOfColumns"
            },
            {
                "defaultValue": false,
                "kind": "LocalArgument",
                "name": "shouldSkipHierarchy"
            },
            {
                "kind": "RootArgument",
                "name": "staticViewInput"
            }
        ],
        "kind": "Fragment",
        "name": "main_issueNavigator_ListView_view",
        "selections": [
            {
                "kind": "RequiredField",
                "field": {
                    "kind": "ScalarField",
                    "name": "id"
                },
                "action": "THROW",
                "path": "id"
            },
            {
                "kind": "RequiredField",
                "field": {
                    "kind": "ScalarField",
                    "name": "viewId"
                },
                "action": "THROW",
                "path": "viewId"
            },
            {
                "kind": "ScalarField",
                "name": "hasDefaultFieldSets"
            },
            {
                "condition": "shouldSkipHierarchy",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                    {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                            {
                                "args": [
                                    {
                                        "kind": "Variable",
                                        "name": "staticViewInput",
                                        "variableName": "staticViewInput"
                                    }
                                ],
                                "concreteType": "JiraIssueSearchViewConfigSettings",
                                "kind": "LinkedField",
                                "name": "viewConfigSettings",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "ScalarField",
                                        "name": "isHierarchyEnabled"
                                    },
                                    {
                                        "kind": "ScalarField",
                                        "name": "canEnableHierarchy"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "kind": "RequiredField",
                "field": {
                    "args": [
                        {
                            "kind": "Literal",
                            "name": "filter",
                            "value": {
                                "fieldSetSelectedState": "SELECTED"
                            }
                        },
                        {
                            "kind": "Variable",
                            "name": "first",
                            "variableName": "amountOfColumns"
                        }
                    ],
                    "concreteType": "JiraIssueSearchFieldSetConnection",
                    "kind": "LinkedField",
                    "name": "fieldSets",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "name": "totalCount"
                        },
                        {
                            "concreteType": "JiraIssueSearchFieldSetEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "concreteType": "JiraIssueSearchFieldSet",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "name": "fieldSetId"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "kind": "FragmentSpread",
                            "name": "ui_nativeIssueTable_NativeIssueTable_columns"
                        },
                        {
                            "kind": "ClientExtension",
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "name": "__id"
                                }
                            ]
                        }
                    ]
                },
                "action": "THROW",
                "path": "fieldSets"
            }
        ],
        "type": "JiraIssueSearchView"
    };
    $9a78a0369755798f$var$node.hash = "d82219b8d2374e1737b1541e44da814a";
    var $9a78a0369755798f$export$2e2bcd8739ae039 = $9a78a0369755798f$var$node;
});
parcelRegister("krXnE", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$cd44a72c3036ca18$export$2e2bcd8739ae039);
    const $cd44a72c3036ca18$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_ListView_groupResults",
        "selections": [
            {
                "kind": "FragmentSpread",
                "name": "ui_nativeIssueTable_NativeIssueTable_groups"
            }
        ],
        "type": "JiraSpreadsheetGroupConnection"
    };
    $cd44a72c3036ca18$var$node.hash = "10f0d02785e17748bb2be9a69915e2e4";
    var $cd44a72c3036ca18$export$2e2bcd8739ae039 = $cd44a72c3036ca18$var$node;
});
parcelRegister("ebHAX", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$5be221d7b1d11260$export$2e2bcd8739ae039);
    const $5be221d7b1d11260$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "main_issueNavigator_ListView_issueResults",
        "selections": [
            {
                "kind": "FragmentSpread",
                "name": "registerShortcutDialogActions_issueNavigator_RegisterShortcutsDialogActions"
            },
            {
                "kind": "FragmentSpread",
                "name": "ui_nativeIssueTable_NativeIssueTable_issues"
            },
            {
                "args": [
                    {
                        "kind": "Literal",
                        "name": "maxCursors",
                        "value": 7
                    }
                ],
                "concreteType": "JiraPageCursors",
                "kind": "LinkedField",
                "name": "pageCursors",
                "plural": false,
                "selections": [
                    {
                        "concreteType": "JiraPageCursor",
                        "kind": "LinkedField",
                        "name": "around",
                        "plural": true,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "name": "pageNumber"
                            },
                            {
                                "kind": "ScalarField",
                                "name": "isCurrent"
                            }
                        ]
                    }
                ],
                "storageKey": "pageCursors(maxCursors:7)"
            },
            {
                "kind": "ClientExtension",
                "selections": [
                    {
                        "kind": "ScalarField",
                        "name": "__id"
                    }
                ]
            }
        ],
        "type": "JiraIssueConnection"
    };
    $5be221d7b1d11260$var$node.hash = "26e5557aa71bd28be0879894b7dd8134";
    var $5be221d7b1d11260$export$2e2bcd8739ae039 = $5be221d7b1d11260$var$node;
});
parcelRegister("6bs0c", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$c801f3f91f59a7ab$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $gjEtj;
    var $kxZPA;
    const $c801f3f91f59a7ab$var$AsyncAttachFileModal = (0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("gDF7a")), {
        moduleId: "./src/packages/issue-navigator/issue-operations/dialogs/attach-file-dialog/src/ui/index.tsx"
    });
    var $c801f3f91f59a7ab$export$2e2bcd8739ae039 = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gjEtj")).ModalBoundary), {
            id: "attach-file-dialog",
            packageName: "native-issue-table"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($c801f3f91f59a7ab$var$AsyncAttachFileModal, null));
});
parcelRegister("gDF7a", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("6Rhyy")
    ]).then(()=>parcelRequire('jStLa'));
});
parcelRegister("gkVdw", function(module, exports) {
    $parcel$export(module.exports, "DialogContextContainer", ()=>$2bf33a8fdc4b74e3$export$8e9ef3fae6d51d86);
    $parcel$export(module.exports, "useIssueOperationsDialogContext", ()=>$2bf33a8fdc4b74e3$export$a2a00ec2da7337e);
    var $gg7kZ;
    var $3sQ5x;
    var $iXqE5;
    const $2bf33a8fdc4b74e3$var$initialState = {
        openDialog: null,
        issueKey: null,
        issueId: null,
        mediaUploadContext: null
    };
    const $2bf33a8fdc4b74e3$var$actions = {
        setDialogContext: (openDialog, issueId, issueKey, mediaUploadContext)=>({ setState: setState })=>{
                setState({
                    openDialog: openDialog,
                    issueId: issueId,
                    issueKey: issueKey,
                    mediaUploadContext: mediaUploadContext
                });
            }
    };
    const $2bf33a8fdc4b74e3$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: $2bf33a8fdc4b74e3$var$initialState,
        actions: $2bf33a8fdc4b74e3$var$actions,
        name: 'IssueNavigatorIssueOperationsDialogContext'
    });
    const $2bf33a8fdc4b74e3$export$8e9ef3fae6d51d86 = (0, (0, parcelRequire("gg7kZ")).createContainer)($2bf33a8fdc4b74e3$var$Store);
    const $2bf33a8fdc4b74e3$export$a2a00ec2da7337e = (0, (0, parcelRequire("3sQ5x")).createHook)($2bf33a8fdc4b74e3$var$Store);
});
parcelRegister("5iGOJ", function(module, exports) {
    $parcel$export(module.exports, "useColumnWidthUpdater", ()=>$64afe22c2487a08a$export$e54397ae25183faf);
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $eiowN = parcelRequire("eiowN");
    const $64afe22c2487a08a$export$e54397ae25183faf = (connectionId)=>{
        const environment = (0, $8Uumt.useRelayEnvironment)();
        return (0, $5uXOq.useCallback)((columnId, width)=>{
            (0, $eiowN.commitLocalUpdate)(environment, (store)=>{
                const connection = store.get(connectionId);
                const fieldSetEdges = connection?.getLinkedRecords('edges') ?? [];
                const fieldSetEdge = fieldSetEdges.find((edge)=>{
                    const fieldSetId = edge?.getLinkedRecord('node')?.getValue('fieldSetId');
                    return columnId === fieldSetId;
                });
                if (!fieldSetEdge) return;
                const fieldSetNode = fieldSetEdge.getLinkedRecord('node');
                if (!fieldSetNode) return;
                if (width == null) {
                    fieldSetNode.setValue(null, 'fieldSetPreferences');
                    return;
                }
                const fieldSetPreferences = fieldSetNode.getOrCreateLinkedRecord('fieldSetPreferences', 'JiraFieldSetPreferences');
                fieldSetPreferences.setValue(width, 'width');
            });
        }, [
            connectionId,
            environment
        ]);
    };
});
parcelRegister("32GaU", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$6283edc5fe5ca969$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $lfTZh;
    var $kxZPA;
    var $hxGva;
    const $6283edc5fe5ca969$var$AsyncNativeIssueTable = (0, (0, parcelRequire("kxZPA")).lazyForPaint)(()=>(parcelRequire("gW0mw")), {
        moduleId: "./src/packages/platform/ui/issue-table/issue-table/src/ui/index.tsx"
    });
    const $6283edc5fe5ca969$var$AsyncNativeIssueTableWithWrapper = (0, $5uXOq.memo)((props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hxGva")).IssueTableSkeleton), null),
            name: "async-native-issue-table"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($6283edc5fe5ca969$var$AsyncNativeIssueTable, props)));
    var $6283edc5fe5ca969$export$2e2bcd8739ae039 = $6283edc5fe5ca969$var$AsyncNativeIssueTableWithWrapper;
});
parcelRegister("hxGva", function(module, exports) {
    $parcel$export(module.exports, "IssueTableSkeleton", ()=>$d6b3a71d2cc1f810$export$32020b6a5557f03e);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $3ZuB3;
    const $d6b3a71d2cc1f810$export$32020b6a5557f03e = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement($d6b3a71d2cc1f810$var$SkeletonContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3ZuB3")).NativeIssueTableSkeleton), null));
    const $d6b3a71d2cc1f810$var$SkeletonContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_16jlkb7n _1reo15vq _18m915vq",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("gW0mw", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("hPzOn"),
        $6fhBS("nEBxE")
    ]).then(()=>parcelRequire('bPcgy'));
});
parcelRegister("hZQx0", function(module, exports) {
    $parcel$export(module.exports, "ColumnPickerMessageContext", ()=>$709509bebc56c733$export$2f14e8286acf4660);
    var $5uXOq = parcelRequire("5uXOq");
    const $709509bebc56c733$var$defaultMessageContext = {
        hasMaxSelectedColumns: false
    };
    const $709509bebc56c733$export$2f14e8286acf4660 = (0, $5uXOq.createContext)($709509bebc56c733$var$defaultMessageContext);
});
parcelRegister("kiKYF", function(module, exports) {
    $parcel$export(module.exports, "useColumnLoader", ()=>$c727c8c1b7da3679$export$6dc2ac601f90aafc);
    var $5MNOm;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $cgL5K;
    var $jcw0u;
    var $koVbs;
    var $hH4aB;
    var $Nu4Sg;
    var $kHDIc;
    var $koeKL;
    var $6UAdF;
    var $eJ0Zs;
    var $bWSqf;
    var $2F3SN;
    const $c727c8c1b7da3679$var$mapColumnPickerOptions = (data, search, intl, selectedColumns, canEditColumns)=>{
        let totalCount = 0;
        let edges;
        let endCursor;
        if (data?.jira.issueSearchViewByNamespaceAndViewId.fieldSets) {
            totalCount = data?.jira.issueSearchViewByNamespaceAndViewId.fieldSets.totalCount;
            edges = data?.jira.issueSearchViewByNamespaceAndViewId.fieldSets.edges;
            endCursor = data?.jira.issueSearchViewByNamespaceAndViewId.fieldSets.pageInfo?.endCursor ? data?.jira.issueSearchViewByNamespaceAndViewId.fieldSets.pageInfo.endCursor : undefined;
        }
        const options = edges?.filter((0, (0, parcelRequire("eJ0Zs")).isNonNullish)).map((edge)=>{
            const fieldTypeDisplayName = edge.node.fieldType?.displayName;
            const icon = edge.node.type ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kHDIc")).FieldTypeIcon), {
                type: edge.node.type,
                size: "small"
            }) : undefined;
            const isOnlyUnselectable = (0, (0, parcelRequire("2F3SN")).isOnlyUnselectableField)(edge.node.type, canEditColumns);
            return {
                optionType: 'option',
                cursor: edge.cursor,
                value: edge.node.fieldSetId,
                label: edge.node.displayName,
                secondaryLabel: fieldTypeDisplayName && icon ? (0, (0, parcelRequire("Nu4Sg")).getSecondaryLabelWithIcon)(icon, fieldTypeDisplayName) : undefined,
                ...isOnlyUnselectable && {
                    isOnlyUnselectable: isOnlyUnselectable,
                    disabledMessage: intl.formatMessage((0, (0, parcelRequire("bWSqf")).default).deprecatedParentyLinkMessage, {
                        field: edge.node.displayName,
                        strong: (chunks)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
                                as: "strong",
                                color: "inherit",
                                size: "UNSAFE_small"
                            }, chunks)
                    })
                }
            };
        });
        if (!options?.length) return {
            options: [],
            totalCount: 0
        };
        if (search.length) return {
            options: options,
            totalCount: totalCount,
            endCursor: endCursor
        };
        totalCount += selectedColumns.length;
        return {
            options: [
                {
                    label: intl.formatMessage((0, (0, parcelRequire("bWSqf")).default).columnGroupHeader),
                    optionType: 'group',
                    options: options
                }
            ],
            totalCount: totalCount,
            endCursor: endCursor
        };
    };
    const $c727c8c1b7da3679$export$6dc2ac601f90aafc = (viewId, canEditColumns, selectedColumns)=>{
        const intl = (0, (0, parcelRequire("koVbs")).default)();
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const environment = (0, $8Uumt.useRelayEnvironment)();
        const columnsRef = (0, $5uXOq.useRef)(selectedColumns);
        const endCursorRef = (0, $5uXOq.useRef)();
        const searchRef = (0, $5uXOq.useRef)();
        const loadedOptionsRef = (0, $5uXOq.useRef)();
        return (0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("hH4aB")).createDebouncedColumnLoader)(async (search, renderInitialValues, endCursor)=>{
                let cursor = endCursor;
                if (!canEditColumns) {
                    const options = selectedColumns ? selectedColumns.filter((col)=>col.optionType === 'option' && (search ? col.label.toLocaleLowerCase().includes(search.toLocaleLowerCase()) : true)) : [];
                    return Promise.resolve({
                        options: options,
                        totalCount: options.length
                    });
                }
                if (endCursor !== undefined && loadedOptionsRef.current && selectedColumns) cursor = (0, (0, parcelRequire("2F3SN")).getCursorOfLastUnselectedOption)(loadedOptionsRef.current, selectedColumns, search, endCursor);
                try {
                    const result = await (0, $8Uumt.fetchQuery)(environment, (0, (0, parcelRequire("5MNOm")).default), {
                        cloudId: cloudId,
                        namespace: (0, (0, parcelRequire("6UAdF")).ISSUE_SEARCH_NAMESPACE),
                        viewId: viewId,
                        first: 25,
                        after: cursor,
                        filter: {
                            searchString: search,
                            fieldSetSelectedState: !search ? 'NON_SELECTED' : 'ALL'
                        }
                    }, {
                        fetchPolicy: columnsRef.current !== selectedColumns || endCursorRef.current !== cursor || searchRef.current !== search ? 'network-only' : 'store-or-network'
                    }).toPromise();
                    columnsRef.current = selectedColumns;
                    endCursorRef.current = cursor;
                    searchRef.current = search;
                    const pickerOptions = $c727c8c1b7da3679$var$mapColumnPickerOptions(result, search, intl, selectedColumns, canEditColumns);
                    loadedOptionsRef.current = pickerOptions.options || undefined;
                    return pickerOptions;
                } catch (error) {
                    (0, (0, parcelRequire("jcw0u")).default)({
                        meta: {
                            id: 'fetchColumnPickerOptions',
                            packageName: (0, (0, parcelRequire("6UAdF")).PACKAGE_NAME),
                            teamName: (0, (0, parcelRequire("6UAdF")).TEAM_NAME)
                        },
                        error: error,
                        sendToPrivacyUnsafeSplunk: true
                    });
                    loadedOptionsRef.current = undefined;
                    return {
                        options: [
                            {
                                optionType: 'error',
                                error: error,
                                onRetry: ()=>undefined
                            }
                        ],
                        totalCount: 0
                    };
                }
            }), [
            canEditColumns,
            cloudId,
            environment,
            intl,
            selectedColumns,
            viewId
        ]);
    };
});
parcelRegister("5MNOm", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$611919e8b187ec0e$export$2e2bcd8739ae039);
    const $611919e8b187ec0e$var$node = function() {
        var v0 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "after"
        }, v1 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cloudId"
        }, v2 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "filter"
        }, v3 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "first"
        }, v4 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "namespace"
        }, v5 = {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "viewId"
        }, v6 = [
            {
                "kind": "Variable",
                "name": "cloudId",
                "variableName": "cloudId"
            },
            {
                "kind": "Variable",
                "name": "namespace",
                "variableName": "namespace"
            },
            {
                "kind": "Variable",
                "name": "viewId",
                "variableName": "viewId"
            }
        ], v7 = [
            {
                "kind": "Variable",
                "name": "after",
                "variableName": "after"
            },
            {
                "kind": "Variable",
                "name": "filter",
                "variableName": "filter"
            },
            {
                "kind": "Variable",
                "name": "first",
                "variableName": "first"
            }
        ], v8 = {
            "kind": "ScalarField",
            "name": "totalCount"
        }, v9 = {
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "endCursor"
                }
            ]
        }, v10 = {
            "kind": "ScalarField",
            "name": "cursor"
        }, v11 = {
            "kind": "ScalarField",
            "name": "fieldSetId"
        }, v12 = {
            "kind": "ScalarField",
            "name": "displayName"
        }, v13 = {
            "kind": "ScalarField",
            "name": "type"
        }, v14 = {
            "concreteType": "JiraFieldType",
            "kind": "LinkedField",
            "name": "fieldType",
            "plural": false,
            "selections": [
                v12
            ]
        };
        return {
            "fragment": {
                "argumentDefinitions": [
                    v0,
                    v1,
                    v2,
                    v3,
                    v4,
                    v5
                ],
                "kind": "Fragment",
                "name": "fetchColumnPickerOptionsQuery",
                "selections": [
                    {
                        "kind": "RequiredField",
                        "field": {
                            "concreteType": "JiraQuery",
                            "kind": "LinkedField",
                            "name": "jira",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "RequiredField",
                                    "field": {
                                        "args": v6,
                                        "concreteType": "JiraIssueSearchView",
                                        "kind": "LinkedField",
                                        "name": "issueSearchViewByNamespaceAndViewId",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "RequiredField",
                                                "field": {
                                                    "args": v7,
                                                    "concreteType": "JiraIssueSearchFieldSetConnection",
                                                    "kind": "LinkedField",
                                                    "name": "fieldSets",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "kind": "RequiredField",
                                                            "field": v8,
                                                            "action": "THROW",
                                                            "path": "jira.issueSearchViewByNamespaceAndViewId.fieldSets.totalCount"
                                                        },
                                                        v9,
                                                        {
                                                            "kind": "RequiredField",
                                                            "field": {
                                                                "concreteType": "JiraIssueSearchFieldSetEdge",
                                                                "kind": "LinkedField",
                                                                "name": "edges",
                                                                "plural": true,
                                                                "selections": [
                                                                    v10,
                                                                    {
                                                                        "kind": "RequiredField",
                                                                        "field": {
                                                                            "concreteType": "JiraIssueSearchFieldSet",
                                                                            "kind": "LinkedField",
                                                                            "name": "node",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                {
                                                                                    "kind": "RequiredField",
                                                                                    "field": v11,
                                                                                    "action": "THROW",
                                                                                    "path": "jira.issueSearchViewByNamespaceAndViewId.fieldSets.edges.node.fieldSetId"
                                                                                },
                                                                                {
                                                                                    "kind": "RequiredField",
                                                                                    "field": v12,
                                                                                    "action": "THROW",
                                                                                    "path": "jira.issueSearchViewByNamespaceAndViewId.fieldSets.edges.node.displayName"
                                                                                },
                                                                                {
                                                                                    "kind": "RequiredField",
                                                                                    "field": v13,
                                                                                    "action": "THROW",
                                                                                    "path": "jira.issueSearchViewByNamespaceAndViewId.fieldSets.edges.node.type"
                                                                                },
                                                                                v14
                                                                            ]
                                                                        },
                                                                        "action": "THROW",
                                                                        "path": "jira.issueSearchViewByNamespaceAndViewId.fieldSets.edges.node"
                                                                    }
                                                                ]
                                                            },
                                                            "action": "THROW",
                                                            "path": "jira.issueSearchViewByNamespaceAndViewId.fieldSets.edges"
                                                        }
                                                    ]
                                                },
                                                "action": "THROW",
                                                "path": "jira.issueSearchViewByNamespaceAndViewId.fieldSets"
                                            }
                                        ]
                                    },
                                    "action": "THROW",
                                    "path": "jira.issueSearchViewByNamespaceAndViewId"
                                }
                            ]
                        },
                        "action": "THROW",
                        "path": "jira"
                    }
                ],
                "type": "Query"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": [
                    v1,
                    v4,
                    v5,
                    v3,
                    v0,
                    v2
                ],
                "kind": "Operation",
                "name": "fetchColumnPickerOptionsQuery",
                "selections": [
                    {
                        "concreteType": "JiraQuery",
                        "kind": "LinkedField",
                        "name": "jira",
                        "plural": false,
                        "selections": [
                            {
                                "args": v6,
                                "concreteType": "JiraIssueSearchView",
                                "kind": "LinkedField",
                                "name": "issueSearchViewByNamespaceAndViewId",
                                "plural": false,
                                "selections": [
                                    {
                                        "args": v7,
                                        "concreteType": "JiraIssueSearchFieldSetConnection",
                                        "kind": "LinkedField",
                                        "name": "fieldSets",
                                        "plural": false,
                                        "selections": [
                                            v8,
                                            v9,
                                            {
                                                "concreteType": "JiraIssueSearchFieldSetEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                    v10,
                                                    {
                                                        "concreteType": "JiraIssueSearchFieldSet",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                            v11,
                                                            v12,
                                                            v13,
                                                            v14
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "kind": "ScalarField",
                                        "name": "id"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "params": {
                "id": "2fc2bdb7e99242c748d59a003f006e50b4a56a963e6ef08e6ffb31bdfa8c511d",
                "metadata": {},
                "name": "fetchColumnPickerOptionsQuery",
                "operationKind": "query",
                "text": null
            }
        };
    }();
    $611919e8b187ec0e$var$node.hash = "374080c39c08d2cf25a28460ad1b6425";
    var $611919e8b187ec0e$export$2e2bcd8739ae039 = $611919e8b187ec0e$var$node;
});
parcelRegister("hH4aB", function(module, exports) {
    $parcel$export(module.exports, "MAX_COLUMNS", ()=>$3fccc46c5f9227b4$export$7ec4af037f372df4);
    $parcel$export(module.exports, "createDebouncedColumnLoader", ()=>$3fccc46c5f9227b4$export$a6cff24b97727de1);
    var $7pFAn;
    const $3fccc46c5f9227b4$export$b8b9d90f9e5bd72b = 'jiraIssueTableColumnHeader';
    const $3fccc46c5f9227b4$export$d57b6b390e78d90f = 'empanada';
    const $3fccc46c5f9227b4$export$7ec4af037f372df4 = 500;
    const $3fccc46c5f9227b4$export$a6cff24b97727de1 = (0, (0, parcelRequire("7pFAn")).createGenericDebouncableDataLoader);
});
parcelRegister("7pFAn", function(module, exports) {
    $parcel$export(module.exports, "createGenericDebouncableDataLoader", ()=>$b301ace3fb837e49$export$ea63a11564958c9e);
    var $lFKmC = parcelRequire("lFKmC");
    const $b301ace3fb837e49$export$ea63a11564958c9e = (dataLoader)=>{
        const debouncedDataLoader = (0, ($parcel$interopDefault($lFKmC)))((search, enableFullList, endCursor, cb)=>dataLoader(search, enableFullList, endCursor).then((data)=>{
                cb(data);
                return data;
            }), 500);
        return (search, enableFullList, endCursor, cb)=>{
            if (!search) {
                debouncedDataLoader.cancel();
                return dataLoader(search, enableFullList, endCursor).then((data)=>{
                    cb(data);
                    return data;
                });
            }
            return debouncedDataLoader(search, enableFullList, endCursor, cb);
        };
    };
    var $b301ace3fb837e49$export$2e2bcd8739ae039 = $b301ace3fb837e49$export$ea63a11564958c9e;
});
parcelRegister("Nu4Sg", function(module, exports) {
    $parcel$export(module.exports, "getSecondaryLabelWithIcon", ()=>$f4ca1075411980d4$export$de24d837eb50c435);
    var $5uXOq = parcelRequire("5uXOq");
    const $f4ca1075411980d4$export$de24d837eb50c435 = (icon, fieldTypeDisplayName)=>({
            label: fieldTypeDisplayName,
            element: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, icon, fieldTypeDisplayName)
        });
});
parcelRegister("kHDIc", function(module, exports) {
    $parcel$export(module.exports, "fieldTypesIcons", ()=>$84df96f6dc813d24$export$67f993f3bbfb364d);
    $parcel$export(module.exports, "panelIcons", ()=>$84df96f6dc813d24$export$4641b622f6c36f58);
    $parcel$export(module.exports, "FieldTypeIcon", ()=>$84df96f6dc813d24$export$87c5289d8835f589);
    $parcel$export(module.exports, "default", ()=>$84df96f6dc813d24$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $5LWLA;
    var $4iV1e;
    var $9XvX5;
    var $h8cgU;
    var $63gsv;
    var $4R6GH;
    var $5fc53;
    var $5fc53;
    var $3XO6n;
    var $VoGvu;
    const $84df96f6dc813d24$var$ResizedIcon = ({ glyph: glyph })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5LWLA")).default), {
            label: "",
            glyph: glyph,
            size: "medium"
        });
    const $84df96f6dc813d24$var$checkedFieldTypesIcons = {
        [(0, (0, parcelRequire("5fc53")).NUMBER_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).numberGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).STORY_POINT_ESTIMATE_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).numberGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).LABELS_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).labelGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).DATE_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).dateGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).DATETIME_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).timeStampGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).RADIO_BUTTONS_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).radioGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).SELECT_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).dropdownGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).MULTI_SELECT_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).dropdownGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).CASCADING_SELECT_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).doubleDropdownGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).MULTI_VERSION_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).dropdownGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).VERSION_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).dropdownGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).GROUP_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).dropdownGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).MULTI_GROUP_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).dropdownGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).USER_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).peopleGlyph)
        }),
        [(0, (0, parcelRequire("3XO6n")).USERPICKER_FIELD_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).peopleGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).MULTI_USER_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).peopleGlyph)
        }),
        [(0, (0, parcelRequire("3XO6n")).MULTIUSERPICKER_FIELD_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).peopleGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).PEOPLE_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).peopleGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).FLAGGED_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).timeStampGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).URL_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).customFieldGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).TEAM_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).customFieldGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).TEAMS_PLATFORM_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("h8cgU"))))), {
            label: ""
        }),
        [(0, (0, parcelRequire("5fc53")).PARENT_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).customFieldGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).BASELINE_START_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).dateGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).BASELINE_END_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).dateGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).SPRINT_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).shipGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).REQUEST_PARTICIPANTS_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).peopleGroupGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).ORGANIZATIONS_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).officeBuildingGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).REPORTER_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).peopleGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).ATTACHMENT_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).attachmentGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).ASSIGNEE_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).peopleGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).LABELS_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).labelGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).PRIORITY_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).priorityGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).DUE_DATE_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).dateGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).TIME_ESTIMATE_CONFIG_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).timeStampGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).TIME_TRACKING_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).timeTrackingGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).TIME_ESTIMATE_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).originalTimeEstimateGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).ASSETS_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).screenGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).SERVICE_ENTITY_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).labelGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).STATUS_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).arrowRightGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).SUMMARY_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).shortTextGlyph)
        }),
        [(0, (0, parcelRequire("3XO6n")).DESCRIPTION_FIELD_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).paragraphGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).TEXT_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).shortTextGlyph)
        }),
        [(0, (0, parcelRequire("3XO6n")).TEXT_AREA_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).paragraphGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).MULTI_CHECKBOXES_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).checkboxGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).ISSUE_LINKS_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).customFieldGlyph)
        }),
        [(0, (0, parcelRequire("3XO6n")).ENVIRONMENT_FIELD_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).paragraphGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).FIX_VERSIONS_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).dropdownGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).COMMENT_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).commentGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).APPROVERS_LIST_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).appAccessGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).APPROVALS_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).appAccessGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).PROJECT_PICKER_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).folderGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).MESSAGE_EDIT_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).paragraphGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).MESSAGE_VIEW_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).paragraphGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).CATEGORY_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).jwmCategoryGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).ENTITLEMENT_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).dropdownGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).SENTIMENT_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).customFieldGlyph)
        }),
        [(0, (0, parcelRequire("5fc53")).GOALS_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("4iV1e"))))), {
            label: "",
            spacing: "spacious",
            color: "var(--ds-icon, #44546F)"
        }),
        [(0, (0, parcelRequire("5fc53")).ORGANIZATION_CF_TYPE)]: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).customFieldGlyph)
        })
    };
    ({
        ...$84df96f6dc813d24$var$checkedFieldTypesIcons
    });
    const $84df96f6dc813d24$export$67f993f3bbfb364d = $84df96f6dc813d24$var$checkedFieldTypesIcons;
    const $84df96f6dc813d24$export$4641b622f6c36f58 = {
        development: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("9XvX5"))))), {
            label: "",
            size: "medium"
        }),
        devSummary: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("9XvX5"))))), {
            label: "",
            size: "medium"
        }),
        timetracking: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("63gsv"))))), {
            label: "",
            size: "medium"
        })
    };
    const $84df96f6dc813d24$var$allIcons = {
        ...$84df96f6dc813d24$export$67f993f3bbfb364d,
        text: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).paragraphGlyph)
        }),
        number: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).numberGlyph)
        }),
        date: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).dateGlyph)
        }),
        datetime: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).timeStampGlyph)
        }),
        dropdown: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).dropdownGlyph)
        }),
        addon: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).addonGlyph)
        }),
        checkbox: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).checkboxGlyph)
        }),
        user: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).peopleGlyph)
        }),
        radio: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).radioGlyph)
        }),
        time: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).timeStampGlyph)
        }),
        message: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).commentGlyph)
        }),
        bulletList: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).bulletListGlyph)
        }),
        label: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).labelGlyph)
        }),
        labelpicker: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).labelGlyph)
        }),
        close: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).closeGlyph)
        }),
        assetspicker: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).screenGlyph)
        }),
        select: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).dropdownGlyph)
        }),
        multiselect: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).dropdownGlyph)
        }),
        cascadingselect: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).doubleDropdownGlyph)
        }),
        multigrouppicker: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).dropdownGlyph)
        }),
        grouppicker: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).dropdownGlyph)
        }),
        multiversion: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).dropdownGlyph)
        }),
        version: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).dropdownGlyph)
        }),
        projectpicker: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).dropdownGlyph)
        }),
        customField: (0, ($parcel$interopDefault($5uXOq))).createElement($84df96f6dc813d24$var$ResizedIcon, {
            glyph: (0, (0, parcelRequire("VoGvu")).customFieldGlyph)
        })
    };
    const $84df96f6dc813d24$var$checkedFieldTypesGlyphs = {
        [(0, (0, parcelRequire("5fc53")).NUMBER_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).numberGlyph),
        [(0, (0, parcelRequire("5fc53")).STORY_POINT_ESTIMATE_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).numberGlyph),
        [(0, (0, parcelRequire("5fc53")).LABELS_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).labelGlyph),
        [(0, (0, parcelRequire("5fc53")).DATE_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).dateGlyph),
        [(0, (0, parcelRequire("5fc53")).DATETIME_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).timeStampGlyph),
        [(0, (0, parcelRequire("5fc53")).RADIO_BUTTONS_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).radioGlyph),
        [(0, (0, parcelRequire("5fc53")).SELECT_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).dropdownGlyph),
        [(0, (0, parcelRequire("5fc53")).MULTI_SELECT_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).dropdownGlyph),
        [(0, (0, parcelRequire("5fc53")).CASCADING_SELECT_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).doubleDropdownGlyph),
        [(0, (0, parcelRequire("5fc53")).MULTI_VERSION_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).dropdownGlyph),
        [(0, (0, parcelRequire("5fc53")).VERSION_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).dropdownGlyph),
        [(0, (0, parcelRequire("5fc53")).GROUP_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).dropdownGlyph),
        [(0, (0, parcelRequire("5fc53")).MULTI_GROUP_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).dropdownGlyph),
        [(0, (0, parcelRequire("5fc53")).USER_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).peopleGlyph),
        [(0, (0, parcelRequire("3XO6n")).USERPICKER_FIELD_TYPE)]: (0, (0, parcelRequire("VoGvu")).peopleGlyph),
        [(0, (0, parcelRequire("5fc53")).MULTI_USER_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).peopleGlyph),
        [(0, (0, parcelRequire("3XO6n")).MULTIUSERPICKER_FIELD_TYPE)]: (0, (0, parcelRequire("VoGvu")).peopleGlyph),
        [(0, (0, parcelRequire("5fc53")).PEOPLE_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).peopleGlyph),
        [(0, (0, parcelRequire("5fc53")).FLAGGED_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).timeStampGlyph),
        [(0, (0, parcelRequire("5fc53")).URL_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).customFieldGlyph),
        [(0, (0, parcelRequire("5fc53")).TEAM_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).customFieldGlyph),
        [(0, (0, parcelRequire("5fc53")).PARENT_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).customFieldGlyph),
        [(0, (0, parcelRequire("5fc53")).BASELINE_START_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).dateGlyph),
        [(0, (0, parcelRequire("5fc53")).BASELINE_END_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).dateGlyph),
        [(0, (0, parcelRequire("5fc53")).SPRINT_TYPE)]: (0, (0, parcelRequire("VoGvu")).shipGlyph),
        [(0, (0, parcelRequire("5fc53")).REQUEST_PARTICIPANTS_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).peopleGroupGlyph),
        [(0, (0, parcelRequire("5fc53")).ORGANIZATIONS_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).officeBuildingGlyph),
        [(0, (0, parcelRequire("5fc53")).REPORTER_TYPE)]: (0, (0, parcelRequire("VoGvu")).peopleGlyph),
        [(0, (0, parcelRequire("5fc53")).ATTACHMENT_TYPE)]: (0, (0, parcelRequire("VoGvu")).attachmentGlyph),
        [(0, (0, parcelRequire("5fc53")).ASSIGNEE_TYPE)]: (0, (0, parcelRequire("VoGvu")).peopleGlyph),
        [(0, (0, parcelRequire("5fc53")).LABELS_TYPE)]: (0, (0, parcelRequire("VoGvu")).labelGlyph),
        [(0, (0, parcelRequire("5fc53")).PRIORITY_TYPE)]: (0, (0, parcelRequire("VoGvu")).priorityGlyph),
        [(0, (0, parcelRequire("5fc53")).DUE_DATE_TYPE)]: (0, (0, parcelRequire("VoGvu")).dateGlyph),
        [(0, (0, parcelRequire("5fc53")).TIME_ESTIMATE_CONFIG_TYPE)]: (0, (0, parcelRequire("VoGvu")).timeStampGlyph),
        [(0, (0, parcelRequire("5fc53")).TIME_TRACKING_TYPE)]: (0, (0, parcelRequire("VoGvu")).timeTrackingGlyph),
        [(0, (0, parcelRequire("5fc53")).TIME_ESTIMATE_TYPE)]: (0, (0, parcelRequire("VoGvu")).originalTimeEstimateGlyph),
        [(0, (0, parcelRequire("5fc53")).ASSETS_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).screenGlyph),
        [(0, (0, parcelRequire("5fc53")).SERVICE_ENTITY_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).labelGlyph),
        [(0, (0, parcelRequire("5fc53")).STATUS_TYPE)]: (0, (0, parcelRequire("VoGvu")).arrowRightGlyph),
        [(0, (0, parcelRequire("5fc53")).SUMMARY_TYPE)]: (0, (0, parcelRequire("VoGvu")).shortTextGlyph),
        [(0, (0, parcelRequire("3XO6n")).DESCRIPTION_FIELD_TYPE)]: (0, (0, parcelRequire("VoGvu")).paragraphGlyph),
        [(0, (0, parcelRequire("5fc53")).TEXT_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).shortTextGlyph),
        [(0, (0, parcelRequire("3XO6n")).TEXT_AREA_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).paragraphGlyph),
        [(0, (0, parcelRequire("5fc53")).MULTI_CHECKBOXES_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).checkboxGlyph),
        [(0, (0, parcelRequire("5fc53")).ISSUE_LINKS_TYPE)]: (0, (0, parcelRequire("VoGvu")).customFieldGlyph),
        [(0, (0, parcelRequire("3XO6n")).ENVIRONMENT_FIELD_TYPE)]: (0, (0, parcelRequire("VoGvu")).paragraphGlyph),
        [(0, (0, parcelRequire("5fc53")).FIX_VERSIONS_TYPE)]: (0, (0, parcelRequire("VoGvu")).dropdownGlyph),
        [(0, (0, parcelRequire("5fc53")).COMMENT_TYPE)]: (0, (0, parcelRequire("VoGvu")).commentGlyph),
        [(0, (0, parcelRequire("5fc53")).APPROVERS_LIST_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).appAccessGlyph),
        [(0, (0, parcelRequire("5fc53")).APPROVALS_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).appAccessGlyph),
        [(0, (0, parcelRequire("5fc53")).PROJECT_PICKER_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).folderGlyph),
        [(0, (0, parcelRequire("5fc53")).MESSAGE_EDIT_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).paragraphGlyph),
        [(0, (0, parcelRequire("5fc53")).MESSAGE_VIEW_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).paragraphGlyph),
        [(0, (0, parcelRequire("5fc53")).CATEGORY_TYPE)]: (0, (0, parcelRequire("VoGvu")).jwmCategoryGlyph),
        [(0, (0, parcelRequire("5fc53")).ENTITLEMENT_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).dropdownGlyph),
        [(0, (0, parcelRequire("5fc53")).SENTIMENT_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).customFieldGlyph),
        [(0, (0, parcelRequire("5fc53")).ORGANIZATION_CF_TYPE)]: (0, (0, parcelRequire("VoGvu")).customFieldGlyph)
    };
    const $84df96f6dc813d24$export$14355977bfae6aaa = {
        ...$84df96f6dc813d24$var$checkedFieldTypesGlyphs,
        text: (0, (0, parcelRequire("VoGvu")).paragraphGlyph),
        number: (0, (0, parcelRequire("VoGvu")).numberGlyph),
        date: (0, (0, parcelRequire("VoGvu")).dateGlyph),
        datetime: (0, (0, parcelRequire("VoGvu")).timeStampGlyph),
        dropdown: (0, (0, parcelRequire("VoGvu")).dropdownGlyph),
        addon: (0, (0, parcelRequire("VoGvu")).addonGlyph),
        checkbox: (0, (0, parcelRequire("VoGvu")).checkboxGlyph),
        user: (0, (0, parcelRequire("VoGvu")).peopleGlyph),
        radio: (0, (0, parcelRequire("VoGvu")).radioGlyph),
        time: (0, (0, parcelRequire("VoGvu")).timeStampGlyph),
        message: (0, (0, parcelRequire("VoGvu")).commentGlyph),
        bulletList: (0, (0, parcelRequire("VoGvu")).bulletListGlyph),
        label: (0, (0, parcelRequire("VoGvu")).labelGlyph),
        labelpicker: (0, (0, parcelRequire("VoGvu")).labelGlyph),
        close: (0, (0, parcelRequire("VoGvu")).closeGlyph),
        assetspicker: (0, (0, parcelRequire("VoGvu")).screenGlyph),
        select: (0, (0, parcelRequire("VoGvu")).dropdownGlyph),
        multiselect: (0, (0, parcelRequire("VoGvu")).dropdownGlyph),
        cascadingselect: (0, (0, parcelRequire("VoGvu")).doubleDropdownGlyph),
        multigrouppicker: (0, (0, parcelRequire("VoGvu")).dropdownGlyph),
        grouppicker: (0, (0, parcelRequire("VoGvu")).dropdownGlyph),
        multiversion: (0, (0, parcelRequire("VoGvu")).dropdownGlyph),
        version: (0, (0, parcelRequire("VoGvu")).dropdownGlyph),
        projectpicker: (0, (0, parcelRequire("VoGvu")).dropdownGlyph),
        customField: (0, (0, parcelRequire("VoGvu")).customFieldGlyph)
    };
    const $84df96f6dc813d24$export$aaac7dbe01e6025d = (0, $5uXOq.memo)(({ type: type, size: size = 'medium', testId: testId })=>{
        if (type === (0, (0, parcelRequire("5fc53")).TEAMS_PLATFORM_CF_TYPE)) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("h8cgU"))))), {
            label: "",
            size: size,
            testId: testId
        });
        if (type === (0, (0, parcelRequire("5fc53")).GOALS_CF_TYPE) && (0, (0, parcelRequire("4R6GH")).fg)('native_goals_integration_on_list_view')) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("4iV1e"))))), {
            label: "",
            spacing: "spacious",
            testId: testId,
            color: "var(--ds-icon, #44546F)"
        });
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5LWLA")).default), {
            label: "",
            glyph: $84df96f6dc813d24$var$checkedFieldTypesGlyphs[type] || $84df96f6dc813d24$var$checkedFieldTypesGlyphs.customField,
            size: size,
            testId: testId
        });
    });
    const $84df96f6dc813d24$export$87c5289d8835f589 = (0, $5uXOq.memo)(({ type: type, size: size = 'medium', testId: testId })=>{
        if (type === (0, (0, parcelRequire("5fc53")).TEAMS_PLATFORM_CF_TYPE)) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("h8cgU"))))), {
            label: "",
            size: size,
            testId: testId
        });
        if (type === (0, (0, parcelRequire("5fc53")).GOALS_CF_TYPE) && (0, (0, parcelRequire("4R6GH")).fg)('native_goals_integration_on_list_view')) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("4iV1e"))))), {
            label: "",
            spacing: "spacious",
            testId: testId,
            color: "var(--ds-icon, #44546F)"
        });
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5LWLA")).default), {
            label: "",
            glyph: $84df96f6dc813d24$export$14355977bfae6aaa[type] || $84df96f6dc813d24$export$14355977bfae6aaa.customField,
            size: size,
            testId: testId
        });
    });
    var $84df96f6dc813d24$export$2e2bcd8739ae039 = $84df96f6dc813d24$var$allIcons;
});
parcelRegister("9XvX5", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $740268c474e86b43$var$_react = $740268c474e86b43$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $740268c474e86b43$var$_branch = $740268c474e86b43$var$_interopRequireDefault((parcelRequire("4iPF3")));
    function $740268c474e86b43$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $740268c474e86b43$var$BitbucketBranchesIcon = (props)=>$740268c474e86b43$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><path fill="currentcolor" fill-rule="evenodd" d="M19 11c0 1.3-.8 2.4-2 2.8V15c0 2.2-1.8 4-4 4h-2.2c-.4 1.2-1.5 2-2.8 2-1.7 0-3-1.3-3-3 0-1.3.8-2.4 2-2.8V8.8C5.9 8.4 5 7.3 5 6c0-1.7 1.3-3 3-3s3 1.3 3 3c0 1.3-.8 2.4-2.1 2.8v6.4c.9.3 1.6.9 1.9 1.8H13c1.1 0 2-.9 2-2v-1.2c-1.2-.4-2-1.5-2-2.8 0-1.7 1.3-3 3-3s3 1.3 3 3M8 5c-.5 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1m8 7c.6 0 1-.4 1-1s-.4-1-1-1-1 .4-1 1 .4 1 1 1m-8 7c.6 0 1-.4 1-1s-.4-1-1-1-1 .4-1 1 .4 1 1 1"/></svg>`
        }, props, {
            newIcon: $740268c474e86b43$var$_branch.default
        }));
    $740268c474e86b43$var$BitbucketBranchesIcon.displayName = 'BitbucketBranchesIcon';
    var $740268c474e86b43$var$_default = module.exports.default = $740268c474e86b43$var$BitbucketBranchesIcon;
});
parcelRegister("4iPF3", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $3220eab15e59539d$var$_react = $3220eab15e59539d$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $3220eab15e59539d$var$_UNSAFE_baseNew = $3220eab15e59539d$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $3220eab15e59539d$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $3220eab15e59539d$var$BranchIcon = (props)=>$3220eab15e59539d$var$_react.default.createElement($3220eab15e59539d$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-width="1.5" d="M4.25 4.75a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm0 0V9m1.5 3.75a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 0h3.5a2.5 2.5 0 0 0 2.5-2.5v-5.5m0 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"/>`
        }, props));
    $3220eab15e59539d$var$BranchIcon.displayName = 'BranchIcon';
    var $3220eab15e59539d$var$_default = module.exports.default = $3220eab15e59539d$var$BranchIcon;
});
parcelRegister("h8cgU", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $c78cb3eb85357720$var$_react = $c78cb3eb85357720$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $c78cb3eb85357720$var$_peopleGroup = $c78cb3eb85357720$var$_interopRequireDefault((parcelRequire("fptva")));
    function $c78cb3eb85357720$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $c78cb3eb85357720$var$PeopleIcon = (props)=>$c78cb3eb85357720$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><g fill="currentcolor" fill-rule="evenodd"><circle cx="16" cy="9" r="3"/><circle cx="8" cy="6" r="3"/><path d="M11 15c0-1.105.887-2 2-2h6c1.105 0 2 .885 2 2v3.73c0 3.027-10 3.027-10 0z"/><path d="M13 12a1.997 1.997 0 0 0-2-2H5c-1.113 0-2 .897-2 2.003v3.826c0 1.921 4.054 2.518 7 1.984v-2.807A3 3 0 0 1 12.997 12z"/></g></svg>`
        }, props, {
            newIcon: $c78cb3eb85357720$var$_peopleGroup.default
        }));
    $c78cb3eb85357720$var$PeopleIcon.displayName = 'PeopleIcon';
    var $c78cb3eb85357720$var$_default = module.exports.default = $c78cb3eb85357720$var$PeopleIcon;
});
parcelRegister("63gsv", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $467fca4622452061$var$_react = $467fca4622452061$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $467fca4622452061$var$_stopwatch = $467fca4622452061$var$_interopRequireDefault((parcelRequire("cXVn2")));
    function $467fca4622452061$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $467fca4622452061$var$StopwatchIcon = (props)=>$467fca4622452061$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><path fill="currentcolor" fill-rule="evenodd" d="m15.587 6.988.626-.664c.203-.215.481-.324.76-.324.247 0 .496.086.694.262a.98.98 0 0 1 .063 1.414l-.565.6a7 7 0 1 1-10.33 0l-.564-.6a.98.98 0 0 1 .062-1.414A1.04 1.04 0 0 1 7.03 6c.278 0 .556.109.76.324l.624.664a7 7 0 0 1 2.582-.916L10.998 5H10.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-.498l.001 1.071a7 7 0 0 1 2.584.917M12 18a5 5 0 1 0 0-10 5 5 0 0 0 0 10m.368-4.232a.796.796 0 0 1-1.14-.018.834.834 0 0 1 .018-1.163l2.386-2.355a.796.796 0 0 1 1.14.018.834.834 0 0 1-.018 1.163z"/></svg>`
        }, props, {
            newIcon: $467fca4622452061$var$_stopwatch.default
        }));
    $467fca4622452061$var$StopwatchIcon.displayName = 'StopwatchIcon';
    var $467fca4622452061$var$_default = module.exports.default = $467fca4622452061$var$StopwatchIcon;
});
parcelRegister("cXVn2", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $97077f52e189c3c9$var$_react = $97077f52e189c3c9$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $97077f52e189c3c9$var$_UNSAFE_baseNew = $97077f52e189c3c9$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $97077f52e189c3c9$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $97077f52e189c3c9$var$StopwatchIcon = (props)=>$97077f52e189c3c9$var$_react.default.createElement($97077f52e189c3c9$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-linejoin="round" stroke-width="1.5" d="M8 3.75v-3m0 3a5.73 5.73 0 0 1 3.885 1.511M8 3.75a5.73 5.73 0 0 0-3.885 1.511M8 .75h3m-3 0H5m8.5 2.75-1.615 1.761M2.5 3.5l1.615 1.761m7.77 0a5.75 5.75 0 1 1-7.77 0M7.75 9.75 10.292 7"/>`
        }, props));
    $97077f52e189c3c9$var$StopwatchIcon.displayName = 'StopwatchIcon';
    var $97077f52e189c3c9$var$_default = module.exports.default = $97077f52e189c3c9$var$StopwatchIcon;
});
parcelRegister("VoGvu", function(module, exports) {
    $parcel$export(module.exports, "numberGlyph", ()=>$2e91e7edf801074a$export$b368272069fe93ea);
    $parcel$export(module.exports, "checkboxGlyph", ()=>$2e91e7edf801074a$export$747056db840f2e62);
    $parcel$export(module.exports, "folderGlyph", ()=>$2e91e7edf801074a$export$fc939f15c0ac57d8);
    $parcel$export(module.exports, "dateGlyph", ()=>$2e91e7edf801074a$export$54ae6633cc16eed1);
    $parcel$export(module.exports, "dropdownGlyph", ()=>$2e91e7edf801074a$export$d298f123ec74117c);
    $parcel$export(module.exports, "doubleDropdownGlyph", ()=>$2e91e7edf801074a$export$b3ea0fc10228ca3e);
    $parcel$export(module.exports, "labelGlyph", ()=>$2e91e7edf801074a$export$fc64eea29ed0831d);
    $parcel$export(module.exports, "paragraphGlyph", ()=>$2e91e7edf801074a$export$cbee9ded46569696);
    $parcel$export(module.exports, "peopleGlyph", ()=>$2e91e7edf801074a$export$2ade78849d2113ea);
    $parcel$export(module.exports, "shortTextGlyph", ()=>$2e91e7edf801074a$export$1c6cf19eb34bcc14);
    $parcel$export(module.exports, "timeStampGlyph", ()=>$2e91e7edf801074a$export$98a4a461fe316fce);
    $parcel$export(module.exports, "timeTrackingGlyph", ()=>$2e91e7edf801074a$export$637d50811e2b7176);
    $parcel$export(module.exports, "originalTimeEstimateGlyph", ()=>$2e91e7edf801074a$export$bc2bb299bed9df91);
    $parcel$export(module.exports, "arrowRightGlyph", ()=>$2e91e7edf801074a$export$3a9d3f1c52f4dab9);
    $parcel$export(module.exports, "addonGlyph", ()=>$2e91e7edf801074a$export$a4cdad58220c405a);
    $parcel$export(module.exports, "attachmentGlyph", ()=>$2e91e7edf801074a$export$e6e85f1bc2e23bd4);
    $parcel$export(module.exports, "bulletListGlyph", ()=>$2e91e7edf801074a$export$a6a7f7b7c0399a2a);
    $parcel$export(module.exports, "commentGlyph", ()=>$2e91e7edf801074a$export$835d228e2a9d6a93);
    $parcel$export(module.exports, "officeBuildingGlyph", ()=>$2e91e7edf801074a$export$fdad804cb16a6544);
    $parcel$export(module.exports, "peopleGroupGlyph", ()=>$2e91e7edf801074a$export$a20644de4b67f161);
    $parcel$export(module.exports, "radioGlyph", ()=>$2e91e7edf801074a$export$ce87e45dceec205d);
    $parcel$export(module.exports, "shipGlyph", ()=>$2e91e7edf801074a$export$d95e2215202c8556);
    $parcel$export(module.exports, "priorityGlyph", ()=>$2e91e7edf801074a$export$3062e49c5521698d);
    $parcel$export(module.exports, "screenGlyph", ()=>$2e91e7edf801074a$export$17d48fc8b753df77);
    $parcel$export(module.exports, "closeGlyph", ()=>$2e91e7edf801074a$export$cbbe21ca62a58ed6);
    $parcel$export(module.exports, "appAccessGlyph", ()=>$2e91e7edf801074a$export$8fd991b9da3a43e5);
    $parcel$export(module.exports, "jwmCategoryGlyph", ()=>$2e91e7edf801074a$export$f21213fb7b480582);
    $parcel$export(module.exports, "customFieldGlyph", ()=>$2e91e7edf801074a$export$88453606d7da6ff7);
    var $5uXOq = parcelRequire("5uXOq");
    const $2e91e7edf801074a$export$b368272069fe93ea = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M7 4h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3zm.417 10.636h1.15V9.141H7.42L6 10.12v1.07l1.348-.93h.069v4.376zm1.923-3.85v.02h1.067v-.024c0-.506.365-.857.898-.857.503 0 .861.313.861.755 0 .357-.194.643-.967 1.397l-1.794 1.756v.803h3.964v-.955h-2.406v-.069l1.05-1.002c.953-.895 1.277-1.409 1.277-2.007 0-.944-.8-1.603-1.947-1.603-1.188 0-2.003.724-2.003 1.786zm5.811 1.466h.682c.617 0 .994.297.994.777 0 .469-.4.792-.975.792-.59 0-.983-.293-1.017-.757h-1.1c.05 1.035.88 1.713 2.106 1.713 1.253 0 2.159-.697 2.159-1.66 0-.724-.472-1.226-1.226-1.31v-.069c.613-.125 1.013-.62 1.013-1.257 0-.864-.811-1.481-1.939-1.481-1.2 0-1.972.655-2.01 1.695H14.9c.03-.476.385-.777.918-.777.537 0 .88.282.88.723 0 .45-.355.754-.876.754h-.67v.857z",
            fill: "currentColor",
            fillRule: "evenodd"
        }));
    const $2e91e7edf801074a$export$747056db840f2e62 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M4 5.994C4 4.893 4.895 4 5.994 4h12.012C19.107 4 20 4.895 20 5.994v12.012A1.995 1.995 0 0 1 18.006 20H5.994A1.995 1.995 0 0 1 4 18.006V5.994zm5.707 5.299a1 1 0 1 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 1 0-1.414-1.414L11 12.586l-1.293-1.293z",
            fill: "currentColor",
            fillRule: "evenodd"
        }));
    const $2e91e7edf801074a$export$fc939f15c0ac57d8 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M20 19V8h-9.154l-.503-1.258-.455-1.136C9.778 5.33 9.291 5 9.003 5H3.997C4.002 5 4 19 4 19h16zM12.2 6h7.809C21.109 6 22 6.893 22 7.992v11.016c0 1.1-.898 1.992-1.991 1.992H3.991C2.891 21 2 20.107 2 19.008V5.006C2 3.898 2.896 3 3.997 3h5.006c1.103 0 2.327.826 2.742 1.862L12.2 6z",
            fill: "currentColor",
            fillRule: "evenodd"
        }));
    const $2e91e7edf801074a$export$54ae6633cc16eed1 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M8.667 6.167V7A.834.834 0 0 1 7 7V5.333a.834.834 0 0 1 1.667 0v.834zm6.666 0v-.834a.834.834 0 0 1 1.667 0V7a.834.834 0 0 1-1.667 0v-.833zm2.5 0h.005c.918 0 1.662.745 1.662 1.661v10.01c0 .918-.743 1.662-1.662 1.662H6.161A1.663 1.663 0 0 1 4.5 17.838V7.828c0-.917.743-1.661 1.662-1.661h.005V7c0 .927.746 1.667 1.666 1.667C8.76 8.667 9.5 7.92 9.5 7v-.833h5V7c0 .927.746 1.667 1.667 1.667.927 0 1.666-.747 1.666-1.667v-.833zm-10 6.667H9.5v-1.667H7.833v1.667zm0 3.334H9.5V14.5H7.833v1.668zm3.334-3.334h1.666v-1.667h-1.666v1.667zm0 3.334h1.666V14.5h-1.666v1.668zm3.333-3.334h1.667v-1.667H14.5v1.667zm0 3.334h1.667V14.5H14.5v1.668z",
            fill: "currentColor",
            fillRule: "evenodd"
        }));
    const $2e91e7edf801074a$export$d298f123ec74117c = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM15.708 11.723L12.779 14.678C12.566 14.893 12.288 15 12.009 15C11.73 15 11.449 14.893 11.231 14.678L8.291 11.713C7.903 11.321 7.903 10.686 8.291 10.294C8.679 9.902 9.309 9.902 9.698 10.294L12.005 12.621L14.303 10.304C14.69 9.912 15.32 9.912 15.708 10.304C16.097 10.696 16.097 11.331 15.708 11.723Z",
            fill: "currentColor"
        }));
    const $2e91e7edf801074a$export$b3ea0fc10228ca3e = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM10.0543 7.95865C9.72121 7.63948 9.17412 7.63199 8.83238 7.94192C8.49064 8.25184 8.48365 8.76182 8.81678 9.08099L11.3658 11.5232C11.5389 11.689 11.7698 11.7707 11.9997 11.7675C12.2295 11.7707 12.4604 11.689 12.6335 11.5232L15.1825 9.08099C15.5156 8.76182 15.5087 8.25184 15.1669 7.94192C14.8252 7.63199 14.2781 7.63948 13.945 7.95865L11.9997 9.82242L10.0543 7.95865ZM8.83238 12.3333C9.17412 12.0234 9.72121 12.0309 10.0543 12.35L11.9997 14.2138L13.945 12.35C14.2781 12.0309 14.8252 12.0234 15.1669 12.3333C15.5087 12.6432 15.5156 13.1532 15.1825 13.4724L12.6335 15.9146C12.4604 16.0804 12.2295 16.1621 11.9997 16.1588C11.7698 16.1621 11.5389 16.0804 11.3658 15.9146L8.81678 13.4724C8.48365 13.1532 8.49064 12.6432 8.83238 12.3333Z",
            fill: "currentColor"
        }));
    const $2e91e7edf801074a$export$fc64eea29ed0831d = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M11.433 5.428l-4.207.6a2 2 0 0 0-1.697 1.698l-.601 4.207a1 1 0 0 0 .283.849l6.894 6.894a1 1 0 0 0 1.414 0l5.657-5.657a1 1 0 0 0 0-1.414L12.282 5.71a1 1 0 0 0-.849-.283zm-.647 5.858a1.667 1.667 0 1 1-2.357-2.357 1.667 1.667 0 0 1 2.357 2.357z",
            fill: "currentColor",
            fillRule: "evenodd"
        }));
    const $2e91e7edf801074a$export$cbee9ded46569696 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M7 7h11a1 1 0 0 1 0 2H7a1 1 0 1 1 0-2zm0 4h11a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2zm0 4h6a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2z",
            fill: "currentColor",
            fillRule: "evenodd"
        }));
    const $2e91e7edf801074a$export$2ade78849d2113ea = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M12.12 20.249a8.398 8.398 0 0 1-.39-.003A8.25 8.25 0 0 1 3.75 12 8.25 8.25 0 0 1 12 3.75 8.25 8.25 0 0 1 20.25 12a8.25 8.25 0 0 1-8.13 8.25v-.001zm4.463-3.762A6.396 6.396 0 0 0 18.417 12 6.424 6.424 0 0 0 12 5.583 6.424 6.424 0 0 0 5.583 12c0 1.745.7 3.33 1.834 4.487v-1.27a2.291 2.291 0 0 1 2.292-2.292h4.582a2.292 2.292 0 0 1 2.292 2.291v1.27zM12 12a2.75 2.75 0 1 1 0-5.5 2.75 2.75 0 0 1 0 5.5z",
            fill: "currentColor",
            fillRule: "evenodd"
        }));
    const $2e91e7edf801074a$export$1c6cf19eb34bcc14 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M6.72 7.178l-2.93 8.627a.5.5 0 0 0 .473.661h.842a.75.75 0 0 0 .716-.53l.581-1.887h3.425l.58 1.887a.75.75 0 0 0 .717.53h.916a.5.5 0 0 0 .473-.66L9.578 7.177a1 1 0 0 0-.946-.678h-.966a1 1 0 0 0-.947.678zm1.37 1.228h.047l1.25 4.082H6.841l1.25-4.082zm10.187 1.872v-.23a.986.986 0 1 1 1.972 0v5.433a.986.986 0 0 1-1.972 0v-.217h-.08c-.36.802-1.13 1.32-2.217 1.32-1.81 0-2.952-1.479-2.952-3.834 0-2.334 1.149-3.805 2.952-3.805 1.075 0 1.858.546 2.216 1.333h.08zm-.04 2.486c0-1.347-.63-2.203-1.61-2.203-.978 0-1.58.843-1.58 2.203 0 1.368.602 2.196 1.58 2.196.988 0 1.61-.836 1.61-2.196z",
            fill: "currentColor"
        }));
    const $2e91e7edf801074a$export$98a4a461fe316fce = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M12 20a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm.787-7.674V8.944c0-.52-.402-.944-.893-.944-.492 0-.894.425-.894.944v3.777c0 .263.104.5.269.672l2.207 2.332a.862.862 0 0 0 1.263 0 .985.985 0 0 0 0-1.336l-1.952-2.063z",
            fill: "currentColor",
            fillRule: "evenodd"
        }));
    const $2e91e7edf801074a$export$637d50811e2b7176 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M17.476 9.067A6.552 6.552 0 0 1 19 13.263C19 16.983 15.866 20 12 20s-7-3.016-7-6.737c0-1.587.57-3.045 1.524-4.196l-.383-.402a.82.82 0 0 1 .054-1.19.9.9 0 0 1 1.237.052l.345.363a7.116 7.116 0 0 1 3.348-1.312v-.894h-.438a.43.43 0 0 1-.437-.42V4.42a.43.43 0 0 1 .438-.42h2.624a.43.43 0 0 1 .438.421v.842a.43.43 0 0 1-.438.421h-.437v.894a7.116 7.116 0 0 1 3.348 1.312l.345-.363a.9.9 0 0 1 1.237-.052.82.82 0 0 1 .054 1.19l-.383.402zM12 18.316c2.9 0 5.25-2.262 5.25-5.053 0-2.79-2.35-5.052-5.25-5.052s-5.25 2.262-5.25 5.052c0 2.79 2.35 5.053 5.25 5.053zm.645-4.562a.9.9 0 0 1-1.237-.02.82.82 0 0 1 .02-1.19l2.588-2.412a.9.9 0 0 1 1.238.02.82.82 0 0 1-.02 1.19l-2.589 2.412z",
            fill: "currentColor"
        }));
    const $2e91e7edf801074a$export$bc2bb299bed9df91 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M12 4c-4.412 0-8 3.588-8 8s3.588 8 8 8 8-3.588 8-8-3.588-8-8-8zm0 14.222A6.23 6.23 0 015.778 12 6.23 6.23 0 0112 5.778 6.23 6.23 0 0118.222 12 6.23 6.23 0 0112 18.222zm.889-6.59V8.448c0-.489-.4-.889-.889-.889s-.889.4-.889.89v3.555c0 .248.103.471.268.633l2.195 2.194a.891.891 0 001.257 0 .891.891 0 000-1.256l-1.942-1.943z",
            fill: "currentColor",
            fillRule: "evenodd"
        }));
    const $2e91e7edf801074a$export$3a9d3f1c52f4dab9 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M12.268 4.934a.919.919 0 0 0 0 1.296l4.852 4.853H4.668A.92.92 0 0 0 3.75 12a.92.92 0 0 0 .917.917H17.12l-4.856 4.855a.917.917 0 0 0 1.296 1.296l6.416-6.415c.002 0 .003-.003.006-.004a.914.914 0 0 0 .267-.65.915.915 0 0 0-.274-.653l-6.412-6.412a.915.915 0 0 0-.648-.267.915.915 0 0 0-.648.267z",
            fill: "currentColor",
            fillRule: "evenodd"
        }));
    const $2e91e7edf801074a$export$a4cdad58220c405a = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M5.928 6.917l4.81-2.846c.727-.429 1.8-.427 2.523 0l4.811 2.846c.726.43 1.261 1.382 1.261 2.238v5.69c0 .859-.537 1.81-1.26 2.238L13.26 19.93c-.726.429-1.799.427-2.522 0l-4.811-2.846c-.726-.43-1.261-1.382-1.261-2.238v-5.69c0-.859.537-1.81 1.26-2.238zm6.408-1.204c-.152-.09-.52-.09-.672 0L6.853 8.558c-.152.09-.336.416-.336.597v5.69c0 .18.183.507.336.597l4.81 2.845c.153.09.52.09.673 0l4.811-2.845c.152-.09.336-.416.336-.597v-5.69c0-.18-.183-.507-.336-.597l-4.81-2.845zM12 15.667a3.667 3.667 0 1 1 0-7.334 3.667 3.667 0 0 1 0 7.334zm0-1.834a1.833 1.833 0 1 0 0-3.666 1.833 1.833 0 0 0 0 3.666z",
            fill: "currentColor",
            fillRule: "evenodd"
        }));
    const $2e91e7edf801074a$export$e6e85f1bc2e23bd4 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M12.672 17.468c-1.402 1.37-3.68 1.37-5.08.004a3.458 3.458 0 0 1 .002-4.962l6.552-6.402a2.123 2.123 0 0 1 2.964-.003c.818.8.82 2.092.003 2.89L10.968 15a.599.599 0 0 1-.838-.008.568.568 0 0 1 0-.827l5.71-5.58a.863.863 0 0 0 0-1.24.912.912 0 0 0-1.268 0l-5.71 5.58a2.294 2.294 0 0 0 0 3.307 2.423 2.423 0 0 0 3.375.008l6.145-6.005a3.735 3.735 0 0 0-.003-5.37 3.947 3.947 0 0 0-5.502.003L6.325 11.27a5.184 5.184 0 0 0-.001 7.442c2.1 2.052 5.514 2.051 7.617-.004l6.13-5.99a.863.863 0 0 0 0-1.24.912.912 0 0 0-1.27 0l-6.129 5.99z",
            fill: "currentColor",
            fillRule: "evenodd"
        }));
    const $2e91e7edf801074a$export$a6a7f7b7c0399a2a = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M5.5 7.59a1 1 0 0 1 1.007-1.007h.152a1 1 0 0 1 1.008 1.008v.152A1 1 0 0 1 6.659 8.75h-.152A1 1 0 0 1 5.5 7.743V7.59zm4.333 8.667a.999.999 0 0 1 .995-1.007h6.678c.549 0 .994.438.994 1.007v.152a.999.999 0 0 1-.994 1.008h-6.678a.993.993 0 0 1-.995-1.008v-.152zm-4.333 0a1 1 0 0 1 1.007-1.007h.152a1 1 0 0 1 1.008 1.007v.152a1 1 0 0 1-1.008 1.008h-.152A1 1 0 0 1 5.5 16.409v-.152zm4.333-4.333a.999.999 0 0 1 .995-1.007h6.678c.549 0 .994.438.994 1.007v.152a.999.999 0 0 1-.994 1.007h-6.678a.993.993 0 0 1-.995-1.007v-.152zm-4.333 0a1 1 0 0 1 1.007-1.007h.152a1 1 0 0 1 1.008 1.007v.152a1 1 0 0 1-1.008 1.007h-.152A1 1 0 0 1 5.5 12.076v-.152zM9.833 7.59a.999.999 0 0 1 .995-1.008h6.678c.549 0 .994.439.994 1.008v.152a.999.999 0 0 1-.994 1.007h-6.678a.993.993 0 0 1-.995-1.007V7.59z",
            fill: "currentColor",
            fillRule: "evenodd"
        }));
    const $2e91e7edf801074a$export$835d228e2a9d6a93 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M4.998 11.513c0-3.038 3.141-5.51 7.002-5.51 3.861 0 7.002 2.472 7.002 5.51 0 3.039-3.141 5.51-7.002 5.51-3.861 0-7.002-2.471-7.002-5.51zm14.84 7.771v-.002s-1.564-2.26-.767-3.116l-.037.02C20.261 14.902 21 13.279 21 11.513 21 7.371 16.963 4 12 4s-9 3.37-9 7.513 4.037 7.514 9 7.514c1.42 0 2.76-.285 3.957-.776 1.003 1.022 2.287 1.572 3.24 1.719l.002-.003a.524.524 0 0 0 .164.033.515.515 0 0 0 .474-.716zM7 10c0-.552.456-1 .995-1h8.01c.55 0 .995.444.995 1 0 .552-.456 1-.995 1h-8.01A.995.995 0 0 1 7 10zm0 3c0-.552.444-1 1-1h3c.552 0 1 .444 1 1 0 .552-.444 1-1 1H8c-.552 0-1-.444-1-1z",
            fill: "currentColor",
            fillRule: "evenodd"
        }));
    const $2e91e7edf801074a$export$fdad804cb16a6544 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M8.444 14.667V20H5.785C4.8 20 4 19.146 4 18.09V8.577c0-1.055.802-1.91 1.785-1.91h2.66v3.556H6.666V12h1.777v.889H6.667v1.778h1.777zm.89-8.894A1.78 1.78 0 0 1 11.119 4h7.096A1.78 1.78 0 0 1 20 5.773v12.454c0 .979-.802 1.773-1.785 1.773H9.333V5.773zM12 7.556v1.777h1.778V7.556H12zm0 2.666V12h1.778v-1.778H12zm0 2.667v1.778h1.778v-1.778H12zm1.778 2.667v2.666h1.778v-2.666h-1.778zm1.778-8v1.777h1.777V7.556h-1.777zm0 2.666V12h1.777v-1.778h-1.777zm0 2.667v1.778h1.777v-1.778h-1.777z",
            fill: "currentColor"
        }));
    const $2e91e7edf801074a$export$a20644de4b67f161 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M12 12a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm-3.228-1.667A3.333 3.333 0 0 0 9.795 12H9.5A2.501 2.501 0 0 0 7 14.497v2.469c-1.771-.147-3.333-.766-3.333-1.858V12c0-.92.74-1.667 1.666-1.667h3.439zm6.456 0h3.439c.92 0 1.666.738 1.666 1.667v3.108c0 1.092-1.562 1.711-3.333 1.858v-2.47A2.5 2.5 0 0 0 14.5 12h-.295a3.333 3.333 0 0 0 1.023-1.667zM10.306 6.63A3.332 3.332 0 0 0 8.67 9.357a2.5 2.5 0 1 1 1.636-2.728zm5.024 2.728a3.328 3.328 0 0 0-1.636-2.73 2.5 2.5 0 1 1 1.636 2.73zm-7.497 5.456c0-1.093.892-1.98 1.996-1.98h4.342a2 2 0 0 1 1.996 1.994v2.781c0 2.523-8.334 2.523-8.334 0v-2.795z",
            fill: "currentColor",
            fillRule: "evenodd"
        }));
    const $2e91e7edf801074a$export$ce87e45dceec205d = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M12 20a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-6a2 2 0 1 0 0-4 2 2 0 0 0 0 4z",
            fill: "currentColor",
            fillRule: "evenodd"
        }));
    const $2e91e7edf801074a$export$d95e2215202c8556 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M6.5 12h7.333v-1.833H6.5V12zM4.667 9.242c0-.502.417-.909.919-.909h9.162c.507 0 .919.414.919.909v4.591h-11V9.242zm.458 7.341h12.828l.919-2.75H4.795l.33 2.75zm-2.287-3.677A.79.79 0 0 1 3.643 12h16.86c.504 0 .786.382.625.865l-1.565 4.686c-.16.478-.695.866-1.205.866H4.415c-.506 0-.964-.402-1.025-.906l-.552-4.605zM6.5 7.42c0-1.015.823-1.838 1.831-1.838h1.838c1.011 0 1.831.82 1.831 1.838v2.746H6.5V7.42zm3.667 0v-.004H8.331c.005 0 .002.002.002.004v.912h1.834V7.42z",
            fill: "currentColor"
        }));
    const $2e91e7edf801074a$export$3062e49c5521698d = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M7.959 15.833V8.474l-2.868 2.868a.541.541 0 1 1-.766-.766l3.79-3.789.001-.004a.544.544 0 0 1 .77.003l3.791 3.792a.543.543 0 0 1 0 .766.543.543 0 0 1-.765 0l-2.87-2.87v7.36a.543.543 0 0 1-.541.541.543.543 0 0 1-.542-.542zm7-6.666v7.359l-2.868-2.868a.541.541 0 1 0-.766.766l3.79 3.789.001.004a.544.544 0 0 0 .77-.003l3.791-3.792a.543.543 0 0 0 0-.766.543.543 0 0 0-.765 0l-2.87 2.87v-7.36a.543.543 0 0 0-.541-.541.543.543 0 0 0-.542.542z",
            fill: "currentColor",
            stroke: "currentColor",
            strokeWidth: ".5",
            fillRule: "evenodd"
        }));
    const $2e91e7edf801074a$export$17d48fc8b753df77 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M4 6.772C4 5.793 4.793 5 5.773 5h12.454C19.206 5 20 5.796 20 6.772v7.044c0 .979-.793 1.772-1.773 1.772H5.773A1.774 1.774 0 0 1 4 13.816V6.772zm1.778 7.052h12.444v-7.06H5.778v7.06zm8 5.294h.452c.241 0 .437.204.437.44V20H9.333v-.441c0-.244.203-.441.437-.441h.452V16.47h3.556v2.647z",
            fill: "currentColor"
        }));
    const $2e91e7edf801074a$export$cbbe21ca62a58ed6 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M16.244 6.244a.833.833 0 1 1 1.179 1.179l-4.412 4.41 4.412 4.411a.834.834 0 0 1 .074 1.093l-.074.086a.833.833 0 0 1-1.179 0l-4.411-4.412-4.41 4.412a.834.834 0 0 1-1.094.074l-.085-.074a.833.833 0 0 1 0-1.179l4.411-4.411-4.41-4.41a.834.834 0 0 1-.076-1.094l.075-.085a.833.833 0 0 1 1.179 0l4.41 4.411z",
            fill: "currentColor"
        }));
    const $2e91e7edf801074a$export$8fd991b9da3a43e5 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            fillRule: "evenodd"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M12.682 12H5.01A2.006 2.006 0 0 0 3 14v4.446c0 2.162 4.836 2.951 8.368 2.368A5.975 5.975 0 0 1 10 17a5.994 5.994 0 0 1 2.682-5z",
            fill: "currentColor"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("circle", {
            fill: "currentColor",
            cx: "9",
            cy: "7",
            r: "4"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("circle", {
            fill: "currentColor",
            cx: "16",
            cy: "17",
            r: "5"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M14.674 19.331c.36.36.941.36 1.3 0l2.758-2.763a.92.92 0 0 0-1.301-1.298l-2.108 2.11-.755-.754a.92.92 0 0 0-1.3 1.3l1.406 1.405z",
            fill: "inherit"
        })));
    const $2e91e7edf801074a$export$f21213fb7b480582 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            focusable: false
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M6 12c0-.552.456-1 1.002-1h9.996a.999.999 0 110 2H7.002A.999.999 0 016 12zm0 4c0-.552.456-1 1.002-1h9.996a.999.999 0 110 2H7.002A.999.999 0 016 16zm0-8c0-.552.456-1 1.002-1h9.996a.999.999 0 110 2H7.002A.999.999 0 016 8z",
            fill: "currentColor"
        }));
    const $2e91e7edf801074a$export$88453606d7da6ff7 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement("svg", {
            ...props,
            width: "24",
            height: "24",
            role: "presentation",
            focusable: "false",
            viewBox: "0 0 24 24",
            xmlns: "http://www.w3.org/2000/svg"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("g", {
            fillRule: "evenodd"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("path", {
            d: "M3 6C2.44772 6 2 6.44772 2 7C2 7.55228 2.44772 8 3 8H11C11.5523 8 12 7.55228 12 7C12 6.44772 11.5523 6 11 6H3ZM4 16V12H20V16H4ZM2 12C2 10.8954 2.89543 10 4 10H20C21.1046 10 22 10.8954 22 12V16C22 17.1046 21.1046 18 20 18H4C2.89543 18 2 17.1046 2 16V12Z",
            fill: "currentColor"
        })));
});
parcelRegister("bWSqf", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$c969c7acf49869cf$export$2e2bcd8739ae039);
    var $7VHMR;
    var $c969c7acf49869cf$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        columnGroupHeader: {
            "id": "issue-table-column-picker.common.column-group-header",
            "defaultMessage": "All columns"
        },
        deprecatedParentyLinkMessage: {
            "id": "issue-navigator.services.fetch-column-picker-options.deprecated-parenty-link-message",
            "defaultMessage": "<strong>{field}</strong> is replaced by <strong>Parent</strong>. Use the <strong>Parent</strong> field instead."
        }
    });
});
parcelRegister("2F3SN", function(module, exports) {
    $parcel$export(module.exports, "getCursorOfLastUnselectedOption", ()=>$0932bf87004e47f4$export$5918fea5c056698b);
    $parcel$export(module.exports, "isOnlyUnselectableField", ()=>$0932bf87004e47f4$export$bf5bbd6344a5f1aa);
    var $hIkqU = parcelRequire("hIkqU");
    var $jG2IE;
    var $6UAdF;
    const $0932bf87004e47f4$var$isNotinSelectedList = (selectedOptions, option)=>{
        const optionFound = selectedOptions.find((select)=>(0, (0, parcelRequire("jG2IE")).isSingleValueOption)(select) && (0, (0, parcelRequire("jG2IE")).isSingleValueOption)(option) && select.cursor === option.cursor);
        return optionFound === undefined;
    };
    const $0932bf87004e47f4$export$5918fea5c056698b = (options, selectedOptions, search, endCursor)=>{
        const flatten = options.flatMap((option)=>option.optionType === 'group' ? option.options : option);
        const lastUnselectedOption = (0, ($parcel$interopDefault($hIkqU)))(flatten, (option)=>(0, (0, parcelRequire("jG2IE")).isSingleValueOption)(option) && !!option.cursor && $0932bf87004e47f4$var$isNotinSelectedList(selectedOptions, option));
        let cursor = lastUnselectedOption && (0, (0, parcelRequire("jG2IE")).isSingleValueOption)(lastUnselectedOption) ? lastUnselectedOption?.cursor : undefined;
        if (search && cursor === undefined && endCursor) cursor = endCursor;
        return cursor;
    };
    const $0932bf87004e47f4$export$bf5bbd6344a5f1aa = (fieldType, canEditColumns)=>{
        if (fieldType === (0, (0, parcelRequire("6UAdF")).COLUMN_TYPE_EPIC_LINK)) return canEditColumns;
        if (fieldType === (0, (0, parcelRequire("6UAdF")).COLUMN_TYPE_PARENT_LINK)) return canEditColumns;
        return false;
    };
});
parcelRegister("hIkqU", function(module, exports) {
    var $bptzf = parcelRequire("bptzf");
    var $apUNl = parcelRequire("apUNl");
    var $0353f9604956038c$var$findLast = $bptzf($apUNl);
    module.exports = $0353f9604956038c$var$findLast;
});
parcelRegister("bptzf", function(module, exports) {
    var $bxDef = parcelRequire("bxDef");
    var $hTJwX = parcelRequire("hTJwX");
    var $66FNL = parcelRequire("66FNL");
    function $84e8f705a13e859f$var$createFind(findIndexFunc) {
        return function(collection, predicate, fromIndex) {
            var iterable = Object(collection);
            if (!$hTJwX(collection)) {
                var iteratee = $bxDef(predicate, 3);
                collection = $66FNL(collection);
                predicate = function(key) {
                    return iteratee(iterable[key], key, iterable);
                };
            }
            var index = findIndexFunc(collection, predicate, fromIndex);
            return index > -1 ? iterable[iteratee ? collection[index] : index] : undefined;
        };
    }
    module.exports = $84e8f705a13e859f$var$createFind;
});
parcelRegister("apUNl", function(module, exports) {
    var $b5dwA = parcelRequire("b5dwA");
    var $bxDef = parcelRequire("bxDef");
    var $kPZpS = parcelRequire("kPZpS");
    var $79584b713e0c6354$var$nativeMax = Math.max, $79584b713e0c6354$var$nativeMin = Math.min;
    function $79584b713e0c6354$var$findLastIndex(array, predicate, fromIndex) {
        var length = array == null ? 0 : array.length;
        if (!length) return -1;
        var index = length - 1;
        if (fromIndex !== undefined) {
            index = $kPZpS(fromIndex);
            index = fromIndex < 0 ? $79584b713e0c6354$var$nativeMax(length + index, 0) : $79584b713e0c6354$var$nativeMin(index, length - 1);
        }
        return $b5dwA(array, $bxDef(predicate, 3), index, true);
    }
    module.exports = $79584b713e0c6354$var$findLastIndex;
});
parcelRegister("jG2IE", function(module, exports) {
    $parcel$export(module.exports, "isSingleValueOption", ()=>$4dbead67dfc4bb06$export$35faed52a6bc243e);
    const $4dbead67dfc4bb06$export$35faed52a6bc243e = (option)=>option.optionType === 'option' || option.optionType === 'avatar' || option.optionType === 'lozenge' || option.optionType === 'field';
});
parcelRegister("8BTWe", function(module, exports) {
    $parcel$export(module.exports, "ColumnPickerContainer", ()=>$1e52c1fb9f73219f$export$e1286e1cb3fa0dec);
    var $5uXOq = parcelRequire("5uXOq");
    var $ih9zY;
    const $1e52c1fb9f73219f$export$e1286e1cb3fa0dec = ({ children: children, viewIdFromResponse: viewIdFromResponse, isRestoringDefaults: isRestoringDefaults })=>{
        const [{ view: optimisticViewId }] = (0, (0, parcelRequire("ih9zY")).useSelectedViewState)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            key: isRestoringDefaults ? 'loading' : optimisticViewId + viewIdFromResponse
        }, children);
    };
});
parcelRegister("7UBlg", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$3f97be657325a468$export$2e2bcd8739ae039);
    var $bwpsq;
    var $5uXOq = parcelRequire("5uXOq");
    var $e4yMr;
    var $ousew;
    var $hZQx0;
    var $ih9zY;
    var $gwtSG;
    var $hW8cB;
    var $8HSL7;
    var $f4YBC;
    var $kgFPU;
    const $3f97be657325a468$var$ColumnPickerPopupComponent = (0, $5uXOq.forwardRef)(({ children: children, ...props }, ref)=>{
        const { filterId: filterId, isFilterEditable: isFilterEditable } = (0, (0, parcelRequire("gwtSG")).useActiveJql)();
        const [{ view: view }] = (0, (0, parcelRequire("ih9zY")).useSelectedViewState)();
        const { hasMaxSelectedColumns: hasMaxSelectedColumns, filterName: filterName } = (0, $5uXOq.useContext)((0, (0, parcelRequire("hZQx0")).ColumnPickerMessageContext));
        let bannerMessage = null;
        if (hasMaxSelectedColumns) bannerMessage = (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8HSL7")).ColumnPickerMaxSelectedColumns), null);
        else if (view === 'list_system') bannerMessage = (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hW8cB")).ColumnPickerBannerMessage), {
            bannerMessage: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ousew")).default), (0, (0, parcelRequire("kgFPU")).default).listSystemWarningMessage),
            type: "warning"
        });
        else if (view === `list_filter_${filterId}`) {
            if (isFilterEditable === false) bannerMessage = (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hW8cB")).ColumnPickerBannerMessage), {
                type: "info",
                bannerMessage: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ousew")).default), {
                    ...(0, (0, parcelRequire("kgFPU")).default).listNonEditableFilterInfoMessage,
                    values: {
                        link: (chunks)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("e4yMr")).default), {
                                href: `/jira/filters${filterName ? `?name="${encodeURIComponent(filterName)}"` : ''}`,
                                target: "_blank"
                            }, chunks)
                    }
                })
            });
            else bannerMessage = (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hW8cB")).ColumnPickerBannerMessage), {
                bannerMessage: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ousew")).default), (0, (0, parcelRequire("kgFPU")).default).listFilterWarningMessage),
                type: "warning"
            });
        }
        return (0, ($parcel$interopDefault($5uXOq))).createElement($3f97be657325a468$var$PopupComponentContainer, {
            ...props,
            ref: ref
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("f4YBC")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement($3f97be657325a468$var$TabsContentContainer, null, bannerMessage, children)));
    });
    var $3f97be657325a468$export$2e2bcd8739ae039 = $3f97be657325a468$var$ColumnPickerPopupComponent;
    const $3f97be657325a468$var$PopupComponentContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1bsb1mjv _bfhk1bhr _2rko1l7b _16qs1mxi _1pby1nn1 _ca0q1b66 _nt751r31 _49pcglyw _1hvw1o36",
                __cmplp.className
            ])
        });
    });
    const $3f97be657325a468$var$TabsContentContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _2lx21bp4 _1bsb1osq",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("hW8cB", function(module, exports) {
    $parcel$export(module.exports, "ColumnPickerBannerMessage", ()=>$cc30b6aaadae0517$export$3ccab74b576df308);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $1jWct;
    var $bU3PY;
    const $cc30b6aaadae0517$export$3ccab74b576df308 = ({ bannerMessage: bannerMessage, type: type })=>(0, ($parcel$interopDefault($5uXOq))).createElement($cc30b6aaadae0517$var$BannerContent, {
            type: type
        }, type === 'warning' ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("bU3PY"))))), {
            spacing: "spacious",
            label: "",
            color: "currentColor"
        }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("1jWct"))))), {
            spacing: "spacious",
            label: "",
            color: "currentColor"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement($cc30b6aaadae0517$var$BannerMessage, null, bannerMessage));
    const $cc30b6aaadae0517$var$BannerContent = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _1yt41uce _syazjjyb",
                __cmplp.type === 'warning' ? "_bfhk1bai" : "_bfhk1fpu",
                __cmplp.className
            ])
        });
    });
    const $cc30b6aaadae0517$var$BannerMessage = (0, $5uXOq.forwardRef)(({ as: C = "p", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_18s8171m _11c81vlj",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("1jWct", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $0f64b62884a9bedb$var$_react = $0f64b62884a9bedb$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $0f64b62884a9bedb$var$_information = $0f64b62884a9bedb$var$_interopRequireDefault((parcelRequire("2Yv61")));
    var $0f64b62884a9bedb$var$_info = $0f64b62884a9bedb$var$_interopRequireDefault((parcelRequire("2hXfb")));
    function $0f64b62884a9bedb$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $0f64b62884a9bedb$var$InformationIcon = (props)=>$0f64b62884a9bedb$var$_react.default.createElement($0f64b62884a9bedb$var$_information.default, Object.assign({
            LEGACY_fallbackIcon: $0f64b62884a9bedb$var$_info.default
        }, props));
    $0f64b62884a9bedb$var$InformationIcon.Name = 'InformationIconMigration';
    var $0f64b62884a9bedb$var$_default = module.exports.default = $0f64b62884a9bedb$var$InformationIcon;
});
parcelRegister("8HSL7", function(module, exports) {
    $parcel$export(module.exports, "ColumnPickerMaxSelectedColumns", ()=>$661e3e27622b6b54$export$3ec21851fe32329c);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $kkuz9;
    var $koVbs;
    var $czfFt;
    var $5oFIy;
    var $dyiFy;
    const $661e3e27622b6b54$export$3ec21851fe32329c = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement($661e3e27622b6b54$var$MessageContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("kkuz9"))))), {
            label: ""
        }), (0, ($parcel$interopDefault($5uXOq))).createElement($661e3e27622b6b54$var$Message, null, formatMessage((0, (0, parcelRequire("dyiFy")).default).maxSelectedColumns, {
            maxColumns: (0, (0, parcelRequire("czfFt")).MAX_COLUMNS)
        }))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireUiAnalytics), {
            actionSubject: "maxSelectedFieldsMessage",
            action: "viewed",
            actionSubjectId: "columnPicker"
        }));
    };
    const $661e3e27622b6b54$var$MessageContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _bfhk1s4m _1yt41uce _syazjjyb _19pku2gc",
                __cmplp.className
            ])
        });
    });
    const $661e3e27622b6b54$var$Message = (0, $5uXOq.forwardRef)(({ as: C = "p", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_18s8171m _11c81vlj",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("dyiFy", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$cf25dbe16eb48e62$export$2e2bcd8739ae039);
    var $7VHMR;
    var $cf25dbe16eb48e62$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        maxSelectedColumns: {
            "id": "issue-navigator.issue-results.list-view.column-picker-max-selected-columns.max-selected-columns",
            "defaultMessage": "The column limit is {maxColumns, number}. Remove and add the most important ones to your view."
        }
    });
});
parcelRegister("f4YBC", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$a4d61f43128e0eaf$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $dC5iT;
    var $fphHl;
    var $cte3m;
    var $eQ4wW;
    var $koVbs;
    var $9Nvh7;
    var $8zOmE;
    var $g5X3G;
    var $itmJb;
    var $eJ0Zs;
    var $ih9zY;
    var $gwtSG;
    var $2CHGs;
    var $k4UJN;
    const $a4d61f43128e0eaf$var$CustomTabPanel = ({ children: children })=>{
        const { tabIndex: tabIndex, ...tabPanelAttributes } = (0, (0, parcelRequire("eQ4wW")).useTabPanel)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement("div", tabPanelAttributes, children);
    };
    const $a4d61f43128e0eaf$var$ColumnPickerTabs = ({ children: children })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { filterId: filterId } = (0, (0, parcelRequire("gwtSG")).useActiveJql)();
        const isAdmin = (0, (0, parcelRequire("itmJb")).useIsAdmin)();
        const [{ view: view }, { setView: setView }] = (0, (0, parcelRequire("ih9zY")).useSelectedViewState)();
        const isFilterTabEnabled = filterId != null && (0, (0, parcelRequire("9Nvh7")).isCustomFilter)(filterId);
        const tabsMetaData = (0, $5uXOq.useMemo)(()=>{
            const resultTabs = [
                {
                    key: 'Default',
                    label: formatMessage((0, (0, parcelRequire("2CHGs")).default).pickerTabTextDefault),
                    view: 'list_default'
                }
            ];
            if (isFilterTabEnabled) {
                const filterViewId = (0, (0, parcelRequire("eJ0Zs")).convertFilterIdToIssueNavigatorId)(filterId);
                if (filterViewId != null) resultTabs.push({
                    key: 'Filter',
                    label: formatMessage((0, (0, parcelRequire("2CHGs")).default).pickerTabTextFilter),
                    view: filterViewId
                });
            }
            if (isAdmin) resultTabs.push({
                key: 'System',
                label: formatMessage((0, (0, parcelRequire("2CHGs")).default).pickerTabTextSystem),
                view: 'list_system'
            });
            return resultTabs;
        }, [
            formatMessage,
            isFilterTabEnabled,
            filterId,
            isAdmin
        ]);
        const handleUpdate = (0, $5uXOq.useCallback)((index, event)=>{
            if (view !== tabsMetaData[index].view) {
                (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(event, 'columnPickerTabs', {
                    tab: tabsMetaData[index].view
                });
                setView(tabsMetaData[index].view, (0, (0, parcelRequire("g5X3G")).toIssueKey)(''));
            }
        }, [
            setView,
            tabsMetaData,
            view
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fphHl")).default), {
            id: "issue-table-column-picker.ui.column-picker.picker-tabs",
            onChange: handleUpdate,
            selected: tabsMetaData.findIndex((tab)=>tab.view === view),
            shouldUnmountTabPanelOnChange: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            paddingInline: "space.100"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cte3m")).default), null, tabsMetaData.map((tab)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("k4UJN")).default), {
                key: tab.key,
                label: tab.label,
                maxWidth: `${Math.floor(100 / (tabsMetaData.length || 1))}%`
            })))), tabsMetaData.map((tab)=>(0, ($parcel$interopDefault($5uXOq))).createElement($a4d61f43128e0eaf$var$CustomTabPanel, {
                key: tab.key
            }, children)));
    };
    var $a4d61f43128e0eaf$export$2e2bcd8739ae039 = $a4d61f43128e0eaf$var$ColumnPickerTabs;
});
parcelRegister("fphHl", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$4810d6c8b7d5c95d$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $f8Sb3;
    var $6QvkM;
    var $dWD6q;
    var $8emiI;
    function $4810d6c8b7d5c95d$var$_EMOTION_STRINGIFIED_CSS_ERROR__() {
        return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop).";
    }
    const $4810d6c8b7d5c95d$var$baseStyles = {
        name: "7i0hsw",
        styles: "display:flex;max-width:100%;min-height:0%;flex-basis:100%;flex-direction:column;flex-grow:1"
    };
    const $4810d6c8b7d5c95d$var$tabsStyles = (0, (0, parcelRequire("8emiI")).getTabsStyles)();
    const $4810d6c8b7d5c95d$var$analyticsAttributes = {
        componentName: 'tabs',
        packageName: "platform-pkg",
        packageVersion: "0.0.0-use.local"
    };
    const $4810d6c8b7d5c95d$var$getTabPanelWithContext = ({ tabPanel: tabPanel, index: index, isSelected: isSelected, tabsId: tabsId })=>tabPanel && (0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("dWD6q")).TabPanelContext).Provider, {
            value: {
                role: 'tabpanel',
                id: `${tabsId}-${index}-tab`,
                hidden: isSelected ? undefined : true,
                'aria-labelledby': `${tabsId}-${index}`,
                tabIndex: isSelected ? 0 : -1
            },
            key: index
        }, tabPanel);
    const $4810d6c8b7d5c95d$var$Tabs = (props)=>{
        const { shouldUnmountTabPanelOnChange: shouldUnmountTabPanelOnChange = false, selected: SelectedType, defaultSelected: defaultSelected, onChange: onChangeProp, id: id, analyticsContext: analyticsContext, children: children, testId: testId } = props;
        const [selectedState, setSelected] = (0, $5uXOq.useState)(SelectedType || defaultSelected || 0);
        const selected = SelectedType === undefined ? selectedState : SelectedType;
        const childrenArray = (0, $5uXOq.Children).toArray(children).filter((child)=>Boolean(child));
        const [tabList, ...tabPanels] = childrenArray;
        const visited = (0, $5uXOq.useRef)(new Set([
            selected
        ]));
        if (!visited.current.has(selected)) visited.current.add(selected);
        const onChange = (0, $5uXOq.useCallback)((index, analyticsEvent)=>{
            if (onChangeProp) onChangeProp(index, analyticsEvent);
            setSelected(index);
        }, [
            onChangeProp
        ]);
        const onChangeAnalytics = (0, (0, parcelRequire("6QvkM")).usePlatformLeafEventHandler)({
            fn: onChange,
            action: 'clicked',
            analyticsData: analyticsContext,
            ...$4810d6c8b7d5c95d$var$analyticsAttributes
        });
        const tabPanelsWithContext = shouldUnmountTabPanelOnChange ? $4810d6c8b7d5c95d$var$getTabPanelWithContext({
            tabPanel: tabPanels[selected],
            index: selected,
            isSelected: true,
            tabsId: id
        }) : Array.from(visited.current).map((tabIndex)=>$4810d6c8b7d5c95d$var$getTabPanelWithContext({
                tabPanel: tabPanels[tabIndex],
                index: tabIndex,
                isSelected: tabIndex === selected,
                tabsId: id
            }));
        return ((0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            "data-testid": testId,
            css: [
                $4810d6c8b7d5c95d$var$baseStyles,
                $4810d6c8b7d5c95d$var$tabsStyles
            ]
        }, (0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("dWD6q")).TabListContext).Provider, {
            value: {
                selected: selected,
                onChange: onChangeAnalytics,
                tabsId: id
            }
        }, tabList), (0, (0, parcelRequire("f8Sb3")).jsx)((0, $5uXOq.Fragment), null, tabPanelsWithContext)));
    };
    var $4810d6c8b7d5c95d$export$2e2bcd8739ae039 = $4810d6c8b7d5c95d$var$Tabs;
});
parcelRegister("dWD6q", function(module, exports) {
    $parcel$export(module.exports, "TabContext", ()=>$b47499898157cfdc$export$5a5d356ca5f10a0a);
    $parcel$export(module.exports, "TabListContext", ()=>$b47499898157cfdc$export$aa6907f9fd69e93b);
    $parcel$export(module.exports, "TabPanelContext", ()=>$b47499898157cfdc$export$2054129d247c3102);
    var $5uXOq = parcelRequire("5uXOq");
    const $b47499898157cfdc$export$5a5d356ca5f10a0a = (0, $5uXOq.createContext)(null);
    const $b47499898157cfdc$export$aa6907f9fd69e93b = (0, $5uXOq.createContext)(null);
    const $b47499898157cfdc$export$2054129d247c3102 = (0, $5uXOq.createContext)(null);
});
parcelRegister("8emiI", function(module, exports) {
    $parcel$export(module.exports, "getTabsStyles", ()=>$899bec80a30728c7$export$dd5afd2190fb792c);
    $parcel$export(module.exports, "getTabListStyles", ()=>$899bec80a30728c7$export$42a3c683c377bcb2);
    $parcel$export(module.exports, "getTabListStylesOld", ()=>$899bec80a30728c7$export$f208f8acf591dbc2);
    var $f8Sb3;
    var $6VxaK;
    const $899bec80a30728c7$var$tabInlinePadding = "var(--ds-space-100, 8px)";
    const $899bec80a30728c7$var$tabBlockPadding = "var(--ds-space-075, 6px)";
    const $899bec80a30728c7$var$tabInlineMargin = "var(--ds-space-negative-100, -8px)";
    const $899bec80a30728c7$var$underlineHeight = "var(--ds-border-width-outline, 2px)";
    const $899bec80a30728c7$var$getTabPanelStyles = ()=>({
            flexGrow: 1,
            minHeight: '0%',
            display: 'flex'
        });
    const $899bec80a30728c7$export$dd5afd2190fb792c = ()=>(0, (0, parcelRequire("f8Sb3")).css)({
            '& [role="tabpanel"]': $899bec80a30728c7$var$getTabPanelStyles(),
            '& > [hidden]': {
                display: 'none'
            }
        });
    const $899bec80a30728c7$var$tabLineStyles = {
        content: '""',
        bottom: 0,
        margin: 0,
        position: 'absolute',
        width: 'inherit',
        insetInlineStart: $899bec80a30728c7$var$tabInlinePadding,
        insetInlineEnd: 0
    };
    const $899bec80a30728c7$export$42a3c683c377bcb2 = ()=>(0, (0, parcelRequire("f8Sb3")).css)({
            '& [role="tab"]': $899bec80a30728c7$export$916c8b0fccbb37d2(),
            fontWeight: "var(--ds-font-weight-medium, 500)",
            marginInlineStart: $899bec80a30728c7$var$tabInlineMargin,
            '&::before': {
                ...$899bec80a30728c7$var$tabLineStyles,
                height: "var(--ds-border-width, 1px)",
                backgroundColor: (0, (0, parcelRequire("6VxaK")).tabLineColors).lineColor
            }
        });
    const $899bec80a30728c7$export$916c8b0fccbb37d2 = ()=>{
        const colors = (0, (0, parcelRequire("6VxaK")).tabColors);
        return {
            color: colors.labelColor,
            cursor: 'pointer',
            margin: 0,
            padding: `${$899bec80a30728c7$var$tabBlockPadding} ${$899bec80a30728c7$var$tabInlinePadding}`,
            position: 'relative',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '&:hover': {
                color: colors.hoverLabelColor,
                '&::after': {
                    ...$899bec80a30728c7$var$tabLineStyles,
                    insetInlineEnd: $899bec80a30728c7$var$tabInlinePadding,
                    borderBottom: `${"var(--ds-border-width-indicator, 3px)"} solid ${(0, (0, parcelRequire("6VxaK")).tabLineColors).hoveredColor}`,
                    height: 0
                }
            },
            '&:active': {
                color: colors.activeLabelColor,
                '&::after': {
                    ...$899bec80a30728c7$var$tabLineStyles,
                    insetInlineEnd: $899bec80a30728c7$var$tabInlinePadding,
                    borderBottom: `${"var(--ds-border-width-indicator, 3px)"} solid ${(0, (0, parcelRequire("6VxaK")).tabLineColors).activeColor}`,
                    height: 0
                }
            },
            '&[aria-selected="true"]': {
                color: colors.selectedColor,
                '&::after': {
                    ...$899bec80a30728c7$var$tabLineStyles,
                    insetInlineEnd: $899bec80a30728c7$var$tabInlinePadding,
                    borderBottom: `${"var(--ds-border-width-indicator, 3px)"} solid ${(0, (0, parcelRequire("6VxaK")).tabLineColors).selectedColor}`,
                    height: 0
                }
            }
        };
    };
    const $899bec80a30728c7$var$tabLineStylesOld = {
        content: '""',
        borderRadius: "var(--ds-border-radius-050, 2px)",
        bottom: 0,
        margin: 0,
        position: 'absolute',
        width: 'inherit',
        insetInlineStart: $899bec80a30728c7$var$tabInlinePadding,
        insetInlineEnd: 0
    };
    const $899bec80a30728c7$export$f208f8acf591dbc2 = ()=>(0, (0, parcelRequire("f8Sb3")).css)({
            '& [role="tab"]': $899bec80a30728c7$export$442d081208938078(),
            fontWeight: "var(--ds-font-weight-medium, 500)",
            marginInlineStart: $899bec80a30728c7$var$tabInlineMargin,
            '&::before': {
                ...$899bec80a30728c7$var$tabLineStylesOld,
                height: $899bec80a30728c7$var$underlineHeight,
                backgroundColor: (0, (0, parcelRequire("6VxaK")).tabLineColors).lineColor
            }
        });
    const $899bec80a30728c7$export$442d081208938078 = ()=>{
        const colors = (0, (0, parcelRequire("6VxaK")).tabColors);
        return {
            color: colors.labelColor,
            cursor: 'pointer',
            margin: 0,
            padding: `${$899bec80a30728c7$var$tabBlockPadding} ${$899bec80a30728c7$var$tabInlinePadding}`,
            position: 'relative',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '&:hover': {
                color: colors.hoverLabelColor,
                '&::after': {
                    ...$899bec80a30728c7$var$tabLineStylesOld,
                    insetInlineEnd: $899bec80a30728c7$var$tabInlinePadding,
                    borderBottom: `${$899bec80a30728c7$var$underlineHeight} solid ${(0, (0, parcelRequire("6VxaK")).tabLineColors).hoveredColor}`,
                    height: 0
                }
            },
            '&:active': {
                color: colors.activeLabelColor,
                '&::after': {
                    ...$899bec80a30728c7$var$tabLineStylesOld,
                    insetInlineEnd: $899bec80a30728c7$var$tabInlinePadding,
                    borderBottom: `${$899bec80a30728c7$var$underlineHeight} solid ${(0, (0, parcelRequire("6VxaK")).tabLineColors).activeColor}`,
                    height: 0
                }
            },
            '&[aria-selected="true"]': {
                color: colors.selectedColor,
                '&::after': {
                    ...$899bec80a30728c7$var$tabLineStylesOld,
                    insetInlineEnd: $899bec80a30728c7$var$tabInlinePadding,
                    borderBottom: `${$899bec80a30728c7$var$underlineHeight} solid ${(0, (0, parcelRequire("6VxaK")).tabLineColors).selectedColor}`,
                    height: 0
                }
            }
        };
    };
});
parcelRegister("6VxaK", function(module, exports) {
    $parcel$export(module.exports, "tabColors", ()=>$874e3836572be4cc$export$5159a1ad8efe50bc);
    $parcel$export(module.exports, "tabLineColors", ()=>$874e3836572be4cc$export$6be6fa4649b31472);
    const $874e3836572be4cc$export$5159a1ad8efe50bc = {
        labelColor: `var(--ds-text-subtle, ${(0, $829f609a65d26a98$export$c39c0fe0f26207ae)})`,
        activeLabelColor: `var(--ds-text, ${(0, $829f609a65d26a98$export$a6ee3d71bc067634)})`,
        hoverLabelColor: `var(--ds-text-subtle, ${(0, $829f609a65d26a98$export$b3de0ae08da2ce64)})`,
        selectedColor: `var(--ds-text-selected, ${(0, $829f609a65d26a98$export$b3de0ae08da2ce64)})`,
        focusBorderColor: `var(--ds-border-focused, ${(0, $829f609a65d26a98$export$ecfc1dff386a3e7a)})`
    };
    const $874e3836572be4cc$export$6be6fa4649b31472 = {
        lineColor: `var(--ds-border, ${(0, $829f609a65d26a98$export$3e51e8f1440314ba)})`,
        hoveredColor: "var(--ds-border, transparent)",
        activeColor: "var(--ds-border, transparent)",
        selectedColor: `var(--ds-border-selected, ${(0, $829f609a65d26a98$export$b3de0ae08da2ce64)})`
    };
});
parcelRegister("cte3m", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$e15f9538b451f314$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $f8Sb3;
    var $VSDnB;
    var $eQ4wW;
    var $dWD6q;
    var $8emiI;
    function $e15f9538b451f314$var$_EMOTION_STRINGIFIED_CSS_ERROR__() {
        return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop).";
    }
    const $e15f9538b451f314$var$baseStyles = {
        name: "g0wsdz",
        styles: "display:flex;padding:var(--ds-space-0, 0px);position:relative"
    };
    const $e15f9538b451f314$var$tabListStyles = (0, (0, parcelRequire("8emiI")).getTabListStyles)();
    const $e15f9538b451f314$var$tabListStylesOld = (0, (0, parcelRequire("8emiI")).getTabListStylesOld)();
    const $e15f9538b451f314$var$TabList = ({ children: children })=>{
        const { tabsId: tabsId, selected: selected, onChange: onChange } = (0, (0, parcelRequire("eQ4wW")).useTabList)();
        const ref = (0, $5uXOq.createRef)();
        const childrenArray = (0, $5uXOq.Children).toArray(children).filter(Boolean);
        const length = childrenArray.length;
        const selectTabByIndex = (0, $5uXOq.useCallback)((index)=>{
            const newSelectedNode = ref.current?.querySelector(`[id='${tabsId}-${index}']`);
            if (newSelectedNode) newSelectedNode.focus();
            onChange(index);
        }, [
            tabsId,
            ref,
            onChange
        ]);
        const onKeyDown = (0, $5uXOq.useCallback)((e)=>{
            if (![
                'ArrowRight',
                'ArrowLeft',
                'Home',
                'End'
            ].includes(e.key)) return;
            e.preventDefault();
            const lastTabIndex = length - 1;
            if ([
                'Home',
                'End'
            ].includes(e.key)) {
                const newSelected = e.key === 'Home' ? 0 : lastTabIndex;
                selectTabByIndex(newSelected);
                return;
            }
            const selectedIndex = parseInt(e.currentTarget.getAttribute('aria-posinset') || '0') - 1;
            const modifier = e.key === 'ArrowRight' ? 1 : -1;
            let newSelected = selectedIndex + modifier;
            if (newSelected < 0 || newSelected >= length) newSelected = newSelected < 0 ? lastTabIndex : 0;
            selectTabByIndex(newSelected);
        }, [
            length,
            selectTabByIndex
        ]);
        const getTabWithContext = (0, $5uXOq.useCallback)(({ tab: tab, isSelected: isSelected, index: index })=>(0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("dWD6q")).TabContext).Provider, {
                value: {
                    onClick: ()=>onChange(index),
                    onKeyDown: onKeyDown,
                    'aria-setsize': length,
                    role: 'tab',
                    id: `${tabsId}-${index}`,
                    'aria-posinset': index + 1,
                    'aria-selected': isSelected,
                    'aria-controls': `${tabsId}-${index}-tab`,
                    tabIndex: isSelected ? 0 : -1
                },
                key: index
            }, tab), [
            length,
            onKeyDown,
            onChange,
            tabsId
        ]);
        return ((0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            role: "tablist",
            ref: ref,
            css: [
                $e15f9538b451f314$var$baseStyles,
                (0, (0, parcelRequire("VSDnB")).fg)('platform-component-visual-refresh') ? $e15f9538b451f314$var$tabListStyles : $e15f9538b451f314$var$tabListStylesOld
            ]
        }, childrenArray.map((child, index)=>getTabWithContext({
                tab: child,
                index: index,
                isSelected: index === selected
            }))));
    };
    var $e15f9538b451f314$export$2e2bcd8739ae039 = $e15f9538b451f314$var$TabList;
});
parcelRegister("eQ4wW", function(module, exports) {
    $parcel$export(module.exports, "useTab", ()=>$4d8616502e4de6b0$export$fdf4756d5b8ef90a);
    $parcel$export(module.exports, "useTabList", ()=>$4d8616502e4de6b0$export$773e389e644c5874);
    $parcel$export(module.exports, "useTabPanel", ()=>$4d8616502e4de6b0$export$fae0121b5afe572d);
    var $5uXOq = parcelRequire("5uXOq");
    var $dWD6q;
    const $4d8616502e4de6b0$export$fdf4756d5b8ef90a = ()=>{
        const tabData = (0, $5uXOq.useContext)((0, (0, parcelRequire("dWD6q")).TabContext));
        if (tabData == null || typeof tabData === 'undefined') throw Error('@atlaskit/tabs: A Tab must have a TabList parent.');
        return tabData;
    };
    const $4d8616502e4de6b0$export$773e389e644c5874 = ()=>{
        const tabListData = (0, $5uXOq.useContext)((0, (0, parcelRequire("dWD6q")).TabListContext));
        if (tabListData === null || typeof tabListData === 'undefined') throw Error('@atlaskit/tabs: A TabList must have a Tabs parent.');
        return tabListData;
    };
    const $4d8616502e4de6b0$export$fae0121b5afe572d = ()=>{
        const tabPanelData = (0, $5uXOq.useContext)((0, (0, parcelRequire("dWD6q")).TabPanelContext));
        if (tabPanelData === null || typeof tabPanelData === 'undefined') throw Error('@atlaskit/tabs:  A TabPanel must have a Tabs parent.');
        return tabPanelData;
    };
});
parcelRegister("2CHGs", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$824f5fbceb95a5ee$export$2e2bcd8739ae039);
    var $7VHMR;
    var $824f5fbceb95a5ee$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        pickerTabTextDefault: {
            "id": "issue-navigator.issue-results.list-view.column-picker-popup-component.column-picker-tabs.picker-tab-text-default",
            "defaultMessage": "My defaults"
        },
        pickerTabTextFilter: {
            "id": "issue-navigator.issue-results.list-view.column-picker-popup-component.column-picker-tabs.picker-tab-text-filter",
            "defaultMessage": "Filter"
        },
        pickerTabTextSystem: {
            "id": "issue-navigator.issue-results.list-view.column-picker-popup-component.column-picker-tabs.picker-tab-text-system",
            "defaultMessage": "System"
        }
    });
});
parcelRegister("k4UJN", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$51a2efbb5ab69baf$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $lD23V;
    var $dC5iT;
    var $8CUq3;
    var $eQ4wW;
    var $fKpEJ;
    const $51a2efbb5ab69baf$var$Tab = ({ label: label, maxWidth: maxWidth = '100%' })=>{
        const [labelOverflows, setLabelOverflows] = (0, $5uXOq.useState)(false);
        const tabAttributes = (0, (0, parcelRequire("eQ4wW")).useTab)();
        const onLabelRef = (0, $5uXOq.useCallback)((node)=>{
            if (node != null) {
                const isOverflow = Boolean(node.offsetWidth < node.scrollWidth);
                isOverflow !== labelOverflows && setLabelOverflows(isOverflow);
            }
        }, [
            labelOverflows
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($51a2efbb5ab69baf$var$TabStyled, {
            maxWidthValue: maxWidth,
            ...tabAttributes
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fKpEJ")).ConditionalTooltip), {
            condition: labelOverflows,
            content: label,
            position: "top"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            ref: onLabelRef,
            xcss: $51a2efbb5ab69baf$var$textStyles
        }, label)));
    };
    const $51a2efbb5ab69baf$var$textStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    });
    const $51a2efbb5ab69baf$var$TabStyled = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { maxWidthValue: maxWidthValue, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: {
                ...__cmpls,
                "--_1ocvhpw": (0, (0, parcelRequire("lD23V")).default)(__cmplp.maxWidthValue)
            },
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_p12fd0nw _vchhusvi",
                __cmplp.className
            ])
        });
    });
    var $51a2efbb5ab69baf$export$2e2bcd8739ae039 = $51a2efbb5ab69baf$var$Tab;
});
parcelRegister("fKpEJ", function(module, exports) {
    $parcel$export(module.exports, "ConditionalTooltip", ()=>$68dbfbed07ea6c84$export$d8abb7a1cfbd4b6b);
    var $5uXOq = parcelRequire("5uXOq");
    var $3bDjY;
    const $68dbfbed07ea6c84$export$d8abb7a1cfbd4b6b = ({ condition: condition, children: children, ...rest })=>condition ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), rest, children) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children);
});
parcelRegister("kgFPU", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$77988429b36d0c09$export$2e2bcd8739ae039);
    var $7VHMR;
    var $77988429b36d0c09$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        listSystemWarningMessage: {
            "id": "issue-navigator.issue-results.list-view.column-picker-popup-component.list-system-warning-message",
            "defaultMessage": "Any changes here will modify columns for all users."
        },
        listFilterWarningMessage: {
            "id": "issue-navigator.issue-results.list-view.column-picker-popup-component.list-filter-warning-message",
            "defaultMessage": "Any changes here will affect all users of this filter."
        },
        listNonEditableFilterInfoMessage: {
            "id": "issue-navigator.issue-results.list-view.column-picker-popup-component.list-non-editable-filter-info-message",
            "defaultMessage": "You can\u2019t modify this filter. Contact the owner using the <link>Filters directory</link> for edit permission."
        }
    });
});
parcelRegister("2lRxk", function(module, exports) {
    $parcel$export(module.exports, "useFooterOverride", ()=>$67350735789792d8$export$d8b42fa25d3fad8c);
    $parcel$export(module.exports, "Footer", ()=>$67350735789792d8$export$a06f1c675e846f6f);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $9DYf6;
    var $cm6vO;
    var $isIOR;
    const $67350735789792d8$export$d8b42fa25d3fad8c = (isFeedbackButtonDisabled)=>{
        const { isStalePage: isStalePage } = (0, (0, parcelRequire("isIOR")).useIssueSearchQuery)();
        return (0, $5uXOq.useMemo)(()=>({ end: end, center: center, ...props })=>{
                let start;
                if (!isFeedbackButtonDisabled) start = (0, ($parcel$interopDefault($5uXOq))).createElement($67350735789792d8$var$FeedbackContainer, {
                    "data-testid": "issue-navigator.ui.issue-results.list-view.footer.feedback-wrapper"
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cm6vO")).default), null));
                return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9DYf6")).components).Footer, {
                    ...props,
                    start: start,
                    center: isStalePage && props.isNewFooterOrderEnabled ? undefined : center,
                    end: isStalePage && !props.isNewFooterOrderEnabled ? undefined : end
                });
            }, [
            isFeedbackButtonDisabled,
            isStalePage
        ]);
    };
    const $67350735789792d8$export$a06f1c675e846f6f = ({ end: end, center: center, ...props })=>{
        const { isStalePage: isStalePage } = (0, (0, parcelRequire("isIOR")).useIssueSearchQuery)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9DYf6")).components).Footer, {
            ...props,
            center: isStalePage && props.isNewFooterOrderEnabled ? undefined : center,
            end: isStalePage && !props.isNewFooterOrderEnabled ? undefined : end
        });
    };
    const $67350735789792d8$var$FeedbackContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _16jlkb7n _1o9zkb7n _i0dlf1ug",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("9DYf6", function(module, exports) {
    $parcel$export(module.exports, "components", ()=>$b2da35897bea1cb9$export$9b5ac10f59f76296);
    var $eUiAo;
    var $35sx5;
    var $56vto;
    const $b2da35897bea1cb9$export$9b5ac10f59f76296 = {
        columnPicker: (0, parcelRequire("eUiAo")).components,
        Footer: (0, parcelRequire("35sx5")).Footer,
        NoColumns: (0, parcelRequire("56vto")).NoColumns
    };
});
parcelRegister("eUiAo", function(module, exports) {
    $parcel$export(module.exports, "components", ()=>$63a1b7bd09a72394$export$9b5ac10f59f76296);
    var $50z6e;
    const $63a1b7bd09a72394$export$9b5ac10f59f76296 = {
        NoOptionsMessage: (0, parcelRequire("50z6e")).default
    };
});
parcelRegister("50z6e", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$1a81df9ef986c35d$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $dD7Zc;
    var $luBm6;
    var $cgL5K;
    var $8CUq3;
    var $koVbs;
    var $5oFIy;
    var $dCm1o;
    var $7krAo;
    const $1a81df9ef986c35d$var$IconContainerConditional = ({ children: children })=>{
        if ((0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)()) return children;
        return (0, ($parcel$interopDefault($5uXOq))).createElement($1a81df9ef986c35d$var$IconContainer, null, children);
    };
    const $1a81df9ef986c35d$var$MessageContainer = ({ icon: icon, children: children })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        if ((0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)()) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("luBm6")).default), {
            space: "space.150",
            xcss: $1a81df9ef986c35d$var$stackStyles,
            alignInline: "center"
        }, icon, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
            color: "color.text"
        }, formatMessage((0, (0, parcelRequire("7krAo")).default).noOptionsPrimaryMessage)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
            color: "color.text.subtle",
            size: "small"
        }, formatMessage((0, (0, parcelRequire("7krAo")).default).noOptionsSecondaryMessage)), children);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($1a81df9ef986c35d$var$Container, null, icon, (0, ($parcel$interopDefault($5uXOq))).createElement($1a81df9ef986c35d$var$StyledTitle, null, formatMessage((0, (0, parcelRequire("7krAo")).default).title)), children);
    };
    const $1a81df9ef986c35d$var$NoOptionsMessage = ({ children: children })=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement($1a81df9ef986c35d$var$MessageContainer, {
            icon: (0, ($parcel$interopDefault($5uXOq))).createElement($1a81df9ef986c35d$var$IconContainerConditional, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("dD7Zc"))))), {
                label: "",
                color: "var(--ds-icon, #44546F)",
                LEGACY_primaryColor: "currentColor"
            }))
        }, children, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireUiAnalytics), {
            actionSubject: "emptyState",
            action: "viewed",
            actionSubjectId: "columnPicker"
        }));
    };
    var $1a81df9ef986c35d$export$2e2bcd8739ae039 = $1a81df9ef986c35d$var$NoOptionsMessage;
    const $1a81df9ef986c35d$var$stackStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        paddingBlock: 'space.150',
        paddingInline: 'space.100',
        color: 'color.text.subtlest'
    });
    const $1a81df9ef986c35d$var$Container = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_syaz1wmz _1e0c1txw _2lx21bp4 _4cvr1h6o _18s81gzt",
                __cmplp.className
            ])
        });
    });
    const $1a81df9ef986c35d$var$IconContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_otyru2gc _w8l5t9kd _1752t9kd",
                __cmplp.className
            ])
        });
    });
    const $1a81df9ef986c35d$var$StyledTitle = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_syaz1fxt _otyru2gc",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("7krAo", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$51300b676abd2848$export$2e2bcd8739ae039);
    var $7VHMR;
    var $51300b676abd2848$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        title: {
            "id": "issue-table-column-picker.column-picker.no-options-message.title",
            "defaultMessage": "We couldn\u2019t find the field you\u2019re looking for."
        },
        noOptionsPrimaryMessage: {
            "id": "issue-table-column-picker.common.no-options-message.no-options-primary-message",
            "defaultMessage": "We couldn't find what you're looking for"
        },
        noOptionsSecondaryMessage: {
            "id": "issue-table-column-picker.common.no-options-message.no-options-secondary-message",
            "defaultMessage": "Try using different search terms."
        }
    });
});
parcelRegister("35sx5", function(module, exports) {
    $parcel$export(module.exports, "Footer", ()=>$9ac639c2cf475c33$export$a06f1c675e846f6f);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $cSD7y;
    var $9Ik53;
    var $kYFm9;
    var $9CFBJ;
    var $Y2c0n;
    const $9ac639c2cf475c33$var$MaybeComputedFooter = (0, (0, parcelRequire("cSD7y")).componentWithFG)('jira_spreadsheet_component_m1_api_updates', $9ac639c2cf475c33$var$FooterNew, $9ac639c2cf475c33$var$FooterOld);
    const $9ac639c2cf475c33$export$a06f1c675e846f6f = ({ start: start = $9ac639c2cf475c33$var$SpacerElement, center: center = $9ac639c2cf475c33$var$SpacerElement, end: end = $9ac639c2cf475c33$var$SpacerElement })=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement($9ac639c2cf475c33$var$MaybeComputedFooter, null, start, center, end);
    };
    function $9ac639c2cf475c33$var$FooterNew({ children: children }) {
        const tableHeight = (0, (0, parcelRequire("kYFm9")).useElementSizeSelector)((size)=>size.height);
        const hasShadow = (0, (0, parcelRequire("9CFBJ")).useScrollStateSelector)((scrollState)=>{
            const { height: containerHeight, scrollTop: scrollTop } = scrollState;
            return containerHeight < tableHeight && Math.round(scrollTop) < tableHeight - containerHeight;
        });
        return (0, ($parcel$interopDefault($5uXOq))).createElement($9ac639c2cf475c33$var$FooterBase, {
            hasShadow: hasShadow
        }, children);
    }
    function $9ac639c2cf475c33$var$FooterOld({ children: children }) {
        const { height: tableHeight } = (0, (0, parcelRequire("Y2c0n")).useTableSize)();
        const { height: containerHeight, scrollTop: scrollTop } = (0, (0, parcelRequire("9Ik53")).useScrollState)();
        const hasShadow = containerHeight < tableHeight && Math.round(scrollTop) < tableHeight - containerHeight;
        return (0, ($parcel$interopDefault($5uXOq))).createElement($9ac639c2cf475c33$var$FooterBase, {
            hasShadow: hasShadow
        }, children);
    }
    function $9ac639c2cf475c33$var$FooterBase({ children: children, hasShadow: hasShadow }) {
        return (0, ($parcel$interopDefault($5uXOq))).createElement($9ac639c2cf475c33$var$FooterContainer, {
            "data-testid": "native-issue-table.common.ui.components.footer.footer-container",
            hasShadow: hasShadow
        }, children);
    }
    const $9ac639c2cf475c33$var$FooterContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { hasShadow: hasShadow, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _2lx2vrvc _1n261q9c _kqsw1if8 _1ltvidpf _94n5idpf _bfhkvuon _1yt41b66 _x3doi319",
                __cmplp.hasShadow && "_16qsahas",
                __cmplp.className
            ])
        });
    });
    const $9ac639c2cf475c33$export$d7a54cba636a8cd6 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _16jlkb7n _1o9zkb7n _i0dlf1ug",
                __cmplp.className
            ])
        });
    });
    const $9ac639c2cf475c33$var$SpacerElement = (0, ($parcel$interopDefault($5uXOq))).createElement($9ac639c2cf475c33$export$d7a54cba636a8cd6, null);
});
parcelRegister("9Ik53", function(module, exports) {
    $parcel$export(module.exports, "useScrollState", ()=>$c77424cc0be42400$export$98084ad349e2edae);
    $parcel$export(module.exports, "ScrollStateContextProvider", ()=>$c77424cc0be42400$export$7cc0055278d35041);
    var $5uXOq = parcelRequire("5uXOq");
    const $c77424cc0be42400$export$50b6b7783d156e1b = 200;
    const $c77424cc0be42400$var$INITIAL_SCROLL_STATE = {
        height: 0,
        width: 0,
        scrollHeight: 0,
        scrollWidth: 0,
        scrollTop: 0,
        scrollLeft: 0,
        isScrolling: false,
        scrollElement: null
    };
    const $c77424cc0be42400$var$ScrollStateContext = (0, $5uXOq.createContext)($c77424cc0be42400$var$INITIAL_SCROLL_STATE);
    const $c77424cc0be42400$export$98084ad349e2edae = ()=>(0, $5uXOq.useContext)($c77424cc0be42400$var$ScrollStateContext);
    const $c77424cc0be42400$var$attachResizeObserver = (callback, targetEl)=>{
        if (!targetEl) return ()=>{};
        let resizeObserver = null;
        if (typeof ResizeObserver !== 'undefined') resizeObserver = new ResizeObserver(()=>callback());
        if (targetEl instanceof Window) targetEl.addEventListener('resize', callback);
        else resizeObserver?.observe(targetEl);
        return ()=>{
            if (targetEl instanceof Window) targetEl.removeEventListener('resize', callback);
            else resizeObserver?.disconnect();
        };
    };
    const $c77424cc0be42400$var$useElementScrollState = (scrollRef)=>{
        const [scrollState, setScrollState] = (0, $5uXOq.useState)($c77424cc0be42400$var$INITIAL_SCROLL_STATE);
        (0, $5uXOq.useEffect)(()=>{
            const targetEl = scrollRef.current;
            if (!targetEl) return undefined;
            let clearScrollingTimeoutId;
            const onScroll = ()=>{
                setScrollState((state)=>({
                        ...state,
                        scrollTop: targetEl.scrollTop,
                        scrollLeft: targetEl.scrollLeft,
                        isScrolling: true
                    }));
                clearTimeout(clearScrollingTimeoutId);
                clearScrollingTimeoutId = setTimeout(()=>{
                    setScrollState((state)=>({
                            ...state,
                            scrollTop: targetEl.scrollTop,
                            scrollLeft: targetEl.scrollLeft,
                            isScrolling: false
                        }));
                }, $c77424cc0be42400$export$50b6b7783d156e1b);
            };
            const onResize = ()=>{
                setScrollState((state)=>({
                        ...state,
                        scrollTop: targetEl.scrollTop,
                        scrollLeft: targetEl.scrollLeft,
                        height: targetEl.clientHeight,
                        width: targetEl.clientWidth,
                        scrollHeight: targetEl.scrollHeight,
                        scrollWidth: targetEl.scrollWidth,
                        isScrolling: true
                    }));
                clearTimeout(clearScrollingTimeoutId);
                clearScrollingTimeoutId = setTimeout(()=>{
                    setScrollState((state)=>({
                            ...state,
                            scrollTop: targetEl.scrollTop,
                            scrollLeft: targetEl.scrollLeft,
                            isScrolling: false
                        }));
                }, $c77424cc0be42400$export$50b6b7783d156e1b);
            };
            const cleanupResizeObserver = $c77424cc0be42400$var$attachResizeObserver(onResize, targetEl);
            targetEl.addEventListener('scroll', onScroll);
            setScrollState({
                height: targetEl.clientHeight,
                width: targetEl.clientWidth,
                scrollHeight: targetEl.scrollHeight,
                scrollWidth: targetEl.scrollWidth,
                scrollTop: targetEl.scrollTop,
                scrollLeft: targetEl.scrollLeft,
                isScrolling: false,
                scrollElement: targetEl
            });
            return ()=>{
                targetEl.removeEventListener('scroll', onScroll);
                cleanupResizeObserver();
                clearTimeout(clearScrollingTimeoutId);
            };
        }, [
            scrollRef
        ]);
        return scrollState;
    };
    const $c77424cc0be42400$export$7cc0055278d35041 = ({ scrollRef: scrollRef, children: children })=>{
        const scrollState = $c77424cc0be42400$var$useElementScrollState(scrollRef);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($c77424cc0be42400$var$ScrollStateContext.Provider, {
            value: scrollState
        }, children);
    };
});
parcelRegister("Y2c0n", function(module, exports) {
    $parcel$export(module.exports, "TableSizeProvider", ()=>$ac40cd84f8ce0bc5$export$d5a5e307b6cc4da8);
    $parcel$export(module.exports, "useTableSize", ()=>$ac40cd84f8ce0bc5$export$95b939e430a260c);
    var $5uXOq = parcelRequire("5uXOq");
    var $kYFm9;
    const $ac40cd84f8ce0bc5$var$TableSizeContext = (0, $5uXOq.createContext)((0, (0, parcelRequire("kYFm9")).DEFAULT_SIZE));
    const $ac40cd84f8ce0bc5$export$d5a5e307b6cc4da8 = ({ children: children, tableRef: tableRef })=>{
        const tableSize = (0, (0, parcelRequire("kYFm9")).useElementSize)(tableRef);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($ac40cd84f8ce0bc5$var$TableSizeContext.Provider, {
            value: tableSize
        }, children);
    };
    const $ac40cd84f8ce0bc5$export$95b939e430a260c = ()=>(0, $5uXOq.useContext)($ac40cd84f8ce0bc5$var$TableSizeContext);
});
parcelRegister("56vto", function(module, exports) {
    $parcel$export(module.exports, "NoColumns", ()=>$1e9e65187073fe12$export$a0e9f6d02eee4aa4);
    var $5uXOq = parcelRequire("5uXOq");
    var $fVnbM;
    var $hTEpF;
    var $dC5iT;
    var $koVbs;
    var $bHZac;
    var $8zOmE;
    var $9yV2W;
    const $1e9e65187073fe12$export$a0e9f6d02eee4aa4 = ({ defaultNoColumnsGlyph: defaultNoColumnsGlyph, hasColumnConfiguration: hasColumnConfiguration })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const [{ isOpen: isOpen }, actions] = (0, (0, parcelRequire("bHZac")).useColumnPickerOpenState)();
        const onOpenColumnPicker = (0, $5uXOq.useCallback)((event, analyticsEvent)=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'button clicked', 'noColumnsButton');
            if (!isOpen) actions.open();
        }, [
            isOpen,
            actions
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            paddingBlock: "space.800"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hTEpF")).default), {
            header: formatMessage((0, (0, parcelRequire("9yV2W")).default).noColumnsHeaderText),
            description: formatMessage((0, (0, parcelRequire("9yV2W")).default).noColumnsDescriptionText),
            testId: "native-issue-table.common.ui.components.no-columns.no-columns",
            primaryAction: hasColumnConfiguration ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                appearance: "primary",
                onClick: onOpenColumnPicker
            }, formatMessage((0, (0, parcelRequire("9yV2W")).default).configureColumns)) : undefined,
            renderImage: ()=>defaultNoColumnsGlyph
        }));
    };
});
parcelRegister("bHZac", function(module, exports) {
    $parcel$export(module.exports, "ColumnPickerContainer", ()=>$cceed226e735615f$export$e1286e1cb3fa0dec);
    $parcel$export(module.exports, "useColumnPickerOpenState", ()=>$cceed226e735615f$export$81588ffe8d9d4add);
    var $gg7kZ;
    var $3sQ5x;
    var $iXqE5;
    const $cceed226e735615f$export$e1286e1cb3fa0dec = (0, (0, parcelRequire("gg7kZ")).createContainer)();
    const $cceed226e735615f$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: {
            isOpen: false
        },
        actions: {
            open: ()=>({ setState: setState })=>{
                    setState({
                        isOpen: true
                    });
                },
            close: ()=>({ setState: setState })=>{
                    setState({
                        isOpen: false
                    });
                }
        },
        name: 'ColumnPickerOpenState',
        containedBy: $cceed226e735615f$export$e1286e1cb3fa0dec
    });
    const $cceed226e735615f$export$81588ffe8d9d4add = (0, (0, parcelRequire("3sQ5x")).createHook)($cceed226e735615f$var$Store);
});
parcelRegister("9yV2W", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$cb37f0f12ee9177e$export$2e2bcd8739ae039);
    var $7VHMR;
    var $cb37f0f12ee9177e$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        noColumnsHeaderText: {
            "id": "native-issue-table.issue-table.no-columns.no-columns-header-text",
            "defaultMessage": "Configure your columns"
        },
        noColumnsDescriptionText: {
            "id": "native-issue-table.issue-table.no-columns.no-columns-description-text",
            "defaultMessage": "You haven't configured any columns yet. Add fields as columns to see search results and their information."
        },
        configureColumns: {
            "id": "native-issue-table.issue-table.no-columns.configure-columns",
            "defaultMessage": "Configure columns"
        }
    });
});
parcelRegister("2hlL8", function(module, exports) {
    $parcel$export(module.exports, "IssueCount", ()=>$987d8685128f2230$export$542fba90cc9c1bd1);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $dC5iT;
    var $8CUq3;
    var $6s1PB;
    var $4R6GH;
    var $dCm1o;
    var $cyCoT;
    var $eLt1F;
    var $isIOR;
    const $987d8685128f2230$export$542fba90cc9c1bd1 = (issueCountProps)=>{
        let isStalePage = false;
        if (!(0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) ({ isStalePage: isStalePage } = (0, (0, parcelRequire("isIOR")).useIssueSearchQuery)());
        return (0, ($parcel$interopDefault($5uXOq))).createElement($987d8685128f2230$var$IssueCountContainer, {
            "data-testid": "issue-navigator.ui.issue-results.list-view.issue-count",
            isFlexEnd: issueCountProps.isNewFooterOrderEnabled
        }, !isStalePage && ((0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() && (0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_beta_drop_1') ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            as: "span",
            testId: "issue-navigator.ui.issue-results.list-view.issue-count.text",
            xcss: $987d8685128f2230$var$textStyles
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cyCoT")).default), issueCountProps)) : (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "data-testid": "issue-navigator.ui.issue-results.list-view.issue-count.text"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cyCoT")).default), issueCountProps))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eLt1F")).default), null));
    };
    const $987d8685128f2230$var$IssueCountContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isFlexEnd: isFlexEnd, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _16jlkb7n _1o9zkb7n _i0dlf1ug _4cvr1h6o _k48pni7l _zulp1b66",
                __cmplp.isFlexEnd ? "_1bahesu3" : "_1bah1h6o",
                __cmplp.className
            ])
        });
    });
    const $987d8685128f2230$var$textStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        fontWeight: 'font.weight.medium',
        color: 'color.text.subtle'
    });
});
parcelRegister("df5k9", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9512add73ee5293a$export$2e2bcd8739ae039);
    var $7VHMR;
    var $9512add73ee5293a$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        reorderColumnsErrorTitle: {
            "id": "issue-navigator.issue-results.list-view.reorder-columns-error-title",
            "defaultMessage": "We couldn't reorder your columns"
        },
        resetColumnsErrorTitle: {
            "id": "issue-navigator.issue-results.list-view.reset-columns-error-title",
            "defaultMessage": "We couldn't restore your default columns"
        },
        columnConfigMutationErrorDescription: {
            "id": "issue-navigator.issue-results.list-view.column-config-mutation-error-description",
            "defaultMessage": "Refresh and try again."
        }
    });
});
parcelRegister("cKSn6", function(module, exports) {
    $parcel$export(module.exports, "NoColumns", ()=>$21675d57ff4dba29$export$a0e9f6d02eee4aa4);
    var $5uXOq = parcelRequire("5uXOq");
    var $fVnbM;
    var $hTEpF;
    var $dC5iT;
    var $6s1PB;
    var $8Q4sF;
    var $koVbs;
    var $9DYf6;
    var $8zOmE;
    var $g5X3G;
    var $cZpsT;
    var $ih9zY;
    var $1oKq6;
    const $21675d57ff4dba29$var$Bold = ({ children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement("b", null, children);
    const $21675d57ff4dba29$var$NoColumnsAnonymous = ({ defaultNoColumnsGlyph: defaultNoColumnsGlyph })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const [, { setView: setView }] = (0, (0, parcelRequire("ih9zY")).useSelectedViewState)();
        const onClick = (0, $5uXOq.useCallback)((event, analyticsEvent)=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'button clicked', 'noColumnsButton', {
                isAnon: true
            });
            setView('detail', (0, (0, parcelRequire("g5X3G")).toIssueKey)(''));
        }, [
            setView
        ]);
        const component = (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hTEpF")).default), {
            header: formatMessage((0, (0, parcelRequire("1oKq6")).default).noColumnsHeaderAnonymous),
            description: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8Q4sF")).FormattedI18nMessage), {
                componentsMapping: {
                    bold: $21675d57ff4dba29$var$Bold
                },
                message: formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("1oKq6")).default).noColumnsDescriptionAnonymousIssueTermRefresh : (0, (0, parcelRequire("1oKq6")).default).noColumnsDescriptionAnonymous, {
                    boldStart: '{boldStart}',
                    boldEnd: '{boldEnd}'
                })
            }),
            testId: "issue-navigator.ui.issue-results.list-view.no-columns.no-columns-anonymous",
            primaryAction: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                appearance: "primary",
                onClick: onClick
            }, formatMessage((0, (0, parcelRequire("1oKq6")).default).switchView)),
            renderImage: ()=>defaultNoColumnsGlyph
        });
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            paddingBlock: "space.800"
        }, component);
    };
    const $21675d57ff4dba29$export$a0e9f6d02eee4aa4 = ({ defaultNoColumnsGlyph: defaultNoColumnsGlyph, hasColumnConfiguration: hasColumnConfiguration })=>{
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        return isAnonymous ? (0, ($parcel$interopDefault($5uXOq))).createElement($21675d57ff4dba29$var$NoColumnsAnonymous, {
            defaultNoColumnsGlyph: defaultNoColumnsGlyph
        }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9DYf6")).components).NoColumns, {
            defaultNoColumnsGlyph: defaultNoColumnsGlyph,
            hasColumnConfiguration: hasColumnConfiguration
        });
    };
});
parcelRegister("8Q4sF", function(module, exports) {
    $parcel$export(module.exports, "FormattedI18nMessage", ()=>$7c8c20575fbc9bfc$export$200d7bd7633253ce);
    var $5uXOq = parcelRequire("5uXOq");
    var $gHDlh;
    var $9Byym;
    const $7c8c20575fbc9bfc$export$200d7bd7633253ce = (props)=>{
        const { message: message, packageName: packageName, componentsMapping: componentsMapping } = props;
        const messageParts = (0, $5uXOq.useMemo)(()=>{
            const { tags: tags, openTags: openTags, closeTags: closeTags } = (0, (0, parcelRequire("9Byym")).getTags)(componentsMapping);
            try {
                const parts = [];
                let token = '';
                let TagComponent;
                let closeTagLookup;
                for(let i = 0; i < message.length; i += 1){
                    const letter = message[i];
                    token += letter;
                    if ((0, (0, parcelRequire("9Byym")).isCloseTagDelimiter)(letter)) {
                        const firstTagFound = (0, (0, parcelRequire("9Byym")).findTag)(token, {
                            tags: openTags
                        });
                        if (firstTagFound !== null && firstTagFound !== undefined) {
                            if (TagComponent) throw new (0, (0, parcelRequire("gHDlh")).MalformedMessageError)();
                            const text = token.replace(firstTagFound, '');
                            parts.push(text);
                            const tagInfo = tags[firstTagFound];
                            TagComponent = tagInfo.component;
                            closeTagLookup = tagInfo.close;
                            token = '';
                        }
                    }
                    if (closeTagLookup !== null && closeTagLookup !== undefined && (0, (0, parcelRequire("9Byym")).isCloseTagDelimiter)(letter) && (0, (0, parcelRequire("9Byym")).isAnyTag)(token, {
                        tags: [
                            closeTagLookup
                        ]
                    })) {
                        if (!TagComponent) throw new (0, (0, parcelRequire("gHDlh")).MalformedMessageError)();
                        const text = token.replace(closeTagLookup, '');
                        parts.push((0, ($parcel$interopDefault($5uXOq))).createElement(TagComponent, null, text));
                        TagComponent = undefined;
                        token = '';
                        closeTagLookup = undefined;
                    }
                    if ((0, (0, parcelRequire("9Byym")).isCloseTagDelimiter)(letter) && (0, (0, parcelRequire("9Byym")).isAnyTag)(token, {
                        tags: closeTags
                    })) {
                        if (!TagComponent) throw new (0, (0, parcelRequire("gHDlh")).MalformedMessageError)();
                    }
                }
                if (TagComponent || closeTagLookup) throw new (0, (0, parcelRequire("gHDlh")).MalformedMessageError)();
                if (token.length > 0) parts.push(token);
                return parts;
            } catch (e) {
                if (e instanceof (0, (0, parcelRequire("gHDlh")).MalformedMessageError)) {
                    (0, (0, parcelRequire("gHDlh")).logMalformedMessageError)({
                        message: message,
                        packageName: packageName
                    });
                    return [
                        (0, (0, parcelRequire("gHDlh")).getMalformedMessage)({
                            message: message,
                            closeTags: closeTags,
                            openTags: openTags
                        })
                    ];
                }
                throw e;
            }
        }, [
            message,
            packageName,
            componentsMapping
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, messageParts.map((item, index)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, $5uXOq.Fragment), {
                key: index
            }, item)));
    };
});
parcelRegister("gHDlh", function(module, exports) {
    $parcel$export(module.exports, "getMalformedMessage", ()=>$ecff24857c176a60$export$f085cf20ec1f69ab);
    $parcel$export(module.exports, "logMalformedMessageError", ()=>$ecff24857c176a60$export$954c3e6aad1c9429);
    $parcel$export(module.exports, "MalformedMessageError", ()=>$ecff24857c176a60$export$40d900e818b9630e);
    var $dJl1T;
    var $jcw0u;
    const $ecff24857c176a60$export$f085cf20ec1f69ab = ({ message: message, openTags: openTags, closeTags: closeTags })=>[
            ...openTags,
            ...closeTags
        ].reduce((acc, tag)=>acc.replace(new RegExp(tag, 'g'), ''), message);
    const $ecff24857c176a60$export$954c3e6aad1c9429 = ({ message: message, packageName: packageName })=>{
        if (packageName !== null && packageName !== undefined) {
            const errorMessage = `Malformed message: ${message}`;
            (0, (0, parcelRequire("jcw0u")).default)({
                meta: {
                    packageName: packageName,
                    id: (0, $5514815b06163219$export$f3390a0e172fee41)
                },
                attributes: {
                    message: errorMessage
                }
            });
            const location = `${packageName}.${(0, $5514815b06163219$export$f3390a0e172fee41)}`;
            (0, (0, parcelRequire("dJl1T")).default).unsafeErrorWithCustomerData(location, errorMessage);
        }
    };
    class $ecff24857c176a60$export$40d900e818b9630e extends Error {
        constructor(){
            super('Malformed i18n message');
        }
    }
});
const $5514815b06163219$export$f3390a0e172fee41 = 'formattedI18NMessage';
parcelRegister("9Byym", function(module, exports) {
    $parcel$export(module.exports, "getTags", ()=>$84498158f946a67b$export$af63d7414243dad3);
    $parcel$export(module.exports, "isAnyTag", ()=>$84498158f946a67b$export$38ffa64fc639355f);
    $parcel$export(module.exports, "findTag", ()=>$84498158f946a67b$export$dfca0f74034f8657);
    $parcel$export(module.exports, "isCloseTagDelimiter", ()=>$84498158f946a67b$export$d5170904651e91b1);
    const $84498158f946a67b$export$af63d7414243dad3 = (componentsMapping)=>Object.entries(componentsMapping).reduce((acc, [key, value])=>{
            const open = `{${key}Start}`;
            const close = `{${key}End}`;
            acc.tags[open] = {
                close: close,
                component: value
            };
            acc.openTags.push(open);
            acc.closeTags.push(close);
            return acc;
        }, {
            tags: {},
            openTags: [],
            closeTags: []
        });
    const $84498158f946a67b$export$38ffa64fc639355f = (text, { tags: tags })=>tags.some((tag)=>text.includes(tag));
    const $84498158f946a67b$export$dfca0f74034f8657 = (text, { tags: tags })=>tags.find((tag)=>text.includes(tag));
    const $84498158f946a67b$export$d5170904651e91b1 = (letter)=>letter === (0, $7ed9c0b663db8b35$export$a10c567c3288a6b4);
});
const $7ed9c0b663db8b35$export$a10c567c3288a6b4 = '}';
parcelRegister("1oKq6", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$180f8d8b8bfd2e47$export$2e2bcd8739ae039);
    var $7VHMR;
    var $180f8d8b8bfd2e47$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        noColumnsHeaderAnonymous: {
            "id": "issue-navigator.issue-results.list-view.issue-table-no-columns-anonymous.no-columns-header-anonymous",
            "defaultMessage": "The admin hasn't configured any columns"
        },
        noColumnsDescriptionAnonymous: {
            "id": "issue-navigator.issue-results.list-view.issue-table-no-columns-anonymous.no-columns-description-anonymous",
            "defaultMessage": "Switch to {boldStart}Detail view{boldEnd} to see issues and their information."
        },
        switchView: {
            "id": "issue-navigator.issue-results.list-view.issue-table-no-columns-anonymous.switch-view",
            "defaultMessage": "Switch to detail view"
        },
        noColumnsDescriptionAnonymousIssueTermRefresh: {
            "id": "issue-navigator.issue-results.list-view.issue-table-no-columns-anonymous.no-columns-description-anonymous-issue-term-refresh",
            "defaultMessage": "Switch to {boldStart}Detail view{boldEnd} to see work items and their information."
        }
    });
});
parcelRegister("1g3qz", function(module, exports) {
    $parcel$export(module.exports, "NoIssues", ()=>$b830958a6a36b095$export$a7a61a727a1d891b);
    var $5uXOq = parcelRequire("5uXOq");
    var $hTEpF;
    var $dC5iT;
    var $6s1PB;
    var $koVbs;
    var $hgx9A;
    const $b830958a6a36b095$export$a7a61a727a1d891b = ({ defaultNoIssuesGlyph: defaultNoIssuesGlyph })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            paddingBlock: "space.800"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hTEpF")).default), {
            header: formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("hgx9A")).default).noIssuesIssueTermRefresh : (0, (0, parcelRequire("hgx9A")).default).noIssues),
            renderImage: ()=>defaultNoIssuesGlyph,
            testId: "issue-navigator.ui.issue-results.list-view.no-issues.empty-state"
        }));
    };
});
parcelRegister("hgx9A", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$4793c96b4dc9cacf$export$2e2bcd8739ae039);
    var $7VHMR;
    var $4793c96b4dc9cacf$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        noIssues: {
            "id": "issue-navigator.common.empty-list-icon.no-issues",
            "defaultMessage": "No issues were found matching your search"
        },
        noIssuesIssueTermRefresh: {
            "id": "issue-navigator.issue-results.list-view.no-issues.no-issues-issue-term-refresh",
            "defaultMessage": "There's nothing matching your search"
        }
    });
});
parcelRegister("ePwTF", function(module, exports) {
    $parcel$export(module.exports, "AsyncRegisterShortcutDialogActions", ()=>$336808f7ab792907$export$53d5920188b673b6);
    var $5uXOq = parcelRequire("5uXOq");
    var $39xOx;
    var $lfTZh;
    var $a0UwL;
    var $kxZPA;
    var $6Lg6A;
    const $336808f7ab792907$var$LazyRegisterShortcutDialogActions = (0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("dDn6P")), {
        moduleId: "./src/packages/issue-navigator/issue-navigator/src/ui/issue-results/list-view/register-shortcut-dialog-actions/index.tsx"
    });
    const $336808f7ab792907$export$53d5920188b673b6 = (props)=>{
        const [{ isShortcutsDialogVisible: isShortcutsDialogVisible }] = (0, (0, parcelRequire("a0UwL")).useShortcuts)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            packageName: "jiraIssueNavigator",
            id: "register-issue-actions-worklflow-shortcuts-dialog",
            fallback: "flag"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6Lg6A")).LazyWait), {
            until: isShortcutsDialogVisible
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "register-shortcut-dialog-actions",
            fallback: null
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($336808f7ab792907$var$LazyRegisterShortcutDialogActions, props))));
    };
});
parcelRegister("a0UwL", function(module, exports) {
    $parcel$export(module.exports, "useShortcuts", ()=>$991d4b7077d5c717$export$a1ca853c6e4bb6fb);
    $parcel$export(module.exports, "useShortcutsActions", ()=>$991d4b7077d5c717$export$b331ee6e87b9104c);
    var $axjlw;
    const $991d4b7077d5c717$export$a1ca853c6e4bb6fb = ()=>(0, (0, parcelRequire("axjlw")).useShortcutsStore)();
    const $991d4b7077d5c717$export$b331ee6e87b9104c = ()=>{
        const [, actions] = (0, (0, parcelRequire("axjlw")).useShortcutsActionsStore)();
        return [
            undefined,
            actions
        ];
    };
});
parcelRegister("axjlw", function(module, exports) {
    $parcel$export(module.exports, "useShortcutsStore", ()=>$fefbe59bb1ce22b9$export$a14646bfc7ba8d81);
    $parcel$export(module.exports, "useShortcutsActionsStore", ()=>$fefbe59bb1ce22b9$export$1a29bfef7fa09838);
    var $3sQ5x;
    var $iXqE5;
    var $f0R9h;
    const $fefbe59bb1ce22b9$export$d4c72bab9d6cc13a = {
        shortcuts: {
            admin: {
                key: 'admin',
                label: 'ADMIN',
                options: [],
                isLoaded: false
            }
        },
        isShortcutsDialogVisible: false,
        isShortcutsLoading: false,
        hasError: false,
        isAdminLoading: false,
        selectedItemKey: null,
        header: null,
        title: undefined
    };
    const $fefbe59bb1ce22b9$export$6f57813fe9f31bf9 = (0, (0, parcelRequire("iXqE5")).createStore)({
        name: 'shortcuts',
        initialState: $fefbe59bb1ce22b9$export$d4c72bab9d6cc13a,
        actions: (0, parcelRequire("f0R9h"))
    });
    const $fefbe59bb1ce22b9$export$a14646bfc7ba8d81 = (0, (0, parcelRequire("3sQ5x")).createHook)($fefbe59bb1ce22b9$export$6f57813fe9f31bf9);
    const $fefbe59bb1ce22b9$export$1a29bfef7fa09838 = (0, (0, parcelRequire("3sQ5x")).createHook)($fefbe59bb1ce22b9$export$6f57813fe9f31bf9, {
        selector: null
    });
});
parcelRegister("f0R9h", function(module, exports) {
    $parcel$export(module.exports, "registerShortcuts", ()=>$d845eb212fa1ca53$export$598ec0e6047cbdca);
    $parcel$export(module.exports, "setShortcutsDialogVisibility", ()=>$d845eb212fa1ca53$export$80ab1e79e32c3021);
    $parcel$export(module.exports, "setShortcutsLoading", ()=>$d845eb212fa1ca53$export$7ed2ac8328423043);
    $parcel$export(module.exports, "setHasError", ()=>$d845eb212fa1ca53$export$645a7c7453685186);
    $parcel$export(module.exports, "setHeader", ()=>$d845eb212fa1ca53$export$604ed74fb893f0e2);
    $parcel$export(module.exports, "fetchAdminSections", ()=>$d845eb212fa1ca53$export$aec87c8c9bde62e6);
    $parcel$export(module.exports, "setSelectedItemKey", ()=>$d845eb212fa1ca53$export$249c9d5b49cd9712);
    $parcel$export(module.exports, "dropShortcutsByKeys", ()=>$d845eb212fa1ca53$export$9cf39adf0dec984);
    var $k8IPx = parcelRequire("k8IPx");
    var $1qfyE;
    var $8mrJC;
    const $d845eb212fa1ca53$export$598ec0e6047cbdca = (shortcuts)=>({ getState: getState, setState: setState })=>{
            const currentState = getState();
            const newShortcuts = shortcuts.reduce((acc, current)=>{
                acc[current.key] = current;
                return acc;
            }, {
                ...currentState.shortcuts
            });
            setState({
                ...currentState,
                shortcuts: newShortcuts,
                isShortcutsLoading: false
            });
        };
    const $d845eb212fa1ca53$export$80ab1e79e32c3021 = (isVisible)=>({ getState: getState, setState: setState })=>{
            setState({
                ...getState(),
                isShortcutsDialogVisible: isVisible
            });
        };
    const $d845eb212fa1ca53$export$7ed2ac8328423043 = (isLoading)=>({ getState: getState, setState: setState })=>{
            setState({
                ...getState(),
                isShortcutsLoading: isLoading
            });
        };
    const $d845eb212fa1ca53$export$645a7c7453685186 = (hasError)=>({ getState: getState, setState: setState })=>{
            const currentState = getState();
            setState({
                ...currentState,
                hasError: hasError
            });
        };
    const $d845eb212fa1ca53$export$604ed74fb893f0e2 = (header, title)=>({ getState: getState, setState: setState })=>{
            const currentState = getState();
            setState({
                ...currentState,
                header: header,
                title: title
            });
        };
    const $d845eb212fa1ca53$export$aec87c8c9bde62e6 = ()=>async ({ setState: setState, getState: getState })=>{
            const currentState = getState();
            setState({
                ...currentState,
                isAdminLoading: true
            });
            const result = await (0, (0, parcelRequire("1qfyE")).default)();
            const shortcuts = result ? (0, (0, parcelRequire("8mrJC")).buildAdminSections)(result) : [];
            const newState = getState();
            setState({
                ...newState,
                shortcuts: {
                    ...newState.shortcuts,
                    admin: {
                        ...newState.shortcuts.admin,
                        key: 'admin',
                        options: shortcuts,
                        isLoaded: true
                    }
                },
                hasError: currentState.hasError || !result,
                isAdminLoading: false
            });
        };
    const $d845eb212fa1ca53$export$249c9d5b49cd9712 = (item)=>({ setState: setState, getState: getState })=>{
            setState({
                ...getState(),
                selectedItemKey: item
            });
        };
    const $d845eb212fa1ca53$export$9cf39adf0dec984 = (keys)=>({ getState: getState, setState: setState })=>{
            if (!keys.length) return;
            const { shortcuts: shortcuts, ...rest } = getState();
            const newShortcuts = (0, ($parcel$interopDefault($k8IPx)))(Object.keys(shortcuts), keys).reduce((acc, key)=>{
                acc[key] = shortcuts[key];
                return acc;
            }, {});
            setState({
                ...rest,
                shortcuts: newShortcuts,
                isShortcutsLoading: false
            });
        };
});
parcelRegister("1qfyE", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$0636f27c00094fd3$export$2e2bcd8739ae039);
    var $dJl1T;
    var $7f4tk;
    var $0636f27c00094fd3$export$2e2bcd8739ae039 = async ()=>{
        try {
            return await (0, (0, parcelRequire("7f4tk")).default)('/rest/adminquicksearch/latest/links/default');
        } catch (error) {
            (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('shortcuts-dialog.fetch-admin-links', error.toString());
            return null;
        }
    };
});
parcelRegister("8mrJC", function(module, exports) {
    $parcel$export(module.exports, "buildAdminSections", ()=>$9289773ab8ca36d5$export$5f8c94ecd4c9eb76);
    const $9289773ab8ca36d5$export$5f8c94ecd4c9eb76 = (parent, result = [])=>{
        if (parent.sections && parent.sections.length) parent.sections.forEach((s)=>$9289773ab8ca36d5$export$5f8c94ecd4c9eb76(s, result));
        if (parent.items && parent.items.length) parent.items.forEach((i)=>result.push({
                key: i.key,
                label: i.label,
                onClick: ()=>{
                    window.location.href = i.linkUrl;
                }
            }));
        return result;
    };
});
parcelRegister("dDn6P", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("lA0j4").then(()=>parcelRequire('Jq3ih'));
});
parcelRegister("dHKP1", function(module, exports) {
    $parcel$export(module.exports, "dragAndDropExperience", ()=>$ede56ac081600cc8$export$c1eb53e51d4d0ab3);
    var $atdLB;
    var $duUGa;
    const $ede56ac081600cc8$export$c1eb53e51d4d0ab3 = new (0, (0, parcelRequire("duUGa")).UFOExperience)('jira-issue-navigator.list-view.drag-and-drop', {
        type: (0, (0, parcelRequire("atdLB")).ExperienceTypes).Experience,
        performanceType: (0, (0, parcelRequire("atdLB")).ExperiencePerformanceTypes).InlineResult
    });
});
parcelRegister("dYvVi", function(module, exports) {
    $parcel$export(module.exports, "useEventHandlers", ()=>$a2e970e494c5fd70$export$8ce912bc34d0dbea);
    var $5uXOq = parcelRequire("5uXOq");
    var $6s1PB;
    var $g5X3G;
    var $gtUFk;
    var $7OTah;
    var $isIOR;
    var $cqgcE;
    var $6Hhfn;
    const $a2e970e494c5fd70$var$useEventHandlersOld = ()=>{
        const { setSelectedIssueByKey: setSelectedIssueByKey, deselectIssue: deselectIssue } = (0, (0, parcelRequire("gtUFk")).useSelectedIssueStateOldActions)();
        const { onDeleteIssue: onDeleteIssue, onIssueByFieldsRefetch: onIssueByFieldsRefetch } = (0, (0, parcelRequire("isIOR")).useIssueSearchQuery)();
        const onIssueRefetch = onIssueByFieldsRefetch;
        const onUpdateCache = onIssueByFieldsRefetch;
        const onIssueDelete = (0, $5uXOq.useCallback)((deletedIssueKey)=>{
            const issueKeyToSelect = onDeleteIssue(deletedIssueKey);
            if (!issueKeyToSelect.length) {
                deselectIssue(false, false, false);
                return;
            }
            setSelectedIssueByKey((0, (0, parcelRequire("g5X3G")).toIssueKey)(issueKeyToSelect), false, false, false);
        }, [
            onDeleteIssue,
            setSelectedIssueByKey,
            deselectIssue
        ]);
        const onIssueLinkSelected = (0, $5uXOq.useCallback)((issueKey)=>{
            setSelectedIssueByKey((0, (0, parcelRequire("g5X3G")).toIssueKey)(issueKey));
            return true;
        }, [
            setSelectedIssueByKey
        ]);
        return (0, $5uXOq.useMemo)(()=>({
                onIssueRefetch: onIssueRefetch,
                onUpdateCache: onUpdateCache,
                onIssueDelete: onIssueDelete,
                onIssueLinkSelected: onIssueLinkSelected
            }), [
            onIssueRefetch,
            onUpdateCache,
            onIssueDelete,
            onIssueLinkSelected
        ]);
    };
    const $a2e970e494c5fd70$var$useEventHandlersNew = (connectionId, fieldSetIds)=>{
        const { setSelectedIssueByKey: setSelectedIssueByKey, deselectIssue: deselectIssue } = (0, (0, parcelRequire("7OTah")).useSelectedIssueActions)();
        const onDeleteIssue = (0, (0, parcelRequire("cqgcE")).useOnDeleteIssue)(connectionId);
        const onIssueByFieldsRefetch = (0, (0, parcelRequire("6Hhfn")).useIssueByFieldsRefetch)(fieldSetIds);
        const onIssueRefetch = onIssueByFieldsRefetch;
        const onUpdateCache = onIssueByFieldsRefetch;
        const onIssueDelete = (0, $5uXOq.useCallback)((deletedIssueKey)=>{
            const issueKeyToSelect = onDeleteIssue(deletedIssueKey);
            if (!issueKeyToSelect.length) {
                deselectIssue({
                    shouldDebounce: false,
                    shouldNotifyOnChange: false,
                    isSelectedByUserInteraction: false
                });
                return;
            }
            setSelectedIssueByKey((0, (0, parcelRequire("g5X3G")).toIssueKey)(issueKeyToSelect), {
                shouldDebounce: false,
                shouldNotifyOnChange: false,
                isSelectedByUserInteraction: false
            });
        }, [
            onDeleteIssue,
            setSelectedIssueByKey,
            deselectIssue
        ]);
        const onIssueLinkSelected = (0, $5uXOq.useCallback)((issueKey)=>{
            setSelectedIssueByKey((0, (0, parcelRequire("g5X3G")).toIssueKey)(issueKey));
            return true;
        }, [
            setSelectedIssueByKey
        ]);
        return (0, $5uXOq.useMemo)(()=>({
                onIssueRefetch: onIssueRefetch,
                onUpdateCache: onUpdateCache,
                onIssueDelete: onIssueDelete,
                onIssueLinkSelected: onIssueLinkSelected
            }), [
            onIssueRefetch,
            onUpdateCache,
            onIssueDelete,
            onIssueLinkSelected
        ]);
    };
    const $a2e970e494c5fd70$export$8ce912bc34d0dbea = (connectionId, fieldSetIds)=>(0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false) ? $a2e970e494c5fd70$var$useEventHandlersNew(connectionId, fieldSetIds) : $a2e970e494c5fd70$var$useEventHandlersOld();
});
parcelRegister("gOpmP", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$df04299218cd8fb7$export$2e2bcd8739ae039);
    var $2KpEY;
    var $CuCBv;
    var $9XMR6;
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Uumt = parcelRequire("8Uumt");
    var $dC5iT;
    var $8CUq3;
    var $39xOx;
    var $gcl1l;
    var $6s1PB;
    var $koVbs;
    var $lhXIu;
    var $kQ256;
    var $3RNwj;
    var $8WcPx;
    var $032ey;
    var $3HwlS;
    var $9z65D;
    var $cZpsT;
    var $l8ruj;
    var $6UAdF;
    var $eJ0Zs;
    var $a8lIB;
    var $1z7Yr;
    var $acgaZ;
    var $anxcG;
    var $gwtSG;
    var $isIOR;
    var $38r5c;
    var $bNJuB;
    var $5aoL8;
    var $66jXs;
    var $weR3I;
    var $3iu8n;
    const $df04299218cd8fb7$var$JQLBuilderWrapper = ({ defaultJql: defaultJql, onFilterSave: onFilterSave, onReset: onReset, onChangeJql: onChangeJql, onToggleAi: onToggleAi, issueResults: issueResults, filter: filter, jqlBuilderProps: jqlBuilderProps, jqlBuilderWithAiKey: jqlBuilderWithAiKey })=>{
        const { startJqlBuilderMetric: startJqlBuilderMetric, stopJqlBuilderMetric: stopJqlBuilderMetric, markJqlBuilderMetric: markJqlBuilderMetric } = (0, (0, parcelRequire("weR3I")).useJqlBuilderInstrumentation)();
        (0, $5uXOq.useLayoutEffect)(()=>{
            startJqlBuilderMetric();
            markJqlBuilderMetric((0, (0, parcelRequire("a8lIB")).marks).JQL_BUILDER_START);
        }, [
            startJqlBuilderMetric,
            markJqlBuilderMetric
        ]);
        const { query: query, onSearch: onSearch } = (0, (0, parcelRequire("anxcG")).useJqlSearch)(onChangeJql);
        const { jql: jql, filterJql: filterJql, filterId: filterId } = (0, (0, parcelRequire("gwtSG")).useActiveJql)();
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        let jqlErrors;
        let isFetching;
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) isFetching = (0, (0, parcelRequire("38r5c")).useIsFetching)();
        else ({ isFetching: isFetching } = (0, (0, parcelRequire("isIOR")).useIssueSearchQuery)());
        const issueResultsData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("9XMR6")).default), issueResults);
        const issueSearchError = issueResultsData?.issueSearchError ?? null;
        if (issueSearchError && !isFetching) {
            if (issueSearchError.__typename === 'JiraInvalidJqlError') jqlErrors = issueSearchError.messages?.filter((0, (0, parcelRequire("eJ0Zs")).isNonNullish)).map((message)=>({
                    type: 'error',
                    message: message
                })) ?? [];
            else if (issueSearchError.__typename === 'JiraInvalidSyntaxError' && issueSearchError.message != null) jqlErrors = [
                {
                    type: 'error',
                    message: issueSearchError.message,
                    errorType: issueSearchError.errorType ?? undefined
                }
            ];
        }
        const filterData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("CuCBv")).default), filter);
        const ResetButtonControl = (0, $5uXOq.useCallback)(()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("66jXs")).default), {
                onReset: onReset
            }), [
            onReset
        ]);
        const GoBackToFilterButtonControl = (0, $5uXOq.useCallback)(()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("66jXs")).default), {
                onReset: onReset,
                isResetToFilter: true
            }), [
            onReset
        ]);
        const SaveFilterControl = (0, $5uXOq.useCallback)(({ searchMode: searchMode })=>(0, ($parcel$interopDefault($5uXOq))).createElement($df04299218cd8fb7$var$SaveFilterWrapper, {
                "data-testid": "issue-navigator.ui.jql-builder.save-filter-control"
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9z65D")).AsyncSaveFilterDialog), {
                jql: query,
                attributes: {
                    isAdvancedModeEnabled: searchMode === (0, (0, parcelRequire("032ey")).SEARCH_MODE_ADVANCED)
                },
                onFilterSave: onFilterSave,
                buttonText: formatMessage((0, (0, parcelRequire("bNJuB")).default).saveFilterButton),
                title: formatMessage((0, (0, parcelRequire("bNJuB")).default).saveFilterDialogTitle)
            })), [
            onFilterSave,
            query,
            formatMessage
        ]);
        const SaveFilterDropdownControl = (0, $5uXOq.useCallback)(()=>(0, ($parcel$interopDefault($5uXOq))).createElement($df04299218cd8fb7$var$SaveFilterWrapper, {
                "data-testid": "issue-navigator.ui.jql-builder.save-filter-dropdown-control"
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lhXIu")).SaveFilterDropdown), {
                query: query,
                filterId: filterId,
                onFilterSave: onFilterSave
            })), [
            filterId,
            query,
            onFilterSave
        ]);
        const CopyFilterControl = (0, $5uXOq.useCallback)(({ searchMode: searchMode })=>(0, ($parcel$interopDefault($5uXOq))).createElement($df04299218cd8fb7$var$SaveFilterWrapper, {
                "data-testid": "issue-navigator.ui.jql-builder.copy-filter-control"
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9z65D")).AsyncSaveFilterDialog), {
                jql: query,
                filterId: filterId,
                attributes: {
                    isAdvancedModeEnabled: searchMode === (0, (0, parcelRequire("032ey")).SEARCH_MODE_ADVANCED)
                },
                onFilterSave: onFilterSave,
                buttonText: formatMessage((0, (0, parcelRequire("bNJuB")).default).copyFilterButton),
                title: formatMessage((0, (0, parcelRequire("bNJuB")).default).copyFilterDialogTitle)
            })), [
            onFilterSave,
            query,
            filterId,
            formatMessage
        ]);
        const renderFilterControls = (0, $5uXOq.useCallback)(()=>{
            const extraControls = [];
            if (!isAnonymous) {
                if (filterData) {
                    const { isEditable: isEditable } = filterData;
                    if (!jqlErrors || jqlErrors.length === 0) {
                        if ((0, (0, parcelRequire("3iu8n")).isFilterJqlModified)({
                            filterJql: filterJql,
                            query: query
                        })) {
                            if (isEditable === true) extraControls.push(SaveFilterDropdownControl);
                            else extraControls.push(SaveFilterControl);
                        } else extraControls.push(CopyFilterControl);
                    }
                } else extraControls.push(SaveFilterControl);
            }
            return extraControls;
        }, [
            CopyFilterControl,
            SaveFilterControl,
            SaveFilterDropdownControl,
            filterData,
            filterJql,
            isAnonymous,
            jqlErrors,
            query
        ]);
        const renderExtraControls = (0, $5uXOq.useCallback)(()=>{
            const extraControls = [];
            const showResetButton = (0, (0, parcelRequire("3iu8n")).isOnlyJqlAndDifferentToDefault)(jql, defaultJql, filterJql) || (0, (0, parcelRequire("3iu8n")).isOnlyFilterAndDifferentToDefault)(jql, defaultJql, filterJql) || (0, (0, parcelRequire("3iu8n")).filterAndJqlIsTheSameAndDifferentToDefault)(jql, defaultJql, filterJql);
            const showGoBackToFilterButton = (0, (0, parcelRequire("3iu8n")).jqlIsDifferentToFilter)(jql, filterJql);
            const addResetButton = ()=>showGoBackToFilterButton || showResetButton;
            if (addResetButton()) extraControls.push(showResetButton ? ResetButtonControl : GoBackToFilterButtonControl);
            extraControls.push(...renderFilterControls());
            return extraControls;
        }, [
            renderFilterControls,
            jql,
            defaultJql,
            filterJql,
            ResetButtonControl,
            GoBackToFilterButtonControl
        ]);
        let jqlMessages = (0, $5uXOq.useMemo)(()=>[
                ...jqlErrors || []
            ], [
            jqlErrors
        ]);
        const { messages: jqlInfos } = (0, (0, parcelRequire("acgaZ")).useJqlSearchStatus)({
            query: query,
            isSearching: isFetching
        });
        jqlMessages = (0, $5uXOq.useMemo)(()=>[
                ...jqlErrors || [],
                ...jqlInfos || []
            ], [
            jqlErrors,
            jqlInfos
        ]);
        const onLoad = (0, $5uXOq.useCallback)((mode)=>{
            if (mode === (0, (0, parcelRequire("032ey")).SEARCH_MODE_BASIC)) markJqlBuilderMetric((0, (0, parcelRequire("a8lIB")).marks).JQL_BUILDER_BASIC_START);
            else markJqlBuilderMetric((0, (0, parcelRequire("a8lIB")).marks).JQL_BUILDER_ADVANCED_START);
        }, [
            markJqlBuilderMetric
        ]);
        const onReady = (0, $5uXOq.useCallback)((mode)=>{
            if (mode === (0, (0, parcelRequire("032ey")).SEARCH_MODE_BASIC)) {
                markJqlBuilderMetric((0, (0, parcelRequire("a8lIB")).marks).JQL_BUILDER_BASIC_END);
                markJqlBuilderMetric((0, (0, parcelRequire("a8lIB")).marks).JQL_BUILDER_END);
            } else {
                markJqlBuilderMetric((0, (0, parcelRequire("a8lIB")).marks).JQL_BUILDER_ADVANCED_END);
                markJqlBuilderMetric((0, (0, parcelRequire("a8lIB")).marks).JQL_BUILDER_END);
            }
            stopJqlBuilderMetric();
        }, [
            stopJqlBuilderMetric,
            markJqlBuilderMetric
        ]);
        const aiBuilderOverrides = (0, $5uXOq.useMemo)(()=>({
                renderExtraControls: renderFilterControls
            }), [
            renderFilterControls
        ]);
        const jqlBuilderWithAiData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("2KpEY")).default), jqlBuilderWithAiKey);
        const { onJourneyStart: onJourneyStart } = (0, (0, parcelRequire("1z7Yr")).useAppContextualAnalyticsActions)();
        const handlePerformanceMark = (0, $5uXOq.useCallback)(({ mark: mark })=>{
            markJqlBuilderMetric(mark);
        }, [
            markJqlBuilderMetric
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: [
                $df04299218cd8fb7$var$wrapperStyles,
                (0, (0, parcelRequire("3HwlS")).getWillShowNav4)() && $df04299218cd8fb7$var$newWrapperStyles
            ]
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kQ256")).ShortcutsDialogIntegrationProvider), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8WcPx")).default), {
            analyticsSource: (0, (0, parcelRequire("6UAdF")).ANALYTICS_SOURCE),
            query: query,
            onSearch: onSearch,
            renderExtraControls: renderExtraControls,
            jqlMessages: jqlMessages,
            isSearching: isFetching,
            onReady: onReady,
            onLoad: onLoad,
            onJourneyStart: onJourneyStart,
            NoOptionComponent: (0, (0, parcelRequire("5aoL8")).NoOptionsMessage),
            aiBuilderOverrides: aiBuilderOverrides,
            onToggleAi: onToggleAi,
            fragmentKey: jqlBuilderWithAiData,
            onPerformanceMark: handlePerformanceMark,
            ...jqlBuilderProps
        })));
    };
    const $df04299218cd8fb7$var$JQLBuilderWrapperWithErrorBoundary = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l8ruj")).default), {
            name: "issue-navigator-jql-builder"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "ui.jql-builder.error-boundary",
            packageName: (0, (0, parcelRequire("6UAdF")).PACKAGE_NAME),
            teamName: (0, (0, parcelRequire("6UAdF")).TEAM_NAME),
            sendToPrivacyUnsafeSplunk: true,
            attributes: (0, (0, parcelRequire("gcl1l")).isLoaderErrorAttributes),
            fallback: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3RNwj")).JQLBuilderErrorBoundryFallback), null)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($df04299218cd8fb7$var$JQLBuilderWrapper, props)));
    var $df04299218cd8fb7$export$2e2bcd8739ae039 = $df04299218cd8fb7$var$JQLBuilderWrapperWithErrorBoundary;
    const $df04299218cd8fb7$var$wrapperStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        marginTop: 'space.negative.025',
        marginBottom: 'space.0'
    });
    const $df04299218cd8fb7$var$newWrapperStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        flexGrow: 'var(--jql-wrapper-grow, 0)',
        maxHeight: 'min-content'
    });
    const $df04299218cd8fb7$var$SaveFilterWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_19pk1b66",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("2KpEY", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$f3f74bc17cdb37af$export$2e2bcd8739ae039);
    const $f3f74bc17cdb37af$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "jqlBuilder_issueNavigator_JQLBuilderWrapper_jqlBuilderWithAiKey",
        "selections": [
            {
                "kind": "FragmentSpread",
                "name": "ui_jqlBuilderWithAi_JQLBuilderWithAI"
            }
        ],
        "type": "JiraQuery"
    };
    $f3f74bc17cdb37af$var$node.hash = "dd0aae667d7d92a8112c28d2dcff0ac8";
    var $f3f74bc17cdb37af$export$2e2bcd8739ae039 = $f3f74bc17cdb37af$var$node;
});
parcelRegister("CuCBv", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$85c380d9942dc9d5$export$2e2bcd8739ae039);
    const $85c380d9942dc9d5$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "jqlBuilder_issueNavigator_JQLBuilderWrapper_filter",
        "selections": [
            {
                "kind": "ScalarField",
                "name": "isEditable"
            }
        ],
        "type": "JiraCustomFilter"
    };
    $85c380d9942dc9d5$var$node.hash = "46b543f50c73d7423957a8ecbe71b627";
    var $85c380d9942dc9d5$export$2e2bcd8739ae039 = $85c380d9942dc9d5$var$node;
});
parcelRegister("9XMR6", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$a2c66ac90ee34e3d$export$2e2bcd8739ae039);
    const $a2c66ac90ee34e3d$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "jqlBuilder_issueNavigator_JQLBuilderWrapper_issueResults",
        "selections": [
            {
                "kind": "LinkedField",
                "name": "issueSearchError",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "name": "__typename"
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "name": "messages"
                            }
                        ],
                        "type": "JiraInvalidJqlError"
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "name": "message"
                            },
                            {
                                "kind": "ScalarField",
                                "name": "errorType"
                            }
                        ],
                        "type": "JiraInvalidSyntaxError"
                    }
                ]
            }
        ],
        "type": "JiraIssueConnection"
    };
    $a2c66ac90ee34e3d$var$node.hash = "eeb59c3a307dc75263f7e39b4f314c7c";
    var $a2c66ac90ee34e3d$export$2e2bcd8739ae039 = $a2c66ac90ee34e3d$var$node;
});
parcelRegister("lhXIu", function(module, exports) {
    $parcel$export(module.exports, "SaveFilterDropdown", ()=>$27f5e7d6c447efe5$export$4aae2fedc7924eed);
    var $64TeP;
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Uumt = parcelRequire("8Uumt");
    var $fVnbM;
    var $BLPHT;
    var $6B7KL;
    var $aRoVz;
    var $6Ingq;
    var $jcw0u;
    var $7HGHK;
    var $pa9q9;
    var $koVbs;
    var $7oAQw;
    var $eVAtv;
    var $dXq4S;
    var $8zOmE;
    var $2NMNM;
    var $cKjus;
    var $9OXo1;
    var $a0UsQ;
    var $inHPh;
    const $27f5e7d6c447efe5$export$a3ca021e3e6c7315 = (0, (0, parcelRequire("7HGHK")).toFlagId)('issue-navigator-save-filter-success-flag');
    const $27f5e7d6c447efe5$export$f5e4c120ac01b615 = (0, (0, parcelRequire("7HGHK")).toFlagId)('issue-navigator-save-filter-error-flag');
    const $27f5e7d6c447efe5$export$4aae2fedc7924eed = ({ defaultOpen: defaultOpen = false, filterId: filterId, query: query, onFilterSave: onFilterSave })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { showFlag: showFlag } = (0, (0, parcelRequire("pa9q9")).useFlagService)();
        const [updateJiraCustomFilterJqlMutation] = (0, $8Uumt.useMutation)((0, (0, parcelRequire("64TeP")).default));
        const { activationId: activationId, cloudId: cloudId } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const analyticsEvent = createAnalyticsEvent({});
        const onSaveChanges = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                action: 'clicked',
                actionSubject: 'dropdownItem'
            }), 'saveFilterChanges');
            filterId !== undefined && updateJiraCustomFilterJqlMutation({
                variables: {
                    id: (0, (0, parcelRequire("dXq4S")).createAri)({
                        resourceOwner: 'jira',
                        cloudId: cloudId,
                        resourceType: 'filter',
                        resourceId: filterId,
                        activationId: activationId
                    }),
                    jql: query
                },
                onCompleted: ({ jira: jira })=>{
                    if (jira?.jiraFilterMutation?.updateJiraCustomFilterJql) {
                        const { success: success, filter: filter, errors: jiraErrors } = jira?.jiraFilterMutation?.updateJiraCustomFilterJql;
                        if (success && filter) {
                            const { name: name } = filter;
                            (0, (0, parcelRequire("8zOmE")).fireTrackAnalytics)(createAnalyticsEvent({
                                actionSubject: 'issueFilterJql',
                                action: 'updated'
                            }), 'issueFilterJql updated');
                            showFlag({
                                id: $27f5e7d6c447efe5$export$a3ca021e3e6c7315,
                                render: (renderProps)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eVAtv")).SuccessFlag), {
                                        id: $27f5e7d6c447efe5$export$a3ca021e3e6c7315,
                                        filterName: name,
                                        viewUrl: `${window.location.origin}/issues/?filter=${filterId}`,
                                        analyticsEvent: createAnalyticsEvent({
                                            actionSubject: 'button',
                                            action: 'clicked'
                                        }),
                                        ...renderProps
                                    })
                            });
                        } else {
                            const errors = jiraErrors ? jiraErrors.map((error)=>error.message || '') : [];
                            showFlag({
                                id: $27f5e7d6c447efe5$export$f5e4c120ac01b615,
                                render: (renderProps)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7oAQw")).ErrorFlag), {
                                        id: $27f5e7d6c447efe5$export$f5e4c120ac01b615,
                                        errors: errors,
                                        ...renderProps
                                    })
                            });
                            (0, (0, parcelRequire("jcw0u")).default)({
                                event: analyticsEvent,
                                error: new Error(errors[0]),
                                meta: {
                                    id: 'updateFilterJqlMutation',
                                    packageName: 'jiraIssueNavigatorCustomFilters',
                                    teamName: 'empanada'
                                }
                            });
                        }
                    }
                },
                onError: (error)=>{
                    showFlag({
                        id: $27f5e7d6c447efe5$export$f5e4c120ac01b615,
                        render: (renderProps)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7oAQw")).ErrorFlag), {
                                id: $27f5e7d6c447efe5$export$f5e4c120ac01b615,
                                ...renderProps,
                                errors: [
                                    ''
                                ]
                            })
                    });
                    (0, (0, parcelRequire("jcw0u")).default)({
                        event: analyticsEvent,
                        error: error,
                        meta: {
                            id: 'updateFilterJqlMutation',
                            packageName: 'jiraIssueNavigatorCustomFilters',
                            teamName: 'empanada'
                        }
                    });
                    return undefined;
                }
            });
        }, [
            activationId,
            cloudId,
            createAnalyticsEvent,
            filterId,
            query,
            showFlag,
            updateJiraCustomFilterJqlMutation,
            analyticsEvent
        ]);
        const [isModalOpen, setIsModalOpen] = (0, $5uXOq.useState)(false);
        const [isDropdownOpen, setIsDropdownOpen] = (0, $5uXOq.useState)(defaultOpen);
        const [shouldShowThirdPartyNudge, setShouldShowThirdPartyNudge] = (0, $5uXOq.useState)(false);
        const onOpenSaveFilterModal = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                action: 'clicked',
                actionSubject: 'dropdownItem'
            }), 'saveFilterCopy');
            setIsModalOpen(true);
        }, [
            createAnalyticsEvent,
            setIsModalOpen
        ]);
        const onCloseSaveFilterModal = (0, $5uXOq.useCallback)(()=>{
            setIsModalOpen(false);
        }, [
            setIsModalOpen
        ]);
        const onTriggerClick = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                action: 'toggled',
                actionSubject: 'dropdownMenu'
            }), 'filterOptions');
        }, [
            createAnalyticsEvent
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, shouldShowThirdPartyNudge && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("a0UsQ")).default), null), (0, ($parcel$interopDefault($5uXOq))).createElement($27f5e7d6c447efe5$var$DropdownMenuWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("BLPHT")).default), {
            isOpen: isDropdownOpen,
            onOpenChange: (attrs)=>{
                setIsDropdownOpen(attrs.isOpen);
                if (attrs.isOpen) {
                    setShouldShowThirdPartyNudge(true);
                    onTriggerClick();
                }
            },
            trigger: ({ triggerRef: triggerRef, ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                    ...props,
                    appearance: "link",
                    ref: triggerRef,
                    iconAfter: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("6Ingq"))))), {
                        label: formatMessage((0, (0, parcelRequire("inHPh")).default).saveFilter)
                    })
                }, formatMessage((0, (0, parcelRequire("inHPh")).default).saveFilter))
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("aRoVz")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6B7KL")).default), {
            onClick: onSaveChanges
        }, formatMessage((0, (0, parcelRequire("inHPh")).default).saveChanges)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6B7KL")).default), {
            onClick: onOpenSaveFilterModal
        }, formatMessage((0, (0, parcelRequire("inHPh")).default).saveAsACopy))))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cKjus")).AsyncSaveFilterDialogContent), {
            isOpen: isModalOpen,
            jql: query,
            filterId: filterId,
            onCloseModal: onCloseSaveFilterModal,
            onFilterSave: onFilterSave,
            title: formatMessage((0, (0, parcelRequire("inHPh")).default).copyFilterDialogTitle)
        }));
    };
    const $27f5e7d6c447efe5$var$DropdownMenuWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_k4hekqvv",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("64TeP", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$7e51d2a292043c16$export$2e2bcd8739ae039);
    const $7e51d2a292043c16$var$node = function() {
        var v0 = [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "id"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "jql"
            }
        ], v1 = [
            {
                "concreteType": "JiraMutation",
                "kind": "LinkedField",
                "name": "jira",
                "plural": false,
                "selections": [
                    {
                        "concreteType": "JiraFilterMutation",
                        "kind": "LinkedField",
                        "name": "jiraFilterMutation",
                        "plural": false,
                        "selections": [
                            {
                                "args": [
                                    {
                                        "fields": [
                                            {
                                                "kind": "Variable",
                                                "name": "id",
                                                "variableName": "id"
                                            },
                                            {
                                                "kind": "Variable",
                                                "name": "jql",
                                                "variableName": "jql"
                                            }
                                        ],
                                        "kind": "ObjectValue",
                                        "name": "input"
                                    }
                                ],
                                "concreteType": "JiraUpdateCustomFilterJqlPayload",
                                "kind": "LinkedField",
                                "name": "updateJiraCustomFilterJql",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "ScalarField",
                                        "name": "success"
                                    },
                                    {
                                        "concreteType": "MutationError",
                                        "kind": "LinkedField",
                                        "name": "errors",
                                        "plural": true,
                                        "selections": [
                                            {
                                                "kind": "ScalarField",
                                                "name": "message"
                                            }
                                        ]
                                    },
                                    {
                                        "concreteType": "JiraCustomFilter",
                                        "kind": "LinkedField",
                                        "name": "filter",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "ScalarField",
                                                "name": "id"
                                            },
                                            {
                                                "kind": "ScalarField",
                                                "name": "name"
                                            },
                                            {
                                                "kind": "ScalarField",
                                                "name": "jql"
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ];
        return {
            "fragment": {
                "argumentDefinitions": v0,
                "kind": "Fragment",
                "name": "mainUpdateFilterJqlMutation",
                "selections": v1,
                "type": "Mutation"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": v0,
                "kind": "Operation",
                "name": "mainUpdateFilterJqlMutation",
                "selections": v1
            },
            "params": {
                "id": "33abce877d8ddf36bd3fdb295b2e91c467ceff0b718872d73a32845f7130d8d5",
                "metadata": {},
                "name": "mainUpdateFilterJqlMutation",
                "operationKind": "mutation",
                "text": null
            }
        };
    }();
    $7e51d2a292043c16$var$node.hash = "6807efed6a00f60f9cf95df455e467fd";
    var $7e51d2a292043c16$export$2e2bcd8739ae039 = $7e51d2a292043c16$var$node;
});
parcelRegister("6B7KL", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9ff85f737833a5bc$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $5JjdN;
    var $kJpBP;
    var $1aogK;
    var $3Z96c;
    var $VSDnB;
    var $l7Q0F;
    const $9ff85f737833a5bc$var$DropdownMenuItem = (0, $5uXOq.forwardRef)(({ children: children, component: component, description: description, elemAfter: elemAfter, elemBefore: elemBefore, href: href, isDisabled: isDisabled, isSelected: isSelected, onClick: onClick, rel: rel, shouldDescriptionWrap: shouldDescriptionWrap = true, shouldTitleWrap: shouldTitleWrap = true, target: target, testId: testId, UNSAFE_shouldDisableRouterLink: UNSAFE_shouldDisableRouterLink, returnFocusRef: returnFocusRef, interactionName: interactionName, ...rest }, ref)=>{
        const itemRef = (0, (0, parcelRequire("l7Q0F")).default)();
        const handleItemClick = (0, $5uXOq.useCallback)((event)=>{
            if (returnFocusRef?.current) returnFocusRef.current.focus();
            if (onClick) onClick(event);
        }, [
            onClick,
            returnFocusRef
        ]);
        if (component) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1aogK")).default), {
            component: component,
            description: description,
            iconAfter: elemAfter,
            iconBefore: elemBefore,
            isDisabled: isDisabled,
            isSelected: isSelected,
            onClick: handleItemClick,
            ref: (0, (0, parcelRequire("5JjdN")).default)([
                ref,
                itemRef
            ]),
            shouldDescriptionWrap: shouldDescriptionWrap,
            shouldTitleWrap: shouldTitleWrap,
            testId: testId,
            href: href,
            target: target,
            rel: rel,
            ...(0, (0, parcelRequire("VSDnB")).fg)('platform_button_item-add-ufo-metrics') && {
                interactionName: interactionName
            },
            ...rest
        }, children);
        else if (href) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3Z96c")).default), {
            description: description,
            href: href,
            iconAfter: elemAfter,
            iconBefore: elemBefore,
            isDisabled: isDisabled,
            isSelected: isSelected,
            onClick: handleItemClick,
            ref: (0, (0, parcelRequire("5JjdN")).default)([
                ref,
                itemRef
            ]),
            rel: rel,
            role: "menuitem",
            shouldDescriptionWrap: shouldDescriptionWrap,
            shouldTitleWrap: shouldTitleWrap,
            target: target,
            testId: testId,
            UNSAFE_shouldDisableRouterLink: UNSAFE_shouldDisableRouterLink,
            ...(0, (0, parcelRequire("VSDnB")).fg)('platform_button_item-add-ufo-metrics') && {
                interactionName: interactionName
            },
            ...rest
        }, children);
        else return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kJpBP")).default), {
            description: description,
            iconAfter: elemAfter,
            iconBefore: elemBefore,
            isDisabled: isDisabled,
            isSelected: isSelected,
            onClick: handleItemClick,
            ref: (0, (0, parcelRequire("5JjdN")).default)([
                ref,
                itemRef
            ]),
            role: "menuitem",
            shouldDescriptionWrap: shouldDescriptionWrap,
            shouldTitleWrap: shouldTitleWrap,
            testId: testId,
            target: target,
            rel: rel,
            ...(0, (0, parcelRequire("VSDnB")).fg)('platform_button_item-add-ufo-metrics') && {
                interactionName: interactionName
            },
            ...rest
        }, children);
    });
    var $9ff85f737833a5bc$export$2e2bcd8739ae039 = $9ff85f737833a5bc$var$DropdownMenuItem;
});
parcelRegister("aRoVz", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$6c1f89f82839ba50$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $9KnKW;
    var $9UCCQ;
    var $k20lf;
    const $6c1f89f82839ba50$var$DropdownMenuItemGroup = (0, $5uXOq.forwardRef)(({ children: children, id: id, isList: isList, isScrollable: isScrollable, title: title, testId: testId, hasSeparator: hasSeparator, overrides: overrides, ...rest }, ref)=>{
        const uid = (0, (0, parcelRequire("9KnKW")).useId)();
        const titleId = `dropdown-menu-item-group-title-${uid}`;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), {
            id: id,
            ref: ref,
            isList: isList,
            isScrollable: isScrollable,
            hasSeparator: hasSeparator,
            testId: testId,
            titleId: title ? titleId : undefined,
            overrides: overrides,
            ...rest
        }, title && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("k20lf")).default), {
            id: titleId,
            title: title
        }), children);
    });
    var $6c1f89f82839ba50$export$2e2bcd8739ae039 = $6c1f89f82839ba50$var$DropdownMenuItemGroup;
});
parcelRegister("7oAQw", function(module, exports) {
    $parcel$export(module.exports, "ErrorFlag", ()=>$8373e62b74d8a04b$export$518ba8e0e60f145f);
    var $5uXOq = parcelRequire("5uXOq");
    var $7HGHK;
    var $pa9q9;
    var $1wpPp;
    var $koVbs;
    var $f2rml;
    const $8373e62b74d8a04b$export$518ba8e0e60f145f = ({ id: id, errors: errors })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { dismissFlag: dismissFlag } = (0, (0, parcelRequire("pa9q9")).useFlagsService)();
        const onDismissed = (0, $5uXOq.useCallback)(()=>dismissFlag(id), [
            dismissFlag,
            id
        ]);
        const description = (0, $5uXOq.useMemo)(()=>errors.reduce((desc, error)=>`${desc}\n${error}`), [
            errors
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1wpPp")).default), {
            id: (0, (0, parcelRequire("7HGHK")).fromFlagId)(id),
            onDismissed: onDismissed,
            title: formatMessage((0, (0, parcelRequire("f2rml")).default).flagTitle),
            description: description
        });
    };
});
parcelRegister("1wpPp", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$2ebed3b5115fbfc5$export$2e2bcd8739ae039);
    var $3KRpp;
    var $2ebed3b5115fbfc5$export$2e2bcd8739ae039 = (0, (0, parcelRequire("3KRpp")).ErrorFlag);
});
parcelRegister("f2rml", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9196e79ea8ea2ad1$export$2e2bcd8739ae039);
    var $7VHMR;
    var $9196e79ea8ea2ad1$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        flagTitle: {
            "id": "issue-navigator-actions-common.error-flag.flag-title",
            "defaultMessage": "Filter couldn't be saved"
        }
    });
});
parcelRegister("eVAtv", function(module, exports) {
    $parcel$export(module.exports, "SuccessFlag", ()=>$92d66436aee8a83b$export$93bd4963a1d00fd5);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $jcw0u;
    var $7HGHK;
    var $pa9q9;
    var $4ywcn;
    var $koVbs;
    var $8zOmE;
    var $d0WfM;
    const $92d66436aee8a83b$export$93bd4963a1d00fd5 = ({ id: id, filterName: filterName, viewUrl: viewUrl, analyticsEvent: analyticsEvent })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { dismissFlag: dismissFlag } = (0, (0, parcelRequire("pa9q9")).useFlagsService)();
        const [isCopied, setCopied] = (0, $5uXOq.useState)(false);
        (0, $5uXOq.useEffect)(()=>{
            let timeoutId;
            if (isCopied) timeoutId = setTimeout(()=>{
                setCopied(false);
            }, 2000);
            return ()=>{
                timeoutId && clearTimeout(timeoutId);
            };
        }, [
            isCopied
        ]);
        const copyToClipboard = (0, $5uXOq.useCallback)(()=>{
            try {
                navigator.clipboard.writeText(viewUrl);
                setCopied(true);
                (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'copyLink');
            } catch (error) {
                (0, (0, parcelRequire("jcw0u")).default)({
                    error: error,
                    meta: {
                        id: 'copyToClipboard',
                        packageName: 'jiraIssueNavigatorActionsCommon'
                    }
                });
            }
        }, [
            analyticsEvent,
            viewUrl
        ]);
        const actions = (0, $5uXOq.useMemo)(()=>{
            const flagActions = [];
            flagActions.push({
                content: isCopied ? (0, ($parcel$interopDefault($5uXOq))).createElement($92d66436aee8a83b$var$CopySuccess, null, formatMessage((0, (0, parcelRequire("d0WfM")).default).copyActionCopied)) : formatMessage((0, (0, parcelRequire("d0WfM")).default).copyAction),
                onClick: copyToClipboard
            });
            return flagActions;
        }, [
            copyToClipboard,
            formatMessage,
            isCopied
        ]);
        const onDismissed = (0, $5uXOq.useCallback)(()=>dismissFlag(id), [
            dismissFlag,
            id
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4ywcn")).default), {
            id: (0, (0, parcelRequire("7HGHK")).fromFlagId)(id),
            onDismissed: onDismissed,
            title: (0, ($parcel$interopDefault($5uXOq))).createElement($92d66436aee8a83b$var$WordBreakSpan, null, formatMessage((0, (0, parcelRequire("d0WfM")).default).flagTitle, {
                name: filterName
            })),
            description: formatMessage((0, (0, parcelRequire("d0WfM")).default).flagDescription),
            actions: actions
        });
    };
    const $92d66436aee8a83b$var$CopySuccess = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_syazocqs",
                __cmplp.className
            ])
        });
    });
    const $92d66436aee8a83b$var$WordBreakSpan = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1nmz1hna",
                __cmplp.className
            ])
        });
    });
    const $92d66436aee8a83b$export$7ed12d2a5241e393 = {
        WordBreakSpan: $92d66436aee8a83b$var$WordBreakSpan
    };
});
parcelRegister("4ywcn", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$1a612b92e24f3853$export$2e2bcd8739ae039);
    var $3KRpp;
    var $1a612b92e24f3853$export$2e2bcd8739ae039 = (0, (0, parcelRequire("3KRpp")).SuccessFlag);
});
parcelRegister("d0WfM", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$be725ec701d9f454$export$2e2bcd8739ae039);
    var $7VHMR;
    var $be725ec701d9f454$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        flagTitle: {
            "id": "issue-navigator-actions-common.success-flag.flag-title",
            "defaultMessage": "\"{name}\" filter saved"
        },
        flagDescription: {
            "id": "issue-navigator-actions-common.success-flag.flag-description",
            "defaultMessage": "We\u2019ve saved this filter successfully."
        },
        viewAction: {
            "id": "issue-navigator-actions-common.success-flag.view-action",
            "defaultMessage": "View filter"
        },
        copyAction: {
            "id": "issue-navigator-actions-common.success-flag.copy-action",
            "defaultMessage": "Copy link"
        },
        copyActionCopied: {
            "id": "issue-navigator-actions-common.success-flag.copy-action-copied",
            "defaultMessage": "Copied"
        }
    });
});
parcelRegister("cKjus", function(module, exports) {
    $parcel$export(module.exports, "AsyncSaveFilterDialogContent", ()=>$c23de2261bd2f58d$export$e0d40cb0f88fa57d);
    var $5uXOq = parcelRequire("5uXOq");
    var $lfTZh;
    var $kxZPA;
    const $c23de2261bd2f58d$var$SaveFilterDialogContent = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("fbsDH")), {
        ssr: false,
        moduleId: "./src/packages/issue-navigator/save-filter-dialog/src/ui/save-filter-dialog/save-filter-dialog-content/index.tsx"
    });
    const $c23de2261bd2f58d$export$e0d40cb0f88fa57d = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "async-save-filters-dialog-content",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($c23de2261bd2f58d$var$SaveFilterDialogContent, props));
});
parcelRegister("fbsDH", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("hPzOn"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("3ChD0")
    ]).then(()=>parcelRequire('2lPrs'));
});
parcelRegister("a0UsQ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$18bfcc44ee4250a9$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $7XluA;
    var $4mO32;
    var $apcoj;
    const $18bfcc44ee4250a9$var$runtimeProps = {};
    const $18bfcc44ee4250a9$var$entryPointParams = {};
    const $18bfcc44ee4250a9$var$AsyncThirdPartyNudge = ()=>{
        const { entryPointActions: entryPointActions, entryPointReferenceSubject: entryPointReferenceSubject } = (0, (0, parcelRequire("4mO32")).useEntryPoint)((0, (0, parcelRequire("apcoj")).thirdPartyNudgeEntryPoint), $18bfcc44ee4250a9$var$entryPointParams);
        (0, $5uXOq.useEffect)(()=>{
            entryPointActions.load();
        }, [
            entryPointActions
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7XluA")).JiraEntryPointContainer), {
            id: "third-party-nudge-spotlight",
            packageName: "third-party-nudge",
            entryPointReferenceSubject: entryPointReferenceSubject,
            runtimeProps: $18bfcc44ee4250a9$var$runtimeProps,
            errorFallback: "unmount"
        });
    };
    var $18bfcc44ee4250a9$export$2e2bcd8739ae039 = $18bfcc44ee4250a9$var$AsyncThirdPartyNudge;
});
parcelRegister("apcoj", function(module, exports) {
    $parcel$export(module.exports, "thirdPartyNudgeEntryPoint", ()=>$b44a97c5eec5eb15$export$b751b31b276f613a);
    var $eufEc;
    var $fEDBv;
    const $b44a97c5eec5eb15$export$b751b31b276f613a = (0, (0, parcelRequire("fEDBv")).createEntryPoint)({
        root: (0, (0, parcelRequire("eufEc")).JSResourceForInteraction)(()=>(parcelRequire("ho18r")), {
            moduleId: "3cb7fd8f"
        }),
        getPreloadProps: ()=>({})
    });
});
parcelRegister("ho18r", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("hPzOn"),
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("jsSdY")
    ]).then(()=>parcelRequire('7R5AD'));
});
parcelRegister("inHPh", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$610585c330983aff$export$2e2bcd8739ae039);
    var $7VHMR;
    var $610585c330983aff$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        saveFilter: {
            "id": "issue-navigator-custom-filters.save-filter-dropdown.save-filter",
            "defaultMessage": "Save filter"
        },
        saveChanges: {
            "id": "issue-navigator-custom-filters.save-filter-dropdown.save-changes",
            "defaultMessage": "Save changes"
        },
        saveAsACopy: {
            "id": "issue-navigator-custom-filters.save-filter-dropdown.save-as-a-copy",
            "defaultMessage": "Save as a copy"
        },
        copyFilterDialogTitle: {
            "id": "issue-navigator-custom-filters.save-filter-dropdown.copy-filter-dialog-title",
            "defaultMessage": "Copy filter"
        }
    });
});
parcelRegister("kQ256", function(module, exports) {
    $parcel$export(module.exports, "useIntegrateDotDialogContext", ()=>$4f25f7900866c0e5$export$705d46354f301b1c);
    $parcel$export(module.exports, "ShortcutsDialogIntegrationProvider", ()=>$4f25f7900866c0e5$export$97e3ca22c7078b46);
    var $5uXOq = parcelRequire("5uXOq");
    const $4f25f7900866c0e5$var$PRESET_VALUE = {
        DEFAULT: {
            isShortcutsDialogIntegrationAvailable: false
        },
        INTEGRATED: {
            isShortcutsDialogIntegrationAvailable: true
        }
    };
    const $4f25f7900866c0e5$var$IntegrateDotDialogContext = (0, $5uXOq.createContext)($4f25f7900866c0e5$var$PRESET_VALUE.DEFAULT);
    const $4f25f7900866c0e5$export$705d46354f301b1c = ()=>(0, $5uXOq.useContext)($4f25f7900866c0e5$var$IntegrateDotDialogContext);
    const $4f25f7900866c0e5$export$97e3ca22c7078b46 = ({ children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement($4f25f7900866c0e5$var$IntegrateDotDialogContext.Provider, {
            value: $4f25f7900866c0e5$var$PRESET_VALUE.INTEGRATED
        }, children);
});
parcelRegister("3RNwj", function(module, exports) {
    $parcel$export(module.exports, "JQLBuilderErrorBoundryFallback", ()=>$8e5460bcff63ee1a$export$4c766016a5659721);
    var $5uXOq = parcelRequire("5uXOq");
    var $fVnbM;
    var $92Z2l;
    var $koVbs;
    var $iWDqa;
    const $8e5460bcff63ee1a$var$onRefresh = ()=>{
        if (typeof window !== 'undefined') window.location.reload();
    };
    const $8e5460bcff63ee1a$export$4c766016a5659721 = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("92Z2l")).default), {
            appearance: "error",
            testId: "jql-builder-common.ui.error-boundry-fallback.section-message"
        }, formatMessage((0, (0, parcelRequire("iWDqa")).default).errorMessage, {
            link: (linkText)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                    appearance: "link",
                    spacing: "none",
                    onClick: $8e5460bcff63ee1a$var$onRefresh
                }, linkText)
        }));
    };
});
parcelRegister("iWDqa", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$c45ebd3e2c96181c$export$2e2bcd8739ae039);
    var $7VHMR;
    var $c45ebd3e2c96181c$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        errorMessage: {
            "id": "jql-builder-common.error-boundry-fallback.error-message",
            "defaultMessage": "We couldn't load your search criteria. <link>Refresh the page</link> to try again."
        }
    });
});
parcelRegister("8WcPx", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$95e61a910f85b3f4$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $lfTZh;
    var $4p4kl;
    var $kxZPA;
    const $95e61a910f85b3f4$var$JQLBuilderWithAIAsync = (0, (0, parcelRequire("kxZPA")).lazyForPaint)(()=>(parcelRequire("6YqPz")), {
        moduleId: "./src/packages/jql-builder/with-ai/src/index.tsx"
    });
    const $95e61a910f85b3f4$var$JQLBuilderWithAI = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "async-jql-builder-with-ai",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4p4kl")).IssueNavigatorJQLBuilderSkeleton), null)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($95e61a910f85b3f4$var$JQLBuilderWithAIAsync, props));
    var $95e61a910f85b3f4$export$2e2bcd8739ae039 = $95e61a910f85b3f4$var$JQLBuilderWithAI;
});
parcelRegister("4p4kl", function(module, exports) {
    $parcel$export(module.exports, "IssueNavigatorJQLBuilderSkeleton", ()=>$fb9c2cbb8213ab8c$export$5be82223ddc28851);
    var $bwpsq;
    var $5uXOq = parcelRequire("5uXOq");
    var $gnABE;
    var $ei1nS = parcelRequire("ei1nS");
    const $fb9c2cbb8213ab8c$var$customCssStyles = null;
    const $fb9c2cbb8213ab8c$var$containerStyles = {
        height: '52px'
    };
    const $fb9c2cbb8213ab8c$export$5be82223ddc28851 = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gnABE")).AppSkeletonImage), {
            src: (0, ($parcel$interopDefault($ei1nS))),
            containerStyles: $fb9c2cbb8213ab8c$var$containerStyles,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1wgmglyw"
            ])
        });
});
parcelRegister("ei1nS", function(module, exports) {
    module.exports = new URL("issue-navigator-jql-builder-skeleton.tpl.20beba3c.svg", import.meta.url).toString();
});
parcelRegister("6YqPz", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("hPzOn"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("2fUpT")
    ]).then(()=>parcelRequire('7F6VS'));
});
parcelRegister("9z65D", function(module, exports) {
    $parcel$export(module.exports, "AsyncSaveFilterDialog", ()=>$88c358d8076a9a73$export$2213da0eee0aa8f);
    var $5uXOq = parcelRequire("5uXOq");
    var $fVnbM;
    var $lfTZh;
    var $kxZPA;
    const $88c358d8076a9a73$var$SaveFilterDialog = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("c7luI")), {
        ssr: false,
        moduleId: "./src/packages/issue-navigator/save-filter-dialog/src/ui/save-filter-dialog/main.tsx"
    });
    const $88c358d8076a9a73$export$2213da0eee0aa8f = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "save-filters-dialog",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                appearance: "link",
                isDisabled: true
            }, props.buttonText)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($88c358d8076a9a73$var$SaveFilterDialog, props));
});
parcelRegister("c7luI", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("jiwqI").then(()=>parcelRequire('elFwM'));
});
parcelRegister("acgaZ", function(module, exports) {
    $parcel$export(module.exports, "useJqlSearchStatus", ()=>$68036d2e3f2d9e10$export$6d36bd77a6aeafd9);
    var $0qvdT;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $jcw0u;
    var $6MtPV;
    var $koVbs;
    var $8zOmE;
    var $2NMNM;
    var $koeKL;
    var $4fmBV;
    const $68036d2e3f2d9e10$export$529d2e9d8e9d3cad = 2000;
    const $68036d2e3f2d9e10$export$bbec15ef0a4926b6 = 7000;
    const $68036d2e3f2d9e10$export$cf31b3fd6a57249f = 5;
    const $68036d2e3f2d9e10$export$2b91517d6d5b4365 = (0, (0, parcelRequire("0qvdT")).default);
    const $68036d2e3f2d9e10$var$statusToMessages = (status, formatMessage)=>{
        if (status == null || !('functions' in status) || !Array.isArray(status?.functions)) return [];
        const processing = status.functions.filter((fn)=>fn.app && fn.function && fn.status === 'PROCESSING');
        const functions = Array.from(new Set(processing.map((fn)=>fn.function)));
        const apps = Array.from(new Set(processing.map((fn)=>fn.app)));
        switch(functions.length){
            case 0:
                return [];
            case 1:
                {
                    const message = formatMessage((0, (0, parcelRequire("4fmBV")).default).singleSlowForgeFunction, {
                        functionName: functions[0],
                        appName: apps[0]
                    });
                    return [
                        {
                            type: 'info',
                            message: message
                        }
                    ];
                }
            default:
                {
                    let message;
                    switch(apps.length){
                        case 1:
                            message = formatMessage((0, (0, parcelRequire("4fmBV")).default).multipleSlowForgeFunctionsFromTheSameApp, {
                                appName: apps[0]
                            });
                            break;
                        case 2:
                            message = formatMessage((0, (0, parcelRequire("4fmBV")).default).multipleSlowForgeFunctionsTwoApps, {
                                appName1: apps[0],
                                appName2: apps[1]
                            });
                            break;
                        default:
                            message = formatMessage((0, (0, parcelRequire("4fmBV")).default).multipleSlowForgeFunctionsThreeApps, {
                                appName1: apps[0],
                                appName2: apps[1],
                                appName3: apps[2]
                            });
                    }
                    return [
                        {
                            type: 'info',
                            message: message
                        }
                    ];
                }
        }
    };
    const $68036d2e3f2d9e10$var$analyticsMeta = {
        id: 'jqlSearchStatus',
        packageName: 'jiraIssueNavigator',
        teamName: 'empanada'
    };
    const $68036d2e3f2d9e10$export$6d36bd77a6aeafd9 = ({ query: query, isSearching: isSearching })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const [statusMessages, setStatusMessages] = (0, $5uXOq.useState)([]);
        const isSearchingRef = (0, $5uXOq.useRef)(isSearching);
        const timeoutRef = (0, $5uXOq.useRef)(null);
        const fetchCountRef = (0, $5uXOq.useRef)(0);
        const environment = (0, $8Uumt.useRelayEnvironment)();
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const fireOperationalEvent = (0, $5uXOq.useCallback)((action, attributes)=>(0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), `${$68036d2e3f2d9e10$var$analyticsMeta.packageName}.${$68036d2e3f2d9e10$var$analyticsMeta.id} ${action}`, attributes), [
            createAnalyticsEvent
        ]);
        const cancelPendingOperations = (0, $5uXOq.useCallback)(()=>{
            timeoutRef.current && clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
            fetchCountRef.current = 0;
        }, []);
        const scheduleSearchStatusFetch = (0, $5uXOq.useCallback)((delay)=>{
            const isRetry = delay === $68036d2e3f2d9e10$export$bbec15ef0a4926b6;
            const defaultAnalyticsAttributes = (0, (0, parcelRequire("6MtPV")).createAttributes)({
                isRetry: isRetry
            });
            const timeout = setTimeout(()=>{
                if (fetchCountRef.current >= $68036d2e3f2d9e10$export$cf31b3fd6a57249f) {
                    fireOperationalEvent('interrupted', {
                        ...defaultAnalyticsAttributes,
                        reason: 'MAX_ATTEMPTS_REACHED'
                    });
                    return;
                }
                fireOperationalEvent('attempt', defaultAnalyticsAttributes);
                fetchCountRef.current += 1;
                (0, $8Uumt.fetchQuery)(environment, $68036d2e3f2d9e10$export$2b91517d6d5b4365, {
                    cloudId: cloudId,
                    jql: query
                }, {
                    fetchPolicy: 'network-only'
                }).toPromise().then((data)=>{
                    let hasPendingFn = false;
                    const status = data?.jira?.issueSearchStatus ?? null;
                    if (isSearchingRef.current && timeoutRef.current === timeout) {
                        const infos = $68036d2e3f2d9e10$var$statusToMessages(status, formatMessage);
                        setStatusMessages(infos);
                        if (infos.length) {
                            hasPendingFn = true;
                            scheduleSearchStatusFetch($68036d2e3f2d9e10$export$bbec15ef0a4926b6);
                        }
                    }
                    fireOperationalEvent('finished', {
                        ...defaultAnalyticsAttributes,
                        hasPendingFn: hasPendingFn
                    });
                }).catch((error)=>{
                    (0, (0, parcelRequire("jcw0u")).default)({
                        meta: $68036d2e3f2d9e10$var$analyticsMeta,
                        attributes: (0, (0, parcelRequire("6MtPV")).createAttributes)({
                            error: error,
                            isRetry: isRetry
                        })
                    });
                    if (isSearchingRef.current && timeoutRef.current === timeout) scheduleSearchStatusFetch($68036d2e3f2d9e10$export$bbec15ef0a4926b6);
                });
            }, delay);
            timeoutRef.current = timeout;
        }, [
            cloudId,
            environment,
            fireOperationalEvent,
            formatMessage,
            query
        ]);
        (0, $5uXOq.useEffect)(()=>{
            isSearchingRef.current = isSearching;
        }, [
            isSearching
        ]);
        (0, $5uXOq.useEffect)(()=>{
            setStatusMessages([]);
            cancelPendingOperations();
            if (isSearching) scheduleSearchStatusFetch($68036d2e3f2d9e10$export$529d2e9d8e9d3cad);
        }, [
            isSearching,
            query,
            cancelPendingOperations,
            scheduleSearchStatusFetch
        ]);
        return (0, $5uXOq.useMemo)(()=>({
                messages: statusMessages
            }), [
            statusMessages
        ]);
    };
});
parcelRegister("0qvdT", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$12b7df9e3f6f6313$export$2e2bcd8739ae039);
    const $12b7df9e3f6f6313$var$node = function() {
        var v0 = [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cloudId"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "jql"
            }
        ], v1 = [
            {
                "concreteType": "JiraQuery",
                "kind": "LinkedField",
                "name": "jira",
                "plural": false,
                "selections": [
                    {
                        "args": [
                            {
                                "kind": "Variable",
                                "name": "cloudId",
                                "variableName": "cloudId"
                            },
                            {
                                "kind": "Variable",
                                "name": "jql",
                                "variableName": "jql"
                            }
                        ],
                        "concreteType": "JiraIssueSearchStatus",
                        "kind": "LinkedField",
                        "name": "issueSearchStatus",
                        "plural": false,
                        "selections": [
                            {
                                "concreteType": "JiraJqlFunctionProcessingStatus",
                                "kind": "LinkedField",
                                "name": "functions",
                                "plural": true,
                                "selections": [
                                    {
                                        "kind": "ScalarField",
                                        "name": "status"
                                    },
                                    {
                                        "kind": "ScalarField",
                                        "name": "function"
                                    },
                                    {
                                        "kind": "ScalarField",
                                        "name": "app"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ];
        return {
            "fragment": {
                "argumentDefinitions": v0,
                "kind": "Fragment",
                "name": "jqlSearchStatus_Query",
                "selections": v1,
                "type": "Query"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": v0,
                "kind": "Operation",
                "name": "jqlSearchStatus_Query",
                "selections": v1
            },
            "params": {
                "id": "8e680ef9e6d909ed43bf02677fe95ccafcad565518b3a659febcd16aa4f5bbd8",
                "metadata": {},
                "name": "jqlSearchStatus_Query",
                "operationKind": "query",
                "text": null
            }
        };
    }();
    $12b7df9e3f6f6313$var$node.hash = "9dd1bed08ceb0d25df132f36097ae6d1";
    var $12b7df9e3f6f6313$export$2e2bcd8739ae039 = $12b7df9e3f6f6313$var$node;
});
parcelRegister("4fmBV", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$15682dd15c8b1e33$export$2e2bcd8739ae039);
    var $7VHMR;
    var $15682dd15c8b1e33$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        singleSlowForgeFunction: {
            "id": "issue-navigator.controllers.jql-search-status.single-slow-forge-function",
            "defaultMessage": "{appName} is still executing the {functionName} JQL function."
        },
        multipleSlowForgeFunctionsFromTheSameApp: {
            "id": "issue-navigator.controllers.jql-search-status.multiple-slow-forge-functions-from-the-same-app",
            "defaultMessage": "{appName} is still executing multiple JQL functions."
        },
        multipleSlowForgeFunctionsTwoApps: {
            "id": "issue-navigator.controllers.jql-search-status.multiple-slow-forge-functions-two-apps",
            "defaultMessage": "{appName1} and {appName2} are still executing multiple JQL functions."
        },
        multipleSlowForgeFunctionsThreeApps: {
            "id": "issue-navigator.controllers.jql-search-status.multiple-slow-forge-functions-three-apps",
            "defaultMessage": "{appName1}, {appName2}, and {appName3} are still executing multiple JQL functions."
        }
    });
});
parcelRegister("anxcG", function(module, exports) {
    $parcel$export(module.exports, "useJqlSearch", ()=>$34ef274676d00077$export$e69d0de2e354a2cb);
    var $5uXOq = parcelRequire("5uXOq");
    var $6s1PB;
    var $gwtSG;
    var $isIOR;
    var $38r5c;
    const $34ef274676d00077$export$e69d0de2e354a2cb = (changeJql)=>{
        const query = (0, (0, parcelRequire("gwtSG")).useResolvedJql)();
        let onRefetch;
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) ({ onRefetch: onRefetch } = (0, (0, parcelRequire("38r5c")).useIssueSearchActions)());
        else ({ onIssueSearch: onRefetch } = (0, (0, parcelRequire("isIOR")).useIssueSearchQuery)());
        const onSearch = (0, $5uXOq.useCallback)((jql)=>{
            if (jql === query) onRefetch();
            else changeJql && changeJql(jql);
        }, [
            query,
            onRefetch,
            changeJql
        ]);
        return {
            onSearch: onSearch,
            query: query
        };
    };
});
parcelRegister("bNJuB", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$f106025efda7e896$export$2e2bcd8739ae039);
    var $7VHMR;
    var $f106025efda7e896$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        saveFilterButton: {
            "id": "issue-navigator.jql-builder.save-filter-button",
            "defaultMessage": "Save filter"
        },
        saveFilterDialogTitle: {
            "id": "issue-navigator.jql-builder.save-filter-dialog-title",
            "defaultMessage": "Save filter"
        },
        copyFilterButton: {
            "id": "issue-navigator.jql-builder.copy-filter-button",
            "defaultMessage": "Copy filter"
        },
        copyFilterDialogTitle: {
            "id": "issue-navigator.jql-builder.copy-filter-dialog-title",
            "defaultMessage": "Copy filter"
        }
    });
});
parcelRegister("5aoL8", function(module, exports) {
    $parcel$export(module.exports, "NoOptionsMessage", ()=>$c1e8091431e2a9ec$export$d58b72b7d3c4072c);
    var $bwpsq;
    var $5uXOq = parcelRequire("5uXOq");
    var $dD7Zc;
    var $dC5iT;
    var $luBm6;
    var $cgL5K;
    var $8CUq3;
    var $koVbs;
    var $dCm1o;
    var $3EOg8;
    const $c1e8091431e2a9ec$var$IconContainer = ({ children: children })=>{
        if ((0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)()) return children;
        return (0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_otyru2gc _w8l5t9kd _1752t9kd"
            ])
        }, children);
    };
    const $c1e8091431e2a9ec$var$MessageContainer = ({ children: children })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        if ((0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)()) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("luBm6")).default), {
            space: "space.150",
            xcss: $c1e8091431e2a9ec$var$stackStyles,
            alignInline: "center"
        }, children, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
            color: "color.text"
        }, formatMessage((0, (0, parcelRequire("3EOg8")).default).noOptionsPrimaryMessage)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
            size: "small",
            color: "color.text.subtle"
        }, formatMessage((0, (0, parcelRequire("3EOg8")).default).noOptionsSecondaryMessage)));
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $c1e8091431e2a9ec$var$boxStyles
        }, children, (0, ($parcel$interopDefault($5uXOq))).createElement("p", {
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_18s861nu _1i4qfg65"
            ])
        }, formatMessage((0, (0, parcelRequire("3EOg8")).default).fieldNotFoundMessage)));
    };
    const $c1e8091431e2a9ec$export$d58b72b7d3c4072c = ()=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement($c1e8091431e2a9ec$var$MessageContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement($c1e8091431e2a9ec$var$IconContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("dD7Zc"))))), {
            label: "",
            color: "var(--ds-icon, #44546F)",
            LEGACY_primaryColor: `var(--ds-icon-subtle, ${(0, $829f609a65d26a98$export$f563ee71e9ee74cd)})`
        })));
    };
    const $c1e8091431e2a9ec$var$boxStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 'space.100',
        paddingLeft: 'space.250',
        paddingRight: 'space.250',
        paddingBottom: 'space.100',
        color: 'color.text.subtle',
        textAlign: 'center'
    });
    const $c1e8091431e2a9ec$var$stackStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        paddingBlock: 'space.150',
        paddingInline: 'space.100'
    });
    const $c1e8091431e2a9ec$var$titleStyles = null;
    const $c1e8091431e2a9ec$var$iconContainerStyles = null;
});
parcelRegister("3EOg8", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$1d1234d483fa7193$export$2e2bcd8739ae039);
    var $7VHMR;
    var $1d1234d483fa7193$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        fieldNotFoundMessage: {
            "id": "issue-navigator.jql-builder.no-options-message.field-not-found-message",
            "defaultMessage": "We couldn't find the field you are looking for."
        },
        noOptionsPrimaryMessage: {
            "id": "issue-navigator.jql-builder.no-options-message.no-options-primary-message",
            "defaultMessage": "We couldn't find what you're looking for"
        },
        noOptionsSecondaryMessage: {
            "id": "issue-navigator.jql-builder.no-options-message.no-options-secondary-message",
            "defaultMessage": "Try using different search terms."
        }
    });
});
parcelRegister("66jXs", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$8f283f726de8b644$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $fVnbM;
    var $koVbs;
    var $8zOmE;
    var $7HGp6;
    const $8f283f726de8b644$export$2491f201e3dd9acf = ({ onReset: onReset, isResetToFilter: isResetToFilter = false })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const handleOnReset = (0, $5uXOq.useCallback)((mouseEvent, analyticsEvent)=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, isResetToFilter ? 'goBackToFilterButton' : 'resetButton', {
                keyboard: mouseEvent.detail === 0
            });
            onReset && onReset(isResetToFilter);
        }, [
            onReset,
            isResetToFilter
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($8f283f726de8b644$var$ResetButtonContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            testId: "issue-navigator.ui.jql-builder.reset-button",
            appearance: "subtle-link",
            onClick: handleOnReset
        }, formatMessage(isResetToFilter ? (0, (0, parcelRequire("7HGp6")).default).goBackToFilterButtonText : (0, (0, parcelRequire("7HGp6")).default).resetButtonText)));
    };
    var $8f283f726de8b644$export$2e2bcd8739ae039 = $8f283f726de8b644$export$2491f201e3dd9acf;
    const $8f283f726de8b644$var$ResetButtonContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _16jlidpf _1o9zkb7n _i0dlf1ug _p12fuuw1 _4cvr1h6o _4t3izwfg _19pk1b66",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("7HGp6", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$0b65ac11d69a3064$export$2e2bcd8739ae039);
    var $7VHMR;
    var $0b65ac11d69a3064$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        resetButtonText: {
            "id": "issue-navigator.jql-builder.reset-button.reset-button-text",
            "defaultMessage": "Reset"
        },
        goBackToFilterButtonText: {
            "id": "issue-navigator.jql-builder.reset-button.go-back-to-filter-button-text",
            "defaultMessage": "Go back to filter"
        }
    });
});
parcelRegister("weR3I", function(module, exports) {
    $parcel$export(module.exports, "useJqlBuilderInstrumentation", ()=>$83b99282af44666a$export$42691712ca1334f9);
    var $5uXOq = parcelRequire("5uXOq");
    var $km2nW;
    var $a8lIB;
    const $83b99282af44666a$export$42691712ca1334f9 = ()=>{
        const [finished, setFinished] = (0, $5uXOq.useState)(false);
        return (0, $5uXOq.useMemo)(()=>({
                startJqlBuilderMetric: ()=>{
                    if (finished) return;
                    (0, (0, parcelRequire("a8lIB")).NINJqlBuilderAsyncLoad).start();
                },
                stopJqlBuilderMetric: ()=>{
                    setFinished(true);
                    (0, (0, parcelRequire("a8lIB")).NINJqlBuilderAsyncLoad).stop({
                        customData: {
                            ...(0, (0, parcelRequire("km2nW")).getIssueNavigatorFFAndFG)()
                        }
                    });
                },
                markJqlBuilderMetric: (mark)=>{
                    if (finished) return;
                    (0, (0, parcelRequire("a8lIB")).markOnce)(mark);
                }
            }), [
            finished
        ]);
    };
});
parcelRegister("km2nW", function(module, exports) {
    $parcel$export(module.exports, "getIssueNavigatorFFAndFG", ()=>$2bdea8c8e46e39fe$export$f94cc81069819a21);
    var $co1wz;
    var $4R6GH;
    const $2bdea8c8e46e39fe$export$f94cc81069819a21 = ()=>({
            isCohorted: (0, (0, parcelRequire("co1wz")).ff)('nin.global-scope_aqec8'),
            isNinExposeTextOption: (0, (0, parcelRequire("co1wz")).ff)('nin_expose_text_option_jql_builder_4kwzo'),
            isJscM1ApiUpdatesEnabled: (0, (0, parcelRequire("4R6GH")).fg)('jira_spreadsheet_component_m1_api_updates')
        });
});
parcelRegister("3iu8n", function(module, exports) {
    $parcel$export(module.exports, "isOnlyJqlAndDifferentToDefault", ()=>$6170d953a5f8d41c$export$3cfc4f52186efe7d);
    $parcel$export(module.exports, "isOnlyFilterAndDifferentToDefault", ()=>$6170d953a5f8d41c$export$933470cfeba275aa);
    $parcel$export(module.exports, "filterAndJqlIsTheSameAndDifferentToDefault", ()=>$6170d953a5f8d41c$export$a25fc74cc3fc7015);
    $parcel$export(module.exports, "jqlIsDifferentToFilter", ()=>$6170d953a5f8d41c$export$7dfcf59726e2b396);
    $parcel$export(module.exports, "isFilterJqlModified", ()=>$6170d953a5f8d41c$export$d3d543997ba807);
    const $6170d953a5f8d41c$export$3cfc4f52186efe7d = (jqlParam, defaultJqlTerm, filterParam)=>filterParam === undefined && jqlParam !== undefined && jqlParam.toLowerCase() !== defaultJqlTerm.toLowerCase();
    const $6170d953a5f8d41c$export$933470cfeba275aa = (jqlParam, defaultJqlTerm, filterJqlTerm)=>filterJqlTerm !== undefined && jqlParam === undefined && filterJqlTerm.toLowerCase() !== defaultJqlTerm.toLowerCase();
    const $6170d953a5f8d41c$export$a25fc74cc3fc7015 = (jqlParam, defaultJqlTerm, filterJqlTerm)=>jqlParam !== undefined && filterJqlTerm !== undefined && jqlParam.toLowerCase() === filterJqlTerm.toLowerCase() && filterJqlTerm.toLowerCase() !== defaultJqlTerm.toLowerCase();
    const $6170d953a5f8d41c$export$7dfcf59726e2b396 = (jqlParam, filterJqlTerm)=>jqlParam !== undefined && filterJqlTerm !== undefined && filterJqlTerm.toLowerCase() !== jqlParam.toLowerCase();
    const $6170d953a5f8d41c$export$d3d543997ba807 = ({ query: query, filterJql: filterJql })=>filterJql !== undefined && query.toLowerCase().trim() !== filterJql.toLowerCase().trim();
});
parcelRegister("7Cqtr", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$912451f7718e6cf5$export$2e2bcd8739ae039);
    var $7VHMR;
    var $912451f7718e6cf5$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        screenReaderSearchResultInfo: {
            "id": "issue-navigator.screen-reader-search-result-info",
            "defaultMessage": "{total, plural, one {One issue matches your search.} other {{total} issues match your search.}}"
        },
        screenReaderSearchResultMoreInfo: {
            "id": "issue-navigator.screen-reader-search-result-more-info",
            "defaultMessage": "{total, plural, one {{total} or more issues found} other {{total} or more issues found}}."
        },
        screenReaderSearchResultInfoIssueTermRefresh: {
            "id": "issue-navigator.screen-reader-search-result-info-issue-term-refresh",
            "defaultMessage": "{total, plural, one {One work item matches your search.} other {{total} work items match your search.}}"
        },
        screenReaderSearchResultMoreInfoIssueTermRefresh: {
            "id": "issue-navigator.screen-reader-search-result-more-info-issue-term-refresh",
            "defaultMessage": "{total, plural, one {{total} or more work items found} other {{total} or more work items found}}."
        }
    });
});
parcelRegister("5RTlC", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9f478b4b7c16dded$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $d8lE2;
    var $3Tty1;
    var $8zOmE;
    var $2NMNM;
    const $9f478b4b7c16dded$var$RegisterDotShortcut = ()=>{
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const { open: open } = (0, (0, parcelRequire("d8lE2")).useActionsCommandPalette)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3Tty1")).default), {
            keyMap: {
                '.': {
                    callback: ()=>{
                        (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                            action: 'pressed',
                            actionSubject: 'keyboardShortcut'
                        }), 'dotDialogKeyboardShortcut', {
                            keyPressed: '.',
                            keyboardShortcut: true
                        });
                        open();
                    }
                }
            }
        });
    };
    var $9f478b4b7c16dded$export$2e2bcd8739ae039 = $9f478b4b7c16dded$var$RegisterDotShortcut;
});
parcelRegister("d8lE2", function(module, exports) {
    $parcel$export(module.exports, "useCommandPalette", ()=>$f75514d959bfcca8$export$6b58f9610017fbb7);
    $parcel$export(module.exports, "useActionsCommandPalette", ()=>$f75514d959bfcca8$export$72aa7bc7c6a83c36);
    $parcel$export(module.exports, "useCommandPaletteAnalyticsAttributes", ()=>$f75514d959bfcca8$export$1f90c8f89f98110e);
    $parcel$export(module.exports, "useCommandPaletteIsOpen", ()=>$f75514d959bfcca8$export$cc979be9b69236df);
    $parcel$export(module.exports, "useCommandPaletteHasChildPopup", ()=>$f75514d959bfcca8$export$a18633b655403b12);
    $parcel$export(module.exports, "useCommandPaletteIsLoaded", ()=>$f75514d959bfcca8$export$af8fd8cd4b048315);
    $parcel$export(module.exports, "useCommandPaletteSessionId", ()=>$f75514d959bfcca8$export$7422170a403e909);
    $parcel$export(module.exports, "useCommandPaletteExpandedCommand", ()=>$f75514d959bfcca8$export$282aa48e4a3afada);
    $parcel$export(module.exports, "useCommandPaletteSearch", ()=>$f75514d959bfcca8$export$96819dfb22ad05d3);
    $parcel$export(module.exports, "useCommandPaletteRegistryCurrentAttribution", ()=>$f75514d959bfcca8$export$f893891deedab3c6);
    $parcel$export(module.exports, "useCommandPaletteUsePerformModeState", ()=>$f75514d959bfcca8$export$bbc05723eb144e03);
    var $5uXOq = parcelRequire("5uXOq");
    var $3sQ5x;
    var $6dDSs;
    var $iXqE5;
    var $gM5ou;
    var $2sRNe;
    const $f75514d959bfcca8$var$initialState = {
        commandPaletteSessionId: '',
        isOpen: false,
        isLoaded: false,
        search: '',
        activeIndex: 0,
        isSearchFieldFocused: false,
        commands: [],
        parentCommand: [],
        sections: [],
        isDisabled: false,
        registry: {},
        activeRegistry: {
            commandRegistry: {},
            registryIds: []
        },
        onClosePendingCommandAction: undefined,
        isLoadChildrenError: false
    };
    const $f75514d959bfcca8$var$store = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: $f75514d959bfcca8$var$initialState,
        actions: (0, parcelRequire("2sRNe")).actions,
        name: 'commandPalette'
    });
    const $f75514d959bfcca8$export$6b58f9610017fbb7 = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store);
    const $f75514d959bfcca8$export$72aa7bc7c6a83c36 = (0, (0, parcelRequire("3sQ5x")).createActionsHook)($f75514d959bfcca8$var$store);
    const $f75514d959bfcca8$export$e144a44b650635f4 = (ComponentToRender)=>(props)=>{
            const commandPalette = $f75514d959bfcca8$export$6b58f9610017fbb7();
            return (0, ($parcel$interopDefault($5uXOq))).createElement(ComponentToRender, {
                ...props,
                commandPalette: commandPalette
            });
        };
    const $f75514d959bfcca8$export$56f06313122d0aac = ({ search: search, commandPaletteSessionId: commandPaletteSessionId, expandedCommand: expandedCommand, registry: registry, activeRegistry: activeRegistry })=>({
            wordCount: (0, (0, parcelRequire("gM5ou")).getWordCount)(search),
            queryLength: search.length,
            queryHash: (0, (0, parcelRequire("gM5ou")).getQueryHash)(search),
            commandPaletteSessionId: commandPaletteSessionId,
            cpMenu: expandedCommand?.id || 'rootMenu',
            ...(0, (0, parcelRequire("gM5ou")).getExpandedCommandRegistryAnalytics)(registry, activeRegistry, expandedCommand?.id) || {},
            activeRegistryIds: activeRegistry.registryIds,
            activeRegistryIdsLength: activeRegistry.registryIds.length,
            activeRegistryCommandsLength: Object.keys(activeRegistry.commandRegistry).length,
            relevancyModel: 'v2-string-similarity'
        });
    const $f75514d959bfcca8$export$1f90c8f89f98110e = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: $f75514d959bfcca8$export$56f06313122d0aac
    });
    const $f75514d959bfcca8$export$cc979be9b69236df = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: ({ isOpen: isOpen })=>({
                isOpen: isOpen
            })
    });
    const $f75514d959bfcca8$export$a18633b655403b12 = (0, (0, parcelRequire("3sQ5x")).createStateHook)($f75514d959bfcca8$var$store, {
        selector: (state)=>state.hasPopupOverlay
    });
    const $f75514d959bfcca8$export$af8fd8cd4b048315 = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: ({ isLoaded: isLoaded })=>({
                isLoaded: isLoaded
            })
    });
    const $f75514d959bfcca8$export$4372697329bd9cbe = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: ({ isDisabled: isDisabled })=>({
                isDisabled: isDisabled
            })
    });
    const $f75514d959bfcca8$export$7422170a403e909 = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: ({ commandPaletteSessionId: commandPaletteSessionId })=>({
                commandPaletteSessionId: commandPaletteSessionId
            })
    });
    const $f75514d959bfcca8$export$282aa48e4a3afada = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: ({ expandedCommand: expandedCommand, isOpen: isOpen })=>({
                expandedCommand: expandedCommand,
                isOpen: isOpen
            })
    });
    const $f75514d959bfcca8$export$96819dfb22ad05d3 = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: ({ search: search })=>({
                search: search
            })
    });
    const $f75514d959bfcca8$export$a40e8ae3e53feab0 = (0, (0, parcelRequire("6dDSs")).createSelector)(({ registry: registry, activeRegistry: activeRegistry, expandedCommand: expandedCommand })=>({
            registry: registry,
            activeRegistry: activeRegistry,
            expandedCommand: expandedCommand
        }), ()=>undefined, ({ registry: registry, activeRegistry: activeRegistry, expandedCommand: expandedCommand })=>{
        if (!expandedCommand) return undefined;
        const registryData = (0, (0, parcelRequire("gM5ou")).getRegistryData)(registry, activeRegistry, expandedCommand.id);
        return {
            registryData: registryData,
            analyticsAttributes: registryData
        };
    });
    const $f75514d959bfcca8$export$f893891deedab3c6 = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: $f75514d959bfcca8$export$a40e8ae3e53feab0
    });
    const $f75514d959bfcca8$export$bbc05723eb144e03 = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: ({ commands: commands, search: search, expandedCommand: expandedCommand, childResult: childResult, registry: registry, activeRegistry: activeRegistry })=>({
                commands: commands,
                search: search,
                expandedCommand: expandedCommand,
                childResult: childResult,
                registry: registry,
                activeRegistry: activeRegistry
            })
    });
});
parcelRegister("gM5ou", function(module, exports) {
    $parcel$export(module.exports, "getRegistryData", ()=>$cf289af0c139712c$export$6a1ed481c04a3ae6);
    $parcel$export(module.exports, "getExpandedCommandRegistryAnalytics", ()=>$cf289af0c139712c$export$eb055284806da6e5);
    $parcel$export(module.exports, "getShortcutSpecialCharacters", ()=>$cf289af0c139712c$export$e6994b845b967732);
    $parcel$export(module.exports, "removeShortcutSpecialCharacters", ()=>$cf289af0c139712c$export$cd2a5827eae5f4cf);
    $parcel$export(module.exports, "mergeSections", ()=>$cf289af0c139712c$export$389b0273703d5c48);
    $parcel$export(module.exports, "mergeChildResults", ()=>$cf289af0c139712c$export$60a91e1b94ab4fef);
    $parcel$export(module.exports, "getAnalyticsAttributesFromState", ()=>$cf289af0c139712c$export$4023cd53e5e5fcb8);
    $parcel$export(module.exports, "isSecondaryTrigger", ()=>$cf289af0c139712c$export$daa5beabdf8d9fc3);
    $parcel$export(module.exports, "hasChildren", ()=>$cf289af0c139712c$export$bd5ff50f0e53d41c);
    $parcel$export(module.exports, "getAnalyticsAttributesFromResult", ()=>$cf289af0c139712c$export$bc9cd28438b3ea4b);
    $parcel$export(module.exports, "getQueryHash", ()=>$cf289af0c139712c$export$fa1c95a5ffa92036);
    $parcel$export(module.exports, "getWordCount", ()=>$cf289af0c139712c$export$dba3e2bcdbd49da4);
    $parcel$export(module.exports, "getCommandAction", ()=>$cf289af0c139712c$export$fb6dafa07e60c21a);
    $parcel$export(module.exports, "hasAction", ()=>$cf289af0c139712c$export$e217e69099d082f5);
    $parcel$export(module.exports, "useSetTimeoutAutoClear", ()=>$cf289af0c139712c$export$c3d20e8c1e93a572);
    var $5uXOq = parcelRequire("5uXOq");
    var $bdDiz = parcelRequire("bdDiz");
    var $2f2jJ;
    var $bcTvL;
    var $jh7L9;
    const $cf289af0c139712c$export$6a1ed481c04a3ae6 = (registry, activeRegistry, commandId)=>{
        if (!commandId) return undefined;
        const activeCommandRegistryData = activeRegistry?.commandRegistry?.[commandId];
        const registryData = activeCommandRegistryData?.registryId ? registry[activeCommandRegistryData?.registryId] : undefined;
        return registryData;
    };
    const $cf289af0c139712c$export$eb055284806da6e5 = (registry, activeRegistry, commandId)=>$cf289af0c139712c$export$6a1ed481c04a3ae6(registry, activeRegistry, commandId);
    const $cf289af0c139712c$export$8af60a6897157f84 = (registry, activeRegistry, parentCommands)=>{
        const parentCommandIds = parentCommands?.map((command)=>command.id);
        while(parentCommandIds?.length){
            const nextParentCommandId = parentCommandIds?.pop();
            const registryData = $cf289af0c139712c$export$6a1ed481c04a3ae6(registry, activeRegistry, nextParentCommandId);
            if (registryData) return registryData;
        }
        return undefined;
    };
    const $cf289af0c139712c$export$e6994b845b967732 = ()=>[
            {
                char: ' ',
                replacement: (0, (0, parcelRequire("bcTvL")).SHORTCUT_ELEMENT_TYPES).THEN
            },
            {
                char: '+',
                replacement: (0, (0, parcelRequire("bcTvL")).SHORTCUT_ELEMENT_TYPES).PLUS
            },
            {
                char: '|',
                replacement: (0, (0, parcelRequire("bcTvL")).SHORTCUT_ELEMENT_TYPES).OR
            }
        ];
    const $cf289af0c139712c$export$cd2a5827eae5f4cf = (shortcut)=>{
        const specialChars = $cf289af0c139712c$export$e6994b845b967732();
        let strippedShortcut = shortcut;
        specialChars.forEach(({ char: char })=>{
            strippedShortcut = strippedShortcut.split(char).join('');
        });
        return strippedShortcut.toLowerCase();
    };
    const $cf289af0c139712c$export$389b0273703d5c48 = (sections)=>sections.reduce((acc, curr)=>{
            Object.entries(curr).forEach(([key, entry])=>{
                acc[key] = entry;
            });
            return acc;
        }, {});
    const $cf289af0c139712c$export$60a91e1b94ab4fef = (childResults)=>{
        const commands = [];
        const sections = [];
        childResults.forEach((childResult)=>{
            commands.push(...childResult.commands);
            if (childResult.sections) sections.push(childResult.sections);
        });
        return {
            commands: commands,
            sections: $cf289af0c139712c$export$389b0273703d5c48(sections)
        };
    };
    const $cf289af0c139712c$export$ae8f55d2b67cb8d6 = (text, commonWordsToBeRemoved)=>{
        const commonWords = commonWordsToBeRemoved.toLowerCase().replace(/\s{2,}/g, ' ').trim().split(' ');
        const regexString = commonWords.map((word)=>`\\b${word}\\b`).join('|');
        const regex = new RegExp(regexString, 'g');
        return text.replace(/[.,]/g, '').replace(regex, '').replace(/\s{2,}/g, ' ').trim();
    };
    const $cf289af0c139712c$export$4023cd53e5e5fcb8 = (state, command, method = 'undefined', wasSecondaryTriggered, attributes)=>{
        const analyticsAttributes = {
            commandPaletteId: command.id,
            commandPaletteSessionId: state.commandPaletteSessionId,
            searchTaken: !!state.search,
            method: method,
            cpMenu: 'rootMenu',
            action: command.id,
            actionCategory: 'none',
            wasSecondaryTriggered: wasSecondaryTriggered,
            ...$cf289af0c139712c$export$8af60a6897157f84(state.registry, state.activeRegistry, command.parentCommands) || {},
            ...$cf289af0c139712c$export$6a1ed481c04a3ae6(state.registry, state.activeRegistry, command.id) || {},
            ...$cf289af0c139712c$export$eb055284806da6e5(state.registry, state.activeRegistry, state.expandedCommand?.id) || {},
            ...attributes,
            ...!!command.analytics && command.analytics
        };
        if (state.expandedCommand?.analytics) analyticsAttributes.cpMenu = state.expandedCommand.analytics.action;
        else if (state.expandedCommand?.id) analyticsAttributes.cpMenu = state.expandedCommand.id;
        const sections = state.expandedCommand && state.childResult?.sections ? $cf289af0c139712c$export$389b0273703d5c48([
            ...state.sections,
            state.childResult?.sections
        ]) : $cf289af0c139712c$export$389b0273703d5c48(state.sections);
        const rootCommand = state.parentCommand?.[0] || state.expandedCommand || command;
        const rootSection = rootCommand.section ? sections?.[rootCommand.section] : undefined;
        analyticsAttributes.actionCategory = rootSection?.actionCategory || rootCommand?.section || rootCommand?.analytics?.actionCategory || (command?.section ? sections?.[command?.section]?.actionCategory || command?.section : undefined) || analyticsAttributes.actionCategory;
        return analyticsAttributes;
    };
    const $cf289af0c139712c$export$daa5beabdf8d9fc3 = (event)=>(0, (0, parcelRequire("2f2jJ")).isMacOs)() ? event.metaKey : event.ctrlKey;
    const $cf289af0c139712c$export$bd5ff50f0e53d41c = (command)=>{
        if (command?.wasSecondaryTriggered) {
            if (command?.secondaryAction?.type) {
                const hasSecondaryChildren = (0, (0, parcelRequire("jh7L9")).COMMAND_WITH_CHILDREN).some((commandType)=>commandType === command?.secondaryAction?.type);
                return hasSecondaryChildren;
            }
            return (0, (0, parcelRequire("jh7L9")).COMMAND_WITH_CHILDREN).some((commandType)=>commandType === command?.primaryAction?.type);
        }
        return (0, (0, parcelRequire("jh7L9")).COMMAND_WITH_CHILDREN).some((commandType)=>commandType === command?.primaryAction?.type);
    };
    const $cf289af0c139712c$export$bc9cd28438b3ea4b = (result)=>{
        if (result) return {
            globalIndex: result.index,
            sectionIndex: result.sectionIndex,
            indexWithinSection: result.indexWithinSection,
            sectionId: result.sectionId
        };
        return {};
    };
    const $cf289af0c139712c$export$fa1c95a5ffa92036 = (str)=>(0, $bdDiz.createHash)().update(str).digest('hex');
    const $cf289af0c139712c$export$dba3e2bcdbd49da4 = (str)=>str.length > 0 ? str.split(/\s+/).length : 0;
    const $cf289af0c139712c$export$fb6dafa07e60c21a = (command, wasSecondaryTriggered)=>{
        if (!command) return null;
        const { secondaryAction: secondaryAction, primaryAction: primaryAction } = command;
        if (!primaryAction) return null;
        const commandAction = wasSecondaryTriggered && secondaryAction ? secondaryAction : primaryAction;
        return {
            ...commandAction
        };
    };
    const $cf289af0c139712c$export$e217e69099d082f5 = (command)=>Boolean(command.primaryAction || command.secondaryAction);
    const $cf289af0c139712c$export$c3d20e8c1e93a572 = ()=>{
        const timeoutRef = (0, $5uXOq.useRef)();
        (0, $5uXOq.useEffect)(()=>()=>{
                if (timeoutRef.current) clearTimeout(timeoutRef.current);
            }, []);
        const setTimeoutAutoClear = (...args)=>{
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(...args);
        };
        return {
            setTimeoutAutoClear: setTimeoutAutoClear
        };
    };
});
parcelRegister("2f2jJ", function(module, exports) {
    $parcel$export(module.exports, "isMacOs", ()=>$6846128323867ebd$export$c5e71f489eb3970b);
    $parcel$export(module.exports, "getShortcutModifierKey", ()=>$6846128323867ebd$export$fe66dae91c68619b);
    const $6846128323867ebd$export$c5e71f489eb3970b = ()=>/Mac/i.test(window?.navigator?.platform);
    const $6846128323867ebd$export$fe66dae91c68619b = ()=>$6846128323867ebd$export$c5e71f489eb3970b() ? 'meta' : 'ctrl';
});
parcelRegister("bcTvL", function(module, exports) {
    $parcel$export(module.exports, "UI_EVENT_TYPE", ()=>$3996a4302dca83b1$export$a1562d08625f5d7e);
    $parcel$export(module.exports, "TRACK_EVENT_TYPE", ()=>$3996a4302dca83b1$export$947dac6c72b26f81);
    $parcel$export(module.exports, "SHORTCUT_ELEMENT_TYPES", ()=>$3996a4302dca83b1$export$1ee9d153576afd13);
    $parcel$export(module.exports, "COMMAND_PALETTE_DEBOUNCE_DEFAULT", ()=>$3996a4302dca83b1$export$894e19fc96687503);
    const $3996a4302dca83b1$export$a1562d08625f5d7e = 'UI';
    const $3996a4302dca83b1$export$947dac6c72b26f81 = 'TRACK';
    const $3996a4302dca83b1$export$f1d537d508d2945a = 'SCREEN';
    const $3996a4302dca83b1$export$12346a674644c156 = 'OPERATIONAL';
    var $3996a4302dca83b1$export$1ee9d153576afd13;
    (function(SHORTCUT_ELEMENT_TYPES) {
        SHORTCUT_ELEMENT_TYPES["THEN"] = "then";
        SHORTCUT_ELEMENT_TYPES["OR"] = "or";
        SHORTCUT_ELEMENT_TYPES["PLUS"] = "plus";
        SHORTCUT_ELEMENT_TYPES["KEY"] = "key";
    })($3996a4302dca83b1$export$1ee9d153576afd13 || ($3996a4302dca83b1$export$1ee9d153576afd13 = {}));
    const $3996a4302dca83b1$export$894e19fc96687503 = 250;
});
parcelRegister("jh7L9", function(module, exports) {
    $parcel$export(module.exports, "CommandActionType", ()=>$b1e48ebd4c8d5e4e$export$f041b1b117ab382f);
    $parcel$export(module.exports, "COMMAND_WITH_CHILDREN", ()=>$b1e48ebd4c8d5e4e$export$904b2a0e38a1bef3);
    var $b1e48ebd4c8d5e4e$export$f041b1b117ab382f;
    (function(CommandActionType) {
        CommandActionType["PERFORM"] = "perform";
        CommandActionType["COMMAND_LIST"] = "command-list";
    })($b1e48ebd4c8d5e4e$export$f041b1b117ab382f || ($b1e48ebd4c8d5e4e$export$f041b1b117ab382f = {}));
    const $b1e48ebd4c8d5e4e$export$3e448fd53031e13c = Object.values($b1e48ebd4c8d5e4e$export$f041b1b117ab382f);
    const $b1e48ebd4c8d5e4e$export$904b2a0e38a1bef3 = [
        "command-list"
    ];
});
parcelRegister("2sRNe", function(module, exports) {
    $parcel$export(module.exports, "actions", ()=>$4f4c9e92d50d4f24$export$e324594224ef24da);
    var $kGsr8 = parcelRequire("kGsr8");
    var $7Jxgt = parcelRequire("7Jxgt");
    var $e7WVN;
    var $eTXmK;
    var $2f2jJ;
    var $jcw0u;
    var $bcTvL;
    var $jh7L9;
    var $gM5ou;
    var $4DwnT;
    var $h3eZO;
    const $4f4c9e92d50d4f24$var$rootCommands = {
        parentCommand: [],
        expandedCommand: undefined,
        childCommands: undefined,
        childResult: undefined
    };
    const $4f4c9e92d50d4f24$var$open = ()=>({ setState: setState, getState: getState })=>{
            if (getState().isDisabled) return;
            setState({
                commandPaletteSessionId: (0, ($parcel$interopDefault($7Jxgt))).v4(),
                isOpen: true,
                activeIndex: 0,
                search: ''
            });
        };
    const $4f4c9e92d50d4f24$var$close = ()=>({ setState: setState })=>{
            setState({
                isOpen: false,
                commandPaletteSessionId: '',
                search: '',
                ...$4f4c9e92d50d4f24$var$rootCommands
            });
        };
    const $4f4c9e92d50d4f24$var$toggle = ()=>({ setState: setState, getState: getState })=>{
            const isOpen = !getState().isOpen;
            setState({
                isOpen: isOpen,
                activeIndex: 0,
                search: '',
                commandPaletteSessionId: isOpen ? (0, ($parcel$interopDefault($7Jxgt))).v4() : '',
                ...!isOpen ? $4f4c9e92d50d4f24$var$rootCommands : {}
            });
        };
    const $4f4c9e92d50d4f24$var$addCommands = (commands)=>({ setState: setState, getState: getState })=>{
            const currentState = getState();
            const newCommands = currentState.commands.concat(commands);
            const newState = {
                commands: newCommands
            };
            if (currentState.expandedCommand) {
                const matchedExpandedCommand = commands.filter((command)=>command.id === currentState.expandedCommand?.id);
                if (matchedExpandedCommand.length === 1) {
                    const [updatedExpandedCommand] = matchedExpandedCommand;
                    newState.expandedCommand = updatedExpandedCommand;
                }
            }
            setState(newState);
        };
    const $4f4c9e92d50d4f24$var$removeCommands = (commands)=>({ setState: setState, getState: getState })=>{
            const newCommands = getState().commands.filter((command)=>!commands.includes(command));
            setState({
                commands: newCommands
            });
        };
    const $4f4c9e92d50d4f24$var$removeCommandsById = (commandIds)=>({ setState: setState, getState: getState })=>{
            const newCommands = getState().commands.filter((command)=>!commandIds.includes(command.id));
            setState({
                commands: newCommands
            });
        };
    const $4f4c9e92d50d4f24$var$handleSearch = (search)=>({ setState: setState })=>{
            setState({
                search: search,
                activeIndex: 0
            });
        };
    const $4f4c9e92d50d4f24$var$forceSearchValue = (search)=>({ setState: setState })=>{
            setState({
                search: search,
                shouldForceSearchValue: true
            });
        };
    const $4f4c9e92d50d4f24$var$resetShouldForceSearchValue = ()=>({ setState: setState })=>{
            setState({
                shouldForceSearchValue: false
            });
        };
    const $4f4c9e92d50d4f24$var$goBackLevel = (goToRoot, analytics)=>({ setState: setState, getState: getState })=>{
            const currentState = getState();
            if (!currentState.expandedCommand) return;
            const newState = {
                search: '',
                activeIndex: 0,
                childResult: undefined,
                forceSearchFocus: true
            };
            if (currentState.parentCommand.length && !goToRoot) {
                newState.expandedCommand = currentState.parentCommand[currentState.parentCommand.length - 1];
                newState.parentCommand = currentState.parentCommand.slice(0, currentState.parentCommand.length - 1);
                if (newState.expandedCommand?.primaryAction?.type === (0, (0, parcelRequire("jh7L9")).CommandActionType).COMMAND_LIST && newState.expandedCommand?.primaryAction?.commands) newState.childResult = {
                    commands: newState.expandedCommand?.primaryAction?.commands,
                    sections: newState.expandedCommand?.primaryAction?.sections
                };
            } else {
                newState.loadingList = false;
                newState.parentCommand = [];
                newState.expandedCommand = undefined;
                newState.childResult = undefined;
            }
            if (currentState?.onAnalytics && analytics?.createAnalyticsEvent) currentState.onAnalytics(analytics.createAnalyticsEvent({
                action: 'navigation',
                actionSubject: 'commandPalette'
            }), (0, (0, parcelRequire("bcTvL")).TRACK_EVENT_TYPE), {
                attributes: {
                    cpMenu: currentState.expandedCommand?.id || 'rootMenu',
                    searchSessionId: currentState.commandPaletteSessionId,
                    action: goToRoot ? 'backToRoot' : 'backToParent',
                    actionCategory: 'back',
                    method: analytics?.method
                }
            });
            setState(newState);
        };
    const $4f4c9e92d50d4f24$var$forceFocusSearch = (forceSearchFocus = false)=>({ setState: setState })=>{
            setState({
                forceSearchFocus: forceSearchFocus
            });
        };
    const $4f4c9e92d50d4f24$var$setActiveIndex = (index)=>({ setState: setState, getState: getState })=>{
            const { isSearchFieldFocused: isSearchFieldFocused } = getState();
            setState({
                activeIndex: index,
                ...!isSearchFieldFocused ? {
                    forceSearchFocus: true
                } : {}
            });
        };
    const $4f4c9e92d50d4f24$var$getAnalyticsAttributes = (command, method, wasSecondaryTriggered = false, attributes = {})=>({ getState: getState })=>(0, (0, parcelRequire("gM5ou")).getAnalyticsAttributesFromState)(getState(), command, method, wasSecondaryTriggered, attributes);
    const $4f4c9e92d50d4f24$var$performDialogCloseCommand = ()=>({ setState: setState, getState: getState })=>{
            setTimeout(()=>{
                const { onClosePendingCommandAction: onClosePendingCommandAction, onAnalytics: onAnalytics } = getState();
                if (onClosePendingCommandAction !== undefined) {
                    try {
                        (0, (0, parcelRequire("eTXmK")).default)('command-palette-executed');
                        onClosePendingCommandAction.perform();
                        if (onAnalytics && onClosePendingCommandAction?.createAnalyticsEvent) {
                            const performAnalyticsEvent = onClosePendingCommandAction?.createAnalyticsEvent({
                                action: 'taskSucceeded',
                                actionSubject: 'commandPalette'
                            });
                            onAnalytics(performAnalyticsEvent, (0, (0, parcelRequire("bcTvL")).TRACK_EVENT_TYPE), {
                                attributes: onClosePendingCommandAction?.analyticsArgs
                            });
                        }
                    } catch (err) {
                        const error = err instanceof Error ? err : new Error('Command palette perform error');
                        (0, (0, parcelRequire("jcw0u")).default)({
                            error: error,
                            meta: {
                                id: 'compal.performDialogCloseCommand',
                                packageName: 'jiraCommandPalette',
                                teamName: typeof onClosePendingCommandAction?.analyticsArgs?.teamName === 'string' ? onClosePendingCommandAction?.analyticsArgs.teamName : 'deliveroo'
                            },
                            attributes: onClosePendingCommandAction.analyticsArgs
                        });
                        (0, (0, parcelRequire("e7WVN")).setInteractionError)('command-palette-executed', {
                            errorMessage: error.message,
                            name: 'performDialogCloseCommandPerformError'
                        });
                    }
                    setState({
                        onClosePendingCommandAction: undefined
                    });
                }
            }, 0);
        };
    const $4f4c9e92d50d4f24$var$executeCommand = (command, analytics, wasSecondaryTriggered = false)=>({ setState: setState, getState: getState })=>{
            const currentState = getState();
            const newState = {
                ...!(0, (0, parcelRequire("4DwnT")).getActiveCommandList)(command, wasSecondaryTriggered)?.keepSearch && {
                    search: ''
                },
                activeIndex: 0
            };
            const analyticsAttributes = (0, (0, parcelRequire("gM5ou")).getAnalyticsAttributesFromState)(currentState, command, analytics?.method || 'undefined', wasSecondaryTriggered, analytics?.attributes);
            const currentCommandAction = (0, (0, parcelRequire("gM5ou")).getCommandAction)(command, wasSecondaryTriggered);
            if (!currentCommandAction) return;
            if (currentState?.onAnalytics && analytics?.createAnalyticsEvent) {
                const analyticsEvent = analytics.createAnalyticsEvent({
                    action: 'navigation',
                    actionSubject: 'commandPalette'
                });
                currentState.onAnalytics(analyticsEvent, (0, (0, parcelRequire("bcTvL")).TRACK_EVENT_TYPE), {
                    attributes: analyticsAttributes
                });
            }
            const shouldLoadChildren = (0, (0, parcelRequire("gM5ou")).hasChildren)({
                ...command,
                wasSecondaryTriggered: wasSecondaryTriggered
            });
            const shouldExecutePerform = currentCommandAction?.type === (0, (0, parcelRequire("jh7L9")).CommandActionType).PERFORM;
            if (shouldLoadChildren) {
                (0, (0, parcelRequire("eTXmK")).default)('command-palette-load-child-command-list');
                if (currentState.expandedCommand) newState.parentCommand = [
                    ...currentState.parentCommand,
                    currentState.expandedCommand
                ];
                newState.expandedCommand = {
                    ...command,
                    wasSecondaryTriggered: wasSecondaryTriggered
                };
                newState.childResult = undefined;
                newState.forceSearchFocus = true;
                if ((0, (0, parcelRequire("h3eZO")).isSearchableChildrenAction)(currentCommandAction)) {
                    newState.childResult = {
                        commands: currentCommandAction.commands,
                        sections: currentCommandAction.sections
                    };
                    if (command.parentCommands?.length) newState.parentCommand = [
                        ...newState.parentCommand || [],
                        ...command.parentCommands
                    ];
                } else newState.childResult = undefined;
                setState(newState);
                if (currentCommandAction.type === (0, (0, parcelRequire("jh7L9")).CommandActionType).COMMAND_LIST && currentCommandAction.onClick) currentCommandAction.onClick();
            } else if (shouldExecutePerform) {
                if (!currentCommandAction.preventModalClose) {
                    newState.commandPaletteSessionId = '';
                    newState.isOpen = false;
                    newState.expandedCommand = undefined;
                    newState.parentCommand = [];
                    newState.childResult = undefined;
                    setState(newState);
                    if (currentState.onAnalytics && analytics?.createAnalyticsEvent) {
                        const closeAnalyticsEvent = analytics.createAnalyticsEvent({
                            action: 'closed',
                            actionSubject: 'commandPalette'
                        });
                        currentState.onAnalytics(closeAnalyticsEvent, (0, (0, parcelRequire("bcTvL")).UI_EVENT_TYPE), {
                            attributes: analyticsAttributes
                        });
                    }
                }
                if (wasSecondaryTriggered && currentState.onAnalytics && analytics?.createAnalyticsEvent) {
                    const closeAnalyticsEvent = analytics.createAnalyticsEvent({
                        action: 'secondaryAction',
                        actionSubject: 'commandPalette'
                    });
                    currentState.onAnalytics(closeAnalyticsEvent, (0, (0, parcelRequire("bcTvL")).TRACK_EVENT_TYPE), {
                        attributes: {
                            ...analyticsAttributes,
                            keyPressed: (0, (0, parcelRequire("2f2jJ")).isMacOs)() ? 'cmdEnter' : 'ctrlEnter',
                            ...currentCommandAction.analyticsArgs
                        }
                    });
                }
                if (currentCommandAction.preventModalClose) try {
                    (0, (0, parcelRequire("eTXmK")).default)('command-palette-executed');
                    currentCommandAction.perform();
                    if (currentState.onAnalytics && analytics?.createAnalyticsEvent) {
                        const performAnalyticsEvent = analytics.createAnalyticsEvent({
                            action: 'taskSucceeded',
                            actionSubject: 'commandPalette'
                        });
                        currentState.onAnalytics(performAnalyticsEvent, (0, (0, parcelRequire("bcTvL")).TRACK_EVENT_TYPE), {
                            attributes: analyticsAttributes
                        });
                    }
                } catch (err) {
                    const error = err instanceof Error ? err : new Error('Command palette perform error');
                    (0, (0, parcelRequire("jcw0u")).default)({
                        error: error,
                        meta: {
                            id: 'compal.executeCommand',
                            packageName: 'jiraCommandPalette',
                            teamName: typeof analytics?.attributes?.teamName === 'string' ? analytics?.attributes.teamName : 'deliveroo'
                        },
                        attributes: analytics?.attributes
                    });
                    (0, (0, parcelRequire("e7WVN")).setInteractionError)('command-palette-executed', {
                        errorMessage: error.message,
                        name: 'executeCommandPerformError'
                    });
                }
                else setState({
                    onClosePendingCommandAction: {
                        ...currentCommandAction,
                        analyticsArgs: analyticsAttributes,
                        createAnalyticsEvent: analytics?.createAnalyticsEvent
                    }
                });
            }
        };
    const $4f4c9e92d50d4f24$var$setChildCommands = (childResult)=>({ setState: setState })=>{
            setState({
                childResult: childResult
            });
        };
    const $4f4c9e92d50d4f24$var$setLoadingList = (loadingList)=>({ setState: setState })=>{
            setState({
                loadingList: loadingList
            });
        };
    const $4f4c9e92d50d4f24$var$setFocusInput = (isSearchFieldFocused)=>({ setState: setState })=>setState({
                isSearchFieldFocused: isSearchFieldFocused
            });
    const $4f4c9e92d50d4f24$var$registerSections = (newSections)=>({ setState: setState, getState: getState })=>{
            const currentState = getState();
            const sections = currentState.sections.slice(0);
            sections.push(newSections);
            setState({
                sections: sections
            });
        };
    const $4f4c9e92d50d4f24$var$unregisterSections = (newSections)=>({ setState: setState, getState: getState })=>{
            const currentState = getState();
            const sections = currentState.sections.filter((section)=>section !== newSections);
            setState({
                sections: sections
            });
        };
    const $4f4c9e92d50d4f24$var$markAsLoaded = ()=>({ setState: setState })=>{
            setState({
                isLoaded: true
            });
        };
    const $4f4c9e92d50d4f24$var$setIsDisabled = (isDisabled)=>({ setState: setState })=>{
            setState({
                isDisabled: isDisabled,
                ...isDisabled ? {
                    isOpen: false
                } : {}
            });
        };
    const $4f4c9e92d50d4f24$var$setRegistry = (registry)=>({ setState: setState })=>{
            setState({
                registry: registry
            });
        };
    const $4f4c9e92d50d4f24$var$updateActiveRegistry = (registryId, commands)=>({ setState: setState, getState: getState })=>{
            const currentActiveRegistry = getState().activeRegistry;
            const activeRegistry = {
                commandRegistry: {
                    ...currentActiveRegistry.commandRegistry,
                    ...commands.reduce((acc, command)=>{
                        acc[command.id] = {
                            registryId: registryId
                        };
                        return acc;
                    }, {})
                },
                registryIds: (0, ($parcel$interopDefault($kGsr8)))([
                    ...currentActiveRegistry.registryIds,
                    registryId
                ])
            };
            setState({
                activeRegistry: activeRegistry
            });
        };
    const $4f4c9e92d50d4f24$var$removeFromActiveRegistry = (registryId, commands)=>({ setState: setState, getState: getState })=>{
            const currentActiveRegistry = getState().activeRegistry;
            const commandRegistry = {
                ...currentActiveRegistry.commandRegistry
            };
            commands.forEach((command)=>{
                delete commandRegistry[command.id];
            });
            setState({
                activeRegistry: {
                    commandRegistry: commandRegistry,
                    registryIds: currentActiveRegistry.registryIds.filter((id)=>id !== registryId)
                }
            });
        };
    const $4f4c9e92d50d4f24$var$setAnalytics = (onAnalytics, onErrorAnalytics)=>({ setState: setState })=>{
            setState({
                onAnalytics: onAnalytics,
                onErrorAnalytics: onErrorAnalytics
            });
        };
    const $4f4c9e92d50d4f24$var$setFallbackOverride = (fallbackOverride)=>({ setState: setState })=>{
            setState({
                fallbackOverride: fallbackOverride
            });
        };
    const $4f4c9e92d50d4f24$var$setHasPopupOverlay = (hasPopupOverlay)=>({ setState: setState })=>{
            setState({
                hasPopupOverlay: hasPopupOverlay
            });
        };
    const $4f4c9e92d50d4f24$export$e324594224ef24da = {
        open: $4f4c9e92d50d4f24$var$open,
        toggle: $4f4c9e92d50d4f24$var$toggle,
        close: $4f4c9e92d50d4f24$var$close,
        addCommands: $4f4c9e92d50d4f24$var$addCommands,
        removeCommands: $4f4c9e92d50d4f24$var$removeCommands,
        handleSearch: $4f4c9e92d50d4f24$var$handleSearch,
        forceFocusSearch: $4f4c9e92d50d4f24$var$forceFocusSearch,
        setActiveIndex: $4f4c9e92d50d4f24$var$setActiveIndex,
        executeCommand: $4f4c9e92d50d4f24$var$executeCommand,
        goBackLevel: $4f4c9e92d50d4f24$var$goBackLevel,
        setChildCommands: $4f4c9e92d50d4f24$var$setChildCommands,
        setLoadingList: $4f4c9e92d50d4f24$var$setLoadingList,
        setFocusInput: $4f4c9e92d50d4f24$var$setFocusInput,
        registerSections: $4f4c9e92d50d4f24$var$registerSections,
        unregisterSections: $4f4c9e92d50d4f24$var$unregisterSections,
        markAsLoaded: $4f4c9e92d50d4f24$var$markAsLoaded,
        getAnalyticsAttributes: $4f4c9e92d50d4f24$var$getAnalyticsAttributes,
        setIsDisabled: $4f4c9e92d50d4f24$var$setIsDisabled,
        setRegistry: $4f4c9e92d50d4f24$var$setRegistry,
        updateActiveRegistry: $4f4c9e92d50d4f24$var$updateActiveRegistry,
        removeCommandsById: $4f4c9e92d50d4f24$var$removeCommandsById,
        removeFromActiveRegistry: $4f4c9e92d50d4f24$var$removeFromActiveRegistry,
        performDialogCloseCommand: $4f4c9e92d50d4f24$var$performDialogCloseCommand,
        setAnalytics: $4f4c9e92d50d4f24$var$setAnalytics,
        setFallbackOverride: $4f4c9e92d50d4f24$var$setFallbackOverride,
        setHasPopupOverlay: $4f4c9e92d50d4f24$var$setHasPopupOverlay,
        forceSearchValue: $4f4c9e92d50d4f24$var$forceSearchValue,
        resetShouldForceSearchValue: $4f4c9e92d50d4f24$var$resetShouldForceSearchValue
    };
});
parcelRegister("4DwnT", function(module, exports) {
    $parcel$export(module.exports, "getActiveCommandList", ()=>$b4088022dc3f5c54$export$639b7f41b0b47a01);
    var $jh7L9;
    const $b4088022dc3f5c54$export$639b7f41b0b47a01 = (expandedCommand, wasSecondaryTriggered)=>{
        if (wasSecondaryTriggered && expandedCommand?.secondaryAction?.type === (0, (0, parcelRequire("jh7L9")).CommandActionType).COMMAND_LIST) return expandedCommand.secondaryAction;
        if (!wasSecondaryTriggered && expandedCommand?.primaryAction?.type === (0, (0, parcelRequire("jh7L9")).CommandActionType).COMMAND_LIST) return expandedCommand.primaryAction;
        return null;
    };
});
parcelRegister("h3eZO", function(module, exports) {
    $parcel$export(module.exports, "isSearchableChildrenAction", ()=>$dd5db8363c7eeda7$export$d75616d77443fe4e);
    var $jh7L9;
    const $dd5db8363c7eeda7$export$d75616d77443fe4e = (action)=>action?.type === (0, (0, parcelRequire("jh7L9")).CommandActionType).COMMAND_LIST && action.commands !== undefined;
});
parcelRegister("6tXCF", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$7541b9a37d8ce6a5$export$2e2bcd8739ae039);
    var $gPAgh;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $5Czte;
    var $atxn1;
    var $eJ0Zs;
    var $ih9zY;
    const $7541b9a37d8ce6a5$var$IssueNavigatorSpotlight = ({ hasIssueResults: hasIssueResults, userPreferences: userPreferences, isAiEnabled: isAiEnabled })=>{
        const [{ view: view }] = (0, (0, parcelRequire("ih9zY")).useSelectedViewState)();
        const searchModeResponse = (0, $8Uumt.useFragment)((0, (0, parcelRequire("gPAgh")).default), userPreferences);
        const selectedSearchMode = (0, (0, parcelRequire("atxn1")).toSearchModeWithBasicModeFallback)(searchModeResponse?.jqlBuilderSearchMode);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5Czte")).NinChangeboardingTourCard), {
            mode: selectedSearchMode,
            view: (0, (0, parcelRequire("eJ0Zs")).convertToView)(view),
            hasIssueResults: hasIssueResults,
            isAiEnabled: isAiEnabled
        });
    };
    var $7541b9a37d8ce6a5$export$2e2bcd8739ae039 = $7541b9a37d8ce6a5$var$IssueNavigatorSpotlight;
});
parcelRegister("gPAgh", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$74e7fcdf8e98c48e$export$2e2bcd8739ae039);
    const $74e7fcdf8e98c48e$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "spotlight_issueNavigator_IssueNavigatorSpotlight",
        "selections": [
            {
                "kind": "ScalarField",
                "name": "jqlBuilderSearchMode"
            }
        ],
        "type": "JiraUserPreferences"
    };
    $74e7fcdf8e98c48e$var$node.hash = "3c8e72948beb901cccd7a6650610b18e";
    var $74e7fcdf8e98c48e$export$2e2bcd8739ae039 = $74e7fcdf8e98c48e$var$node;
});
parcelRegister("atxn1", function(module, exports) {
    $parcel$export(module.exports, "toSearchModeWithBasicModeFallback", ()=>$b0ab4dfe8728dd0f$export$7c0f55a4bd3730ea);
    $parcel$export(module.exports, "toServerSearchMode", ()=>$b0ab4dfe8728dd0f$export$32a166cc1b3e6927);
    var $032ey;
    function $b0ab4dfe8728dd0f$export$7c0f55a4bd3730ea(value) {
        switch(value){
            case 0, (0, parcelRequire("032ey")).SERVER_SEARCH_MODE_ADVANCED:
                return 0, (0, parcelRequire("032ey")).SEARCH_MODE_ADVANCED;
            default:
                return 0, (0, parcelRequire("032ey")).DEFAULT_SEARCH_MODE;
        }
    }
    function $b0ab4dfe8728dd0f$export$32a166cc1b3e6927(mode) {
        switch(mode){
            case 0, (0, parcelRequire("032ey")).SEARCH_MODE_ADVANCED:
                return 0, (0, parcelRequire("032ey")).SERVER_SEARCH_MODE_ADVANCED;
            default:
                return 0, (0, parcelRequire("032ey")).SERVER_SEARCH_MODE_BASIC;
        }
    }
});
parcelRegister("dqObP", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$072aa667050b5f51$export$2e2bcd8739ae039);
    var $7ITL8;
    var $d0LIH;
    var $iCUpo;
    var $bwpsq;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $gcl1l;
    var $1asmd;
    var $3HwlS;
    var $imS60;
    var $6UAdF;
    var $jjbNu;
    var $1igGa;
    var $ih9zY;
    var $gwtSG;
    var $cKK2E;
    const $072aa667050b5f51$var$getLastPage = (pageCursors)=>{
        if (pageCursors?.last) return pageCursors.last.pageNumber;
        if (pageCursors?.around?.length) return pageCursors.around[pageCursors.around.length - 1]?.pageNumber;
        return undefined;
    };
    const $072aa667050b5f51$var$styles = {
        jqlWrapper: "_5ynfkb7n"
    };
    const $072aa667050b5f51$var$TopBar = ({ ActionMenu: ActionMenu, filter: filter, issueResults: issueResults, HeaderSkeletonImage: HeaderSkeletonImage, jqlBuilder: jqlBuilder, viewResult: viewResult })=>{
        const issueResultsData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("iCUpo")).default), issueResults);
        const offset = (issueResultsData?.issueNavigatorPageInfo?.firstIssuePosition ?? 1) - 1;
        const pages = $072aa667050b5f51$var$getLastPage(issueResultsData?.pageCursors) ?? 0;
        const total = issueResultsData?.totalIssueSearchResultCount ?? 0;
        const searchResultPageData = (0, $5uXOq.useMemo)(()=>({
                offset: offset,
                pages: pages,
                total: total
            }), [
            offset,
            pages,
            total
        ]);
        const { filterId: filterId } = (0, (0, parcelRequire("gwtSG")).useActiveJql)();
        const jql = (0, (0, parcelRequire("gwtSG")).useResolvedJql)();
        const { filterQueryIsFetching: filterQueryIsFetching } = (0, (0, parcelRequire("cKK2E")).useFilterQuery)();
        const filterData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("d0LIH")).default), filter);
        const viewResultData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("7ITL8")).default), viewResult);
        const filterDetails = (0, $5uXOq.useMemo)(()=>filterData ? {
                filterId: filterData.filterId,
                name: filterData.name,
                jql: filterData.jql,
                isFavourite: Boolean(filterData.isFavourite)
            } : undefined, [
            filterData
        ]);
        const [{ view: view }] = (0, (0, parcelRequire("ih9zY")).useSelectedViewState)();
        const HierarchyToggle = viewResultData && view !== 'detail' ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jjbNu")).HierarchyToggleItem), {
            viewResult: viewResultData
        }) : null;
        if (filterQueryIsFetching) return (0, ($parcel$interopDefault($5uXOq))).createElement(HeaderSkeletonImage, null);
        const pageTopBar = (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1asmd")).PageTopBar), {
            actions: (0, ($parcel$interopDefault($5uXOq))).createElement(ActionMenu, {
                jql: jql,
                filterId: filterId,
                filterDetails: filterDetails,
                searchResultPageData: searchResultPageData,
                HierarchyToggle: HierarchyToggle
            }),
            bottomBar: jqlBuilder
        });
        if ((0, (0, parcelRequire("3HwlS")).getWillShowNav4)()) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("imS60")).default), {
            name: "top-bar"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            className: (0, (0, parcelRequire("bwpsq")).default)([
                $072aa667050b5f51$var$styles.jqlWrapper
            ])
        }, pageTopBar));
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("imS60")).default), {
            name: "top-bar"
        }, pageTopBar);
    };
    var $072aa667050b5f51$export$2e2bcd8739ae039 = (0, (0, parcelRequire("1igGa")).withReportErrors)($072aa667050b5f51$var$TopBar, {
        id: 'ui.topbar.unhandled',
        packageName: (0, (0, parcelRequire("6UAdF")).PACKAGE_NAME),
        teamName: (0, (0, parcelRequire("6UAdF")).TEAM_NAME),
        sendToPrivacyUnsafeSplunk: true,
        attributes: (0, (0, parcelRequire("gcl1l")).isLoaderErrorAttributes)
    });
});
parcelRegister("7ITL8", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$be8c2e42f32f9d34$export$2e2bcd8739ae039);
    const $be8c2e42f32f9d34$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "topBar_issueNavigator_viewResult",
        "selections": [
            {
                "kind": "FragmentSpread",
                "name": "hierarchyToggle_issueNavigator_HierarchyToggleItem"
            }
        ],
        "type": "JiraIssueSearchView"
    };
    $be8c2e42f32f9d34$var$node.hash = "92131635561c1513fd7bd3e44159086b";
    var $be8c2e42f32f9d34$export$2e2bcd8739ae039 = $be8c2e42f32f9d34$var$node;
});
parcelRegister("d0LIH", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$45d39de334e93931$export$2e2bcd8739ae039);
    const $45d39de334e93931$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "topBar_issueNavigator_filter",
        "selections": [
            {
                "kind": "ScalarField",
                "name": "filterId"
            },
            {
                "kind": "ScalarField",
                "name": "name"
            },
            {
                "kind": "ScalarField",
                "name": "isFavourite"
            },
            {
                "kind": "ScalarField",
                "name": "jql"
            }
        ],
        "type": "JiraFilter",
        "abstractKey": "__isJiraFilter"
    };
    $45d39de334e93931$var$node.hash = "d1fd34a229f9cb2668ab6793af284885";
    var $45d39de334e93931$export$2e2bcd8739ae039 = $45d39de334e93931$var$node;
});
parcelRegister("iCUpo", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$a632fb92c01e9f2e$export$2e2bcd8739ae039);
    const $a632fb92c01e9f2e$var$node = function() {
        var v0 = [
            {
                "kind": "ScalarField",
                "name": "pageNumber"
            }
        ];
        return {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "name": "topBar_issueNavigator_issueResults",
            "selections": [
                {
                    "concreteType": "JiraIssueNavigatorPageInfo",
                    "kind": "LinkedField",
                    "name": "issueNavigatorPageInfo",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "name": "firstIssuePosition"
                        }
                    ]
                },
                {
                    "args": [
                        {
                            "kind": "Literal",
                            "name": "maxCursors",
                            "value": 7
                        }
                    ],
                    "concreteType": "JiraPageCursors",
                    "kind": "LinkedField",
                    "name": "pageCursors",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraPageCursor",
                            "kind": "LinkedField",
                            "name": "around",
                            "plural": true,
                            "selections": v0
                        },
                        {
                            "concreteType": "JiraPageCursor",
                            "kind": "LinkedField",
                            "name": "last",
                            "plural": false,
                            "selections": v0
                        }
                    ],
                    "storageKey": "pageCursors(maxCursors:7)"
                },
                {
                    "kind": "ScalarField",
                    "name": "totalIssueSearchResultCount"
                }
            ],
            "type": "JiraIssueConnection"
        };
    }();
    $a632fb92c01e9f2e$var$node.hash = "52b4c743e436bc90ea0831559f2c3e48";
    var $a632fb92c01e9f2e$export$2e2bcd8739ae039 = $a632fb92c01e9f2e$var$node;
});
parcelRegister("1asmd", function(module, exports) {
    $parcel$export(module.exports, "PageTopBar", ()=>$a509d50a7a06aca2$export$7e5801dc91bf1819);
    var $5uXOq = parcelRequire("5uXOq");
    var $dC5iT;
    var $h9kUS;
    var $8CUq3;
    var $cS37j;
    var $3HwlS;
    var $dCm1o;
    const $a509d50a7a06aca2$var$SporkPageTopBar = ({ bottomBar: bottomBar, actions: actions, wrapAtMd: wrapAtMd = false })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            testId: (0, $2fd65ab7d0fa0d2a$export$90c4434a1e138106),
            xcss: [
                (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() ? $a509d50a7a06aca2$var$wrapperStyles : $a509d50a7a06aca2$var$wrapperStylesOld,
                wrapAtMd ? $a509d50a7a06aca2$var$wrapAtMdStyle : $a509d50a7a06aca2$var$noWrapStyle
            ]
        }, bottomBar, actions && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            testId: (0, $2fd65ab7d0fa0d2a$export$1742330ac6a668f0),
            xcss: [
                $a509d50a7a06aca2$var$actionsBoxStyles,
                wrapAtMd && $a509d50a7a06aca2$var$actionsBoxAtMdStyles
            ]
        }, actions));
    const $a509d50a7a06aca2$export$7e5801dc91bf1819 = (props)=>(0, (0, parcelRequire("3HwlS")).getWillShowNav4)() ? (0, ($parcel$interopDefault($5uXOq))).createElement($a509d50a7a06aca2$var$SporkPageTopBar, {
            bottomBar: props.bottomBar,
            actions: props.actions,
            wrapAtMd: props.wrapAtMd
        }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cS37j")).default), props);
    const $a509d50a7a06aca2$var$noWrapStyle = (0, (0, parcelRequire("8CUq3")).xcss)({
        flexWrap: 'nowrap'
    });
    const $a509d50a7a06aca2$var$wrapAtMdStyle = (0, (0, parcelRequire("8CUq3")).xcss)({
        flexWrap: 'wrap-reverse',
        [(0, (0, parcelRequire("h9kUS")).media).above.md]: {
            flexWrap: 'nowrap'
        }
    });
    const $a509d50a7a06aca2$var$wrapperStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        display: 'flex',
        paddingTop: 'space.250',
        paddingBottom: 'space.250',
        justifyContent: 'space-between',
        gap: 'space.100',
        [(0, (0, parcelRequire("h9kUS")).media).above.md]: {
            gap: 'space.300'
        }
    });
    const $a509d50a7a06aca2$var$wrapperStylesOld = (0, (0, parcelRequire("8CUq3")).xcss)({
        display: 'flex',
        paddingTop: 'space.150',
        paddingBottom: 'space.150',
        justifyContent: 'space-between',
        gap: 'space.100',
        [(0, (0, parcelRequire("h9kUS")).media).above.md]: {
            gap: 'space.300'
        }
    });
    const $a509d50a7a06aca2$var$actionsBoxAtMdStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        flex: '1 1 auto',
        [(0, (0, parcelRequire("h9kUS")).media).above.md]: {
            flex: '0 1 auto'
        }
    });
    const $a509d50a7a06aca2$var$actionsBoxStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        marginLeft: 'auto',
        display: 'flex',
        justifyContent: 'flex-end',
        flex: '0 0 auto'
    });
});
parcelRegister("cS37j", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$2a15efb09f101ecb$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $57MwZ;
    const $2a15efb09f101ecb$var$PageHeader = ({ actions: actions, ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("57MwZ")).default), {
            ...props,
            actions: actions ? (0, ($parcel$interopDefault($5uXOq))).createElement($2a15efb09f101ecb$var$ActionsWrapper, null, actions) : null
        });
    var $2a15efb09f101ecb$export$2e2bcd8739ae039 = $2a15efb09f101ecb$var$PageHeader;
    const $2a15efb09f101ecb$var$ActionsWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_y3gn1e5h",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("57MwZ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9694e728fd1ea060$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $eeGIy;
    const $9694e728fd1ea060$var$PageHeader = ({ innerRef: innerRef, breadcrumbs: breadcrumbs, actions: actions, bottomBar: bottomBar, children: children, id: id, disableTitleStyles: disableTitleStyles = false, truncateTitle: truncateTitle = false })=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eeGIy")).OuterWrapper), null, breadcrumbs, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eeGIy")).TitleWrapper), {
            truncateTitle: truncateTitle
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eeGIy")).TitleContainer), {
            truncateTitle: truncateTitle
        }, disableTitleStyles ? children : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eeGIy")).StyledTitleWrapper), {
            ref: innerRef,
            truncateTitle: truncateTitle,
            id: id
        }, children)), actions && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eeGIy")).ActionsWrapper), null, actions)), bottomBar && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eeGIy")).BottomBarWrapper), null, " ", bottomBar, " "));
    };
    var $9694e728fd1ea060$export$2e2bcd8739ae039 = $9694e728fd1ea060$var$PageHeader;
});
parcelRegister("eeGIy", function(module, exports) {
    $parcel$export(module.exports, "OuterWrapper", ()=>$0c196d37a4e8266c$export$edaaad985a878983);
    $parcel$export(module.exports, "StyledTitleWrapper", ()=>$0c196d37a4e8266c$export$284be1ee64a3465c);
    $parcel$export(module.exports, "TitleWrapper", ()=>$0c196d37a4e8266c$export$585a3b23e887288b);
    $parcel$export(module.exports, "TitleContainer", ()=>$0c196d37a4e8266c$export$35e09ba17cc51c69);
    $parcel$export(module.exports, "ActionsWrapper", ()=>$0c196d37a4e8266c$export$c8223871eecc8f3a);
    $parcel$export(module.exports, "BottomBarWrapper", ()=>$0c196d37a4e8266c$export$6330225854c30dc);
    var $5uXOq = parcelRequire("5uXOq");
    var $f8Sb3;
    var $dC5iT;
    var $8CUq3;
    function $0c196d37a4e8266c$var$_EMOTION_STRINGIFIED_CSS_ERROR__() {
        return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop).";
    }
    const $0c196d37a4e8266c$var$truncateStyles = {
        name: "13oenf9",
        styles: "overflow-x:hidden;overflow-y:hidden;text-overflow:ellipsis;white-space:nowrap"
    };
    const $0c196d37a4e8266c$var$outerStyles = (0, (0, parcelRequire("f8Sb3")).css)({
        margin: `${"var(--ds-space-300, 24px)"} 0 ${"var(--ds-space-200, 16px)"} 0`
    });
    const $0c196d37a4e8266c$var$titleStyles = {
        name: "1o56te0",
        styles: "color:var(--ds-text, #172B4D);font:var(--ds-font-heading-large, normal 500 24px/28px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif);letter-spacing:normal;margin-block-start:0;outline:none"
    };
    const $0c196d37a4e8266c$var$titleWrapperStyles = {
        name: "1wb76vg",
        styles: "display:flex;align-items:flex-start;flex-wrap:wrap"
    };
    const $0c196d37a4e8266c$var$titleWrapperTruncateStyles = {
        name: "14gl6v0",
        styles: "flex-wrap:nowrap"
    };
    const $0c196d37a4e8266c$var$titleContainerStyles = (0, (0, parcelRequire("f8Sb3")).css)({
        minWidth: 0,
        maxWidth: '100%',
        flex: '1 0 auto',
        flexShrink: undefined,
        marginBlockEnd: "var(--ds-space-100, 8px)"
    });
    const $0c196d37a4e8266c$var$actionStyles = {
        name: "1ianfu6",
        styles: "max-width:100%;flex:0 0 auto;margin-block-end:var(--ds-space-100, 8px);margin-inline-start:auto;padding-inline-start:var(--ds-space-400, 32px);white-space:nowrap;>{text-align:right;}"
    };
    const $0c196d37a4e8266c$var$titleContainerTruncateStyles = {
        name: "s2a4s9",
        styles: "flex-shrink:1"
    };
    const $0c196d37a4e8266c$var$bottomBarStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        marginBlockStart: 'space.200'
    });
    const $0c196d37a4e8266c$export$edaaad985a878983 = ({ children: children })=>{
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            css: $0c196d37a4e8266c$var$outerStyles
        }, children);
    };
    const $0c196d37a4e8266c$export$284be1ee64a3465c = (0, ($parcel$interopDefault($5uXOq))).forwardRef(({ children: children, id: id, truncateTitle: truncateTitle }, ref)=>{
        return (0, (0, parcelRequire("f8Sb3")).jsx)("h1", {
            css: [
                $0c196d37a4e8266c$var$titleStyles,
                truncateTitle && $0c196d37a4e8266c$var$truncateStyles
            ],
            ref: ref,
            tabIndex: -1,
            id: id
        }, children);
    });
    const $0c196d37a4e8266c$export$585a3b23e887288b = ({ children: children, truncateTitle: truncateTitle })=>{
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            css: [
                $0c196d37a4e8266c$var$titleWrapperStyles,
                truncateTitle && $0c196d37a4e8266c$var$titleWrapperTruncateStyles
            ]
        }, children);
    };
    const $0c196d37a4e8266c$export$35e09ba17cc51c69 = ({ children: children, truncateTitle: truncateTitle })=>{
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            css: [
                $0c196d37a4e8266c$var$titleContainerStyles,
                truncateTitle && $0c196d37a4e8266c$var$titleContainerTruncateStyles
            ]
        }, children);
    };
    const $0c196d37a4e8266c$export$c8223871eecc8f3a = ({ children: children })=>{
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            css: $0c196d37a4e8266c$var$actionStyles
        }, children);
    };
    const $0c196d37a4e8266c$export$6330225854c30dc = ({ children: children })=>{
        return (0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $0c196d37a4e8266c$var$bottomBarStyles
        }, children);
    };
});
const $2fd65ab7d0fa0d2a$export$90c4434a1e138106 = 'horizontal-nav-page-topbar.ui.horizontal-nav-page-topbar';
const $2fd65ab7d0fa0d2a$export$1742330ac6a668f0 = 'horizontal-nav-page-topbar.ui.horizontal-nav-page-topbar.actions';
parcelRegister("9BgIv", function(module, exports) {
    $parcel$export(module.exports, "SelectedIssue", ()=>$29e7a5baa3c338c1$export$39b2b4a30505b395);
    var $1qDhJ;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $g5X3G;
    var $eJ0Zs;
    var $hnJJ3;
    var $ih9zY;
    var $38r5c;
    const $29e7a5baa3c338c1$export$39b2b4a30505b395 = ({ children: children, issues: issues, selectedIssueKey: selectedIssueKey, onChange: onChange })=>{
        const [{ view: view }] = (0, (0, parcelRequire("ih9zY")).useSelectedViewState)();
        const searchKey = (0, (0, parcelRequire("38r5c")).useSearchKey)();
        const issueData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("1qDhJ")).default), issues);
        const issueKeys = (0, $5uXOq.useMemo)(()=>issueData?.edges?.map((edge)=>(0, (0, parcelRequire("g5X3G")).toIssueKey)(edge?.node?.key ?? '')) ?? [], [
            issueData?.edges
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hnJJ3")).SelectedIssueContainer), {
            view: (0, (0, parcelRequire("eJ0Zs")).convertToView)(view),
            issueKeys: issueKeys,
            searchKey: searchKey,
            selectedIssueKey: selectedIssueKey,
            onChange: onChange
        }, children);
    };
});
parcelRegister("1qDhJ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$41bc6b895343393b$export$2e2bcd8739ae039);
    const $41bc6b895343393b$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "selectedIssue_issueNavigator",
        "selections": [
            {
                "concreteType": "JiraIssueEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                    {
                        "concreteType": "JiraIssue",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "name": "key"
                            }
                        ]
                    }
                ]
            }
        ],
        "type": "JiraIssueConnection"
    };
    $41bc6b895343393b$var$node.hash = "966aa39a272d43746556adcb1b54c0d8";
    var $41bc6b895343393b$export$2e2bcd8739ae039 = $41bc6b895343393b$var$node;
});
parcelRegister("k4pU6", function(module, exports) {
    $parcel$export(module.exports, "SelectedViewContainer", ()=>$f88dfd427b272bd1$export$6e497a97feb37da2);
    var $6qOEo;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $6s1PB;
    var $6UAdF;
    var $eJ0Zs;
    var $ih9zY;
    var $isIOR;
    var $38r5c;
    const $f88dfd427b272bd1$export$6e497a97feb37da2 = ({ children: children, onSetView: onSetView, fragment: fragment })=>{
        let onIssueSearchForView;
        if ((0, (0, parcelRequire("6s1PB")).expVal)('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) ({ onChangeView: onIssueSearchForView } = (0, (0, parcelRequire("38r5c")).useIssueSearchActions)());
        else ({ onIssueSearchForView: onIssueSearchForView } = (0, (0, parcelRequire("isIOR")).useIssueSearchQuery)());
        const data = (0, $8Uumt.useFragment)((0, (0, parcelRequire("6qOEo")).default), fragment);
        const view = (0, (0, parcelRequire("eJ0Zs")).parseIssueNavigatorViewIdOrDefault)(data?.viewId, (0, (0, parcelRequire("6UAdF")).DEFAULT_VIEW_ID));
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ih9zY")).Container), {
            view: view,
            onSetView: onSetView,
            onIssueSearchForView: onIssueSearchForView
        }, children);
    };
});
parcelRegister("6qOEo", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$118bd4eda5b42768$export$2e2bcd8739ae039);
    const $118bd4eda5b42768$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "selectedView_issueNavigator_SelectedViewContainer",
        "selections": [
            {
                "kind": "ScalarField",
                "name": "viewId"
            }
        ],
        "type": "JiraIssueSearchView"
    };
    $118bd4eda5b42768$var$node.hash = "7715cf1d3aecd1efc3d771f2f7af4bf4";
    var $118bd4eda5b42768$export$2e2bcd8739ae039 = $118bd4eda5b42768$var$node;
});
parcelRegister("lNtuJ", function(module, exports) {
    $parcel$export(module.exports, "GlobalIssueNavigatorHeaderSkeleton", ()=>$a7cd888c50f3d6dc$export$51e99114949d3e8f);
    var $bwpsq;
    var $5uXOq = parcelRequire("5uXOq");
    var $gnABE;
    var $hh0MT = parcelRequire("hh0MT");
    const $a7cd888c50f3d6dc$var$customCssStyles = null;
    const $a7cd888c50f3d6dc$var$containerStyles = {
        height: '80px'
    };
    const $a7cd888c50f3d6dc$export$51e99114949d3e8f = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gnABE")).AppSkeletonImage), {
            src: (0, ($parcel$interopDefault($hh0MT))),
            containerStyles: $a7cd888c50f3d6dc$var$containerStyles,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1wgmglyw"
            ])
        });
});
parcelRegister("hh0MT", function(module, exports) {
    module.exports = new URL("global-issue-navigator-header-skeleton.tpl.f1ae7ec6.svg", import.meta.url).toString();
});
parcelRegister("kfUal", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$0774879bc5ee0439$export$2e2bcd8739ae039);
    var $bwpsq;
    var $5uXOq = parcelRequire("5uXOq");
    var $3bDjY;
    var $cS37j;
    var $ew1Lk;
    var $koVbs;
    var $9Nvh7;
    var $jCD74;
    var $cgTfp;
    var $2jaAN;
    var $cZpsT;
    var $gs7uP;
    var $9Bfjr;
    const $0774879bc5ee0439$export$49640514ca453805 = (0, $5uXOq.forwardRef)((props, ref)=>(0, ($parcel$interopDefault($5uXOq))).createElement($0774879bc5ee0439$export$acedcd153f648084, {
            ref: ref,
            ...props
        }));
    const $0774879bc5ee0439$export$8b251419efc915eb = ({ jql: jql, filterId: filterId, filterDetails: filterDetails, searchResultPageData: searchResultPageData })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        const titleMessage = filterId !== undefined && filterDetails ? formatMessage((0, (0, parcelRequire("gs7uP")).default).filterWindowTitle, {
            filterName: filterDetails.name
        }) : formatMessage((0, (0, parcelRequire("gs7uP")).default).windowTitle);
        const heading = formatMessage((0, (0, parcelRequire("gs7uP")).default).heading);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cS37j")).default), {
            actions: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9Bfjr")).default), {
                jql: jql,
                filterId: filterId,
                isModifiedFilter: filterDetails?.jql !== jql,
                searchResultPageData: searchResultPageData,
                filterName: filterDetails?.name
            })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ew1Lk")).DocumentTitle), {
            title: titleMessage
        }), (0, ($parcel$interopDefault($5uXOq))).createElement($0774879bc5ee0439$export$6d0d24718f298af7, null, filterId !== undefined && filterDetails ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
            content: filterDetails.name,
            tag: $0774879bc5ee0439$export$49640514ca453805,
            position: "mouse"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($0774879bc5ee0439$export$d337e02ad938bc27, null, filterDetails.name)), !isAnonymous && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2jaAN")).FavoriteButtonHeaderWrapper), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2jaAN")).FavoriteButton), {
            filterId: filterDetails.filterId,
            isFavoriteInitial: filterDetails.isFavourite,
            favoriteItemName: filterDetails.name
        })), (0, (0, parcelRequire("9Nvh7")).isCustomFilter)(filterDetails.filterId) && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgTfp")).DetailsButtonHeaderWrapper), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jCD74")).DetailsPopup), {
            filterId: filterId
        }))) : heading));
    };
    var $0774879bc5ee0439$export$2e2bcd8739ae039 = $0774879bc5ee0439$export$8b251419efc915eb;
    const $0774879bc5ee0439$export$acedcd153f648084 = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _1reo15vq _18m915vq",
                __cmplp.className
            ])
        });
    });
    const $0774879bc5ee0439$export$d337e02ad938bc27 = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1reo15vq _18m915vq _1bto1l2s _o5721q9c",
                __cmplp.className
            ])
        });
    });
    const $0774879bc5ee0439$export$6d0d24718f298af7 = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c116y _4cvr1y6m _1dyz1kw7 _4t3izwfg _11c81p5s _vwz4zwfg _p12f1osq",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("ew1Lk", function(module, exports) {
    $parcel$export(module.exports, "DocumentTitle", ()=>$41b549fa18234081$export$dd5ad0b65cf665da);
    var $5uXOq = parcelRequire("5uXOq");
    var $6nkVm;
    class $41b549fa18234081$export$dd5ad0b65cf665da extends (0, $5uXOq.Component) {
        static{
            this.defaultProps = {
                TitleSubscriber: (0, (0, parcelRequire("6nkVm")).DocumentTitleSubscriber)
            };
        }
        shouldComponentUpdate(nextProps) {
            return nextProps.title !== this.props.title;
        }
        render() {
            const { title: title, TitleSubscriber: TitleSubscriber } = this.props;
            return (0, ($parcel$interopDefault($5uXOq))).createElement(TitleSubscriber, null, ({ contextArray: contextArray }, { setTitle: setTitle, addDocumentTitle: addDocumentTitle, removeDocumentTitle: removeDocumentTitle })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6nkVm")).DocumentTitleHelper), {
                    title: title,
                    setTitle: setTitle,
                    addDocumentTitle: addDocumentTitle,
                    contextArray: contextArray,
                    removeDocumentTitle: removeDocumentTitle,
                    TitleSubscriber: TitleSubscriber
                }));
        }
    }
});
parcelRegister("jCD74", function(module, exports) {
    $parcel$export(module.exports, "DetailsPopup", ()=>$ad1b1ac535cd8166$export$b61797ace80c3d8);
    var $5uXOq = parcelRequire("5uXOq");
    var $fVnbM;
    var $1I7E8;
    var $koVbs;
    var $8zOmE;
    var $6KXPO;
    var $i4HKU;
    var $9DNHu;
    const $ad1b1ac535cd8166$export$b61797ace80c3d8 = ({ filterId: filterId, defaultOpen: defaultOpen = false })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const [isOpen, setIsOpen] = (0, $5uXOq.useState)(defaultOpen);
        const onClose = (0, $5uXOq.useCallback)(()=>setIsOpen(false), [
            setIsOpen
        ]);
        const onTriggerClick = (0, $5uXOq.useCallback)((_, analyticsEvent)=>{
            setIsOpen(!isOpen);
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'filterDetailsButton');
        }, [
            isOpen,
            setIsOpen
        ]);
        const [isEditFilterModalOpen, setIsEditFilterModalOpen] = (0, $5uXOq.useState)(false);
        const onOpenEditFilterModal = (0, $5uXOq.useCallback)(()=>{
            setIsEditFilterModalOpen(true);
            onClose();
        }, [
            onClose
        ]);
        const onCloseEditFilterModal = (0, $5uXOq.useCallback)(()=>{
            setIsEditFilterModalOpen(false);
        }, []);
        const Content = (0, $5uXOq.useCallback)((props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9DNHu")).PopupContent), {
                ...props,
                onOpenEditFilterModal: onOpenEditFilterModal,
                onOpenNewSubscriptionModal: onClose,
                filterId: filterId
            }), [
            filterId,
            onClose,
            onOpenEditFilterModal
        ]);
        const Trigger = (0, $5uXOq.useCallback)((props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                ...props,
                appearance: "link",
                onClick: onTriggerClick,
                isSelected: isOpen,
                onMouseOver: (0, (0, parcelRequire("9DNHu")).preloadPopupContentComponent),
                testId: "issue-navigator-custom-filters.ui.details-popup.button"
            }, formatMessage((0, (0, parcelRequire("i4HKU")).default).filterDetails)), [
            isOpen,
            onTriggerClick,
            formatMessage
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6KXPO")).AsyncUpdateFilterDialog), {
            filterId: filterId,
            onCloseModal: onCloseEditFilterModal,
            isOpen: isEditFilterModalOpen
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1I7E8")).default), {
            isOpen: isOpen,
            onClose: onClose,
            placement: "bottom-start",
            content: Content,
            trigger: Trigger,
            testId: "issue-navigator-custom-filters.ui.details-popup.popup"
        }));
    };
});
parcelRegister("6KXPO", function(module, exports) {
    $parcel$export(module.exports, "AsyncUpdateFilterDialog", ()=>$5522efbabcc83563$export$8692e2751472dade);
    var $5uXOq = parcelRequire("5uXOq");
    var $lfTZh;
    var $kxZPA;
    const $5522efbabcc83563$var$UpdateFilterDialog = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("4LqdW")), {
        ssr: false,
        moduleId: "./src/packages/issue-navigator/save-filter-dialog/src/ui/update-filter-dialog/index.tsx"
    });
    const $5522efbabcc83563$export$8692e2751472dade = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "async-update-filter-dialog",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($5522efbabcc83563$var$UpdateFilterDialog, props));
});
parcelRegister("4LqdW", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("hPzOn"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("fBWcb")
    ]).then(()=>parcelRequire('36hTn'));
});
parcelRegister("i4HKU", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$4a4107a8a3141584$export$2e2bcd8739ae039);
    var $7VHMR;
    var $4a4107a8a3141584$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        filterDetails: {
            "id": "issue-navigator-custom-filters.details-popup.filter-details",
            "defaultMessage": "Filter details"
        }
    });
});
parcelRegister("9DNHu", function(module, exports) {
    $parcel$export(module.exports, "preloadPopupContentComponent", ()=>$166058a1fd3e08e7$export$c115357d632001d1);
    $parcel$export(module.exports, "PopupContent", ()=>$166058a1fd3e08e7$export$2a2c7ba12d3231e3);
    var $5uXOq = parcelRequire("5uXOq");
    var $eZJjh;
    var $lfTZh;
    var $kxZPA;
    var $dnjeJ;
    var $eyQMS;
    var $6ibCj;
    const $166058a1fd3e08e7$var$LazyPopupContent = (0, (0, parcelRequire("kxZPA")).lazy)(()=>Promise.resolve((parcelRequire("6ibCj"))), {
        moduleId: "./src/packages/issue-navigator/custom-filters/src/ui/details-popup/popup-content/index.tsx"
    });
    const $166058a1fd3e08e7$export$c115357d632001d1 = ()=>$166058a1fd3e08e7$var$LazyPopupContent.preload();
    const $166058a1fd3e08e7$var$LoadingFallback = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6ibCj")).LoadingWrapper), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eZJjh")).default), null));
    const $166058a1fd3e08e7$export$2a2c7ba12d3231e3 = (props)=>{
        (0, (0, parcelRequire("dnjeJ")).useUFOComponentExperience)((0, (0, parcelRequire("eyQMS")).viewDetailsPopupExperience));
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "popup-content",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement($166058a1fd3e08e7$var$LoadingFallback, null)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($166058a1fd3e08e7$var$LazyPopupContent, props));
    };
});
parcelRegister("dnjeJ", function(module, exports) {
    $parcel$export(module.exports, "useUFOComponentExperience", ()=>$44448c24118cb590$export$30ab340faaed55d4);
    var $5uXOq = parcelRequire("5uXOq");
    const $44448c24118cb590$var$useConstructor = (callback)=>{
        const hasBeenFired = (0, $5uXOq.useRef)(false);
        if (!hasBeenFired.current) {
            callback();
            hasBeenFired.current = true;
        }
        return null;
    };
    const $44448c24118cb590$export$30ab340faaed55d4 = (experience)=>{
        (0, $5uXOq.useEffect)(()=>{
            return ()=>{
                experience.abort();
            };
        }, []);
        $44448c24118cb590$var$useConstructor(()=>experience.start());
        return null;
    };
});
parcelRegister("eyQMS", function(module, exports) {
    $parcel$export(module.exports, "viewDetailsPopupExperience", ()=>$0dbbce9899ca1284$export$54c37dd263cb6171);
    $parcel$export(module.exports, "viewNewSubscriptionModal", ()=>$0dbbce9899ca1284$export$1584a1158c503c0a);
    var $atdLB;
    var $duUGa;
    const $0dbbce9899ca1284$export$54c37dd263cb6171 = new (0, (0, parcelRequire("duUGa")).UFOExperience)('jira-issue-navigator.custom-filters.view-details-popup', {
        type: (0, (0, parcelRequire("atdLB")).ExperienceTypes).Experience,
        performanceType: (0, (0, parcelRequire("atdLB")).ExperiencePerformanceTypes).PageSegmentLoad
    });
    const $0dbbce9899ca1284$export$1584a1158c503c0a = new (0, (0, parcelRequire("duUGa")).UFOExperience)('jira-issue-navigator.custom-filters.details-popup.view-new-subscription-modal', {
        type: (0, (0, parcelRequire("atdLB")).ExperienceTypes).Experience,
        performanceType: (0, (0, parcelRequire("atdLB")).ExperiencePerformanceTypes).PageSegmentLoad
    });
});
parcelRegister("6ibCj", function(module, exports) {
    $parcel$defineInteropFlag(module.exports);
    $parcel$export(module.exports, "PopupContent", ()=>$0b05b477a3716d14$export$2a2c7ba12d3231e3);
    $parcel$export(module.exports, "ContentWrapper", ()=>$0b05b477a3716d14$export$6ce170286acaa8d3);
    $parcel$export(module.exports, "default", ()=>$0b05b477a3716d14$export$2e2bcd8739ae039);
    $parcel$export(module.exports, "LoadingWrapper", ()=>$0b05b477a3716d14$export$43a792a870d28db2);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Uumt = parcelRequire("8Uumt");
    var $39xOx;
    var $dXq4S;
    var $iXVV6;
    var $bGxOb;
    var $fY39E;
    var $koeKL;
    var $cZpsT;
    var $eyQMS;
    var $7BHj0;
    var $lxlno;
    const $0b05b477a3716d14$export$2a2c7ba12d3231e3 = ({ filterId: filterId, ...props })=>{
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        const activationId = (0, (0, parcelRequire("fY39E")).useActivationId)();
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const filterAri = (0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("dXq4S")).createAri)({
                resourceOwner: 'jira',
                cloudId: cloudId,
                resourceType: 'filter',
                resourceId: filterId,
                activationId: activationId
            }), [
            filterId,
            activationId,
            cloudId
        ]);
        const [queryReference, loadQuery, disposeQuery] = (0, $8Uumt.useQueryLoader)((0, (0, parcelRequire("bGxOb")).default));
        (0, $5uXOq.useEffect)(()=>{
            (0, (0, parcelRequire("eyQMS")).viewDetailsPopupExperience).start();
            loadQuery({
                filterAri: filterAri,
                isAnonymous: isAnonymous
            });
            return disposeQuery;
        }, [
            loadQuery,
            filterAri,
            isAnonymous,
            disposeQuery
        ]);
        const onRetry = (0, $5uXOq.useCallback)(()=>{
            loadQuery({
                filterAri: filterAri,
                isAnonymous: isAnonymous
            }, {
                fetchPolicy: 'network-only'
            });
        }, [
            loadQuery,
            filterAri,
            isAnonymous
        ]);
        const ErrorFallback = (0, $5uXOq.useCallback)((errorStateProps)=>{
            (0, (0, parcelRequire("eyQMS")).viewDetailsPopupExperience).failure();
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7BHj0")).ErrorState), {
                ...errorStateProps,
                onRetry: onRetry
            });
        }, [
            onRetry
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            sourceName: "filterDetails",
            sourceType: (0, $4d5e0871c06cee03$export$cae2783c9279926)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($0b05b477a3716d14$export$6ce170286acaa8d3, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "DetailsPopupContentWrapper",
            packageName: (0, $c18cf10dfb008b12$export$ad257a98f20ee631),
            teamName: "empanada",
            fallback: ErrorFallback,
            key: queryReference?.fetchKey
        }, queryReference ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lxlno")).PopupContent), {
            ...props,
            queryReference: queryReference,
            onSuccess: onRetry
        }) : null)));
    };
    var $0b05b477a3716d14$export$2e2bcd8739ae039 = $0b05b477a3716d14$export$2a2c7ba12d3231e3;
    const $0b05b477a3716d14$export$6ce170286acaa8d3 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1bsb1vif _1yt4v47k _1reo1wug _18m91wug _c71lqdd9",
                __cmplp.className
            ])
        });
    });
    const $0b05b477a3716d14$export$43a792a870d28db2 = (0, $5uXOq.forwardRef)(({ as: C = $0b05b477a3716d14$export$6ce170286acaa8d3, style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_y3gn1h6o",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("bGxOb", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$208d33cacd8348e2$export$2e2bcd8739ae039);
    const $208d33cacd8348e2$var$node = function() {
        var v0 = [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "filterAri"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "isAnonymous"
            }
        ], v1 = [
            {
                "kind": "Variable",
                "name": "id",
                "variableName": "filterAri"
            }
        ], v2 = {
            "kind": "ScalarField",
            "name": "name"
        }, v3 = {
            "kind": "ClientExtension",
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "__id"
                }
            ]
        }, v4 = {
            "kind": "ScalarField",
            "name": "__typename"
        }, v5 = {
            "kind": "ScalarField",
            "name": "id"
        }, v6 = {
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
                v2,
                {
                    "kind": "ScalarField",
                    "name": "key"
                },
                v5
            ]
        }, v7 = [
            v2,
            v5
        ], v8 = {
            "concreteType": "JiraGroup",
            "kind": "LinkedField",
            "name": "group",
            "plural": false,
            "selections": v7
        }, v9 = {
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
                v4,
                v2,
                v5
            ]
        }, v10 = [
            {
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                    v4,
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            v6
                        ],
                        "type": "JiraShareableEntityProjectGrant"
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            v6,
                            {
                                "concreteType": "JiraRole",
                                "kind": "LinkedField",
                                "name": "role",
                                "plural": false,
                                "selections": v7
                            }
                        ],
                        "type": "JiraShareableEntityProjectRoleGrant"
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            v8
                        ],
                        "type": "JiraShareableEntityGroupGrant"
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            v9
                        ],
                        "type": "JiraShareableEntityUserGrant"
                    }
                ]
            }
        ];
        return {
            "fragment": {
                "argumentDefinitions": v0,
                "kind": "Fragment",
                "name": "mainPopupContentFilterByAriQuery",
                "selections": [
                    {
                        "kind": "RequiredField",
                        "field": {
                            "concreteType": "JiraQuery",
                            "kind": "LinkedField",
                            "name": "jira",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "RequiredField",
                                    "field": {
                                        "args": v1,
                                        "kind": "LinkedField",
                                        "name": "filter",
                                        "plural": false,
                                        "selections": [
                                            v2,
                                            {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                    {
                                                        "kind": "FragmentSpread",
                                                        "name": "description_issueNavigatorCustomFilters"
                                                    },
                                                    {
                                                        "condition": "isAnonymous",
                                                        "kind": "Condition",
                                                        "passingValue": false,
                                                        "selections": [
                                                            {
                                                                "kind": "FragmentSpread",
                                                                "name": "owner_issueNavigatorCustomFilters"
                                                            },
                                                            {
                                                                "kind": "FragmentSpread",
                                                                "name": "subscriptions_issueNavigatorCustomFilters"
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        "kind": "FragmentSpread",
                                                        "name": "permissions_issueNavigatorCustomFilters"
                                                    }
                                                ],
                                                "type": "JiraCustomFilter"
                                            },
                                            v3
                                        ]
                                    },
                                    "action": "THROW",
                                    "path": "jira.filter"
                                }
                            ]
                        },
                        "action": "THROW",
                        "path": "jira"
                    }
                ],
                "type": "Query"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": v0,
                "kind": "Operation",
                "name": "mainPopupContentFilterByAriQuery",
                "selections": [
                    {
                        "concreteType": "JiraQuery",
                        "kind": "LinkedField",
                        "name": "jira",
                        "plural": false,
                        "selections": [
                            {
                                "args": v1,
                                "kind": "LinkedField",
                                "name": "filter",
                                "plural": false,
                                "selections": [
                                    v4,
                                    v2,
                                    v5,
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            {
                                                "kind": "ScalarField",
                                                "name": "description"
                                            },
                                            {
                                                "kind": "ScalarField",
                                                "name": "isEditable"
                                            },
                                            {
                                                "concreteType": "JiraShareableEntityShareGrantConnection",
                                                "kind": "LinkedField",
                                                "name": "shareGrants",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "concreteType": "JiraShareableEntityShareGrantEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": v10
                                                    }
                                                ]
                                            },
                                            {
                                                "concreteType": "JiraShareableEntityEditGrantConnection",
                                                "kind": "LinkedField",
                                                "name": "editGrants",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "concreteType": "JiraShareableEntityEditGrantEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": v10
                                                    }
                                                ]
                                            },
                                            {
                                                "condition": "isAnonymous",
                                                "kind": "Condition",
                                                "passingValue": false,
                                                "selections": [
                                                    {
                                                        "kind": "LinkedField",
                                                        "name": "owner",
                                                        "plural": false,
                                                        "selections": [
                                                            v4,
                                                            {
                                                                "kind": "ScalarField",
                                                                "name": "accountId"
                                                            },
                                                            v2,
                                                            {
                                                                "kind": "ScalarField",
                                                                "name": "picture"
                                                            },
                                                            v5
                                                        ]
                                                    },
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "filterId"
                                                    },
                                                    {
                                                        "args": [
                                                            {
                                                                "kind": "Literal",
                                                                "name": "last",
                                                                "value": 5
                                                            }
                                                        ],
                                                        "concreteType": "JiraFilterEmailSubscriptionConnection",
                                                        "kind": "LinkedField",
                                                        "name": "emailSubscriptions",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "concreteType": "JiraFilterEmailSubscriptionEdge",
                                                                "kind": "LinkedField",
                                                                "name": "edges",
                                                                "plural": true,
                                                                "selections": [
                                                                    {
                                                                        "concreteType": "JiraFilterEmailSubscription",
                                                                        "kind": "LinkedField",
                                                                        "name": "node",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            v5,
                                                                            v9,
                                                                            v8
                                                                        ]
                                                                    }
                                                                ]
                                                            }
                                                        ],
                                                        "storageKey": "emailSubscriptions(last:5)"
                                                    }
                                                ]
                                            }
                                        ],
                                        "type": "JiraCustomFilter"
                                    },
                                    v3
                                ]
                            }
                        ]
                    }
                ]
            },
            "params": {
                "id": "6bc8c23e9cd41f906771238f4fc28eed8056bad2b96a927b890039d4d973ea8d",
                "metadata": {},
                "name": "mainPopupContentFilterByAriQuery",
                "operationKind": "query",
                "text": null
            }
        };
    }();
    $208d33cacd8348e2$var$node.hash = "0d557f612ff494f70f6caa72336c5a34";
    var $208d33cacd8348e2$export$2e2bcd8739ae039 = $208d33cacd8348e2$var$node;
});
const $c18cf10dfb008b12$export$ad257a98f20ee631 = 'jiraIssueNavigatorCustomFilters';
parcelRegister("7BHj0", function(module, exports) {
    $parcel$export(module.exports, "ErrorState", ()=>$4ba264838a2054d0$export$c1ed4f415b9e7023);
    var $5uXOq = parcelRequire("5uXOq");
    var $koVbs;
    var $Y0ZJB;
    var $3M2k1;
    const $4ba264838a2054d0$export$c1ed4f415b9e7023 = ({ error: error, onRetry: onRetry, ...props })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("Y0ZJB")).ErrorState), {
            ...props,
            onRetry: onRetry,
            description: formatMessage((0, (0, parcelRequire("3M2k1")).default).errorMessage),
            pageAction: "detailsPopup"
        });
    };
});
parcelRegister("Y0ZJB", function(module, exports) {
    $parcel$export(module.exports, "ErrorState", ()=>$a04e313879f6b069$export$c1ed4f415b9e7023);
    var $5uXOq = parcelRequire("5uXOq");
    var $fVnbM;
    var $dD7Zc;
    var $gwIPs;
    var $koVbs;
    var $8zOmE;
    var $2NMNM;
    var $6TIqn;
    var $4MFPU;
    const $a04e313879f6b069$var$BOX_HEIGHT = (0, (0, parcelRequire("gwIPs")).gridSize) * 18;
    const $a04e313879f6b069$var$ERROR_ICON_BOX_WRAPPER_HEIGHT = (0, (0, parcelRequire("gwIPs")).gridSize) * 9;
    const $a04e313879f6b069$var$ICON_SIZE = (0, (0, parcelRequire("gwIPs")).gridSize) * 9;
    const $a04e313879f6b069$export$c1ed4f415b9e7023 = (props)=>{
        const { pageAction: pageAction, onRetry: onRetry, description: description, role: role } = props;
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const onClick = ()=>{
            onRetry();
            (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), `${pageAction}Retry clicked`);
        };
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4MFPU")).StyledBox), {
            height: $a04e313879f6b069$var$BOX_HEIGHT,
            role: role
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4MFPU")).StyledBox), {
            height: $a04e313879f6b069$var$ERROR_ICON_BOX_WRAPPER_HEIGHT,
            iconSize: $a04e313879f6b069$var$ICON_SIZE
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("dD7Zc"))))), {
            color: "var(--ds-icon, #44546F)",
            LEGACY_primaryColor: "var(--ds-icon, #44546F)",
            label: ""
        })), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4MFPU")).StyledText), null, description !== undefined ? description : formatMessage((0, (0, parcelRequire("6TIqn")).default).errorMessage)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            onClick: onClick,
            appearance: "link",
            testId: "issue-navigator-actions-common.ui.error.button"
        }, formatMessage((0, (0, parcelRequire("6TIqn")).default).retryMessage)));
    };
});
parcelRegister("6TIqn", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$c213a9b9cc23628d$export$2e2bcd8739ae039);
    var $7VHMR;
    const $c213a9b9cc23628d$var$messages = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        errorMessage: {
            "id": "issue-navigator-actions-common.error.error-message",
            "defaultMessage": "Couldn't retrieve data"
        },
        retryMessage: {
            "id": "issue-navigator-actions-common.error.retry-message",
            "defaultMessage": "Click to retry"
        }
    });
    var $c213a9b9cc23628d$export$2e2bcd8739ae039 = $c213a9b9cc23628d$var$messages;
});
parcelRegister("4MFPU", function(module, exports) {
    $parcel$export(module.exports, "StyledBox", ()=>$acc4659069112fb0$export$d675f139b342d51a);
    $parcel$export(module.exports, "StyledText", ()=>$acc4659069112fb0$export$31ea92f3997410dd);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $lD23V;
    var $1dJ6S;
    const $acc4659069112fb0$export$d675f139b342d51a = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { iconSize: iconSize, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: {
                ...__cmpls,
                "--_8jvb0j": (0, (0, parcelRequire("lD23V")).default)(__cmplp.height, "px"),
                "--_1kpj8xj": (0, (0, parcelRequire("lD23V")).default)(__cmplp.iconSize, "px")
            },
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_4cvr1h6o _1e0c1txw _2lx21bp4 _4t3i1474 _1bah1h6o _18s8dq2u",
                __cmplp.iconSize && "_w8l51dmb _17521dmb",
                __cmplp.className
            ])
        });
    });
    const $acc4659069112fb0$var$StyledTextComponent = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_19pku2gc",
                __cmplp.className
            ])
        });
    });
    const $acc4659069112fb0$export$31ea92f3997410dd = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1dJ6S")).default), {
            size: "xsmall"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($acc4659069112fb0$var$StyledTextComponent, props));
});
parcelRegister("3M2k1", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$ed4a72b3654a9846$export$2e2bcd8739ae039);
    var $7VHMR;
    var $ed4a72b3654a9846$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        errorMessage: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.error-state.error-message",
            "defaultMessage": "We couldn't display the filter details."
        }
    });
});
parcelRegister("lxlno", function(module, exports) {
    $parcel$export(module.exports, "PopupContent", ()=>$e8e95610faaefc30$export$2a2c7ba12d3231e3);
    var $bGxOb;
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Uumt = parcelRequire("8Uumt");
    var $8zOmE;
    var $2NMNM;
    var $cZpsT;
    var $dFJCD;
    var $iloUv;
    var $gVFu0;
    var $ghHbc;
    const $e8e95610faaefc30$export$2a2c7ba12d3231e3 = ({ queryReference: queryReference, update: update, onSuccess: onSuccessParent, onOpenEditFilterModal: onOpenEditFilterModal, onOpenNewSubscriptionModal: onOpenNewSubscriptionModal })=>{
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const { jira: { filter: filter } } = (0, $8Uumt.usePreloadedQuery)((0, (0, parcelRequire("bGxOb")).default), queryReference);
        (0, $5uXOq.useEffect)(()=>{
            if (filter) update();
        }, [
            update,
            filter
        ]);
        const environment = (0, $8Uumt.useRelayEnvironment)();
        const onSuccess = (0, $5uXOq.useCallback)(()=>{
            (0, $8Uumt.commitLocalUpdate)(environment, (store)=>{
                const filterRecord = store.get(filter.__id);
                if (filterRecord) filterRecord.invalidateRecord();
            });
            onSuccessParent();
        }, [
            environment,
            filter,
            onSuccessParent
        ]);
        (0, $5uXOq.useEffect)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                action: 'viewed',
                actionSubject: 'inlineDialog'
            }), 'filterDetails');
        }, [
            createAnalyticsEvent
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement($e8e95610faaefc30$var$FilterTitle, null, filter.name), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dFJCD")).Description), {
            filter: filter,
            onOpenEditFilterModal: onOpenEditFilterModal
        }), !isAnonymous && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iloUv")).Owner), {
            filter: filter
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gVFu0")).Permissions), {
            filter: filter,
            onOpenEditFilterModal: onOpenEditFilterModal
        }), !isAnonymous && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ghHbc")).Subscriptions), {
            filter: filter,
            onOpenNewSubscriptionModal: onOpenNewSubscriptionModal,
            onSuccess: onSuccess
        }));
    };
    const $e8e95610faaefc30$var$FilterTitle = (0, $5uXOq.forwardRef)(({ as: C = "h3", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1i4q1hna",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("dFJCD", function(module, exports) {
    $parcel$export(module.exports, "Description", ()=>$1feb8686d0bc80b2$export$393edc798c47379d);
    var $7FNpl;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $fVnbM;
    var $koVbs;
    var $8zOmE;
    var $ce4pa;
    var $1xOsd;
    var $atGOA;
    var $7kWfK;
    var $eyQMS;
    var $gjFTW;
    const $1feb8686d0bc80b2$export$393edc798c47379d = ({ filter: filter, onOpenEditFilterModal: onOpenEditFilterModal })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const data = (0, $8Uumt.useFragment)((0, (0, parcelRequire("7FNpl")).default), filter);
        const openEditFilterNameAndDescriptionModal = (0, $5uXOq.useCallback)((_, analyticsEvent)=>{
            onOpenEditFilterModal();
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'editFilterNameAndDescription');
        }, [
            onOpenEditFilterModal
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7kWfK")).Section), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1xOsd")).SectionHeader), {
            actions: data.isEditable === true ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                onClick: openEditFilterNameAndDescriptionModal,
                appearance: "link",
                spacing: "none",
                testId: "issue-navigator-custom-filters.ui.details-popup.popup-content.description.edit-name-and-description-button"
            }, formatMessage((0, (0, parcelRequire("gjFTW")).default).editNameAndDescription)) : null
        }, formatMessage((0, (0, parcelRequire("gjFTW")).default).description)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("atGOA")).ShowMore), null, data.description != null && data.description.length ? data.description : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7kWfK")).PlaceholderText), null, formatMessage((0, (0, parcelRequire("gjFTW")).default).descriptionPlaceholder))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ce4pa")).ExperienceSuccess), {
            experience: (0, (0, parcelRequire("eyQMS")).viewDetailsPopupExperience)
        }));
    };
});
parcelRegister("7FNpl", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$6db38d34ea87f99f$export$2e2bcd8739ae039);
    const $6db38d34ea87f99f$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "description_issueNavigatorCustomFilters",
        "selections": [
            {
                "kind": "ScalarField",
                "name": "description"
            },
            {
                "kind": "ScalarField",
                "name": "isEditable"
            }
        ],
        "type": "JiraCustomFilter"
    };
    $6db38d34ea87f99f$var$node.hash = "f9b450af0990be0be18e39994e4e39db";
    var $6db38d34ea87f99f$export$2e2bcd8739ae039 = $6db38d34ea87f99f$var$node;
});
parcelRegister("1xOsd", function(module, exports) {
    $parcel$export(module.exports, "SectionHeader", ()=>$19e48a90230944ae$export$8adb256d999cd5a0);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $f5TWP;
    var $gwIPs;
    var $cZpsT;
    const $19e48a90230944ae$export$8adb256d999cd5a0 = ({ actions: actions, children: children })=>{
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("f5TWP")).default), {
            alignBlock: "center",
            spread: "space-between",
            space: "space.100",
            shouldWrap: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($19e48a90230944ae$var$HeaderText, null, children), actions != null && !isAnonymous && (0, ($parcel$interopDefault($5uXOq))).createElement($19e48a90230944ae$var$ActionsContainer, null, actions));
    };
    const $19e48a90230944ae$var$HEADER_FONT_SIZE = (0, (0, parcelRequire("gwIPs")).fontSize);
    const $19e48a90230944ae$var$LINE_HEIGHT = 1.25;
    const $19e48a90230944ae$export$cdb7fd9d999c7168 = $19e48a90230944ae$var$HEADER_FONT_SIZE * $19e48a90230944ae$var$LINE_HEIGHT;
    const $19e48a90230944ae$var$HeaderText = (0, $5uXOq.forwardRef)(({ as: C = "h4", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_vwz410nf _1wybdlk8",
                __cmplp.className
            ])
        });
    });
    const $19e48a90230944ae$var$ActionsContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_vwz410nf _1wybdlk8 _k48p4jg8 _1q9t4jg8",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("atGOA", function(module, exports) {
    $parcel$export(module.exports, "ShowMore", ()=>$816256f01ce09ed9$export$ca7697601dcd7803);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $lD23V;
    var $fVnbM;
    var $koVbs;
    var $3XxG9;
    const $816256f01ce09ed9$var$DEFAULT_MAX_HEIGHT = 200;
    const $816256f01ce09ed9$export$ca7697601dcd7803 = ({ children: children, maxHeight: maxHeight = $816256f01ce09ed9$var$DEFAULT_MAX_HEIGHT })=>{
        const contentRef = (0, $5uXOq.createRef)();
        const [shouldShowShowMore, setShouldShowShowMore] = (0, $5uXOq.useState)(false);
        const [collapsed, setCollapsed] = (0, $5uXOq.useState)(true);
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        (0, $5uXOq.useEffect)(()=>{
            if (contentRef.current !== null && contentRef.current.clientHeight > maxHeight) setShouldShowShowMore(true);
        }, [
            contentRef,
            maxHeight
        ]);
        const toggleShowMore = (0, $5uXOq.useCallback)(()=>{
            setCollapsed(!collapsed);
        }, [
            collapsed
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement($816256f01ce09ed9$var$Container, {
            collapsed: collapsed,
            maxHeight: maxHeight
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("p", {
            ref: contentRef
        }, children)), shouldShowShowMore && (0, ($parcel$interopDefault($5uXOq))).createElement($816256f01ce09ed9$var$ButtonContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            appearance: "link",
            spacing: "none",
            onClick: toggleShowMore
        }, collapsed ? formatMessage((0, (0, parcelRequire("3XxG9")).default).showMore) : formatMessage((0, (0, parcelRequire("3XxG9")).default).showLess))));
    };
    const $816256f01ce09ed9$var$Container = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { collapsed: collapsed, maxHeight: maxHeight, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: {
                ...__cmpls,
                "--_1upm8ip": (0, (0, parcelRequire("lD23V")).default)(__cmplp.maxHeight, "px")
            },
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_18s81bpf _14cp1hna",
                __cmplp.collapsed && "_c71l1w9o _1reo15vq _18m915vq",
                __cmplp.className
            ])
        });
    });
    const $816256f01ce09ed9$var$ButtonContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1q9t4jg8",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("3XxG9", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$52ce38150bf07a43$export$2e2bcd8739ae039);
    var $7VHMR;
    var $52ce38150bf07a43$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        showMore: {
            "id": "issue-navigator-custom-filters.common.details-popup.show-more.show-more",
            "defaultMessage": "Show more..."
        },
        showLess: {
            "id": "issue-navigator-custom-filters.common.details-popup.show-more.show-less",
            "defaultMessage": "Show less"
        }
    });
});
parcelRegister("7kWfK", function(module, exports) {
    $parcel$export(module.exports, "PlaceholderText", ()=>$70b9e5c0470ccc91$export$5dd7e4f3fa008590);
    $parcel$export(module.exports, "Section", ()=>$70b9e5c0470ccc91$export$6e2c8f0811a474ce);
    $parcel$export(module.exports, "SectionBodyTitle", ()=>$70b9e5c0470ccc91$export$1d69e81357d9c7e8);
    $parcel$export(module.exports, "SectionBody", ()=>$70b9e5c0470ccc91$export$e7049d4e10b2bdab);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    const $70b9e5c0470ccc91$export$5dd7e4f3fa008590 = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_syaz1e9r",
                __cmplp.className
            ])
        });
    });
    const $70b9e5c0470ccc91$export$6e2c8f0811a474ce = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_n7zlh6cr _syaz1dv3 _1yt4r2fv _1lh4glyw _j2v5utpp",
                __cmplp.className
            ])
        });
    });
    const $70b9e5c0470ccc91$export$1d69e81357d9c7e8 = (0, $5uXOq.forwardRef)(({ as: C = "h5", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_syazaj1x _11c816g6 _vwz410yn _19pkidpf _2hwxidpf _otyridpf _18u0idpf _19pkpxbi",
                __cmplp.className
            ])
        });
    });
    const $70b9e5c0470ccc91$export$e7049d4e10b2bdab = (0, $5uXOq.forwardRef)(({ as: C = "p", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_syaz2l1e _vwz4gktf _19pkidpf _2hwxidpf _otyridpf _18u0idpf",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("gjFTW", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$dfa1690e5be898aa$export$2e2bcd8739ae039);
    var $7VHMR;
    var $dfa1690e5be898aa$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        description: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.description.description",
            "defaultMessage": "Description"
        },
        editNameAndDescription: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.description.edit-name-and-description",
            "defaultMessage": "Edit name and description"
        },
        descriptionPlaceholder: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.description.description-placeholder",
            "defaultMessage": "This filter doesn't have a description."
        }
    });
});
parcelRegister("iloUv", function(module, exports) {
    $parcel$export(module.exports, "Owner", ()=>$d0f6eb494505673b$export$25fb39c3824c9c44);
    var $bB5VF;
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Uumt = parcelRequire("8Uumt");
    var $ujWmd;
    var $dC5iT;
    var $8CUq3;
    var $jhmo0;
    var $ousew;
    var $8zOmE;
    var $kYYfm;
    var $7I2zi;
    var $9OXo1;
    var $7kWfK;
    var $xymkp;
    const $d0f6eb494505673b$var$PROFILE_ACTIONS = [
        (0, $e6a2e0952e4731b8$export$23cedd3261bd8015),
        (0, $e6a2e0952e4731b8$export$56da0dd0b0aa67b9)
    ];
    const $d0f6eb494505673b$export$25fb39c3824c9c44 = ({ filter: filter })=>{
        const { owner: owner } = (0, $8Uumt.useFragment)((0, (0, parcelRequire("bB5VF")).default), filter);
        const onOwnerLinkClick = (0, $5uXOq.useCallback)((_, analyticsEvent)=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'filterOwner', {
                filterOwnerId: owner.accountId
            });
        }, [
            owner.accountId
        ]);
        const UserLink = (0, $5uXOq.useCallback)((msg)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jhmo0")).default), {
                xcss: $d0f6eb494505673b$var$linkStyle,
                href: (0, (0, parcelRequire("7I2zi")).generateUserLink)(owner.accountId),
                onClick: onOwnerLinkClick
            }, msg), [
            owner.accountId,
            onOwnerLinkClick
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7kWfK")).Section), null, (0, ($parcel$interopDefault($5uXOq))).createElement($d0f6eb494505673b$var$OwnerContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $d0f6eb494505673b$var$avatarContainerStyles
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kYYfm")).AsyncProfileCardNext), {
            accountId: owner.accountId,
            TenantContextSubscriber: (0, (0, parcelRequire("9OXo1")).TenantContextSubscriber),
            actions: $d0f6eb494505673b$var$PROFILE_ACTIONS
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ujWmd")).default), {
            src: owner.picture,
            size: "large",
            testId: "issue-navigator-custom-filters.ui.details-popup.popup-content.owner.avatar"
        }))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ousew")).default), {
            ...(0, (0, parcelRequire("xymkp")).default).owner,
            values: {
                anchor: UserLink,
                ownerName: owner.name
            }
        })));
    };
    const $d0f6eb494505673b$var$OwnerContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _4cvr1h6o _2lx2vrvc",
                __cmplp.className
            ])
        });
    });
    const $d0f6eb494505673b$var$avatarContainerStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        marginRight: 'space.100'
    });
    const $d0f6eb494505673b$var$linkStyle = (0, (0, parcelRequire("8CUq3")).xcss)({
        marginLeft: 'space.050',
        marginRight: 'space.050'
    });
});
parcelRegister("bB5VF", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$28caf394d8293f07$export$2e2bcd8739ae039);
    const $28caf394d8293f07$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "owner_issueNavigatorCustomFilters",
        "selections": [
            {
                "kind": "RequiredField",
                "field": {
                    "kind": "LinkedField",
                    "name": "owner",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "name": "accountId"
                        },
                        {
                            "kind": "ScalarField",
                            "name": "name"
                        },
                        {
                            "kind": "ScalarField",
                            "name": "picture"
                        }
                    ]
                },
                "action": "THROW",
                "path": "owner"
            }
        ],
        "type": "JiraCustomFilter"
    };
    $28caf394d8293f07$var$node.hash = "cb1b46f3f320b4c4793ee7c164c198c7";
    var $28caf394d8293f07$export$2e2bcd8739ae039 = $28caf394d8293f07$var$node;
});
parcelRegister("kYYfm", function(module, exports) {
    $parcel$export(module.exports, "AsyncProfileCardNext", ()=>$15521008cc986085$export$a3c0845f856d4292);
    var $5uXOq = parcelRequire("5uXOq");
    var $39xOx;
    var $gcl1l;
    var $lfTZh;
    var $kxZPA;
    const $15521008cc986085$var$LazyComponent = (0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("beEVQ")).then((module)=>module.ProfileCard), {
        moduleId: "./src/packages/profilecard-next/src/main.tsx"
    });
    const $15521008cc986085$export$a3c0845f856d4292 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "AsyncProfileCardNext",
            packageName: "jiraProfilecardNext",
            render: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, props.children),
            sendToPrivacyUnsafeSplunk: true,
            teamName: "empanada",
            attributes: (0, (0, parcelRequire("gcl1l")).isLoaderErrorAttributes)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "async-profile-card-next-view",
            fallback: props.children
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($15521008cc986085$var$LazyComponent, props)));
    var $15521008cc986085$export$2e2bcd8739ae039 = $15521008cc986085$export$a3c0845f856d4292;
});
parcelRegister("beEVQ", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("hPzOn"),
        $6fhBS("ek7ue")
    ]).then(()=>parcelRequire('jO6eK'));
});
const $e6a2e0952e4731b8$export$febebefae5a09065 = 'profile-card.loaded';
const $e6a2e0952e4731b8$export$3ca1936ee743bd18 = 'profile-card.view';
const $e6a2e0952e4731b8$export$25bd35f2cd9ae0ea = 'profile-card.reload';
const $e6a2e0952e4731b8$export$86aa8b9c379ebbbd = 'profile-card.error';
const $e6a2e0952e4731b8$export$4442e31da6ba4127 = 'profile-card.click';
const $e6a2e0952e4731b8$export$7ecd9c56ca354ac0 = 'profileCardNext';
const $e6a2e0952e4731b8$export$81319ffa9c0d18d7 = 'profileCardNext';
const $e6a2e0952e4731b8$export$3145fc6c544ad7bf = 'profileCardNextLegacy';
const $e6a2e0952e4731b8$export$50632e21ea714398 = 'profileCardNextLegacy';
const $e6a2e0952e4731b8$export$d053e144c034c8aa = 'teamProfileCardNextLegacy';
const $e6a2e0952e4731b8$export$2549357601611c61 = 'loadError';
const $e6a2e0952e4731b8$export$23cedd3261bd8015 = 'viewProfile';
const $e6a2e0952e4731b8$export$6bef782d14a92db8 = 'assignedIssues';
const $e6a2e0952e4731b8$export$6b36f20db7a18a86 = 'reportedIssues';
const $e6a2e0952e4731b8$export$56da0dd0b0aa67b9 = 'viewFilters';
parcelRegister("7I2zi", function(module, exports) {
    $parcel$export(module.exports, "generateUserLink", ()=>$9d3ef40360103f2e$export$f4673d7e11341514);
    $parcel$export(module.exports, "generateTeamLink", ()=>$9d3ef40360103f2e$export$b163ce931504dcff);
    $parcel$export(module.exports, "isTeamProfileCardEventContentRendered", ()=>$9d3ef40360103f2e$export$6dc006c3779708c0);
    $parcel$export(module.exports, "fireLegacyTeamAnalytics", ()=>$9d3ef40360103f2e$export$ffbbcf55d672ec61);
    var $8zOmE;
    const $9d3ef40360103f2e$export$f4673d7e11341514 = (userId)=>`/jira/people/${userId}`;
    const $9d3ef40360103f2e$export$b163ce931504dcff = (teamId)=>`/jira/people/team/${teamId}`;
    const $9d3ef40360103f2e$export$7b55c373f7e07896 = (label, profileUrl)=>({
            id: 'view-profile',
            label: label,
            callback: (event)=>{
                event.stopPropagation();
                window.open(profileUrl);
            }
        });
    const $9d3ef40360103f2e$export$6dc006c3779708c0 = (analyticsPayload)=>analyticsPayload?.action === 'rendered' && analyticsPayload?.actionSubjectId === 'content';
    const $9d3ef40360103f2e$export$ffbbcf55d672ec61 = (analyticsEvent)=>{
        if (analyticsEvent?.payload?.eventType === 'ui') {
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, `legacyTeamProfileCard ${analyticsEvent.payload.action}}`);
            if ($9d3ef40360103f2e$export$6dc006c3779708c0(analyticsEvent?.payload)) (0, (0, parcelRequire("8zOmE")).fireScreenAnalytics)(analyticsEvent);
        }
    };
});
parcelRegister("xymkp", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$f4c39e6b739b52d2$export$2e2bcd8739ae039);
    var $7VHMR;
    var $f4c39e6b739b52d2$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        owner: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.owner.owner",
            "defaultMessage": "Owned by <anchor>{ownerName}</anchor>"
        }
    });
});
parcelRegister("gVFu0", function(module, exports) {
    $parcel$export(module.exports, "Permissions", ()=>$e6f768241231e20a$export$2d41982586bbb6be);
    var $hScLY;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $fVnbM;
    var $koVbs;
    var $8zOmE;
    var $1xOsd;
    var $7kWfK;
    var $lhctX;
    var $6J1ZJ;
    var $1Mdhx;
    const $e6f768241231e20a$export$2d41982586bbb6be = ({ filter: filter, onOpenEditFilterModal: onOpenEditFilterModal })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const filterData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("hScLY")).default), filter);
        const onOpenEditPermissionsModal = (0, $5uXOq.useCallback)((_, analyticsEvent)=>{
            onOpenEditFilterModal();
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'editFilterPermissions');
        }, [
            onOpenEditFilterModal
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7kWfK")).Section), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1xOsd")).SectionHeader), {
            actions: filterData.isEditable === true ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                onClick: onOpenEditPermissionsModal,
                appearance: "link",
                spacing: "none",
                testId: "issue-navigator-custom-filters.ui.details-popup.popup-content.permissions.edit-permissions-button"
            }, formatMessage((0, (0, parcelRequire("1Mdhx")).default).editPermissions)) : null
        }, formatMessage((0, (0, parcelRequire("1Mdhx")).default).permissions)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lhctX")).Access), {
            filter: filterData
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6J1ZJ")).Edit), {
            filter: filterData
        }));
    };
});
parcelRegister("hScLY", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$65fee2ecc9815ad7$export$2e2bcd8739ae039);
    const $65fee2ecc9815ad7$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "permissions_issueNavigatorCustomFilters",
        "selections": [
            {
                "kind": "FragmentSpread",
                "name": "access_issueNavigatorCustomFilters"
            },
            {
                "kind": "FragmentSpread",
                "name": "edit_issueNavigatorCustomFilters"
            },
            {
                "kind": "ScalarField",
                "name": "isEditable"
            }
        ],
        "type": "JiraCustomFilter"
    };
    $65fee2ecc9815ad7$var$node.hash = "31add9aa56167a062969a5bd4dfaec78";
    var $65fee2ecc9815ad7$export$2e2bcd8739ae039 = $65fee2ecc9815ad7$var$node;
});
parcelRegister("lhctX", function(module, exports) {
    $parcel$export(module.exports, "Access", ()=>$b14400dfe9dfa99e$export$3026e5a52c747d2c);
    var $2dFW7;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $koVbs;
    var $g3bE2;
    var $jvnq8;
    var $ac33r;
    var $7kWfK;
    var $1sr0p;
    var $6K5XV;
    var $5HGLW;
    const $b14400dfe9dfa99e$export$3026e5a52c747d2c = ({ filter: filter })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { shareGrants: shareGrants } = (0, $8Uumt.useFragment)((0, (0, parcelRequire("2dFW7")).default), filter);
        const users = (0, $5uXOq.useMemo)(()=>shareGrants?.edges && shareGrants.edges?.length > 0 ? shareGrants?.edges.filter((shareableEntity)=>shareableEntity?.node && shareableEntity.node.__typename === 'JiraShareableEntityUserGrant').map((shareableEntity)=>shareableEntity?.node?.user ? shareableEntity.node.user.name : '') : [], [
            shareGrants?.edges
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7kWfK")).SectionBodyTitle), null, formatMessage((0, (0, parcelRequire("5HGLW")).default).visibleTo)), shareGrants?.edges && shareGrants.edges.length > 0 ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, shareGrants.edges.map((shareableEntity, key)=>{
            if (shareableEntity?.node) {
                const { __typename: __typename } = shareableEntity.node;
                if (__typename === 'JiraShareableEntityProjectGrant') return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jvnq8")).ProjectGrant), {
                    key: key,
                    entity: shareableEntity.node
                });
                if (__typename === 'JiraShareableEntityProjectRoleGrant') return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ac33r")).ProjectRoleGrant), {
                    key: key,
                    entity: shareableEntity.node
                });
                if (__typename === 'JiraShareableEntityGroupGrant') return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("g3bE2")).GroupGrant), {
                    key: key,
                    entity: shareableEntity.node
                });
                if (__typename === 'JiraShareableEntityAnonymousAccessGrant') return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7kWfK")).SectionBody), {
                    key: key
                }, formatMessage((0, (0, parcelRequire("5HGLW")).default).public));
                if (__typename === 'JiraShareableEntityAnyLoggedInUserGrant') return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7kWfK")).SectionBody), {
                    key: key
                }, formatMessage((0, (0, parcelRequire("5HGLW")).default).loggedInUsers));
                if (__typename === 'JiraShareableEntityUnknownProjectGrant') return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1sr0p")).UnknownProject), {
                    key: key
                });
            }
            return null;
        }), users.length > 0 && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6K5XV")).UsersGrant), {
            users: users
        })) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7kWfK")).SectionBody), null, formatMessage((0, (0, parcelRequire("5HGLW")).default).private)));
    };
});
parcelRegister("2dFW7", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$6e0a5deba8e52e7a$export$2e2bcd8739ae039);
    const $6e0a5deba8e52e7a$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "access_issueNavigatorCustomFilters",
        "selections": [
            {
                "concreteType": "JiraShareableEntityShareGrantConnection",
                "kind": "LinkedField",
                "name": "shareGrants",
                "plural": false,
                "selections": [
                    {
                        "concreteType": "JiraShareableEntityShareGrantEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "ScalarField",
                                        "name": "__typename"
                                    },
                                    {
                                        "kind": "FragmentSpread",
                                        "name": "projectGrant_issueNavigatorCustomFilters"
                                    },
                                    {
                                        "kind": "FragmentSpread",
                                        "name": "projectRoleGrant_issueNavigatorCustomFilters"
                                    },
                                    {
                                        "kind": "FragmentSpread",
                                        "name": "groupGrant_issueNavigatorCustomFilters"
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            {
                                                "kind": "LinkedField",
                                                "name": "user",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "name"
                                                    }
                                                ]
                                            }
                                        ],
                                        "type": "JiraShareableEntityUserGrant"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ],
        "type": "JiraCustomFilter"
    };
    $6e0a5deba8e52e7a$var$node.hash = "723acfaab9c38e502d502422ae67f3e2";
    var $6e0a5deba8e52e7a$export$2e2bcd8739ae039 = $6e0a5deba8e52e7a$var$node;
});
parcelRegister("g3bE2", function(module, exports) {
    $parcel$export(module.exports, "GroupGrant", ()=>$a9f081aeb50fc961$export$7ab94d64a59792d5);
    var $7SuER;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $koVbs;
    var $7kWfK;
    var $lfHfQ;
    const $a9f081aeb50fc961$export$7ab94d64a59792d5 = ({ entity: entity })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const groupGrant = (0, $8Uumt.useFragment)((0, (0, parcelRequire("7SuER")).default), entity);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7kWfK")).SectionBody), null, groupGrant.group && groupGrant.group?.name !== undefined && formatMessage((0, (0, parcelRequire("lfHfQ")).default).groupName, {
            name: groupGrant.group?.name
        }));
    };
});
parcelRegister("7SuER", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$1a164ceafca5f636$export$2e2bcd8739ae039);
    const $1a164ceafca5f636$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "groupGrant_issueNavigatorCustomFilters",
        "selections": [
            {
                "concreteType": "JiraGroup",
                "kind": "LinkedField",
                "name": "group",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "name": "name"
                    }
                ]
            }
        ],
        "type": "JiraShareableEntityGroupGrant"
    };
    $1a164ceafca5f636$var$node.hash = "5d137f8bd705292955fe74f248e79b45";
    var $1a164ceafca5f636$export$2e2bcd8739ae039 = $1a164ceafca5f636$var$node;
});
parcelRegister("lfHfQ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$f06054d8f2c43199$export$2e2bcd8739ae039);
    var $7VHMR;
    var $f06054d8f2c43199$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        groupName: {
            "id": "issue-navigator-custom-filters.common.details-popup.group-grant.group-name",
            "defaultMessage": "Group: {name}"
        }
    });
});
parcelRegister("jvnq8", function(module, exports) {
    $parcel$export(module.exports, "ProjectGrant", ()=>$0f668964e1d11678$export$c0fe5a638e35f019);
    var $e22xN;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $e4yMr;
    var $8Q4sF;
    var $koVbs;
    var $8zOmE;
    var $7kWfK;
    var $iw2Na;
    const $0f668964e1d11678$var$linkStart = '{linkStart}';
    const $0f668964e1d11678$var$linkEnd = '{linkEnd}';
    const $0f668964e1d11678$export$c0fe5a638e35f019 = ({ entity: entity })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const projectGrant = (0, $8Uumt.useFragment)((0, (0, parcelRequire("e22xN")).default), entity);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7kWfK")).SectionBody), null, projectGrant.project?.name && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8Q4sF")).FormattedI18nMessage), {
            componentsMapping: {
                link: ({ children: children })=>projectGrant.project?.key ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("e4yMr")).default), {
                        href: `/browse/${projectGrant.project?.key}`,
                        onClick: (_, analyticsEvent)=>(0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'projectGrant')
                    }, children) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children)
            },
            message: formatMessage((0, (0, parcelRequire("iw2Na")).default).projectNameLink, {
                name: projectGrant.project?.name,
                linkStart: $0f668964e1d11678$var$linkStart,
                linkEnd: $0f668964e1d11678$var$linkEnd
            })
        }));
    };
});
parcelRegister("e22xN", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$8d5ec7650a67141d$export$2e2bcd8739ae039);
    const $8d5ec7650a67141d$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "projectGrant_issueNavigatorCustomFilters",
        "selections": [
            {
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "name": "name"
                    },
                    {
                        "kind": "ScalarField",
                        "name": "key"
                    }
                ]
            }
        ],
        "type": "JiraShareableEntityProjectGrant"
    };
    $8d5ec7650a67141d$var$node.hash = "a6f0045e8c355671600e72844955bb81";
    var $8d5ec7650a67141d$export$2e2bcd8739ae039 = $8d5ec7650a67141d$var$node;
});
parcelRegister("iw2Na", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$d702b23630ef5302$export$2e2bcd8739ae039);
    var $7VHMR;
    var $d702b23630ef5302$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        projectNameLink: {
            "id": "issue-navigator-custom-filters.common.details-popup.project-grant.project-name-link",
            "defaultMessage": "Project: {linkStart}{name}{linkEnd} (Everyone)"
        }
    });
});
parcelRegister("ac33r", function(module, exports) {
    $parcel$export(module.exports, "ProjectRoleGrant", ()=>$e505d32c633c280b$export$7da97d7ea4daa99e);
    var $4C7qB;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $e4yMr;
    var $8Q4sF;
    var $koVbs;
    var $8zOmE;
    var $7kWfK;
    var $c8Ts1;
    const $e505d32c633c280b$var$linkStart = '{linkStart}';
    const $e505d32c633c280b$var$linkEnd = '{linkEnd}';
    const $e505d32c633c280b$export$7da97d7ea4daa99e = ({ entity: entity })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const projectRoleGrant = (0, $8Uumt.useFragment)((0, (0, parcelRequire("4C7qB")).default), entity);
        const message = formatMessage((0, (0, parcelRequire("c8Ts1")).default).projectRoleNameLink, {
            name: projectRoleGrant.project?.name,
            role: projectRoleGrant.role?.name,
            linkStart: $e505d32c633c280b$var$linkStart,
            linkEnd: $e505d32c633c280b$var$linkEnd
        });
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7kWfK")).SectionBody), null, projectRoleGrant.project?.name !== undefined && projectRoleGrant.role?.name !== undefined && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8Q4sF")).FormattedI18nMessage), {
            componentsMapping: {
                link: ({ children: children })=>projectRoleGrant.project?.key ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("e4yMr")).default), {
                        href: `/browse/${projectRoleGrant.project?.key}`,
                        onClick: (_, analyticsEvent)=>(0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'projectRoleGrant')
                    }, children) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children)
            },
            message: message
        }));
    };
});
parcelRegister("4C7qB", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$b3bdf53cdc0390c2$export$2e2bcd8739ae039);
    const $b3bdf53cdc0390c2$var$node = function() {
        var v0 = {
            "kind": "ScalarField",
            "name": "name"
        };
        return {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "name": "projectRoleGrant_issueNavigatorCustomFilters",
            "selections": [
                {
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                        v0,
                        {
                            "kind": "ScalarField",
                            "name": "key"
                        }
                    ]
                },
                {
                    "concreteType": "JiraRole",
                    "kind": "LinkedField",
                    "name": "role",
                    "plural": false,
                    "selections": [
                        v0
                    ]
                }
            ],
            "type": "JiraShareableEntityProjectRoleGrant"
        };
    }();
    $b3bdf53cdc0390c2$var$node.hash = "0353d445a52987cb7c245d2ca5cd2d8c";
    var $b3bdf53cdc0390c2$export$2e2bcd8739ae039 = $b3bdf53cdc0390c2$var$node;
});
parcelRegister("c8Ts1", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$fa4a660c80587b24$export$2e2bcd8739ae039);
    var $7VHMR;
    var $fa4a660c80587b24$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        projectRoleNameLink: {
            "id": "issue-navigator-custom-filters.common.details-popup.project-role-grant.project-role-name-link",
            "defaultMessage": "Project: {linkStart}{name}{linkEnd} ({role})"
        }
    });
});
parcelRegister("1sr0p", function(module, exports) {
    $parcel$export(module.exports, "UnknownProject", ()=>$8911a7d61838d6f8$export$a8bd5ea331aa2c2c);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $eJS47;
    var $3bDjY;
    var $koVbs;
    var $7kWfK;
    var $19Z3I;
    const $8911a7d61838d6f8$export$a8bd5ea331aa2c2c = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7kWfK")).SectionBody), null, formatMessage((0, (0, parcelRequire("19Z3I")).default).unknownProject), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
            content: formatMessage((0, (0, parcelRequire("19Z3I")).default).infoTooltip),
            tag: $8911a7d61838d6f8$var$QuestionCircleIconWrapper
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("eJS47"))))), {
            label: formatMessage((0, (0, parcelRequire("19Z3I")).default).infoTooltip),
            size: "small"
        })));
    };
    const $8911a7d61838d6f8$var$QuestionCircleIconWrapper = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1o8l _19bv1b66 _s7n4nkob _80omtlke",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("19Z3I", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$04ba52e18fca98e7$export$2e2bcd8739ae039);
    var $7VHMR;
    var $04ba52e18fca98e7$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        unknownProject: {
            "id": "issue-navigator-custom-filters.common.details-popup.unknown-project.unknown-project",
            "defaultMessage": "Project: unknown"
        },
        infoTooltip: {
            "id": "issue-navigator-custom-filters.common.details-popup.unknown-project.info-tooltip",
            "defaultMessage": "You do not have access to this project"
        }
    });
});
parcelRegister("6K5XV", function(module, exports) {
    $parcel$export(module.exports, "UsersGrant", ()=>$0277383aeba74910$export$eafe47c6bcbc8617);
    var $5uXOq = parcelRequire("5uXOq");
    var $koVbs;
    var $7kWfK;
    var $5pJGt;
    const $0277383aeba74910$export$eafe47c6bcbc8617 = ({ users: users })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7kWfK")).SectionBody), null, formatMessage((0, (0, parcelRequire("5pJGt")).default).users), ":", ' ', users.map((user, index)=>(0, ($parcel$interopDefault($5uXOq))).createElement("span", {
                key: user
            }, user, index < users.length - 1 ? ', ' : '')));
    };
});
parcelRegister("5pJGt", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$665ddacbbd6fd714$export$2e2bcd8739ae039);
    var $7VHMR;
    var $665ddacbbd6fd714$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        users: {
            "id": "issue-navigator-custom-filters.common.details-popup.users-grant.users",
            "defaultMessage": "Users"
        }
    });
});
parcelRegister("5HGLW", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$91aed0cb1f9e9339$export$2e2bcd8739ae039);
    var $7VHMR;
    var $91aed0cb1f9e9339$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        private: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.permissions.access.private",
            "defaultMessage": "Private"
        },
        visibleTo: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.permissions.access.visible-to",
            "defaultMessage": "Visible to:"
        },
        public: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.permissions.access.public",
            "defaultMessage": "Public"
        },
        loggedInUsers: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.permissions.access.logged-in-users",
            "defaultMessage": "Logged in users on this instance"
        }
    });
});
parcelRegister("6J1ZJ", function(module, exports) {
    $parcel$export(module.exports, "Edit", ()=>$26bb7429aec51ede$export$6ed0965c9443aaa6);
    var $22PpD;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $koVbs;
    var $g3bE2;
    var $jvnq8;
    var $ac33r;
    var $7kWfK;
    var $1sr0p;
    var $6K5XV;
    var $7mEdZ;
    const $26bb7429aec51ede$export$6ed0965c9443aaa6 = ({ filter: filter })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { editGrants: editGrants } = (0, $8Uumt.useFragment)((0, (0, parcelRequire("22PpD")).default), filter);
        const users = (0, $5uXOq.useMemo)(()=>editGrants?.edges && editGrants.edges?.length > 0 ? editGrants?.edges.filter((shareableEntity)=>shareableEntity?.node && shareableEntity.node.__typename === 'JiraShareableEntityUserGrant').map((shareableEntity)=>shareableEntity?.node?.user ? shareableEntity.node.user.name : '') : [], [
            editGrants?.edges
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7kWfK")).SectionBodyTitle), null, formatMessage((0, (0, parcelRequire("7mEdZ")).default).editabledBy)), editGrants?.edges && editGrants.edges?.length > 0 ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, editGrants.edges.map((shareableEntity, key)=>{
            if (shareableEntity?.node) {
                const { __typename: __typename } = shareableEntity?.node;
                if (__typename === 'JiraShareableEntityProjectGrant') return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jvnq8")).ProjectGrant), {
                    key: key,
                    entity: shareableEntity.node
                });
                if (__typename === 'JiraShareableEntityProjectRoleGrant') return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ac33r")).ProjectRoleGrant), {
                    key: key,
                    entity: shareableEntity.node
                });
                if (__typename === 'JiraShareableEntityGroupGrant') return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("g3bE2")).GroupGrant), {
                    key: key,
                    entity: shareableEntity.node
                });
                if (__typename === 'JiraShareableEntityUnknownProjectGrant') return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1sr0p")).UnknownProject), {
                    key: key
                });
            }
            return null;
        }), users.length > 0 && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6K5XV")).UsersGrant), {
            users: users
        })) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7kWfK")).SectionBody), null, formatMessage((0, (0, parcelRequire("7mEdZ")).default).private)));
    };
});
parcelRegister("22PpD", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$131694b96f2213d4$export$2e2bcd8739ae039);
    const $131694b96f2213d4$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "edit_issueNavigatorCustomFilters",
        "selections": [
            {
                "concreteType": "JiraShareableEntityEditGrantConnection",
                "kind": "LinkedField",
                "name": "editGrants",
                "plural": false,
                "selections": [
                    {
                        "concreteType": "JiraShareableEntityEditGrantEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "ScalarField",
                                        "name": "__typename"
                                    },
                                    {
                                        "kind": "FragmentSpread",
                                        "name": "projectGrant_issueNavigatorCustomFilters"
                                    },
                                    {
                                        "kind": "FragmentSpread",
                                        "name": "projectRoleGrant_issueNavigatorCustomFilters"
                                    },
                                    {
                                        "kind": "FragmentSpread",
                                        "name": "groupGrant_issueNavigatorCustomFilters"
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            {
                                                "kind": "LinkedField",
                                                "name": "user",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "name"
                                                    }
                                                ]
                                            }
                                        ],
                                        "type": "JiraShareableEntityUserGrant"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ],
        "type": "JiraCustomFilter"
    };
    $131694b96f2213d4$var$node.hash = "cb8085a4ada55e8e573b553cfab4d229";
    var $131694b96f2213d4$export$2e2bcd8739ae039 = $131694b96f2213d4$var$node;
});
parcelRegister("7mEdZ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$871453c42c2eb9aa$export$2e2bcd8739ae039);
    var $7VHMR;
    var $871453c42c2eb9aa$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        private: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.permissions.edit.private",
            "defaultMessage": "Private"
        },
        editabledBy: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.permissions.edit.editabled-by",
            "defaultMessage": "Editable by:"
        }
    });
});
parcelRegister("1Mdhx", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$0cc04d7911b28eec$export$2e2bcd8739ae039);
    var $7VHMR;
    var $0cc04d7911b28eec$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        permissions: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.permissions.permissions",
            "defaultMessage": "Permissions"
        },
        editPermissions: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.permissions.edit-permissions",
            "defaultMessage": "Edit Permissions"
        }
    });
});
parcelRegister("ghHbc", function(module, exports) {
    $parcel$export(module.exports, "Subscriptions", ()=>$ec61f12cc29817fb$export$d73dcc2e0bdfb5bd);
    var $arIzK;
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Uumt = parcelRequire("8Uumt");
    var $fVnbM;
    var $iRH6p;
    var $f5TWP;
    var $cgL5K;
    var $3bDjY;
    var $41j30;
    var $6s1PB;
    var $koVbs;
    var $8zOmE;
    var $1xOsd;
    var $7kWfK;
    var $evIOd;
    var $5Vx5Z;
    var $hnHBW;
    const $ec61f12cc29817fb$export$d73dcc2e0bdfb5bd = ({ filter: filter, onOpenNewSubscriptionModal: onOpenNewSubscriptionModal, onSuccess: onSuccess })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const data = (0, $8Uumt.useFragment)((0, (0, parcelRequire("arIzK")).default), filter);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7kWfK")).Section), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1xOsd")).SectionHeader), {
            actions: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("f5TWP")).default), {
                space: "space.050",
                alignBlock: "center",
                shouldWrap: true
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5Vx5Z")).AddSubscriptionModalProvider), {
                filter: data,
                onOpenNewSubscriptionModal: onOpenNewSubscriptionModal,
                onSuccess: onSuccess
            }, ({ isLoading: isLoading, open: open, preload: preload })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                    appearance: "link",
                    spacing: "none",
                    onClick: open,
                    onMouseOver: preload,
                    isDisabled: isLoading,
                    testId: "issue-navigator-custom-filters.ui.details-popup.popup-content.subscriptions.add-subscription-button"
                }, formatMessage((0, (0, parcelRequire("hnHBW")).default).addSubscription))), data.emailSubscriptions.edges.length > 0 && (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), null, "\u2022"), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("41j30")).default), {
                href: (0, (0, parcelRequire("evIOd")).getManageSubscriptionsLink)(data.filterId),
                onClick: (_, analyticsEvent)=>(0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'manageSubscriptions'),
                "data-testid": "issue-navigator-custom-filters.ui.details-popup.popup-content.subscriptions.manage-subscriptions-link"
            }, formatMessage((0, (0, parcelRequire("hnHBW")).default).manageSubscriptions))))
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("f5TWP")).default), {
            space: "space.025",
            alignBlock: "center"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
            weight: "semibold"
        }, formatMessage((0, (0, parcelRequire("hnHBW")).default).subscriptions)), !data.emailSubscriptions.edges.length && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
            content: formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("hnHBW")).default).subscriptionsInfoTooltipIssueTermRefresh : (0, (0, parcelRequire("hnHBW")).default).subscriptionsInfoTooltip),
            tag: $ec61f12cc29817fb$var$InfoIconWrapper
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("iRH6p"))))), {
            label: formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("hnHBW")).default).subscriptionsInfoTooltipIssueTermRefresh : (0, (0, parcelRequire("hnHBW")).default).subscriptionsInfoTooltip),
            size: "medium"
        })))), data.emailSubscriptions.edges.length ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement("p", null, formatMessage((0, (0, parcelRequire("hnHBW")).default).hasTheFollowingSubscriptions)), (0, ($parcel$interopDefault($5uXOq))).createElement($ec61f12cc29817fb$var$SubscriptionList, null, data.emailSubscriptions.edges.map((edge)=>edge?.node ? (0, ($parcel$interopDefault($5uXOq))).createElement("li", {
                key: edge.node.id
            }, edge.node.group ? edge.node.group.name : edge.node.user?.name) : null))) : (0, ($parcel$interopDefault($5uXOq))).createElement("p", null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7kWfK")).PlaceholderText), null, formatMessage((0, (0, parcelRequire("hnHBW")).default).subscriptionsPlaceholder))));
    };
    const $ec61f12cc29817fb$var$InfoIconWrapper = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_19pkr5cr _4t3i77iu",
                __cmplp.className
            ])
        });
    });
    const $ec61f12cc29817fb$var$SubscriptionList = (0, $5uXOq.forwardRef)(({ as: C = "ul", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_2mzuglyw _ca0qidpf _u5f3idpf _n3tdidpf _19bvidpf",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("arIzK", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$fa4e67fab805a5c5$export$2e2bcd8739ae039);
    const $fa4e67fab805a5c5$var$node = function() {
        var v0 = [
            {
                "kind": "ScalarField",
                "name": "name"
            }
        ];
        return {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "name": "subscriptions_issueNavigatorCustomFilters",
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "filterId"
                },
                {
                    "kind": "FragmentSpread",
                    "name": "addSubscriptionModal_issueNavigatorCustomFilters_AddSubscriptionModalProvider"
                },
                {
                    "kind": "RequiredField",
                    "field": {
                        "args": [
                            {
                                "kind": "Literal",
                                "name": "last",
                                "value": 5
                            }
                        ],
                        "concreteType": "JiraFilterEmailSubscriptionConnection",
                        "kind": "LinkedField",
                        "name": "emailSubscriptions",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "RequiredField",
                                "field": {
                                    "concreteType": "JiraFilterEmailSubscriptionEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "concreteType": "JiraFilterEmailSubscription",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "ScalarField",
                                                    "name": "id"
                                                },
                                                {
                                                    "kind": "LinkedField",
                                                    "name": "user",
                                                    "plural": false,
                                                    "selections": v0
                                                },
                                                {
                                                    "concreteType": "JiraGroup",
                                                    "kind": "LinkedField",
                                                    "name": "group",
                                                    "plural": false,
                                                    "selections": v0
                                                }
                                            ]
                                        }
                                    ]
                                },
                                "action": "THROW",
                                "path": "emailSubscriptions.edges"
                            }
                        ],
                        "storageKey": "emailSubscriptions(last:5)"
                    },
                    "action": "THROW",
                    "path": "emailSubscriptions"
                }
            ],
            "type": "JiraCustomFilter"
        };
    }();
    $fa4e67fab805a5c5$var$node.hash = "85c47f44d1a205822525c56240e74538";
    var $fa4e67fab805a5c5$export$2e2bcd8739ae039 = $fa4e67fab805a5c5$var$node;
});
parcelRegister("iRH6p", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $dbbe9c7ad934f1e0$var$_react = $dbbe9c7ad934f1e0$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $dbbe9c7ad934f1e0$var$_information = $dbbe9c7ad934f1e0$var$_interopRequireDefault((parcelRequire("2Yv61")));
    function $dbbe9c7ad934f1e0$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $dbbe9c7ad934f1e0$var$EditorInfoIcon = (props)=>$dbbe9c7ad934f1e0$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><path fill="currentcolor" fill-rule="evenodd" d="M12 20a8 8 0 1 1 0-16 8 8 0 0 1 0 16m0-8.5a1 1 0 0 0-1 1V15a1 1 0 0 0 2 0v-2.5a1 1 0 0 0-1-1m0-1.125a1.375 1.375 0 1 0 0-2.75 1.375 1.375 0 0 0 0 2.75"/></svg>`
        }, props, {
            newIcon: $dbbe9c7ad934f1e0$var$_information.default
        }));
    $dbbe9c7ad934f1e0$var$EditorInfoIcon.displayName = 'EditorInfoIcon';
    var $dbbe9c7ad934f1e0$var$_default = module.exports.default = $dbbe9c7ad934f1e0$var$EditorInfoIcon;
});
parcelRegister("41j30", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$7c2f3c33a6194375$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $8oe80;
    var $hDzhb;
    function $7c2f3c33a6194375$var$Link(props) {
        const { children: children, ...rest } = props;
        delete rest.createAnalyticsEvent;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hDzhb")).default), rest, children);
    }
    var $7c2f3c33a6194375$export$2e2bcd8739ae039 = (0, (0, parcelRequire("8oe80")).default)('link', {
        onClick: 'clicked'
    })($7c2f3c33a6194375$var$Link);
});
parcelRegister("evIOd", function(module, exports) {
    $parcel$export(module.exports, "getManageSubscriptionsLink", ()=>$ea79a1614bbba455$export$426da445adfeedc1);
    const $ea79a1614bbba455$export$426da445adfeedc1 = (filterId)=>`/secure/ViewSubscriptions.jspa?filterId=${filterId}`;
});
parcelRegister("5Vx5Z", function(module, exports) {
    $parcel$export(module.exports, "AddSubscriptionModalProvider", ()=>$ce3a4a7692cedeb9$export$1b56ce395ad0fc78);
    var $cpoBb;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $2NMNM;
    var $jcw0u;
    var $jJZqs;
    var $iwPwz;
    var $pa9q9;
    var $8zOmE;
    var $4su49;
    var $evIOd;
    var $eyQMS;
    var $2xlLu;
    const $ce3a4a7692cedeb9$var$WRM_RESOURCES = [
        'wrc!jira.webresources:croneditor'
    ];
    const $ce3a4a7692cedeb9$var$preload = ()=>{
        (0, (0, parcelRequire("4su49")).asyncWrmRequire)($ce3a4a7692cedeb9$var$WRM_RESOURCES);
    };
    const $ce3a4a7692cedeb9$var$getFormDialog = ()=>(0, (0, parcelRequire("4su49")).asyncWrmRequire)($ce3a4a7692cedeb9$var$WRM_RESOURCES).then(()=>window.JIRA.FormDialog);
    const $ce3a4a7692cedeb9$export$1b56ce395ad0fc78 = ({ filter: filter, children: children, onError: onError, onOpenNewSubscriptionModal: onOpenNewSubscriptionModal, onSuccess: onSuccess })=>{
        const [isLoading, setLoading] = (0, $5uXOq.useState)(false);
        const { showFlag: showFlag } = (0, (0, parcelRequire("pa9q9")).useFlagService)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const filterData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("cpoBb")).default), filter);
        const onSuccessfulSubmit = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireTrackAnalytics)(createAnalyticsEvent({
                actionSubject: 'issueFilterSubscription',
                action: 'created'
            }), 'issueFilterSubscription created', {
                issueFilterNameLength: filterData.name.length,
                issueFilterDescriptionLength: filterData.description?.length,
                issueFilterOwner: filterData.owner?.accountId
            });
            onSuccess && onSuccess();
        }, [
            createAnalyticsEvent,
            filterData,
            onSuccess
        ]);
        const open = (0, $5uXOq.useCallback)(()=>{
            (async ()=>{
                (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                    action: 'clicked',
                    actionSubject: 'button'
                }), 'addFilterSubscription');
                (0, (0, parcelRequire("eyQMS")).viewNewSubscriptionModal).start();
                setLoading(true);
                try {
                    const FormDialog = await $ce3a4a7692cedeb9$var$getFormDialog();
                    const dialog = new FormDialog({
                        ajaxOptions: {
                            url: `https://${window.location.hostname}/secure/EditSubscription!default.jspa?filterId=${filterData.filterId}`,
                            data: {
                                decorator: 'dialog',
                                inline: 'true'
                            }
                        },
                        autoClose: true,
                        onUnSuccessfulSubmit (statusText, errorThrown, smartAjaxResult) {
                            const error = errorThrown || new (0, (0, parcelRequire("jJZqs")).default)(smartAjaxResult.status, undefined, (0, (0, parcelRequire("iwPwz")).getTraceIdJq)(smartAjaxResult.xhr));
                            (0, (0, parcelRequire("jcw0u")).default)({
                                meta: {
                                    id: 'AddSubscriptionModalProvider.onUnSuccessfulSubmit',
                                    packageName: (0, $c18cf10dfb008b12$export$ad257a98f20ee631),
                                    teamName: 'empanada'
                                },
                                error: error,
                                sendToPrivacyUnsafeSplunk: true
                            });
                            onError && onError(error);
                        },
                        onDialogFinished () {
                            onSuccessfulSubmit();
                            showFlag({
                                type: 'success',
                                title: (0, (0, parcelRequire("2xlLu")).default).successFlagTitle,
                                description: [
                                    (0, (0, parcelRequire("2xlLu")).default).successFlagDescription,
                                    {
                                        filterName: filterData.name
                                    }
                                ],
                                actions: [
                                    {
                                        content: (0, (0, parcelRequire("2xlLu")).default).successFlagActionManage,
                                        href: (0, (0, parcelRequire("evIOd")).getManageSubscriptionsLink)(filterData.filterId),
                                        target: '_blank'
                                    }
                                ]
                            });
                        }
                    });
                    dialog.onContentReadyCallbacks?.push(()=>{
                        (0, (0, parcelRequire("eyQMS")).viewNewSubscriptionModal).success();
                    });
                    dialog.show();
                    onOpenNewSubscriptionModal && onOpenNewSubscriptionModal();
                } catch (error) {
                    showFlag({
                        type: 'error',
                        title: (0, (0, parcelRequire("2xlLu")).default).showModalErrorTitle,
                        description: (0, (0, parcelRequire("2xlLu")).default).showModalErrorDescription,
                        error: error
                    });
                    (0, (0, parcelRequire("jcw0u")).default)({
                        meta: {
                            id: 'AddSubscriptionModalProvider',
                            packageName: (0, $c18cf10dfb008b12$export$ad257a98f20ee631),
                            teamName: 'empanada'
                        },
                        error: error,
                        sendToPrivacyUnsafeSplunk: true
                    });
                    (0, (0, parcelRequire("eyQMS")).viewNewSubscriptionModal).failure();
                }
                setLoading(false);
            })();
        }, [
            createAnalyticsEvent,
            filterData,
            showFlag,
            onError,
            onOpenNewSubscriptionModal,
            onSuccessfulSubmit
        ]);
        return children({
            isLoading: isLoading,
            open: open,
            preload: $ce3a4a7692cedeb9$var$preload
        });
    };
});
parcelRegister("cpoBb", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$0b2ef7fe09dc89bc$export$2e2bcd8739ae039);
    const $0b2ef7fe09dc89bc$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "addSubscriptionModal_issueNavigatorCustomFilters_AddSubscriptionModalProvider",
        "selections": [
            {
                "kind": "RequiredField",
                "field": {
                    "kind": "ScalarField",
                    "name": "filterId"
                },
                "action": "THROW",
                "path": "filterId"
            },
            {
                "kind": "RequiredField",
                "field": {
                    "kind": "ScalarField",
                    "name": "name"
                },
                "action": "THROW",
                "path": "name"
            },
            {
                "kind": "ScalarField",
                "name": "description"
            },
            {
                "kind": "LinkedField",
                "name": "owner",
                "plural": false,
                "selections": [
                    {
                        "kind": "RequiredField",
                        "field": {
                            "kind": "ScalarField",
                            "name": "accountId"
                        },
                        "action": "THROW",
                        "path": "owner.accountId"
                    }
                ]
            }
        ],
        "type": "JiraCustomFilter"
    };
    $0b2ef7fe09dc89bc$var$node.hash = "9d8d65563104a3f742dd7a5adc73ec81";
    var $0b2ef7fe09dc89bc$export$2e2bcd8739ae039 = $0b2ef7fe09dc89bc$var$node;
});
parcelRegister("2xlLu", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$d54da6ee7e6a1e9c$export$2e2bcd8739ae039);
    var $7VHMR;
    var $d54da6ee7e6a1e9c$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        showModalErrorTitle: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.subscriptions.add-subscription-modal.show-modal-error-title",
            "defaultMessage": "Something went wrong"
        },
        showModalErrorDescription: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.subscriptions.add-subscription-modal.show-modal-error-description",
            "defaultMessage": "We couldn't create a new subscription. Please refresh the page and try again."
        },
        successFlagTitle: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.subscriptions.add-subscription-modal.success-flag-title",
            "defaultMessage": "Subscription created"
        },
        successFlagDescription: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.subscriptions.add-subscription-modal.success-flag-description",
            "defaultMessage": "You\u2019ve created a subscription for \u201C{filterName}\u201D filter."
        },
        successFlagActionManage: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.subscriptions.add-subscription-modal.success-flag-action-manage",
            "defaultMessage": "Manage subscriptions"
        }
    });
});
parcelRegister("hnHBW", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$dc582a4f423b0f58$export$2e2bcd8739ae039);
    var $7VHMR;
    var $dc582a4f423b0f58$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        subscriptions: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.subscriptions.subscriptions",
            "defaultMessage": "Subscriptions"
        },
        addSubscription: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.subscriptions.add-subscription",
            "defaultMessage": "Add subscription"
        },
        manageSubscriptions: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.subscriptions.manage-subscriptions",
            "defaultMessage": "Manage subscriptions"
        },
        subscriptionsPlaceholder: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.subscriptions.subscriptions-placeholder",
            "defaultMessage": "This filter doesn't have any subscriptions."
        },
        subscriptionsInfoTooltip: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.subscriptions.subscriptions-info-tooltip",
            "defaultMessage": "Get regular updates from issues in this filter."
        },
        hasTheFollowingSubscriptions: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.subscriptions.has-the-following-subscriptions",
            "defaultMessage": "This filter has the following subscriptions:"
        },
        subscriptionsInfoTooltipIssueTermRefresh: {
            "id": "issue-navigator-custom-filters.details-popup.popup-content.subscriptions.subscriptions-info-tooltip-issue-term-refresh",
            "defaultMessage": "Get regular updates from work in this filter."
        }
    });
});
parcelRegister("cgTfp", function(module, exports) {
    $parcel$export(module.exports, "DetailsButtonHeaderWrapper", ()=>$f2f63e79032646e3$export$1eb74eb504903ed6);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    const $f2f63e79032646e3$export$1eb74eb504903ed6 = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1wybdlk8 _k48p4jg8 _18s8otz2 _19pkv77o",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("2jaAN", function(module, exports) {
    $parcel$export(module.exports, "FavoriteButton", ()=>$dc82f725480f4be1$export$8d5dab2ad1e19ea0);
    $parcel$export(module.exports, "FavoriteButtonHeaderWrapper", ()=>$dc82f725480f4be1$export$5ca4047aa6ecffac);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $hLZZY;
    var $eXQym;
    const $dc82f725480f4be1$export$8d5dab2ad1e19ea0 = ({ filterId: filterId, isFavoriteInitial: isFavoriteInitial, favoriteItemName: favoriteItemName })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hLZZY")).default), {
            key: filterId,
            baseUrl: "",
            itemType: (0, (0, parcelRequire("eXQym")).FILTERS_ITEM_TYPE),
            itemId: filterId,
            isFavoriteInitial: isFavoriteInitial,
            favoriteItemName: favoriteItemName,
            isShownInList: true
        });
    const $dc82f725480f4be1$export$5ca4047aa6ecffac = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1wybdlk8 _4t3izwfg _18s81nu5",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("hLZZY", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$a968ac170a325ff4$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $4041n;
    var $fH2dG;
    class $a968ac170a325ff4$export$2e2bcd8739ae039 extends (0, $5uXOq.Component) {
        render() {
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4041n")).FavoriteChangeConsumer), null, (favoriteChangeContext)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fH2dG")).FavoriteButton), {
                    ...this.props,
                    favoriteChangeContext: favoriteChangeContext
                }));
        }
    }
});
parcelRegister("fH2dG", function(module, exports) {
    $parcel$export(module.exports, "FavoriteButton", ()=>$e0f2aac973023e83$export$8d5dab2ad1e19ea0);
    var $5uXOq = parcelRequire("5uXOq");
    var $8Rkzz = parcelRequire("8Rkzz");
    var $hcObS;
    var $eXQym;
    var $1WPu2;
    const $e0f2aac973023e83$var$isProduction = window.BUILD_KEY !== 'JF-TEST';
    class $e0f2aac973023e83$export$8d5dab2ad1e19ea0 extends (0, $5uXOq.Component) {
        static{
            this.defaultProps = {
                isFavoriteInitial: undefined,
                favoriteItemName: undefined,
                isSmall: false,
                hideTooltip: false,
                onClick: (0, ($parcel$interopDefault($8Rkzz)))
            };
        }
        constructor(props){
            super(props);
            this.onClick = (analyticsEvent)=>{
                const { favoriteChangeContext: favoriteChangeContext, itemId: itemId, itemType: itemType, onClick: onClick } = this.props;
                const { changeFavorite: changeFavorite } = favoriteChangeContext;
                if (changeFavorite) changeFavorite({
                    id: itemId,
                    type: itemType,
                    value: !this.getIsFavorite()
                }, analyticsEvent);
                if (typeof onClick === 'function') onClick();
            };
            this.state = {
                isFavorite: props.isFavoriteInitial
            };
        }
        componentDidMount() {
            const { baseUrl: baseUrl, itemId: itemId, itemType: itemType } = this.props;
            if (typeof baseUrl === 'undefined' || typeof itemId === 'undefined' || typeof itemType === 'undefined') return;
            if (!$e0f2aac973023e83$var$isProduction && !(0, (0, parcelRequire("eXQym")).VALID_ITEM_TYPES).includes(itemType)) throw new Error(`FavoriteButton: Invalid value for prop 'type'.\n You passed: "${itemType}"\n It must be one of [${JSON.stringify((0, (0, parcelRequire("eXQym")).VALID_ITEM_TYPES))}]\n\n`);
            if (typeof this.props.isFavoriteInitial !== 'undefined') this.props.favoriteChangeContext.items[itemType][itemId] = {
                id: itemId,
                type: itemType,
                value: this.props.isFavoriteInitial,
                pending: false
            };
            if (this.getIsFavorite() === undefined) (0, (0, parcelRequire("1WPu2")).getFavorite)(baseUrl, {
                id: itemId,
                type: itemType
            }).then((isFavorite)=>{
                this.setState({
                    isFavorite: isFavorite
                });
            });
        }
        getIsFavorite() {
            const { favoriteChangeContext: favoriteChangeContext, itemId: itemId, itemType: itemType } = this.props;
            let itemChanged;
            if (typeof favoriteChangeContext.items[itemType] !== 'undefined' && typeof favoriteChangeContext.items[itemType][itemId] !== 'undefined') itemChanged = favoriteChangeContext.items[itemType][itemId];
            return itemChanged ? itemChanged.value : this.state.isFavorite;
        }
        getIsPending() {
            const { favoriteChangeContext: favoriteChangeContext, itemId: itemId, itemType: itemType } = this.props;
            let itemChanged;
            if (typeof favoriteChangeContext.items[itemType] !== 'undefined' && typeof favoriteChangeContext.items[itemType][itemId] !== 'undefined') itemChanged = favoriteChangeContext.items[itemType][itemId];
            return itemChanged ? itemChanged.pending : false;
        }
        render() {
            return (0, ($parcel$interopDefault($5uXOq))).createElement("div", {
                "data-testid": "favourite-button.favorite-button"
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hcObS")).default), {
                isFavorite: this.getIsFavorite(),
                favoriteItemName: this.props.favoriteItemName,
                pending: this.getIsPending(),
                hideTooltip: this.props.hideTooltip,
                tooltipPosition: this.props.tooltipPosition,
                onClick: this.onClick,
                isShownInList: this.props.isShownInList,
                isSmall: this.props.isSmall
            }));
        }
    }
});
parcelRegister("hcObS", function(module, exports) {
    $parcel$export(module.exports, "FavoriteButtonWrapper", ()=>$0a68425ce3fc2fa8$export$bb1478fee6cd5f0c);
    $parcel$export(module.exports, "default", ()=>$0a68425ce3fc2fa8$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $basmW = parcelRequire("basmW");
    var $8Rkzz = parcelRequire("8Rkzz");
    var $fVnbM;
    var $dm1qi;
    var $dAf5R;
    var $5oTeF;
    var $bH1hf;
    var $7FtaJ;
    var $1Cvrw;
    var $fNobW;
    var $3bDjY;
    var $iQv12;
    var $7XYc4;
    var $az4zD;
    var $dCm1o;
    var $6fQ6A;
    const $0a68425ce3fc2fa8$var$SKELETON_TEST_ID = 'favouriting.favorite-button-stateless.skeleton';
    const $0a68425ce3fc2fa8$var$ICON_WRAPPER_TEST_ID_PREFIX = 'favouriting.favorite-button-stateless.icon-wrapper';
    class $0a68425ce3fc2fa8$export$f8ad2acf0ec4860f extends (0, $5uXOq.Component) {
        static{
            this.defaultProps = {
                isFavorite: undefined,
                favoriteItemName: undefined,
                isShownInList: false,
                isSmall: false,
                hideTooltip: false,
                onClick: (0, ($parcel$interopDefault($8Rkzz))),
                tooltipPosition: 'bottom',
                skipAnimation: false
            };
        }
        componentDidUpdate(prevProps) {
            if (!this.props.skipAnimation && (!prevProps.pending && this.props.pending || !prevProps.isFavorite && this.props.isFavorite)) {
                this.onAnimationStart();
                this.onSettingAnimationEnd();
            }
        }
        onAnimationStart() {
            this.setState({
                isAnimating: !this.state.isAnimating
            });
        }
        onSettingAnimationEnd() {
            setTimeout(()=>{
                this.setState({
                    isAnimating: false
                });
            }, $0a68425ce3fc2fa8$var$animationTime * 2);
        }
        render() {
            const { isFavorite: isFavorite, favoriteItemName: favoriteItemName, isShownInList: isShownInList, isSmall: isSmall, hideTooltip: hideTooltip, tooltipPosition: tooltipPosition, pending: pending, intl: { formatMessage: formatMessage } } = this.props;
            const { isAnimating: isAnimating } = this.state;
            if (isFavorite === undefined) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fNobW")).default), {
                name: "favourite-button"
            }, (0, ($parcel$interopDefault($5uXOq))).createElement($0a68425ce3fc2fa8$export$db65185b5889c83, {
                "data-testid": $0a68425ce3fc2fa8$var$SKELETON_TEST_ID,
                isSmall: isSmall
            }));
            const iconWrapperTestId = isShownInList ? `${$0a68425ce3fc2fa8$var$ICON_WRAPPER_TEST_ID_PREFIX}-list` : $0a68425ce3fc2fa8$var$ICON_WRAPPER_TEST_ID_PREFIX;
            const buttonContentOld = (0, ($parcel$interopDefault($5uXOq))).createElement($0a68425ce3fc2fa8$var$FavIconWrapper, {
                isFavorite: isFavorite,
                isAnimating: isAnimating,
                "data-testid": iconWrapperTestId,
                isSmall: isSmall
            }, isFavorite ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("dm1qi"))))), {
                LEGACY_fallbackIcon: (0, ($parcel$interopDefault((0, parcelRequire("bH1hf"))))),
                LEGACY_size: isSmall ? 'small' : undefined,
                color: "currentColor",
                label: formatMessage((0, (0, parcelRequire("6fQ6A")).default).removeFromStarred)
            }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("dAf5R"))))), {
                LEGACY_fallbackIcon: (0, ($parcel$interopDefault((0, parcelRequire("5oTeF"))))),
                LEGACY_size: isSmall ? 'small' : undefined,
                color: "currentColor",
                label: formatMessage((0, (0, parcelRequire("6fQ6A")).default).addToStarred)
            }));
            const renderIcon = ()=>{
                const label = isFavorite ? formatMessage((0, (0, parcelRequire("6fQ6A")).default).removeFromStarred) : formatMessage((0, (0, parcelRequire("6fQ6A")).default).addToStarred);
                let StarIconComponent;
                if (isSmall) StarIconComponent = isFavorite ? (0, ($parcel$interopDefault((0, parcelRequire("7FtaJ"))))) : (0, ($parcel$interopDefault((0, parcelRequire("1Cvrw")))));
                else StarIconComponent = isFavorite ? (0, ($parcel$interopDefault((0, parcelRequire("dm1qi"))))) : (0, ($parcel$interopDefault((0, parcelRequire("dAf5R")))));
                return (0, ($parcel$interopDefault($5uXOq))).createElement(StarIconComponent, {
                    color: "currentColor",
                    label: label
                });
            };
            const buttonContent = (0, ($parcel$interopDefault($5uXOq))).createElement($0a68425ce3fc2fa8$var$FavIconWrapper, {
                isFavorite: isFavorite,
                isAnimating: isAnimating,
                "data-testid": iconWrapperTestId,
                isSmall: isSmall
            }, renderIcon());
            const tooltipContent = isFavorite ? formatMessage((0, (0, parcelRequire("6fQ6A")).default).removeFromStarred) : formatMessage((0, (0, parcelRequire("6fQ6A")).default).addToStarred);
            const ariaLabel = favoriteItemName ? formatMessage((0, (0, parcelRequire("6fQ6A")).default).toggleStarredWithItemName, {
                itemName: favoriteItemName
            }) : formatMessage((0, (0, parcelRequire("6fQ6A")).default).toggleStarred);
            return (0, ($parcel$interopDefault($5uXOq))).createElement($0a68425ce3fc2fa8$export$bb1478fee6cd5f0c, {
                role: "presentation",
                onClick: this.onBtnClickWrapper,
                onKeyPress: this.onBtnClickWrapper,
                "data-is-favorite": isFavorite
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
                content: !hideTooltip && tooltipContent,
                hideTooltipOnClick: true,
                position: tooltipPosition
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                appearance: isShownInList ? 'subtle-link' : 'subtle',
                spacing: "none",
                "aria-label": ariaLabel,
                "aria-pressed": isFavorite,
                onClick: !pending ? this.onClick : (0, ($parcel$interopDefault($8Rkzz)))
            }, (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() ? buttonContent : buttonContentOld)));
        }
        constructor(...args){
            super(...args);
            this.state = {
                isAnimating: false
            };
            this.onBtnClickWrapper = (e)=>{
                e.stopPropagation();
                e.preventDefault();
            };
            this.onClick = (_, analyticsEvent)=>{
                this.props.onClick(analyticsEvent);
            };
        }
    }
    var $0a68425ce3fc2fa8$export$2e2bcd8739ae039 = (0, ($parcel$interopDefault($basmW)))((0, (0, parcelRequire("iQv12")).default)({
        onClick: ()=>({
                name: 'star'
            })
    }), (0, (0, parcelRequire("az4zD")).injectIntlV2))($0a68425ce3fc2fa8$export$f8ad2acf0ec4860f);
    const $0a68425ce3fc2fa8$var$animationTime = 500;
    const $0a68425ce3fc2fa8$export$bb1478fee6cd5f0c = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1o8l",
                __cmplp.className
            ])
        });
    });
    const $0a68425ce3fc2fa8$var$FavoriteIconWrapperBaseOld = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isSmall: isSmall, isAnimating: isAnimating, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _4cvr1h6o _1bah1h6o",
                __cmplp.isSmall ? "_1bsb1tcg" : "_1bsbzwfg",
                __cmplp.isSmall ? "_4t3i1tcg" : "_4t3izwfg",
                __cmplp.isAnimating && "_11vn1ssb _1px1fmmq _48hmq7pw _1uvf1e03",
                __cmplp.className
            ])
        });
    });
    const $0a68425ce3fc2fa8$var$FavoriteIconWrapperBaseNew = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isSmall: isSmall, isAnimating: isAnimating, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _4cvr1h6o _1bah1h6o",
                __cmplp.isSmall ? "_1bsb1tcg" : "_1bsbzwfg",
                __cmplp.isSmall ? "_4t3i1tcg" : "_4t3izwfg",
                __cmplp.isAnimating && "_1px1fmmq _48hmq7pw _1uvf1e03",
                __cmplp.className
            ])
        });
    });
    const $0a68425ce3fc2fa8$var$FavoriteIconWrapperBase = (0, (0, parcelRequire("7XYc4")).componentWithCondition)((0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled), $0a68425ce3fc2fa8$var$FavoriteIconWrapperBaseNew, $0a68425ce3fc2fa8$var$FavoriteIconWrapperBaseOld);
    const $0a68425ce3fc2fa8$var$FavIconWrapper = (0, $5uXOq.forwardRef)(({ as: C = $0a68425ce3fc2fa8$var$FavoriteIconWrapperBase, style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "",
                __cmplp.isFavorite ? "_syaz2kyj" : "_syaz2u25",
                __cmplp.isFavorite ? "_30l31ebw" : "_30l3w9a8",
                __cmplp.className
            ])
        });
    });
    const $0a68425ce3fc2fa8$export$db65185b5889c83 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isSmall: isSmall, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw",
                __cmplp.isSmall ? "_1bsb1tcg" : "_1bsbzwfg",
                __cmplp.isSmall ? "_4t3i1tcg" : "_4t3izwfg",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("iQv12", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$945500a9598c3ac2$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $gvRPW;
    const $945500a9598c3ac2$var$fireWithUIAnalytics = (ConnectedProps)=>{
        const providedKeys = Object.keys(ConnectedProps).sort();
        const cachedMappedProps = {};
        const cachedConnectedProps = {};
        const getStateFromMappedProps = (mapped)=>{
            providedKeys.forEach((key, index)=>{
                const prop = mapped[index];
                if (key in cachedMappedProps && cachedMappedProps[key] === prop) return;
                cachedMappedProps[key] = prop;
                cachedConnectedProps[key] = (...attrs)=>{
                    const analyticsEvent = attrs[attrs.length - 1].clone();
                    const connectedProp = ConnectedProps[key];
                    if (typeof ConnectedProps[key] === 'string') analyticsEvent.update({
                        name: connectedProp,
                        analyticsType: (0, (0, parcelRequire("gvRPW")).UI_EVENT_TYPE)
                    }).fire();
                    else if (typeof connectedProp === 'function') {
                        const update = connectedProp(...attrs);
                        analyticsEvent.update({
                            ...update,
                            analyticsType: (0, (0, parcelRequire("gvRPW")).UI_EVENT_TYPE)
                        }).fire();
                    }
                    if (typeof prop === 'function') prop(...attrs);
                };
            });
            return cachedConnectedProps;
        };
        return (WrappedComponent)=>class WithAnalytics extends (0, $5uXOq.Component) {
                static{
                    this.displayName = `WithAnalytics(${WrappedComponent.displayName || WrappedComponent.name || 'UnnamedComponent'}`;
                }
                render() {
                    const newMappedProps = getStateFromMappedProps(providedKeys.map((key)=>this.props[key]));
                    return (0, ($parcel$interopDefault($5uXOq))).createElement(WrappedComponent, {
                        ...this.props,
                        ...newMappedProps
                    });
                }
            };
    };
    var $945500a9598c3ac2$export$2e2bcd8739ae039 = $945500a9598c3ac2$var$fireWithUIAnalytics;
});
parcelRegister("6fQ6A", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$10bc2ec5d9a72db1$export$2e2bcd8739ae039);
    var $7VHMR;
    var $10bc2ec5d9a72db1$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        addToStarred: {
            "id": "platform.ui.favouriting.favourite-button-stateless.add-to-starred",
            "defaultMessage": "Add to Starred"
        },
        removeFromStarred: {
            "id": "platform.ui.favouriting.favourite-button-stateless.remove-from-starred",
            "defaultMessage": "Remove from Starred"
        },
        toggleStarred: {
            "id": "platform.ui.favouriting.favourite-button-stateless.toggle-starred",
            "defaultMessage": "Star"
        },
        toggleStarredWithItemName: {
            "id": "platform.ui.favouriting.favourite-button-stateless.toggle-starred-with-item-name",
            "defaultMessage": "Star {itemName}"
        }
    });
});
parcelRegister("1WPu2", function(module, exports) {
    $parcel$export(module.exports, "getFavorite", ()=>$1961b1823763022d$export$fce5eda070ce88d0);
    var $eusud;
    const $1961b1823763022d$export$959ca2ee969adb8d = (baseUrl, request)=>`${baseUrl}/rest/internal/2/favourites/${request.type}/${request.id}`;
    const $1961b1823763022d$export$fce5eda070ce88d0 = async (baseUrl, request)=>(0, (0, parcelRequire("eusud")).performGetRequest)($1961b1823763022d$export$959ca2ee969adb8d(baseUrl, request));
});
parcelRegister("gs7uP", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$a87a2ffd163e1826$export$2e2bcd8739ae039);
    var $7VHMR;
    const $a87a2ffd163e1826$var$messages = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        heading: {
            "id": "global-issue-navigator.heading",
            "defaultMessage": "Issues"
        },
        windowTitle: {
            "id": "global-issue-navigator.window-title-migration",
            "defaultMessage": "Issue navigator"
        },
        filterWindowTitle: {
            "id": "global-issue-navigator.filter-window-title-migration",
            "defaultMessage": "[{filterName}] Issue navigator"
        }
    });
    var $a87a2ffd163e1826$export$2e2bcd8739ae039 = $a87a2ffd163e1826$var$messages;
});
parcelRegister("9Bfjr", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$47364065a0de794c$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $cY1ec;
    var $UOiaS;
    var $o4HRh;
    var $7bJv8;
    var $a8lIB;
    var $lml1M;
    var $cZpsT;
    const $47364065a0de794c$var$PageActions = ({ jql: jql, filterId: filterId, isModifiedFilter: isModifiedFilter, searchResultPageData: searchResultPageData, filterName: filterName })=>{
        (0, (0, parcelRequire("a8lIB")).markOnce)((0, (0, parcelRequire("a8lIB")).marks).PAGE_ACTIONS_START);
        (0, $5uXOq.useLayoutEffect)(()=>{
            (0, (0, parcelRequire("a8lIB")).markOnce)((0, (0, parcelRequire("a8lIB")).marks).PAGE_ACTIONS_END);
        }, []);
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement($47364065a0de794c$export$48377ae1c677702b, null, (0, ($parcel$interopDefault($5uXOq))).createElement($47364065a0de794c$var$StyledPageAction, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("o4HRh")).default), null)), !isAnonymous && (0, ($parcel$interopDefault($5uXOq))).createElement($47364065a0de794c$var$StyledPageAction, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7bJv8")).ShareButton), {
            jql: jql,
            filterId: filterId,
            isModifiedFilter: isModifiedFilter
        })), (0, ($parcel$interopDefault($5uXOq))).createElement($47364065a0de794c$var$StyledPageAction, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cY1ec")).default), {
            jql: jql,
            filterId: filterId,
            isModifiedFilter: isModifiedFilter,
            showEcosystemApps: true,
            filterName: filterName
        })), (0, ($parcel$interopDefault($5uXOq))).createElement($47364065a0de794c$var$StyledPageAction, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lml1M")).default), {
            filterId: filterId
        })), (0, ($parcel$interopDefault($5uXOq))).createElement($47364065a0de794c$var$StyledPageAction, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("UOiaS")).default), {
            jql: jql,
            filterId: filterId,
            searchResultPageData: searchResultPageData
        })));
    };
    var $47364065a0de794c$export$2e2bcd8739ae039 = $47364065a0de794c$var$PageActions;
    const $47364065a0de794c$var$StyledPageAction = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_18s8otz2 _1ul9idpf _1xoqidpf",
                __cmplp.className
            ])
        });
    });
    const $47364065a0de794c$export$48377ae1c677702b = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _2lx2vrvc _1n261q9c",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("cY1ec", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$10ae3433867a34b6$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $BLPHT;
    var $39xOx;
    var $6s1PB;
    var $koVbs;
    var $Y0ZJB;
    var $WbiIJ;
    var $lfTZh;
    var $kxZPA;
    var $6h1uG;
    const $10ae3433867a34b6$var$ExportIssues = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("3vcpp")), {
        ssr: false,
        moduleId: "./src/packages/issue-navigator/actions/export-issues/src/ui/index.tsx"
    });
    const $10ae3433867a34b6$var$Fallback = ({ onRetry: onRetry, customTriggerButton: customTriggerButton })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const triggerButton = ({ triggerRef: triggerRef, ...props })=>customTriggerButton ? customTriggerButton(false, {
                triggerRef: triggerRef,
                ...props
            }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("WbiIJ")).FilterButton), {
                ...props,
                text: formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m1-fade', 'isIssueTermFaded', false) ? (0, (0, parcelRequire("6h1uG")).default).exportButtonText : (0, (0, parcelRequire("6h1uG")).default).exportIssuesButtonText),
                label: formatMessage((0, (0, parcelRequire("6h1uG")).default).exportIssuesButtonIconLabel),
                ref: triggerRef
            });
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("BLPHT")).default), {
            trigger: ({ triggerRef: triggerRef, ...props })=>triggerButton({
                    triggerRef: triggerRef,
                    ...props
                }),
            shouldFlip: true,
            placement: "bottom-end",
            spacing: "compact"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("Y0ZJB")).ErrorState), {
            onRetry: onRetry,
            pageAction: "exportIssues"
        }));
    };
    const $10ae3433867a34b6$var$AsyncExportIssues = (props)=>{
        const { customTriggerButton: customTriggerButton, customDisabledButton: customDisabledButton } = props;
        const [resetCaughtError, setResetCaughtError] = (0, $5uXOq.useState)();
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            packageName: "jiraIssueNavigatorActionExportIssues",
            id: "async-export-issues",
            sendToPrivacyUnsafeSplunk: true,
            fallback: ({ error: error })=>(0, ($parcel$interopDefault($5uXOq))).createElement($10ae3433867a34b6$var$Fallback, {
                    onRetry: ()=>setResetCaughtError(error),
                    customTriggerButton: customTriggerButton
                }),
            onError: ()=>setResetCaughtError(undefined),
            resetCaughtError: resetCaughtError
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "async-export-issues",
            fallback: customDisabledButton ?? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("WbiIJ")).FilterButton), {
                isDisabled: true,
                text: formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m1-fade', 'isIssueTermFaded', false) ? (0, (0, parcelRequire("6h1uG")).default).exportButtonText : (0, (0, parcelRequire("6h1uG")).default).exportIssuesButtonText),
                label: formatMessage((0, (0, parcelRequire("6h1uG")).default).exportIssuesButtonIconLabel)
            })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($10ae3433867a34b6$var$ExportIssues, props)));
    };
    var $10ae3433867a34b6$export$2e2bcd8739ae039 = $10ae3433867a34b6$var$AsyncExportIssues;
});
parcelRegister("WbiIJ", function(module, exports) {
    $parcel$export(module.exports, "FilterButton", ()=>$d3768321d4c015de$export$fb46dd9777cc6db0);
    var $bwpsq;
    var $5uXOq = parcelRequire("5uXOq");
    var $fVnbM;
    var $c8Oei;
    var $dC5iT;
    var $8CUq3;
    const $d3768321d4c015de$export$fb46dd9777cc6db0 = (0, $5uXOq.forwardRef)(({ isSelected: isSelected, onClick: onClick, testId: testId, isDisabled: isDisabled, text: text, label: label, customTriggerProps: customTriggerProps }, ref)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $d3768321d4c015de$var$buttonWrapperStyles
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            isSelected: isSelected,
            onClick: onClick,
            ref: ref,
            testId: testId,
            isDisabled: isDisabled,
            iconAfter: (0, ($parcel$interopDefault($5uXOq))).createElement($d3768321d4c015de$var$IconWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("c8Oei"))))), {
                label: label,
                color: "currentColor"
            })),
            ...customTriggerProps
        }, text)));
    const $d3768321d4c015de$var$IconWrapper = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c116y _4cvr1h6o _2hwx196n",
                __cmplp.className
            ])
        });
    });
    const $d3768321d4c015de$var$buttonWrapperStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        position: 'relative'
    });
});
parcelRegister("6h1uG", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$85218cd505ca3251$export$2e2bcd8739ae039);
    var $7VHMR;
    const $85218cd505ca3251$var$messages = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        exportIssuesButtonText: {
            "id": "issue-navigator-action-export-issues.export-issues-button-text",
            "defaultMessage": "Export issues"
        },
        exportButtonText: {
            "id": "issue-navigator-action-export-issues.export-button-text",
            "defaultMessage": "Export"
        },
        exportIssuesButtonIconLabel: {
            "id": "issue-navigator-action-export-issues.export-issues-button-icon-label",
            "defaultMessage": "Open export issues dropdown"
        }
    });
    var $85218cd505ca3251$export$2e2bcd8739ae039 = $85218cd505ca3251$var$messages;
});
parcelRegister("3vcpp", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("89rrl").then(()=>parcelRequire('3wO03'));
});
parcelRegister("UOiaS", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$bd9f20031380c50d$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $fVnbM;
    var $BLPHT;
    var $3oUqw;
    var $39xOx;
    var $Y0ZJB;
    var $lfTZh;
    var $kxZPA;
    const $bd9f20031380c50d$var$MeatballMenu = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("gm6px")), {
        ssr: false,
        moduleId: "./src/packages/issue-navigator/actions/meatball-menu/src/ui/index.tsx"
    });
    const $bd9f20031380c50d$var$Fallback = ({ onRetry: onRetry })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("BLPHT")).default), {
            trigger: ({ triggerRef: triggerRef, ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                    ...props,
                    appearance: "subtle",
                    spacing: "default",
                    iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("3oUqw"))))), {
                        label: "",
                        size: "medium"
                    }),
                    ref: triggerRef
                }),
            shouldFlip: true,
            placement: "bottom-end",
            spacing: "compact"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("Y0ZJB")).ErrorState), {
            onRetry: onRetry,
            pageAction: "meatballMenu"
        }));
    const $bd9f20031380c50d$var$AsyncMeatballMenu = ({ jql: jql, filterId: filterId, searchResultPageData: searchResultPageData, additionalMenuItems: additionalMenuItems, viewOptions: viewOptions })=>{
        const [resetCaughtError, setResetCaughtError] = (0, $5uXOq.useState)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            packageName: "jiraIssueNavigatorActionMeatballMenu",
            id: "async-issue-navigator-actions-meatball-menu",
            sendToPrivacyUnsafeSplunk: true,
            fallback: ({ error: error })=>(0, ($parcel$interopDefault($5uXOq))).createElement($bd9f20031380c50d$var$Fallback, {
                    onRetry: ()=>setResetCaughtError(error)
                }),
            onError: ()=>setResetCaughtError(undefined),
            resetCaughtError: resetCaughtError
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "async-issue-navigator-actions-meatball-menu",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                appearance: "subtle",
                spacing: "default",
                isDisabled: true,
                iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("3oUqw"))))), {
                    label: "",
                    size: "medium"
                })
            })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($bd9f20031380c50d$var$MeatballMenu, {
            jql: jql,
            filterId: filterId,
            searchResultPageData: searchResultPageData,
            additionalMenuItems: additionalMenuItems,
            viewOptions: viewOptions
        })));
    };
    var $bd9f20031380c50d$export$2e2bcd8739ae039 = $bd9f20031380c50d$var$AsyncMeatballMenu;
});
parcelRegister("gm6px", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("65sLi").then(()=>parcelRequire('1VU5d'));
});
parcelRegister("o4HRh", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$99334036623bc424$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $BLPHT;
    var $39xOx;
    var $Y0ZJB;
    var $lfTZh;
    var $kxZPA;
    var $7trNp;
    const $99334036623bc424$var$PluggableItems = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("1DLoo")), {
        ssr: false,
        moduleId: "./src/packages/issue-navigator/actions/pluggable-items/src/ui/main.tsx"
    });
    const $99334036623bc424$var$Fallback = ({ onRetry: onRetry })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("BLPHT")).default), {
            trigger: ({ triggerRef: triggerRef, ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7trNp")).PluggableItemsFilterButton), {
                    ...props,
                    ref: triggerRef
                }),
            shouldFlip: true,
            placement: "bottom-end",
            spacing: "compact"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("Y0ZJB")).ErrorState), {
            onRetry: onRetry,
            pageAction: "apps"
        }));
    const $99334036623bc424$var$AsyncPluggableItems = ()=>{
        const [resetCaughtError, setResetCaughtError] = (0, $5uXOq.useState)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            packageName: "jiraIssueNavigatorActionPluggableItems",
            id: "async-pluggable-items",
            sendToPrivacyUnsafeSplunk: true,
            fallback: ({ error: error })=>(0, ($parcel$interopDefault($5uXOq))).createElement($99334036623bc424$var$Fallback, {
                    onRetry: ()=>setResetCaughtError(error)
                }),
            onError: ()=>setResetCaughtError(undefined),
            resetCaughtError: resetCaughtError
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "async-pluggable-items",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7trNp")).PluggableItemsFilterButton), {
                isDisabled: true
            })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($99334036623bc424$var$PluggableItems, null)));
    };
    var $99334036623bc424$export$2e2bcd8739ae039 = $99334036623bc424$var$AsyncPluggableItems;
});
parcelRegister("7trNp", function(module, exports) {
    $parcel$export(module.exports, "PluggableItemsFilterButton", ()=>$91976ee30eecca78$export$7f94e3b48172a1bd);
    var $5uXOq = parcelRequire("5uXOq");
    var $koVbs;
    var $WbiIJ;
    var $64Mgm;
    const $91976ee30eecca78$export$7f94e3b48172a1bd = (0, $5uXOq.forwardRef)((props, ref)=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("WbiIJ")).FilterButton), {
            testId: "issue-navigator-action-pluggable-items.ui.filter-button.menu-trigger",
            text: formatMessage((0, (0, parcelRequire("64Mgm")).default).pluggableItemsButtonText),
            label: formatMessage((0, (0, parcelRequire("64Mgm")).default).pluggableItemsButtonIconLabel),
            ...props,
            ref: ref
        });
    });
});
parcelRegister("64Mgm", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$ec76edf4a5abe930$export$2e2bcd8739ae039);
    var $7VHMR;
    const $ec76edf4a5abe930$var$messages = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        pluggableItemsButtonText: {
            "id": "issue-navigator-action-pluggable-items.filter-button.pluggable-items-button-text",
            "defaultMessage": "Apps"
        },
        pluggableItemsButtonIconLabel: {
            "id": "issue-navigator-action-pluggable-items.filter-button.pluggable-items-button-icon-label",
            "defaultMessage": "Open pluggable items dropdown"
        }
    });
    var $ec76edf4a5abe930$export$2e2bcd8739ae039 = $ec76edf4a5abe930$var$messages;
});
parcelRegister("1DLoo", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("ijXkn")
    ]).then(()=>parcelRequire('9avSx'));
});
parcelRegister("7bJv8", function(module, exports) {
    $parcel$export(module.exports, "ShareButton", ()=>$2f0198851cf45c49$export$e980dfbc2840e8bc);
    var $5uXOq = parcelRequire("5uXOq");
    var $bvUB1;
    var $3Tty1;
    var $koVbs;
    var $WbiIJ;
    var $9Nvh7;
    var $8zOmE;
    var $2NMNM;
    var $iL8EU;
    var $16v4H;
    var $a0UsQ;
    var $bcD3e;
    var $i5w7k;
    var $68QXU;
    var $lYorD;
    const $2f0198851cf45c49$var$getShareLink = (param, value)=>{
        const { hostname: hostname, protocol: protocol } = window.location;
        return `${protocol}//${hostname}/issues/?${param}=${encodeURIComponent(value)}`;
    };
    const $2f0198851cf45c49$var$CustomTriggerButton = ({ isDisabled: isDisabled, isSelected: isSelected, onClick: onClick }, triggerProps)=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const onKeyboardShortcutClick = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                action: 'pressed',
                actionSubject: 'keyboardShortcut'
            }), 'shareSearchCriteriaModalKeyboardShortcut', {
                keyPressed: 's',
                keyboardShortcut: true
            });
            onClick();
        }, [
            createAnalyticsEvent,
            onClick
        ]);
        const shortcutsKeyMap = (0, $5uXOq.useMemo)(()=>({
                s: {
                    callback: onKeyboardShortcutClick,
                    label: (0, ($parcel$interopDefault($5uXOq))).createElement("div", null, formatMessage((0, (0, parcelRequire("lYorD")).messages).shareKeyboardShortcut))
                }
            }), [
            formatMessage,
            onKeyboardShortcutClick
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3Tty1")).default), {
            keyMap: shortcutsKeyMap
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("WbiIJ")).FilterButton), {
            isDisabled: isDisabled,
            isSelected: isSelected,
            onClick: onClick,
            label: "",
            text: formatMessage((0, (0, parcelRequire("lYorD")).messages).share),
            customTriggerProps: {
                ...triggerProps,
                'aria-haspopup': false
            }
        }));
    };
    const $2f0198851cf45c49$var$addContextToEvent = (event)=>{
        const attributes = event.context.map((context)=>context.attributes);
        event.update((payload)=>({
                ...payload,
                attributes: Object.assign(event.payload.attributes || {}, ...attributes)
            }));
    };
    const $2f0198851cf45c49$export$e980dfbc2840e8bc = ({ jql: jql, filterId: filterId, isModifiedFilter: isModifiedFilter = false })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const subProduct = (0, (0, parcelRequire("16v4H")).useSubProduct)();
        const [shouldShowThirdPartyNudge, setShouldShowThirdPartyNudge] = (0, $5uXOq.useState)(false);
        const showFilterShareLinkNew = filterId !== undefined && (0, (0, parcelRequire("9Nvh7")).isCustomFilter)(filterId) && !isModifiedFilter;
        const additionalProps = showFilterShareLinkNew ? {
            integrationType: (0, (0, parcelRequire("bcD3e")).INTEGRATION_TYPES).FILTER,
            shareContentType: (0, (0, parcelRequire("bcD3e")).INTEGRATION_TYPES).FILTER,
            shareLink: $2f0198851cf45c49$var$getShareLink('filter', filterId),
            shareTitle: formatMessage((0, (0, parcelRequire("lYorD")).messages).filter),
            shareFormTitle: formatMessage((0, (0, parcelRequire("lYorD")).messages).dialogTitleFilter)
        } : {
            integrationType: (0, (0, parcelRequire("bcD3e")).INTEGRATION_TYPES).SEARCH,
            shareContentType: (0, (0, parcelRequire("bcD3e")).INTEGRATION_TYPES).SEARCH,
            shareLink: $2f0198851cf45c49$var$getShareLink('jql', jql),
            shareTitle: formatMessage((0, (0, parcelRequire("lYorD")).messages).search),
            shareFormTitle: formatMessage((0, (0, parcelRequire("lYorD")).messages).dialogTitleSearch)
        };
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bvUB1")).default), {
            channel: "*",
            onEvent: $2f0198851cf45c49$var$addContextToEvent
        }, shouldShowThirdPartyNudge && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("a0UsQ")).default), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iL8EU")).default), {
            triggerButtonStyle: "icon-with-text",
            productId: "jira",
            subProductId: (0, (0, parcelRequire("68QXU")).getShareSubProductId)(subProduct),
            objectAri: "",
            legacyShareClientConfig: (0, (0, parcelRequire("i5w7k")).shareSearchClientConfig),
            renderCustomTriggerButton: $2f0198851cf45c49$var$CustomTriggerButton,
            onTriggerButtonClick: ()=>{
                setShouldShowThirdPartyNudge(true);
            },
            ...additionalProps
        }));
    };
});
parcelRegister("iL8EU", function(module, exports) {
    $parcel$export(module.exports, "ShareLoadingPlaceholder", ()=>$4e5335f44d688bbf$export$5619d05bf511476b);
    $parcel$export(module.exports, "default", ()=>$4e5335f44d688bbf$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $8Rkzz = parcelRequire("8Rkzz");
    var $fVnbM;
    var $jZX0t;
    var $heNNl;
    var $koVbs;
    var $lfTZh;
    var $kxZPA;
    var $Kpokz;
    const $4e5335f44d688bbf$export$5619d05bf511476b = ({ triggerButtonStyle: triggerButtonStyle = 'icon-only' })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement("div", null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            appearance: "subtle",
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("jZX0t"))))), {
                size: "medium",
                label: formatMessage((0, (0, parcelRequire("Kpokz")).messages).shareTriggerButtonText),
                primaryColor: `var(--ds-icon, ${(0, $829f609a65d26a98$exports).N30})`
            }),
            isDisabled: true
        }, triggerButtonStyle === 'icon-with-text' ? formatMessage((0, (0, parcelRequire("Kpokz")).messages).shareTriggerButtonText) : null));
    };
    const $4e5335f44d688bbf$var$ShareButton = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("jHQiG")), {
        ssr: false,
        moduleId: "./src/packages/growth/share-button/src/ui/index.tsx"
    });
    var $4e5335f44d688bbf$export$2e2bcd8739ae039 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("heNNl")).default), {
            id: "async.share.button",
            packageName: "share-button"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "share-button",
            fallback: props.renderCustomTriggerButton === undefined ? (0, ($parcel$interopDefault($5uXOq))).createElement($4e5335f44d688bbf$export$5619d05bf511476b, {
                triggerButtonStyle: props.triggerButtonStyle
            }) : (0, ($parcel$interopDefault($5uXOq))).createElement(props.renderCustomTriggerButton, {
                isDisabled: true,
                onClick: (0, ($parcel$interopDefault($8Rkzz)))
            })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($4e5335f44d688bbf$var$ShareButton, props)));
});
parcelRegister("jZX0t", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $e8f17c524b03e26b$var$_react = $e8f17c524b03e26b$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $e8f17c524b03e26b$var$_share = $e8f17c524b03e26b$var$_interopRequireDefault((parcelRequire("empdx")));
    function $e8f17c524b03e26b$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $e8f17c524b03e26b$var$ShareIcon = (props)=>$e8f17c524b03e26b$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><g fill="currentcolor" fill-rule="evenodd"><path fill-rule="nonzero" d="M6 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6m0-2a1 1 0 1 1 0-2 1 1 0 0 1 0 2m12-4a3 3 0 1 0 0-6 3 3 0 0 0 0 6m0-2a1 1 0 1 1 0-2 1 1 0 0 1 0 2m0 14a3 3 0 1 0 0-6 3 3 0 0 0 0 6m0-2a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/><path d="m7 13.562 8.66 5 1-1.732-8.66-5z"/><path d="m7 10.83 1 1.732 8.66-5-1-1.732z"/></g></svg>`
        }, props, {
            newIcon: $e8f17c524b03e26b$var$_share.default
        }));
    $e8f17c524b03e26b$var$ShareIcon.displayName = 'ShareIcon';
    var $e8f17c524b03e26b$var$_default = module.exports.default = $e8f17c524b03e26b$var$ShareIcon;
});
parcelRegister("empdx", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $a746ae65a0e1944c$var$_react = $a746ae65a0e1944c$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $a746ae65a0e1944c$var$_UNSAFE_baseNew = $a746ae65a0e1944c$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $a746ae65a0e1944c$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $a746ae65a0e1944c$var$ShareIcon = (props)=>$a746ae65a0e1944c$var$_react.default.createElement($a746ae65a0e1944c$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-width="1.5" d="M10.434 11.717a1.75 1.75 0 1 0 3.131 1.566 1.75 1.75 0 0 0-3.13-1.566Zm0 0L5.316 8.783m0 0a1.74 1.74 0 0 0 0-1.566m0 1.566a1.75 1.75 0 1 1 0-1.566m0 0 5.118-2.934m0 0a1.75 1.75 0 1 0 3.13-1.566 1.75 1.75 0 0 0-3.13 1.566Z"/>`
        }, props));
    $a746ae65a0e1944c$var$ShareIcon.displayName = 'ShareIcon';
    var $a746ae65a0e1944c$var$_default = module.exports.default = $a746ae65a0e1944c$var$ShareIcon;
});
parcelRegister("Kpokz", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$026d1258a1c95d57$export$defe85febe8b728c);
    var $7VHMR;
    const $026d1258a1c95d57$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        shareTriggerButtonText: {
            "id": "share-button.share-trigger-button-text",
            "defaultMessage": "Share"
        }
    });
});
parcelRegister("jHQiG", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("hPzOn"),
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("bfk3F")
    ]).then(()=>parcelRequire('7aU9g'));
});
parcelRegister("bcD3e", function(module, exports) {
    $parcel$export(module.exports, "INTEGRATION_TYPES", ()=>$b057f09e7b03d4c8$export$195813ac8b6ae6f9);
    const $b057f09e7b03d4c8$export$195813ac8b6ae6f9 = {
        FILTER: 'filter',
        SEARCH: 'search'
    };
});
parcelRegister("i5w7k", function(module, exports) {
    $parcel$export(module.exports, "shareSearchClientConfig", ()=>$b84dbc261bc35c80$export$14295897acb58714);
    var $bcD3e;
    const $b84dbc261bc35c80$export$14295897acb58714 = {
        getShareEndpoint: ({ link: link, type: type })=>type === (0, (0, parcelRequire("bcD3e")).INTEGRATION_TYPES).FILTER ? `/rest/share/1.0/filter/${new URL(link).searchParams.get('filter') || ''}` : '/rest/share/1.0/search',
        getSharePayload: ({ link: link, type: type })=>{
            const jql = new URL(link).searchParams.get('jql');
            return type === (0, (0, parcelRequire("bcD3e")).INTEGRATION_TYPES).SEARCH && jql !== null ? {
                jql: jql
            } : {};
        }
    };
});
parcelRegister("68QXU", function(module, exports) {
    $parcel$export(module.exports, "getShareSubProductId", ()=>$a1c93c3f1c8e701d$export$4a20278dd1d015dd);
    const $a1c93c3f1c8e701d$var$subProductMap = {
        addon: 'jira-addon',
        core: 'jira-core',
        polaris: 'jira-polaris',
        portfolio: 'jira-portfolio',
        serviceDesk: 'jira-servicedesk',
        software: 'jira-software',
        platform: 'jira-platform',
        unknown: 'jira-unknown'
    };
    const $a1c93c3f1c8e701d$export$4a20278dd1d015dd = (subProduct)=>subProduct && $a1c93c3f1c8e701d$var$subProductMap[subProduct] || 'jira-platform';
});
parcelRegister("lYorD", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$7d14c0e250c5cb9b$export$defe85febe8b728c);
    var $7VHMR;
    const $7d14c0e250c5cb9b$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        dialogTitleFilter: {
            "id": "issue-navigator-action-share-button.dialog-title-filter",
            "defaultMessage": "Share current filter"
        },
        dialogTitleSearch: {
            "id": "issue-navigator-action-share-button.dialog-title-search",
            "defaultMessage": "Share search criteria"
        },
        filter: {
            "id": "issue-navigator-action-share-button.filter",
            "defaultMessage": "Filter"
        },
        search: {
            "id": "issue-navigator-action-share-button.search",
            "defaultMessage": "Search"
        },
        share: {
            "id": "issue-navigator-action-share-button.share",
            "defaultMessage": "Share"
        },
        shareKeyboardShortcut: {
            "id": "issue-navigator-action-share-button.share-keyboard-shortcut",
            "defaultMessage": "Open share modal"
        }
    });
});
parcelRegister("lml1M", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$600f1e70c70bcfbe$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Rkzz = parcelRequire("8Rkzz");
    var $90gd1;
    var $jmEdU;
    var $dC5iT;
    var $cgL5K;
    var $3Tty1;
    var $6s1PB;
    var $aWse0;
    var $9Nvh7;
    var $5Czte;
    var $761Hb;
    var $qLuSt;
    var $2jDmD;
    var $3HwlS;
    var $8zOmE;
    var $2NMNM;
    var $g5X3G;
    var $1aUn4;
    var $dCm1o;
    var $6UAdF;
    var $eJ0Zs;
    var $a8lIB;
    var $5NwJk;
    var $ih9zY;
    var $71kvA;
    const $600f1e70c70bcfbe$var$KeyboardShortcuts = ({ view: view, onToggle: onToggle, intl: { formatMessage: formatMessage } })=>{
        const isDetailView = view === (0, (0, parcelRequire("6UAdF")).views).detail;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3Tty1")).default), {
            keyMap: {
                t: {
                    callback: ()=>{
                        const newView = isDetailView ? (0, (0, parcelRequire("6UAdF")).views).list : (0, (0, parcelRequire("6UAdF")).views).detail;
                        onToggle(newView, true);
                    },
                    label: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, formatMessage(isDetailView ? (0, (0, parcelRequire("71kvA")).default).listText : (0, (0, parcelRequire("71kvA")).default).detailText))
                }
            }
        });
    };
    const $600f1e70c70bcfbe$export$c774a1845e27fedc = ({ view: view, onToggle: onToggle, intl: intl })=>{
        const { formatMessage: formatMessage } = intl;
        const testIdPrefix = 'issue-navigator.ui.refinement-bar.view-switcher.toggle-button.';
        const options = (0, (0, parcelRequire("3HwlS")).getWillShowNav4)() ? [
            {
                id: (0, (0, parcelRequire("6UAdF")).views).list,
                label: (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() ? formatMessage((0, (0, parcelRequire("71kvA")).default).listOptionLabel) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
                    size: "UNSAFE_small",
                    weight: "semibold"
                }, formatMessage((0, (0, parcelRequire("71kvA")).default).listOptionLabel)),
                testId: `${testIdPrefix}${(0, (0, parcelRequire("6UAdF")).views).list}`
            },
            {
                id: (0, (0, parcelRequire("6UAdF")).views).detail,
                label: (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() ? formatMessage((0, (0, parcelRequire("71kvA")).default).detailOptionLabel) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
                    size: "UNSAFE_small",
                    weight: "semibold"
                }, formatMessage((0, (0, parcelRequire("71kvA")).default).detailOptionLabel)),
                testId: `${testIdPrefix}${(0, (0, parcelRequire("6UAdF")).views).detail}`
            }
        ] : [
            {
                id: (0, (0, parcelRequire("6UAdF")).views).list,
                label: (0, ($parcel$interopDefault($5uXOq))).createElement($600f1e70c70bcfbe$var$IconLabel, null, (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
                    as: "span"
                }, formatMessage((0, (0, parcelRequire("71kvA")).default).listOptionLabel)) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
                    as: "span"
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
                    size: "UNSAFE_small",
                    weight: "semibold"
                }, formatMessage((0, (0, parcelRequire("71kvA")).default).listOptionLabel))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("90gd1"))))), {
                    label: "",
                    size: "small"
                })),
                testId: `${testIdPrefix}${(0, (0, parcelRequire("6UAdF")).views).list}`
            },
            {
                id: (0, (0, parcelRequire("6UAdF")).views).detail,
                label: (0, ($parcel$interopDefault($5uXOq))).createElement($600f1e70c70bcfbe$var$IconLabel, null, (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
                    as: "span"
                }, formatMessage((0, (0, parcelRequire("71kvA")).default).detailOptionLabel)) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
                    as: "span"
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
                    size: "UNSAFE_small",
                    weight: "semibold"
                }, formatMessage((0, (0, parcelRequire("71kvA")).default).detailOptionLabel))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("jmEdU"))))), {
                    label: "",
                    size: "small"
                })),
                testId: `${testIdPrefix}${(0, (0, parcelRequire("6UAdF")).views).detail}`
            }
        ];
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement($600f1e70c70bcfbe$var$KeyboardShortcuts, {
            view: view,
            onToggle: onToggle,
            intl: intl
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1aUn4")).default), {
            label: formatMessage((0, (0, parcelRequire("71kvA")).default).toggleButtonsLabel),
            options: options,
            selectedOption: view,
            onChange: (newView)=>{
                onToggle(newView, false);
            }
        }));
    };
    $600f1e70c70bcfbe$export$c774a1845e27fedc.defaultProps = {
        onToggle: (0, ($parcel$interopDefault($8Rkzz)))
    };
    const $600f1e70c70bcfbe$export$3f624639bbe23442 = ({ filterId: filterId })=>{
        (0, (0, parcelRequire("a8lIB")).markOnce)((0, (0, parcelRequire("a8lIB")).marks).PAGE_ACTIONS_VIEW_SWITCHER_START);
        (0, $5uXOq.useLayoutEffect)(()=>{
            (0, (0, parcelRequire("a8lIB")).markOnce)((0, (0, parcelRequire("a8lIB")).marks).PAGE_ACTIONS_VIEW_SWITCHER_END);
        }, []);
        const selectedIssueKey = (0, (0, parcelRequire("5NwJk")).useSelectedIssueKey)();
        const [{ view: view }, { setView: setView }] = (0, (0, parcelRequire("ih9zY")).useSelectedViewState)();
        const intl = (0, (0, parcelRequire("aWse0")).useIntlV2)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const handleOnToggle = (0, $5uXOq.useCallback)((newView, isKeyboardShortcut)=>{
            const newIssueKey = newView === (0, (0, parcelRequire("6UAdF")).views).detail ? selectedIssueKey : (0, (0, parcelRequire("g5X3G")).toIssueKey)('');
            let resolvedNewView = (0, (0, parcelRequire("eJ0Zs")).convertToIssueNavigatorId)(newView);
            if (newView === (0, (0, parcelRequire("6UAdF")).views).list && filterId && (0, (0, parcelRequire("9Nvh7")).isCustomFilter)(filterId)) resolvedNewView = (0, (0, parcelRequire("eJ0Zs")).convertFilterIdToIssueNavigatorId)(filterId);
            setView(resolvedNewView, newIssueKey);
            const analyticsEvent = createAnalyticsEvent({
                action: 'clicked',
                actionSubject: 'button'
            });
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'switchIssueNavigatorView', {
                keyboardShortcut: isKeyboardShortcut,
                switchToView: newView
            });
            return true;
        }, [
            selectedIssueKey,
            filterId,
            setView,
            createAnalyticsEvent
        ]);
        const children = (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5Czte")).NinChangeboardingTourTarget), {
            engagementId: "nin.view-switcher"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($600f1e70c70bcfbe$export$c774a1845e27fedc, {
            view: (0, (0, parcelRequire("eJ0Zs")).convertToView)(view),
            onToggle: handleOnToggle,
            intl: intl
        }));
        const renderSpotlightCard = ({ onEndTour: onEndTour, onSetActiveCard: onSetActiveCard })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("qLuSt")).default), {
                cardKey: (0, (0, parcelRequire("761Hb")).cardKeys).GLOBAL_SCOPE_VIEW_SWITCHER,
                dialogWidth: 272,
                dialogPlacement: "bottom right",
                actions: view === 'detail' ? [
                    {
                        onClick: ()=>{
                            onEndTour();
                        },
                        text: intl.formatMessage((0, (0, parcelRequire("71kvA")).default).done)
                    }
                ] : [
                    {
                        onClick: ()=>{
                            onSetActiveCard((0, (0, parcelRequire("761Hb")).cardKeys).GLOBAL_SCOPE_COLUMN_PICKER);
                        },
                        text: intl.formatMessage((0, (0, parcelRequire("71kvA")).default).next)
                    },
                    {
                        appearance: 'subtle',
                        onClick: ()=>{
                            onEndTour();
                        },
                        text: intl.formatMessage((0, (0, parcelRequire("71kvA")).default).skip)
                    }
                ],
                heading: intl.formatMessage((0, (0, parcelRequire("71kvA")).default).tourHeading),
                target: "nin.spotlight.view-switcher",
                targetBgColor: `var(--ds-surface, ${(0, $829f609a65d26a98$exports).N0})`,
                targetRadius: 3,
                messageId: "jira-issue-navigator.ui.view-switcher.spotlight",
                messageType: "transactional"
            }, intl.formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("71kvA")).default).tourBodyIssueTermRefresh : (0, (0, parcelRequire("71kvA")).default).tourBody));
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2jDmD")).default), {
            name: "nin.spotlight.view-switcher",
            renderSpotlightCard: renderSpotlightCard
        }, children);
    };
    var $600f1e70c70bcfbe$export$2e2bcd8739ae039 = $600f1e70c70bcfbe$export$3f624639bbe23442;
    const $600f1e70c70bcfbe$var$IconLabel = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _4cvr1h6o",
                (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() ? "_zulp1b66" : "_10cu1b66 _2t30idpf",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("90gd1", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $68e0afe9fd548c5c$var$_react = $68e0afe9fd548c5c$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $68e0afe9fd548c5c$var$_listBulleted = $68e0afe9fd548c5c$var$_interopRequireDefault((parcelRequire("bEjt9")));
    function $68e0afe9fd548c5c$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $68e0afe9fd548c5c$var$BulletListIcon = (props)=>$68e0afe9fd548c5c$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><g fill="currentcolor" fill-rule="evenodd"><rect width="8" height="2" x="10" y="15" rx="1"/><rect width="2" height="2" x="6" y="15" rx="1"/><rect width="8" height="2" x="10" y="11" rx="1"/><rect width="2" height="2" x="6" y="11" rx="1"/><rect width="8" height="2" x="10" y="7" rx="1"/><rect width="2" height="2" x="6" y="7" rx="1"/></g></svg>`
        }, props, {
            newIcon: $68e0afe9fd548c5c$var$_listBulleted.default
        }));
    $68e0afe9fd548c5c$var$BulletListIcon.displayName = 'BulletListIcon';
    var $68e0afe9fd548c5c$var$_default = module.exports.default = $68e0afe9fd548c5c$var$BulletListIcon;
});
parcelRegister("bEjt9", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $87b28849bcb64a34$var$_react = $87b28849bcb64a34$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $87b28849bcb64a34$var$_UNSAFE_baseNew = $87b28849bcb64a34$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $87b28849bcb64a34$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $87b28849bcb64a34$var$ListBulletedIcon = (props)=>$87b28849bcb64a34$var$_react.default.createElement($87b28849bcb64a34$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path fill="currentcolor" d="M2.75 2.75a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m0 5.25a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m0 5.25a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0"/><path stroke="currentcolor" stroke-width="1.5" d="M5 2.75h10M5 8h10M5 13.25h10M2.75 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm0 5.25a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm0-10.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"/>`
        }, props));
    $87b28849bcb64a34$var$ListBulletedIcon.displayName = 'ListBulletedIcon';
    var $87b28849bcb64a34$var$_default = module.exports.default = $87b28849bcb64a34$var$ListBulletedIcon;
});
parcelRegister("jmEdU", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $e18f45ea4410736a$var$_react = $e18f45ea4410736a$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $e18f45ea4410736a$var$_sidebarRight = $e18f45ea4410736a$var$_interopRequireDefault((parcelRequire("aRO6g")));
    function $e18f45ea4410736a$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $e18f45ea4410736a$var$DetailViewIcon = (props)=>$e18f45ea4410736a$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><path fill="currentcolor" d="M14 17h6V7.01C20 7 14 7 14 7zM12 5h8c1.11 0 2 .9 2 2.01V17a2 2 0 0 1-2 2h-8zM2 7h8V5H3a1 1 0 0 0-1 1zm0 4h8V9H2zm0 7c0 .55.45 1 1 1h7v-2H2zm0-3h8v-2H2z"/></svg>`
        }, props, {
            newIcon: $e18f45ea4410736a$var$_sidebarRight.default
        }));
    $e18f45ea4410736a$var$DetailViewIcon.displayName = 'DetailViewIcon';
    var $e18f45ea4410736a$var$_default = module.exports.default = $e18f45ea4410736a$var$DetailViewIcon;
});
parcelRegister("aRO6g", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $7e95b773b99220a6$var$_react = $7e95b773b99220a6$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $7e95b773b99220a6$var$_UNSAFE_baseNew = $7e95b773b99220a6$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $7e95b773b99220a6$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $7e95b773b99220a6$var$SidebarRightIcon = (props)=>$7e95b773b99220a6$var$_react.default.createElement($7e95b773b99220a6$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-linejoin="round" stroke-width="1.5" d="M7.75 2.75H14c.69 0 1.25.56 1.25 1.25v8c0 .69-.56 1.25-1.25 1.25H7.75m0-10.5v10.5m0-10.5H2C1.31 2.75.75 3.31.75 4v8c0 .69.56 1.25 1.25 1.25h5.75M10 5.75h3m-3 3h3"/>`
        }, props));
    $7e95b773b99220a6$var$SidebarRightIcon.displayName = 'SidebarRightIcon';
    var $7e95b773b99220a6$var$_default = module.exports.default = $7e95b773b99220a6$var$SidebarRightIcon;
});
parcelRegister("761Hb", function(module, exports) {
    $parcel$export(module.exports, "cardKeys", ()=>$d137429970660ed4$export$79399c5778983d77);
    const $d137429970660ed4$export$79399c5778983d77 = {
        GLOBAL_SCOPE_JQL_BUILDER_NL_TO_JQL: 'global-scope.jql-builder-with-ai.natural-language-to-jql',
        GLOBAL_SCOPE_VIEW_SWITCHER: 'global-scope.view-switcher',
        GLOBAL_SCOPE_COLUMN_PICKER: 'global-scope.column-picker',
        JQL_BUILDER_NL_TOGGLE: 'jql-builder-with-ai.natural-language-toggle',
        JQL_BUILDER_NL_TO_JQL: 'jql-builder-with-ai.natural-language-to-jql'
    };
});
parcelRegister("qLuSt", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$ea5d5d108c2f1c20$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $39xOx;
    var $lfTZh;
    var $kxZPA;
    const $ea5d5d108c2f1c20$var$SpotlightTourCardAsync = (0, (0, parcelRequire("kxZPA")).lazyForPaint)(()=>(parcelRequire("2TbQO")), {
        ssr: false,
        moduleId: "./src/packages/issue-navigator/changeboarding/src/ui/spotlight-tour-card/main.tsx"
    });
    const $ea5d5d108c2f1c20$var$SpotlightTourCard = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            packageName: "jiraIssueNavigatorChangeboarding",
            id: "async-issue-navigator-changeboarding-spotlight-tour-card",
            sendToPrivacyUnsafeSplunk: true,
            fallback: "unmount"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "async-issue-navigator-changeboarding-spotlight-tour-card"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($ea5d5d108c2f1c20$var$SpotlightTourCardAsync, props)));
    var $ea5d5d108c2f1c20$export$2e2bcd8739ae039 = $ea5d5d108c2f1c20$var$SpotlightTourCard;
});
parcelRegister("2TbQO", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("khibr").then(()=>parcelRequire('4CMeD'));
});
parcelRegister("2jDmD", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$2e9c17dad5a296c3$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $imZsD;
    var $5Fk4Z;
    var $1QiBP;
    var $8QPci;
    const $2e9c17dad5a296c3$var$SpotlightTourTarget = ({ children: children, name: name, renderSpotlightCard: renderSpotlightCard })=>{
        const { onEndTour: onEndTour, onSetActiveCard: onSetActiveCard } = (0, (0, parcelRequire("8QPci")).useSpotlightCoordinationActions)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("imZsD")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5Fk4Z")).default), {
            name: name
        }, children), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1QiBP")).default), null, typeof renderSpotlightCard === 'function' ? renderSpotlightCard({
            onEndTour: onEndTour,
            onSetActiveCard: onSetActiveCard
        }) : renderSpotlightCard));
    };
    var $2e9c17dad5a296c3$export$2e2bcd8739ae039 = $2e9c17dad5a296c3$var$SpotlightTourTarget;
});
parcelRegister("8QPci", function(module, exports) {
    $parcel$export(module.exports, "useIsActiveSpotlightCard", ()=>$1bf3a4be42170bcd$export$ac706a6842f43035);
    $parcel$export(module.exports, "useSpotlightCoordinationActions", ()=>$1bf3a4be42170bcd$export$d0297e733168e478);
    var $3sQ5x;
    var $iXqE5;
    const $1bf3a4be42170bcd$var$initialState = {
        cardKey: null,
        onStopCoordination: null
    };
    const $1bf3a4be42170bcd$var$actions = {
        onSetActiveCard: (cardKey, stop)=>({ getState: getState, setState: setState })=>{
                const { onStopCoordination: onStopCoordination } = getState();
                setState({
                    cardKey: cardKey,
                    onStopCoordination: stop ?? onStopCoordination
                });
            },
        onEndTour: ()=>({ getState: getState, setState: setState })=>{
                const { onStopCoordination: onStopCoordination } = getState();
                onStopCoordination && onStopCoordination();
                setState({
                    cardKey: null,
                    onStopCoordination: null
                });
            }
    };
    const $1bf3a4be42170bcd$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: $1bf3a4be42170bcd$var$initialState,
        actions: $1bf3a4be42170bcd$var$actions
    });
    const $1bf3a4be42170bcd$export$ac706a6842f43035 = (0, (0, parcelRequire("3sQ5x")).createHook)($1bf3a4be42170bcd$var$Store, {
        selector: (state, cardKeys)=>Array.isArray(cardKeys) ? state.cardKey !== null && cardKeys.includes(state.cardKey) : state.cardKey === cardKeys
    });
    const $1bf3a4be42170bcd$export$d0297e733168e478 = (0, (0, parcelRequire("3sQ5x")).createActionsHook)($1bf3a4be42170bcd$var$Store);
});
parcelRegister("1aUn4", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$3938a8ce9ce3c5ba$export$2e2bcd8739ae039);
    var $7XYc4;
    var $dCm1o;
    var $6maaD;
    var $1AOfx;
    var $3938a8ce9ce3c5ba$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7XYc4")).componentWithCondition)((0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled), (0, (0, parcelRequire("6maaD")).ToggleButtons), (0, (0, parcelRequire("1AOfx")).default));
});
parcelRegister("6maaD", function(module, exports) {
    $parcel$export(module.exports, "ToggleButtons", ()=>$5bbd957d88c15d3d$export$34301fadafd0cd9d);
    var $5uXOq = parcelRequire("5uXOq");
    var $dC5iT;
    var $5ILhZ;
    var $8CUq3;
    const $5bbd957d88c15d3d$export$34301fadafd0cd9d = ({ options: options, selectedOption: selectedOption, onChange: onChange, label: label, isCompact: isCompact })=>{
        const getButtonWithContext = (0, $5uXOq.useCallback)(({ option: option, isSelected: isSelected })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5ILhZ")).default), {
                key: option.id,
                role: "radio",
                "aria-checked": isSelected,
                xcss: [
                    $5bbd957d88c15d3d$var$baseStyles,
                    !isCompact && $5bbd957d88c15d3d$var$wideStyles,
                    isSelected && $5bbd957d88c15d3d$var$selectedStyles
                ],
                onClick: (event)=>!isSelected && onChange(option.id, event),
                isDisabled: option.isDisabled,
                testId: option.testId
            }, option.label), [
            onChange,
            isCompact
        ]);
        return ((0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            role: "radiogroup",
            xcss: $5bbd957d88c15d3d$var$containerStyles,
            "aria-label": label,
            as: "fieldset"
        }, options.map((option)=>getButtonWithContext({
                option: option,
                isSelected: option.id === selectedOption
            }))));
    };
    const $5bbd957d88c15d3d$var$containerStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        borderColor: 'color.border',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: "var(--ds-border-radius-100, 4px)",
        paddingInline: 'space.025',
        gap: 'space.025',
        display: 'inline-flex',
        alignItems: 'center',
        height: '2rem'
    });
    const $5bbd957d88c15d3d$var$baseStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        background: "var(--ds-background-neutral-subtle, #00000000)",
        border: '1px solid transparent',
        borderRadius: "var(--ds-border-radius-050, 2px)",
        color: 'color.text.subtle',
        height: '26px',
        fontWeight: '500',
        padding: 'space.0',
        ':hover': {
            background: "var(--ds-background-neutral-subtle-hovered, #091E420F)",
            color: 'color.text.subtle'
        },
        ':active': {
            background: "var(--ds-background-neutral-subtle-pressed, #091E4224)",
            color: 'color.text.subtle'
        },
        ':focus': {
            outlineOffset: 'space.0'
        },
        ':disabled': {
            background: "var(--ds-background-disabled, #091E4208)",
            color: 'color.text.disabled'
        }
    });
    const $5bbd957d88c15d3d$var$selectedStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        background: "var(--ds-background-selected, #E9F2FF)",
        border: `1px solid ${"var(--ds-border-selected, #0C66E4)"}`,
        color: 'color.text.selected',
        cursor: 'default',
        ':hover': {
            background: "var(--ds-background-selected, #E9F2FF)",
            color: 'color.text.selected'
        }
    });
    const $5bbd957d88c15d3d$var$wideStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        paddingLeft: 'space.150',
        paddingRight: 'space.150'
    });
});
parcelRegister("1AOfx", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$b3d0d9bc4668d1db$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $lD23V;
    var $gwIPs;
    var $iR4uK;
    var $gmQXa;
    let $b3d0d9bc4668d1db$var$uniqueId = 0;
    var $b3d0d9bc4668d1db$export$2e2bcd8739ae039 = ({ label: label, options: options, selectedOption: selectedOption, onChange: onChange })=>{
        (0, (0, parcelRequire("gmQXa")).assertPrecondition)(()=>({
                precondition: options.length > 0,
                message: `ToggleButtons requires at least one option, but received ${options.length}`
            }));
        (0, (0, parcelRequire("gmQXa")).assertPrecondition)(()=>({
                precondition: (0, (0, parcelRequire("gmQXa")).findDuplicateIds)(options) === null,
                message: `ToggleButtons requires that every option has a unique ID, but received "${(0, (0, parcelRequire("gmQXa")).findDuplicateIds)(options)}" multiple times`
            }));
        (0, (0, parcelRequire("gmQXa")).assertPrecondition)(()=>({
                precondition: options.findIndex((option)=>option.id === selectedOption) !== -1,
                message: `ToggleButtons requires that the selectedOption matches the ID of one of the passed options, but "${selectedOption}" did not`
            }));
        const [id] = (0, $5uXOq.useState)(()=>`toggle-buttons-${$b3d0d9bc4668d1db$var$uniqueId++}`);
        const fieldsetRef = (0, $5uXOq.useRef)(null);
        const selectedOptionRef = (0, $5uXOq.useRef)();
        const [selectedOptionPosition, setSelectedOptionPosition] = (0, $5uXOq.useState)();
        (0, $5uXOq.useEffect)(()=>{
            const fieldset = fieldsetRef.current;
            if (!fieldset || !window.ResizeObserver) return ()=>{};
            const resizeObserver = new window.ResizeObserver(()=>{
                const element = selectedOptionRef.current;
                if (element) setSelectedOptionPosition({
                    left: element.offsetLeft,
                    top: element.offsetTop,
                    width: element.offsetWidth,
                    height: element.offsetHeight
                });
            });
            resizeObserver.observe(fieldset);
            return ()=>{
                resizeObserver.unobserve(fieldset);
            };
        }, [
            selectedOption
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($b3d0d9bc4668d1db$var$Fieldset, {
            ref: fieldsetRef,
            selectedOptionPosition: selectedOptionPosition
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("legend", null, label), options.map((option)=>{
            const isChecked = selectedOption === option.id;
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iR4uK")).default), {
                key: option.id,
                name: id,
                value: option.id,
                isChecked: isChecked,
                isDisabled: !!option.isDisabled,
                onChange: onChange,
                ref: isChecked ? selectedOptionRef : undefined,
                testId: option.testId
            }, option.label);
        }));
    };
    const $b3d0d9bc4668d1db$var$selectedOptionIndicator = {
        backgroundColor: `${`var(--ds-background-accent-gray-subtler, ${(0, $829f609a65d26a98$exports).N0})`} !important`,
        borderRadius: `${(0, (0, parcelRequire("gwIPs")).borderRadius)}px !important`
    };
    const $b3d0d9bc4668d1db$var$Fieldset = (0, $5uXOq.forwardRef)(({ as: C = "fieldset", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { selectedOptionPosition: selectedOptionPosition, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: {
                ...__cmpls,
                "--_ozn9do": (0, (0, parcelRequire("lD23V")).default)(__cmplp.selectedOptionPosition?.left, "px"),
                "--_1ibdxcf": (0, (0, parcelRequire("lD23V")).default)(__cmplp.selectedOptionPosition?.top, "px"),
                "--_1ggrzjo": (0, (0, parcelRequire("lD23V")).default)(__cmplp.selectedOptionPosition?.width, "px"),
                "--_1797z6d": (0, (0, parcelRequire("lD23V")).default)(__cmplp.selectedOptionPosition?.height, "px")
            },
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _1n261g80 _1bsb1ris _p12f1osq _kqswh2mm _bfhk1s4m _2rko1l7b _1yt41b66 _11c8qk37 _k48p1pd9 _vwz41clk _1p1dangw _syazaj1x _1r9t7mnp _ho6m1wmp _soiet94y _1se6t94y _1jdg1n1a _z71v1n1a _1o5t1n1a _irrs1n1a _p8t715vq _b4u715vq _1qiiidpf _12o6idpf _19mbidpf _1ob8idpf _1jhnstnw _10bvh2mm _1nvfze3t _1nei1b66 _rt2pze3t _bmksze3t _fhioidpf _1kt9b3bt _1cs8stnw _1rus1tag _rfx31wa3 _qs2z1l1k _1cg61yx9 _mlfedkwg",
                __cmplp.selectedOptionPosition ? "_cfu11ule" : "_cfu1glyw",
                __cmplp.selectedOptionPosition?.left ? "_qnecejz7" : "_qnecidpf",
                __cmplp.selectedOptionPosition?.top ? "_1auk1nuc" : "_1aukidpf",
                __cmplp.selectedOptionPosition?.width ? "_1mp412nj" : "_1mp4idpf",
                __cmplp.selectedOptionPosition?.height ? "_kfgt1d85" : "_kfgtidpf",
                !__cmplp.selectedOptionPosition && "_15e91tag _1ph31wa3",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("iR4uK", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$e56adc76929ce8ab$export$2e2bcd8739ae039);
    var $bwpsq;
    var $5uXOq = parcelRequire("5uXOq");
    var $e56adc76929ce8ab$export$2e2bcd8739ae039 = (0, $5uXOq.forwardRef)(({ name: name, value: value, isChecked: isChecked, isDisabled: isDisabled, onChange: onChange, children: children, testId: testId }, ref)=>{
        const id = `${name}-${value}`;
        return (0, ($parcel$interopDefault($5uXOq))).createElement($e56adc76929ce8ab$var$Container, {
            ref: ref
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("input", {
            type: "radio",
            id: id,
            name: name,
            value: value,
            checked: isChecked,
            disabled: isDisabled,
            "data-testid": testId,
            onChange: (event)=>onChange(value, event)
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("label", {
            htmlFor: id
        }, children));
    });
    const $e56adc76929ce8ab$var$Container = (0, $5uXOq.forwardRef)(({ as: C = "p", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_11127mnp _1q091wmp _pytkt94y _5hv9t94y _2x4g1n1a _12hv1n1a _x5bd1n1a _1rgf1n1a _1yzy15vq _bgrh15vq _v69yidpf _ogxmidpf _1qtqidpf _n9z4idpf _khufstnw _15pj1ule _136ejjoz _1vzltlke _1ioh1l7b _1hhy73ad _1emz73ad _1efl8q0r _7el91vt6 _15pqglyw _vrjh14ae _c2fy18uv _ynfu18uv",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("gmQXa", function(module, exports) {
    $parcel$export(module.exports, "findDuplicateIds", ()=>$1d35542953a1be7d$export$e5f5e25214715ca1);
    $parcel$export(module.exports, "assertPrecondition", ()=>$1d35542953a1be7d$export$5565eefbe4b468a0);
    const $1d35542953a1be7d$export$e5f5e25214715ca1 = (options)=>{
        for(let i = 0; i < options.length - 1; ++i)for(let j = i + 1; j < options.length; ++j){
            if (options[i].id === options[j].id) return options[i].id;
        }
        return null;
    };
    const $1d35542953a1be7d$export$5565eefbe4b468a0 = (evaluatePrecondition)=>{};
});
parcelRegister("71kvA", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$270ebddc9dcee8b9$export$2e2bcd8739ae039);
    var $7VHMR;
    const $270ebddc9dcee8b9$var$messages = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        done: {
            "id": "issue-navigator.view-switcher.done",
            "defaultMessage": "Done"
        },
        next: {
            "id": "issue-navigator.view-switcher.next",
            "defaultMessage": "Next"
        },
        skip: {
            "id": "issue-navigator.view-switcher.skip",
            "defaultMessage": "Skip"
        },
        tourHeading: {
            "id": "issue-navigator.view-switcher.tour-heading",
            "defaultMessage": "View switcher"
        },
        tourBody: {
            "id": "issue-navigator.view-switcher.tour-body",
            "defaultMessage": "Switch between these two views to easily scan through a list or view the details of the issues."
        },
        detailText: {
            "id": "issue-navigator.refinement-bar.view-switcher.detail-text",
            "defaultMessage": "Switch to detail view"
        },
        listText: {
            "id": "issue-navigator.refinement-bar.view-switcher.list-text",
            "defaultMessage": "Switch to list view"
        },
        toggleButtonsLabel: {
            "id": "issue-navigator.refinement-bar.view-switcher.toggle-buttons-label",
            "defaultMessage": "Select view"
        },
        detailOptionLabel: {
            "id": "issue-navigator.refinement-bar.view-switcher.detail-option-label",
            "defaultMessage": "Detail view"
        },
        listOptionLabel: {
            "id": "issue-navigator.refinement-bar.view-switcher.list-option-label",
            "defaultMessage": "List view"
        },
        tourBodyIssueTermRefresh: {
            "id": "issue-navigator.view-switcher.tour-body-issue-term-refresh",
            "defaultMessage": "Switch between these two views to easily scan through a list or view the details of the work item."
        }
    });
    var $270ebddc9dcee8b9$export$2e2bcd8739ae039 = $270ebddc9dcee8b9$var$messages;
});
parcelRegister("ay8sY", function(module, exports) {
    $parcel$export(module.exports, "getJqlFromParamValues", ()=>$fac2feff71c61553$export$52de656da34011c3);
    $parcel$export(module.exports, "getSearchInput", ()=>$fac2feff71c61553$export$2fa22ca814667ff4);
    $parcel$export(module.exports, "useUpdateUrlQueryParamCallback", ()=>$fac2feff71c61553$export$f6b4f46bd8314b51);
    var $5uXOq = parcelRequire("5uXOq");
    var $9Nvh7;
    var $ieDtd;
    var $6UAdF;
    const $fac2feff71c61553$export$52de656da34011c3 = (defaultJql, jqlParam, filter, customMapper, onMutateJqlParam)=>{
        let jql = jqlParam;
        if (typeof jql === 'string') return jql;
        if ((0, (0, parcelRequire("9Nvh7")).isFilterId)(filter)) return undefined;
        if (typeof filter === 'string' && customMapper) jql = customMapper(filter);
        if (jql === undefined) jql = defaultJql;
        if (jql !== jqlParam) onMutateJqlParam?.(jql);
        return jql;
    };
    const $fac2feff71c61553$var$getFilter = (filterInput)=>(0, (0, parcelRequire("9Nvh7")).isFilterId)(filterInput) ? {
            value: filterInput,
            type: (0, (0, parcelRequire("6UAdF")).FilterTypes).ID
        } : {
            value: filterInput,
            type: (0, (0, parcelRequire("6UAdF")).FilterTypes).JQL
        };
    const $fac2feff71c61553$export$2fa22ca814667ff4 = (jqlInput, filterInput)=>{
        const filter = filterInput !== undefined ? $fac2feff71c61553$var$getFilter(filterInput) : undefined;
        if (jqlInput === undefined) {
            if (filter === undefined) throw new Error('filter is missing for search input');
            return filter;
        }
        if (filter === undefined) return {
            jql: jqlInput,
            type: (0, (0, parcelRequire("6UAdF")).SearchInputTypes).JQL
        };
        return {
            jql: jqlInput,
            filter: filter,
            type: (0, (0, parcelRequire("6UAdF")).SearchInputTypes).FILTER_AND_JQL
        };
    };
    const $fac2feff71c61553$export$f6b4f46bd8314b51 = (queryParamKey)=>{
        const [_, setQueryParam] = (0, (0, parcelRequire("ieDtd")).useQueryParam)(queryParamKey);
        const setJqlQueryParamCallback = (0, $5uXOq.useCallback)((value)=>setQueryParam(value, 'replace'), [
            setQueryParam
        ]);
        return setJqlQueryParamCallback;
    };
});
parcelRegister("5rqex", function(module, exports) {
    $parcel$export(module.exports, "checkAndEmitApdexSignal", ()=>$ab527e70454bab7e$export$3082c88b693a5230);
    $parcel$export(module.exports, "default", ()=>$ab527e70454bab7e$export$2e2bcd8739ae039);
    $parcel$export(module.exports, "baseDefaultProps", ()=>$ab527e70454bab7e$export$9b3029ee6188a41f);
    var $5uXOq = parcelRequire("5uXOq");
    var $99gkn;
    var $aOr3t;
    var $1u4q7;
    var $co1wz;
    var $1AZST;
    var $6G6w0;
    var $3wLoG;
    var $gtdbK;
    var $zbAs4;
    var $3B7oW;
    const $ab527e70454bab7e$export$3082c88b693a5230 = (appName, metricKey)=>{
        var emitSignal;
    };
    class $ab527e70454bab7e$export$2e2bcd8739ae039 extends (0, $5uXOq.Component) {
        static{
            this.defaultProps = {
                isExpectedToHaveSsr: false,
                withMarks: [],
                extra: {},
                emitOnAnimationFrame: false
            };
        }
        render() {
            const { appName: appName, metricKey: metricKey, extra: extra, metric: metric, emitOnAnimationFrame: emitOnAnimationFrame } = this.props;
            $ab527e70454bab7e$export$3082c88b693a5230(appName, metricKey);
            const isUFODefined = (0, (0, parcelRequire("co1wz")).ff)('uip.ufo') && (0, (0, parcelRequire("1AZST")).GlobalPageLoadExperience).loadingPageLoadId !== 'UNKNOWN';
            if (isUFODefined) (0, (0, parcelRequire("zbAs4")).addUFOSSRDoneAsFMPToInteraction)();
            if (metric) {
                (0, (0, parcelRequire("gtdbK")).addBM3SSRDoneAsFMPToInteraction)(metric);
                (0, (0, parcelRequire("3B7oW")).trackBM3FeatureFlagsAccessed)(metric);
            }
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("99gkn")).default), {
                data: extra
            }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("aOr3t")).UFOBM3TimingsToUFO), {
                marks: metric?.getData()?.marks,
                timings: metric?.getData()?.config?.timings
            }), isUFODefined ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3wLoG")).StopUfo), {
                appName: appName,
                customData: extra,
                key: (0, (0, parcelRequire("1u4q7")).getInteractionId)().current
            }) : metric && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6G6w0")).StopBrowserMetrics3), {
                metric: metric,
                customData: extra,
                emitOnAnimationFrame: emitOnAnimationFrame
            }));
        }
    }
    const $ab527e70454bab7e$export$9b3029ee6188a41f = $ab527e70454bab7e$export$2e2bcd8739ae039.defaultProps;
});
parcelRegister("aOr3t", function(module, exports) {
    $parcel$export(module.exports, "getBm3Timings", ()=>$a3e67858f0c2f8da$export$f02972da195a43bb);
    $parcel$export(module.exports, "UFOBM3TimingsToUFO", ()=>$a3e67858f0c2f8da$export$30f0b5e5acd268bf);
    $parcel$export(module.exports, "addBM3TimingsToUFO", ()=>$a3e67858f0c2f8da$export$d93d677ef2f33c5c);
    var $5uXOq = parcelRequire("5uXOq");
    var $1tcYS;
    var $1u4q7;
    var $bFU4G;
    function $a3e67858f0c2f8da$export$f02972da195a43bb(marks, timingConfigs) {
        const bm3Timings = {};
        if (!marks || !timingConfigs) return bm3Timings;
        timingConfigs.forEach((item)=>{
            if (!item.startMark || !item.endMark) return;
            const startTime = marks[item.startMark];
            if (!startTime) return;
            const endTime = marks[item.endMark];
            if (!endTime) return;
            bm3Timings[item.key] = {
                startTime: startTime,
                endTime: endTime
            };
        });
        return bm3Timings;
    }
    function $a3e67858f0c2f8da$export$30f0b5e5acd268bf({ marks: marks, timings: timings }) {
        const interactionContext = (0, $5uXOq.useContext)((0, (0, parcelRequire("1tcYS")).default));
        const interactionId = (0, (0, parcelRequire("1u4q7")).getInteractionId)().current;
        (0, $5uXOq.useMemo)(()=>{
            if (interactionContext != null && interactionId != null && marks != null && timings != null) {
                const interactionType = (0, (0, parcelRequire("bFU4G")).getCurrentInteractionType)(interactionId);
                if (interactionType === 'press') return;
                const bm3Timings = $a3e67858f0c2f8da$export$f02972da195a43bb(marks, timings);
                interactionContext.addCustomTimings(bm3Timings);
            }
        }, [
            interactionContext,
            interactionId,
            marks,
            timings
        ]);
        return null;
    }
    function $a3e67858f0c2f8da$export$d93d677ef2f33c5c(marks, timingsConfig) {
        const interactionId = (0, (0, parcelRequire("1u4q7")).getInteractionId)().current;
        if (interactionId) {
            const interactionType = (0, (0, parcelRequire("bFU4G")).getCurrentInteractionType)(interactionId);
            if (interactionType === 'press') return;
            const bm3Timings = $a3e67858f0c2f8da$export$f02972da195a43bb(marks, timingsConfig);
            (0, (0, parcelRequire("bFU4G")).addCustomTiming)(interactionId, [], bm3Timings);
        }
    }
});
parcelRegister("6G6w0", function(module, exports) {
    $parcel$export(module.exports, "StopBrowserMetrics3", ()=>$84d0d168ebb35c8a$export$575e93c0f9905999);
    var $5uXOq = parcelRequire("5uXOq");
    var $1tcYS;
    var $21pfT;
    var $x9xyg;
    var $7N30e;
    var $co1wz;
    var $4dRjY;
    var $2SVPM;
    var $6b6Tk;
    const $84d0d168ebb35c8a$var$emitMetrics = (metric, customData, stopTime)=>{
        !(0, (0, parcelRequire("co1wz")).ff)('bm3.emit-on-raf.top-experiences') && (0, (0, parcelRequire("2SVPM")).stopInitialPageLoadTimingFromPerformanceMarkStart)('product', (0, (0, parcelRequire("4dRjY")).PRODUCT_START_MARK), true);
        (0, (0, parcelRequire("2SVPM")).setInitialPageLoadTimingFromPerformanceMarks)('batch-js-heritage.eval', 'jira.heritage_batch_file_eval:start', 'jira.heritage_batch_file_eval:end');
        if (stopTime !== undefined) {
            if (!(0, (0, parcelRequire("co1wz")).ff)('bm3.wait-for-long-task')) {
                metric.mark((0, $ce556f125dee8174$export$99c08ec92759c71c));
                (0, (0, parcelRequire("x9xyg")).setMark)((0, $ce556f125dee8174$export$99c08ec92759c71c));
                (0, (0, parcelRequire("x9xyg")).setMeasure)('BROWSER_METRICS_TTI_RAF', 'BROWSER_METRICS_TTI_START', (0, $ce556f125dee8174$export$99c08ec92759c71c));
            }
            metric.stop({
                customData: customData,
                stopTime: stopTime
            });
        } else metric.stop({
            customData: customData
        });
        if ((0, (0, parcelRequire("6b6Tk")).isAutomaticExposureCollectionEnabled)()) (0, (0, parcelRequire("7N30e")).stopLowPriorityEventDelay)();
    };
    class $84d0d168ebb35c8a$export$575e93c0f9905999 extends (0, $5uXOq.Component) {
        componentDidMount() {
            const { metric: metric, customData: customData, emitOnAnimationFrame: emitOnAnimationFrame } = this.props;
            if (!metric) return;
            const start = (0, (0, parcelRequire("co1wz")).ff)('bm3.wait-for-long-task') ? (0, (0, parcelRequire("21pfT")).browserMetrics).getPageLoadMetric().getData()?.start || 0 : 0;
            (0, (0, parcelRequire("x9xyg")).setMark)('BROWSER_METRICS_TTI_START', {
                startTime: start
            });
            (0, (0, parcelRequire("x9xyg")).setMark)('BROWSER_METRICS_TTI_END');
            (0, (0, parcelRequire("x9xyg")).setMeasure)('BROWSER_METRICS_TTI', 'BROWSER_METRICS_TTI_START', 'BROWSER_METRICS_TTI_END');
            const bm3Data = metric.getData();
            if (emitOnAnimationFrame) {
                const stopTime = performance.now();
                (0, (0, parcelRequire("co1wz")).ff)('bm3.emit-on-raf.top-experiences') && (0, (0, parcelRequire("2SVPM")).stopInitialPageLoadTimingFromPerformanceMarkStart)('product', (0, (0, parcelRequire("4dRjY")).PRODUCT_START_MARK), true);
                if (bm3Data) this.context?.addApdex({
                    key: bm3Data.key,
                    startTime: bm3Data.start,
                    stopTime: stopTime
                });
                if (typeof queueMicrotask !== 'undefined') queueMicrotask(()=>{
                    (0, (0, parcelRequire("x9xyg")).setMark)('BROWSER_METRICS_TTI_TASK_END');
                    (0, (0, parcelRequire("x9xyg")).setMeasure)('BROWSER_METRICS_TTI_TASK', 'BROWSER_METRICS_TTI_START', 'BROWSER_METRICS_TTI_TASK_END');
                });
                requestAnimationFrame(()=>{
                    if ((0, (0, parcelRequire("co1wz")).ff)('bm3.wait-for-long-task')) {
                        metric.mark((0, $ce556f125dee8174$export$99c08ec92759c71c));
                        (0, (0, parcelRequire("x9xyg")).setMark)((0, $ce556f125dee8174$export$99c08ec92759c71c));
                        (0, (0, parcelRequire("x9xyg")).setMeasure)('BROWSER_METRICS_TTI_RAF', 'BROWSER_METRICS_TTI_START', (0, $ce556f125dee8174$export$99c08ec92759c71c));
                    }
                    if ((0, (0, parcelRequire("co1wz")).ff)('bm3.wait-for-long-task')) setTimeout(()=>{
                        $84d0d168ebb35c8a$var$emitMetrics(metric, customData, stopTime);
                    }, 500);
                    else $84d0d168ebb35c8a$var$emitMetrics(metric, customData, stopTime);
                });
            } else {
                if (bm3Data) this.context?.addApdex({
                    key: bm3Data.key,
                    startTime: bm3Data.start,
                    stopTime: performance.now()
                });
                (0, (0, parcelRequire("co1wz")).ff)('bm3.emit-on-raf.top-experiences') && (0, (0, parcelRequire("2SVPM")).stopInitialPageLoadTimingFromPerformanceMarkStart)('product', (0, (0, parcelRequire("4dRjY")).PRODUCT_START_MARK), true);
                $84d0d168ebb35c8a$var$emitMetrics(metric, customData);
            }
        }
        static{
            this.contextType = (0, (0, parcelRequire("1tcYS")).default);
        }
        render() {
            return null;
        }
    }
});
parcelRegister("4dRjY", function(module, exports) {
    $parcel$export(module.exports, "PRODUCT_START_MARK", ()=>$9953c23a4f71adac$export$8e54fca0a680fdfd);
    $parcel$export(module.exports, "MarkProductStart", ()=>$9953c23a4f71adac$export$2a999c5f84501025);
    var $x9xyg;
    const $9953c23a4f71adac$export$8e54fca0a680fdfd = 'PRODUCT_START';
    const $9953c23a4f71adac$export$2a999c5f84501025 = ()=>{
        if (!false) {
            performance.clearMarks($9953c23a4f71adac$export$8e54fca0a680fdfd);
            (0, (0, parcelRequire("x9xyg")).setMark)($9953c23a4f71adac$export$8e54fca0a680fdfd);
        }
        return null;
    };
});
const $ce556f125dee8174$export$56940a9488e85199 = 'jira.fe.spa-';
const $ce556f125dee8174$export$45c4b6bf7935c69a = `${$ce556f125dee8174$export$56940a9488e85199}start`;
const $ce556f125dee8174$export$135c96ab5afee429 = 'ssr.spa.rendered';
const $ce556f125dee8174$export$ff834eccc35b9089 = 'appBundleReady';
const $ce556f125dee8174$export$86b05e34d462ead8 = 'prefetchApiReady';
const $ce556f125dee8174$export$d6a604a7d9a390fb = 'prefetchApiStart';
const $ce556f125dee8174$export$1211adfff27a31fe = 'issueViewInitialDataReady';
const $ce556f125dee8174$export$3ae43cc1782d058f = 'jswRenderStart';
const $ce556f125dee8174$export$363f6703ffa600be = 'jswCreateReduxStoreStart';
const $ce556f125dee8174$export$e87b74567b79c8 = 'jswCreateReduxStoreEnd';
const $ce556f125dee8174$export$99fe642459a8b02 = 'jswTransformCriticalDataStart';
const $ce556f125dee8174$export$ab1f5705fc8e0571 = 'jswTransformCriticalDataEnd';
const $ce556f125dee8174$export$8c3a9046da692c35 = 'jswReduxSoftwareAppLoadedStart';
const $ce556f125dee8174$export$5aa497d1efdcc5bf = 'jswReduxSoftwareAppLoadedEnd';
const $ce556f125dee8174$export$99c08ec92759c71c = 'tti-raf-mark';
const $ce556f125dee8174$export$48552baa9db8ce1d = 'tti-raf';
parcelRegister("3wLoG", function(module, exports) {
    $parcel$export(module.exports, "StopUfo", ()=>$bc9c50f4292c4530$export$d91154f099475c5);
    var $5uXOq = parcelRequire("5uXOq");
    var $1tcYS;
    var $1AZST;
    class $bc9c50f4292c4530$export$d91154f099475c5 extends (0, $5uXOq.Component) {
        componentDidMount() {
            const { customData: customData, appName: appName } = this.props;
            (0, (0, parcelRequire("1AZST")).GlobalPageLoadExperience).success({
                force: true,
                metadata: customData
            });
            const name = appName || (0, (0, parcelRequire("1AZST")).GlobalPageLoadExperience).loadingPageLoadId;
            if (name) this.context?.addApdex({
                key: name,
                stopTime: performance.now()
            });
        }
        static{
            this.contextType = (0, (0, parcelRequire("1tcYS")).default);
        }
        render() {
            return null;
        }
    }
});
parcelRegister("gtdbK", function(module, exports) {
    $parcel$export(module.exports, "addBM3SSRDoneAsFMPToInteraction", ()=>$9088c71f7c9189f4$export$bf239ac2bfb04967);
    var $1u4q7;
    var $bFU4G;
    function $9088c71f7c9189f4$export$bf239ac2bfb04967(metric) {
        const interactionId = (0, (0, parcelRequire("1u4q7")).getInteractionId)();
        const currentInteractionId = interactionId.current;
        if (!currentInteractionId) return;
        const data = metric.getData();
        const config = data?.config;
        if (config && data?.isInitial) (0, (0, parcelRequire("bFU4G")).addMetadata)(currentInteractionId, {
            __legacy__bm3ConfigSSRDoneAsFmp: config.ssr?.doneAsFmp || false
        });
    }
});
parcelRegister("zbAs4", function(module, exports) {
    $parcel$export(module.exports, "addUFOSSRDoneAsFMPToInteraction", ()=>$30d390cd1767d57f$export$e63862cd888fe05a);
    var $1u4q7;
    var $bFU4G;
    var $1AZST;
    var $49z18;
    async function $30d390cd1767d57f$export$e63862cd888fe05a() {
        const interactionId = (0, (0, parcelRequire("1u4q7")).getInteractionId)();
        const currentInteractionId = interactionId.current;
        if (!currentInteractionId) return;
        const data = await (0, (0, parcelRequire("1AZST")).GlobalPageLoadExperience).exportData();
        const fmpMark = data.metrics.marks.find((mark)=>mark.name === 'fmp') || (0, (0, parcelRequire("49z18")).payloadPublisher).ssr?.getDoneMark();
        if (data.initial && fmpMark) (0, (0, parcelRequire("bFU4G")).addMetadata)(currentInteractionId, {
            __legacy__ufoConfigSSRDoneAsFmp: true
        });
    }
});
parcelRegister("3B7oW", function(module, exports) {
    $parcel$export(module.exports, "trackBM3FeatureFlagsAccessed", ()=>$afa764d83f81f55e$export$f00f1e874ce869e9);
    var $1u4q7;
    var $y7eb2;
    const $afa764d83f81f55e$var$startsAsObject = (value)=>{
        try {
            if (value.charAt(0) === '{') return true;
        } catch (e) {
            return false;
        }
        return false;
    };
    const $afa764d83f81f55e$var$isObject = (value)=>value && typeof value === 'object' && value.constructor === Object;
    const $afa764d83f81f55e$var$isString = (input)=>{
        let result;
        try {
            result = typeof input === 'string' || input instanceof String;
        } catch (err) {
            result = false;
        }
        return result;
    };
    const $afa764d83f81f55e$export$7b4abba432e57989 = (value)=>$afa764d83f81f55e$var$isString(value) && $afa764d83f81f55e$var$startsAsObject(value) || $afa764d83f81f55e$var$isObject(value);
    const $afa764d83f81f55e$export$fcc03ee4c944962f = (featureFlagValue)=>$afa764d83f81f55e$export$7b4abba432e57989(featureFlagValue) ? 'non_boolean' : featureFlagValue;
    const $afa764d83f81f55e$export$d2818866b4acbea4 = new Map();
    const $afa764d83f81f55e$export$f00f1e874ce869e9 = (metric)=>{
        const interactionId = (0, (0, parcelRequire("1u4q7")).getInteractionId)();
        const currentInteractionId = interactionId.current;
        if (!currentInteractionId) return;
        const data = metric.getData();
        const config = data?.config;
        if (config && config?.featureFlags?.length) {
            const allFeatureFlags = (0, (0, parcelRequire("y7eb2")).default)();
            config.featureFlags.forEach((featureFlagName)=>{
                const value = allFeatureFlags[featureFlagName];
                if (value === undefined) return;
                const featureFlagValue = $afa764d83f81f55e$export$fcc03ee4c944962f(value);
                $afa764d83f81f55e$export$d2818866b4acbea4.set(featureFlagName, featureFlagValue);
            });
        }
    };
});
parcelRegister("9dbF6", function(module, exports) {
    $parcel$export(module.exports, "SubProductUpdater", ()=>$5979c081afa32bfa$export$eb40d3df56aa5c53);
    var $5uXOq = parcelRequire("5uXOq");
    var $bNvU3;
    var $3iTiR;
    var $ero6m;
    var $54I24;
    var $8OHH4;
    const $5979c081afa32bfa$export$eb40d3df56aa5c53 = ({ allowMonolithDeferral: allowMonolithDeferral = false, ...props })=>{
        const subProduct = (0, (0, parcelRequire("8OHH4")).isSubProductProps)(props) ? props.subProduct : (0, (0, parcelRequire("ero6m")).getSubProductFromProjectType)(props.projectType);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bNvU3")).default), {
            op: (0, (0, parcelRequire("54I24")).default),
            args: [
                subProduct,
                allowMonolithDeferral
            ]
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3iTiR")).default), {
            subProduct: subProduct
        }));
    };
});
parcelRegister("3iTiR", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$1214b0731559b377$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $1u3Jv;
    var $hFChZ;
    var $1214b0731559b377$export$2e2bcd8739ae039 = ({ subProduct: subProduct })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1u3Jv")).default), {
            op: (0, (0, parcelRequire("hFChZ")).default),
            args: [
                subProduct
            ]
        });
});
parcelRegister("hFChZ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$0e606430ead76cd8$export$2e2bcd8739ae039);
    var $dJl1T;
    var $eusud;
    var $0e606430ead76cd8$export$2e2bcd8739ae039 = (subProduct)=>{
        (0, (0, parcelRequire("eusud")).performGetRequest)(`/rest/internal/2/mauTag/${subProduct}`).catch((err)=>{
            (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('common.tag-mau.tag-mau-event', 'Error connecting to monolith mau tag endpoint', err);
        });
    };
});
parcelRegister("ero6m", function(module, exports) {
    $parcel$export(module.exports, "getSubProductFromProjectType", ()=>$0997694844c8a9e7$export$b1fe420ed15dff7b);
    var $16v4H;
    const $0997694844c8a9e7$export$b1fe420ed15dff7b = (projectType)=>{
        if (projectType === 'product_discovery') return 'polaris';
        return (0, (0, parcelRequire("16v4H")).subProductFromProjectType)(projectType);
    };
});
parcelRegister("54I24", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$bcbbcd48ca398ed4$export$2e2bcd8739ae039);
    var $7T84i;
    var $9B86Q;
    var $cQZXC;
    var $dJl1T;
    var $fjcgS;
    const $bcbbcd48ca398ed4$var$maybeDelayAndRace = (maybeWaitPromise, getDefaultPromise, getRacePromise)=>maybeWaitPromise ? Promise.race([
            maybeWaitPromise.then(getDefaultPromise),
            getRacePromise()
        ]) : getDefaultPromise();
    var $bcbbcd48ca398ed4$export$2e2bcd8739ae039 = async (subProduct, allowMonolithDeferral)=>{
        (0, (0, parcelRequire("fjcgS")).getAnalyticsWebClientPromise)().then((client)=>{
            client.setSubProduct(subProduct);
            if (subProduct === 'serviceDesk') client.setUIViewedAttributes({
                solutions: [
                    (0, $86344d58e71e925a$export$c8b406ad8ee0dab9)
                ]
            });
            else client.removeUIViewedAttribute('solutions');
        });
        $bcbbcd48ca398ed4$var$maybeDelayAndRace(allowMonolithDeferral ? (0, (0, parcelRequire("cQZXC")).waitForHeritage)() : null, ()=>(0, (0, parcelRequire("7T84i")).loadBridgeStrict)({
                name: 'jira/analytics/sub-product-resolver',
                wrmKeys: [
                    'wrc!com.atlassian.jira.jira-client-analytics-plugin:analytics-web-client'
                ]
            }), ()=>(0, (0, parcelRequire("9B86Q")).loadBridgeWeak)({
                name: 'jira/analytics/sub-product-resolver'
            })).then((subProductResolver)=>{
            subProductResolver.setSubProduct(subProduct);
        }).catch((e)=>(0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('common.analytics-sub-product.sub-product-updater', 'Error connecting to monolith sub product resolver', e));
    };
});
const $86344d58e71e925a$export$c8b406ad8ee0dab9 = 'jiraServiceManagement';
parcelRegister("8OHH4", function(module, exports) {
    $parcel$export(module.exports, "isSubProductProps", ()=>$701d07b56c3e3574$export$c3063d6d7a8c075f);
    const $701d07b56c3e3574$export$c3063d6d7a8c075f = (props)=>'subProduct' in props && typeof props.subProduct === 'string';
    const $701d07b56c3e3574$export$81da4a7e1ba1dcee = (props)=>'projectType' in props && typeof props.projectType === 'string';
});
