import { useEffect, useCallback } from 'react';
import { useService } from '@atlassian/jira-common-services/src/use-service/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';

export const useBooleanPreferenceSet = (
	key: string,
): {
	loading: boolean;
	error?: Error;
	update: () => Promise<void>;
} => {
	const setPreferenceToTrue = useCallback(
		() =>
			performPutRequest(`/rest/api/3/mypreferences?key=${key}`, {
				body: 'true',
			}),
		[key],
	);
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	const { loading, error, fetch: update } = useService<void>(setPreferenceToTrue);

	useEffect(() => {
		if (error) {
			fireErrorAnalytics({
				meta: {
					id: 'useBooleanPreferenceSet',
					packageName: 'jiraIssueNavigatorFlags',
					teamName: 'empanada',
				},
				error,
				attributes: {
					key,
				},
			});
		}
	}, [error, key]);
	return { loading, error, update };
};

export default useBooleanPreferenceSet;
