import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	pluggableItemsButtonText: {
		id: 'issue-navigator-action-pluggable-items.filter-button.pluggable-items-button-text',
		defaultMessage: 'Apps',
		description: 'The button text for the pluggable items button',
	},
	pluggableItemsButtonIconLabel: {
		id: 'issue-navigator-action-pluggable-items.filter-button.pluggable-items-button-icon-label',
		defaultMessage: 'Open pluggable items dropdown',
		description: 'The label text for the pluggable items button icon',
	},
});

export default messages;
